import React, { Component } from 'react';
import { Card, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

const API_notifications = '/WebAppService/CountOpenNotifications';
const API_logout = '/WebAppService/LogOut';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      userId: null,
      authId: null,
      resourceId: null,
      language: null,
      firstName: null,
      lastName: null,
      email: null,
      helpMode: null,
      reporting: null,
      staticData: null,
      usersConfig: null,
      navbarColor: null,
      navbarIcon: null,
      notifications: [],
      selected: null,
      isLoading: false,
      error: null
    };

    // Data Structure
    this.getNotifications = this.getNotifications.bind(this);

    // Actions
    this.changeSelected = this.changeSelected.bind(this);
    this.loadChatWidget = this.loadChatWidget.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
    this.logout = this.logout.bind(this);

    // Templates
    this.templateResource = this.templateResource.bind(this);
    this.templatePopover = this.templatePopover.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const userId = Authentication.getCookie('userId');
    const authId = Authentication.getCookie('authId');
    const resourceId = Authentication.getCookie('resourceId');
    const language = Authentication.getCookie('language');
    const firstName = Authentication.getCookie('firstName');
    const lastName = Authentication.getCookie('lastName');
    const email = Authentication.getCookie('email');
    const helpMode = Authentication.getCookie('helpMode');
    const reporting = Authentication.getCookie('reporting');
    const staticData = Authentication.getCookie('staticData');
    const usersConfig = Authentication.getCookie('usersConfig');
    const navbarColor = Authentication.getCookie('navbarColor');
    const navbarIcon = Authentication.getCookie('navbarIcon');
    const selected = this.props.Selected;

    this.setState({ login, userId, authId, resourceId, language, firstName, lastName, email, helpMode, reporting, staticData, usersConfig, selected, navbarColor, navbarIcon, isLoading: true });

    await this.getNotifications(login, authId);
  }

  async getNotifications(login, authId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };
    
    try {
      const response = await fetch(API_notifications, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const notifications = data.CountOpenNotificationsResult;
      
      if(notifications) {
        this.setState({ notifications, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  changeSelected(value) {
    this.setState({ selected: value });
  }

  loadChatWidget() {
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const email = this.state.email;

    window.fcWidget.init({
      token: "2ffa7ebc-3eac-441a-b653-b1a127c6e897",
      host: "https://wchat.freshchat.com",
      open: true,
      config: {
        hideFAQ: false,
      }
    });

    window.fcWidget.user.update({
      firstName: firstName,
      lastName: lastName,
      email: email
    });
  }

  updateNotifications() {
    const login = this.state.login;
    const authId = this.state.authId;

    this.getNotifications(login, authId);
  }

  async logout() {
    const login = this.state.login;
    const authId = this.state.authId;

    // Delete Front Cookies
    Authentication.deleteCookies();

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };
    
    try {
      const response = await fetch(API_logout, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const logout = data.LogOutResult;

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }
  
  templateResource(item) {
    if(item) {
      let firstname, lastname;
      let split = item.split(' ');

      if(split.length === 1) {
        firstname = item.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = item.split(' ')[0].substring(0,1);
        lastname = item.split(' ')[1].substring(0,1);
      }
      else {
        firstname = item.split(' ')[0].substring(0,1);
        lastname = item.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="iconFullCircleBlueDark brd icons fs13">
        <Card.Text className="white">{firstname + lastname}</Card.Text>  
      </div>;
    }
    else {
      return null;
    }
  }

  templatePopover() {
    const { login, userId, language, resourceId, usersConfig } = this.state;

    const popover = (
      <Popover id="popover-basic" className="popoverAccount">
        <Popover.Content>
          {/* Title */}
          <Form.Label className="accountTitle">{Traduction.translate(language, 'tabsters_account')}</Form.Label>
          {/* Border */}
          <div className="blockBorderBlue"></div>

          {/* Account Functionalities */}
          <div className="flex flex-column">
            {/* Profile */}
            <Link className="linkAccount" to={`/Card/User/${userId}/Details`}>
              <div className="accountAction">
                <span className="iconUserNavAccount verysmallIcons nav icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'my_profile')}</div>
              </div>
            </Link>
            {/* Change Password */}
            <Link className="linkAccount" to="/ChangePassword">
              <div className="accountAction">
                <span className="iconLockDarkBlue verysmallIcons nav icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'password_change')}</div>
              </div>
            </Link>
            {/* Notifications Center */}
            <Link className="linkAccount" to="/NotificationsCenter">
              <div className="accountAction cursor">
                <span className="iconNotificationsAccount verysmallIcons nav icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'notifications_parameters')}</div>
                {/* <div className="fs10 green ml5 italic">{Traduction.translate(language, 'available_later')}</div> */}
              </div>
            </Link>
            {/* Administration */}
            {usersConfig === 'true' && <Link className="linkAccount" to={`/Administration/Users`}>
              <div className="accountAction">
                <span className="iconAdministration verysmallIcons nav icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'administration')}</div>
              </div>
            </Link>}
            {/* DeleteBin */}
            {resourceId != 0 && <Link className="linkAccount" to={`/DeleteBin`}>
              <div className="accountAction">
                <span className="iconArchived verysmallIcons nav icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'delete_bin')}</div>
              </div>
            </Link>}
            {/* Help */}
            {/* <Link className="linkAccount" to="/Help">
              <div className="accountAction">
                <span className="iconHelpAccount verysmallIcons nav icon"></span>
                <div className="accountLabel">{labelHelp}</div>
              </div>
            </Link> */}
            {/* Logout */}
            <Link className="linkAccount" to={Traduction.translate(language, 'logout_link')} onClick={this.logout}>
              <div className="accountAction">
                <span className="iconLogoutAccount verysmallIcons nav icon"></span>
                <div className="accountLogout">{Traduction.translate(language, 'logout')}</div>
              </div>
            </Link>
          </div>
        </Popover.Content>
      </Popover>
    );
    
    return popover;
  }

  render() {
    const { userId, resourceId, language, firstName, lastName, email, helpMode, staticData, navbarColor, navbarIcon, selected, notifications } = this.state;
    let notif;

    // Notifications tooltip
    if(notifications > 0) {
      notif = <div className="notifications"><span className="tooltipNotifications">{notifications}</span></div>;
    }

    // Get current Date for Timetracking
    const date = new Date();
    const month = (date.getMonth())+1;
    const year = date.getFullYear();
    
    return (
      <div className="navigationBar" style={{ backgroundColor: navbarColor }}>
        <div className="">
          {/* Logo */}
          {navbarIcon && <Link className="linkNav" to="/Home">
            <div className="flex justify mt10 mb10">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'home')}</strong></Tooltip>}>
                  <img className="navbarLogo nav icon" src={navbarIcon} alt="Logo"/>
                </OverlayTrigger>
              ))}
            </div>
          </Link>}
          {!navbarIcon && <Link className="linkNav" to="/Home">
            <div className="flex justify mt10 mb10">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'home')}</strong></Tooltip>}>
                  <span className="iconTabstersLogoWhite navbarLogo nav icon"></span>
                </OverlayTrigger>
              ))}
            </div>
          </Link>}
          {/* Search */}
          <Link className="linkNav" to="/SearchResults">
            <div className={"flex justify pv15 mh5" + (selected === '/SearchResults' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'search')}</strong></Tooltip>}>
                  <span className="iconSearch iconsNav nav icon"></span>
                </OverlayTrigger>
              ))}
            </div>
          </Link>
          {/* Notifications */}
          <Link className="linkNav" to="/Notifications">
            <div className={"flex justify pv15 mh5" + (selected === '/Notifications' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'my_notifications')}</strong></Tooltip>}>
                  <div className="iconNotificationsWrapper"><i className="iconNotifications iconsNav nav icon" alt="Notifications"/>{notif}</div>
                </OverlayTrigger>
              ))}
            </div>
          </Link>
          {/* Environment */}
          <Link className="linkNav" to="/Environment/Home">
            <div className={"flex justify pv15 mh5" + ((selected === '/Environment/Home' || selected === '/Environment/Roadmap' || selected === '/Environment/Resources' || selected === '/Environment/AxisValues' || selected === '/Environment/CustomFields') ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'my_environment')}</strong></Tooltip>}>
                  <span className="iconEnvironmentNav iconsNav nav icon"></span>
                </OverlayTrigger>
              ))}
            </div>
          </Link>
          {/* My Activity */}
          {resourceId != 0 && <Link className="linkNav" to={`/Card/Resource/${resourceId}/Roadmap`}>
          {/* {resourceId != 0 && <Link className="linkNav" to="/Activity"> */}
            <div className={"flex justify pv15 mh5" + (selected === '/Card/Resource/' + resourceId + '/Roadmap' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'my_activity')}</strong></Tooltip>}>
                  <span className="iconActivity iconsNav nav icon"></span>
                </OverlayTrigger>
              ))}
            </div>
          </Link>}
          {/* Time Tracking */}
          {resourceId != 0 && <Link className="linkNav" to={`/TimeTracking?year=${year}&month=${month}&resourceId=${resourceId}`}>
          {/* {resourceId != 0 && <Link className="linkNav" to={`/TimeTracking/${year}/${month}/${resourceId}`}> */}
            <div className={"flex justify pv15 mh5" + (selected === '/TimeTracking' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'my_timetracking')}</strong></Tooltip>}>
                  <span className="iconTimeTracking iconsNav nav icon" alt="Activity"></span>
                </OverlayTrigger>
              ))}
            </div>
          </Link>}
        </div>
        <div className="">
          {/* Help */}
          {helpMode === 'help' && <a target="_blank" href={"https://help.tabsters.fr/support/" + Traduction.translate(language, 'locale') + "/" + Traduction.translate(language, 'help_center')} className="">
            <div className="cursor flex justify pv15 mh5">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'help')}</strong></Tooltip>}>
                  <span className="iconHelp iconsNav nav icon" alt="Help"></span>
                </OverlayTrigger>
              ))}
            </div>
          </a>}
          {helpMode === 'chat' && <div className="" onClick={() => this.loadChatWidget()}>
            <div className="cursor flex justify pv15 mh5">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'help')}</strong></Tooltip>}>
                  <span className="iconHelp iconsNav nav icon" alt="Help"></span>
                </OverlayTrigger>
              ))}
            </div>
          </div>}
          {/* User Ressource */}
          <div className="cursor flex justify pv15 mh5">
            <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.templatePopover()}>
              <span className="">{this.templateResource(firstName + " " + lastName)}</span>
            </OverlayTrigger>
          </div>
          
          {/* User */}
          {/* <Link className="linkNav" to={`/Card/User/${userId}/Details`}>
            <div className={"flex justify pv15 mh5" + (selected === '/Card/User/' + userId + '/Details' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{labelUser}</strong></Tooltip>}>
                  <div><i className="iconUserNav iconsNav nav icon" alt="User"/></div>
                </OverlayTrigger>
              ))}
            </div>
          </Link> */}
          {/* Help */}
          {/* <Link className="linkNav" to="/Help">
            <div className={"flex justify pv15 mh5" + (selected === '/Help' ? " bg-darkblue" : "")}>
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{labelHelp}</strong></Tooltip>}>
                  <div><i className="iconHelp iconsNav nav icon" alt="Help"/></div>
                </OverlayTrigger>
              ))}
            </div>
          </Link> */}
          {/* Logout */}
          {/* <Link className="linkNav" to="/" onClick={this.logout}>
            <div className="flex justify mt15 mb30">
              {['right'].map(placement => (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{labelLogout}</strong></Tooltip>}>
                  <div><i className="iconLogout iconsNav nav icon" alt="Logout"/></div>
                </OverlayTrigger>
              ))}
            </div>
          </Link> */}
        </div>
      </div>
    )
  }
}

export default Navbar;