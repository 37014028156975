import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopupConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemType: null,
      message: null
    };

    this.confirmMessage = this.confirmMessage.bind(this);
    this.cancelMessage = this.cancelMessage.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemType = this.props.ItemType;
    const message = this.props.Message;

    this.setState({ language, itemType, message });
  }

  componentDidUpdate(prevProps) {

  }

  confirmMessage() {
    // Call the event from the Parent component through the props
    this.props.onMessageConfirm();
  }

  cancelMessage() {
    // Call the event from the Parent component through the props
    this.props.onMessageCancel();
  }

  render() {
    const { language, itemType, message } = this.state;

    return (
      <div className="messagePopup">
        <div className="messageInnerPopup">
          <div className="popupMessage">
            {/* Message */}
            {itemType !== 'Resource' && <div className="bold">{message}</div>}
            {itemType === 'Resource' && <div className="red bold">{message}</div>}
            {/* Button Validate or Cancel */}
            <div className="flex flex-end mt10">
              <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.confirmMessage()}>{Traduction.translate(language, 'validate')}</Button>
              <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.cancelMessage()}>{Traduction.translate(language, 'cancel')}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopupConfirmation;