import React, { Component } from 'react';
import { HeatMapComponent, Legend, Tooltip, Adaptor, Inject } from '@syncfusion/ej2-react-heatmap';
import '../Css/App.css';
import Authentication from '../Authentication';

class WorkloadHeatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      heatmap: {},
      title: null,
      columns: [],
      rows: [],
      chartData: [],
      occupationLevel: null,
      occupationMetric: null,
      selectedDates: [],
      selectedResources: [],
      level: null,
      visibleHeatmap: true,
      visibleGraph: true,
      error: {}
    };
    
    // Data Structure
    this.dataStructure = this.dataStructure.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.cellSelected = this.cellSelected.bind(this);
    this.getColumnsLabel = this.getColumnsLabel.bind(this);
    this.getColumnsValues = this.getColumnsValues.bind(this);
    this.getRowsLabel = this.getRowsLabel.bind(this);
    this.getRowsValues = this.getRowsValues.bind(this);
    // this.getSelectedMonth = this.getSelectedMonth.bind(this);
    // this.getSelectedYear = this.getSelectedYear.bind(this);
    // this.getSelectedResourceId = this.getSelectedResourceId.bind(this);

    // Actions
    this.clearSelection = this.clearSelection.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemType = this.props.ItemType;
    const title = this.props.Title;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const chartData = this.props.ChartData;
    const occupationLevel = this.props.OccupationLevel;
    const occupationMetric = this.props.OccupationMetric;
    const visibleHeatmap = this.props.VisibleHeatmap;
    const visibleGraph = this.props.VisibleGraph;

    // Build Heatmap Datasource
    let heatmap = this.dataStructure(columns, rows, chartData);

    this.setState({ language, itemType, heatmap, title, columns, rows, chartData, occupationLevel, occupationMetric, visibleHeatmap, visibleGraph });
  }

  componentDidUpdate(prevProps) {
    const itemType = this.props.ItemType;
    const title = this.props.Title;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const chartData = this.props.ChartData;
    const occupationLevel = this.props.OccupationLevel;
    const occupationMetric = this.props.OccupationMetric;
    const visibleHeatmap = this.props.VisibleHeatmap;
    const visibleGraph = this.props.VisibleGraph;

    if(this.props.Columns !== prevProps.Columns || this.props.Rows !== prevProps.Rows || this.props.ChartData !== prevProps.ChartData) {
      // Build Heatmap Datasource
      let heatmap = this.dataStructure(columns, rows, chartData);
    
      this.setState({ itemType, heatmap, title, columns, rows, chartData });
    }
    if(this.props.OccupationLevel !== prevProps.OccupationLevel) {
      this.setState({ occupationLevel });
    }
    if(this.props.OccupationMetric !== prevProps.OccupationMetric) {
      this.setState({ occupationMetric });
    }
    if(this.props.VisibleHeatmap !== prevProps.VisibleHeatmap || this.props.VisibleGraph !== prevProps.VisibleGraph) {
      this.setState({ visibleHeatmap, visibleGraph })
    }
  }

  dataStructure(columns, rows, chartData) {
    let heatmap = {};

    heatmap.dataSource = [];
    heatmap.xAis = [];
    heatmap.yAis = [];

    if(chartData) {
      for(let x = 0; x < chartData.length; x++) {
        heatmap.dataSource.push([]);

        for(let y = 0; y < chartData[x].length; y++) {
          // Push ChartData corresponding cell (format value => Progress)
          heatmap.dataSource[x].push(chartData[x][y]);
          // heatmap.dataSource[x].push((chartData[x][y] * 100).toFixed(0));
        }
      }

      // Get Heatmap Labels
      heatmap.xAis = this.getColumnsLabel(columns);
      heatmap.yAis = this.getRowsLabel(rows);

      // Get Heatmap Values
      // heatmap.xAisValues = this.getColumnsValues(columns);
      // heatmap.yAisValues = this.getRowsValues(rows);
    }

    return heatmap;
  }

  cellRender(args) {
    const occupationMetric = this.state.occupationMetric;
    // const heatmap = this.state.heatmap;
    // const xLabel = args.xLabel;
    // const yLabel = args.yLabel;

    // console.log(args);

    // if(heatmap.xAis && heatmap.yAis) {
    //   let date, month, year, resourceId;

    //   if(heatmap.xAis.findIndex(element => element === xLabel) !== -1) {
    //     date = heatmap.xAisValues[heatmap.xAis.findIndex(element => element === xLabel)];
    //   }
      
    //   if(heatmap.yAis.findIndex(element => element === yLabel) !== -1) {
    //     resourceId = heatmap.yAisValues[heatmap.yAis.findIndex(element => element === yLabel)];
    //   }

    //   if(date && resourceId) {
    //     month = new Date(date).getMonth() + 1;
    //     year = new Date(date).getFullYear();

    //     args.displayText = month + ' ' + year + ' ' + resourceId + ' ' + args.value;
    //     // args.displayText = '<a target="_self" href={`/TimeTracking?year=${year}&month=${month}&resourceId=${resourceId}`}>' + args.value + '</a>';
    //   }
    //   else {
    //     args.displayText = args.value;
    //   }
    // }

    if(occupationMetric === 'Occupancy') {
      args.displayText = args.value + ' %';
    }
    else if(occupationMetric === 'Availability') {
      args.displayText = args.value + '';
    }
    else if(occupationMetric === 'Workload') {
      args.displayText = args.value + '';
    }
  }

  cellSelected(args) {
    let data = args.data;
    let length = data.length;

    let selectedDates = [];
    let selectedResources = [];

    for(let i = 0; i < length; i++) {
      const yAxisLength = this.heatmap.yAxis.labels.length;

      // Add selected different Dates
      if(!selectedDates.includes(this.heatmap.xAxis.labels[data[i].xPosition])) {
        selectedDates.push(this.heatmap.xAxis.labels[data[i].xPosition]);
      }
      // Add selected different Resources
      if(!selectedResources.includes(this.heatmap.yAxis.labels[yAxisLength - data[i].yPosition - 1])) {
        selectedResources.push(this.heatmap.yAxis.labels[yAxisLength - data[i].yPosition - 1]);
      }
    }

    // Call the event from the Parent component through the props
    this.props.onHeatmapSelection(selectedDates, selectedResources);

    this.setState({ selectedDates, selectedResources });
  }
  
  getColumnsLabel(columns) {
    let labels = [];

    columns.forEach(column => {
      labels.push(column.Label);
    });
    
    return labels;
  }

  getColumnsValues(columns) {
    let values = [];

    columns.forEach(column => {
      values.push(column.Name);
    });
    
    return values;
  }

  getRowsLabel(rows) {
    let labels = [];

    rows.forEach(row => {
      labels.push(row.Label);
    });

    return labels;
  }

  getRowsValues(rows) {
    let values = [];

    rows.forEach(row => {
      values.push(row.Id);
    });

    return values;
  }

  // getSelectedMonth(value) {
  //   const columns = this.state.columns;
  //   let date, month;
    
  //   if(columns.find(element => element.Label === value)) {
  //     date = columns.find(element => element.Label === value).Name;
  //   }

  //   month = new Date(date).getMonth() + 1;

  //   return month;
  // }

  // getSelectedYear(value) {
  //   const columns = this.state.columns;
  //   let date, year;
    
  //   if(columns.find(element => element.Label === value)) {
  //     date = columns.find(element => element.Label === value).Name;
  //   }

  //   year = new Date(date).getFullYear();

  //   return year;
  // }

  // getSelectedResourceId(value) {
  //   const rows = this.state.rows;
  //   let resourceId;

  //   if(rows.find(element => element.Label === value)) {
  //     resourceId = rows.find(element => element.Label === value).Id;
  //   }

  //   return resourceId;
  // }

  clearSelection() {
    let selectedDates = [];
    let selectedResources = [];
    
    // Clear Heatmap selection
    this.heatmap.clearSelection();

    // Call the event from the Parent component through the props
    this.props.onHeatmapSelection(selectedDates, selectedResources);

    this.setState({ selectedDates, selectedResources });
  }

  render() {
    let { language, itemType, heatmap, title, columns, rows, chartData, occupationLevel, occupationMetric, selectedDates, selectedResources, visibleHeatmap, visibleGraph } = this.state;
    let height, minimum;

    // Heatmap height
    if(itemType === 'Resource') {
      height = 100;
      minimum = 100;
    }
    else {
      if(visibleHeatmap && visibleGraph) {
        height = (window.innerHeight - 280) / 2;
        minimum = 100;
      }
      else if(visibleHeatmap && !visibleGraph) {
        height = window.innerHeight - 280;
        minimum = 100;
      }
    }

    if(height > minimum) {
      if(rows && rows.length > 0 && ((height - 20) / rows.length) < 20) {
        height = '100%';
      }
      else {
        height += 'px';
      }
    }
    else {
      height = minimum + 'px';
    }

    // Title Settings
    const titleSettings = { text: title, titleStyle: { size: '15px', fontWeight: '500', fontStyle: 'Normal', fontFamily: 'Segoe UI' }};

    // Cell Settings
    if(occupationMetric === 'Occupancy') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 10, color: 'white' }, format: '{value} %' };
    }
    else if(occupationMetric === 'Availability') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 10, color: 'white' }, format: '{value}' };
    }
    else if(occupationMetric === 'Workload') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 10, color: 'white' }, format: '{value}' };
    }

    // Palette Settings
    if(occupationMetric === 'Occupancy') {
      this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    }
    else if(occupationMetric === 'Availability') {
      if(occupationLevel === 'Month') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -20, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -20, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 20, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 20, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
      else if(occupationLevel === 'Week') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -5, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -5, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 5, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 5, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
      else if(occupationLevel === 'Day') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -1, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -1, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 1, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 1, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
    }
    else if(occupationMetric === 'Workload') {
      if(occupationLevel === 'Month') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 20, color: '#95B0B5' }, { value: 40, color: '#FFD589' }, { value: 60, color: '#FF9052' }] };
      }
      else if(occupationLevel === 'Week') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 5, color: '#95B0B5' }, { value: 10, color: '#FFD589' }, { value: 15, color: '#FF9052' }] };
      }
      else if(occupationLevel === 'Day') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 1, color: '#95B0B5' }, { value: 2, color: '#FFD589' }, { value: 3, color: '#FF9052' }] };
      }
    }
    // const paletteSettings = { palette: [{ value: 0, color: '#EEEEEE' }, { value: 99, color: '#B1B2BE' }, { value: 100, color: '#00C77A' }, { value: 101, color: '#F57C12' }, { value: 500, color: '#F57C12' }] };

    // Tooltip Settings
    // const tooltipSettings= {{ fill: '#265259', textStyle: { color: '#FFFFFF', size:"12px" }, border: { width:1, color:"#98BABF" } }};

    return (
      <div className="workloadHeatmap">
        {/* Heatmap Component */}
        <HeatMapComponent id="heatmap-container" height={height} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellRender={this.cellRender} cellSelected={this.cellSelected} cellSettings={this.cellSettings} paletteSettings={this.paletteSettings} legendSettings={{ visible: false }} allowSelection={true} ref={heatmap=>this.heatmap=heatmap}>
          <Inject services={[ Legend, Tooltip, Adaptor ]} />
        </HeatMapComponent>
      </div>
    )
  }
}

export default WorkloadHeatmap;