import React, {Component} from 'react';
import { Form, InputGroup, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopoverEditSetting from './PopoverEditSetting';

class FiltersColumnChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      blockType: null,
      currentView: {},
      axes: [],
      axe: null,
      keyField: null
    };
    
    // Actions
    this.changeAxe = this.changeAxe.bind(this);
    this.changeKeyField = this.changeKeyField.bind(this);

    // Template
    this.templatePopover = this.templatePopover.bind(this);
    this.templatePopoverEditAxe = this.templatePopoverEditAxe.bind(this);
    this.templatePopoverEditKeyfield = this.templatePopoverEditKeyfield.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const axes = this.props.Axes;
    let axe, keyField;

    // Get Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
    }

    // Get Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
    }
    
    this.setState({ language, blockType, currentView, axes, axe, keyField });
  }

  componentDidUpdate(prevProps) {
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const axes = this.props.Axes;
    let axe, keyField;

    if(this.props.BlockType !== prevProps.BlockType) {
      this.setState({ blockType });
    }
    if(this.props.Axes !== prevProps.Axes) {
      this.setState({ axes });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      // Get Current View Axe
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
        axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
      }

      // Get Current View KeyField
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
        keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
      }

      this.setState({ currentView, axe, keyField });
    }
  }

  changeAxe(axe) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      currentView.Parameters.find(param => param.Name === 'Axe1').Value = axe;
    }

    this.setState({ currentView, axe });

    // Call the event from the Parent component through the props with new Axe selected
    this.props.onAxeChange(axe);
  }

  changeKeyField(keyField) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      currentView.Parameters.find(param => param.Name === 'Axe2').Value = keyField;
    }

    this.setState({ currentView, keyField });
    
    // Call the event from the Parent component through the props with new KeyField selected
    this.props.onKeyFieldChange(keyField);
  }

  templatePopover(axes, axe, keyField) {
    const language = this.state.language;
    const currentView = this.state.currentView;
    let level, axesList = [], keyFieldsList = [];

    // Get View level name & corresponding icon
    switch(currentView.DefaultLevel) {
      case 0:
          level = 'Tree';
          break;
      case 1:
          level = 'Business_Line';
          break;
      case 2:
          level = 'Project';
          break;
      case 3:
          level = 'Workpackage';
          break;
      case 4:
          level = 'Action';
          break;
      case 5:
          level = 'Task';
          break;
      case 6:
          level = 'Risk';
          break;
      case 7:
          level = 'Issue';
          break;
      case 8:
          level = 'Decision';
          break;
      case 9:
          level = 'Meeting';
          break;
      case 10:
          level = 'Definition';
          // level = 'Budget';
          break;
      case 11:
          level = 'Link';
          break;
      case 12:
          level = 'Resource';
          break;
      case 13:
          level = 'Entity';
          break;
      default:
          break;
    }

    if(axes && axes.length > 0) {
      // Build Select List with available Axes
      axes.forEach(axe => {
        if(axe.ItemType === level) {
          axesList.push(axe);
        }
      });

      // Build Select List with available KeyFields
      axes.forEach(axe => {
        if(axe.ItemType === level) {
          keyFieldsList.push(axe);
        }
      });

      keyFieldsList.unshift({ Axe: 'None', ItemType: level, Label: Traduction.translate(language, 'none') });
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex flex-column">
            {/* Axis Selection */}
            <InputGroup className="axeList">
              {/* Label */}
              <Form.Label className="labelAxe">{Traduction.translate(language, 'axis')} :</Form.Label>
              {/* Value */}
              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditAxe(axe, axesList)}>
                {axesList.find(value => value.Axe === axe) && <div className="cursor">
                  <div className="selectAxe"><div className="">{axesList.find(value => value.Axe === axe).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>
                </div>}
              </OverlayTrigger>
            </InputGroup>
            {/* Keyfield Selection */}
            <InputGroup className="keyFieldList">
              {/* Label */}
              <Form.Label className="labelKeyField">{Traduction.translate(language, 'keyfield')} :</Form.Label>
              {/* Value */}
              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditKeyfield(keyField, keyFieldsList)}>
                {keyFieldsList.find(value => value.Axe === keyField) && <div className="cursor">
                  <div className="selectAxe"><div className="">{keyFieldsList.find(value => value.Axe === keyField).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>
                </div>}
              </OverlayTrigger>
            </InputGroup>
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverEditAxe(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeAxe}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverEditKeyfield(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeKeyField}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, blockType, axes, axe, keyField, currentView } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'kanban_axes')}</strong></Tooltip>}>
            <div className="filtersKanban">
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover(axes, axe, keyField)}>
                <span className="iconAxeBlue iconsFilter cursor"/>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>
        ))}
      </div>
      
    )
  }
}

export default FiltersColumnChooser;