import React, {Component} from 'react';
import { OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

const API = '/WebAppService/GetTimeTrackingYearSummary';

class TimetrackingMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      resourceId: null,
      month: null,
      year: null,
      timesheets: [],
      current_month: null,
      current_year: null,
    };

    // Data Structure
    this.getData = this.getData.bind(this);
    this.getMonthName = this.getMonthName.bind(this);

    // Actions
    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    
    // Template
    this.templatePopover = this.templatePopover.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const resourceId = this.props.ResourceId;
    const month = this.props.Month;
    const year = this.props.Year;

    const date = new Date();
    const current_month = (date.getMonth())+1;
    const current_year = date.getFullYear();

    this.setState({ login, authId, language, resourceId, month, year, current_month, current_year });

    if(login && authId && resourceId && year) {
      this.getData(login, authId, resourceId, year);
    }
  }

  componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const resourceId = this.props.ResourceId;
    const month = this.props.Month;
    const year = this.props.Year;

    const date = new Date();
    const current_month = (date.getMonth())+1;
    const current_year = date.getFullYear();

    if(this.props.ResourceId !== prevProps.ResourceId || this.props.Month !== prevProps.Month || this.props.Year !== prevProps.Year) {
      if(login && authId && resourceId && year) {
        this.getData(login, authId, resourceId, year);
      }

      this.setState({ resourceId, month, year });
    }
  }

  async getData(login, authId, resourceId, year) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ResourceId': resourceId,
        'Year': year,
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetTimeTrackingYearSummaryResult;

      if(result) {
        const timesheets = data.GetTimeTrackingYearSummaryResult;

        this.setState({ timesheets });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        if(language === 'English') {
          this.props.history.push("/Login?language=en");
        }
        else {
          this.props.history.push("/Login?language=fr");
        }
      }
      
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getMonthName(month) {
    const language = this.state.language;

    switch(month) {
      case 1:
          return Traduction.translate(language, 'january');
      case 2:
          return Traduction.translate(language, 'february');
      case 3:
          return Traduction.translate(language, 'march');
      case 4:
          return Traduction.translate(language, 'april');
      case 5:
          return Traduction.translate(language, 'may');
      case 6:
          return Traduction.translate(language, 'june');
      case 7:
          return Traduction.translate(language, 'july');
      case 8:
          return Traduction.translate(language, 'august');
      case 9:
          return Traduction.translate(language, 'september');
      case 10:
          return Traduction.translate(language, 'october');
      case 11:
          return Traduction.translate(language, 'november');
      case 12:
          return Traduction.translate(language, 'december');
      default:
          break;
    }
  }

  previousMonth(month, year) {
    let previous;

    if(month === 1) {
      previous = 12;
      year = year - 1;
    }
    else {
      previous = month - 1;
    }

    // Call the event from the Parent component through the props with month and year value
    this.props.onMonthChange(previous, year);

    // this.setState({ month: previous, year: year });
  }

  nextMonth(month, year) {
    let next;

    if(month === 12) {
      next = 1;
      year = year + 1;
    }
    else {
      next = month + 1;
    }

    // Call the event from the Parent component through the props with month and year value
    this.props.onMonthChange(next, year);

    // this.setState({ month: next, year: year });
  }

  templatePopover(timesheets) {
    const language = this.state.language;
    const resourceId = this.state.resourceId;
    const month = this.state.month;
    const year = this.state.year;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic" className="popoverTimetracking">
        <Popover.Content>
          <div className="timetrackingMonths">
            {timesheets.map((timesheet, index) => 
              <div key={index} className="inline-flex align-items-center p5">
                {/* Month Link */}
                <Link className={"timetrackingMonth " + (timesheet.Month === month ? "yellow": "black")} to={`/TimeTracking?year=${timesheet.Year}&month=${timesheet.Month}&resourceId=${resourceId}`}>{timesheet.MonthLabel}</Link>
                {/* Month Progress */}
                <div className="timetrackingProgress">
                  {(timesheet.Completion * 100) === 100 && <ProgressBar className="progressTable" variant="success" now={(timesheet.Completion * 100)}></ProgressBar>}
                  {(timesheet.Completion * 100) < 100 && <ProgressBar className="progressTable" variant="primary" now={(timesheet.Completion * 100)}></ProgressBar>}
                  {(timesheet.Completion * 100) > 100 && <ProgressBar className="progressTable" variant="danger" now={(timesheet.Completion * 100)}></ProgressBar>}
                </div>
                {/* Month Burned */}
                <div className="timetrackingBurned">
                  <span className="blue bold">{parseFloat(timesheet.BurnedWorkload.toFixed(2))}</span>
                  <span className="ml5">{Traduction.translate(language, 'burned_days')}</span>
                </div>
              </div>
            )}
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    let { language, resourceId, month, year, timesheets, current_month, current_year } = this.state;

    return (
      <div className="filterTimetrackingMonth">
        {/* Previous Month */}
        <span className="iconPreviousYellow icons cursor" onClick={(e) => this.previousMonth(month, year)}></span>
        {/* Current Month */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopover(timesheets)}>
          <div className="">
            <span className="centervertical cursor ml10 mr10">{this.getMonthName(month)}</span>
            <span className="centervertical cursor mr10">{year}</span>
          </div>
        </OverlayTrigger>
        {/* Next Month */}
        <span className="iconNextYellow icons cursor" onClick={(e) => this.nextMonth(month, year)}></span>
      </div>
    )
  }
}

export default TimetrackingMonth;