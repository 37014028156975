import React, { Component } from 'react';
import { createSpinner, showSpinner, hideSpinner, setSpinner } from '@syncfusion/ej2-popups';
import '../Css/App.css';

class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    if(this.spinner) {
      // Create Spinner
      createSpinner({ target: this.spinner });

      // Show Spinner
      showSpinner(this.spinner);

      // Set Spinner
      // setSpinner({ template: '<div style="width:100%;height:100%" class="custom-rolling"><div></div></div>'});
    }
  }

  render() {
    return (
      <div className="relative ml10">
        <div id="spinner" ref={spinner=>this.spinner=spinner}/>
      </div>
    );
  }
}

export default LoadingSpinner;