import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import App from './App';
import Login from './Blocs/Login';
import ChangePassword from './Blocs/ChangePassword';
import ResetPassword from './Blocs/ResetPassword';
import Navbar from './Blocs/Navbar';
import SearchResults from './Blocs/SearchResults';
import Environment from './Blocs/Environment';
import EnvironmentRoadmap from './Blocs/EnvironmentRoadmap';
import EnvironmentResources from './Blocs/EnvironmentResources';
import EnvironmentAgile from './Blocs/EnvironmentAgile';
import EnvironmentAxisValues from './Blocs/EnvironmentAxisValues';
import EnvironmentCustomFields from './Blocs/EnvironmentCustomFields';
import Notifications from './Blocs/Notifications';
import TimeTracking from './Blocs/TimeTracking';
import Card from './Blocs/Card';
import BlockBudget from './Blocs/BlockBudget';
import BlockBurned from './Blocs/BlockBurned';
import BlockCommunication from './Blocs/BlockCommunication';
import BlockCommunicationEdition from './Blocs/BlockCommunicationEdition';
import BlockDecisions from './Blocs/BlockDecisions';
import BlockDetails from './Blocs/BlockDetails';
import BlockEarnings from './Blocs/BlockEarnings';
import BlockIssues from './Blocs/BlockIssues';
import BlockHolidays from './Blocs/BlockHolidays';
import BlockMeetings from './Blocs/BlockMeetings';
import BlockMeteo from './Blocs/BlockMeteo';
import BlockResources from './Blocs/BlockResources';
import BlockRisks from './Blocs/BlockRisks';
import BlockRoadMap from './Blocs/BlockRoadMap';
import BlockWarnings from './Blocs/BlockWarnings';
import BlockWorkload from './Blocs/BlockWorkload';
import AdministrationUsers from './Blocs/AdministrationUsers';
import AdministrationUserGroups from './Blocs/AdministrationUserGroups';
import NotificationsCenter from './Blocs/NotificationsCenter';
import DeleteBin from './Blocs/DeleteBin';
import NotFound from './Blocs/NotFound';
import './Css/App.css';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: ''
    };
  }

  componentDidMount() {

  }

  render() {
    return (<Router>
      <div className="container-fluid">
        {/* Display Navbar except in Login Page */}
        {/* {pathname !== '/' && <Navbar></Navbar>} */}

        <Switch>
          {/* Login */}
          <Route exact path="/" component={Login} />
          <Route exact path="/Login" component={Login} />
          {/* Change Password */}
          <Route exact path="/ChangePassword" component={ChangePassword} />
          {/* Reset Password */}
          <Route exact path="/ResetPassword/:token" component={ResetPassword} />
          {/* Home */}
          <Route exact path="/Home" component={App} />
          {/* Search */}
          <Route exact path="/SearchResults" component={SearchResults} />
          {/* <Route exact path="/SearchResults/:search" component={SearchResults} /> */}
          {/* Environment */}
          <Route exact path="/Environment/Home" component={Environment} />
          <Route exact path="/Environment/Roadmap" component={EnvironmentRoadmap} />
          <Route exact path="/Environment/Resources" component={EnvironmentResources} />
          <Route exact path="/Environment/Agile" component={EnvironmentAgile} />
          <Route exact path="/Environment/AxisValues" component={EnvironmentAxisValues} />
          <Route exact path="/Environment/CustomFields" component={EnvironmentCustomFields} />
          {/* TimeTracking */}
          <Route exact path="/TimeTracking" component={TimeTracking} />
          {/* <Route path="/TimeTracking/:year/:month/:resourceId" component={TimeTracking} /> */}
          {/* Notifications */}
          <Route exact path="/Notifications" component={Notifications} />
          <Route exact path="/NotificationsCenter" component={NotificationsCenter} />
          {/* Card */}
          {/* <Route exact path="/Card/:itemType/:itemId" component={Card} /> */}
          <Route exact path="/Card/:itemType/:itemId/Home" component={Card} />
          <Route exact path="/Card/:itemType/:itemId/BudgetDetails" component={BlockBudget} />
          <Route exact path="/Card/:itemType/:itemId/Burned" component={BlockBurned} />
          <Route exact path="/Card/:itemType/:itemId/Communication" component={BlockCommunication} />
          <Route exact path="/Card/:itemType/:itemId/Communication/:configId" component={BlockCommunicationEdition} />
          <Route exact path="/Card/:itemType/:itemId/Decisions" component={BlockDecisions} />
          <Route exact path="/Card/:itemType/:itemId/Details" component={BlockDetails} />
          <Route exact path="/Card/:itemType/:itemId/Earnings" component={BlockEarnings} />
          <Route exact path="/Card/:itemType/:itemId/Issues" component={BlockIssues} />
          <Route exact path="/Card/:itemType/:itemId/Holidays" component={BlockHolidays} />
          <Route exact path="/Card/:itemType/:itemId/Meetings" component={BlockMeetings} />
          <Route exact path="/Card/:itemType/:itemId/Qualitative" component={BlockMeteo} />
          <Route exact path="/Card/:itemType/:itemId/Resources" component={BlockResources} />
          <Route exact path="/Card/:itemType/:itemId/Risks" component={BlockRisks} />
          <Route exact path="/Card/:itemType/:itemId/Roadmap" component={BlockRoadMap} />
          <Route exact path="/Card/:itemType/:itemId/Warnings" component={BlockWarnings} />
          <Route exact path="/Card/:itemType/:itemId/Workload" component={BlockWorkload} />
          {/* Administration */}
          <Route exact path="/Administration/Users" component={AdministrationUsers} />
          <Route exact path="/Administration/UserGroups" component={AdministrationUserGroups} />
          {/* Delete Bin */}
          <Route exact path="/DeleteBin" component={DeleteBin} />
          {/* Not Found */}
          <Route component={NotFound}></Route>
          {/* Redirection */}
          <Redirect from='*' to='/Home' />
        </Switch>
      </div>
    </Router>)
  }
}

// const Routes = () => (
//   <Router>
//     <div className="container-fluid ALL">
//       {/* Display Navbar except in Login Page */}
//       {/* {path !== '/' && <Navbar></Navbar>} */}
//       {/* <Navbar></Navbar> */}

//       <Switch>
//         <Route exact path="/" component={Login} />
//         <Route path="/Home" component={App} />
//         <Route path="/SearchResults" component={SearchResults} />
//         <Route path="/TimeTracking" component={TimeTracking} />
//         <Route path="/FavoritesCards" component={FavoritesCards} />
//         <Route path="/Reports" component={Reports} />
//         <Route path="/User" component={User} />
//         <Route path="/Help" component={Help} />
//         <Route path="/Card/:type/:id" component={Card} />
        
//         <Route component={NotFound}></Route>
//         <Redirect from='*' to='/Home' />
//       </Switch>
//     </div>
//   </Router>
// );

export default Routes;