import React, {Component} from 'react';
import { L10n } from '@syncfusion/ej2-base';
import { PivotViewComponent, Inject, FieldList, Toolbar, PivotChart, CalculatedField, ConditionalFormatting, NumberFormatting, ExcelExport, PDFExport } from '@syncfusion/ej2-react-pivotview';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class Pivot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      columns: [],
      rows: [],
      dataSource: [],
      visibleHeatmap: false,
      visibleGraph: false
    };

    // Data Structure
    this.created = this.created.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.getCalculatedFields = this.getCalculatedFields.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.getConditionalFormatting = this.getConditionalFormatting.bind(this);
    this.getFieldsMapping = this.getFieldsMapping.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.getFilterSettings = this.getFilterSettings.bind(this);
    this.getFormatSettings = this.getFormatSettings.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getValues = this.getValues.bind(this);
    this.getChartType = this.getChartType.bind(this);
    this.getDisplayMode = this.getDisplayMode.bind(this);
    
    // Actions
    this.enginePopulated = this.enginePopulated.bind(this);
    this.onFieldDropped = this.onFieldDropped.bind(this);
    this.toolbarRender = this.toolbarRender.bind(this);
    this.refreshPivot = this.refreshPivot.bind(this);
    this.updatePivot = this.updatePivot.bind(this);
    this.updateCurrentView = this.updateCurrentView.bind(this);
    this.exportComplete = this.exportComplete.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportPDF = this.exportPDF.bind(this);

    // Global temporary variable used to store Pivot filters
    let filters;
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const visibleHeatmap = this.props.VisibleHeatmap;
    const visibleGraph = this.props.VisibleGraph;

    // Build Pivot Datasource
    let dataSource = this.dataStructure(rows);

    // Update Pivot Datasource
    if(this.pivot) {
      this.pivot.dataSourceSettings.dataSource = dataSource;
    }

    this.setState({ authId, language, itemId, itemType, itemTitle, blockType, currentView, columns, rows, dataSource, visibleHeatmap, visibleGraph });
  }
  
  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const visibleHeatmap = this.props.VisibleHeatmap;
    const visibleGraph = this.props.VisibleGraph;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    // if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
    //   // Refresh Component Settings
    //   this.refreshPivot(currentView);

    //   this.setState({ currentView });
    // }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Build Pivot Datasource
      let dataSource = this.dataStructure(rows);

      // Update Pivot Datasource
      if(this.pivot) {
        this.pivot.dataSourceSettings.dataSource = dataSource;
      }

      // this.updatePivot(filtered, blockType);
      
      this.setState({ currentView, columns, rows, dataSource });
    }
    if(this.props.VisibleHeatmap !== prevProps.VisibleHeatmap || this.props.VisibleGraph !== prevProps.VisibleGraph) {
      this.setState({ visibleHeatmap, visibleGraph });
    }
  }

  created() {
    const currentView = this.state.currentView;
    const dataSource = this.state.dataSource;

    // Define Pivot dataSource Settings
    if(this.pivot) {      
      this.pivot.dataSourceSettings = {
        calculatedFieldSettings: this.getCalculatedFields(currentView),
        columns: this.getColumns(currentView),
        conditionalFormatSettings: this.getConditionalFormatting(currentView),
        dataSource: dataSource,
        expandAll: false,
        fieldMapping: this.getFieldsMapping(),
        // filters: this.getFilters(currentView),
        // filterSettings: this.getFilterSettings(currentView),
        formatSettings: this.getFormatSettings(currentView),
        rows: this.getRows(currentView),
        values: this.getValues(currentView),
      };

      // this.pivot.chartSettings.chartSeries.type = this.getChartType(currentView);
      // this.pivot.displayOption.primary = this.getDisplayMode(currentView);
      
      // this.pivot.refresh();
    }
  }

  dataStructure(rows) {
    const language = Authentication.getCookie('language');
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Progress
          // else if(item.ColumnName === 'Progress') {
          //   acc[item.ColumnName] = (item.Value * 100).toFixed(0);
          // }
          // Format Timeline
          else if(item.ColumnName === 'Timeline') {
            let status, endDate;

            if(row.Cells.find(item => item.ColumnName === 'Status')) {
              status = row.Cells.find(item => item.ColumnName === 'Status').Value;
            }
            if(row.Cells.find(item => item.ColumnName === 'EndDate')) {
              endDate = row.Cells.find(item => item.ColumnName === 'EndDate').Value;
            }

            // Timeline
            if((status === 'Completed' || status === 'Terminé') || (status === 'Cancelled' || status === 'Annulé') || (status === 'On Hold' || status === 'Mis en attente')) {
              acc['Timeline'] = status;
            }
            else {
              if(new Date(endDate) < new Date()) {
                acc['Timeline'] = Traduction.translate(language, 'delayed');
              }
              else {
                acc['Timeline'] = status;
              }
            }
          }
          // Meteo_Freshness && Data_Freshness
          else if(item.ColumnName === 'Meteo_Freshness' || item.ColumnName === 'Data_Freshness') {
            let columnName = item.ColumnName;
            
            if(item.Value === "1") {
              acc[columnName] = Traduction.translate(language, 'week');
            }
            else if(item.Value === "2") {
              acc[columnName] = Traduction.translate(language, 'month');
            }
            else if(item.Value === "3") {
              acc[columnName] = Traduction.translate(language, 'over_a_month');
            }
            else if(item.Value === "4") {
              acc[columnName] = Traduction.translate(language, 'not_required');
            }
            else {
              acc[columnName] = "";
            }
          }
          // Rating
          else if(item.FieldType === 'Rating') {
            if(!item.Value) {
              acc[item.ColumnName] = "0";
            }
          }
          else {
            // Add the Column Name/Values to the reduced Table
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      })
    }

    return dataSource;
  }

  dataBound(args) {
    const currentView = this.state.currentView;
    let conditionalFormatSettings = [];
    let conditionalFormattingSettings = [];

    if(this.pivot) {
      // Get Pivot Chart Type
      const chartType = this.pivot.chartSettings.chartSeries.type;

      // Get Pivot Display Mode
      const displayMode = this.pivot.displayOption.primary;

      // Update Current View Chart Type
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ChartType')) {
        currentView.Parameters.find(param => param.Name === 'ChartType').Value = chartType;
      }

      // Update Current View Display Mode
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Mode')) {
        currentView.Parameters.find(param => param.Name === 'Mode').Value = displayMode;
      }

      // Update Pivot Conditional Formatting
      conditionalFormatSettings = JSON.parse(this.pivot.getPersistData()).dataSourceSettings.conditionalFormatSettings;

      conditionalFormatSettings.forEach(format => {
        if(currentView.ConditionalFormatSettings) {
          // Get Pivot Current View Format
          conditionalFormattingSettings.push({ Conditions: format.conditions, Value1: format.value1, Value2: format.value2, ApplyGrandTotals: format.applyGrandTotals, BackGroundColor: format.style.backgroundColor, Color: format.style.color, FontFamily: format.style.fontFamily, FontSize: format.style.fontSize, ViewId: currentView.ViewId });

          // Update Current View Format list
          currentView.ConditionalFormatSettings = conditionalFormattingSettings;
        }
      });

      // Call the event from the Parent component through the props with view value
      this.props.onViewChange(currentView);
    }
  }

  getCalculatedFields(currentView) {
    let calculatedFieldView;
    let calculatedFieldSettings = [];

    if(currentView.CalculatedFields.length > 0) {
      calculatedFieldView = currentView.CalculatedFields;

      calculatedFieldView.forEach(field => {
        calculatedFieldSettings.push({ name: field.Name, formula: field.Formula });
      });
    }

    return calculatedFieldSettings;
  }

  getColumnHeaders() {
    return this.state.columns;
  }

  getColumnLabel(columnName) {
    let columns = this.state.columns;
    let label = '';

    if(columns.find(column => column.FieldName === columnName)) {
      label = columns.find(column => column.FieldName === columnName).Label;
    }
    
    return label;
  }

  getColumns(currentView) {
    let columnsView, columnHeader;
    let columns = [];
    let caption = "";

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      if(currentView.Parameters.find(param => param.Name === 'Columns').Value !== '') {
        columnsView = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');

        columnsView.forEach(column => {
          // Get Column corresponding Column Header
          if(columnHeaders.find(colHeader => colHeader.FieldName === column)) {
            columnHeader = columnHeaders.find(colHeader => colHeader.FieldName === column);
            caption = columnHeader.Label;
          }
  
          columns.push({ name: column, caption: caption });
        });
      }
    }

    return columns;
  }

  getConditionalFormatting(currentView) {
    let conditionalFormatView;
    let conditionalFormatSettings = [];

    if(currentView.ConditionalFormatSettings.length > 0) {
      conditionalFormatView = currentView.ConditionalFormatSettings;

      conditionalFormatView.forEach(format => {
        conditionalFormatSettings.push({ conditions: format.Conditions, value1: format.Value1, value2: format.Value2, applyGrandTotals: format.ApplyGrandTotals, style: { backgroundColor: format.BackGroundColor, color: format.Color, fontFamily: format.FontFamily, fontSize: format.FontSize } });
      });
    }

    return conditionalFormatSettings;
  }

  getFieldsMapping() {
    let fieldsMapping = [];

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    columnHeaders.forEach(column => {
      fieldsMapping.push({ name: column.FieldName, caption: column.Label });
    });

    return fieldsMapping;
  }

  getFilters(currentView) {
    let filtersView, filterHeader;
    let filters = [];
    let caption = "";

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    if(currentView.Filters.length > 0) {
      filtersView = currentView.Filters;

      filtersView.forEach(filter => {
        // Get Filter corresponding Column Header
        if(columnHeaders.find(colHeader => colHeader.FieldName === filter.Field)) {
          filterHeader = columnHeaders.find(colHeader => colHeader.FieldName === filter.Field);
          caption = filterHeader.Label;
        }
        
        filters.push({ name: filter.Field, caption: caption });
      });
    }

    return filters;
  }

  getFilterSettings(currentView) {
    let filtersView;
    let filterSettings = [];

    if(currentView.Filters.length > 0) {
      filtersView = currentView.Filters;

      filtersView.forEach(filter => {
        filterSettings.push({ name: filter.Field, type: filter.Type, items: filter.Items.split(',') });
      });
    }

    return filterSettings;
  }

  getFormatSettings(currentView) {
    let formatView;
    let formatSettings = [];

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    columnHeaders.forEach(column => {
      if(column.FieldType === 'Date') {
        formatSettings.push({ name: column.FieldName, type: 'date', format: 'dd/MM/yyyy' });
      }
      else if(column.FieldType === 'Double') {
        formatSettings.push({ name: column.FieldName, format: 'N2' });
      }
    });

    if(currentView.FormatSettings.length > 0) {
      formatView = currentView.FormatSettings;

      formatView.forEach(format => {
        if(formatSettings.findIndex(element => element.name === format.Name) < 0) {
          formatSettings.push({ name: format.Name, format: format.Format, useGrouping: format.UseGrouping });
        }
      });
    }

    return formatSettings;
  }

  getRows(currentView) {
    let rowsView, rowHeader;
    let rows = [];
    let caption = "";

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
      if(currentView.Parameters.find(param => param.Name === 'Rows').Value !== '') {
        rowsView = (currentView.Parameters.find(param => param.Name === 'Rows').Value).split(',');

        rowsView.forEach(row => {
          // Get Row corresponding Column Header
          if(columnHeaders.find(colHeader => colHeader.FieldName === row)) {
            rowHeader = columnHeaders.find(colHeader => colHeader.FieldName === row);
            caption = rowHeader.Label;
          }
  
          rows.push({ name: row, caption: caption });
        });
      }
    }

    return rows;
  }

  getValues(currentView) {
    let valuesView, valueHeader;
    let values = [];
    let caption;

    // Get Columns Headers
    const columnHeaders = this.getColumnHeaders();

    if(currentView.Values.length > 0) {
      valuesView = currentView.Values;

      valuesView.forEach(value => {
        // Get Value corresponding Column Header
        if(columnHeaders.find(colHeader => colHeader.FieldName === value.Field)) {
          valueHeader = columnHeaders.find(colHeader => colHeader.FieldName === value.Field);
          caption = valueHeader.Label;
        }
        else {
          caption = value.Field;
        }
        
        values.push({ name: value.Field, type: value.AggregationType, caption: caption });
      });
    }

    return values;
  }

  getChartType(currentView) {
    let chartType;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ChartType')) {
      chartType = currentView.Parameters.find(param => param.Name === 'ChartType').Value;
    }

    return chartType;
  }

  getDisplayMode(currentView) {
    let displayMode;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Mode')) {
      displayMode = currentView.Parameters.find(param => param.Name === 'Mode').Value;
    }

    return displayMode;
  }

  enginePopulated(args) {
    const dataSourceSettings = args.dataSourceSettings;

    // Remove Filter Settings of the dropped Field
    // if(this.filters) {
    //   dataSourceSettings.filterSettings = this.filters;

    //   this.filters = undefined;
    // }

    // Update Current View with new Pivot Settings
    this.updateCurrentView(dataSourceSettings);
  }

  // Get the dropped Field information
  onFieldDropped(args) {
    // console.log(args);
  }

  toolbarRender(args) {
    args.customToolbar.splice(6, 0, {
      type: 'Separator'
    });
    args.customToolbar.splice(9, 0, {
      type: 'Separator'
    });
  }

  refreshPivot(currentView) {
    const rows = this.state.rows;
    const dataSource = this.dataStructure(rows);

    // Define Pivot dataSource Settings
    if(this.pivot) {
      this.pivot.dataSourceSettings = {
        calculatedFieldSettings: this.getCalculatedFields(currentView),
        columns: this.getColumns(currentView),
        conditionalFormatSettings: this.getConditionalFormatting(currentView),
        dataSource: dataSource,
        expandAll: false,
        fieldMapping: this.getFieldsMapping(),
        // filters: this.getFilters(currentView),
        // filterSettings: this.getFilterSettings(currentView),
        formatSettings: this.getFormatSettings(currentView),
        rows: this.getRows(currentView),
        values: this.getValues(currentView),
      };

      // if(this.getDisplayMode(currentView) === 'Table') {
      //   // this.pivot.currentView = 'Table';
      //   this.pivot.displayOption.primary = 'Table';
      //   // this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart').style.display = 'none';
      //   // this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid').style.display = 'block';

      //   if(this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart')) {
      //     this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart').style.display = 'none';
      //   }
      //   if(this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid')) {
      //     this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid').style.display = 'block';
      //   }
      // }
      // else if(this.getDisplayMode(currentView) === 'Chart') {
      //   // this.pivot.currentView = 'Chart';
      //   this.pivot.displayOption.primary = 'Chart';
      //   // this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid').style.display = 'none';
      //   // this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart').style.display = 'block';

      //   if(this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid')) {
      //     this.pivot.element.querySelector('#' + this.pivot.element.id + '_grid').style.display = 'none';
      //   }
      //   if(this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart')) {
      //     this.pivot.element.querySelector('#' + this.pivot.element.id + '_chart').style.display = 'block';
      //   }
      // }

      this.pivot.chartSettings.chartSeries.type = this.getChartType(currentView);
      this.pivot.displayOption.primary = this.getDisplayMode(currentView);
      // this.pivot.displayOption.view = this.getDisplayMode(currentView);
      
      this.pivot.refresh();
    }
  }

  updatePivot(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      // Build Pivot Datasource
      dataSource = this.dataStructure(rows);

      // Update Pivot Datasource
      this.pivot.dataSourceSettings.dataSource = dataSource;
    }
  }

  updateCurrentView(dataSourceSettings) {
    const currentView = this.props.CurrentView;

    let calculatedFieldSettings = [];
    // let conditionalFormatSettings = [];
    let columns = '';
    let filterSettings = [];
    let formatSettings = [];
    let rows = '';
    let values = [];

    if(this.pivot.dataSourceSettings) {
      // Calculated Fields
      if(currentView.CalculatedFields) {
        if(this.pivot.dataSourceSettings.calculatedFieldSettings.length > 0) {
          this.pivot.dataSourceSettings.calculatedFieldSettings.forEach(field => {
            // Get Pivot Current View Format
            calculatedFieldSettings.push({ Name: field.name, Formula: field.formula, ViewId: currentView.ViewId });
          });
        }

        // Update Current View Format list
        currentView.CalculatedFields = calculatedFieldSettings;
      }

      // ConditionalFormatSettings
      // dataSourceSettings.conditionalFormatSettings.forEach(format => {
      //   if(currentView.ConditionalFormatSettings) {
      //     // Get Pivot Current View Format
      //     conditionalFormatSettings.push({ Conditions: format.conditions, Value1: format.value1, Value2: format.value2, ApplyGrandTotals: format.applyGrandTotals, BackGroundColor: format.style.backgroundColor, Color: format.style.color, FontFamily: format.style.fontFamily, FontSize: format.style.fontSize, ViewId: currentView.ViewId });

      //     // Update Current View Format list
      //     currentView.ConditionalFormatSettings = conditionalFormatSettings;
      //   }
      // });

      // Columns
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        if(this.pivot.dataSourceSettings.columns.length > 0) {
          // Loop on Pivot Columns list
          this.pivot.dataSourceSettings.columns.forEach((column, index) => {  
            // Get Pivot Current View Columns
            if(index < this.pivot.dataSourceSettings.columns.length - 1) {
              columns = columns.concat(column.name, ',');
            }
            else {
              columns = columns.concat(column.name);
            }
          });
        }

        // Update Current View Columns list
        currentView.Parameters.find(param => param.Name === 'Columns').Value = columns;
      }

      // Filter Settings
      // if(currentView.Filters) {
      //   if(this.pivot.dataSourceSettings.filters.length > 0) {
      //     this.pivot.dataSourceSettings.filters.forEach(filter => {
      //       // Check corresponding Filter Settings
      //       if(this.pivot.dataSourceSettings.filterSettings.find(filterSetting => filterSetting.name === filter.name)) {
      //         let filterSetting = this.pivot.dataSourceSettings.filterSettings.find(filterSetting => filterSetting.name === filter.name);

      //         // Get Pivot Current View Filters
      //         filterSettings.push({ Field: filterSetting.name, Type: filterSetting.type, Items: filterSetting.items.join(','), ViewFilterId: -1, ViewId: currentView.ViewId });
      //       }
      //     });
      //   }

      //   // if(dataSourceSettings.filterSettings.length > 0) {
      //   //   dataSourceSettings.filterSettings.forEach(filter => {
      //   //     // Get Pivot Current View Filters
      //   //     filterSettings.push({ Field: filter.name, Type: filter.type, Items: filter.items.join(','), ViewFilterId: -1, ViewId: currentView.ViewId });
      //   //   });
      //   // }

      //   // Update Current View Filters list
      //   currentView.Filters = filterSettings;
      // }

      // Format Settings
      if(currentView.FormatSettings) {
        if(this.pivot.dataSourceSettings.formatSettings.length > 0) {
          this.pivot.dataSourceSettings.formatSettings.forEach(format => {
            // Get Pivot Current View Format
            formatSettings.push({ Name: format.name, Format: format.format, UseGrouping: format.useGrouping, ViewId: currentView.ViewId });
          });
        }

        // Update Current View Format list
        currentView.FormatSettings = formatSettings;
      }
      
      // Rows
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
        if(this.pivot.dataSourceSettings.rows.length > 0) {
          // Loop on Pivot Rows list
          this.pivot.dataSourceSettings.rows.forEach((row, index) => {
            // Get Pivot Current View Rows
            if(index < this.pivot.dataSourceSettings.rows.length - 1) {
              rows = rows.concat(row.name, ',');
            }
            else {
              rows = rows.concat(row.name);
            }
          });
        }

        // Update Current View Rows list
        currentView.Parameters.find(param => param.Name === 'Rows').Value = rows;
      }

      // Values
      if(currentView.Values) {
        if(this.pivot.dataSourceSettings.values.length > 0) {
          // Loop on Pivot Values list
          this.pivot.dataSourceSettings.values.forEach(value => {
            // Get Pivot Current View Values
            values.push({ AggregationType: value.type, Field: value.name, ViewId: currentView.ViewId });
          });
        }

        // Update Current View Values list
        currentView.Values = values;
      }
    }

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  exportComplete(args) {
    
  }

  exportCSV() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.pivot) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.pivot.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.pivot) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };
      
      this.pivot.excelExport(excelExportProperties);
    }
  }

  exportPDF() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".pdf";

    if(this.pivot) {
      // Define Export File Name
      let pdfExportProperties = { fileName: fileName };

      this.pivot.pdfExport(pdfExportProperties);
    }
  }

  render() {
    let { language, itemId, itemType, blockType, currentView, columns, rows, dataSource, visibleGraph, visibleHeatmap } = this.state;
    let height, minimum;

    // Get Current View Chart Type
    let chartType = this.getChartType(currentView);

    // Get Current View Display Mode
    let displayMode = this.getDisplayMode(currentView);

    // Pivot height
    if(blockType === 'Roadmap' || blockType === 'Decisions' || blockType === 'Issues' || blockType === 'Meetings' || blockType === 'Risks' || blockType === 'BudgetDetails' || blockType === 'Earnings' || blockType === 'Warnings') {
      height = (window.innerHeight - 140);
      minimum = 100;
    }
    else if(itemType === 'Resource') {
      height = (window.innerHeight - 270);
      minimum = 100;
    }
    else if(blockType === 'Burned' || blockType === 'Workload') {
      if(visibleGraph && visibleHeatmap) {
        height = (window.innerHeight - 140) / 2;
        minimum = 100;
      }
      else if(visibleGraph && !visibleHeatmap) {
        height = window.innerHeight - 140;
        minimum = 100;
      }
    }
    else {
      height = (window.innerHeight - 140) / 2;
      minimum = 100;
    }

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Display Options
    const displayOption = { view: 'Both', primary: displayMode };

    // Palette
    const palette = ['#0078A2','#0098B2','#00B7AF','#47D29B','#A1E981','#F9F871','#58ACD9','#CBF7FF','#EC9929','#6372B4','#896BB0','#AA62A3','#C35B8E','#8D7257','#374955','#9AAEBC','#009682','#EEE8A9','#3B867B'];

    // Chart Settings
    this.chartSettings = { chartSeries: { dataLabel: { visible: true, position: "Outside" }, type: chartType }, palettes: palette, enableSmartLabels: true };
    
    // Toolbar Options
    const toolbarOptions = ['Grid', 'Chart', 'FieldList', 'Export', 'Formatting', 'SubTotal', 'GrandTotal'];

    return (
      <div className="pivot">
        {dataSource.length > 0 && 
          <PivotViewComponent id='PivotView' locale={Traduction.translate(language, 'locale')} height={height} dataSourceSettings={this.dataSourceSettings} chartSettings={this.chartSettings} displayOption={displayOption} created={this.created} dataBound={this.dataBound} enginePopulated={this.enginePopulated} onFieldDropped={this.onFieldDropped} exportComplete={this.exportComplete} toolbar={toolbarOptions} toolbarRender={this.toolbarRender} showToolbar={true} showFieldList={true} allowCalculatedField={true} allowConditionalFormatting={true} allowNumberFormatting={true} allowDeferLayoutUpdate={true} allowExcelExport={true} allowPdfExport={true} ref={pivot=>this.pivot=pivot}>
            <Inject services={[ FieldList, Toolbar, PivotChart, CalculatedField, ConditionalFormatting, NumberFormatting, ExcelExport, PDFExport ]} /> 
          </PivotViewComponent>
        }
      </div>
    );
  }
}

export default Pivot;