import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import '../Css/App.css';

class PopupQRCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemTitle: null,
      url: null
    };

    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const itemTitle = this.props.ItemTitle;
    const url = this.props.URL;

    this.setState({ itemTitle, url });
  }

  componentDidUpdate(prevProps) {
    const itemTitle = this.props.ItemTitle;
    const url = this.props.URL;

    if(this.props.ItemTitle !== prevProps.ItemTitle) {
      this.setState({ itemTitle });
    }
    if(this.props.URL !== prevProps.URL) {
      this.setState({ url });
    }
  }

  copyToClipBoard(url) {
    navigator.clipboard.writeText(url);
  }

  closePopup() {
    // Call the event from the Parent component through the props
    this.props.onPopupClose();
  }
  
  render() {
    const { itemTitle, url } = this.state;

    return (
      <div className="qrCodePopup">
        <div className="qrCodeInnerPopup">
          <div className="flex flex-column">
            <div className="inline-flex mb20">
              <div className="flex-start width100p mr20">
                {/* Label */}
                <div className="qrCodeLabel">{url}</div>
                {/* Copy to ClipBoard */}
                <div className="qrCodeCopy" onClick={() => this.copyToClipBoard(url)}>
                  <div className="iconCopy icons cursor"></div>
                </div>
              </div>

              {/* Close Popup */}
              <div className="flex-end">
                <div className="iconClear icons cursor" onClick={() => this.closePopup()}></div>
              </div>
            </div>

            {url && <div className="qrCode">
              <QRCode value={url} style={{ height: "100%", width: "100%" }} viewBox={`0 0 256 256`}/>
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

export default PopupQRCode;