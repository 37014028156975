import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class MiniDoughnut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      columns: [],
      rows: [],
      budget: 0,
      burned: 0,
      workload: 0,
      burnedPercentage: 0,
      budgetUnit: { Value: "€", Label: "€" }
    };

    // Data Structure
    this.buildBudgetDetails = this.buildBudgetDetails.bind(this);
    this.buildBudgetData = this.buildBudgetData.bind(this);

    // Actions
    this.refreshDoughnut = this.refreshDoughnut.bind(this);

    // Template
    this.templateBudgetIndicators = this.templateBudgetIndicators.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Budget Doughnut
    this.buildBudgetData(currentView, rows);

    this.setState({ language, currentView, columns, rows });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(JSON.stringify(this.props.CurrentView) != JSON.stringify(prevProps.CurrentView) || JSON.stringify(this.props.Columns) != JSON.stringify(prevProps.Columns) || JSON.stringify(this.props.Rows) != JSON.stringify(prevProps.Rows)) {
      // Build Budget Doughnut
      this.buildBudgetData(currentView, rows);

      this.setState({ currentView, columns, rows });
    }
  }

  // Build Doughnut Data based on Budget Table
  buildBudgetData(currentView, rows) {
    let burned = 0;
    let workload = 0;
    let budget = 0;
    let burnedPercentage = 0;
    let budgetUnit = { Value: "€", Label: "€" };

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DoughtnutBudgetUnit')) {
      budgetUnit = currentView.Parameters.find(param => param.Name === 'DoughtnutBudgetUnit');
    }

    // Budget Unit Euro
    if(budgetUnit.Value === "€") {
      rows.forEach(row => {
        if(row.Cells) {
          // Budget
          if(row.Cells.find(cell => cell.ColumnName === "Actual_Amount")) {
            if(row.Cells.find(cell => cell.ColumnName === "Actual_Amount").Value) {
              // Sum Values of all Rows
              budget = budget + parseInt(row.Cells.find(cell => cell.ColumnName === "Actual_Amount").Value);
            }
          }
          // Burned
          if(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost")) {
            if(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost").Value) {
              burned = burned + parseInt(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost").Value);
            }
          }
          // Workload
          if(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost")) {
            if(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost").Value) {
              workload = workload + parseInt(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost").Value);
            }
          }
        }
      });
    }
    // Budget Unit Man Days
    else if(budgetUnit.Value === "Mandays") {
      // Sum Values of all Rows
      rows.forEach(row => {
        if(row.Cells) {
          // Budget
          if(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays")) {
            if(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays").Value) {
              // Sum Values of all Rows
              budget = budget + parseInt(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays").Value);
            }
          }
          // Burned
          if(row.Cells.find(cell => cell.ColumnName === "Burned_Workload")) {
            if(row.Cells.find(cell => cell.ColumnName === "Burned_Workload").Value) {
              burned = burned + parseInt(row.Cells.find(cell => cell.ColumnName === "Burned_Workload").Value);
            }
          }
          // Workload
          if(row.Cells.find(cell => cell.ColumnName === "Workload")) {
            if(row.Cells.find(cell => cell.ColumnName === "Workload").Value) {
              workload = workload + parseInt(row.Cells.find(cell => cell.ColumnName === "Workload").Value);
            }
          }
        }
      });
    }

    // Burned percentage calcul
    if(burned != 0 && budget != 0) {
      burnedPercentage = Math.round(burned * 100 / budget);
    }
    
    this.setState({ burned, budget, workload, burnedPercentage, budgetUnit });
  }

  buildBudgetDetails() {
    const { language, columns, budget, burned, workload, budgetUnit } = this.state;
    let budgetValue, workloadValue, burnedValue, unit, burnedUnit, workloadUnit, overrunBurned, overrunWorkload;
    let labelBudget, labelBurned, labelWorkload, labelOverrun;

    // Budget Unit Euro
    if(budgetUnit.Value === "€") {
      // Budget
      if(columns.find(element => element.FieldName === 'Actual_Amount')) {
        unit = columns.find(element => element.FieldName === 'Actual_Amount').Unit;
        labelBudget = columns.find(element => element.FieldName === 'Actual_Amount').Label;
  
        if((budget / 1000) < 1000) {
          budgetValue = parseFloat((budget / 1000).toFixed(2)) + ' k' + unit;
        }
        else {
          budgetValue = parseFloat((budget / 1000000).toFixed(2)) + ' M' + unit;
        }
      }
      // Burned
      if(columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost')) {
        burnedUnit = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Unit;
        labelBurned = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Label;
        
        if((burned / 1000) < 1000) {
          burnedValue = parseFloat((burned / 1000).toFixed(2)) + ' k' + burnedUnit;
        }
        else {
          burnedValue = parseFloat((burned / 1000000).toFixed(2)) + ' M' + burnedUnit;
        }
      }
      // Workload
      if(columns.find(element => element.FieldName === 'Global_Workload_Cost')) {
        workloadUnit = columns.find(element => element.FieldName === 'Global_Workload_Cost').Unit;
        labelWorkload = columns.find(element => element.FieldName === 'Global_Workload_Cost').Label;
  
        if((workload / 1000) < 1000) {
          workloadValue = parseFloat((workload / 1000).toFixed(2)) + ' k' + workloadUnit;
        }
        else {
          workloadValue = parseFloat((workload / 1000000).toFixed(2)) + ' M' + workloadUnit;
        }
      }
    }
    // Budget Unit Man Days
    else if(budgetUnit.Value === "Mandays") {
      // Budget
      if(columns.find(element => element.FieldName === 'Actual_ManDays')) {
        unit = columns.find(element => element.FieldName === 'Actual_ManDays').Unit;
        labelBudget = columns.find(element => element.FieldName === 'Actual_ManDays').Label;
  
        if((budget / 1000) < 1000) {
          budgetValue = parseFloat((budget / 1000).toFixed(2)) + ' k' + unit;
        }
        else {
          budgetValue = parseFloat((budget / 1000000).toFixed(2)) + ' M' + unit;
        }
      }
      // Burned
      if(columns.find(element => element.FieldName === 'Burned_Workload')) {
        burnedUnit = columns.find(element => element.FieldName === 'Burned_Workload').Unit;
        labelBurned = columns.find(element => element.FieldName === 'Burned_Workload').Label;
        
        if((burned / 1000) < 1000) {
          burnedValue = parseFloat((burned / 1000).toFixed(2)) + ' k' + burnedUnit;
        }
        else {
          burnedValue = parseFloat((burned / 1000000).toFixed(2)) + ' M' + burnedUnit;
        }
      }
      // Workload
      if(columns.find(element => element.FieldName === 'Workload')) {
        workloadUnit = columns.find(element => element.FieldName === 'Workload').Unit;
        labelWorkload = columns.find(element => element.FieldName === 'Workload').Label;
  
        if((workload / 1000) < 1000) {
          workloadValue = parseFloat((workload / 1000).toFixed(2)) + ' k' + workloadUnit;
        }
        else {
          workloadValue = parseFloat((workload / 1000000).toFixed(2)) + ' M' + workloadUnit;
        }
      }
    }

    // Overrun
    labelOverrun = Traduction.translate(language, 'overrun');

    if(burned > budget) {
      overrunBurned = true;

      if(workload > budget){
        overrunWorkload = true;
      } 
      else {
        overrunWorkload = false;
      }  
    }
    else {
      overrunBurned = false;

      if(workload > budget) {
        overrunWorkload = true;
      }
      else {
        overrunWorkload = false;
      }
    }
    
    return this.templateBudgetIndicators(budgetValue, labelBudget, burnedValue, labelBurned, workloadValue, labelWorkload, overrunBurned, overrunWorkload, labelOverrun);
  }

  refreshDoughnut(currentView, columns, rows) {
    // Build Budget Doughnut
    this.buildBudgetData(currentView, rows);
  }

  templateBudgetIndicators(budget, labelBudget, burned, labelBurned, workload, labelWorkload, overrunBurned, overrunWorkload, labelOverrun) {
    return (<div className="miniblockBudgetIndicators">
      {/* Budget */}
      <div className="miniblockBudgetDetailsElement budgetDetailsBudgetBorder">
        <div className="miniblockBudgetIndicatorValue">{budget}</div>
        <div className="miniblockBudgetIndicatorLabel">{labelBudget}</div>
      </div>
      {/* Burned */}
      <div className={"miniblockBudgetDetailsElement" + (overrunBurned ? " budgetDetailsOverBurnedBorder" : " budgetDetailsBurnedBorder")}>
        <div className="miniblockBudgetIndicatorValue">{burned}</div>
        <div className="miniblockBudgetIndicatorLabel">{labelBurned}</div>
        {overrunBurned && <div className="axe brd-radius bg-red white mt3">{labelOverrun}</div>}
      </div>
      {/* Workload */}
      <div className={"miniblockBudgetDetailsElement" + (overrunWorkload ? " budgetDetailsOverWorkloadBorder" : " budgetDetailsWorkloadBorder")}>
        <div className="miniblockBudgetIndicatorValue">{workload}</div>
        <div className="miniblockBudgetIndicatorLabel">{labelWorkload}</div>
        {overrunWorkload && <div className="axe brd-radius bg-red white mt3">{labelOverrun}</div>}
      </div>
    </div>);
    // return <div className="">{" burned " + burned + " Buddget " + budget + "workload :" + workload }</div>;
  }

  render() {
    const { language, currentView, columns, rows, budget, burned, workload, burnedPercentage, budgetUnit } = this.state;
    let labelBurned;

    // Label Burned
    if(budgetUnit.Value === "€") {
      if(columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost')) {
        labelBurned = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Label;
      }
    }
    else if(budgetUnit.Value === "Mandays") {
      if(columns.find(element => element.FieldName === 'Burned_Workload')) {
        labelBurned = columns.find(element => element.FieldName === 'Burned_Workload').Label;
      }
    }

    // Doughnut Component
    Chart.register(ArcElement);

    // Doughnut Options
    this.doughnutOptions = { legend: { display: false, position: "right" }, layout: { padding: 10 }, elements: { arc:{ borderWidth: 0 }} };

    // Doughnut ChartColor
    if(burned > budget) {
      this.colors = ["#E21313"];
    }
    else {
      if(workload > budget) {
        this.colors = ["#336699", "#E21313"];
      }
      else {
        this.colors = ["#336699", "#41B2E7", '#D5D5D7'];
      }
    }

    // Doughnut Data
    if(burned > budget) {
      this.data = { datasets: [{ data: [100], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: false };
    }
    else {
      if(workload > budget) {
        this.data = { datasets: [{ data: [burned, workload-burned], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: true };
      }
      else {
        this.data = { datasets: [{ data: [burned, workload-burned, budget-workload], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: true };
      }
    }

    return(
      <div className="miniblockBudgetDoughnut">
        <div className="chart-container">
          {/* Doughnut */}
          <Doughnut id='budgetDoughnut' data={this.data} options={this.doughnutOptions}></Doughnut>
          {/* Labels */}
          <div className="miniblockBudgetDoughnutValue">
            <div className="miniblockBudgetDoughnutPercentage">{burnedPercentage + " %"}</div>
            {/* <div className="miniblockBudgetDoughnutUnit">{labelBurned}</div> */}
          </div>
        </div>
        {/* Budget Details */}
        <div className="miniblockBudgetDetails">{this.buildBudgetDetails()}</div>
      </div>
    )
  }
}

export default MiniDoughnut;