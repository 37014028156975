import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      right: null,
      currentValue: null,
      values: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const right = this.props.Right;
    const currentValue = this.props.CurrentValue;
    const values = this.props.Values;

    this.setState({ language, staticData, itemId, itemType, right, currentValue, values });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const right = this.props.Right;
    const currentValue = this.props.CurrentValue;
    const values = this.props.Values;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  updateValue(right, value) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    // Call the event from the Parent component through the props
    this.props.onValueEdit(right, value);
  }

  render() {
    const { language, staticData, itemId, itemType, right, currentValue, values, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        {/* <div className="axeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
          </Form.Group>
        </div> */}
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Value */}
          {currentValue && <div className="rightsSelect">
            {currentValue === 'Admin' && <div className="rightsAdmin">{Traduction.translate(language, 'rights_admin')}</div>}
            {currentValue === 'Modify' && <div className="rightsModify">{Traduction.translate(language, 'rights_modify')}</div>}
            {currentValue === 'View' && <div className="rightsView">{Traduction.translate(language, 'rights_view')}</div>}
            {currentValue === 'None' && <div className="rightsNone">{Traduction.translate(language, 'rights_no_access')}</div>}
          </div>}
          {/* Border */}
          <div className="popoverBorder"></div>
          {/* Other Values */}
          {values.map((value, index) => {
            if(value.Name !== currentValue) {
              // if(search === "" || value.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="rightsSelect mv5" onClick={() => this.updateValue(right, value.Name)}>
                  {value.Name === 'Admin' && <div className="rightsAdmin">{value.Label}</div>}
                  {value.Name === 'Modify' && <div className="rightsModify">{value.Label}</div>}
                  {value.Name === 'View' && <div className="rightsView">{value.Label}</div>}
                  {value.Name === 'None' && <div className="rightsNone">{value.Label}</div>}
                </div>;
              // }
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditValue;