import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniBlockDetails from './MiniBlockDetails';
import MiniBlockRoadMap from './MiniBlockRoadMap';
import MiniBlockBudget from './MiniBlockBudget';
import MiniBlockBurned from './MiniBlockBurned';
import MiniBlockCommunication from './MiniBlockCommunication';
import MiniBlockDecisions from './MiniBlockDecisions';
import MiniBlockEarnings from './MiniBlockEarnings';
import MiniBlockIssues from './MiniBlockIssues';
import MiniBlockHolidays from './MiniBlockHolidays';
import MiniBlockMeetings from './MiniBlockMeetings';
import MiniBlockMeteo from './MiniBlockMeteo';
import MiniBlockResources from './MiniBlockResources';
import MiniBlockRisks from './MiniBlockRisks';
import MiniBlockWorkload from './MiniBlockWorkload';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_available_miniblocks = '/WebAppService/GetAvailableMiniBlocks';

class MiniDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      editable: null,
      currentView: {},
      views: [],
      blocksContent: [],
      blocks: [],
      panels: [],
      availableMiniblocks: [],
      panelBlockInfo: {},
      panelBlockId: null,
      panelBlockLabel: null,
      panelBlockType: null,
      panelViews: [],
      panelViewId: null,
      panelCurrentView: {},
      panelFields: [],
      panelTables: [],
      panelColumns: [],
      panelRows: [],
      error: {}
    };
    
    // Dashboard Structure
    this.getBlockInformation = this.getBlockInformation.bind(this);
    this.getBlockData = this.getBlockData.bind(this);
    this.getMiniblockData = this.getMiniblockData.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.getAvailableMiniblocks = this.getAvailableMiniblocks.bind(this);
    this.getMiniblockTypeIcon = this.getMiniblockTypeIcon.bind(this);

    // Actions
    this.addListBlock = this.addListBlock.bind(this);
    this.addMiniblock = this.addMiniblock.bind(this);
    this.addPanel = this.addPanel.bind(this);
    this.removePanel = this.removePanel.bind(this);
    this.updatePanelLabel = this.updatePanelLabel.bind(this);
    this.updatePanels = this.updatePanels.bind(this);
    this.updateDashboardView = this.updateDashboardView.bind(this);
    this.updateView = this.updateView.bind(this);

    // Template Functions
    this.templateHeader = this.templateHeader.bind(this);
    this.templateContent = this.templateContent.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const editable = this.props.Editable;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const blocksContent = this.props.BlocksContent;

    // Build Dahsboard Blocks & Panels
    this.dataStructure(blocksContent, currentView);

    this.setState({ login, authId, language, itemId, itemType, editable, currentView, views, blocksContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const editable = this.props.Editable;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const blocksContent = this.props.BlocksContent;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      this.setState({ itemId, itemType });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.BlocksContent !== prevProps.BlocksContent || JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || this.props.Views !== prevProps.Views) {
      // Build Dahsboard Blocks & Panels
      this.dataStructure(blocksContent, currentView);

      this.setState({ currentView, views, blocksContent });
    }
  }

  // Get Block Information from the API
  async getBlockInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const panelBlockInfo = data.GetCardBlockInformationResult;

      if(panelBlockInfo) {
        this.setState({ panelBlockInfo });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getBlockData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const panelBlockContent = data.GetCardBlockInformationResult;

      if(panelBlockContent) {
        const panelFields = panelBlockContent.Fields;
        const panelTables = panelBlockContent.Tables;
        let panelColumns = [], panelRows = [];

        // Get Tables, Rows & Columns
        if(panelTables.find(element => element.Level === "Tree")) {
          panelColumns = panelTables.find(element => element.Level === "Tree").ColumnHeaders;
          panelRows = panelTables.find(element => element.Level === "Tree").Rows;
        }

        this.setState({ panelFields, panelTables, panelColumns, panelRows, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getMiniblockData(login, authId, itemId, itemType, miniblock) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': miniblock.BlockName,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'ForDashboard': true
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetCardBlockInformationResult;

      if(result) {
        miniblock.MiniBlock.Communications = result.Communications;
        miniblock.MiniBlock.Fields = result.Fields;
        miniblock.MiniBlock.Headers = result.Headers;
        miniblock.MiniBlock.HeatMaps = result.HeatMaps;
        miniblock.MiniBlock.Tables = result.Tables;
        miniblock.MiniBlock.Views = result.Views;
        miniblock.MiniBlock.DefaultViewId = result.DefaultViewId;

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  dataStructure(blocksContent, currentView) {
    let blocks = [];
    let panels = [];

    // Get dashboard parameters for each block
    if(currentView.ListBlocks) {
      currentView.ListBlocks.map(listBlock => {
        if(blocksContent.find(blockContent => blockContent.Key === listBlock.ViewDashboardBlockId)) {
          let blockContent = blocksContent.find(blockContent => blockContent.Key === listBlock.ViewDashboardBlockId).Value;
  
          // Merge BlockContent & ListBlock
          let merged = {...blockContent, ...listBlock};
  
          blocks.push(merged);
        }
      });
    }

    // blocksContent.forEach(blockContent => {
    //   if(currentView.ListBlocks.find(element => element.Block === blockContent.Block)) {
    //     let listBlock = currentView.ListBlocks.find(element => element.Block === blockContent.Block);
        
    //     // Merge BlockContent & ListBlock
    //     let merged = {...blockContent, ...listBlock};
        
    //     blocks.push(merged);
    //   }
    // });

    for(let i = 0; i < blocks.length; i++) {
      let panel;

      // Define Panel object for Dashboard
      panel = {
        id: blocks[i].ViewDashboardBlockId.toString(),
        // id: 'Panel'.concat(i),
        col: blocks[i].Left,
        row: blocks[i].Top,
        sizeX: blocks[i].Width,
        sizeY: blocks[i].Height,
        header: () => this.templateHeader(blocks[i]),
        content: () => this.templateContent(blocks[i])
      };

      panels.push(panel);
    }

    this.setState({ blocks, panels });
  }

  async getAvailableMiniblocks(login, authId, itemType, itemId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId
      })
    };

    try{
      const response = await fetch(API_available_miniblocks, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.GetAvailableMiniBlocksResult;
      let availableMiniblocks;

      if(result) {
        availableMiniblocks = result;

        this.setState({ availableMiniblocks });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getMiniblockTypeIcon(itemType) {
    switch(itemType) {
      case "Details":
          return <span className="iconDetails veryBigIcons" alt="Details"></span>;
      case "Roadmap":
          return <span className="iconRoadMap veryBigIcons" alt="Roadmap"></span>;
      case "Qualitative":
          return <span className="iconQualitativeData veryBigIcons" alt="Qualitative Data"></span>;
      case "Risks":
          return <span className="iconRisks veryBigIcons" alt="Risks"></span>;
      case "Issues":
          return <span className="iconIssues veryBigIcons" alt="Issues"></span>;
      case "Decisions":
          return <span className="iconDecisions veryBigIcons" alt="Decisions"></span>;
      case "Meetings":
          return <span className="iconMeetings veryBigIcons" alt="Meetings"></span>;
      case "BudgetDetails":
          return <span className="iconBudget veryBigIcons" alt="Budget"></span>;
      case "Resources":
          return <span className="iconResourceBlueLight veryBigIcons" alt="Resources"></span>;
      case "Holidays":
          return <span className="iconHolidaysBlue veryBigIcons" alt="Holidays"></span>;
      case "Burned":
          return <span className="iconTimeTrackingBlueLight veryBigIcons" alt="Burned"></span>;
      case "Workload":
          return <span className="iconWorkload veryBigIcons" alt="Workload"></span>;
      case "Communication":
          return <span className="iconCommunication veryBigIcons" alt="Communication"></span>;
      case "Earnings":
          return <span className="iconEarnings veryBigIcons" alt="Earnings"></span>;
      default:
          break;
    }
  }

  addListBlock(miniblock) {
    const currentView = this.state.currentView;
    let listBlock, viewDashboardBlockId = -1;

    currentView.ListBlocks.forEach(listBlock => {
      // If we create multiple MiniBlockIssues, decrement viewDashboardBlockId
      if(listBlock.ViewDashboardBlockId <= viewDashboardBlockId) {
        viewDashboardBlockId = viewDashboardBlockId -1;
      }

      // Move down other Blocks
      if(listBlock.Left === 0) {
        listBlock.Top = listBlock.Top + 1;
      }
    });

    // Create new ListBlock object
    listBlock = { "Block": miniblock.Type, "FieldId": 0, "Height": 1, "Label": miniblock.BlockLabel, "Left": 0, "Top": 0, "ViewDashboardBlockId": viewDashboardBlockId, "ViewDashboardId": currentView.ViewDashboardId, "ViewId": miniblock.DefaultViewId, "Width": 1 };

    // Push new Block inside CurrentView ListBlocks
    currentView.ListBlocks.push(listBlock);

    // Add Miniblock
    this.addMiniblock(miniblock, listBlock);

    // Close Popup
    this.setState({ addPanelPopup: false });
  }

  async addMiniblock(miniblock, listBlock) {
    const login = this.state.login;
    const authId = this.state.authId;
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blocks = this.state.blocks;

    // Get MiniBlock Data
    await this.getMiniblockData(login, authId, itemId, itemType, miniblock);

    // Update ListBlock ViewId
    listBlock.ViewId = miniblock.MiniBlock.DefaultViewId;

    // Merge BlockContent & ListBlock
    let merged = {...miniblock.MiniBlock, ...listBlock};

    // Increment other Blocks Order
    // blocks.forEach(block => {
    //   block.BlockOrder = block.BlockOrder + 1;
    // });

    // Push new Miniblock inside list
    blocks.push(merged);

    // Add Panel inside Dashboard
    this.addPanel(merged);
  }

  addPanel(miniblock) {
    const panels = this.state.panels;
    let panel;

    // Define Panel object for Dashboard
    panel = {
      id: miniblock.ViewDashboardBlockId.toString(),
      col: miniblock.Left,
      row: miniblock.Top,
      sizeX: miniblock.Width,
      sizeY: miniblock.Height,
      header: () => this.templateHeader(miniblock),
      content: () => this.templateContent(miniblock)
    };

    // Add Panel to Dashboard Component
    this.dashboard.addPanel(panel);

    // Push Panel into Panels
    panels.push(panel);
  }

  removePanel(id) {
    const panels = this.state.panels;

    if(this.dashboard) {
      // Remove Panel from Dashboard
      this.dashboard.removePanel(id.toString());

      // Update Panels with Dashboard
      this.updatePanels(panels);

      // Update CurrentView with Panels
      this.updateView(panels);
    }
  }

  updatePanelLabel(event) {
    let panelBlockLabel = this.state.panelBlockLabel;

    panelBlockLabel = event.target.value;
    
    this.setState({ panelBlockLabel });
  }

  updatePanels(panels) {
    panels.forEach((panel, index) => {
      if(this.dashboard.panels.find(element => element.id === panel.id)) {
        let dashboardPanel = this.dashboard.panels.find(element => element.id === panel.id);

        // Update Panel with Dashboard Settings
        panel.col = dashboardPanel.col;
        panel.row = dashboardPanel.row;

        panel.sizeX = dashboardPanel.sizeX;
        panel.sizeY = dashboardPanel.sizeY;
      }
      else {
        panels.splice(index, 1);
      }
    });

    // this.dashboard.panels.forEach(element => {
    //   if(panels.find(panel => panel.id === element.id)) {
    //     // Update Panel with Dashboard Settings
    //     panels.find(panel => panel.id === element.id).col = element.col;
    //     panels.find(panel => panel.id === element.id).row = element.row;

    //     panels.find(panel => panel.id === element.id).sizeX = element.sizeX;
    //     panels.find(panel => panel.id === element.id).sizeY = element.sizeY;
    //   }
    //   else {
    //     // Get Panel's index to remove
    //     let index = panels.findIndex(panel => panel.id === element.id);

    //     panels.splice(index, 1);
    //   }
    // });

    this.setState({ panels });
  }

  updateDashboardView(id, label, view) {
    const currentView = this.state.currentView;
    let listBlock;

    if(currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId === id)) {
      listBlock = currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId === id);

      // Update CurrentView ListBlocks based on Dashboard Panels
      listBlock.Label = label;
      listBlock.ViewId = view.ViewId;
    }
  }

  updateView(panels) {
    const currentView = this.state.currentView;

    currentView.ListBlocks.forEach((listBlock, index) => {
      if(panels.find(panel => panel.id == listBlock.ViewDashboardBlockId)) {
        let panel = panels.find(panel => panel.id == listBlock.ViewDashboardBlockId);

        // Update CurrentView ListBlocks based on Dashboard Panels
        listBlock.Left = panel.col;
        listBlock.Top = panel.row;

        listBlock.Width = panel.sizeX;
        listBlock.Height = panel.sizeY;
      }
      else {
        currentView.ListBlocks.splice(index, 1);
      }
    });

    // panels.forEach(panel => {
    //   if(currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id)) {
    //     // Update CurrentView ListBlocks based on Dashboard Panels
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Left = panel.col;
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Top = panel.row;
        
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Width = panel.sizeX;
    //     currentView.ListBlocks.find(listBlock => listBlock.ViewDashboardBlockId == panel.id).Height = panel.sizeY;
    //   }
    //   else {
    //     // Get ListBlock's index to remove
    //     let index = currentView.ListBlocks.findIndex(listBlock => listBlock.ViewDashboardBlockId == panel.id);

    //     currentView.ListBlocks.splice(index, 1);
    //   }
    // });

    this.setState({ currentView });
  }

  templateHeader(block) {
    const { language, itemId, itemType, editable } = this.state;
    const id = block.ViewDashboardBlockId;
    const viewId = block.ViewId;

    // Get current Year for Burned
    const date = new Date();
    const month = (date.getMonth())+1;
    const year = date.getFullYear();

    // Miniblock Header Indicators
    const fields = block.Fields;
    let totalRows, displayedRows, filters, labelTotal, labelDisplayed;

    if(fields.find(field => field.FieldName === 'TotalRows')) {
      totalRows = fields.find(field => field.FieldName === 'TotalRows').FieldValue;
    }
    if(fields.find(field => field.FieldName === 'DisplayedRows')) {
      displayedRows = fields.find(field => field.FieldName === 'DisplayedRows').FieldValue;
    }
    if(fields.find(field => field.FieldName === 'Filters')) {
      filters = fields.find(field => field.FieldName === 'Filters').FieldValue;
    }

    // Labels
    if(totalRows === "1") {
      labelDisplayed = Traduction.translate(language, 'displayed');
      labelTotal = Traduction.translate(language, 'item');
    }
    else {
      labelDisplayed = Traduction.translate(language, 'displayed');
      labelTotal = Traduction.translate(language, 'items');
    }

    switch(block.BlockName) {
      case "Details":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconDetails icons mr10"/><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Roadmap":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconRoadMap icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Qualitative Data":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/Qualitative`}>
              <div className="miniblockHeader"><span className="iconQualitativeData icons mr10"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Risks":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconRisks icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Issues":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconIssues icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Decisions":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconDecisions icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Meetings":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconMeetings icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "BudgetDetails":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconBudget icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Resources":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconResourceBlueLight icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Holidays":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?year=${year}&month=${month}`}>
              <div className="miniblockHeader"><span className="iconHolidaysBlue icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Burned":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}&year=${year}`}>
              <div className="miniblockHeader"><span className="iconTimeTrackingBlueLight icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Workload":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconWorkload icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Communication":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}`}>
              <div className="miniblockHeader"><span className="iconCommunication icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      case "Earnings":
          return <div className="flex mh15">
            <Link className="linkMiniblock" to={`/Card/${itemType}/${itemId}/${block.BlockName}?viewId=${viewId}`}>
              <div className="miniblockHeader"><span className="iconEarnings icons mr15"></span><span className="miniblockTitle">{block.BlockLabel}</span></div>
            </Link>
            <div className="flex-end">
              {/* Indicators */}
              <div className={"miniblockTotalRows" + (editable ? " hidden" : "")}>{totalRows} {labelTotal}</div>
              {/* <div className="miniblockDisplayedRows">{displayedRows} {labelDisplayed}</div> */}
              {filters > 0 && <div className={"miniblockFilters" + (editable ? " hidden" : "")}><span className="iconFilterKanban iconsFilterMiniblock"></span><span className="miniblockFiltersNumber">{filters}</span></div>}
              {/* Edition & Delete */}
              <span className={"iconEdit iconsMiniblock cursor mr15" + (editable ? "" : " hidden")} onClick={(e) => this.editPanel(id)}></span>
              <span className={"iconClear iconsMiniblock cursor" + (editable ? "" : " hidden")} onClick={(e) => this.removePanel(id)}/>
            </div>
          </div>;
      default:
          break;
    }
  }

  templateContent(block) {
    const { itemId, itemType } = this.state;
    const fields = block.Fields;
    let filters;

    if(fields.find(field => field.FieldName === 'Filters')) {
      filters = fields.find(field => field.FieldName === 'Filters').FieldValue;
    }

    switch(block.BlockName) {
      case "Details":
          return <MiniBlockDetails Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.Label} Filters={filters}></MiniBlockDetails>;
      case "Roadmap":
          return <MiniBlockRoadMap Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockRoadMap>;
      case "Qualitative Data":
          return <MiniBlockMeteo Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockMeteo>;
      case "Risks":
          return <MiniBlockRisks Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockRisks>;
      case "Issues":
          return <MiniBlockIssues Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockIssues>;
      case "Decisions":
          return <MiniBlockDecisions Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockDecisions>;
      case "Meetings":
          return <MiniBlockMeetings Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockMeetings>;
      case "BudgetDetails":
          return <MiniBlockBudget Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockBudget>;
      case "Resources":
          return <MiniBlockResources Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockResources>;
      case "Holidays":
          return <MiniBlockHolidays Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockHolidays>;
      case "Burned":
          return <MiniBlockBurned Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockBurned>;
      case "Workload":
          return <MiniBlockWorkload Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockWorkload>;
      case "Communication":
          return <MiniBlockCommunication Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockCommunication>;
      case "Earnings":
          return <MiniBlockEarnings Id={itemId} Type={itemType} BlockContent={block} BlockType={block.BlockName} BlockLabel={block.BlockLabel} Filters={filters}></MiniBlockEarnings>;
      default:
          break;
    }
  }

  render() {
    let { language, itemId, itemType, editable, blocks, panels } = this.state;

    this.cellSpacing = [ 20, 20 ];

    return (
      <div className="cardDashboard">
        <div className="control-pane">
          <DashboardLayoutComponent id='defaultLayout' panels={panels} columns={12} cellSpacing={this.cellSpacing} cellAspectRatio={100 / 50} allowDragging={false} allowResizing={false} ref={dashboard=>this.dashboard=dashboard}/>
        </div>
      </div>
    )
  }
}

export default MiniDashboard;