import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import PopupInformation from './PopupInformation';
import LogoTabsters from '../Images/LogoTabsters.png';
import LoginImage from '../Images/LoginPage.png';

// API
const API = '/WebAppService/ResetPassword';
const API_environment_design = '/WebAppService/GetEnvironmentDesign';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      language: null,
      environmentDesign: null,
      loginIcon: null,
      newPassword: '',
      confirmPassword: '',
      token: {},
      version: '8.04',
      info: false,
      informationMessage: null,
      isLoading: false,
      error: null,
      errors: []
    };

    this.getEnvironmentDesign = this.getEnvironmentDesign.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.encryptPassword = this.encryptPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.validateMessage = this.validateMessage.bind(this);
  };

  async componentDidMount() {
    let language = Authentication.getCookie('language');
    const token = this.props.match.params.token;

    // Get Environment Design
    await this.getEnvironmentDesign();

    if(!language) {
      language = 'Français';
    }

    this.setState({ language, token });
  }

  async getEnvironmentDesign() {
    const language = this.state.language;
    let version = this.state.version;

    try {
      const response = await fetch(API_environment_design);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const result = data.GetEnvironmentDesignResult;

      if(result) {
        const environmentDesign = result;
        let loginIcon;

        if(result.IconLink) {
          loginIcon = result.IconLink;
        }

        if(result.Version) {
          version = result.Version;
        }

        this.setState({ environmentDesign, loginIcon, version });
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(event) {
    const language = this.state.language;
    const newPassword = this.state.newPassword;
    const confirmPassword = this.state.confirmPassword;
    let errors = [];

    event.preventDefault();
    
    // Encrypt Passwords before changing
    if(newPassword === confirmPassword) {
      this.resetPassword(this.encryptPassword(newPassword));
    }
    else {
      errors.push(Traduction.translate(language, 'password_do_not_match'));

      this.setState({ errors });
    }
  }

  encryptPassword(password) {
    const CryptoJS = require("crypto-js");
    
    // Define key
    const key = CryptoJS.enc.Utf8.parse('7P*LDKxy6q5@l%rS');

    // Define vector
    const vector = CryptoJS.enc.Utf8.parse('U7@A7tb$2QuJ5^#9');

    // Encrypt Password with key & vector
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, { keySize: 128 / 8, iv: vector, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    
    // Convert Encrypted PAssword to Hexadecimal
    const buffer = Buffer.from(encrypted.toString(), 'utf8');

    return buffer.toString('hex');
  }

  async resetPassword(newPassword) {
    const language = this.state.language;
    const token = this.state.token;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'Token': token,
        'Password': newPassword
      })
    };

    try{
      const response = await fetch(API, requestOptions);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(errors.push(Traduction.translate(language, 'server_not_responding')));
      }

      const data = await response.json();
      const result = data.ResetPasswordResult;

      if(result) {
        // Check if the Password Change is valid
        if(result.IsValid === true) {
          // Display confirmation message that password has been updated
          this.setState({ info: true, informationMessage: Traduction.translate(language, 'password_updated') });
        }
        else {
          // Clear NewPassword & ConfirmPassword
          this.state.newPassword = "";
          this.state.confirmPassword = "";

          // Update Errors
          errors.push(result.Error);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

      this.setState({ errors });

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  validateMessage() {
    this.setState({ info: false });

    // Redirect to Login Page
    this.props.history.push("/Home");
  }

  render() {
    const { language, login, loginIcon, newPassword, confirmPassword, token, version, info, informationMessage, isLoading, errors } = this.state;

    return (
      <div className="row">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'tabsters')} • {Traduction.translate(language, 'reset_password')}</title></MetaTags>

        {/* Tabsters WebApp version */}
        <div className="tabstersWebappVersion">
          <span className="poweredByTabsters">{Traduction.translate(language, 'version')} {version} {Traduction.translate(language, 'powered_by')}</span>
          <img className="miniLogoTabsters" src={LogoTabsters} alt="Tabsters"/>
        </div>

        {/* Message Information Popup */}
        {info && <PopupInformation Message={informationMessage} onMessageValidate={this.validateMessage}></PopupInformation>}

        <div className="changePasswordForm center">
          <div className="col-md-8">
            <div className="inline-flex mb30">
              {/* Tabsters Logo */}
              {loginIcon && <img className="loginLogo" src={loginIcon} alt="LoginIcon"/>}
              {!loginIcon && <img className="loginLogo" src={LogoTabsters} alt="Tabsters"/>}
            </div>

            {/* Change Password Form */}
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formGroupNewPassword">
                <Form.Control type="password" name="newPassword" placeholder={Traduction.translate(language, 'new_password')} value={this.state.newPassword} onChange={this.handleInputChange} required/>
              </Form.Group>
              <Form.Group controlId="formGroupConfirmPassword">
                <Form.Control type="password" name="confirmPassword" placeholder={Traduction.translate(language, 'confirm_password')} value={this.state.confirmPassword} onChange={this.handleInputChange} required/>
              </Form.Group>

              {/* Errors */}
              {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}
              
              <Button className="changePasswordButton" type="submit">{Traduction.translate(language, 'validate')}</Button>
            </Form>
          </div>
        </div>
        <div className="changePasswordImages">
          {/* <img className="loginImage" src={LoginImage} alt=""/> */}
        </div>
      </div>
    );
  }
}

export default ResetPassword;