import React, { Component } from 'react';
import { L10n, loadCldr, closest } from '@syncfusion/ej2-base';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import { ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, ExcelExport, Inject } from '@syncfusion/ej2-react-schedule';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorModification from './ErrorModification';
import PopupConfirmation from './PopupConfirmation';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });
loadCldr(require('cldr-data/supplemental/numberingSystems.json'), require('cldr-data/main/fr/ca-gregorian.json'), require('cldr-data/main/fr/numbers.json'), require('cldr-data/main/fr/timeZoneNames.json'), require('cldr-data/main/es/ca-gregorian.json'), require('cldr-data/main/es/numbers.json'), require('cldr-data/main/es/timeZoneNames.json'));

// API
const API_resources = '/WebAppService/GetResourcesInformation';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';

class RoadmapScheduler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      editable: null,
      currentView: {},
      columns: [],
      rows: [],
      resources: [],
      dataSource: [],
      departments: [],
      consultants: [],
      modificationErrors: [],
      confirm: false,
      rowItemId: null,
      rowItemType: null,
      rowItemTitle: null,
      error: {}
    };
    
    // Scheduler Structure
    this.getScopeResources = this.getScopeResources.bind(this);
    this.buildDataSource = this.buildDataSource.bind(this);
    this.buildResources = this.buildResources.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    this.getConsultantName = this.getConsultantName.bind(this);
    this.getCurrentViewLevel = this.getCurrentViewLevel.bind(this);
    this.getDeleteLabel = this.getDeleteLabel.bind(this);
    this.getTaskColor = this.getTaskColor.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this)

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.contextMenuOpen = this.contextMenuOpen.bind(this);
    this.contextMenuClick = this.contextMenuClick.bind(this);
    this.dragStop = this.dragStop.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.checkModification = this.checkModification.bind(this);
    // this.createItem = this.createItem.bind(this);
    // this.deleteItem = this.deleteItem.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    // this.addRowsInDatasource = this.addRowsInDatasource.bind(this);
    // this.addRowsInTable = this.addRowsInTable.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    // this.deleteRowInDatasource = this.deleteRowInDatasource.bind(this);
    // this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    // Template Functions
    this.resourceHeaderTemplate = this.resourceHeaderTemplate.bind(this);
    this.timelineEventTemplate = this.timelineEventTemplate.bind(this);
    this.timelineHeaderInfoTemplate = this.timelineHeaderInfoTemplate.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Get first and last day of the month
    const date = new Date();
    const borneInf = new Date(date.getFullYear(), date.getMonth(), 1);
    const borneSup = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Get Scope Resources
    this.getScopeResources(login, authId, itemId, itemType, rows, this.formatDateEn(borneInf), this.formatDateEn(borneSup));

    this.setState({ login, authId, language, itemId, itemType, blockType, editable, currentView, columns, rows });
  }

  componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Get first and last day of the month
    const date = new Date();
    const borneInf = new Date(date.getFullYear(), date.getMonth(), 1);
    const borneSup = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || JSON.stringify(rows) != JSON.stringify(prevProps.Rows)) {
      // Get Scope Resources
      this.getScopeResources(login, authId, itemId, itemType, rows, this.formatDateEn(borneInf), this.formatDateEn(borneSup));
    }

    // this.setState({ language, itemId, itemType, blockType, editable, currentView, columns, rows, filtered });
  }

  async getScopeResources(login, authId, itemId, itemType, rows, borneInf, borneSup) {
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'BorneInf': borneInf,
        'BorneSup': borneSup
      })
    };
  
    try{
      const response = await fetch(API_resources, requestOptions);
  
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
  
      const data = await response.json();
      const result = data.GetResourcesInformationResult;
  
      if(result) {
        // Get Resources
        const resources = result;

        // Build Departments & Consultants
        const departments = this.buildResources(resources).departments;
        const consultants = this.buildResources(resources).consultants;

        // Build Scheduler DataSource based on Roadmap rows
        const dataSource = this.buildDataSource(rows, departments, '');

        this.setState({ rows, dataSource, resources, departments, consultants, borneInf, borneSup });
      }
      else {
        this.updateErrors(result.Errors);
      }
  
    } catch(error) {
      this.setState({ error });
    }
  }

  // Build Scheduler Datasource based on Roadmap rows
  buildDataSource(rows, departments, search) {
    let dataSource = [];
    let task, id, type, name, subStatus, startDate, endDate, resourceName, resourceId, resourceType, groupId, groupName, color;

    rows.forEach(row => {
      // Check if Row Name contains Search and StartDate & EndDate are not empty
      if((search === '' || row.Cells.find(cell => cell.ColumnName === 'Name').Value.toLowerCase().includes(search.toLowerCase())) && row.Cells.find(cell => cell.ColumnName === 'StartDate').Value && row.Cells.find(cell => cell.ColumnName === 'EndDate').Value) {
        
        // Get Tasks row Fields to build DataSource
        if(row.Cells.find(cell => cell.ColumnName === 'Item_ID')) {
          id = parseInt(row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1));
        }
        if(row.Cells.find(cell => cell.ColumnName === 'Item_Type')) {
          type = row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value;
        }
        if(row.Cells.find(cell => cell.ColumnName === 'Name')) {
          name = row.Cells.find(cell => cell.ColumnName === 'Name').Value;
        }
        if(row.Cells.find(cell => cell.ColumnName === 'Sub_Status')) {
          subStatus = JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Sub_Status').Value);
        }
        if(row.Cells.find(cell => cell.ColumnName === 'Resource_ID')) {
          resourceId = parseInt(row.Cells.find(cell => cell.ColumnName === 'Resource_ID').Value);
        }

        if(row.Cells.find(cell => cell.ColumnName === 'Resource_Type')) {
          resourceType = JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Resource_Type').Value);

          // Find Department Group Id & Name corresponding to ResourceType
          if(departments.find(department => department.Text.Id === resourceType.Id)) {
            groupId = departments.find(department => department.Text.Id === resourceType.Id).Id;
            groupName = departments.find(department => department.Text.Id === resourceType.Id).Text.Label;
          }
        }

        // StartDate & EndDate
        startDate = this.formatDate(row.Cells.find(cell => cell.ColumnName === 'StartDate').Value);
        endDate = this.formatDate(row.Cells.find(cell => cell.ColumnName === 'EndDate').Value);

        // Color
        color = this.getTaskColor(row);

        task = { 
          "Id": id,
          "Type": type,
          "Name": name,
          "SubStatus": subStatus,
          "StartTime": startDate,
          "EndTime": endDate,
          "DepartmentID": groupId,
          "ConsultantID": resourceId,
          "Color": color,
          //"DepartmentName": groupName
        };

        dataSource.push(task);
      }
    });

    // Create Item Blockers to avoid Resources Drag & Drop on Groups
    // departments.forEach((item, index) => {
    //   let groupId, blocker; 
      
    //   groupId = item.Id;

    //   blocker = {
    //     "Id": index,
    //     "Name": "Not available",
    //     "SubStatus": "isBlocked",
    //     "StartTime": "2019-01-02T07:30:00.000Z",
    //     "EndTime": "2025-12-02T08:30:00.000Z",
    //     "DepartmentID": groupId,
    //     "IsBlock": true
    //   };

    //   dataSource.push(blocker);
    // });

    return dataSource;
  }

  buildResources(resources) {
    let departments = [];
    let consultants = [];
    let groups = [];
    let groupId;

    resources.forEach((resource, index) => {
      // Reinitialise GroupId value
      groupId = groups.length + 1;

      if(!groups.find(group => group.Id === resource.Resource_Type.Id)) {
        departments.push({ Text: resource.Resource_Type, Id: groupId, Color: '#8E8E90' });
      }
      
      // Add new Group inside list if not already included
      if(!groups.find(group => group.Id === resource.Resource_Type.Id)) {
        groups.push(resource.Resource_Type);
      }
      
      // Get Resource GroupId
      if(groups.find(group => group === resource.Resource_Type)) {
        groupId = groups.findIndex(group => group === resource.Resource_Type) + 1;
      }
      
      consultants.push({ Text: resource.Resource, Id: resource.Resource_ID, GroupId: groupId, Color: '#8E8E90' });
    });

    return { departments, consultants };
  }

  getColumnsName() {
    let columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      columnNames.push(column.FieldName);
    });

    return columnNames;
  }

  getConsultantName(value) {
    return value.resourceData[value.resource.textField];
  }

  getCurrentViewLevel() {
    const currentView = this.state.currentView;
    let level;

    // Get View corresponding level
    if(currentView) {
      switch(currentView.DefaultLevel) {
        case 0:
            level = 'Tree';
            break;
        case 1:
            level = 'Business_Line';
            break;
        case 2:
            level = 'Project';
            break;
        case 3:
            level = 'Workpackage';
            break;
        case 4:
            level = 'Action';
            break;
        case 5:
            level = 'Task';
            break;
        case 6:
            level = 'Risk';
            break;
        case 7:
            level = 'Issue';
            break;
        case 8:
            level = 'Decision';
            break;
        case 9:
            level = 'Meeting';
            break;
        case 10:
            level = 'Definition';
            // level = 'Budget';
            break;
        case 11:
            level = 'Link';
            break;
        case 12:
            level = 'Resource';
            break;
        case 13:
            level = 'Entity';
            break;
        case 14:
            level = 'User';
            break;
        case 15:
            level = 'AxisValue';
            break;
        case 16:
            level = 'DeletedItem';
            break;
        case 18:
            level = 'Workload';
            break;
        case 19:
            level = 'CustomField';
            break;
        case 20:
            level = 'HashTag';
            break;
        case 21:
            level = 'BudgetCode';
            break;
        case 22:
            level = 'UserGroup';
            break;
        case 23:
            level = 'Warning';
            break;
        default:
            break;
      }
    }

    return level;
  }

  getDeleteLabel(itemType) {
    const language = this.state.language;

    switch(itemType) {
      case 'Business_Line':
          return Traduction.translate(language, 'delete_business_line');
      case 'Project':
          return Traduction.translate(language, 'delete_project');
      case 'Workpackage':
          return Traduction.translate(language, 'delete_workpackage');
      case 'Action':
          return Traduction.translate(language, 'delete_action');
      case 'Task':
          return Traduction.translate(language, 'delete_task');
      case 'Entity':
          return Traduction.translate(language, 'delete_entity');
      case 'Resource':
          return Traduction.translate(language, 'delete_resource');
      case 'AgileBoard':
          return Traduction.translate(language, 'delete_agile_board');
      case 'Sprint':
          return Traduction.translate(language, 'delete_sprint');
    }
  }

  getTaskColor(row) {
    let color;

    if(row.Cells.find(cell => cell.ColumnName === 'Asset')) {
      if(JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Asset').Value).Label === 'FM') {
        color = '#8E8E90';
      }
      else if(JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Asset').Value).Label === 'Marque') {
        color = '#00C77A';
      }
      else if(JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Asset').Value).Label === 'Standard') {
        color = '#0088C7';
      }
      else if(JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Asset').Value).Label === 'PAID') {
        color = '#9E5FFF';
      }
      else {
        color = '#0088C7';
      }
    }

    return color;
  }

  // Format Date for Scheduler DataSource
  formatDate(date) {
    let formattedDate;
    
    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(Date.UTC(year, month, day)).toISOString();
    }

    return formattedDate;
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      let dateEn = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateEn.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      let dateEn = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateEn.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  actionBegin(args) {

  }

  actionComplete(args) {

  }

  // Before Right Click Events
  contextMenuOpen(args) {
    let targetElement = args.event.target;
    this.selectedTarget = closest(targetElement, '.e-appointment,.e-work-cells,' + '.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells');

    // Check if User click on Scheduler Task
    if(this.selectedTarget && this.selectedTarget.classList.contains('e-appointment')) {
      this.contextMenu.showItems(['contextMenu_Duplicate', 'contextMenu_Delete'], true);
    }
    else {
      this.contextMenu.hideItems(['contextMenu_Duplicate', 'contextMenu_Delete'], true);
    }
  }

  // Right Click Events
  contextMenuClick(args) {
    // Check if User click on Scheduler Task
    if(this.selectedTarget && this.selectedTarget.classList.contains('e-appointment')) {
      // Get selected Cell informations
      this.selectedCells = this.scheduler.getEventDetails(this.selectedTarget);

      if(args.item.id === 'contextMenu_Duplicate') {
        // selectedCells = this.scheduler.getSelectedElements();
          
        let itemId = this.selectedCells.Id;
        let itemType = this.selectedCells.Type;

        // Call the event from the Parent component through the props
        this.props.onItemCreate('', itemType, itemId);
      }
      else if(args.item.id === 'contextMenu_Delete') {
        // selectedCells = this.scheduler.getSelectedElements();
          
        let itemId = this.selectedCells.Id;
        let itemType = this.selectedCells.Type;

        this.setState({ confirm: true, rowItemType: itemType, rowItemId: itemId });
      }
    }
  }

  async dragStop(args) {
    const rows = this.state.rows;
    let rowSelected, itemType, itemId;

    // If User Doesn't Drag Task on Resource
    if(!args.data.ConsultantID) {
      args.cancel = true;
    }
    // Find the corresponding row
    else if(rows.find(row => (row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1) == args.data.Id))) {
      rowSelected = rows.find(row => (row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1) == args.data.Id));

      itemId = rowSelected.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1);
      itemType = rowSelected.Cells.find(cell => cell.ColumnName === 'Item_Type').Value;

      // Drag and Drop both StartDate & EndDate
      if(args.data.StartTime && rowSelected.Cells.find(cell => cell.ColumnName === 'StartDate').Value !== this.formatDateEn(args.data.StartTime) && args.data.EndTime && rowSelected.Cells.find(cell => cell.ColumnName === 'EndDate').Value !== this.formatDateEn(args.data.EndTime)) {
        let oldStartDate, oldEndDate, newStartDate, newEndDate, startDateFormat, endDateFormat;

        // Get Old StartDate & EndDate value
        oldStartDate = rowSelected.Cells.find(cell => cell.ColumnName === 'StartDate').Value;
        oldEndDate = rowSelected.Cells.find(cell => cell.ColumnName === 'EndDate').Value;

        // Get New StartDate & EndDate value
        newStartDate = this.formatDateEn(args.data.StartTime.getTime());
        newEndDate = this.formatDateEn(args.data.EndTime.getTime());

        // Format Old StartDate & EndDate to compare with New StartDate
        startDateFormat = new Date(oldStartDate);
        startDateFormat.setHours(1,0,0,0);
        endDateFormat = new Date(oldEndDate);
        endDateFormat.setHours(1,0,0,0);

        // If Old StartDate & EndDate is after New StartDate & EndDate (Drag & Drop to the left)
        if(startDateFormat.getTime() > args.data.StartTime.getTime() && endDateFormat.getTime() > args.data.EndTime.getTime()) {
          // Wait that the first Request return before starting the second Request
          await this.checkModification(itemId, itemType, "StartDate", oldStartDate, newStartDate);
          this.checkModification(itemId, itemType, "EndDate", oldEndDate, newEndDate);
        }
        // If Old StartDate & EndDate is before New StartDate & EndDate (Drag & Drop to the right)
        else if(startDateFormat.getTime() < args.data.StartTime.getTime()) {
          // Wait that the first Request return before starting the second Request
          await this.checkModification(itemId, itemType, "EndDate", oldEndDate, newEndDate);
          this.checkModification(itemId, itemType, "StartDate", oldStartDate, newStartDate);
        }
      }
      // Drag and Drop Resource
      if(args.data.ConsultantID && rowSelected.Cells.find(cell => cell.ColumnName === 'Resource_ID').Value != args.data.ConsultantID) {
        let columnName, newResourceId, newResourceName, oldResourceName;
        columnName = "Resource";

        // Get Old Resource value
        oldResourceName = rowSelected.Cells.find(cell => cell.ColumnName === 'Resource').Value;

        // Get New Resource value
        newResourceId = args.data.ConsultantID;

        // Find Resource Name associated with the new Resource ID
        if(rows.find(row => (row.Cells.find(cell => cell.ColumnName === 'Resource_ID').Value == newResourceId))) {
          newResourceName = rows.find(row => (row.Cells.find(cell => cell.ColumnName === 'Resource_ID' ).Value == newResourceId)).Cells.find(cell => cell.ColumnName === 'Resource').Value; 
        }
        
        // filtered.forEach( row => {
        //   if( row.Cells.find( cell => cell.ColumnName === 'Resource_ID' ).Value == args.data.ConsultantID ) {
        //     newResourceName = row.Cells.find( cell => cell.ColumnName === 'Resource').Value;
        //   }
        // })
        
        this.checkModification(itemId, itemType, columnName, oldResourceName, newResourceName);
      }
    }
  }

  resizeStop(args) {
    const rows = this.state.rows;
    let rowSelected, itemType, itemId, columnName, oldValue, newValue;

    // Find the corresponding row
    if(rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1) == args.data.Id)) {
      rowSelected = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1) == args.data.Id);

      itemId = rowSelected.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1);
      itemType = rowSelected.Cells.find(cell => cell.ColumnName === 'Item_Type').Value;

      // If User modify StartDate
      if(rowSelected.Cells.find(cell => cell.ColumnName === 'StartDate').Value !== this.formatDateEn(args.data.StartTime)) {
        oldValue = rowSelected.Cells.find(cell => cell.ColumnName === 'StartDate').Value;
        newValue = this.formatDateEn(args.data.StartTime);
        columnName = "StartDate";
        
        this.checkModification(itemId, itemType, columnName, oldValue, newValue);
      }
      // If User modify EndDate
      else if(rowSelected.Cells.find(cell => cell.ColumnName === 'EndDate').Value !== this.formatDateEn(args.data.EndTime)) {
        oldValue = rowSelected.Cells.find(cell => cell.ColumnName === 'EndDate').Value;
        newValue = this.formatDateEn(args.data.EndTime);
        columnName = "EndDate";
        
        this.checkModification(itemId, itemType, columnName, oldValue, newValue);
      }
    }
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const login = this.state.login;
    const authId = this.state.authId;
    let additionalContext = [];

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;

      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Table with Modification and Warnings
            this.updateTable(modification, warnings);
          }
          // If the Modification is not valid
          else {
            // Cancel Modification and Update Datasource with OldValue
            this.cancelModification(modification);

            // Update Errors
            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors('Impossible de modifier cet élément');
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateDatasource(modification) {

  }

  updateTable(modification, warnings) {
    let rows = this.state.rows;

    rows.forEach(row => {
      let itemType = row.ItemType;
      let itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

      if(modification.ItemType === itemType && modification.ItemId == itemId.substring(1)) {
        // Update Row with Modification NewValue
        row.Cells.find(cell => cell.ColumnName === modification.ColumnName).Value = modification.NewValue;

        // Update Warnings
        if(warnings.length > 0) {
          if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
            row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = warnings;
          }
        }
        else {
          if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
            row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = "";
          }
        }
      }
    });

    // Call the event from the Parent component through the props
    // this.props.onSchedulerChange(rows);
  }

  cancelModification(modification) {
    const itemId = modification.ItemId;
    const columnName = modification.ColumnName;
    const oldValue = modification.OldValue;
    let dataSource, task;

    if(this.scheduler) {
      dataSource = this.scheduler.eventSettings.dataSource;

      // Find corresponding Task
      if(dataSource.find(row => row.Id = itemId)) {
        task = dataSource.find(row => row.Id = itemId);
  
        // Update modified ColumnName with OldValue
        if(columnName === 'StartDate') {
          task['StartTime'] = oldValue;
        }
        else if(columnName === 'EndDate') {
          task['EndTime'] = oldValue;
        }
        else if(columnName === 'Resource_ID') {
          task['ConsultantID'] = oldValue;
        }

        this.scheduler.saveEvent(task);
      }
    }
  }

  // addRowsInDatasource(rows) {
  //   const currentView = this.state.currentView;
  //   const departments = this.state.departments;
  //   let task, id, type, name, subStatus, startDate, endDate, resourceId, resourceType, groupId, groupName, color;

  //   // Get View corresponding level
  //   const level = this.getCurrentViewLevel(currentView);

  //   // Add Rows in the Datasource
  //   rows.forEach(row => {
  //     if(row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value === level) {
  //       // Check if StartDate & EndDate are not empty
  //       if(row.Cells.find(cell => cell.ColumnName === 'StartDate').Value && row.Cells.find(cell => cell.ColumnName === 'EndDate').Value) {
  //         // Get Tasks row Fields to build DataSource
  //         if(row.Cells.find(cell => cell.ColumnName === 'Item_ID')) {
  //           id = parseInt(row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1));
  //         }
  //         if(row.Cells.find(cell => cell.ColumnName === 'Item_Type')) {
  //           type = row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value;
  //         }
  //         if(row.Cells.find(cell => cell.ColumnName === 'Name')) {
  //           name = row.Cells.find(cell => cell.ColumnName === 'Name').Value;
  //         }
  //         if(row.Cells.find(cell => cell.ColumnName === 'Sub_Status')) {
  //           subStatus = row.Cells.find(cell => cell.ColumnName === 'Sub_Status').Value;
  //         }
  //         if(row.Cells.find(cell => cell.ColumnName === 'Resource_ID')) {
  //           resourceId = parseInt(row.Cells.find(cell => cell.ColumnName === 'Resource_ID').Value);
  //         }
  //         if(row.Cells.find(cell => cell.ColumnName === 'Resource_Type')) {
  //           resourceType = row.Cells.find(cell => cell.ColumnName === 'Resource_Type').Value;
            
  //           // Find Department Group Id & Name corresponding to ResourceType
  //           if(departments.find(department => department.Text === resourceType)) {
  //             groupId = departments.find(department => department.Text === resourceType).Id;
  //             groupName = departments.find(department => department.Text === resourceType).Text;
  //           }
  //         }

  //         // StartDate & EndDate
  //         startDate = this.formatDate(row.Cells.find(cell => cell.ColumnName === 'StartDate').Value);
  //         endDate = this.formatDate(row.Cells.find(cell => cell.ColumnName === 'EndDate').Value);

  //         // Color
  //         color = this.getTaskColor(row);

  //         task = { 
  //           "Id": id,
  //           "Type": type,
  //           "Name": name,
  //           "SubStatus": subStatus,
  //           "StartTime": startDate,
  //           "EndTime": endDate,
  //           "DepartmentID": groupId,
  //           "ConsultantID": resourceId,
  //           "Color": color,
  //           //"DepartmentName": groupName
  //         };

  //         this.scheduler.addEvent(task);
  //       }
  //     }
  //   });
  // }

  // addRowsInTable(row) {
  //   let rows = this.state.rows;
  //   let filtered = this.state.filtered;

  //   rows.push(row);
  //   filtered.push(row);

  //   // Call the event from the Parent component through the props
  //   this.props.onSchedulerChange(rows);
  // }

  confirmDelete() {
    const rowItemId = this.state.rowItemId;
    const rowItemType = this.state.rowItemType;

    // Call the event from the Parent component through the props
    this.props.onItemDelete(rowItemId, rowItemType);

    this.setState({ confirm: false });
  }
  
  cancelDelete() {
    this.setState({ confirm: false });
  }

  // deleteRowInDatasource(modification) {
  //   if(this.scheduler) {
  //     this.scheduler.deleteEvent(modification.ItemId);
  //   }
  // }

  // deleteRowInTable(modification) {
  //   // const blockType = this.state.blockType;
  //   let rows = this.state.rows;
  //   let filtered = this.state.filtered;
  //   let indexToDelete = -1;

  //   rows.forEach((row, index) => {
  //     let itemType = row.ItemType;
  //     let itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

  //     // Get Row Index to delete
  //     if(modification.ItemType === itemType && modification.ItemId == itemId.substring(1)) {
  //       indexToDelete = index;
  //     }
  //   });

  //   // Delete Row
  //   if(indexToDelete >= 0) {
  //     rows.splice(indexToDelete, 1);
  //     filtered.splice(indexToDelete, 1);
  //   }

  //   // Call the event from the Parent component through the props
  //   this.props.onSchedulerChange(rows);
  // }

  searchItem(search) {
    const rows = this.state.rows;
    const departments = this.state.departments;

    if(this.scheduler) {
      // Build Scheduler DataSource based on Roadmap rows
      const dataSource = this.buildDataSource(rows, departments, search);

      this.setState({ dataSource });
    }
  }

  updateErrors(errors) {
    let modificationErrors = this.state.modificationErrors;

    // Push the new Errors in the Errors Table
    modificationErrors.push(errors);

    this.setState({ modificationErrors });
  }

  cleanErrors() {
    this.setState({ modificationErrors: [] });
  }

  resourceHeaderTemplate(props) {
    return (<div className="template-wrap">
      <div className="specialist-category">
        <div className="specialist-name">{this.getConsultantName(props).Label}</div>
      </div>
    </div>);
  }

  timelineEventTemplate(props) {
    const language = this.state.language;
    
    if(props.SubStatus === 'A valider') {
      return (<div className="template-wrap" style={{ background: '#FFFFFF', border: '2px solid', borderColor: props.Color, borderRadius: '6px' }}>
        <div className="" style={{ background: '#FFFFFF', color: props.Color }}>{props.Name}</div>
        {language === "English" && <div className="" style={{ background: '#FFFFFF', color: props.Color }}>{this.formatDateEn(props.StartTime)} - {this.formatDateEn(props.EndTime)}</div>}
        {language === "Français" && <div className="" style={{ background: '#FFFFFF', color: props.Color }}>{this.formatDateFr(props.StartTime)} - {this.formatDateFr(props.EndTime)}</div>}
      </div>);
    }
    // else if(props.SubStatus === 'isBlocked') {
    //   return (<div className="template-wrap" style={{ background: '#FFFFFF'}}></div>) 
    // }
    else {
      return (<div className="template-wrap" style={{ background: props.Color, border: '2px solid', borderColor: props.Color }}>
      <div className="" style={{ background: props.Color }}>{props.Name}</div>
      {language === "English" && <div className="" style={{ background: props.Color }}>{this.formatDateEn(props.StartTime)} - {this.formatDateEn(props.EndTime)}</div>}
      {language === "Français" && <div className="" style={{ background: props.Color }}>{this.formatDateFr(props.StartTime)} - {this.formatDateFr(props.EndTime)}</div>}
    </div>);
    }
  }

  timelineHeaderInfoTemplate(props) {
    return (<div className="quick-info-header">
      <div className="quick-info-header-content" style={{ background: props.Color, color: '#FFFFFF', fontSize: '18px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="quick-info-title">{props.Name}</div>
      </div>
    </div>);
  }

  render() {
    let { language, itemId, itemType, blockType, editable, currentView, columns, rows, dataSource, resources, departments, consultants, borneInf, borneSup, modificationErrors, confirm, rowItemType } = this.state;

    // Calendar Height
    let height = (window.innerHeight - 140);
    let minimum = 100;

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Event Settings
    // this.eventSettings = { dataSource: dataSource, allowAdding: false, allowDeleting: false, allowEditing: false };
    this.fields = { subject: { title: 'Patient Name', name: 'Name' }, startTime: { title: 'From', name: 'StartTime' }, endTime: { title: 'To', name: 'EndTime' }, description: { title: 'Reason', name: 'Description' } };
    this.eventSettings = { dataSource: dataSource, fields: this.fields, enableTooltip: true };
    this.group = { enableCompactView: false, resources: ['Departments', 'Consultants'] };
    this.menuItems = [{ text: 'Duplicate', iconCss: 'e-icons e-edit', id: 'contextMenu_Duplicate' }, { text: 'Delete', iconCss: 'e-icons e-trash', id: 'contextMenu_Delete' }];

    return (
      <div className="blockRoadmapScheduler">
        {/* Errors */}
        {modificationErrors.length > 0 && 
          <ErrorModification Open={true} Errors={modificationErrors} onErrorsClean={this.cleanErrors}></ErrorModification>
        }

        {/* Delete Confirmation Popup */}
        {confirm && <PopupConfirmation Message={this.getDeleteLabel(rowItemType)} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

        {dataSource.length > 0 && departments.length > 0 && consultants.length > 0 && 
          <div className="schedule-control-section">
            <div className="control-section">
              <div className="control-wrapper">
                {language === 'English' && 
                  <ScheduleComponent height={height} width={'100%'} dragStop={this.dragStop} resizeStop={this.resizeStop} cssClass="schedule-drag-drop" currentView="TimelineMonth" rowAutoHeight={true} selectedDate={new Date(2022, 0, 1)} eventSettings={this.eventSettings} group={this.group} resourceHeaderTemplate={this.resourceHeaderTemplate} quickInfoTemplates={{ header: this.timelineHeaderInfoTemplate }} actionBegin={this.actionBegin} actionComplete={this.actionComplete} firstDayOfWeek={1} ref={scheduler=>this.scheduler=scheduler}>
                    <ResourcesDirective>
                      <ResourceDirective field="DepartmentID" title="Department" name="Departments" allowMultiple={false} dataSource={departments} textField="Text" idField="Id" colorField="Color"></ResourceDirective>
                      <ResourceDirective field="ConsultantID" title="Consultant" name="Consultants" allowMultiple={false} dataSource={consultants} textField="Text" idField="Id" groupIDField="GroupId" colorField="Color"></ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective option="TimelineMonth" displayName='Timeline Month' eventTemplate={this.timelineEventTemplate}/>
                      <ViewDirective option="TimelineYear" displayName='Timeline Year' eventTemplate={this.timelineEventTemplate}/>
                    </ViewsDirective>
                    <Inject services={[ TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, ExcelExport ]}/>
                  </ScheduleComponent>
                }
                {language === 'Français' && 
                  <ScheduleComponent height={height} width={'100%'} locale={Traduction.translate(language, 'locale')} dragStop={this.dragStop} resizeStop={this.resizeStop} cssClass="schedule-drag-drop" currentView="TimelineMonth" rowAutoHeight={true} selectedDate={new Date(2022, 0, 1)} eventSettings={this.eventSettings} group={this.group} resourceHeaderTemplate={this.resourceHeaderTemplate} quickInfoTemplates={{ header: this.timelineHeaderInfoTemplate }} actionBegin={this.actionBegin} actionComplete={this.actionComplete} firstDayOfWeek={1} ref={scheduler=>this.scheduler=scheduler}>
                    <ResourcesDirective>
                      <ResourceDirective field="DepartmentID" title="Department" name="Departments" allowMultiple={false} dataSource={departments} textField="Text" idField="Id" colorField="Color"></ResourceDirective>
                      <ResourceDirective field="ConsultantID" title="Consultant" name="Consultants" allowMultiple={false} dataSource={consultants} textField="Text" idField="Id" groupIDField="GroupId" colorField="Color"></ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective option="TimelineMonth" displayName='Vue Mois' eventTemplate={this.timelineEventTemplate}/>
                      <ViewDirective option="TimelineYear" displayName='Vue Année' eventTemplate={this.timelineEventTemplate}/>
                    </ViewsDirective>
                    <Inject services={[ TimelineViews, TimelineMonth, TimelineYear, DragAndDrop, Resize, ExcelExport ]}/>
                  </ScheduleComponent>
                }
              </div>
            </div>
            <ContextMenuComponent cssClass='schedule-context-menu' target='.e-schedule' items={this.menuItems} beforeOpen={this.contextMenuOpen} select={this.contextMenuClick} ref={menu=>this.contextMenu=menu}/>
          </div>
        }
      </div>
    )
  }
}

export default RoadmapScheduler;