import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      view: null,
      startDate: null,
      endDate: null,
      status: null
    };

    // Data Structure
    this.formatDate = this.formatDate.bind(this);
    this.getDayDifference = this.getDayDifference.bind(this);
    this.getMonthName = this.getMonthName.bind(this);
    this.progressCalculation = this.progressCalculation.bind(this);

    // Template
    this.templateTimeline = this.templateTimeline.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const view = this.props.View;
    const startDate = this.props.StartDate;
    const endDate = this.props.EndDate;
    const status = this.props.Status;

    this.setState({ language, view, status, startDate, endDate });
  }

  componentDidUpdate(prevProps) {
    const view = this.props.View;
    const startDate = this.props.StartDate;
    const endDate = this.props.EndDate;
    const status = this.props.Status;
    
    if(this.props.StartDate !== prevProps.StartDate || this.props.EndDate !== prevProps.EndDate || this.props.Status !== prevProps.Status) {
      this.setState({ view, status, startDate, endDate });
    }
  }

  // Format Date for Timeline calculation
  formatDate(date) {
    let formattedDate;

    if(date) {
      // Force Date format
      const newDate = new Date(date).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });

      const split = newDate.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(Date.UTC(year, month, day)).toISOString();
    }

    return formattedDate;
  }

  getDayDifference(language, date1, date2) {
    let difference;
    date1 = date1.getTime() / 86400000;
    date2 = date2.getTime() / 86400000;
    
    // Difference between 2 dates - 1 day/86 400 000 milliseconds
    difference = new Number(date2 - date1 -1).toFixed(0);

    if(difference === "1") {
      return difference + " " + Traduction.translate(language, 'timeline_day');
    }
    else {
      return difference + " " + Traduction.translate(language, 'timeline_days');
    }
  }

  getMonthName(month) {
    const language = this.state.language;

    switch(month) {
      case 1:
          return Traduction.translate(language, 'january').substring(0, 3);
      case 2:
          return Traduction.translate(language, 'february').substring(0, 3);
      case 3:
          return Traduction.translate(language, 'march').substring(0, 3);
      case 4:
          return Traduction.translate(language, 'april').substring(0, 3);
      case 5:
          return Traduction.translate(language, 'may').substring(0, 3);
      case 6:
          return Traduction.translate(language, 'june').substring(0, 3);
      case 7:
          return Traduction.translate(language, 'july').substring(0, 3);
      case 8:
          return Traduction.translate(language, 'august').substring(0, 3);
      case 9:
          return Traduction.translate(language, 'september').substring(0, 3);
      case 10:
          return Traduction.translate(language, 'october').substring(0, 3);
      case 11:
          return Traduction.translate(language, 'november').substring(0, 3);
      case 12:
          return Traduction.translate(language, 'december').substring(0, 3);
      default:
          break;
    }
  }

  // Timeline progress calculation based on the StartDate and EndDate
  progressCalculation(startDate, endDate) {
    let progress;
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if(startDate && endDate) {
      // If Start Date is after Now
      if(now.getTime() <= start.getTime()) {
        progress = 0;
      }
      // If End Date is before Now
      else if(now.getTime() >= end.getTime()) {
        progress = 100;
      }
      else {
        progress = (now.getTime() - start.getTime()) * 100 / (end.getTime() - start.getTime());
      }
    }
    else {
      progress = 0;
    }
    
    return progress;
  }

  templateTimeline(language, view, status, startDate, endDate) {
    let progress, label, color, style;

    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = new Date();

    const startDay = start.getDate();
    const startMonth = start.getMonth()+1;
    const startYear = start.getFullYear();

    const endDay = end.getDate();
    const endMonth = end.getMonth()+1;
    const endYear = end.getFullYear();

    // Define Timeline attributes (progress, label, color, style)
    if(status && startDate && endDate) {
      // EndDate & Today on the same Year
      if(endYear === today.getFullYear()) {
        // StartDate & EndDate on the same Year
        if(startYear === endYear) {
          // StartDate & EndDate on the same Month
          if(startMonth === endMonth) {
            label = startDay + ' - ' + endDay + ' ' + this.getMonthName(startMonth);
          }
          // StartDate & EndDate not on the same Month
          else {
            label = startDay + ' ' + this.getMonthName(startMonth) + ' - ' + endDay + ' ' + this.getMonthName(endMonth);
          }
        }
        // StartDate & EndDate not on the same Year
        else {
          label = startDay + ' ' + this.getMonthName(startMonth) + ' ' + startYear + ' - ' + endDay + ' ' + this.getMonthName(endMonth) + ' ' + endYear;
        }
      }
      else {
        label = startDay + ' ' + this.getMonthName(startMonth) + ' ' + startYear + ' - ' + endDay + ' ' + this.getMonthName(endMonth) + ' ' + endYear;
      }
      
      // Status Cancelled/On Hold
      if(status.Id === 3 || status.Id === 4) {
        color = "grey";
        style = "bg-white brd-dashed-grey-hold";
        progress = 100;
      }
      // Status Completed
      else if(status.Id === 5) {
        color = "white";
        style = "bg-success";
        progress = 100;

        // Specific Label for Status Completed
        if(endYear === today.getFullYear()) {
          label = endDay + ' ' + this.getMonthName(endMonth);
        }
        else {
          label = endDay + ' ' + this.getMonthName(endMonth) + ' ' + endYear;
        }
      }
      // Status Delayed (if EndDate is before Today - 1 day/86 400 000 milliseconds)
      else if(end.getTime() + 86400000 < today.getTime()) {
        color = "white";
        style = "bg-danger";
        progress = this.progressCalculation(this.formatDate(startDate), this.formatDate(endDate));

        // Specific Label for Status Delayed
        if(endYear === today.getFullYear()) {
          label = endDay + ' ' + this.getMonthName(endMonth) + ' - ' + this.getDayDifference(language, end, today);
        }
        else {
          label = endDay + ' ' + this.getMonthName(endMonth) + ' ' + endYear + ' - ' + this.getDayDifference(language, end, today);
        }
      }
      else {
        // Status To be done
        if(status.Id === 1) {
          color = "black";
          style = "bg-grey opacity60";
          progress = this.progressCalculation(this.formatDate(startDate), this.formatDate(endDate));
        }
        // Status In Progress
        else if(status.Id === 2) {
          color = "black";
          style = "bg-blue opacity60";
          progress = this.progressCalculation(this.formatDate(startDate), this.formatDate(endDate));
        }
      }
    }
    // If Status, StartDate or EndDate is null
    else {
      color = "black";
      style = "";
      progress = 0;
      label = null;
    }

    // Display Timeline with attributes
    if(!endDate) {
      return null;
    }
    // Status Cancelled
    else if(status.Id === 4) {
      if(view === 'Minicard') {
        return (<div className="progressTimelineMinicard marginauto">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel timelineLabelCancelled " + color : "timelineLabel timelineLabelCancelled " + color}>{label}</div>
        </div>);
      }
      else if(view === 'MinicardKanban') {
        return (<div className="progressTimelineKanban">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel timelineLabelCancelled " + color : "timelineLabel timelineLabelCancelled " + color}>{label}</div>
        </div>);
      }
      else {
        return (<div className="progressBarTimeline">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel timelineLabelCancelled " + color : "timelineLabel timelineLabelCancelled " + color}>{label}</div>
        </div>);
      }
    }
    else {
      if(view === 'Minicard') {
        return (<div className="progressTimelineMinicard marginauto">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel " + color : "timelineLabel " + color}>{label}</div>
        </div>);
      }
      else if(view === 'MinicardKanban') {
        return (<div className="progressTimelineKanban">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel " + color : "timelineLabel " + color}>{label}</div>
        </div>);
      }
      else {
        return (<div className="progressBarTimeline">
          <div className={"progress-bar " + style} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          <div className={view === 'Dashboard' ? "minitableTimelineLabel " + color : "timelineLabel " + color}>{label}</div>
        </div>);
      }
    }
  }

  render() {
    const { language, view, status, startDate, endDate } = this.state;

    return this.templateTimeline(language, view, status, startDate, endDate);
  }
}

export default Timeline;