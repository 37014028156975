import translationEn from "./Translations/en.json";
import translationFr from "./Translations/fr.json";
import translationEs from "./Translations/es.json";

export const Traduction = {
  translate
};

function translate(language, labelKey) {
  let file;

  switch(language) {
    case "English":
        file = translationEn;
        break;
    case "Français":
        file = translationFr;
        break;
    case "Spanish":
        file = translationEs;
        break;
    default:
        file = translationEn;
        break;
  }

  if(file[labelKey]) {
    return file[labelKey];
  }
  else {
    return labelKey;
  }
}

export default Traduction;