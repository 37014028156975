import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import IndicatorsRights from './IndicatorsRights';
import FiltersViewDashboard from './FiltersViewDashboard';
import FiltersCurrentViewDashboard from './FiltersCurrentViewDashboard';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Dashboard from './Dashboard';

const API_info = '/WebAppService/GetCardHomeInformation';
const API_notifications = '/WebAppService/SubscribeNotificationRuleForUser';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      cardInfo: {},
      cardTitle: null,
      editable: false,
      guestLicence: null,
      favorite: null,
      parents: [],
      rights: [],
      subscribe: null,
      warnings: 0,
      blocks: [],
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView : {},
      listblocks: [],
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);

    // Actions
    this.subscribeNotifications = this.subscribeNotifications.bind(this);
    this.editDashboard = this.editDashboard.bind(this);
    this.addMiniBlock = this.addMiniBlock.bind(this);
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    this.views = React.createRef();
    this.dashboard = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, viewId);

    const cardInfo = this.state.cardInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(cardInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewDashboardId === viewId)) {
        currentView = views.find(view => view.ViewDashboardId === viewId);
      }
      else if(views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId)) {
        currentView = views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId);
      }

      if(!viewId && (currentView.ViewDashboardId && viewId !== currentView.ViewDashboardId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewDashboardId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });
      
      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, viewId);
    }
    if(viewId !== oldViewId) {
      const cardInfo = this.state.cardInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewDashboardId === viewId)) {
        currentView = views.find(view => view.ViewDashboardId === viewId);
      }
      else if(views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId)) {
        currentView = views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const cardInfo = data.GetCardHomeInformationResult;

      if(cardInfo) {
        const cardTitle = cardInfo.Name;
        const guestLicence = cardInfo.GuestLicence;
        const favorite = cardInfo.IsFavorite;
        const parents = cardInfo.Parents;
        const rights = cardInfo.AuthorizedUsers;
        const subscribe = cardInfo.HasNotification;
        const warnings = cardInfo.Warnings;
        const blocks = cardInfo.Blocks;
        const views = cardInfo.DashboardViews;
        const defaultViewId = cardInfo.DefaultDashBoardViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewDashboardId === viewId)) {
          currentView = views.find(view => view.ViewDashboardId === viewId);
        }
        else if(views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId)) {
          currentView = views.find(view => view.ViewDashboardId === cardInfo.DefaultDashBoardViewId);
        }

        // Get Listblocks of current View
        let listblocks = currentView.ListBlocks;

        this.setState({ cardInfo, cardTitle, guestLicence, favorite, parents, rights, subscribe, warnings, blocks, views, defaultViewId, viewId, currentView, listblocks });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const cardContent = data.GetCardHomeInformationResult;

      if(cardContent) {
        const blocks = cardContent.Blocks;

        // Get Listblocks of currentview
        let listblocks = currentView.ListBlocks;
        
        this.setState({ blocks, viewId: currentView.ViewDashboardId, currentView, listblocks, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Subscribe Object Notifications for User
  async subscribeNotifications() {
    const { login, authId, language, itemId, itemType, subscribe } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'Subscribe': !subscribe
      })
    };
    
    try{
      const response = await fetch(API_notifications, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const subscribe = data.SubscribeNotificationRuleForUserResult;
      
      if(subscribe) {
        this.setState({ subscribe: !subscribe, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  editDashboard() {
    const editable = this.state.editable;

    if(this.dashboard.current) {
      this.dashboard.current.editDashboard(!editable);
    }

    // Update HTML element class
    if(document.getElementsByClassName("miniblockTotalRows")) {
      let elements = document.getElementsByClassName("miniblockTotalRows");

      Array.from(elements).forEach((element) => {
        if(!editable) {
          element.classList.add("hidden");
        }
        else {
          element.classList.remove("hidden");
        }
      });
    }
    if(document.getElementsByClassName("miniblockDisplayedRows")) {
      let elements = document.getElementsByClassName("miniblockDisplayedRows");

      Array.from(elements).forEach((element) => {
        if(!editable) {
          element.classList.add("hidden");
        }
        else {
          element.classList.remove("hidden");
        }
      });
    }
    if(document.getElementsByClassName("miniblockFilters")) {
      let elements = document.getElementsByClassName("miniblockFilters");

      Array.from(elements).forEach((element) => {
        if(!editable) {
          element.classList.add("hidden");
        }
        else {
          element.classList.remove("hidden");
        }
      });
    }
    if(document.getElementsByClassName("iconEdit")) {
      let elements = document.getElementsByClassName("iconEdit");

      Array.from(elements).forEach((element) => {
        if(!editable) {
          element.classList.remove("hidden");
        }
        else {
          element.classList.add("hidden");
        }
      });
    }
    if(document.getElementsByClassName("iconClear")) {
      let elements = document.getElementsByClassName("iconClear");

      Array.from(elements).forEach((element) => {
        if(!editable) {
          element.classList.remove("hidden");
        }
        else {
          element.classList.add("hidden");
        }
      });
    }

    this.setState({ editable: !editable });
  }

  addMiniBlock() {
    if(this.dashboard.current) {
      this.dashboard.current.addPanelPopup();
    }
  }

  async displayViews() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
  }

  async hideViews() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;
    
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
  }

  changeView(view) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // this.setState({ currentView: view });

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/Home?viewId=${view.ViewDashboardId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    const { language, itemId, itemType, blockType, cardInfo, cardTitle, editable, guestLicence, favorite, parents, rights, subscribe, warnings, blocks, displayViews, views, defaultViewId, currentView, listblocks, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{cardTitle} • {Traduction.translate(language, 'home')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {cardInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {cardInfo.HasRightOnItem !== false && <div className="block">
          {/* Card Header */}
          <div className="cardHeader">
            <div className="flex inline-flex width100p">
              {/* Card Title */}
              <div className="flex-start width100p">
                <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={cardTitle} BlockType={blockType} CurrentView={currentView} Blocks={cardInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
              </div>
              {/* Card Indicators */}
              <div className="blockIndicators">
                {/* Rights */}
                {itemType !== 'Meeting' && !guestLicence && 
                  <IndicatorsRights ItemId={itemId} ItemType={itemType} ItemTitle={cardTitle} Rights={rights}></IndicatorsRights>
                }
                {/* Notifications */}
                <div className="" onClick={(e) => this.subscribeNotifications()}>
                  {subscribe && <div className="cursor"><span className="iconMute mediumIcons"/></div>}
                  {!subscribe && <div className="cursor"><span className="iconBell mediumIcons"/></div>}
                </div>
              </div>
            </div>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="cardBody">
            {/* Filters Views */}
            {displayViews && <FiltersViewDashboard ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersViewDashboard>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentViewDashboard ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentViewDashboard>}

                {editable && !guestLicence && <span className="iconButtonPlus mediumIcons ml5 mr10 cursor" onClick={(e) => this.addMiniBlock()}/>}
                {editable && !guestLicence && <Button className="dashboardButton" variant="primary" onClick={(e) => this.editDashboard()}>{Traduction.translate(language, 'view')}</Button>}
                {!editable && !guestLicence && <Button className="dashboardButton ml5" variant="primary" onClick={(e) => this.editDashboard()}>{Traduction.translate(language, 'edition')}</Button>}
              </div>
            
              {/* Card Block Content */}
              <div className="cardComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Dashboard Component */}
                <Dashboard ref={this.dashboard} ItemId={itemId} ItemType={itemType} Editable={editable} GuestLicence={guestLicence} BlocksContent={blocks} CurrentView={currentView} Views={views} onViewChange={this.updateView}></Dashboard>
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default Card;