import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Form, FormGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import MiniCard from './MiniCard';
import LoadingSpinner from './LoadingSpinner';

const API = '/WebAppService/GetSearchResults';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      search: '',
      items: [],
      results: [],
      filter: null,
      filtered: [],
      isLoading: false,
      error: null
    };

    this.getData = this.getData.bind(this);
    this.buildResults = this.buildResults.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.filterResultsByType = this.filterResultsByType.bind(this);
    this.getObjectsNumberByType = this.getObjectsNumberByType.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    let search = '';

    // const search = this.props.match.params.search;

    // Get Params from Query string url
    if(this.props.location.search) {
      const params = queryString.parse(this.props.location.search);
      search = params.search;
    }
    
    this.setState({ login, authId, language, search, filter: null });

    await this.getData(login, authId, search);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    
    // Get old Params from Query string url
    const old_params = queryString.parse(prevProps.location.search);
    const old_search = old_params.search;

    // Get Params from Query string url
    const params = queryString.parse(this.props.location.search);
    const search = params.search;

    if(search !== old_search) {
      await this.getData(login, authId, search);
    }
  }

  async getData(login, authId, search) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'Pattern': search
      })
    };
    
    try {
      const response = await fetch(API, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const items = data.GetSearchResultsResult;

      if(items) {
        const results = this.buildResults(items);
        const filtered = this.buildResults(items);

        this.setState({ items, results, filtered, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  buildResults(items) {
    let results = [];
    let result, type;

    // Build Results Minicards Data structure based on Items array
    items.forEach(item => {
      result = item.Rows.map(row => {
        return row.Cells.reduce((acc, elem) => {
          // Add the Column Name/Values to the reduced Table
          acc[elem.ColumnName] = elem.Value;

          // Add Item Type
          acc['Item_Type'] = row.ItemType;

          // Store the Row ItemType
          type = row.ItemType;

          return acc;
        }, {});
      });

      // Push Items grouped by Type inside Results
      results.push({ "Items": result, "Type": type });
    });

    return results;
  }

  changeSearch(event) {
    this.setState({ search: event.target.value });
  }

  async handleSubmit(event) {
    const search = this.state.search;

    event.preventDefault();

    this.setState({ filter: null });

    if(search !== '') {
      // Redirect to SearchResults Page
      this.props.history.push(`/SearchResults?search=${search}`);
    }
  }

  // Filter Minicards results by selected type
  filterResultsByType(type) {
    const results = this.state.results;
    let filtered = [];

    if(results.find(result => result.Type === type)) {
      filtered.push(results.find(result => result.Type === type));
    }
    else if(type === 'Axe') {
      results.forEach(result => {
        if(result.Type !== 'AgileBoard' && result.Type !== 'Business_Line' && result.Type !== 'Project' && result.Type !== 'Workpackage' && result.Type !== 'BudgetCode' && result.Type !== 'Entity' && result.Type !== 'Meeting' && result.Type !== 'Resource') {
          filtered.push(result);
        }
      });
    }

    this.setState({ filter: type, filtered });
  }

  // Get the number of Objects by Type
  getObjectsNumberByType(type) {
    const results = this.state.results;
    let counter = 0;

    if(results.find(result => result.Type === type)) {
      counter = results.find(result => result.Type === type).Items.length;
    }
    else if(type === 'Axe') {
      results.forEach(result => {
        if(result.Type !== 'AgileBoard' && result.Type !== 'Business_Line' && result.Type !== 'Project' && result.Type !== 'Workpackage' && result.Type !== 'BudgetCode' && result.Type !== 'Entity' && result.Type !== 'Meeting' && result.Type !== 'Resource') {
          counter = counter + result.Items.length;
        }
      });
    }

    return counter;
  }

  render() {
    let { language, search, items, results, filter, filtered, isLoading, error } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        {search && <MetaTags><title>{Traduction.translate(language, 'search')} • {search}</title></MetaTags>}
        {!search && <MetaTags><title>{Traduction.translate(language, 'search')}</title></MetaTags>}

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* Block */}
        <div className="block">
          {/* Search Content */}
          <div className="searchContent">
            <div className="row">
              {/* Title */}
              <div className="col-md-12"><div className="searchTitle">{Traduction.translate(language, 'search')}</div></div>

              <div className="col-md-6 mt10">
                {/* Search input */}
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group className="searchbar">
                    <Form.Control type="text" id="searchHome" name="search" value={search} placeholder={Traduction.translate(language, 'search_placeholder')} onChange={this.changeSearch} />
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6"></div>
            </div>

            {/* Objects number and filter */}
            {items && <div className="searchResults">
              <div className="row">
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Business_Line' ? " bold" : "") + (this.getObjectsNumberByType('Business_Line') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Business_Line')}>
                    <span className="iconBusinessLine iconsTitle" alt="Business Line"></span>
                    {this.getObjectsNumberByType('Business_Line') <= 1 && <span className="">{Traduction.translate(language, 'business_line') + " (" + this.getObjectsNumberByType('Business_Line') + ")"}</span>}
                    {this.getObjectsNumberByType('Business_Line') > 1 && <span className="">{Traduction.translate(language, 'business_lines') + " (" + this.getObjectsNumberByType('Business_Line') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Project' ? " bold" : "") + (this.getObjectsNumberByType('Project') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Project')}>
                    <span className="iconProject cursor iconsTitle" alt="Project"></span>
                    {this.getObjectsNumberByType('Project') <= 1 && <span className="">{Traduction.translate(language, 'project') + " (" + this.getObjectsNumberByType('Project') + ")"}</span>}
                    {this.getObjectsNumberByType('Project') > 1 && <span className="">{Traduction.translate(language, 'projects') + " (" + this.getObjectsNumberByType('Project') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Workpackage' ? " bold" : "") + (this.getObjectsNumberByType('Workpackage') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Workpackage')}>
                    <span className="iconWorkpackage cursor iconsTitle" alt="Workpackage"></span>
                    {this.getObjectsNumberByType('Workpackage') <= 1 && <span className="">{Traduction.translate(language, 'workpackage') + " ("  + this.getObjectsNumberByType('Workpackage') + ")"}</span>}
                    {this.getObjectsNumberByType('Workpackage') > 1 && <span className="">{Traduction.translate(language, 'workpackages') + " ("  + this.getObjectsNumberByType('Workpackage') + ")"}</span>}
                  </div>
                </div>
                {/* <div className="col col-md-auto col-sm-auto">
                  <span className={"mh10 cursor" + (filter === 'Action' ? " bold" : "") + (this.getObjectsNumberByType('Action') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Action')}>
                    <i className="iconAction cursor iconsTitle" alt="Action"/>Action
                    ({this.getObjectsNumberByType('Action')})
                  </span>
                </div> */}
                {/* <div className="col col-md-auto col-sm-auto">
                  <span className={"mh10 cursor" + (filter === 'Task' ? " bold" : "") + (this.getObjectsNumberByType('Task') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Task')}>
                    <i className="iconTask cursor iconsTitle" alt="Task"/>Task
                    ({this.getObjectsNumberByType('Task')})
                  </span>
                </div> */}
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Entity' ? " bold" : "") + (this.getObjectsNumberByType('Entity') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Entity')}>
                    <span className="iconEntity iconsTitle" alt="Entity"></span>
                    {this.getObjectsNumberByType('Entity') <= 1 && <span className="">{Traduction.translate(language, 'entity') + " (" + this.getObjectsNumberByType('Entity') + ")"}</span>}
                    {this.getObjectsNumberByType('Entity') > 1 && <span className="">{Traduction.translate(language, 'entities') + " (" + this.getObjectsNumberByType('Entity') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Resource' ? " bold" : "") + (this.getObjectsNumberByType('Resource') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Resource')}>
                    <span className="iconResource iconsTitle" alt="Resource"></span>
                    {this.getObjectsNumberByType('Resource') <= 1 && <span className="">{Traduction.translate(language, 'resource') + " (" + this.getObjectsNumberByType('Resource') + ")"}</span>}
                    {this.getObjectsNumberByType('Resource') > 1 && <span className="">{Traduction.translate(language, 'resources') + " (" + this.getObjectsNumberByType('Resource') + ")"}</span>}
                  </div>
                </div>
                {/* <div className="col col-md-auto col-sm-auto">
                  <span className={"mh10 cursor" + (filter === 'User' ? " bold" : "") + (this.getObjectsNumberByType('User') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('User')}>
                    <i className="iconUser cursor iconsTitle" alt="User"/>User
                    ({this.getObjectsNumberByType('User')})
                  </span>
                </div> */}
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'AgileBoard' ? " bold" : "") + (this.getObjectsNumberByType('AgileBoard') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('AgileBoard')}>
                    <span className="iconAgileBoardBlue iconsTitle" alt="AgileBoard"></span>
                    {this.getObjectsNumberByType('AgileBoard') <= 1 && <span className="">{Traduction.translate(language, 'agile_board') + " (" + this.getObjectsNumberByType('AgileBoard') + ")"}</span>}
                    {this.getObjectsNumberByType('AgileBoard') > 1 && <span className="">{Traduction.translate(language, 'agile_boards') + " (" + this.getObjectsNumberByType('AgileBoard') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'BudgetCode' ? " bold" : "") + (this.getObjectsNumberByType('BudgetCode') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('BudgetCode')}>
                    <span className="iconBudgetCode iconsTitle" alt="Budget Code"></span>
                    {this.getObjectsNumberByType('BudgetCode') <= 1 && <span className="">{Traduction.translate(language, 'budget_code') + " (" + this.getObjectsNumberByType('BudgetCode') + ")"}</span>}
                    {this.getObjectsNumberByType('BudgetCode') > 1 && <span className="">{Traduction.translate(language, 'budget_codes') + " (" + this.getObjectsNumberByType('BudgetCode') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Axe' ? " bold" : "") + (this.getObjectsNumberByType('Axe') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Axe')}>
                    <span className="iconAxe iconsTitle" alt="Axe"></span>
                    {this.getObjectsNumberByType('Axe') <= 1 && <span className="">{Traduction.translate(language, 'axis') + " (" + this.getObjectsNumberByType('Axe') + ")"}</span>}
                    {this.getObjectsNumberByType('Axe') > 1 && <span className="">{Traduction.translate(language, 'axes') + " (" + this.getObjectsNumberByType('Axe') + ")"}</span>}
                  </div>
                </div>
                <div className="col col-md-auto col-sm-auto">
                  <div className={"cursor" + (filter === 'Meeting' ? " bold" : "") + (this.getObjectsNumberByType('Meeting') === 0 ? " opacity30" : "")} onClick={(e) => this.filterResultsByType('Meeting')}>
                    <span className="iconMeeting iconsTitle" alt="Meeting"></span>
                    {this.getObjectsNumberByType('Meeting') <= 1 && <span className="">{Traduction.translate(language, 'meeting') + " (" + this.getObjectsNumberByType('Meeting') + ")"}</span>}
                    {this.getObjectsNumberByType('Meeting') > 1 && <span className="">{Traduction.translate(language, 'meetings') + " (" + this.getObjectsNumberByType('Meeting') + ")"}</span>}
                  </div>
                </div>
              </div>
            </div>}

            {/* Loading Spinner */}
            {isLoading && <div className="center mt30 mb20">
              <span className=""><LoadingSpinner></LoadingSpinner></span>
              <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
            </div>}

            {/* Search Results */}
            <div className="minicardsWrapper">
              {filtered && filtered.map((result, index) =>
                <div key={index} className="row col-md-12">
                  {result.Items.map(item =>
                    <div key={item.Item_Type + item.Item_ID} className="minicard">
                      {/* All Item_Types except BudgetCode */}
                      <Link to={`/Card/${item.Item_Type}/${item.Item_ID.substring(1)}/Home`}>
                        <MiniCard Content={item} Name={item.Name} Id={item.Item_ID.substring(1)} Type={item.Item_Type} Status={item.Status} StartDate={item.StartDate} EndDate={item.EndDate} Progress={item.Progress}></MiniCard>
                      </Link>
                      {/* BudgetCode */}
                      {/* {item.Item_Type === 'BudgetCode' && 
                        <Link to={`/Card/${item.Item_Type}/${item.Item_ID.substring(2)}/Home`}>
                          <MiniCard Content={item} Name={item.Name} Id={item.Item_ID.substring(2)} Type={item.Item_Type} StartDate={item.StartDate} EndDate={item.EndDate} Progress={item.Progress}></MiniCard>
                        </Link>
                      } */}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Empty Results */}
            {!isLoading && items.length === 0 && 
              <div className="searchEmpty">{Traduction.translate(language, 'no_result_found')}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResults;