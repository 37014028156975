import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import PopupConfirmation from './PopupConfirmation';

const API = '/WebAppService/GetCardBlock';
const API_templates = '/WebAppService/GetTemplatesPPT';
const API_build = '/WebAppService/BuildPowerPointPresentation';
const API_delete = '/WebAppService/DeletePresentation';
const API_duplicate = '/WebAppService/DuplicatePresentation';

class BlockCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockContent: {},
      editable: null,
      favorite: null,
      warnings: 0,
      parents: [],
      headers: [],
      communications: [],
      communicationIndex: null,
      addPresentationPopup: false,
      availableTemplates: [],
      confirm: false,
      configId: null,
      newPresentationTemplate: null,
      templateSelected: {},
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getData = this.getData.bind(this);
    this.getAvailableTemplates = this.getAvailableTemplates.bind(this);

    // Actions
    this.buildPresentation = this.buildPresentation.bind(this);
    this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);
    this.downloadBlobFile = this.downloadBlobFile.bind(this);
    this.duplicatePresentation = this.duplicatePresentation.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deletePresentation = this.deletePresentation.bind(this);
    this.updateSelectedPresentation = this.updateSelectedPresentation.bind(this);

    // Template
    this.templateAddPresentationPopup = this.templateAddPresentationPopup.bind(this);
    this.templatePopover = this.templatePopover.bind(this);
    this.closeAddPopup = this.closeAddPopup.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    await this.getData(login, authId, itemId, itemType, blockType);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });

      await this.getData(login, authId, itemId, itemType, blockType);
    }
  }

  // Get Block Content from the API
  async getData(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const blockContent = data.GetCardBlockResult;

      if(blockContent) {
        const itemTitle = blockContent.ObjectName;
        const editable = blockContent.InsertDeleteAllowed;
        const favorite = blockContent.IsFavorite;
        const warnings = blockContent.Warnings;
        const parents = blockContent.Parents;
        const headers = blockContent.Headers;
        const communications = blockContent.Communications;
  
        this.setState({ blockContent, itemTitle, editable, favorite, warnings, parents, headers, communications, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getAvailableTemplates(itemType) {
    const { login, authId, language } = this.state;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_templates, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetTemplatesPPTResult;

      if(result) {
        this.setState({ availableTemplates: result });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async buildPresentation(configId) {
    const { login, authId, language } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'PresentationConfigID': configId
      }),
      timeout: 600000
    };

    try{
      const response = await fetch(API_build, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.BuildPowerPointPresentationResult;

      if(result) {
        if(result.IsValid === true) {
          const url = result.Url;
          const fileBase64 = result.FileBase64;
          const fileContent = result.FileContents;
          const type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          const fileName = result.FileName;

          // Convert Base64 File to ArrayBuffer
          // const arrayBuffer = this.base64ToArrayBuffer(fileBase64);

          // Create and Download Blob File
          this.downloadBlobFile(fileContent, type, fileName);
        }
        else {
          this.setState({ errors: result.Errors });
        }

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  base64ToArrayBuffer(fileBase64) {
    const binaryString = window.atob(fileBase64);
    const bytes = new Uint8Array(fileBase64.length);

    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  downloadBlobFile(arrayBuffer, type, fileName) {
    // Convert Bytes Array to Bytes
    const bytes = new Uint8Array(arrayBuffer);

    // Create Blob from Bytes
    const blob = new Blob([bytes], { type: type });
    
    // Create File link
    const link = document.createElement('a');

    if(link.download !== undefined) {
      // Define File link attributes
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.visibility = 'hidden';

      // Simulate click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async duplicatePresentation(configId, fromTemplate) {
    const { login, authId, language, itemType, itemId, blockType } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'PresentationId': configId,
        'CardItemType': itemType,
        'CardItemId': itemId,
        'FromTemplate': fromTemplate
      })
    };

    try{
      const response = await fetch(API_duplicate, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.DuplicatePresentationResult;

      if(result) {
        if(result.IsValid === true) {
          await this.getData(login, authId, itemId, itemType, blockType);
        }
        else {
          this.setState({ errors: result.Errors });
        }

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  confirmDelete(configId) {
    this.setState({ confirm: true, configId });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  async deletePresentation() {
    const { login, authId, language, configId } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CommunicationId': configId
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const result = data.DeletePresentationResult;
      let communications = this.state.communications;

      if(result) {
        if(result.IsValid === true) {
          if(communications.find(item => item.ConfigID === configId)) {
            let index = communications.findIndex(item => item.ConfigID === configId);

            // Delete Presentation from list
            communications.splice(index, 1);

            this.setState({ communications });
          }
        }
        else {
          this.setState({ errors: result.Errors });
        }

        this.setState({ confirm: false, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  updateSelectedPresentation(index) {
    this.setState({ communicationIndex: index });
  }

  templateAddPresentationPopup() {
    const { language, itemId, itemType, availableTemplates, newPresentationTemplate, templateSelected } = this.state;

    return(<div className="flex flex-column popupAddPresentation">
      {/* Label */}
      {!newPresentationTemplate && <div className="addPresentationLabel mb10">{Traduction.translate(language, 'create_new_presentation')} {Traduction.translate(language, 'new')} {Traduction.translate(language, 'presentation')}</div>}
      {/* Available Presentations */}
      {!newPresentationTemplate && <div className="newPresentationTemplates">
        <Link to={`/Card/${itemType}/${itemId}/Communication/-1`}>
          <div className="newPresentationEmptyTemplate">
            <div className="addPresentationLabel mv10">{Traduction.translate(language, 'empty_presentation')}</div>
          </div>
        </Link>
        <div className="newPresentationTemplate" onClick={() => { this.setState({ newPresentationTemplate: 'template' }); this.getAvailableTemplates(itemType) }}>
          <span className="iconNewProjetTemplate veryBigIcons" alt="Template"/>
          <div className="addPresentationLabel mv10">{Traduction.translate(language, 'from_template')}</div>
        </div>
      </div>}
      {newPresentationTemplate === 'template' && <div className="flex flex-column popupPresentationTemplate">
        {/* Label */}
        <div className="addPresentationLabel mb10">{Traduction.translate(language, 'choose_presentation_template')}</div>
        {/* Available Templates */}
        <div className="flex flex-column scrollbar-y overflow-y mb10">
          {availableTemplates.map((template, index) => {
            return (<div key={index} className={(template.CommunicationID === templateSelected.CommunicationID ? "presentationTemplateSelected" : "presentationTemplate")} onClick={() => this.setState({ templateSelected: template })}>
              <span className="iconPPT verysmallIcons"></span>
              <div className="templateLabel">{template.Name}</div>
            </div>)
          })}
        </div>
      </div>}
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {newPresentationTemplate === 'template' && Object.keys(templateSelected).length !== 0 && 
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.duplicatePresentation(templateSelected.CommunicationID, true); this.closeAddPopup() }}>{Traduction.translate(language, 'validate')}</Button>
        }
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeAddPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePopover(presentation) {
    const { language, itemId, itemType, isLoading } = this.state;

    const popover = (
      <Popover id="popover-basic" className="bg-transparent border-none box-shadow-none zindex2">
        <Popover.Content>
          <div className="flex flex-column">
            {/* Build Presentation Button */}
            {!isLoading && <div className="mb5">
              <Button className="fs12 bold brd-radius width100" variant="primary" onClick={(e) => this.buildPresentation(presentation.ConfigID)}>{Traduction.translate(language, 'build')}</Button>
            </div>}
            
            {/* Edit Presentation Button */}
            {!isLoading && presentation.ReadOnly === false && 
              <Link to={`/Card/${itemType}/${itemId}/Communication/${presentation.ConfigID}`}>
                <Button className="btn-Communication width100" variant="warning">{Traduction.translate(language, 'edit')}</Button>
              </Link>
            }

            {/* Duplicate Presentation Button */}
            {!isLoading && <Button className="btn-Communication width100" variant="secondary" onClick={(e) => this.duplicatePresentation(presentation.ConfigID)}>{Traduction.translate(language, 'duplicate')}</Button>}

            {/* Delete Presentation Button */}
            {!isLoading && presentation.ReadOnly === false && <Button className="btn-Communication width100" variant="danger" onClick={(e) => this.confirmDelete(presentation.ConfigID)}>{Traduction.translate(language, 'delete')}</Button>}

            {/* Loading Spinner */}
            {isLoading && <div className="spinnerCommunicationActions"><LoadingSpinner></LoadingSpinner></div>}
          </div>
        </Popover.Content>
      </Popover>
    );
    
    return popover;
  }

  closeAddPopup() {
    this.setState({ addPresentationPopup: false, newPresentationTemplate: null, templateSelected: {} });
  }

  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockContent, editable, favorite, warnings, parents, headers, communications, communicationIndex, addPresentationPopup, confirm, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'communication')}</title></MetaTags>
        
        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockContent.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockContent.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} Blocks={blockContent.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Card Block Content */}
            <div className="blockContent">
              {/* Title */}
              <div className="flex align-items-center mb10">
                {/* Label */}
                <Form.Label className="fs16 bold blue mb0">{Traduction.translate(language, 'presentations')}</Form.Label>
                {/* Add Button */}
                <span className="iconButtonPlus iconsFilter mh10 cursor" onClick={() => this.setState({ addPresentationPopup: true })}></span>
              </div>

              {/* Errors */}
              {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

              {/* Add Presentation Popup */}
              {addPresentationPopup === true && <div className="addPresentationPopup">
                <div className="addPresentationInnerPopup">{this.templateAddPresentationPopup()}</div>
              </div>}

              {/* Delete Confirmation Popup */}
              {confirm && <PopupConfirmation Message={Traduction.translate(language, 'would_you_like_to_delete_presentation')} onMessageConfirm={this.deletePresentation} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

              {/* Presentations List */}
              <div className="blockCommunicationList">
                {communications.map((item, index) => (
                    <OverlayTrigger key={index} trigger="click" rootClose placement="right" overlay={this.templatePopover(item)}>
                      <div className={(index === communicationIndex ? "minicardPresentationSelected" : "minicardPresentation")} onClick={(e) => this.updateSelectedPresentation(index)}>
                        <span className="iconPPT mediumIcons mv10"/>
                        <div className="communicationTitle">{item.Name}</div>
                      </div>
                    </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockCommunication;