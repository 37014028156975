import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import ErrorModification from './ErrorModification';
import IconCloud from '../Images/IconCloud.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import TextEditor from './TextEditor';

const API = '/WebAppService/GetCardBlock';
const API_save = '/WebAppService/GetPropagationsAndSaveModification';

class BlockMeteo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockContent: {},
      guestLicence: null,
      favorite: null,
      warnings: 0,
      parents: [],
      columns: [],
      rows: [],
      fields: [],
      errors: []
    };

    // Data Structure
    this.getData = this.getData.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getFieldEditable = this.getFieldEditable.bind(this);

    // Actions
    this.changeMeteo = this.changeMeteo.bind(this);
    this.changeTrend = this.changeTrend.bind(this);
    this.changeFields = this.changeFields.bind(this);
    this.remainingCharacter = this.remainingCharacter.bind(this);
    this.saveModification = this.saveModification.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    await this.getData(login, authId, itemId, itemType, blockType);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });

      await this.getData(login, authId, itemId, itemType, blockType);
    }
  }

  // Get Block Content from the API
  async getData(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockResult;

      if(blockContent) {
        const itemTitle = blockContent.ObjectName;
        const guestLicence = blockContent.GuestLicence;
        const favorite = blockContent.IsFavorite;
        const warnings = blockContent.Warnings;
        const parents = blockContent.Parents;
        const tables = blockContent.Tables;
        let columns = [], rows = [];

        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        // Get Fields
        let fields = this.getFields(columns, rows);
  
        this.setState({ blockContent, itemTitle, guestLicence, favorite, warnings, parents, columns, rows, fields, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getFields(columns, rows) {
    let fields = [];

    // // Get Current View Columns
    // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
    //   columnsNames = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    // }

    // Loop through the columns to build and add them to the Grid
    for(let i=0; i < columns.length; i++) {
      let columnHeader = columns[i];
      
      // Find Column corresponding Cell
      if(rows[0].Cells.find(cell => cell.ColumnName === columns[i].FieldName)) {
        // Parse Objects for Meteo & Trend
        if(columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
          columnHeader.FieldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columns[i].FieldName).Value);
          columnHeader.OldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columns[i].FieldName).Value);
        }
        else {
          columnHeader.FieldValue = rows[0].Cells.find(cell => cell.ColumnName === columns[i].FieldName).Value;
          columnHeader.OldValue = rows[0].Cells.find(cell => cell.ColumnName === columns[i].FieldName).Value;
        }
      }
    
      fields.push(columnHeader);
    }

    return fields;
  }

  getFieldEditable(field) {
    const itemType = this.state.itemType;
    const columns = this.state.columns;
    let editionItemTypes;

    if(columns.find(column => column.FieldName === field.FieldName)) {
      editionItemTypes = columns.find(column => column.FieldName === field.FieldName).EditionItemTypes;
    }

    if(editionItemTypes.includes(itemType)) {
      return true;
    }
    else {
      return false;
    }
  }

  changeMeteo(id) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    let fields = this.state.fields;
    let field, columnName, oldValue, value;

    // Update Field Meteo
    if(fields.find(field => field.FieldName === 'Meteo')) {
      field = fields.find(field => field.FieldName === 'Meteo');

      columnName = field.FieldName;
      oldValue = JSON.stringify(field.FieldValue);
      value = JSON.stringify(field.RestrictedValues.find(value => value.Id === id));

      field.OldValue = field.FieldValue;
      field.FieldValue = field.RestrictedValues.find(value => value.Id === id);
    }

    this.setState({ fields });

    this.saveModification(itemId, itemType, columnName, oldValue, value);
  }

  changeTrend(id) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    let fields = this.state.fields;
    let field, columnName, oldValue, value;
    
    // Update Field Trend
    if(fields.find(field => field.FieldName === 'Trend')) {
      field = fields.find(field => field.FieldName === 'Trend');

      columnName = field.FieldName;
      oldValue = JSON.stringify(field.FieldValue);
      value = JSON.stringify(field.RestrictedValues.find(value => value.Id === id));

      field.OldValue = field.FieldValue;
      field.FieldValue = field.RestrictedValues.find(value => value.Id === id);
    }

    this.setState({ fields });
    
    this.saveModification(itemId, itemType, columnName, oldValue, value);
  }

  changeFields(event, fieldName) {
    let fields = this.state.fields;

    // Check if Field's number of characters is less than 1000
    if(event.target.value.length > 1000) {
      event.target.value = event.target.value.substring(0, 1000);
    }

    // Update Field value
    if(fields.find(field => field.FieldName === fieldName)) {
      fields.find(field => field.FieldName === fieldName).FieldValue = event.target.value;
    }
    
    this.setState({ fields });
  }

  remainingCharacter(value, max) {
    const language = this.state.language;
    let remaining = (max - value);
    
    if(remaining > 1) {
      return remaining + " " + Traduction.translate(language, 'remaining_characters');
    }
    else {
      return remaining + " " + Traduction.translate(language, 'remaining_character');
    }
  }

  async saveModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, fields } = this.state;
    let additionalContext = [];

    if(newValue !== oldValue) {
      // Request Options and Body
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Mode': 'Login',
          'Login': login,
          'Token': authId
        },
        body: JSON.stringify({
          'ItemType': itemType,
          'ItemId': itemId,
          'ColumnName': columnName,
          'OldValue': oldValue,
          'NewValue': newValue,
          'AdditionalContext': additionalContext
        })
      };
      
      try{
        const response = await fetch(API_save, requestOptions);

        if(!response.ok) {
          throw new Error('Something went wrong ...');
        }

        const data = await response.json();
        const results = data.GetPropagationsAndSaveModificationResult;

        results.forEach(result => {
          if(result.IsValid === true) {
            if(columnName === 'Previous_Period' || columnName === 'Coming_Period' || columnName === 'Sponsor_Feedback') {
              // Update Field with new value
              if(fields.find(field => field.FieldName === columnName)) {
                fields.find(field => field.FieldName === columnName).FieldValue = newValue;
                fields.find(field => field.FieldName === columnName).OldValue = newValue;
              }

              this.setState({ fields });
            }
          }
          else {
            // Update Field with old value
            if(fields.find(field => field.FieldName === columnName)) {
              fields.find(field => field.FieldName === columnName).FieldValue = fields.find(field => field.FieldName === columnName).OldValue;
            }

            this.setState({ fields });

            // Update Errors
            this.updateErrors(result.Errors);
          }
        });

      } catch(error) {
        this.setState({ error });
      }
    }
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockContent, guestLicence, favorite, warnings, parents, columns, rows, fields, errors } = this.state;

    // Filter Fields by Group
    const meteoField = fields.find(field => field.FieldName === 'Meteo');
    const trendField = fields.find(field => field.FieldName === 'Trend');
    const previousPeriodField = fields.find(field => field.FieldName === 'Previous_Period');
    const comingPeriodField = fields.find(field => field.FieldName === 'Coming_Period');
    const feedbackField = fields.find(field => field.FieldName === 'Sponsor_Feedback');

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'meteo')}</title></MetaTags>
        
        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockContent.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockContent.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} Blocks={blockContent.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Card Block Content */}
            <div className="blockContent">
              {/* Errors */}
              {errors.length > 0 && 
                <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
              }

              <Form className="formMeteo">
                <div className="row mh20 mb20">
                  {/* ----- Meteo ----- */}
                  <div className="col-md-6 pr20">
                    <Form.Group>
                      <Form.Label className="meteoLabel">{Traduction.translate(language, 'meteo')}</Form.Label>
                      <div className="row">
                        <div className="col-md-8 space-between mv10">
                          <div className={"center " + (meteoField.FieldValue.Id === 1 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeMeteo(1) : null}>
                            <img className="mediumIcons" src={IconSun} alt="Sun"/>
                          </div>
                          <div className={"center " + (meteoField.FieldValue.Id === 2 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeMeteo(2) : null}>
                            <img className="mediumIcons" src={IconCloud} alt="Cloud"/>
                          </div>
                          <div className={"center " + (meteoField.FieldValue.Id === 3 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeMeteo(3) : null}>
                            <img className="mediumIcons" src={IconRain} alt="Rain"/>
                          </div>
                          <div className={"center " + (meteoField.FieldValue.Id === 4 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeMeteo(4) : null}>
                            <img className="mediumIcons" src={IconThunder} alt="Thunder"/>
                          </div>
                          <div className={"center " + (meteoField.FieldValue.Id === 0 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeMeteo(0) : null}>
                            <span className="iconEmpty mediumIcons" alt="Empty"></span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  {/* ----- Trend ----- */}
                  <div className="col-md-6 pl20">
                    <Form.Group>
                      <Form.Label className="meteoLabel">{Traduction.translate(language, 'trend')}</Form.Label>
                      <div className="row">
                        <div className="col-md-6 space-between mv10">
                          <div className={"center " + (trendField.FieldValue.Id === 3 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeTrend(3) : null}>
                            <span className="iconTrendUp mediumIcons" alt="Arrow Up"></span>
                          </div>
                          <div className={"center " + (trendField.FieldValue.Id === 2 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeTrend(2) : null}>
                            <span className="iconTrendRight mediumIcons" alt="Arrow Right"></span>
                          </div>
                          <div className={"center " + (trendField.FieldValue.Id === 1 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeTrend(1) : null}>
                            <span className="iconTrendDown mediumIcons" alt="Arrow Down"></span>
                          </div>
                          <div className={"center " + (trendField.FieldValue.Id === 0 ? "selectedMeteo" : "opacity50 pb10")} onClick={!guestLicence ? (e) => this.changeTrend(0) : null}>
                            <span className="iconEmpty mediumIcons" alt="Empty"></span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row mh20 mb20">
                  {/* Previous Period */}
                  <div className="col-md-6 pr20">
                    <Form.Group>
                      <Form.Label className="meteoLabel">{Traduction.translate(language, 'previous_period')}</Form.Label>
                      <div className="fs12"><TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} FieldName={previousPeriodField.FieldName} Label={previousPeriodField.Label} Value={previousPeriodField.FieldValue} Edit={false} Editable={!guestLicence && blockContent.HasRightOnItem} onTextUpdate={this.saveModification}></TextEditor></div>
                      {/* <Form.Control id="previousPeriod" className="blockMeteoFields" as="textarea" rows="5" value={field.Value} placeholder=""
                        onChange={(e) => {this.changeFields(e, field.ColumnName); field.Edit=true;}} onBlur={(e) => {this.saveModification(itemId, itemType, field.ColumnName, field.OldValue, field.Value); field.Edit=false;}}/>
                      {field.Edit && <Form.Label className="fs12 light-grey absolute">{this.remainingCharacter(field.Value.length, 1000)}</Form.Label>} */}
                    </Form.Group>
                  </div>
                  {/* Coming Period */}
                  <div className="col-md-6 pl20">
                    <Form.Group>
                      <Form.Label className="meteoLabel">{Traduction.translate(language, 'coming_period')}</Form.Label>
                      <div className="fs12"><TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} FieldName={comingPeriodField.FieldName} Label={comingPeriodField.Label} Value={comingPeriodField.FieldValue} Edit={false} Editable={!guestLicence && blockContent.HasRightOnItem} onTextUpdate={this.saveModification}></TextEditor></div>
                      {/* <Form.Control id="comingPeriod" className="blockMeteoFields" as="textarea" rows="5" value={field.Value} placeholder=""
                        onChange={(e) => {this.changeFields(e, field.ColumnName); field.Edit=true;}} onBlur={(e) => {this.saveModification(itemId, itemType, field.ColumnName, field.OldValue, field.Value); field.Edit=false;}}/>
                      {field.Edit && <Form.Label className="fs12 light-grey absolute">{this.remainingCharacter(field.Value.length, 1000)}</Form.Label>} */}
                    </Form.Group>
                  </div>
                </div>
                {/* Overall Status */}
                <div className="row mh20 mb20">
                  <div className="col-md-6 pr20">
                    <Form.Group>
                      <Form.Label className="meteoLabel">{Traduction.translate(language, 'overall_status')}</Form.Label>
                      <div className="fs12"><TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} FieldName={feedbackField.FieldName} Label={feedbackField.Label} Value={feedbackField.FieldValue} Edit={false} Editable={!guestLicence && blockContent.HasRightOnItem} onTextUpdate={this.saveModification}></TextEditor></div>
                      {/* <Form.Control id="feedback" className="blockMeteoFields" as="textarea" rows="5" value={field.Value} placeholder=""
                        onChange={(e) => {this.changeFields(e, field.ColumnName); field.Edit=true;}} onBlur={(e) => {this.saveModification(itemId, itemType, field.ColumnName, field.OldValue, field.Value); field.Edit=false;}}/>
                      {field.Edit && <Form.Label className="fs12 light-grey absolute">{this.remainingCharacter(field.Value.length, 1000)}</Form.Label>} */}
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockMeteo;