import React, {Component} from 'react';
import '../Css/App.css';
import Navbar from './Navbar';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount() {}
  
  render() {
    return (
      <div className="">
        <Navbar Selected={this.props.match.url}></Navbar>
        
        <div className="content">
          <div className="fs20 bold pt20 mh30">404 Not Found</div>
        </div>
      </div>
    )
  }
}

export default NotFound;