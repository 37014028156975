import React, {Component} from 'react';
import { Form, FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import PopoverEditRight from './PopoverEditRight';

const API_users = '/WebAppService/GetAllUsersRightsOfObject';
const API_update = '/WebAppService/UpdateUserRightOnObject';

class PopupEditRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      columnName: null,
      allUsersList: [],
      usersList: [],
      userGroupsList: [],
      display: 'User',
      searchUsers: '',
      searchUserGroups: '',
      errors: []
    };

    // Actions
    this.getAllUsers = this.getAllUsers.bind(this);
    this.saveUserModification = this.saveUserModification.bind(this);
    this.saveUserGroupModification = this.saveUserGroupModification.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.searchUserGroups = this.searchUserGroups.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    // Template
    this.templateResource = this.templateResource.bind(this);
    this.templatePopoverUser = this.templatePopoverUser.bind(this);
    this.templatePopoverUserGroup = this.templatePopoverUserGroup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const columnName = this.props.ColumnName;

    // Get List of All Users
    this.getAllUsers(login, authId, itemId, itemType);

    this.setState({ login, authId, language, itemId, itemType, itemTitle, columnName });
  }

  componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const columnName = this.props.ColumnName;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.ItemTitle !== prevProps.ItemTitle || this.props.ColumnName !== prevProps.ColumnName) {
      // Get List of All Users
      this.getAllUsers(login, authId, itemId, itemType);

      this.setState({ itemId, itemType, itemTitle, columnName });
    }
  }

  async getAllUsers(login, authId, itemId, itemType) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ObjectType': itemType,
        'ObjectId': itemId
      })
    };

    try {
      const response = await fetch(API_users, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const allUsersList = data.GetAllUsersRightsOfObjectResult;

      if(allUsersList) {
        const usersList = allUsersList.filter(item => item.ItemType === 'User');
        const userGroupsList = allUsersList.filter(item => item.ItemType === 'UserGroup');

        this.setState({ allUsersList, usersList, userGroupsList, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async saveUserModification(right, value) {
    const { login, authId, itemId, itemType } = this.state;
    let additionalContext = [];

    // Clean Errors
    this.setState({ errors: [] });
  
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ForUserId': right.UserId,
        'ForItemType': right.ItemType,
        'ObjectType': itemType,
        'ObjectId': itemId,
        'RightType': value,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_update, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.UpdateUserRightOnObjectResult;

      const allUsersList = this.state.allUsersList;
      const usersList = this.state.usersList;

      // Check request response
      if(results) {
        // If the Modification is valid
        if(results.IsValid === true) {
          // Update corresponding User in User list
          if(usersList.find(item => item.UserId === right.UserId)) {
            usersList.find(item => item.UserId === right.UserId).RightType = value;
            usersList.find(item => item.UserId === right.UserId).RightFromGroup = "";
          }

          this.setState({ usersList });
        }
        // If the Modification is not valid
        else {
          // Update Errors
          this.updateErrors(results.Errors);
        }
      }
    } catch(error) {
      this.setState({ error });
    }
  }

  async saveUserGroupModification(right, value) {
    const { login, authId, itemId, itemType } = this.state;
    let additionalContext = [];

    // Clean Errors
    this.setState({ errors: [] });
  
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ForUserGroupId': right.UserGroup_Id,
        'ForItemType': right.ItemType,
        'ObjectType': itemType,
        'ObjectId': itemId,
        'RightType': value,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_update, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.UpdateUserRightOnObjectResult;

      const allUsersList = this.state.allUsersList;
      const userGroupsList = this.state.userGroupsList;

      // Check request response
      if(results) {
        // If the Modification is valid
        if(results.IsValid === true) {
          // Update corresponding User in User list
          // if(userGroupsList.find(item => item.UserGroup_Id === userGroupId)) {
          //   userGroupsList.find(item => item.UserGroup_Id === userGroupId).RightType = value;
          // }

          // this.setState({ userGroupsList });

          // Get List of All Users
          this.getAllUsers(login, authId, itemId, itemType);
        }
        // If the Modification is not valid
        else {
          // Update Errors
          this.updateErrors(results.Errors);
        }
      }
    } catch(error) {
      this.setState({ error });
    }
  }

  searchUsers(event) {
    this.setState({ searchUsers: event.target.value });
  }

  searchUserGroups(event) {
    this.setState({ searchUserGroups: event.target.value });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  templateResource(resource) {
    if(resource === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="iconHollowCircle icons fs13">
        <div className="blue">{firstname + lastname}</div>
      </div>;
    }
    else if(resource === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="iconHollowCircle icons fs13">
        <div className="blue">{firstname + lastname}</div>
      </div>;
    }
    else if(resource) {
      let firstname, lastname;
      let split = resource.split(' ');

      if(split.length === 1) {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = resource.split(' ')[1].substring(0,1);
      }
      else {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = resource.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="iconFullCircleBlue icons fs13">
        <div className="white">{firstname + lastname}</div>
      </div>;
    }
    else {
      return null;
    }
  }

  templatePopoverUser(item, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditRight ItemId={itemId} ItemType={itemType} Right={item} CurrentValue={item.RightType} Values={values} onValueEdit={this.saveUserModification}></PopoverEditRight>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverUserGroup(item, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditRight ItemId={itemId} ItemType={itemType} Right={item} CurrentValue={item.RightType} Values={values} onValueEdit={this.saveUserGroupModification}></PopoverEditRight>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closePopup() {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const columnName = this.state.columnName;
    const usersList = this.state.usersList;

    // Get Authorized User
    const authorizedUsersList = usersList.filter(user => user.RightType !== 'None');

    // Call the event from the Parent component through the props
    this.props.onPopupClose(authorizedUsersList, itemId, itemType, columnName);
  }

  render() {
    const { language, itemId, itemType, itemTitle, columnName, usersList, userGroupsList, display, searchUsers, searchUserGroups, errors } = this.state;
    let labelUsers, labelUserGroups;

    // Get Users & UserGroups number
    let nbUsers = usersList.filter(user => user.RightType !== 'None').length;
    let nbUserGroups = userGroupsList.filter(group => group.RightType !== 'None').length;

    // Users number
    if(nbUsers === 1 || nbUsers === 0) {
      labelUsers = nbUsers + " " + Traduction.translate(language, 'authorized_user');
    }
    else {
      labelUsers = nbUsers + " " + Traduction.translate(language, 'authorized_users');
    }

    // UserGroups number
    if(nbUserGroups === 1 || nbUserGroups === 0) {
      labelUserGroups = nbUserGroups + " " + Traduction.translate(language, 'authorized_group');
    }
    else {
      labelUserGroups = nbUserGroups + " " + Traduction.translate(language, 'authorized_groups');
    }

    // Rights Values
    let values = [
      { Name: 'Admin', Label: Traduction.translate(language, 'rights_admin') },
      { Name: 'Modify', Label: Traduction.translate(language, 'rights_modify') },
      { Name: 'View', Label: Traduction.translate(language, 'rights_view') },
      { Name: 'None', Label: Traduction.translate(language, 'rights_no_access') }
    ];

    return (
      <div className="rightsPopup">
        <div className="rightsInnerPopup">
          <div className="popupEditRights">
            {/* Rights Title */}
            <div className="flex mb10">
              {/* Label */}
              <Form.Label className="rightsLabel">
                <div className="rightsLabelBlue">{Traduction.translate(language, 'rights_access')} {Traduction.translate(language, 'of')}</div>
                <div className="rightsLabelYellow ml5">{itemTitle}</div>
              </Form.Label>

              {/* Close Popup */}
              <div className="flex-end align-items-center"><span className="iconClear icons cursor" onClick={() => this.closePopup()}></span></div>
            </div>
            
            <div className="flex mb10">
              {/* Navbar */}
              <div className="flex align-items-center width100p h30">
                <div className={(display === 'User' ? "tabRightsSelected" : "tabRights")} onClick={() => this.setState({ display: 'User' })}>{labelUsers}</div>
                <div className={(display === 'UserGroup' ? "tabRightsSelected" : "tabRights") + " ml10"} onClick={() => this.setState({ display: 'UserGroup' })}>{labelUserGroups}</div>
              </div>

              {/* Search input */}
              <Form.Group className="searchBlock">
                {display === 'User' && <Form.Control type="text" id="searchFilters" name="search" value={searchUsers} placeholder={Traduction.translate(language, 'search')} onChange={(e) => {this.searchUsers(e)}}/>}
                {display === 'UserGroup' && <Form.Control type="text" id="searchFilters" name="search" value={searchUserGroups} placeholder={Traduction.translate(language, 'search')} onChange={(e) => {this.searchUserGroups(e)}}/>}
              </Form.Group>
            </div>
            
            {/* Errors */}
            {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

            {/* Users */}
            {display === 'User' && usersList[0] && 
              <div className={((errors.length === 0) ? "rightsList" : "rightsListErrors") + " scrollbar-y"}>
                {/* Autorized Users list */}
                {usersList.map((item, index) => {
                  if(item.ItemType === 'User' && item.RightType !== 'None') {
                    if(searchUsers === "" || item.Name.toLowerCase().includes(searchUsers.toLowerCase())) {
                      return <div key={index} className="inline-flex mv2">
                        {/* Users list */}
                        <div className="col-md-5 inline-flex">
                          <span className="flex align-items-center mr10">{this.templateResource(item.Name)}</span>
                          <div className="rightsResourceName">{item.Name}</div>
                        </div>
                        {/* Rights Modification */}
                        <div className="col-md-3">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverUser(item, values)}>
                            <div className="rightsSelect">
                              {item.RightType === 'Admin' && <div className="rightsAdmin">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_admin')}</div>
                                <span className="iconChevronDownWhite extrasmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'Modify' && <div className="rightsModify">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_modify')}</div>
                                <span className="iconChevronDownWhite extrasmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'View' && <div className="rightsView">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_view')}</div>
                                <span className="iconChevronDownWhite extrasmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'None' && <div className="rightsNone">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_no_access')}</div>
                                <span className="iconChevronDownGrey extrasmallIcons ml10"></span>
                              </div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                        {/* UserGroup */}
                        <div className="col-md-4 inline-flex">
                          {item.RightFromGroup && <div className="flex align-items-center mr10">
                            <span className="iconUserGroup mediumIcons"></span>
                          </div>}
                          <div className="rightsResourceName">{item.RightFromGroup}</div>
                        </div>
                      </div>
                    }
                  }
                })}
                {/* All Users list */}
                {usersList.map((item, index) => {
                  if(item.ItemType === 'User' && item.RightType === 'None') {
                    if(searchUsers === "" || item.Name.toLowerCase().includes(searchUsers.toLowerCase())) {
                      return <div key={index} className="inline-flex mv2">
                        {/* Users list */}
                        <div className="col-md-5 inline-flex">
                          <span className="flex align-items-center mr10">{this.templateResource(item.Name)}</span>
                          <div className="rightsResourceName">{item.Name}</div>
                        </div>
                        {/* Rights Modification */}
                        <div className="col-md-3">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverUser(item, values)}>
                            <div className="rightsSelect">
                              {item.RightType === 'Admin' && <div className="rightsAdmin">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_admin')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'Modify' && <div className="rightsModify">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_modify')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'View' && <div className="rightsView">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_view')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'None' && <div className="rightsNone">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_no_access')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                        {/* UserGroup */}
                        <div className="col-md-4 inline-flex">
                          {item.RightFromGroup && <div className="flex align-items-center mr10">
                            <span className="iconUserGroup mediumIcons"></span>
                          </div>}
                          <div className="rightsResourceName">{item.RightFromGroup}</div>
                        </div>
                      </div>
                    }
                  }
                })}
              </div>
            }
            {/* Groups */}
            {display === 'UserGroup' && userGroupsList[0] && 
              <div className={((errors.length === 0) ? "rightsList" : "rightsListErrors") + " scrollbar-y"}>
                {/* Autorized UserGroups list */}
                {userGroupsList.map((item, index) => {
                  if(item.ItemType === 'UserGroup' && item.RightType !== 'None') {
                    if(searchUserGroups === "" || item.Name.toLowerCase().includes(searchUserGroups.toLowerCase())) {
                      return <div key={index} className="inline-flex mv2">
                        {/* Users list */}
                        <div className="col-md-6 inline-flex">
                          <div className="flex align-items-center mr10"><span className="iconUserGroup mediumIcons"></span></div>
                          <div className="rightsResourceName">{item.Name}</div>
                        </div>
                        {/* Rights Modification */}
                        <div className="col-md-6">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverUserGroup(item, values)}>
                            <div className="rightsSelect">
                              {item.RightType === 'Admin' && <div className="rightsAdmin">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_admin')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'Modify' && <div className="rightsModify">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_modify')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'View' && <div className="rightsView">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_view')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'None' && <div className="rightsNone">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_no_access')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    }
                  }
                })}
                {/* All UserGroups list */}
                {userGroupsList.map((item, index) => {
                  if(item.ItemType === 'UserGroup' && item.RightType === 'None') {
                    if(searchUserGroups === "" || item.Name.toLowerCase().includes(searchUserGroups.toLowerCase())) {
                      return <div key={index} className="inline-flex mv2">
                        {/* Users list */}
                        <div className="col-md-6 inline-flex">
                          <div className="flex align-items-center mr10"><span className="iconUserGroup mediumIcons"></span></div>
                          <div className="rightsResourceName">{item.Name}</div>
                        </div>
                        {/* Rights Modification */}
                        <div className="col-md-6">
                          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverUserGroup(item, values)}>
                            <div className="rightsSelect">
                              {item.RightType === 'Admin' && <div className="rightsAdmin">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_admin')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'Modify' && <div className="rightsModify">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_modify')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'View' && <div className="rightsView">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_view')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                              {item.RightType === 'None' && <div className="rightsNone">
                                <div className="rightsValue">{Traduction.translate(language, 'rights_no_access')}</div>
                                <span className="iconChevronDownGrey verysmallIcons ml10"></span>
                              </div>}
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    }
                  }
                })}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PopupEditRights;