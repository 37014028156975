import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersViewPopupDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      viewName: null,
      views: [],
      otherViews: []
    };

    // Data Structure
    // this.buildOtherViews = this.buildOtherViews.bind(this);

    // Actions
    this.changeView = this.changeView.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const viewName = currentView.Name;
    const views = this.props.Views;
    const otherViews = this.props.OtherViews;

    // this.buildOtherViews(otherViews);
    
    this.setState({ authId, language, itemId, itemType, blockType, currentView, viewName, views, otherViews });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const viewName = currentView.Name;
    const views = this.props.Views;
    const otherViews = this.props.OtherViews;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.CurrentView !== prevProps.CurrentView || this.props.Views !== prevProps.Views) {
      this.setState({ currentView, viewName, views });
    }
    if(JSON.stringify(this.props.OtherViews) !== JSON.stringify(prevProps.OtherViews)) {
      // this.buildOtherViews(otherViews);

      this.setState({ otherViews });
    }
  }

  // buildOtherViews(views) {
  //   let otherViews = [];

  //   views.ListGenericDashboardViews.forEach(view => {
  //     otherViews.push(view);
  //   });

  //   views.ListUserDashboardViews.forEach(view => {
  //     otherViews.push(view);
  //   });

  //   this.setState({ otherViews });
  // }
  
  changeView(view) {
    // Call the event from the Parent component through the props with new View selected
    this.props.onViewChange(view);
  }
  
  render() {
    const { language, itemId, itemType, blockType, currentView, viewName, views, otherViews } = this.state;

    return (
      <div className="panelViews">
        {/* Views Title */}
        <div className="viewsTitle">
          <div className="viewsLabel">{Traduction.translate(language, 'my_pinned_views')}</div>
        </div>
        {/* Views List */}
        <div className="viewsList scrollbar-y">
          {views.map((view, index) => {
            return <div key={index} className={(view.ViewDashboardId === currentView.ViewDashboardId ? "viewItemSelected": "viewItem") + " cursor"} onClick={(e) => this.changeView(view)}>
              {/* ViewType Icon */}
              <span className="iconViewTypeDashboardGrey iconsViews mr10"></span>
              {/* View Name */}
              <span className="viewName">{view.Name}</span>
            </div>
          })}
        </div>
        {/* Other Views Title */}
        <div className="viewsTitle">
          <div className="viewsLabel">{Traduction.translate(language, 'other_views')}</div>
        </div>
        {/* Other Views List */}
        <div className="viewsList scrollbar-y">
          {otherViews.map((view, index) => {
            return <div key={index} className={(view.ViewDashboardId === currentView.ViewDashboardId ? "viewItemSelected": "viewItem") + " cursor"} onClick={(e) => this.changeView(view)}>
              {/* ViewType Icon */}
              <span className="iconViewTypeDashboardGrey iconsViews mr10"></span>
              {/* View Name */}
              <span className="viewName">{view.Name}</span>
            </div>
          })}
        </div>
      </div>
    )
  }
}

export default FiltersViewPopupDashboard;