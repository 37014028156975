import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
// import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
// registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Rd0xjUX1WdHNVQ2Fb');
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtdcHRdQ2ReWUZxWkM=');
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF1cWWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjXH5acH1UT2BbWUV3Vw==');

ReactDOM.render(<Routes></Routes>, document.getElementById('root'));
// const root = createRoot(document.getElementById('root'));
// root.render(<Routes></Routes>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();