import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Badge, Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import QRCode from "react-qr-code";
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersTable from './FiltersTable';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersDisplayComponent from './FiltersDisplayComponent';
import FiltersDisplayMessages from './FiltersDisplayMessages';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Axe from './Axe';
import PopoverEditAxe from './PopoverEditAxe';
import PopoverEditObjectTable from './PopoverEditObjectTable';
import PopupEditFiles from './PopupEditFiles';
import PopupQRCode from './PopupQRCode';
import DatePickerCalendar from './DatePickerCalendar';
import Progress from './Progress';
import Timeline from './Timeline';
import TextEditor from './TextEditor';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_messages = '/WebAppService/GetMessagesOfScope';
const API_save = '/WebAppService/GetPropagationsAndSaveModification';
const API_add_message = '/WebAppService/AddMessage';
const API_update_message = '/WebAppService/UpdateMessage';
const API_delete_message = '/WebAppService/DeleteMessage';

class BlockDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      userId: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      displayQRCode: null,
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      tables: [],
      columns: [],
      rows: [],
      fields: [],
      openFilesPopup: false,
      rowItemColumn: null,
      rowItemLabel: null,
      visibleLabels: true,
      visibleMessages: true,
      messages: [],
      newMessage: '',
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getFieldChecked = this.getFieldChecked.bind(this);
    this.getFieldEditable = this.getFieldEditable.bind(this);
    this.getRowsNb = this.getRowsNb.bind(this);
    this.getRemainingCaracters = this.getRemainingCaracters.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);

    // Actions
    this.displayQRCode = this.displayQRCode.bind(this);
    this.closeQRCodePopup = this.closeQRCodePopup.bind(this);
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.updateView = this.updateView.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.applyTableSettings = this.applyTableSettings.bind(this);
    this.changeDisplayComponent = this.changeDisplayComponent.bind(this);
    this.changeDisplayMessages = this.changeDisplayMessages.bind(this);
    this.handleAutosize = this.handleAutosize.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.updateBoolean = this.updateBoolean.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.checkModification = this.checkModification.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.getPropagations = this.getPropagations.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Files
    this.openFilesPopup = this.openFilesPopup.bind(this);
    this.closeFilesPopup = this.closeFilesPopup.bind(this);
    this.updateFiles = this.updateFiles.bind(this);

    // Messages
    this.addComment = this.addComment.bind(this);
    this.saveComment = this.saveComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.updateNewComment = this.updateNewComment.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);

    // Template
    this.templatePopoverAxe = this.templatePopoverAxe.bind(this);
    this.templatePopoverObjectTable = this.templatePopoverObjectTable.bind(this);
    this.templatePopoverResourceTable = this.templatePopoverResourceTable.bind(this);
    this.templatePopoverMessage = this.templatePopoverMessage.bind(this);

    this.views = React.createRef();
    this.startDate = React.createRef();
    this.endDate = React.createRef();
    this.initialStartDate = React.createRef();
    this.initialEndDate = React.createRef();
    this.messagesBottom = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const userId = Authentication.getCookie('userId');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, userId, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }

      // Get Messages
      await this.getMessages(login, authId, itemId, itemType);

      this.scrollToBottom();
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length - 1];

    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });

      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

      // Get Messages
      await this.getMessages(login, authId, itemId, itemType);

      this.scrollToBottom();
    }
    if(viewId !== oldViewId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const tables = blockContent.Tables;
        let columns = [], rows = [];
        let visibleLabels, visibleMessages;

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        // Get Fields
        let fields = this.getFields(currentView, columns, rows);

        // Initialise Visible Component
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
          visibleLabels = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
        }
        else {
          visibleLabels = true;
        }

        // Initialise Visible Messages
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleMessages')) {
          visibleMessages = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleMessages').Value);
        }
        else {
          visibleMessages = true;
        }

        this.setState({ viewId: currentView.ViewId, currentView, tables, columns, rows, fields, visibleLabels, visibleMessages, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async getMessages(login, authId, itemId, itemType) {
    const language = this.state.language;
    const offset = new Date().getTimezoneOffset();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'FromMessage': 0,
        'UTCLag': offset
      })
    };

    try{
      const response = await fetch(API_messages, requestOptions);
      let errors = [];

      if(!response.ok || response.status !== 200) {
        errors.push(Traduction.translate(language, 'server_not_responding'));
      }

      const data = await response.json();
      const messages = data.GetMessagesOfScopeResult;

      if(messages) {
        messages.forEach(message => {
          message.Edit = false;
        });
      }

      this.setState({ messages, errors });

    } catch(error) {
      this.setState({ error });
    }
  }

  getFields(currentView, columns, rows) {
    let columnsNames = '';
    let fields = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columnsNames = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }

    // Loop through the columns to build and add them to the Grid
    for(let i = 0; i < columnsNames.length; i++) {
      let columnHeader;

      if(columns.find(columnHeader => columnHeader.FieldName === columnsNames[i])) {
        // Get Column corresponding Column Header
        columnHeader = columns.find(columnHeader => columnHeader.FieldName === columnsNames[i]);

        if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i])) {
          // Parse Value for Axes, Location, Reources, Meteo & Trend
          if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Location' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
            if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value) {
              columnHeader.FieldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
              columnHeader.OldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
            }
            else {
              if(columnHeader.FieldName === 'HashTag') {
                columnHeader.FieldValue = [];
                columnHeader.OldValue = [];
              }
              else {
                columnHeader.FieldValue = "";
                columnHeader.OldValue = "";
              }
            }
          }
          // Axis Table, Files, Resource Table
          else if(columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Files' || columnHeader.FieldType === 'ResourceTable') {
            if(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value) {
              columnHeader.FieldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
              columnHeader.OldValue = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value);
            }
            else {
              columnHeader.FieldValue = [];
              columnHeader.OldValue = [];
            }
          }
          else {
            columnHeader.FieldValue = rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value;
            columnHeader.OldValue = rows[0].Cells.find(cell => cell.ColumnName === columnsNames[i]).Value;
          }
        }

        fields.push(columnHeader);
      }
    }

    return fields;
  }

  getFieldChecked(field) {
    if(field.FieldValue === 'True') {
      return true;
    }
    else {
      return false;
    }
  }

  getFieldEditable(field) {
    const itemType = this.state.itemType;

    if(field.EditionItemTypes.includes(itemType)) {
      return true;
    }
    else {
      return false;
    }
  }

  getRowsNb(field, value) {
    let linesNb;

    if(field === 'Message') {
      if(window.innerWidth < 1200) {
        if(value.length < 35) {
          linesNb = 1;
        }
        else if(value.length < 70) {
          linesNb = 2;
        }
        else if(value.length < 105) {
          linesNb = 3;
        }
        else {
          linesNb = 5;
        }
      }
      else if(window.innerWidth >= 1200 && window.innerWidth < 1400) {
        if(value.length < 40) {
          linesNb = 1;
        }
        else if(value.length < 80) {
          linesNb = 2;
        }
        else if(value.length < 120) {
          linesNb = 3;
        }
        else {
          linesNb = 5;
        }
      }
      else if(window.innerWidth >= 1400) {
        if(value.length < 50) {
          linesNb = 1;
        }
        else if(value.length < 100) {
          linesNb = 2;
        }
        else if(value.length < 150) {
          linesNb = 3;
        }
        else {
          linesNb = 5;
        }
      }
      else if(window.innerWidth < 1800) {
        if(value.length <= 82) {
          linesNb = 1;
        }
        else if(value.length <= 164) {
          linesNb = 2;
        }
        else if(value.length <= 246) {
          linesNb = 3;
        }
        else {
          linesNb = 5;
        }
      }
    }
    else {
      if(value.length < 120) {
        linesNb = 3;
      }
      else {
        linesNb = 5;
      }
    }

    return linesNb;
  }

  getRemainingCaracters(value, max) {
    const language = this.state.language;
    let remaining = (max - value);

    if(remaining > 1) {
      return remaining + " " + Traduction.translate(language, 'remaining_characters');
    }
    else {
      return remaining + " " + Traduction.translate(language, 'remaining_character');
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  displayQRCode() {
    this.setState({ displayQRCode: true });
  }

  closeQRCodePopup() {
    this.setState({ displayQRCode: false });
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeView(view) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  async changeColumns(columnNames) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
  }

  applyTableSettings(currentView) {
    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    this.setState({ currentView });
  }

  async changeDisplayComponent() {
    const { login, authId, itemId, itemType, blockType, currentView, search } = this.state;
    let visibleLabels;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
      // Hide Labels
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === "true") {
        currentView.Parameters.find(param => param.Name === "VisibleComponent").Value = "false";
        visibleLabels = false;
      }
      // Display Labels
      else if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === "false") {
        currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === "VisibleComponent"), 1);
        visibleLabels = true;
      }
    } 
    // Hide Labels
    else {
      currentView.Parameters.push({ Name: "VisibleComponent", Value: "false" });
      visibleLabels = false;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    // await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ visibleLabels });

    // Apply Table Filters
    // this.applyTableFilters(currentView, search);
  }

  changeDisplayMessages() {
    const { login, authId, itemId, itemType, blockType, currentView, search } = this.state;
    let visibleMessages;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleMessages")) {
      // Hide Messages
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleMessages").Value === "true") {
        currentView.Parameters.find(param => param.Name === "VisibleMessages").Value = "false";
        visibleMessages = false;
      }
      // Display Messages
      else if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleMessages").Value === "false") {
        currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === "VisibleMessages"), 1);
        visibleMessages = true;
      }
    } 
    // Hide Messages
    else {
      currentView.Parameters.push({ Name: "VisibleMessages", Value: "false" });
      visibleMessages = false;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    // await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ visibleMessages });
  }

  handleAutosize(event) {
    // e.target.style.height = 'inherit';
    // e.target.style.height = `${e.target.scrollHeight}px`;
  }

  handleKeyDown(event) {
    event.target.style.height = 'inherit';
    event.target.style.height = `${event.target.scrollHeight}px`;
  }

  // Prevent to submit the form when enter is pressed
  handleKeyPressed(event) {
    if(event.key === 'Enter') {
      event.preventDefault();
    }
  }

  // Update Field with input value
  updateFields(event, fieldName) {
    // Check if Field's number of characters is less than 1000
    if(event.target.value.length > 1000) {
      event.target.value = event.target.value.substring(0, 1000);
    }

    let fields = this.state.fields.map((field) => {
      if(field.FieldName === fieldName) {
        // Update Field Value
        field.FieldValue = event.target.value;
      }

      return field;
    });

    this.setState({ fields });
  }

  // Update Field with boolean value
  updateBoolean(fieldName) {
    let fields = this.state.fields.map((field) => {
      if(field.FieldName === fieldName) {
        field.FieldValue = (field.FieldValue === "True") ? "False" : "True";
      }

      return field;
    });

    this.setState({ fields });
  }

  updateDate(fieldName, date) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    let oldValue, start, end, initialStart, initialEnd;
    let isValid = true;

    // Maybe not needed anymore because verification is done on DatePicker Calendar
    if(this.state.fields.find(element => element.FieldName === 'StartDate')) {
      start = this.state.fields.find(element => element.FieldName === 'StartDate').FieldValue;
    }
    if(this.state.fields.find(element => element.FieldName === 'EndDate')) {
      end = this.state.fields.find(element => element.FieldName === 'EndDate').FieldValue;
    }
    if(this.state.fields.find(element => element.FieldName === 'Initial_StartDate')) {
      initialStart = this.state.fields.find(element => element.FieldName === 'Initial_StartDate').FieldValue;
    }
    if(this.state.fields.find(element => element.FieldName === 'Initial_EndDate')) {
      initialEnd = this.state.fields.find(element => element.FieldName === 'Initial_EndDate').FieldValue;
    }

    // Check if StartDate <= EndDate & initial StartDate <= EndDate
    if(fieldName === 'StartDate') {
      let startDate = new Date(date);
      let endDate = new Date(end);

      // If StartDate is after EndDate
      if(startDate.getTime() > endDate.getTime()) {
        isValid = false;
      }
    }
    else if(fieldName === 'EndDate') {
      let startDate = new Date(start);
      let endDate = new Date(date);

      // If EndDate is before StartDate
      if(startDate.getTime() > endDate.getTime()) {
        isValid = false;
      }
    }
    else if(fieldName === 'Initial_StartDate') {
      let initialStartDate = new Date(date);
      let initialEndDate = new Date(initialEnd);

      // If InitialStartDate is after InitialEndDate
      if(initialStartDate.getTime() > initialEndDate.getTime()) {
        isValid = false;
      }
    }
    else if(fieldName === 'Initial_EndDate') {
      let initialStartDate = new Date(initialStart);
      let initialEndDate = new Date(date);

      // If InitialEndDate is before InitialStartDate
      if(initialStartDate.getTime() > initialEndDate.getTime()) {
        isValid = false;
      }
    }

    if(isValid) {
      let fields = this.state.fields.map((field) => {
        if(field.FieldName === fieldName) {
          oldValue = field.FieldValue;
          field.FieldValue = date;
        }

        return field;
      });

      this.setState({ fields });

      this.checkModification(itemId, itemType, fieldName, oldValue, date);
    }
  }

  updateRating(fieldName, newValue) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    let oldValue;

    // if(fields.find(field => field.FieldName === fieldName)) {
    //   oldValue = fields.find(field => field.FieldName === fieldName).FieldValue;
    //   fields.find(field => field.FieldName === fieldName).FieldValue = newValue;
    // }

    let fields = this.state.fields.map((field) => {
      if(field.FieldName === fieldName) {
        oldValue = field.FieldValue;
        field.FieldValue = newValue;
      }

      return field;
    });

    this.setState({ fields });

    if(newValue != oldValue) {
      this.checkModification(itemId, itemType, fieldName, oldValue, newValue);
    }
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language, fields } = this.state;
    let additionalContext = [];

    // Update Format Date Cookie
    if(columnName === 'Format_Date') {
      Authentication.deleteCookie('formatDate');
      Authentication.createCookie('formatDate', JSON.parse(newValue).Label);
    }
    // Update Language Cookie
    if(columnName === 'Language') {
      Authentication.deleteCookie('language');
      Authentication.createCookie('language', JSON.parse(newValue).Label);
    }

    // If newValue on Column Name is empty
    if(columnName === 'Name' && newValue === '') {
      if(fields.find(field => field.FieldName === columnName)) {
        fields.find(field => field.FieldName === columnName).FieldValue = oldValue;
      }

      this.setState({ fields });
    }
    // If newValue is not positif numeric for ColumnName Burned_Workload/Workload/Initial_Workload/Remaining_Workload/Progress
    else if((isNaN(newValue) || newValue < 0) && (columnName === 'Progress' || columnName === 'Burned_Workload' || columnName === 'Workload' || columnName === 'Initial_Workload' || columnName === 'Remaining_Workload')) {
      if(fields.find(field => field.FieldName === columnName)) {
        fields.find(field => field.FieldName === columnName).FieldValue = oldValue;
      }

      this.setState({ fields, errors: [Traduction.translate(language, 'numeric_format_expected')] });
    }
    else if(newValue !== oldValue) {
      // Replace null value by 0 for Burned/Workload
      if(!newValue && (columnName === 'Burned_Workload' || columnName === 'Workload')) {
        newValue = 0;
      }

      // Request Options and Body
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Mode': 'Login',
          'Login': login,
          'Token': authId
        },
        body: JSON.stringify({
          'ItemType': itemType,
          'ItemId': itemId,
          'ColumnName': columnName,
          'OldValue': oldValue,
          'NewValue': newValue,
          'AdditionalContext': additionalContext
        })
      };

      try{
        const response = await fetch(API_save, requestOptions);

        if(!response.ok) {
          throw new Error('Something went wrong ...');
        }

        const data = await response.json();
        const results = data.GetPropagationsAndSaveModificationResult;

        // Check request response
        if(results) {
          // For Each Modification
          results.forEach(result => {
            // Get Modifications and Propagations
            let modification = result.Modification;
            let propagations = result.Propagations;
            let warnings = result.Warnings;

            // If the Modification is valid
            if(result.IsValid === true) {
              // Update Table with Modification, Propagations and Warnings
              this.updateTable(modification, propagations, warnings);
            }
            // If the Modification is not valid
            else {
              // Update Datasource with OldValue
              this.cancelModification(modification);

              // Update Errors
              this.updateErrors(result.Errors);
            }
          });
        }

      } catch(error) {
        this.setState({ error });
      }
    }
  }

  updateTable(modification, propagations, warnings) {
    const columns = this.state.columns;
    let rows = this.state.rows;
    const currentView = this.state.currentView;

    // Update Rows
    rows.forEach(row => {
      let itemType = row.ItemType;
      let itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

      if(modification.ItemType === itemType && modification.ItemId == itemId.substring(1)) {
        // Update Row with Modification NewValue
        row.Cells.find(cell => cell.ColumnName === modification.ColumnName).Value = modification.NewValue;

        // Update Warnings
        if(warnings.length > 0) {
          if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
            row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = warnings;
          }
        }
        else {
          if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
            row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = "";
          }
        }
      }

      // Get Row Propagations
      let props = this.getPropagations(propagations, itemType, itemId.substring(1));

      // Update Row with Propagations NewValue
      props.forEach(prop => {
        if(row.Cells.find(cell => cell.ColumnName === prop.ColumnName)) {
          row.Cells.find(cell => cell.ColumnName === prop.ColumnName).Value = prop.NewValue;
        }
      });
    });

    // Get Fields
    let fields = this.getFields(currentView, columns, rows);

    // Update Field Old Value
    fields.map((field) => {
      if(field.FieldName === modification.ColumnName) {
        field.OldValue = field.FieldValue;
      }
    });

    this.setState({ rows, fields });
  }

  getPropagations(propagations, itemType, itemId) {
    let result = [];

    propagations.forEach(propagation => {
      if(propagation.ToItemType === itemType && propagation.ToItemId == itemId) {
        result.push(propagation);
      }
    });

    return result;
  }

  cancelModification(modification) {
    let fields = this.state.fields;

    fields.map((field) => {
      if(field.FieldName === modification.ColumnName) {
        // Update Datepicker
        if(this.startDate.current && field.FieldName === 'StartDate') {
          this.startDate.current.cancelModification(modification);
        }
        else if(this.endDate.current && field.FieldName === 'EndDate') {
          this.endDate.current.cancelModification(modification);
        }
        else if(this.initialStartDate.current && field.FieldName === 'Initial_StartDate') {
          this.initialStartDate.current.cancelModification(modification);
        }
        else if(this.initialEndDate.current && field.FieldName === 'Initial_EndDate') {
          this.initialEndDate.current.cancelModification(modification);
        }

        // Update Field with Old Value
        field.FieldValue = field.OldValue;
      }
    });

    this.setState({ fields });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  openFilesPopup(columnName) {
    const columns = this.state.columns;
    let rowItemLabel;

    if(columns.find(column => column.FieldName === columnName)) {
      rowItemLabel = columns.find(column => column.FieldName === columnName).Label;
    }
    else {
      rowItemLabel = columnName;
    }

    this.setState({ filesPopup: true, rowItemColumn: columnName, rowItemLabel });
  }

  closeFilesPopup(files, itemId, itemType, columnName) {
    // Update Files field
    this.updateFiles(files, columnName);

    this.setState({ filesPopup: false });
  }

  updateFiles(files, columnName) {
    const fields = this.state.fields;
    let value = [];

    files.forEach(file => {
      value.push({ BackColor: null, ForeColor: null, Id: file.CustomValueId, Label: file.FileName });
    })

    if(fields.find(field => field.FieldName === columnName)) {
      fields.find(field => field.FieldName === columnName).FieldValue = value;
    }

    this.setState({ fields });
  }

  async addComment(message) {
    const { login, authId, itemId, itemType } = this.state;
    const offset = new Date().getTimezoneOffset();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'Message': message,
        'UTCLag': offset
      })
    };

    try{
      const response = await fetch(API_add_message, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.AddMessageResult;
      let messages = this.state.messages;

      // Check request response
      if(result.IsValid === true) {
        messages.push(result.SavedMessage);

        this.setState({ messages, newMessage: "" });

        this.scrollToBottom();
      }
      else {
        this.updateErrors(result.Errors);
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async saveComment(message, index) {
    const { login, authId } = this.state;
    const offset = new Date().getTimezoneOffset();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'UpdatedMessage': message,
        'UTCLag': offset
      })
    };

    try{
      const response = await fetch(API_update_message, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.UpdateMessageResult;
      let messages = this.state.messages;

      // Check request response
      if(result.IsValid === true) {
        messages[index] = result.SavedMessage;

        this.setState({ messages });
      }
      else {
        this.updateErrors(result.Errors);
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async deleteComment(message, index) {
    const { login, authId } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'DeletedMessage': message
      })
    };

    try{
      const response = await fetch(API_delete_message, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteMessageResult;
      let messages = this.state.messages;

      // Check request response
      if(result.IsValid === true) {
        messages.splice(index, 1);

        this.setState({ messages });
      }
      else {
        this.updateErrors(result.Errors);
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  // Update Comment with input value
  updateComment(event, index) {
    const messages = this.state.messages;

    messages[index].MessageString = event.target.value;

    this.setState({ messages });
  }

  // Update New Comment with input value
  updateNewComment(event) {
    const newMessage = event.target.value;

    this.setState({ newMessage });
  }

  scrollToBottom() {
    if(this.messagesBottom.current) {
      this.messagesBottom.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  templatePopoverAxe(field) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const currentView = this.state.currentView;
    const columnName = field.FieldName;
    const value = field.FieldValue;
    const restrictedValues = field.RestrictedValues;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.checkModification}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverObjectTable(field) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const currentView = this.state.currentView;
    const columnName = field.FieldName;
    const columnType = field.FieldType;
    const value = field.FieldValue;
    const restrictedValues = field.RestrictedValues;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ColumnType={columnType} ObjectTableValues={value} RestrictedValues={restrictedValues} onObjectTableEdit={this.checkModification}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverResourceTable(field) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const currentView = this.state.currentView;
    const columnName = field.FieldName;
    const columnType = field.FieldType;
    const value = field.FieldValue;
    const restrictedValues = field.RestrictedValues;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ColumnType={columnType} ObjectTableValues={value} RestrictedValues={restrictedValues} onObjectTableEdit={this.checkModification}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMessage(message, index) {
    const language = this.state.language;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex align-items-center">
            <span className="iconClear icons cursor mr10" onClick={(e) => this.deleteComment(message, index)}></span>
            <span className="">{Traduction.translate(language, 'delete')}</span>
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  render() {
    let { userId, language, itemId, itemType, itemTitle, blockType, blockInfo, editable, guestLicence, favorite, parents, warnings, displayQRCode, displayViews, views, defaultViewId, currentView, columns, rows, fields, filesPopup, rowItemColumn, rowItemLabel, visibleLabels, visibleMessages, messages, newMessage, isLoading, errors } = this.state;
    let status, startDate, endDate, initialStartDate, initialEndDate, url;

    // Status
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'Status') && rows[0].Cells.find(cell => cell.ColumnName === 'Status').Value) {
      status = JSON.parse(rows[0].Cells.find(cell => cell.ColumnName === 'Status').Value);
    }
    // StartDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'StartDate')) {
      startDate = rows[0].Cells.find(cell => cell.ColumnName === 'StartDate').Value;
    }
    // EndDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'EndDate')) {
      endDate = rows[0].Cells.find(cell => cell.ColumnName === 'EndDate').Value;
    }
    // Initial StartDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'Initial_StartDate')) {
      initialStartDate = rows[0].Cells.find(cell => cell.ColumnName === 'Initial_StartDate').Value;
    }
    // Initial EndDate
    if(rows[0] && rows[0].Cells.find(cell => cell.ColumnName === 'Initial_EndDate')) {
      initialEndDate = rows[0].Cells.find(cell => cell.ColumnName === 'Initial_EndDate').Value;
    }
    // QR Code
    if(itemId && itemType) {
      url = window.location.origin + '/Card/' + itemType + '/' + itemId.substring(1) + '/Details';
    }

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'details')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            <div className="flex inline-flex width100p">
              {/* Card Title & Parents */}
              <div className="flex-start width100p">
                <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
              </div>
              {/* Card Indicators */}
              <div className="blockIndicators">
                {/* QR Code */}
                <div className="cardLink" onClick={(e) => this.displayQRCode()}>
                  <span className="iconQRCode mediumIcons" alt=""></span>
                </div>
              </div>
            </div>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContentDetails" : "blockContentViews"}>
              {/* Visible Messages */}
              {/* <div className={(visibleMessages ? "col-md-8 pr10" : "col-md-12")}> */}
              
              {/* Filters */}
              {itemType !== "User" && <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Visible Labels */}
                {!guestLicence && <FiltersDisplayComponent BlockType={blockType} CurrentView={currentView} Display={visibleLabels} onDisplayChange={this.changeDisplayComponent}></FiltersDisplayComponent>}

                {/* Table Settings */}
                {!guestLicence && <FiltersTable ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onViewChange={this.updateView} onSettingsChange={this.applyTableSettings}></FiltersTable>}

                {/* Columns Chooser */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1 || currentView.ViewType === 4) && 
                  <FiltersColumnChooser ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onColumnsAdd={this.changeColumns} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                }

                {/* Visible Messages */}
                {!guestLicence && <FiltersDisplayMessages BlockType={blockType} CurrentView={currentView} Display={visibleMessages} onDisplayChange={this.changeDisplayMessages}></FiltersDisplayMessages>}
              </div>}

              {/* Card Block Component */}
              <div className="blockComponentDetails">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Files Popup */}
                {filesPopup && <PopupEditFiles ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} ColumnName={rowItemColumn} ColumnLabel={rowItemLabel} onPopupClose={this.closeFilesPopup}></PopupEditFiles>}

                {/* Popup QR Code */}
                {displayQRCode && <PopupQRCode ItemTitle={itemTitle} URL={window.location.href} onPopupClose={this.closeQRCodePopup}></PopupQRCode>}

                <Form className={(itemType !== 'User' ? "detailsForm" : "userProfileForm") + " scrollbar-y"}>
                  {fields.map((field, index) => 
                    <div key={index} className="">
                      {/* ----- Name ----- */}
                      {field.FieldName === 'Name' && 
                        <Form.Group className="detailsNameRow">
                          <Form.Control id="" className="detailsName" as="textarea" rows="1" placeholder="" value={field.FieldValue} readOnly={guestLicence || !this.getFieldEditable(field)} 
                            onChange={(e) => this.updateFields(e, field.FieldName)} 
                            onBlur={(e) => this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue)} />
                        </Form.Group>
                      }
                      {/* ----- Axes & Ressource + Meteo & Trend & Project Manager ----- */}
                      {field.FieldType === 'Axe' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {!guestLicence && this.getFieldEditable(field) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverAxe(field)}>
                                <div className=""><Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Boolean ----- */}
                      {/* Active */}
                      {field.FieldName === 'Active' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <span className="iconActiveTrue mediumIcons"></span>}
                            {field.FieldValue === "False" && <span className="iconActiveFalse mediumIcons"></span>}
                          </div>
                        </Form.Group>
                      }
                      {/* External / Internal */}
                      {field.FieldName === 'External' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <div className="fs12 bold"><span className="iconExternal iconsRectangle"></span>{Traduction.translate(language, 'external')}</div>}
                            {field.FieldValue === "False" && <div className="fs12 bold"><span className="iconInternal iconsRectangle"></span>{Traduction.translate(language, 'internal')}</div>}
                          </div>
                        </Form.Group>
                      }
                      {/* Highlighted / Not Highlighted */}
                      {field.FieldName === 'Highlighted' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <div className="fs12 orange bold"><span className="iconHighlighted mediumIcons ml-5 mr10"></span>{Traduction.translate(language, 'highlighted')}</div>}
                            {field.FieldValue === "False" && <span className="mediumIcons iconNotHighlighted"></span>}
                          </div>
                        </Form.Group>
                      }
                      {/* Administrator / Cost_Following / Reporting / Static_Data / Users_Config */}
                      {(field.FieldName === 'Administrator' || field.FieldName === 'Cost_Following' || field.FieldName === 'Reporting' || field.FieldName === 'Static_Data' || field.FieldName === 'Users_Config') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <span className="iconShieldGreen mediumIcons"></span>}
                            {field.FieldValue === "False" && <span className="iconShieldGrey mediumIcons"></span>}
                          </div>
                        </Form.Group>
                      }
                      {/* Template */}
                      {field.FieldName === 'AsTemplate' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <div className="fs12"><span className="iconCheck mediumIcons mr10"></span>{Traduction.translate(language, 'defined_as_template')}</div>}
                            {field.FieldValue === "False" && <div className="fs12"><span className="iconCircleGrey mediumIcons mr10"></span>{Traduction.translate(language, 'not_defined_as_template')}</div>}
                          </div>
                        </Form.Group>
                      }
                      {/* Others Boolean */}
                      {field.FieldType === 'Boolean' && (field.FieldName !== 'Active' && field.FieldName !== 'External' && field.FieldName !== 'Highlighted' && field.FieldName !== 'Administrator' && field.FieldName !== 'Cost_Following' && field.FieldName !== 'Reporting' && field.FieldName !== 'Static_Data' && field.FieldName !== 'Users_Config' && field.FieldName !== 'AsTemplate') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }) : null}>
                            {field.FieldValue === "True" && <span className="iconCheck icons"></span>}
                            {field.FieldValue === "False" && <span className="iconCircleGrey icons"></span>}
                          </div>
                          {/* <Form.Check className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} type="checkbox" label="" checked={this.getFieldChecked(field)} disabled={guestLicence || !this.getFieldEditable(field)}
                            onChange={(e) => { this.updateBoolean(field.FieldName); this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue) }} /> */}
                        </Form.Group>
                      }
                      {/* ----- Dates ----- */}
                      {/* FieldType Date Editable (StartDate, EndDate, Initial StartDate, Initial EndDate) */}
                      {field.FieldType === 'Date' && (!guestLicence && this.getFieldEditable(field)) && (field.FieldName === 'StartDate' || field.FieldName === 'EndDate' || field.FieldName === 'Initial_StartDate' || field.FieldName === 'Initial_EndDate') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsDate" : "detailsDateNoLabel")}>
                            {field.FieldName === 'StartDate' && <DatePickerCalendar ref={this.startDate} Date={startDate} FieldName={field.FieldName} Limitation={endDate} Display={'Datepicker'} Editable={this.getFieldEditable(field)} onDateChange={this.updateDate}></DatePickerCalendar>}
                            {field.FieldName === 'EndDate' && <DatePickerCalendar ref={this.endDate} Date={endDate} FieldName={field.FieldName} Limitation={startDate} Display={'Datepicker'} Editable={this.getFieldEditable(field)} onDateChange={this.updateDate}></DatePickerCalendar>}
                            {field.FieldName === 'Initial_StartDate' && <DatePickerCalendar ref={this.initialStartDate} Date={initialStartDate} FieldName={field.FieldName} Limitation={initialEndDate} Display={'Datepicker'} Editable={this.getFieldEditable(field)} onDateChange={this.updateDate}></DatePickerCalendar>}
                            {field.FieldName === 'Initial_EndDate' && <DatePickerCalendar ref={this.initialEndDate} Date={initialEndDate} FieldName={field.FieldName} Limitation={initialStartDate} Display={'Datepicker'} Editable={this.getFieldEditable(field)} onDateChange={this.updateDate}></DatePickerCalendar>}
                          </div>
                        </Form.Group>
                      }
                      {/* FieldType Date Editable (Custom Fields) */}
                      {field.FieldType === 'Date' && (!guestLicence && this.getFieldEditable(field)) && (field.FieldName !== 'StartDate' && field.FieldName !== 'EndDate' && field.FieldName !== 'Initial_StartDate' && field.FieldName !== 'Initial_EndDate') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsDate" : "detailsDateNoLabel")}>
                            <DatePickerCalendar Date={field.FieldValue} FieldName={field.FieldName} Display={'Datepicker'} Editable={this.getFieldEditable(field)} onDateChange={this.updateDate}></DatePickerCalendar>
                          </div>
                        </Form.Group>
                      }
                      {/* FieldType Date Non Editable */}
                      {field.FieldType === 'Date' && (guestLicence || !this.getFieldEditable(field)) && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsDate" : "detailsDateNoLabel")}>
                            <DatePickerCalendar Date={field.FieldValue} FieldName={field.FieldName} Display={'Datepicker'} Editable={false} onDateChange={this.updateDate}></DatePickerCalendar>
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Double ----- */}
                      {field.FieldType === 'Double' && (field.FieldName !== 'Meteo' && field.FieldName !== 'Trend') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && field.Unit && <Form.Label className="detailsLabel">{field.Label + " " + field.Unit}</Form.Label>}
                          {visibleLabels && !field.Unit && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <Form.Control className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} rows="1" placeholder={field.Label} value={field.FieldValue} readOnly={guestLicence || !this.getFieldEditable(field)} 
                            onChange={(e) => this.updateFields(e, field.FieldName)} onKeyDown={(e) => this.handleKeyPressed(e)} 
                            onBlur={(e) => this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue)} />
                        </Form.Group>
                      }
                      {/* ----- Files ----- */}
                      {field.FieldType === 'Files' && 
                        <Form.Group className="detailsFieldList">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsListLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsFiles" : "detailsFilesNoLabel")}>
                            <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'} onFilesPopupOpen={this.openFilesPopup}></Axe>
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Axis Table ----- */}
                      {field.FieldType === 'AxisTable' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {(!guestLicence && this.getFieldEditable(field)) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverObjectTable(field)}>
                                <div className="height100width100"><Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- HTML ----- */}
                      {field.FieldType === "HTML" && 
                        <Form.Group className="detailsFieldText">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsTextAreaLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsHTML" : "detailsHTMLNoLabel")}>
                            <TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} FieldName={field.FieldName} Label={field.Label} Value={field.FieldValue} Edit={false} Editable={!guestLicence && this.getFieldEditable(field)} onTextUpdate={this.checkModification}></TextEditor>
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Link ----- */}
                      {field.FieldType === 'Link' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Link */}
                          {field.FieldValue !== "" && <a target="_self" className="" href={field.FieldValue !== "" ? field.FieldValue : "#"}>
                            <span className="iconLink iconsPath mr10" alt="Link"></span>
                          </a>}
                          {/* Field */}
                          <Form.Control className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} rows="1" placeholder={field.Label} value={field.FieldValue} readOnly={guestLicence || !this.getFieldEditable(field)} 
                            onChange={(e) => this.updateFields(e, field.FieldName)} onKeyDown={(e) => this.handleKeyPressed(e)} 
                            onBlur={(e) => this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue)} />
                        </Form.Group>
                      }
                      {/* Location */}
                      {field.FieldType === 'Location' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {!guestLicence && this.getFieldEditable(field) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverAxe(field)}>
                                <div className=""><Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Meteo & Trend ----- */}
                      {(field.FieldName === 'Meteo' || field.FieldName === 'Trend') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {(!guestLicence && this.getFieldEditable(field)) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverAxe(field)}>
                                <div className=""><Axe CurrentView={currentView} Axe={field.FieldName} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Progress ----- */}
                      {field.FieldType === 'Percentage' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsProgress" : "detailsProgressNoLabel")}><Progress Progress={field.FieldValue}></Progress></div>
                        </Form.Group>
                      }
                      {/* QR Code */}
                      {field.FieldType === 'QRCode' && 
                        <Form.Group className="detailsFieldQRCode">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")}>
                            <QRCode value={url} size={128} style={{ height: 'auto', width: 'auto' }} viewBox={`0 0 128 128`}/>
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Rating ----- */}
                      {field.FieldType === 'Rating' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")}>
                            {field.FieldValue === "5" && <div className="">
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "1") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                            {field.FieldValue === "4" && <div className="">
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "1") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                            {field.FieldValue === "3" && <div className="">
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "1") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                            {field.FieldValue === "2" && <div className="">
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "1") }) : null}></span>
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                            {field.FieldValue === "1" && <div className="">
                              <span className="iconRating iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "0") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                            {(field.FieldValue === "0" || field.FieldValue === "") && <div className="">
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "1") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "2") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "3") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "4") }) : null}></span>
                              <span className="iconRatingEmpty iconsRating mr10" onClick={(!guestLicence && this.getFieldEditable(field)) ? ((e) => { this.updateRating(field.FieldName, "5") }) : null}></span>
                            </div>}
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Resource & Project Manager ----- */}
                      {(field.FieldType === 'Resource' || field.FieldName === 'Project_Manager') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {(!guestLicence && this.getFieldEditable(field)) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverAxe(field)}>
                                <div className=""><Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Resource Table ----- */}
                      {field.FieldType === 'ResourceTable' && 
                        <Form.Group className="detailsFieldList">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsListLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {(!guestLicence && this.getFieldEditable(field)) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverResourceTable(field)}>
                                <div className="height100width100"><Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Type={field.FieldType} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* ----- Timeline ----- */}
                      {field.FieldName === 'Timeline' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          {startDate && endDate && status && 
                            <div className={(visibleLabels ? "detailsProgress" : "detailsProgressNoLabel")}>
                              <Timeline View={'Block'} StartDate={startDate} EndDate={endDate} Status={status}></Timeline>
                            </div>
                          }
                        </Form.Group>
                      }
                      {/* ----- String ----- */}
                      {/* Data & Meteo Freshness */}
                      {(field.FieldName === 'Data_Freshness' || field.FieldName === 'Meteo_Freshness') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {/* Editable */}
                            {(!guestLicence && this.getFieldEditable(field)) && 
                              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverAxe(field)}>
                                <div className=""><Axe CurrentView={currentView} Axe={field.FieldName} Value={field.FieldValue} View={'Block'}></Axe></div>
                              </OverlayTrigger>
                            }
                            {/* Non Editable */}
                            {(guestLicence || !this.getFieldEditable(field)) && 
                              <Axe CurrentView={currentView} Axe={field.FieldName} Value={field.FieldValue} View={'Block'}></Axe>
                            }
                          </div>
                        </Form.Group>
                      }
                      {/* Description / Comment / Objective / Scope */}
                      {/* {(field.FieldName === 'Description' || field.FieldName === 'Comment' || field.FieldName === 'Objective' || field.FieldName === 'Scope') && 
                        <Form.Group className="detailsFieldText">
                          <Form.Label className="detailsTextAreaLabel col-md-4">{field.Label}</Form.Label>
                          <div className="col-md-8">
                            <Form.Control id="" className="detailsField" as="textarea" rows={this.getRowsNb(field.FieldName, field.FieldValue)} placeholder={field.Label} value={field.FieldValue} readOnly={!this.getFieldEditable(field)} 
                              onChange={(e) => { field.Edit = true; this.updateFields(e, field.FieldName) }} 
                              onBlur={(e) => { this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue); field.Edit = false }} />
                            {field.Edit && <Form.Label className="fs12 light-grey">{this.getRemainingCaracters(field.FieldValue.length, 1000)}</Form.Label>}
                          </div>
                        </Form.Group>
                      } */}
                      {/* Email */}
                      {field.FieldType === 'String' && field.FieldName === 'Email' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <Form.Control className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} rows="1" placeholder={field.Label} value={field.FieldValue} readOnly={guestLicence || !this.getFieldEditable(field)} 
                            onChange={(e) => this.updateFields(e, field.FieldName)} onKeyDown={(e) => this.handleKeyPressed(e)} 
                            onBlur={(e) => this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue)} />
                        </Form.Group>
                      }
                      {/* Others String */}
                      {field.FieldType === 'String' && (field.FieldName !== 'Name' && field.FieldName !== 'Email' && field.FieldName !== 'Data_Freshness' && field.FieldName !== 'Meteo_Freshness' && field.FieldName !== 'WarningMessage') && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <Form.Control className={(visibleLabels ? "detailsField" : "detailsFieldNoLabel")} rows="1" placeholder={field.Label} value={field.FieldValue} readOnly={guestLicence || !this.getFieldEditable(field)} 
                            onChange={(e) => this.updateFields(e, field.FieldName)} onKeyDown={(e) => this.handleKeyPressed(e)} 
                            onBlur={(e) => this.checkModification(itemId, itemType, field.FieldName, field.OldValue, field.FieldValue)} />
                        </Form.Group>
                      }
                      {/* ----- Warnings ----- */}
                      {field.FieldName === 'WarningMessage' && 
                        <Form.Group className="detailsFieldRow">
                          {/* Label */}
                          {visibleLabels && <Form.Label className="detailsLabel">{field.Label}</Form.Label>}
                          {/* Field */}
                          <div className={(visibleLabels ? "detailsAxe" : "detailsAxeNoLabel")}>
                            {field.FieldValue && <div className="fs12 grey italic"><span className="iconWarningRed mediumIcons"></span>{field.FieldValue}</div>}
                            {!field.FieldValue && <div className="fs12 green bold italic">{Traduction.translate(language, 'no_warning')}</div>}
                          </div>
                        </Form.Group>
                      }
                    </div>
                  )}
                </Form>
              </div>
            </div>

            {/* Messages */}
            <div className={(visibleMessages ? "blockMessages" : "hidden")}>
              <div className="detailsComments">
                {/* Label */}
                <div className="detailsCommentsTitle">
                  <span className="iconComments iconsFilter mr10"></span>
                  <span className="">{Traduction.translate(language, 'discussion_thread')}</span>
                </div>
                {/* Message list */}
                <div className={(!guestLicence ? "detailsCommentsMessages" : "detailsCommentsMessagesGuest") + " scrollbar-y"}>
                  {messages.map((message, index) => 
                    <div key={index} className="">
                      {/* Messages grouped by Date */}
                      {index === 0 && <div className="detailsCommentDateBorder"><span className="detailsCommentDate">{message.CreationDateLabel}</span></div>}
                      {(index > 0 && messages[index].CreationDate !== messages[index - 1].CreationDate) && <div className="detailsCommentDateBorder"><span className="detailsCommentDate">{message.CreationDateLabel}</span></div>}

                      {/* Message from User with Popover */}
                      {message.UserId == userId && <div className="detailsComment cursor">
                        {/* Message User & Time */}
                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopoverMessage(message, index)}>
                          <div className="flex align-items-center">
                            <span className="detailsCommentUser">{message.UserFullName}</span>
                            <span className="detailsCommentDateTime">- {message.CreationTime}</span>
                            {/* {message.UserId == userId && <span className="iconClear icons cursor mh15" onClick={(e) => this.deleteComment(message, index)}></span>} */}
                          </div>
                        </OverlayTrigger>
                        {/* Message content */}
                        <Form.Control id="" className="detailsCommentEdit" as="textarea" rows={this.getRowsNb('Message', message.MessageString)} placeholder={Traduction.translate(language, 'add_message')} value={message.MessageString}
                          onLoad={this.handleAutosize()} onKeyDown={this.handleKeyDown} onChange={(e) => this.updateComment(e, index)} onBlur={(e) => this.saveComment(message, index)} />
                      </div>}

                      {/* Message not from User without Popover */}
                      {message.UserId != userId && <div className="detailsComment">
                        {/* Message User & Time */}
                        <div className="flex align-items-center">
                          <span className="detailsCommentUser">{message.UserFullName}</span>
                          <span className="detailsCommentDateTime">- {message.CreationTime}</span>
                          {/* {message.UserId == userId && <span className="iconClear icons cursor mh15" onClick={(e) => this.deleteComment(message, index)}></span>} */}
                        </div>
                        {/* Message content */}
                        <div className="detailsCommentMessage">{message.MessageString}</div>
                      </div>}
                    </div>
                  )}
                  {/* Messages bottom for scrolling */}
                  <div ref={this.messagesBottom} className=""></div>
                </div>

                {/* Add new Message */}
                {!guestLicence && 
                  <Form.Group className="detailsCommentInput">
                    <Form.Control id="" className="detailsCommentsTextArea" as="textarea" rows="3" placeholder={Traduction.translate(language, 'add_message')} value={newMessage} onChange={(e) => this.updateNewComment(e)} />
                  </Form.Group>
                }

                {/* Add Message Button */}
                {!guestLicence && <Button className="detailsCommentsButton" variant="primary" onClick={(e) => this.addComment(newMessage)}><span className="iconSend iconsFilter"></span></Button>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockDetails;