import React, {Component} from 'react';
import { HeatMapComponent, Legend, Tooltip, Adaptor, Inject, HeatMap } from '@syncfusion/ej2-react-heatmap';
import '../Css/App.css';
import MiniHeatmap from './MiniHeatmap';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';

class MiniBlockWorkload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      title: null,
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      chartData: [],
      heatmap: {},
      height: null,
      occupationLevel: null,
      occupationMetric: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.getColumnsLabel = this.getColumnsLabel.bind(this);
    this.getRowsLabel = this.getRowsLabel.bind(this);

    this.miniblockWorkload = React.createRef();
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;
    const filters = this.props.Filters;
    // let height;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    // Get Block Height
    // if(this.miniblockWorkload.current) {
    //   height = (this.miniblockWorkload.current.offsetHeight - 20).toString();
    // }

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent, filters });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [], chart = {};
    let title, columnsHeatmap= [], rowsHeatmap= [], chartData = [], heatmap, height;
    let occupationLevel = 'Month', occupationMetric = 'Occupancy';

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Chart
      if(blockContent.Chart) {
        chart = blockContent.Chart;
      }
      
      // Heatmap
      if(blockContent.HeatMaps.length > 0) {
        title = blockContent.HeatMaps[0].Title;
        columnsHeatmap = blockContent.HeatMaps[0].Columns;
        rowsHeatmap = blockContent.HeatMaps[0].Rows;
        chartData = blockContent.HeatMaps[0].ChartData;

        // Build Heatmap Datasource
        heatmap = this.dataStructure(columnsHeatmap, rowsHeatmap, chartData);

        // Heatmap Height
        if(this.miniblockWorkload.current) {
          if(rowsHeatmap.length * 30 > (this.miniblockWorkload.current.offsetHeight - 20)) {
            height = (rowsHeatmap.length * 30).toString();
          }
          else {
            height = (this.miniblockWorkload.current.offsetHeight - 20).toString();
          }
        }
      }

      // Initialise Occupation Level & Metric
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationLevel')) {
        occupationLevel = currentView.Parameters.find(param => param.Name === 'OccupationLevel').Value;
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'OccupationMetric')) {
        occupationMetric = currentView.Parameters.find(param => param.Name === 'OccupationMetric').Value;
      }

      this.setState({ currentView, views, title, columns, rows, chart, chartData, heatmap, height, occupationLevel, occupationMetric });
    }
  }

  dataStructure(columns, rows, chartData) {
    let heatmap = {};

    heatmap.dataSource = [];
    heatmap.xAis = [];
    heatmap.yAis = [];

    if(chartData) {
      for(let x = 0; x < chartData.length; x++) {
        heatmap.dataSource.push([]);

        for(let y = 0; y < chartData[x].length; y++) {
          // Push ChartData corresponding cell (format value => Progress)
          heatmap.dataSource[x].push(chartData[x][y]);
          // heatmap.dataSource[x].push((chartData[x][y] * 100).toFixed(0));
        }
      }

      heatmap.xAis = this.getColumnsLabel(columns);
      heatmap.yAis = this.getRowsLabel(rows);
    }

    return heatmap;
  }

  getColumnsLabel(columns) {
    let labels = [];

    columns.forEach(column => {
      labels.push(column.Label);
    });
    
    return labels;
  }

  getRowsLabel(rows) {
    let labels = [];

    rows.forEach(row => {
      labels.push(row.Label);
    });

    return labels;
  }
  
  render() {
    const { itemId, itemType, currentView, views, itemTitle, blockType, blockLabel, blockContent, title, columns, rows, chart, chartData, heatmap, height, occupationLevel, occupationMetric } = this.state;
    let visibleComponent;

    // Initialise Visible Component
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
      visibleComponent = currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value;
    }
    else {
      visibleComponent = "Heatmap";
    }

    // Height
    // height = ((window.innerHeight - 240) * (blockContent.Height / 14)).toString();
    
    // Cell Settings
    if(occupationMetric === 'Occupancy') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };
    }
    else if(occupationMetric === 'Availability') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
    }
    else if(occupationMetric === 'Workload') {
      this.cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value}' };
    }

    // Palette Settings
    if(occupationMetric === 'Occupancy') {
      this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    }
    else if(occupationMetric === 'Availability') {
      if(occupationLevel === 'Month') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -20, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -20, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 20, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 20, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
      else if(occupationLevel === 'Week') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -5, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -5, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 5, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 5, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
      else if(occupationLevel === 'Day') {
        this.paletteSettings = { palette: [{ startValue: -2000000, endValue: -1, minColor: '#E21313', maxColor: '#FF9052' }, { startValue: -1, endValue: 0, minColor: '#FF9052', maxColor: '#EAFCFF' }, { startValue: 0, endValue: 1, minColor: '#EAFCFF', maxColor: '#A1E981' }, { startValue: 1, endValue: 2000000, minColor: '#A1E981', maxColor: '#A1E981' }] };
      }
    }
    else if(occupationMetric === 'Workload') {
      if(occupationLevel === 'Month') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 20, color: '#95B0B5' }, { value: 40, color: '#FFD589' }, { value: 60, color: '#FF9052' }] };
      }
      else if(occupationLevel === 'Week') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 5, color: '#95B0B5' }, { value: 10, color: '#FFD589' }, { value: 15, color: '#FF9052' }] };
      }
      else if(occupationLevel === 'Day') {
        this.paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 1, color: '#95B0B5' }, { value: 2, color: '#FFD589' }, { value: 3, color: '#FF9052' }] };
      }
    }
    // const paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    // const paletteSettings = { palette: [{ value: 0, color: '#EEEEEE' }, { value: 99, color: '#B1B2BE' }, { value: 100, color: '#00C77A' }, { value: 101, color: '#F57C12' }, { value: 500, color: '#F57C12' }] };

    return (
      <div className="miniblock">
        <div className="miniblockContent" ref={this.miniblockWorkload}>
          {currentView.ViewType === 3 && heatmap && heatmap.dataSource && heatmap.dataSource.length > 0 && 
            <div className="miniblockWorkload scrollbar-miniblock">
              {/* Heatmap */}
              {visibleComponent === 'Heatmap' && <HeatMapComponent id={blockContent.ViewDashboardBlockId.toString()} height={height} width={'100%'} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellSettings={this.cellSettings} paletteSettings={this.paletteSettings} legendSettings={{ visible: false }} showTooltip={false} ref={heatmap=>this.heatmap=heatmap}>
                <Inject services={[ Legend, Tooltip, Adaptor ]} />
              </HeatMapComponent>}

              {/* <div className="control-pane"> */}
                {/* <div className="control-section"> */}
                  {/* <MiniHeatmap ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} ChartData={chartData} Heatmap={heatmap}></MiniHeatmap> */}
                {/* </div> */}
              {/* </div> */}

              {/* Mini Pivot */}
              {visibleComponent === 'Graph' && <MiniPivot ref={this.workloadPivot} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
            </div>
          }

          {/* Mini Chart */}
          {((chart.Mode === 'Chart' && chart.Series && chart.Series.length > 0) || (chart.Mode === 'Table' && chart.ChartTable && chart.ChartTable.Rows.length > 0)) && <div className="miniblockTable">
            {currentView.ViewType === 7 && <MiniChart ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart}></MiniChart>}
          </div>}
        </div>
      </div>
    )
  }
}

export default MiniBlockWorkload;