import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      columnHeaders: [],
      columns: [],
      columnName: null,
      search: ''
    };

    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.updateColumn = this.updateColumn.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const columnHeaders = this.props.ColumnHeaders;
    const columns = this.props.Columns;
    const columnName = this.props.ColumnName;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, currentView, columnHeaders, columns, columnName });
  }

  getColumnLabel(columnName) {
    const columnHeaders = this.state.columnHeaders;
    let label = '';

    if(columnHeaders.find(column => column.FieldName === columnName)) {
      label = columnHeaders.find(column => column.FieldName === columnName).Label;
    }
    
    return label;
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const { columnHeaders, columns, columnName } = this.state;
    const search = event.target.value;
    let column;

    if(event.key === 'Enter' && search !== '') {
      if(columns.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        column = columns.find(value => value.Label.toLowerCase().includes(search.toLowerCase())).Name;

        // Close Popover
        if(document.getElementById("popover-basic")) {
          document.getElementById("popover-basic").classList.remove("show");
        }

        // Call the event from the Parent component through the props
        this.props.onColumnEdit(column);

        this.setState({ columnName: column });
      }
    }
  }

  updateColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnEdit(columnName);
  }

  render() {
    const { language, currentView, columnHeaders, columns, columnName, search } = this.state;

    return (
      <div className="filtersEditPopover">
        {/* Search */}
        <div className="filtersEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyDown={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Columns */}
        <div className="filtersEditColumns scrollbar-y">
          {columns.map((column, index) => {
            if(search === "" || column.toLowerCase().includes(search.toLowerCase())) {
              return <div key={index} className="filtersEditColumn" onClick={() => this.updateColumn(column)}>{this.getColumnLabel(column)}</div>;
            }
          })}
        </div>
      </div>
    );
  }
}

export default PopoverEditColumn;