import React, {Component} from 'react';
import { L10n } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';
import MiniCard from './MiniCard';
import MiniCardKanban from './MiniCardKanban';

// API
// const API_valid = '/WebAppService/GetPropagationsAndSaveModification';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class Kanban extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      editable: null,
      guestLicence: null,
      currentView: {},
      kanban: {},
      columns: [],
      rows: [],
      dataSource: [],
      axes: [],
      axe: null,
      keyField: null,
      modificationErrors: []
    };

    // Kanban Structure
    this.created = this.created.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.getColumnRestrictedValues = this.getColumnRestrictedValues.bind(this);
    this.getCurrentViewLevel = this.getCurrentViewLevel.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.dragStop = this.dragStop.bind(this);
    this.refreshKanban = this.refreshKanban.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.addRowsInDatasource = this.addRowsInDatasource.bind(this);
    this.buildDatasourceRow = this.buildDatasourceRow.bind(this);
    this.searchItem = this.searchItem.bind(this);
    // this.updateKanban = this.updateKanban.bind(this);
    this.updateAxe = this.updateAxe.bind(this);
    this.updateKeyField = this.updateKeyField.bind(this);

    // Template Functions
    this.templateColumn = this.templateColumn.bind(this);
    this.templateCard = this.templateCard.bind(this);
    this.templateKeyField = this.templateKeyField.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const kanban = this.props.Kanban;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const axes = this.props.Axes;
    let axe, keyField;
    
    // Get Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
    }

    // Get Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
    }

    // Build Table Datasource
    let dataSource = this.dataStructure(rows, axe, keyField);

    this.setState({ authId, language, itemId, itemType, blockType, editable, guestLicence, currentView, kanban, columns, rows, dataSource, axes, axe, keyField });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const kanban = this.props.Kanban;
    const columns = this.props.Columns;
    const rows = this.props.Rows;
    const axes = this.props.Axes;
    let axe, keyField;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || JSON.stringify(this.props.Kanban) !== JSON.stringify(prevProps.Kanban) || this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Get Current View Axe
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
        axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
      }
      // Get Current View KeyField
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
        keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
      }

      // Build Table Datasource
      let dataSource = this.dataStructure(rows, axe, keyField);

      if(this.kanban) {
        this.kanban.dataSource = dataSource;
      }

      this.refreshKanban(currentView, kanban);

      this.setState({ currentView, kanban, columns, rows, dataSource, axe, keyField });
    }
  }

  created() {
    const currentView = this.state.currentView;
    const kanban = this.state.kanban;
    let axe, keyField, columns = [];

    // Get Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
    }

    // Get Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
    }

    // Auto Generated Columns
    if(this.kanban) {
      // Clean Grid columns
      // this.kanban.columns = [];

      // const columns = kanban.AxisValues;

      // Loop through the columns to build and add them to the Kanban
      for(let i=0; i < kanban.AxisValues.length; i++) {
        // Define Column object for Grid
        var colObj = {
          headerText: kanban.AxisValues[i],
          keyField: kanban.AxisValues[i].Id + 1,
          template: this.templateColumn,
          allowDrag: true,
          allowDrop: true,
          allowToggle: false,
          isExpanded: true,
          showItemCount: true
        };
        
        // Add Column in the list
        columns.push(colObj);
      }

      // Update Kanban Columns 
      this.kanban.columns = columns;
    }
  }

  dataStructure(rows, axe, keyField) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Meteo/Trend
          if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            // Create Axe/KeyField string in Datasource for Kanban Component
            if(item.ColumnName === axe) {
              acc['Axe'] = JSON.parse(item.Value).Id + 1;
            }
            if(item.ColumnName === keyField) {
              acc['KeyField'] = JSON.parse(item.Value).Id.toString();
            }

            acc[item.ColumnName] = item.Value;
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            // Create Axe/KeyField string in Datasource for Kanban Component
            if(item.ColumnName === axe) {
              acc['Axe'] = JSON.parse(item.Value).Id + 1;
            }
            if(item.ColumnName === keyField) {
              acc['KeyField'] = JSON.parse(item.Value).Id.toString();
            }
            
            acc[item.ColumnName] = item.Value;
          }
          // Format Objects Table
          else if(item.FieldType === 'ObjectTable' && item.Value) {
            // Create Axe/KeyField string in Datasource for Kanban Component
            if(item.ColumnName === axe) {
              acc['Axe'] = JSON.parse(item.Value).Id + 1;
            }
            if(item.ColumnName === keyField) {
              acc['KeyField'] = JSON.parse(item.Value).Id.toString();
            }

            acc[item.ColumnName] = item.Value;
          }
          else {
            // Create Axe/KeyField string in Datasource for Kanban Component
            if(item.ColumnName === axe) {
              acc['Axe'] = item.Value;
            }
            if(item.ColumnName === keyField) {
              acc['KeyField'] = item.Value.toString();
            }

            // Add the Column Name/Values to the reduced Table
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  dataBound(args) {
    // console.log(args);
  }

  getColumnHeaders() {
    return this.state.columns;
  }

  getColumnRestrictedValues(columnHeaders, columnName) {
    // const columns = this.state.columns;

    if(columnHeaders.find(column => column.FieldName === columnName)) {
      return columnHeaders.find(column => column.FieldName === columnName).RestrictedValues;
    }
    else {
      return [];
    }
  }

  getCurrentViewLevel() {
    const currentView = this.state.currentView;
    let level;

    // Get View corresponding level
    if(currentView) {
      switch(currentView.DefaultLevel) {
        case 0:
            level = 'Tree';
            break;
        case 1:
            level = 'Business_Line';
            break;
        case 2:
            level = 'Project';
            break;
        case 3:
            level = 'Workpackage';
            break;
        case 4:
            level = 'Action';
            break;
        case 5:
            level = 'Task';
            break;
        case 6:
            level = 'Risk';
            break;
        case 7:
            level = 'Issue';
            break;
        case 8:
            level = 'Decision';
            break;
        case 9:
            level = 'Meeting';
            break;
        case 10:
            level = 'Definition';
            // level = 'Budget';
            break;
        case 11:
            level = 'Link';
            break;
        case 12:
            level = 'Resource';
            break;
        case 13:
            level = 'Entity';
            break;
        case 14:
            level = 'User';
            break;
        case 15:
            level = 'AxisValue';
            break;
        case 16:
            level = 'DeletedItem';
            break;
        case 18:
            level = 'Workload';
            break;
        case 19:
            level = 'CustomField';
            break;
        case 20:
            level = 'HashTag';
            break;
        case 21:
            level = 'BudgetCode';
            break;
        case 22:
            level = 'UserGroup';
            break;
        case 23:
            level = 'Warning';
            break;
        case 24:
            level = 'Sprint';
            break;
        case 25:
            level = 'AgileBoard';
            break;
        case 26:
            level = 'Dependency';
            break;
        default:
            break;
      }
    }

    return level;
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // console.log(args);
  }

  dialogOpen(args) {
    args.cancel = true;
  }

  dragStart(args) {
    // console.log(args);
  }

  dragStop(args) {
    const guestLicence = this.state.guestLicence;
    const data = args.data[0];
    const itemId = data.Item_ID.substring(1);
    const itemType = data.Item_Type;
    const oldValue = args.element.dataset.key;
    const axeId = data['Axe'];
    const keyFieldId = data['KeyField'];
    // const newValue = data[columnName];
    let columnName, newValue;

    // const currentRow = this.kanban.dataSource.find(row => row.Item_ID === itemId);

    // Axe modification
    if(this.kanban.columns.find(column => column.keyField == axeId)) {
      columnName = this.state.axe;

      newValue = JSON.stringify(this.kanban.columns.find(column => column.keyField == axeId).headerText);

      // Call the event from the Parent component through the props
      this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
    }
  }

  refreshKanban(currentView, kanban) {
    let axe, keyField, columns = [];

    // Get Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      axe = currentView.Parameters.find(param => param.Name === 'Axe1').Value;
    }

    // Get Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      keyField = currentView.Parameters.find(param => param.Name === 'Axe2').Value;
    }

    // Auto Generated Columns
    if(this.kanban) {
      // Clean Grid columns
      // this.kanban.columns = [];

      // const columns = kanban.AxisValues;

      // Loop through the columns to build and add them to the Kanban
      for(let i=0; i < kanban.AxisValues.length; i++) {
        // Define Column object for Grid
        var colObj = {
          headerText: kanban.AxisValues[i],
          keyField: kanban.AxisValues[i].Id + 1,
          template: this.templateColumn,
          allowDrag: true,
          allowDrop: true,
          allowToggle: false,
          isExpanded: true,
          showItemCount: true
        };

        // Add Column in the list
        columns.push(colObj);
      }

      // Update Kanban Columns
      this.kanban.columns = columns;

      // Update KeyField
      if(keyField === 'None') {
        this.kanban.swimlaneSettings.keyField = "";
      }
      else {
        this.kanban.swimlaneSettings.keyField = "KeyField";
        this.kanban.swimlaneSettings.template = this.templateKeyField;
      }
    }

    this.setState({ axe, keyField });
  }

  updateDatasource(modification, propagations, warnings) {
    const axe = this.state.axe;
    const keyField = this.state.keyField;

    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    const columnName = modification.ColumnName;
    const newValue = modification.NewValue;
    let currentRow;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.kanban.dataSource));

    // Find current Row to apply Modification
    if(dataSource.find(row => row['Item_ID'] === modificationId)) {
      currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
    }

    if(currentRow) {
      // Update Current Row Column
      currentRow[columnName] = newValue;

      if(columnName === axe) {
        currentRow['Axe'] = JSON.parse(newValue).Id + 1;
      }

      // if(columnName === keyField) {
      //   currentRow['KeyField'] = JSON.parse(newValue).Id.toString();
      // }

      propagations.forEach(propagation => {
        const propagationId = propagation.ToItemType.substring(0,1) + propagation.ToItemId;
        const propagationName = propagation.ColumnName;
        const propagationValue = propagation.NewValue;
        let propagationRow;
  
        // Find propagation Row to apply Modification
        if(dataSource.find(row => row['Item_ID'] === propagationId)) {
          propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
        }

        if(propagationRow) {
          // Check if we find Column to apply Propagation
          if(propagationRow[propagationName]) {
            // Update Propagation Row Column
            propagationRow[propagationName] = propagationValue;
          }
        }
      });
    }

    // Apply Update on Kanban Card
    if(this.kanban) {
      this.kanban.updateCard(currentRow);
    }
  }

  // updateKanban(modification, propagations, warnings) {
  //   let rows = this.state.rows;

  //   rows.forEach(row => {
  //     let itemType = row.ItemType;
  //     let itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;

  //     if(modification.ItemType === itemType && modification.ItemId == itemId.substring(1)) {
  //       // Update Row with Modification NewValue
  //       row.Cells.find(cell => cell.ColumnName === modification.ColumnName).Value = modification.NewValue;

  //       // Update Warnings
  //       if(warnings.length > 0) {
  //         if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
  //           row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = warnings;
  //         }
  //       }
  //       else {
  //         if(row.Cells.find(cell => cell.ColumnName === 'WarningMessage')) {
  //           row.Cells.find(cell => cell.ColumnName === 'WarningMessage').Value = "";
  //         }
  //       }
  //     }

  //     // Get Row Propagations
  //     let props = this.getPropagations(propagations, itemType, itemId.substring(1));

  //     // Update Row with Propagations NewValue
  //     props.forEach(prop => {
  //       if(row.Cells.find(cell => cell.ColumnName === prop.ColumnName)) {
  //         row.Cells.find(cell => cell.ColumnName === prop.ColumnName).Value = prop.NewValue;
  //       }
  //     });
  //   });

  //   // Call the event from the Parent component through the props
  //   this.props.onKanbanChange(rows);
  // }

  addRowsInDatasource(rows) {
    const currentView = this.state.currentView;

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);

    if(this.kanban) {
      // Add Rows in the Datasource
      rows.forEach(row => {
        if(row.Cells.find(cell => cell.ColumnName === 'Item_Type').Value === level) {
          this.kanban.addCard(this.buildDatasourceRow(row));
        }
      });
    }
  }

  buildDatasourceRow(row) {
    const axe = this.state.axe;
    const keyField = this.state.keyField;
    let rowDatasource = {};

    rowDatasource = row.Cells.reduce((acc, item) => {
      // Format Meteo/Trend
      if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
        // Create Axe/KeyField string in Datasource for Kanban Component
        if(item.ColumnName === axe) {
          acc['Axe'] = JSON.parse(item.Value).Id + 1;
        }
        if(item.ColumnName === keyField) {
          acc['KeyField'] = JSON.parse(item.Value).Id.toString();
        }

        acc[item.ColumnName] = item.Value;
      }
      // Format Objects
      else if(item.FieldType === 'Object' && item.Value) {
        // Create Axe/KeyField string in Datasource for Kanban Component
        if(item.ColumnName === axe) {
          acc['Axe'] = JSON.parse(item.Value).Id + 1;
        }
        if(item.ColumnName === keyField) {
          acc['KeyField'] = JSON.parse(item.Value).Id.toString();
        }
        
        acc[item.ColumnName] = item.Value;
      }
      else {
        // Create Axe/KeyField string in Datasource for Kanban Component
        if(item.ColumnName === axe) {
          acc['Axe'] = item.Value;
        }
        if(item.ColumnName === keyField) {
          acc['KeyField'] = item.Value.toString();
        }

        // Add the Column Name/Values to the reduced Table
        acc[item.ColumnName] = item.Value;
      }

      return acc;
    }, {});

    return rowDatasource;
  }

  searchItem(search) {
    let searchQuery = new Query();

    if(this.kanban) {
      // Check if search value is not empty
      if(search !== '') {
        // Build Kanban query (search on Name column)
        searchQuery = new Query().search(search, ['Id', 'Name', 'Summary'], 'contains', true);
      }

      this.kanban.query = searchQuery;
    }
  }

  updateAxe(axe) {
    const currentView = this.state.currentView;
    const kanban = this.state.kanban;
    // const axe = item.target.value;

    if(this.kanban) {
      // Update Kanban keyField
      this.kanban.keyField = axe;

      // Refresh Kanban
      this.refreshKanban(currentView, kanban);
    }

    this.setState({ axe });
  }

  updateKeyField(keyField) {
    // const keyField = item.target.value;

    if(this.kanban) {
      if(keyField === 'None') {
        this.kanban.swimlaneSettings.keyField = "";
      }
      else {
        this.kanban.swimlaneSettings.keyField = "KeyField";
        this.kanban.swimlaneSettings.template = this.templateKeyField;
      }
    }

    this.setState({ keyField });
  }

  // ----- ----- Template Functions ----- -----
  templateColumn(props) {
    const currentView = this.state.currentView;
    const axe = this.state.axe;
    const value = props.headerText;

    return <Axe CurrentView={currentView} Axe={axe} Value={value} View={'Kanban'}></Axe>;
  }

  templateCard(props) {
    const currentView = this.state.currentView;
    const columns = this.state.columns;
    const rows = this.state.rows;
    const minicard = props;

    // Get MiniCard ItemId & ItemType
    const itemId = minicard.Item_ID.substring(1);
    const itemType = minicard.Item_Type;

    return <MiniCardKanban ItemId={itemId} ItemType={itemType} CurrentView={currentView} Columns={columns} Rows={rows} Content={minicard}></MiniCardKanban>;
    // return <MiniCard Content={minicard} Name={minicard.Name} Id={minicard.Item_ID.substring(1)} Type={minicard.Item_Type} Status={minicard.Status} StartDate={minicard.StartDate} EndDate={minicard.EndDate} Progress={minicard.Progress}></MiniCard>;
  }

  templateKeyField(props) {
    const currentView = this.state.currentView;
    const columns = this.state.columns;
    const keyField = this.state.keyField;
    let value;

    if(columns.find(column => column.FieldName === keyField)) {
      if(columns.find(column => column.FieldName === keyField).RestrictedValues.find(value => value.Id == props.keyField)) {
        value = columns.find(column => column.FieldName === keyField).RestrictedValues.find(value => value.Id == props.keyField);
      }
    }

    // if(typeof props.keyField === 'string') {
    //   value = props.keyField;
    // }
    // else if(Object.entries(props.keyField).length > 0) {
    //   value = JSON.parse(props.keyField);
    // }

    return <div className="flex align-items-center">
      <Axe CurrentView={currentView} Axe={keyField} Value={value} View={'Kanban'}></Axe>
    </div>;
  }

  render() {
    const { language, itemId, itemType, blockType, editable, guestLicence, currentView, kanban, columns, rows, dataSource, axes, axe, keyField } = this.state;
    let width, nbColumns = 0;

    // Columns Number
    if(kanban && kanban.AxisValues) {
      nbColumns = kanban.AxisValues.length;
    }

    // Width
    if(((nbColumns * 215) + ((nbColumns-1) * 10)) > (window.innerWidth - 155)) {
      width = ((nbColumns * 215) + ((nbColumns-1) * 10)) + 'px';
    }
    else {
      width = '100%';
    }
    
    // Card Settings
    this.cardSettings = { keyField: "Item_ID", headerField: "Item_ID", template: this.templateCard };

    // Swimlane Settings
    if(keyField === 'None') {
      this.swimlaneSettings = {};
    }
    else {
      this.swimlaneSettings = { keyField: "KeyField", template: this.templateKeyField, allowDragAndDrop: false };
    }

    return (
      <div className="kanbanContent">
        {dataSource.length > 0 && 
          <KanbanComponent id="kanban" dataSource={dataSource} locale={Traduction.translate(language, 'locale')} height={'100%'} width={width} keyField={'Axe'} cardSettings={this.cardSettings} swimlaneSettings={this.swimlaneSettings} created={this.created} dialogOpen={this.dialogOpen} dragStart={this.dragStart} dragStop={this.dragStop} allowDragAndDrop={editable && !guestLicence} ref={kanban=>this.kanban=kanban}>
            {/* <ColumnsDirective>
              <ColumnDirective headerText="To Do" keyField="Open" allowToggle={true} isExpanded={true} template={this.templateColumn(this)}/>
              <ColumnDirective headerText="In Progress" keyField="InProgress" allowToggle={true} isExpanded={true} template={this.templateColumn(this)}/>
              <ColumnDirective headerText="Done" keyField="Close" allowToggle={true} isExpanded={true} template={this.templateColumn(this)}/>
              <ColumnDirective headerText="Cancelled" keyField="Cancelled" allowToggle={true} isExpanded={true} template={this.templateColumn.bind(this)}/>
              <ColumnDirective headerText="On Hold" keyField="On Hold" allowToggle={true} isExpanded={true} template={this.templateColumn.bind(this)}/>
            </ColumnsDirective> */}
          </KanbanComponent>
        }
      </div>
    )
  }
}

export default Kanban;