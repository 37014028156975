import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';

class MiniRiskMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      applicationParameters: [],
      impact: [],
      probability: [],
      rows: [],
      matrixData: []
    };

    // Data Structure
    this.buildMatrixData = this.buildMatrixData.bind(this);
    this.getCellData = this.getCellData.bind(this);

    // Actions
    this.displayMatrix = this.displayMatrix.bind(this);
    this.refreshMatrix = this.refreshMatrix.bind(this);    
    this.templateMatrix43 = this.templateMatrix43.bind(this);
    this.templateMatrix55 = this.templateMatrix55.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const applicationParameters = this.props.ApplicationParameters;
    const impact = this.props.Impact;
    const probability = this.props.Probability;
    const rows = this.props.Rows;

    // Build Risks Matrix
    const matrixData = this.buildMatrixData(rows);

    // const probability = ['Low', 'Medium', 'High'];
    // const impact = ['Low', 'Medium', 'High', 'Critical'];

    this.setState({ language, applicationParameters, impact, probability, rows, matrixData });
  }

  componentDidUpdate(prevProps) {
    const applicationParameters = this.props.ApplicationParameters;
    const impact = this.props.Impact;
    const probability = this.props.Probability;
    const rows = this.props.Rows;
    
    if(JSON.stringify(this.props.ApplicationParameters) !== JSON.stringify(prevProps.ApplicationParameters)) {
      this.setState({ applicationParameters });
    }
    if(this.props.Impact != prevProps.Impact || this.props.Probability != prevProps.Probability) {
      this.setState({ impact, probability });
    }
    if(JSON.stringify(this.props.Rows) != JSON.stringify(prevProps.Rows)) {
      // Build Risks Matrix
      const matrixData = this.buildMatrixData(rows);
      
      this.setState({ rows, matrixData });
    }
  }

  // Build Matrix Datasource based on Risks Table
  buildMatrixData(rows) {
    let rowData, index, matImpact, matProba;
    let matrixData = [];

    rows.forEach(element => {
      if(element.Cells) {
        if(element.Cells.find(cell => cell.ColumnName === 'Impact')) {
          matImpact = JSON.parse(element.Cells.find(cell => cell.ColumnName === 'Impact').Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === 'Probability')) {
          matProba = JSON.parse(element.Cells.find(cell => cell.ColumnName === 'Probability').Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === 'Index')) {
          index = element.Cells.find(cell => cell.ColumnName === 'Index').Value;
        }

        rowData = { "Impact": matImpact, "Probability": matProba, "Index": index };

        matrixData.push(rowData);
      }
    });

    return matrixData;
  }

  // Build each Cell of Matrix adding Risks with corresponding Impact & Probability
  getCellData(matrixData, impact, probability) {
    const applicationParameters = this.state.applicationParameters;
    let result = [];

    matrixData.map(item => {
      // Matrix 5 * 5
      if(applicationParameters && applicationParameters.find(param => param.Name === "RiskMatrix") && applicationParameters.find(param => param.Name === "RiskMatrix").Value === "5*5") {
        if(item.Impact.Id === impact && item.Probability.Id === probability) {
          result.push(item.Index);
        }
      }
      // Matrix 4 * 3
      else {
        if(item.Impact.Id === impact && item.Probability.Id === probability) {
          result.push(item.Index);
        }
      }
    });

    return result;
  }

  getImpactLabel(id) {
    const impact = this.state.impact;
    let label;

    if(impact.find(item => item.Id === id)) {
      label = impact.find(item => item.Id === id).Label;
    }

    return label;
  }

  displayMatrix(matrixData, applicationParameters) {
    // Template Matrix 5 * 5
    if(applicationParameters && applicationParameters.find(param => param.Name === "RiskMatrix") && applicationParameters.find(param => param.Name === "RiskMatrix").Value === "5*5") {
      return this.templateMatrix55(matrixData);
    }
    // Template Matrix 4 * 3
    else {
      return this.templateMatrix43(matrixData);
    }
  }

  refreshMatrix(rows) {
    // Build Risks Matrix
    const matrixData = this.buildMatrixData(rows);

    this.setState({ matrixData });
  }

  templateMatrix43(matrixData) {
    const language = this.state.language;

    return <div className="miniRiskMatrix43">
      {/* Impact Critical */}
      <div className="miniMatrixHeight43">
        {/* Probability Low */}
        <div className="miniMatrixWidth43">
          <div id="critical-low" className="miniMatrixCell43 border-critical-low">
            {this.getCellData(matrixData, 4, 1).length > 0 && 
              <span className="miniMatrixResult43 bg-critical-low white">{this.getCellData(matrixData, 4, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 1).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-critical-low white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth43">
          <div id="critical-medium" className="miniMatrixCell43 border-critical-medium">
            {this.getCellData(matrixData, 4, 2).length > 0 && 
              <span className="miniMatrixResult43 bg-critical-medium white">{this.getCellData(matrixData, 4, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 2).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-critical-medium white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth43">
          <div id="critical-high" className="miniMatrixCell43 border-critical-high">
            {this.getCellData(matrixData, 4, 3).length > 0 && 
              <span className="miniMatrixResult43 bg-critical-high white">{this.getCellData(matrixData, 4, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 3).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-critical-high white">{item}</span>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact High */}
      <div className="miniMatrixHeight43">
        {/* Probability Low */}
        <div className="miniMatrixWidth43">
          <div id="high-low" className="miniMatrixCell43 border-high-low">
            {this.getCellData(matrixData, 3, 1).length > 0 && 
              <span className="miniMatrixResult43 bg-high-low white">{this.getCellData(matrixData, 3, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 1).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-high-low white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth43">
          <div id="high-medium" className="miniMatrixCell43 border-high-medium">
            {this.getCellData(matrixData, 3, 2).length > 0 && 
              <span className="miniMatrixResult43 bg-high-medium white">{this.getCellData(matrixData, 3, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 2).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-high-medium white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth43">
          <div id="high-high" className="miniMatrixCell43 border-high-high">
            {this.getCellData(matrixData, 3, 3).length > 0 && 
              <span className="miniMatrixResult43 bg-high-high white">{this.getCellData(matrixData, 3, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 3).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-high-high white">{item}</span>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact Medium */}
      <div className="miniMatrixHeight43">
        {/* Probability Low */}
        <div className="miniMatrixWidth43">
          <div id="medium-low" className="miniMatrixCell43 border-medium-low">
            {this.getCellData(matrixData, 2, 1).length > 0 && 
              <span className="miniMatrixResult43 bg-medium-low white">{this.getCellData(matrixData, 2, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 1).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-medium-low white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth43">
          <div id="medium-medium" className="miniMatrixCell43 border-medium-medium">
            {this.getCellData(matrixData, 2, 2).length > 0 && 
              <span className="miniMatrixResult43 bg-medium-medium white">{this.getCellData(matrixData, 2, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 2).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-medium-medium white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth43">
          <div id="medium-high" className="miniMatrixCell43 border-medium-high">
            {this.getCellData(matrixData, 2, 3).length > 0 && 
              <span className="miniMatrixResult43 bg-medium-high white">{this.getCellData(matrixData, 2, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 3).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-medium-high white">{item}</span>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact Low */}
      <div className="miniMatrixHeight43">
        {/* Probability Low */}
        <div className="miniMatrixWidth43">
          <div id="low-low" className="miniMatrixCell43 border-low-low">
            {this.getCellData(matrixData, 1, 1).length > 0 && 
              <span className="miniMatrixResult43 bg-low-low white">{this.getCellData(matrixData, 1, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 1).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-low-low white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth43">
          <div id="low-medium" className="miniMatrixCell43 border-low-medium">
            {this.getCellData(matrixData, 1, 2).length > 0 && 
              <span className="miniMatrixResult43 bg-low-medium white">{this.getCellData(matrixData, 1, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 2).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-low-medium white">{item}</span>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth43">
          <div id="low-high" className="miniMatrixCell43 border-low-high">
            {this.getCellData(matrixData, 1, 3).length > 0 && 
              <span className="miniMatrixResult43 bg-low-high white">{this.getCellData(matrixData, 1, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 3).map((item, index) => {
              return <span key={index} className="matrixResult43 bg-low-high white">{item}</span>
            })} */}
          </div>
        </div>
      </div>
    </div>;
  }

  templateMatrix55(matrixData) {
    const language = this.state.language;

    return <div className="miniRiskMatrix55">
      {/* Impact Critical */}
      <div className="miniMatrixHeight55">
        {/* Probability Very Low */}
        <div className="miniMatrixWidth55">
          <div id="critical-verylow" className="miniMatrixCell55 border-critical-verylow"> 
            {this.getCellData(matrixData, 4, 0).length > 0 && 
              <span className="miniMatrixResult55 bg-critical-verylow white">{this.getCellData(matrixData, 4, 0).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 0).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-critical-verylow white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Low */}
        <div className="miniMatrixWidth55">
          <div id="critical-low" className="miniMatrixCell55 border-critical-low"> 
            {this.getCellData(matrixData, 4, 1).length > 0 && 
              <span className="miniMatrixResult55 bg-critical-low white">{this.getCellData(matrixData, 4, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 1).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-critical-low white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth55">
          <div id="critical-medium" className="miniMatrixCell55 border-critical-medium">
            {this.getCellData(matrixData, 4, 2).length > 0 && 
              <span className="miniMatrixResult55 bg-critical-medium white">{this.getCellData(matrixData, 4, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 2).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-critical-medium white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth55">
          <div id="critical-high" className="miniMatrixCell55 border-critical-high"> 
            {this.getCellData(matrixData, 4, 3).length > 0 && 
              <span className="miniMatrixResult55 bg-critical-high white">{this.getCellData(matrixData, 4, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 3).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-critical-high white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Critical */}
        <div className="miniMatrixWidth55">
          <div id="critical-critical" className="miniMatrixCell55 border-critical-critical"> 
            {this.getCellData(matrixData, 4, 4).length > 0 && 
              <span className="miniMatrixResult55 bg-critical-critical white">{this.getCellData(matrixData, 4, 4).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 4, 4).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-critical-critical white">{item}</div>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact High */}
      <div className="miniMatrixHeight55">
        {/* Probability Very Low */}
        <div className="miniMatrixWidth55">
          <div id="high-verylow" className="miniMatrixCell55 border-high-verylow">
            {this.getCellData(matrixData, 3, 0).length > 0 && 
              <span className="miniMatrixResult55 bg-high-verylow white">{this.getCellData(matrixData, 3, 0).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 0).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-high-verylow white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Low */}
        <div className="miniMatrixWidth55">
          <div id="high-low" className="miniMatrixCell55 border-high-low">
            {this.getCellData(matrixData, 3, 1).length > 0 && 
              <span className="miniMatrixResult55 bg-high-low white">{this.getCellData(matrixData, 3, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 1).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-high-low white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth55">
          <div id="high-medium" className="miniMatrixCell55 border-high-medium">
            {this.getCellData(matrixData, 3, 2).length > 0 && 
              <span className="miniMatrixResult55 bg-high-medium white">{this.getCellData(matrixData, 3, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 2).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-high-medium white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth55">
          <div id="high-high" className="miniMatrixCell55 border-high-high">
            {this.getCellData(matrixData, 3, 3).length > 0 && 
              <span className="miniMatrixResult55 bg-high-high white">{this.getCellData(matrixData, 3, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 3).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-high-high white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Critical */}
        <div className="miniMatrixWidth55">
          <div id="high-critical" className="miniMatrixCell55 border-high-critical">
            {this.getCellData(matrixData, 3, 4).length > 0 && 
              <span className="miniMatrixResult55 bg-high-critical white">{this.getCellData(matrixData, 3, 4).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 3, 4).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-high-critical white">{item}</div>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact Medium */}
      <div className="miniMatrixHeight55">
        {/* Probability Very Low */}
        <div className="miniMatrixWidth55">
          <div id="medium-verylow" className="miniMatrixCell55 border-medium-verylow">
            {this.getCellData(matrixData, 2, 0).length > 0 && 
              <span className="miniMatrixResult55 bg-medium-verylow white">{this.getCellData(matrixData, 2, 0).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 0).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-medium-verylow white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Low */}
        <div className="miniMatrixWidth55">
          <div id="medium-low" className="miniMatrixCell55 border-medium-low">
            {this.getCellData(matrixData, 2, 1).length > 0 && 
              <span className="miniMatrixResult55 bg-medium-low white">{this.getCellData(matrixData, 2, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 1).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-medium-low white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth55">
          <div id="medium-medium" className="miniMatrixCell55 border-medium-medium">
            {this.getCellData(matrixData, 2, 2).length > 0 && 
              <span className="miniMatrixResult55 bg-medium-medium white">{this.getCellData(matrixData, 2, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 2).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-medium-medium white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth55">
          <div id="medium-high" className="miniMatrixCell55 border-medium-high">
            {this.getCellData(matrixData, 2, 3).length > 0 && 
              <span className="miniMatrixResult55 bg-medium-high white">{this.getCellData(matrixData, 2, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 3).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-medium-high white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Critical */}
        <div className="miniMatrixWidth55">
          <div id="medium-critical" className="miniMatrixCell55 border-medium-critical">
            {this.getCellData(matrixData, 2, 4).length > 0 && 
              <span className="miniMatrixResult55 bg-medium-critical white">{this.getCellData(matrixData, 2, 4).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 2, 4).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-medium-critical white">{item}</div>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact Low */}
      <div className="miniMatrixHeight55">
        {/* Probability Very Low */}
        <div className="miniMatrixWidth55">
          <div id="low-verylow" className="miniMatrixCell55 border-low-verylow">
            {this.getCellData(matrixData, 1, 0).length > 0 && 
              <span className="miniMatrixResult55 bg-low-verylow white">{this.getCellData(matrixData, 1, 0).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 0).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-low-verylow white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Low */}
        <div className="miniMatrixWidth55">
          <div id="low-low" className="miniMatrixCell55 border-low-low">
            {this.getCellData(matrixData, 1, 1).length > 0 && 
              <span className="miniMatrixResult55 bg-low-low white">{this.getCellData(matrixData, 1, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 1).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-low-low white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth55">
          <div id="low-medium" className="miniMatrixCell55 border-low-medium">
            {this.getCellData(matrixData, 1, 2).length > 0 && 
              <span className="miniMatrixResult55 bg-low-medium white">{this.getCellData(matrixData, 1, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 2).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-low-medium white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth55">
          <div id="low-high" className="miniMatrixCell55 border-low-high">
            {this.getCellData(matrixData, 1, 3).length > 0 && 
              <span className="miniMatrixResult55 bg-low-high white">{this.getCellData(matrixData, 1, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 3).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-low-high white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Critical */}
        <div className="miniMatrixWidth55">
          <div id="low-critical" className="miniMatrixCell55 border-low-critical">
            {this.getCellData(matrixData, 1, 4).length > 0 && 
              <span className="miniMatrixResult55 bg-low-critical white">{this.getCellData(matrixData, 1, 4).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 1, 4).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-low-critical white">{item}</div>
            })} */}
          </div>
        </div>
      </div>
      {/* Impact Very Low */}
      <div className="miniMatrixHeight55">
        {/* Probability Very Low */}
        <div className="miniMatrixWidth55">
          <div id="verylow-verylow" className="miniMatrixCell55 border-verylow-verylow">
            {this.getCellData(matrixData, 0, 0).length > 0 && 
              <span className="miniMatrixResult55 bg-verylow-verylow white">{this.getCellData(matrixData, 0, 0).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 0, 0).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-verylow-verylow white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Low */}
        <div className="miniMatrixWidth55">
          <div id="verylow-low" className="miniMatrixCell55 border-verylow-low">
            {this.getCellData(matrixData, 0, 1).length > 0 && 
              <span className="miniMatrixResult55 bg-verylow-low white">{this.getCellData(matrixData, 0, 1).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 0, 1).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-verylow-low white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Medium */}
        <div className="miniMatrixWidth55">
          <div id="verylow-medium" className="miniMatrixCell55 border-verylow-medium">
            {this.getCellData(matrixData, 0, 2).length > 0 && 
              <span className="miniMatrixResult55 bg-verylow-medium white">{this.getCellData(matrixData, 0, 2).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 0, 2).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-verylow-medium white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability High */}
        <div className="miniMatrixWidth55">
          <div id="verylow-high" className="miniMatrixCell55 border-verylow-high">
            {this.getCellData(matrixData, 0, 3).length > 0 && 
              <span className="miniMatrixResult55 bg-verylow-high white">{this.getCellData(matrixData, 0, 3).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 0, 3).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-verylow-high white">{item}</div>
            })} */}
          </div>
        </div>
        {/* Probability Critical */}
        <div className="miniMatrixWidth55">
          <div id="verylow-critical" className="miniMatrixCell55 border-verylow-critical">
            {this.getCellData(matrixData, 0, 4).length > 0 && 
              <span className="miniMatrixResult55 bg-verylow-critical white">{this.getCellData(matrixData, 0, 4).length}</span>
            }
            {/* {matrixData && this.getCellData(matrixData, 0, 4).map((item, index) => {
              return <div key={index} className="miniMatrixResult55 bg-verylow-critical white">{item}</div>
            })} */}
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    let { language, applicationParameters, impact, probability, rows, matrixData } = this.state;

    return(
      <div className="riskMatrix">{this.displayMatrix(matrixData, applicationParameters)}</div>
    )
  }
}

export default MiniRiskMatrix;