import React, {Component} from 'react';
import { HeatMapComponent, Legend, Tooltip, Adaptor, Inject, HeatMap } from '@syncfusion/ej2-react-heatmap';
import '../Css/App.css';

class MiniHeatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      chartData: [],
      heatmap: {}
    };
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const chartData = this.props.ChartData;
    const heatmap = this.props.Heatmap;

    this.setState({ itemId, itemType, itemTitle, blockType, chartData, heatmap });
  }
  
  render() {
    const { itemId, itemType, itemTitle, blockType, chartData, heatmap } = this.state;
    let height;

    // Heatmap height
    height = ((window.innerHeight - 120) / 4).toString();
    
    // Cell Settings
    const cellSettings = { textStyle: { color: 'black' }, border: { width: 4, radius: 5, color: 'white' }, format: '{value} %' };

    // Palette Settings
    const paletteSettings = { palette: [{ value: 0, color: '#EAFCFF' }, { value: 99, color: '#95B0B5' }, { value: 100, color: '#A1E981' }, { value: 101, color: '#FFD589' }, { value: 500, color: '#FF9052' }] };
    // const paletteSettings = { palette: [{ value: 0, color: '#EEEEEE' }, { value: 99, color: '#B1B2BE' }, { value: 100, color: '#00C77A' }, { value: 101, color: '#F57C12' }, { value: 500, color: '#F57C12' }] };

    return (
      <div className="control-pane">
        <div className="control-section">
          <HeatMapComponent height={height} width={'100%'} dataSource={heatmap.dataSource} xAxis={{ labels: heatmap.xAis }} yAxis={{ labels: heatmap.yAis }} cellSettings={cellSettings} paletteSettings={paletteSettings} legendSettings={{ visible: false }} showTooltip={false} ref={heatmap=>this.heatmap=heatmap}>
            <Inject services={[ Legend, Tooltip, Adaptor ]} />
          </HeatMapComponent>
        </div>
      </div>
    )
  }
}

export default MiniHeatmap;