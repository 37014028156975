import React, {Component} from 'react';
import { Button, Form, FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import PopupConfirmation from './PopupConfirmation';

const API_files = '/WebAppService/GetFilesOfScope';
const API_load = '/WebAppService/AddFile';
const API_delete = '/WebAppService/DeleteFile';
const API_download = '/WebAppService/GetFile';

class PopupEditFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      columnName: null,
      columnLabel: null,
      files: [],
      fileId: null,
      confirm: false,
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getFiles = this.getFiles.bind(this);
    this.getFileIcon = this.getFileIcon.bind(this);

    // Actions
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.dataURItoBlob = this.dataURItoBlob.bind(this);
    this.triggerLoadFile = this.triggerLoadFile.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.downloadBlobFile = this.downloadBlobFile.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const columnName = this.props.ColumnName;
    const columnLabel = this.props.ColumnLabel;

    // Get List of Object Files
    this.getFiles(login, authId, itemId, itemType, columnName);

    this.setState({ login, authId, language, itemId, itemType, itemTitle, columnName, columnLabel });
  }

  componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const columnName = this.props.ColumnName;
    const columnLabel = this.props.ColumnLabel;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.ItemTitle !== prevProps.ItemTitle || this.props.ColumnName !== prevProps.ColumnName) {
      // Get List of Object Files
      this.getFiles(login, authId, itemId, itemType, columnName);

      this.setState({ itemId, itemType, itemTitle, columnName, columnLabel });
    }
  }

  async getFiles(login, authId, itemId, itemType, columnName) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'FieldName': columnName
      })
    };

    try {
      const response = await fetch(API_files, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetFilesOfScopeResult;

      if(results) {
        this.setState({ files: results, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getFileIcon(fileType) {
    switch(fileType) {
      case "docx":
          return <span className="iconWord iconsPopup mr10"></span>;
      case "xlsx":
          return <span className="iconExcel iconsPopup mr10"></span>;
      case "pptx":
          return <span className="iconPPT iconsPopup mr10"></span>;
      case "pdf":
          return <span className="iconPDF iconsPopup mr10"></span>;
      default:
          return <span className="iconFile iconsPopup mr10"></span>;
    }
  }

  async handleChangeFile(itemId, itemType, columnName, file) {
    const language = this.state.language;
    let fileName, split, extension;

    this.setState({ isLoading: true, errors: [] });

    await this.getBase64(file).then(result => {
      fileName = file.name;
      split = file.name.split('.');
      extension = split[split.length - 1];

      // Check file size
      if(file.size / (1024 * 1024) < 14) {
        this.loadFile(itemId, itemType, columnName, fileName, extension, this.dataURItoBlob(result));
      }
      else {
        this.updateErrors(Traduction.translate(language, 'file_size'));
      }
    });
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      if(file) {
        reader.readAsDataURL(file);
      }
      
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  dataURItoBlob(dataURI) {
    let byteString;
    let array = [];

    // Convert base64/URLEncoded data component to raw binary data held in a string
    if(dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = window.atob(dataURI.split(',')[1]);
    }

    // Separate out the mime component
    // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);

    for(let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    
    for(var i = 0; i < byteString.length; i++) {
      array[i] = ia[i];
    }
    
    return array;
  }

  triggerLoadFile() {
    if(document.getElementById("loadFile")) {
      document.getElementById("loadFile").click();
    }
  }

  async loadFile(itemId, itemType, columnName, fileName, fileType, fileData) {
    const { login, authId, language } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'FieldName': columnName,
        'FileName': fileName,
        'FileType': fileType,
        'FileData': fileData
      })
    };

    try {
      const response = await fetch(API_load, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.AddFileResult;

      if(result) {
        if(result.IsValid === true) {
          const files = this.state.files;

          result.ItemId = itemId;
          result.ItemType = itemType;
          result.FileName = fileName;
          result.FileType = fileType;

          files.push(result);

          this.setState({ files, isLoading: false });
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  confirmDelete(file) {
    this.setState({ confirm: true, fileId: file.CustomValueId });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  async deleteFile() {
    const { login, authId, language, fileId } = this.state;

    this.setState({ errors: [] });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CustomValueId': fileId
      })
    };

    try {
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteFileResult;

      if(result) {
        if(result.IsValid === true) {
          const files = this.state.files;
          let indexToDelete = -1;

          if(files.find(file => file.CustomValueId === fileId)) {
            indexToDelete = files.findIndex(file => file.CustomValueId === fileId);
          }

          // Delete File
          if(indexToDelete >= 0) {
            files.splice(indexToDelete, 1);
          }

          this.setState({ files });
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }

        this.setState({ confirm: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async downloadFile(fileId) {
    const { login, authId, language } = this.state;

    this.setState({ isLoading: true, errors: [] });

    // Change cursor to loading spinner
    document.body.style.cursor = "progress";

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CustomValueId': fileId
      })
    };

    try {
      const response = await fetch(API_download, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetFileResult;

      if(result) {
        if(result.IsValid === true) {
          const fileContent = result.FileData;
          const type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
          const fileName = result.FileName;

          // Create and Download Blob File
          this.downloadBlobFile(fileContent, type, fileName);

          // Change cursor to default
          document.body.style.cursor = "default";

          this.setState({ isLoading: false });
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  downloadBlobFile(arrayBuffer, type, fileName) {
    // Convert Bytes Array to Bytes
    const bytes = new Uint8Array(arrayBuffer);

    // Create Blob from Bytes
    const blob = new Blob([bytes], { type: type });
    
    // Create File link
    const link = document.createElement('a');

    if(link.download !== undefined) {
      // Define File link attributes
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.visibility = 'hidden';

      // Simulate click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  closePopup() {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const columnName = this.state.columnName;
    const files = this.state.files;

    // Call the event from the Parent component through the props
    this.props.onPopupClose(files, itemId, itemType, columnName);
  }

  render() {
    const { language, itemId, itemType, itemTitle, columnName, columnLabel, files, file, confirm, isLoading, errors } = this.state;

    return (
      <div className="filesPopup">
        <div className="filesInnerPopup">
          {/* Delete Confirmation Popup */}
          {confirm && <PopupConfirmation Message={Traduction.translate(language, 'delete_file')} onMessageConfirm={this.deleteFile} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

          <div className="popupEditFiles">
            {/* Files Title */}
            <div className="filesTitle">
              {/* Label */}
              <Form.Label className="filesLabel">
                <div className="filesLabelBlue">{itemTitle}</div>
                <div className="filesLabelYellow ml5">- {columnLabel}</div>
              </Form.Label>
              
              <div className="flex-end align-items-center">
                {/* Load File */}
                {!isLoading && <div className="loadFileReader">
                  <input id="loadFile" className="inputFileReader hidden" type="file" onChange={(e) => this.handleChangeFile(itemId, itemType, columnName, e.target.files[0])} />
                  <Button id="uploadTrigger" className="loadFileButton" variant="primary" onClick={() => this.triggerLoadFile()}>{Traduction.translate(language, 'load_file')}</Button>
                </div>}

                {/* Close Popup */}
                <div className="flex-end align-items-center"><span className="iconClear icons cursor" onClick={() => this.closePopup()}></span></div>
              </div>
            </div>

            {/* Errors */}
            {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

            {/* <div className="popupFiles"> */}
            <div className="popupFilesList scrollbar-miniblock">
              {files.map((file, index) => {
                return <div key={index} className="fileItem">
                  <span className="fileIcon cursor" onClick={() => this.downloadFile(file.CustomValueId)}>{this.getFileIcon(file.FileType)}</span>
                  <span className="fileName cursor" onClick={() => this.downloadFile(file.CustomValueId)}>{file.FileName}</span>
                  <span className="iconDelete iconsNav cursor mh10" onClick={() => this.confirmDelete(file)}></span>
                </div>;
              })}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PopupEditFiles;