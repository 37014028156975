import React, {Component} from 'react';
import '../Css/App.css';
import EmptyBlockCommunication from '../Images/EmptyBlockCommunication.png';

class MiniBlockCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      emptyBlock: null
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Build Data Structure
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Build Data Structure
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let emptyBlock;

    if(blockContent) {
      // Header
      if(blockContent.Headers) {
        if(blockContent.Headers.find(element => element.FieldName === 'EmptyBlock')) {
          emptyBlock = blockContent.Headers.find(element => element.FieldName === 'EmptyBlock').FieldValue;
        }
  
        this.setState({ emptyBlock });
      }
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, blockType, blockLabel, blockContent, emptyBlock } = this.state;
    
    return (
      <div className="miniblock">
        <div className="miniblockCommunication">
          <div className="miniblockCommunicationList scrollbar-miniblock">
            {blockContent.Communications && blockContent.Communications.map((communication, index) => 
              <div key={index} className="miniblockPresentation">
                {communication.Name && <div className="flex align-items-center">
                  <span className="iconPPT verysmallIcons mr10"/>
                  <div className="flex align-items-center">{communication.Name}</div>
                </div>}
              </div>
            )}
            {/* Empty Block */}
            {/* {Array.isArray(blockContent.Communications) && blockContent.Communications.length === 0 && <div className="miniblockEmpty scrollbar-miniblock">
              <div className=""><img className="iconsEmptyBlock" src={EmptyBlockCommunication} alt="EmptyBlock Communication"/></div>
              <div className=""><div className="miniblockEmptyText" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div></div>
            </div>} */}
          </div>
        </div>
      </div>
    )
  }
}

export default MiniBlockCommunication;