import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import LoadingSpinner from './LoadingSpinner';

const API_export = '/WebAppService/ExportCardBlockView';

class FiltersExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      guestLicence: null,
      currentView: {},
      isLoading: false,
      errors: []
    };
    
    this.exportBlockView = this.exportBlockView.bind(this);
    this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);
    this.downloadBlobFile = this.downloadBlobFile.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.templatePopover = this.templatePopover.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    
    this.setState({ login, authId, language, itemId, itemType, blockType, guestLicence, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      this.setState({ itemId, itemType });
    }
    if(this.props.BlockType !== prevProps.BlockType) {
      this.setState({ blockType });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(JSON.stringify(this.props.CurrentView) != JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  async exportBlockView(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_export, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.ExportCardBlockViewResult;

      if(result) {
        if(result.IsValid === true) {
          const url = result.Url;
          const fileBase64 = result.FileBase64;
          const fileContent = result.FileContents;
          const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          const fileName = result.FileName;

          // Convert Base64 File to ArrayBuffer
          // const arrayBuffer = this.base64ToArrayBuffer(fileBase64);

          // Create and Download Blob File
          this.downloadBlobFile(fileContent, type, fileName);
        }
        else {
          this.updateErrors(result.Errors);
        }

        this.setState({ isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  base64ToArrayBuffer(fileBase64) {
    const binaryString = window.atob(fileBase64);
    const bytes = new Uint8Array(fileBase64.length);

    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  downloadBlobFile(arrayBuffer, type, fileName) {
    // Convert Bytes Array to Bytes
    const bytes = new Uint8Array(arrayBuffer);

    // Create Blob from Bytes
    const blob = new Blob([bytes], { type: type });
    
    // Create File link
    const link = document.createElement('a');

    if(link.download !== undefined) {
      // Define File link attributes
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.style.visibility = 'hidden';

      // Simulate click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  updateErrors(error) {
    // Call the event from the Parent component through the props
    this.props.onErrorsUpdate(error);
  }

  exportCSV() {
    this.setState({ isLoading: true });

    // Call the event from the Parent component through the props
    this.props.onCSVExport();

    this.setState({ isLoading: false });
  }

  exportXLSX() {
    this.setState({ isLoading: true });

    // Call the event from the Parent component through the props
    this.props.onXLSXExport();

    this.setState({ isLoading: false });
  }

  exportJPEG() {
    this.setState({ isLoading: true });

    // Call the event from the Parent component through the props
    this.props.onJPEGExport();

    this.setState({ isLoading: false });
  }

  exportPNG() {
    this.setState({ isLoading: true });

    // Call the event from the Parent component through the props
    this.props.onPNGExport();

    this.setState({ isLoading: false });
  }

  exportPDF() {
    this.setState({ isLoading: true });

    // Call the event from the Parent component through the props
    this.props.onPDFExport();

    this.setState({ isLoading: false });
  }

  templatePopover() {
    const { login, authId, language, itemId, itemType, blockType, guestLicence, currentView } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex flex-column">
            {/* Export View */}
            {currentView && (currentView.ViewType === 0 || currentView.ViewType === 7) && 
              <div id="exportView" className="exportList" onClick={(e) => this.exportBlockView(login, authId, itemId, itemType, blockType, currentView)}>
                <span className="iconExcel icons mr10" alt="Export CSV"></span>
                <span className="fs12">{Traduction.translate(language, 'export_view')}</span>
              </div>
            }
            {/* Export CSV */}
            {!guestLicence && (!currentView || currentView.ViewType === 0 || currentView.ViewType === 3 || (currentView.ViewType === 7 && currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ChartMode') && currentView.Parameters.find(param => param.Name === 'ChartMode').Value === 'Table')) && 
              <div id="exportCSV" className="exportList" onClick={(e) => this.exportCSV()}>
                <span className="iconExcel icons mr10" alt="Export CSV"></span>
                <span className="fs12">{Traduction.translate(language, 'export_csv')}</span>
              </div>
            }
            {/* Export XLSX */}
            {!guestLicence && (!currentView || currentView.ViewType === 0 || currentView.ViewType === 3 || (currentView.ViewType === 7 && currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ChartMode') && currentView.Parameters.find(param => param.Name === 'ChartMode').Value === 'Table')) && 
              <div id="exportXLSX" className="exportList" onClick={(e) => this.exportXLSX()}>
                <span className="iconExcel icons mr10" alt="Export XLSX"></span>
                <span className="fs12">{Traduction.translate(language, 'export_xlsx')}</span>
              </div>
            }
            {/* Export JPEG */}
            {currentView && ((currentView.ViewType === 7 && currentView.Parameters && (!currentView.Parameters.find(param => param.Name === 'ChartMode') || currentView.Parameters.find(param => param.Name === 'ChartMode').Value === 'Chart')) || currentView.ViewType === 8) && 
              <div id="exportJPEG" className="exportList" onClick={(e) => this.exportJPEG()}>
                <span className="iconFile icons mr10" alt="Export JPEG"></span>
                <span className="fs12">{Traduction.translate(language, 'export_jpeg')}</span>
              </div>
            }
            {/* Export PNG */}
            {currentView && ((currentView.ViewType === 7 && currentView.Parameters && (!currentView.Parameters.find(param => param.Name === 'ChartMode') || currentView.Parameters.find(param => param.Name === 'ChartMode').Value === 'Chart')) || currentView.ViewType === 8) && 
              <div id="exportPNG" className="exportList" onClick={(e) => this.exportPNG()}>
                <span className="iconFile icons mr10" alt="Export PNG"></span>
                <span className="fs12">{Traduction.translate(language, 'export_png')}</span>
              </div>
            }
            {/* Export PDF */}
            {currentView && (currentView.ViewType === 0 || currentView.ViewType === 1 || (currentView.ViewType === 7 && currentView.Parameters && (!currentView.Parameters.find(param => param.Name === 'ChartMode') || currentView.Parameters.find(param => param.Name === 'ChartMode').Value === 'Chart')) || currentView.ViewType === 8) && 
              <div id="exportPDF" className="exportList" onClick={(e) => this.exportPDF()}>
                <span className="iconPDF icons mr10" alt="Export PDF"></span>
                <span className="fs12">{Traduction.translate(language, 'export_pdf')}</span>
              </div>
            }
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;

    return (
      <div className="">
        {!isLoading && <div className="">
          {['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'export_data')}</strong></Tooltip>}>
              <div className="filtersExport">
                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover()}>
                  <span className="iconExport iconsFilter cursor"/>
                </OverlayTrigger>
              </div>
            </OverlayTrigger>
          ))}
        </div>}
        {/* Loading Spinner */}
        {isLoading && <div className="spinnerExport"><LoadingSpinner></LoadingSpinner></div>}
      </div>
    )
  }
}

export default FiltersExport;