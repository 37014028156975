import React, {Component} from 'react';
import { Button } from 'react-bootstrap';
import { L10n } from '@syncfusion/ej2-base';
import { RichTextEditorComponent, HtmlEditor, Inject, Toolbar, QuickToolbar, Resize, Image, Link, NodeSelection, PasteCleanup } from '@syncfusion/ej2-react-richtexteditor';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      rowItemId: null,
      rowItemType: null,
      fieldName: null,
      label: null,
      value: null,
      htmlText: null,
      editable: null,
      edit: false
    };

    // Actions
    this.created = this.created.bind(this);
    this.blur = this.blur.bind(this);
    this.saveText = this.saveText.bind(this);
  }
    
  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const rowItemId = this.props.RowItemId;
    const rowItemType = this.props.RowItemType;
    const fieldName = this.props.FieldName;
    const label = this.props.Label;
    const value = this.props.Value;
    const editable = this.props.Editable;
    const edit = this.props.Edit;

    // window.addEventListener('beforeunload', function (e) {
    //   e.preventDefault();
    //   e.returnValue = '';
    // });

    this.setState({ language, itemId, itemType, blockType, rowItemId, rowItemType, fieldName, label, value, editable, edit });
  }
  
  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const rowItemId = this.props.RowItemId;
    const rowItemType = this.props.RowItemType;
    const fieldName = this.props.FieldName;
    const label = this.props.Label;
    const value = this.props.Value;
    const editable = this.props.Editable;
    const edit = this.props.Edit;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.FieldName !== prevProps.FieldName || this.props.Value !== prevProps.Value || this.props.Editable !== prevProps.Editable) {
      this.setState({ itemId, itemType, blockType, rowItemId, rowItemType, fieldName, label, value, editable, edit });
    }
  }

  created(args) {
    const value = this.state.value;

    // Focus In Text Editor
    if(this.editor) {
      this.editor.focusIn();
    }

    const selectionCursor = new NodeSelection();

    // Set Cursor at the end of Text Editor
    // if(value !== '') {
    //   const element = this.editor.contentModule.getDocument().getElementsByClassName('e-rte-content')[0].childNodes[1].lastChild;
    //   const range = document.createRange();

    //   range.setStart(element, 1);
    //   selectionCursor.setRange(document, range);
    // }
    // else {
    //   const range = this.editor.getRange();
    
    //   range.setStart(range.endContainer, range.endContainer.innerText.length);
    //   range.setEnd(range.endContainer, range.endContainer.innerText.length);
    
    //   selectionCursor.selectRange(document, range);
    // }
  }

  blur(args) {
    this.saveText();
  }

  saveText() {
    const { itemId, itemType, blockType, rowItemId, rowItemType, fieldName, value } = this.state;
    let htmlText;

    if(this.editor) {
      // Empty Text
      if(!this.editor.getHtml() || this.editor.getHtml() === "<div><p><br></p></div>" || this.editor.getHtml() === "<div><p></br></p></div>") {
        htmlText = "";
      }
      else {
        htmlText = this.editor.getHtml();
      }

      // Update in Tables/Tree for Blocks except Details & Meteo
      if(blockType !== 'Details' && blockType !== 'Qualitative') {
        // Build Modification object for checkModification function
        let modification = { ItemId: rowItemId.substring(1), ItemType: rowItemType, ColumnName: fieldName, NewValue: htmlText, OldValue: value };

        // Call the event from the Parent component through the props
        this.props.onTextChange(modification, [], []);

        // Call the event from the Parent component through the props with Modification value
        this.props.onTextUpdate(rowItemId.substring(1), rowItemType, fieldName, value, htmlText);

        this.setState({ htmlText });
      }
      // Update for Block Details & Meteo
      else {
        // Call the event from the Parent component through the props with Modification value
        this.props.onTextUpdate(itemId, itemType, fieldName, value, htmlText);

        this.setState({ htmlText, edit: false });
      }
      
      // this.setState({ htmlText });
    }
  } 

  render() {
    const { language, itemType, blockType, fieldName, label, value, editable, edit } = this.state;
    
    // Toolbar Settings
    this.toolbarSettings = { items: ['Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontColor', 'BackgroundColor', 'orderedList', 'unorderedList', '|', 'LowerCase', 'UpperCase', '|', 'clearFormat', 'Undo', 'Redo', '|', 'CreateLink', 'sourceCode'] };
    
    // Paste Cleanup Settings
    this.pasteCleanupSettings = { prompt: false, plainText: false, keepFormat: true };

    return (
      <div className="width100p">
        {edit && editable && <div className="">
          {/* Rich Text Component */}
          {(blockType === 'Details' || blockType === 'Qualitative') && 
            <RichTextEditorComponent locale={Traduction.translate(language, 'locale')} height={400} placeholder={Traduction.translate(language, 'add') + " " + label} value={value} toolbarSettings={this.toolbarSettings} pasteCleanupSettings={this.pasteCleanupSettings} created={this.created} blur={this.blur} enableXhtml={true} ref={textEditor=>this.editor=textEditor}>
              <Inject services={[ HtmlEditor, Toolbar, QuickToolbar, Link, Image, PasteCleanup ]} />
            </RichTextEditorComponent>
          }
          {(blockType !== 'Details' && blockType !== 'Qualitative') && 
            <RichTextEditorComponent locale={Traduction.translate(language, 'locale')} height={400} width={800} cssClass={'textEditorClass'} enableResize={true} placeholder={Traduction.translate(language, 'add') + " " + label} value={value} toolbarSettings={this.toolbarSettings} pasteCleanupSettings={this.pasteCleanupSettings} created={this.created} blur={this.blur} enableXhtml={true} ref={textEditor=>this.editor=textEditor}>
              <Inject services={[ HtmlEditor, Toolbar, QuickToolbar, Resize, Link, Image, PasteCleanup ]} />
            </RichTextEditorComponent>
          }

          <div className="flex flex-end fs10 mt10">{Traduction.translate(language, 'text_editor_message')}</div>

          {/* Button Validate or Cancel */}
          {/* {(blockType !== 'Details' && blockType !== 'Qualitative') && 
            <div className="flex flex-end mt10">
              <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.saveText(); this.props.onPopupClose() }}>{Traduction.translate(language, 'validate')}</Button>
              <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.setState({ edit: false }); this.props.onPopupClose() }}>{Traduction.translate(language, 'cancel')}</Button>
            </div>
          } */}
          {/* {(blockType === 'Details' || blockType === 'Qualitative') && 
            <div className="flex flex-end mt10">
              <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.saveText()}>{Traduction.translate(language, 'validate')}</Button>
              <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.setState({ edit: false })}>{Traduction.translate(language, 'cancel')}</Button>
            </div>
          } */}
        </div>}
        
        {(!edit || !editable) && <div className="flex flex-column">
          {value && <div className="cursor" dangerouslySetInnerHTML={{ __html: value }} onClick={() => this.setState({ edit: true })}/>}
          {!value && <div className="cursor" onClick={() => this.setState({ edit: true })}>{Traduction.translate(language, 'add') + " " + label}</div>}
        </div>}
      </div>
    )
  }
}

export default TextEditor;