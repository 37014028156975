import React, {Component} from 'react';
import { loadCldr, L10n } from '@syncfusion/ej2-base';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });
loadCldr(require('cldr-data/supplemental/numberingSystems.json'), require('cldr-data/main/fr/ca-gregorian.json'), require('cldr-data/main/fr/numbers.json'), require('cldr-data/main/fr/timeZoneNames.json'), require('cldr-data/main/es/ca-gregorian.json'), require('cldr-data/main/es/numbers.json'), require('cldr-data/main/es/timeZoneNames.json'));

class DatePickerCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      formatDate: null,
      itemId: null,
      date: null,
      fieldName: null,
      limitation: null,
      display: null,
      editable: null
    };

    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.formatDateDatePicker = this.formatDateDatePicker.bind(this);
    this.change = this.change.bind(this);
    this.applyModification = this.applyModification.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const date = this.props.Date;
    const fieldName = this.props.FieldName;
    const limitation = this.props.Limitation;
    const display = this.props.Display;
    const editable = this.props.Editable;

    this.setState({ language, formatDate, itemId, date, fieldName, limitation, display, editable });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const date = this.props.Date;
    const fieldName = this.props.FieldName;
    const limitation = this.props.Limitation;
    const display = this.props.Display;
    const editable = this.props.Editable;

    if(this.props.ItemId !== prevProps.ItemId || this.props.FieldName !== prevProps.FieldName) {
      this.setState({ itemId, fieldName });
    }
    if(this.props.Date !== prevProps.Date || this.props.Limitation !== prevProps.Limitation || this.props.Editable !== prevProps.Editable) {
      this.setState({ date, limitation, editable });
    }
    if(this.props.Display !== prevProps.Display) {
      this.setState({ display });
    }
  }

  formatDate(date) {
    const formatDate = this.state.formatDate;

    if(formatDate === 'MM/DD/YYYY') {
      return this.formatDateEn(date);
    }
    else if(formatDate === 'DD/MM/YYYY') {
      return this.formatDateFr(date);
    }
    else if(formatDate === 'YYYY-MM-DD') {
      return this.formatDateKr(date);
    }
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  // Format Date to English format for Backend
  // formatDateEn(date) {
  //   let formattedDate = "";

  //   if(date) {
  //     let day = date.getDate();
  //     let month = date.getMonth()+1; // Between 0 and 11
  //     let year = date.getFullYear();

  //     if(day < 10) {
  //       day = "0" + day;
  //     }
  //     if(month < 10) {
  //       month = "0" + month;
  //     }

  //     formattedDate = month + "/" + day + "/" + year;
  //   }
    
  //   return formattedDate;
  // }

  // Formatting Date for DatePicker
  formatDateDatePicker(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(year, month, day);
    }

    return formattedDate;
  }

  change(args) {
    const itemId = this.state.itemId;
    const fieldName = this.state.fieldName;

    let date = this.formatDateEn(args.value);

    // Call the event from the Parent component through the props
    this.props.onDateChange(fieldName, date, itemId);

    // this.setState({ date });
  }

  applyModification(modification) {
    const date = modification.NewValue;

    this.calendar.value = this.formatDateDatePicker(date);

    this.setState({ date });
  }

  cancelModification(modification) {
    const date = modification.OldValue;

    this.calendar.value = this.formatDateDatePicker(date);

    this.setState({ date });
  }

  render() {
    const { language, formatDate, itemId, date, fieldName, limitation, display, editable } = this.state;
    let startDate, endDate, format;

    // Format date
    if(formatDate === 'MM/DD/YYYY') {
      format = "MM/dd/yyyy";
    }
    else if(formatDate === 'DD/MM/YYYY') {
      format = "dd/MM/yyyy";
    }
    else if (formatDate === 'YYYY-MM-DD') {
      format = "yyyy-MM-dd";
    }

    // Define limitation so StartDate <= EndDate
    if(fieldName === 'StartDate' || fieldName === 'Initial_StartDate') {
      startDate = false;
      endDate = limitation;
      // startDate = date;
    }
    else if(fieldName === 'EndDate' || fieldName === 'Initial_EndDate') {
      startDate = limitation;
      endDate = false;
      // endDate = date;
    }

    return (
      <div className="datepicker">
        {/* Datepicker */}
        {display === 'Datepicker' && 
          <DatePickerComponent locale={Traduction.translate(language, 'locale')} format={format} width={120} firstDayOfWeek={1} weekNumber={true} value={this.formatDateDatePicker(date)} min={this.formatDateDatePicker(startDate)} max={this.formatDateDatePicker(endDate)} change={this.change} enabled={editable} allowEdit={editable} showTodayButton={true} ref={calendar=>this.calendar=calendar}></DatePickerComponent>
        }
        {/* Custom */}
        {display === 'Custom' && <div className="minicardDatepicker">
          <span className="minicardDate">{this.formatDate(date)}</span>
          <span className="iconCalendarGrey iconsDatepicker"></span>
        </div>}
      </div>
    );
  }
}

export default DatePickerCalendar;