import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import DatePickerCalendar from './DatePickerCalendar';

class PlanningTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {}
    };
    
    // Data Structure
    this.formatDateEn = this.formatDateEn.bind(this);
    this.getPeriod = this.getPeriod.bind(this);
    this.getZoom = this.getZoom.bind(this);

    // Actions
    this.updatePeriod = this.updatePeriod.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.updateZoom = this.updateZoom.bind(this);
    this.zoomAuto = this.zoomAuto.bind(this);

    // Template
    this.templatePopover = this.templatePopover.bind(this);

    this.startDate = React.createRef();
    this.endDate = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    this.setState({ language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.CurrentView !== prevProps.CurrentView) {
      this.setState({ currentView });
    }
  }

  // Formatting Date to English format for Gantt usage
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  getPeriod(currentView) {
    let period = 'month';

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'GanttPeriod')) {
      period = currentView.Parameters.find(param => param.Name === 'GanttPeriod').Value;
    }

    return period;
  }

  getStartDate(currentView) {
    let today = new Date();
    let startDate = this.formatDateEn(new Date(today.getFullYear(), today.getMonth() - 1, 0));

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'StartDate')) {
      startDate = currentView.Parameters.find(param => param.Name === 'StartDate').Value;
    }

    return startDate;
  }

  getEndDate(currentView) {
    // let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    let today = new Date();
    let endDate = this.formatDateEn(new Date(today.getFullYear(), today.getMonth() + 1, 0));

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'EndDate')) {
      endDate = currentView.Parameters.find(param => param.Name === 'EndDate').Value;
    }

    return endDate;
  }

  getZoom(currentView) {
    let zoom = 1;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'GanttZoom')) {
      zoom = parseInt(currentView.Parameters.find(param => param.Name === 'GanttZoom').Value);
    }

    return zoom;
  }

  updatePeriod(period) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'GanttPeriod')) {
      currentView.Parameters.find(param => param.Name === 'GanttPeriod').Value = period;
    }
    else {
      currentView.Parameters.push({ Name: 'GanttPeriod', Value: period });
    }

    // Call the event from the Parent component through the props
    this.props.onTimelineChange(currentView);

    this.setState({ currentView });
  }

  updateStartDate(fieldName, date, itemId) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'StartDate')) {
      currentView.Parameters.find(param => param.Name === 'StartDate').Value = date;
    }
    else {
      currentView.Parameters.push({ Name: 'StartDate', Value: date });
    }

    // Call the event from the Parent component through the props
    this.props.onTimelineChange(currentView);

    this.setState({ currentView });
  }

  updateEndDate(fieldName, date, itemId) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'EndDate')) {
      currentView.Parameters.find(param => param.Name === 'EndDate').Value = date;
    }
    else {
      currentView.Parameters.push({ Name: 'EndDate', Value: date });
    }

    // Call the event from the Parent component through the props
    this.props.onTimelineChange(currentView);

    this.setState({ currentView });
  }

  updateZoom(zoom) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'GanttZoom')) {
      currentView.Parameters.find(param => param.Name === 'GanttZoom').Value = zoom;
    }
    else {
      currentView.Parameters.push({ Name: 'GanttZoom', Value: zoom });
    }

    // Call the event from the Parent component through the props
    this.props.onZoomChange(currentView);

    this.setState({ currentView });
  }

  zoomAuto() {
    const currentView = this.state.currentView;

    // Call the event from the Parent component through the props
    this.props.onZoomAuto(currentView);
  }

  templatePopover() {
    const { language, currentView } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="planningTimelinePopover">
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'day' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('day')}>{Traduction.translate(language, 'day')}</div>
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'week' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('week')}>{Traduction.translate(language, 'week')}</div>
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'month' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('month')}>{Traduction.translate(language, 'month')}</div>
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'quarter' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('quarter')}>{Traduction.translate(language, 'quarter')}</div>
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'year' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('year')}>{Traduction.translate(language, 'year')}</div>
            <div className={"planningPeriodList " + (this.getPeriod(currentView) === 'custom' ? "bold" : "opacity50")} onClick={(e) => this.updatePeriod('custom')}>{Traduction.translate(language, 'custom')}</div> 
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, itemId, itemType, blockType, currentView } = this.state;

    return (
      <div className="inline-flex align-items-center">
        {/* Zoom auto */}
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'gantt_zoom_auto')}</strong></Tooltip>}>
            <div className="planningZoomAutoButton">
              <span className="fs12 bold" onClick={(e) => this.zoomAuto()}>{Traduction.translate(language, 'gantt_zoom_auto')}</span>
            </div>
          </OverlayTrigger>
        ))}
        <div className="planningTimelineButton">
          {/* Period */}
          {['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'gantt_period')}</strong></Tooltip>}>
              <div className="planningPeriodButton">
                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover()}>
                  <div className="inline-flex align-items-center cursor">
                    <span className="fs12 bold">{Traduction.translate(language, this.getPeriod(currentView))}</span>
                    <span className="iconChevronDownGrey extrasmallIcons ml10"></span>
                  </div>
                </OverlayTrigger>
              </div>
            </OverlayTrigger>
          ))}
          {/* Custom Period */}
          {this.getPeriod(currentView) === 'custom' && <div className="planningPeriodDatepicker">
            <DatePickerCalendar ref={this.startDate} Date={this.getStartDate(currentView)} FieldName={'StartDate'} Display={'Datepicker'} Editable={true} onDateChange={this.updateStartDate}></DatePickerCalendar>
            <div className="planningDatepickerSeparator"></div>
            <DatePickerCalendar ref={this.endDate} Date={this.getEndDate(currentView)} FieldName={'EndDate'} Display={'Datepicker'} Editable={true} onDateChange={this.updateEndDate}></DatePickerCalendar>
          </div>}
          {/* Zoom out */}
          {this.getPeriod(currentView) !== 'custom' && ['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'gantt_zoom_out')}</strong></Tooltip>}>
              <div className="planningZoomButton">
                {this.getZoom(currentView) === 1 && <span className="iconMinusGrey opacity50 verysmallIcons"></span>}
                {this.getZoom(currentView) > 1 && <span className="iconMinusGrey verysmallIcons cursor" onClick={(e) => this.updateZoom(this.getZoom(currentView) - 1)}></span>}
              </div>
            </OverlayTrigger>
          ))}
          {/* Zoom in */}
          {this.getPeriod(currentView) !== 'custom' && ['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'gantt_zoom_in')}</strong></Tooltip>}>
              <div className="planningZoomButton">
                {this.getZoom(currentView) < 5 && <span className="iconPlusGrey verysmallIcons cursor" onClick={(e) => this.updateZoom(this.getZoom(currentView) + 1)}></span>}
                {this.getZoom(currentView) === 5 && <span className="iconPlusGrey opacity50 verysmallIcons"></span>}
              </div>
            </OverlayTrigger>
          ))}
        </div>
      </div>
    )
  }
}

export default PlanningTimeline;