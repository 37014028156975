import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { L10n } from '@syncfusion/ej2-base';
import { Inject, Category, Tooltip as ChartTooltip, AccumulationTooltip, DataLabel, AccumulationDataLabel, DateTime, Highlight, StripLine, Legend, AccumulationLegend, Export } from '@syncfusion/ej2-react-charts';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective } from '@syncfusion/ej2-react-charts';
import { AreaSeries, BarSeries, BubbleSeries, ColumnSeries, LineSeries, ParetoSeries, PieSeries, PolarSeries, PyramidSeries, RadarSeries, SplineAreaSeries, SplineSeries, StackingAreaSeries, StackingBarSeries, StackingColumnSeries, StackingLineSeries, WaterfallSeries } from '@syncfusion/ej2-react-charts';
import { HeatMapComponent, Legend as HeatmapLegend, Tooltip as HeatmapTooltip, ILoadedEventArgs, HeatMapTheme, Adaptor } from '@syncfusion/ej2-react-heatmap';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniTree from './MiniTree';
import MiniTable from './MiniTable';
import PopoverEditChartType from './PopoverEditChartType';
import PopoverEditChartRow from './PopoverEditChartRow';
import PopoverEditChartColumn from './PopoverEditChartColumn';
import PopoverEditChartIndicator from './PopoverEditChartIndicator';
import PopoverEditChartIndicatorFunction from './PopoverEditChartIndicatorFunction';
import PopoverEditChartSetting from './PopoverEditChartSetting';

// Chart Model Images
import ChartModelBudgetEvolution from '../Images/ChartModelBudgetEvolution.png';
import ChartModelBusinessCase from '../Images/ChartModelBusinessCase.png';
import ChartModelCustom from '../Images/ChartModelCustom.png';
import ChartModelDeliveriesEvolution from '../Images/ChartModelDeliveriesEvolution.png';
import ChartModelProgressEvolution from '../Images/ChartModelProgressEvolution.png';
import ChartModelProgressTrend from '../Images/ChartModelProgressTrend.png';

// API
const API_settings = '/WebAppService/GetChartSetting';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      chart: {},
      availableChartModels: [],
      availableChartTypes: [],
      availableColumns: [],
      availableIndicators: [],
      availableRows: [],
      chartModelSelected: {},
      settings: false,
      chartModelPopup: false,
      isLoading: false
    };

    // Data Structure
    this.getChartSetting = this.getChartSetting.bind(this);
    // this.dataStructure = this.dataStructure.bind(this);
    this.getChartModelImage = this.getChartModelImage.bind(this);

    // Actions
    this.displayComponent = this.displayComponent.bind(this);
    this.displayChart = this.displayChart.bind(this);
    this.displayTable = this.displayTable.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.displaySettings = this.displaySettings.bind(this);
    this.closeSettings = this.closeSettings.bind(this);
    this.updateChartMode = this.updateChartMode.bind(this);
    this.updateChartModel = this.updateChartModel.bind(this);
    this.updateChartType = this.updateChartType.bind(this);
    this.addChartRow = this.addChartRow.bind(this);
    this.deleteChartRow = this.deleteChartRow.bind(this);
    this.updateChartRow = this.updateChartRow.bind(this);
    this.updateChartColumn = this.updateChartColumn.bind(this);
    this.addChartIndicator = this.addChartIndicator.bind(this);
    this.deleteChartIndicator = this.deleteChartIndicator.bind(this);
    this.updateChartIndicator = this.updateChartIndicator.bind(this);
    this.updateChartIndicatorFunction = this.updateChartIndicatorFunction.bind(this);
    this.changeChartSettingValue = this.changeChartSettingValue.bind(this);
    this.updateChartSetting = this.updateChartSetting.bind(this);
    this.updateChartSettingBoolean = this.updateChartSettingBoolean.bind(this);
    this.updateChartSettingText = this.updateChartSettingText.bind(this);
    this.updateCurrentView = this.updateCurrentView.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);

    // Template
    this.templateChartAccumulation = this.templateChartAccumulation.bind(this);
    this.templateChartBar = this.templateChartBar.bind(this);
    this.templateChartBubble = this.templateChartBubble.bind(this);
    this.templateChartHeatmap = this.templateChartHeatmap.bind(this);
    this.templateChartPolar = this.templateChartPolar.bind(this);
    this.templateChartWidget = this.templateChartWidget.bind(this);
    this.templateChartSettings = this.templateChartSettings.bind(this);
    this.templateChartModelPopup = this.templateChartModelPopup.bind(this);
    this.templatePopoverChartModel = this.templatePopoverChartModel.bind(this);
    this.templatePopoverChartType = this.templatePopoverChartType.bind(this);
    this.templatePopoverChartRow = this.templatePopoverChartRow.bind(this);
    this.templatePopoverChartColumn = this.templatePopoverChartColumn.bind(this);
    this.templatePopoverChartAddIndicator = this.templatePopoverChartAddIndicator.bind(this);
    this.templatePopoverChartIndicator = this.templatePopoverChartIndicator.bind(this);
    this.templatePopoverChartIndicatorFunction = this.templatePopoverChartIndicatorFunction.bind(this);
    this.templatePopoverChartSetting = this.templatePopoverChartSetting.bind(this);
    this.closeChartModelPopup = this.closeChartModelPopup.bind(this);

    // Reference
    this.table = React.createRef();
    this.tree = React.createRef();
  }
  
  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const chart = this.props.Chart;
    const isLoading = this.props.IsLoading;

    this.setState({ login, authId, language, itemId, itemType, itemTitle, blockType, currentView, chart, isLoading });
  }
  
  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const chart = this.props.Chart;
    const isLoading = this.props.IsLoading;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.Chart) !== JSON.stringify(prevProps.Chart)) {
      this.setState({ chart });
    }
    if(this.props.IsLoading !== prevProps.IsLoading) {
      this.setState({ isLoading });
    }
  }

  // Get Chart available Settings from the API
  async getChartSetting(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_settings, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetChartSettingResult;
      const chart = this.state.chart;

      if(result) {
        // Update Chart Settings
        chart.ChartSetting = result;

        const availableChartModels = result.AvailableChartModels;
        const availableChartTypes = result.AvailableChartTypes;
        const availableColumns = result.AvailableColumns;
        const availableIndicators = result.AvailableIndicators;
        const availableRows = result.AvailableRows;

        const chartModelSelected = result.ChartModel;

        this.setState({ chart, availableChartModels, availableChartTypes, availableColumns, availableIndicators, availableRows, chartModelSelected, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  // dataStructure(rows) {
  //   let dataSource = [];

  //   if(rows.length > 0) {
  //     dataSource = rows.map(row => {
  //       return row.Cells.reduce((acc, item) => {
  //         // Format Dates
  //         if(item.FieldType === 'Date' && item.Value) {
  //           acc[item.ColumnName] = new Date(item.Value);
  //         }
  //         // Format Numbers
  //         else if(item.FieldType === 'Double' && item.Value) {
  //           acc[item.ColumnName] = parseFloat(item.Value);
  //         }
  //         // Format Meteo/Trend
  //         else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
  //           acc[item.ColumnName] = JSON.parse(item.Value);
  //         }
  //         // Format Objects
  //         else if(item.FieldType === 'Object' && item.Value) {
  //           acc[item.ColumnName] = JSON.parse(item.Value);
  //         }
  //         // Format Object Tables (Decisions/HashTags)
  //         else if(item.FieldType === 'ObjectTable' && item.Value) {
  //           acc[item.ColumnName] = JSON.parse(item.Value);
  //         }
  //         // Add the Column Name/Values to the reduced Table
  //         // Exclude Parent_ID for Table
  //         else if(item.ColumnName !== 'Parent_ID') {
  //           acc[item.ColumnName] = item.Value;
  //         }

  //         return acc;
  //       }, {});
  //     });
  //   }

  //   return dataSource;
  // }

  getChartModelImage(chartModel) {
    switch(chartModel) {
      case "Custom":
          return <img className="iconsChartModel" src={ChartModelCustom} alt="Chart Model Image"/>;
      case "BurnedDownChart":
          return <img className="iconsChartModel" src={ChartModelCustom} alt="Chart Model Image"/>;
      case "BudgetEvolution":
          return <img className="iconsChartModel" src={ChartModelBudgetEvolution} alt="Chart Model Image"/>;
      case "BusinessCase":
          return <img className="iconsChartModel" src={ChartModelBusinessCase} alt="Chart Model Image"/>;
      case "DeliveriesEvolution":
      case "IssuesEvolution":
      case "RisksEvolution":
          return <img className="iconsChartModel" src={ChartModelDeliveriesEvolution} alt="Chart Model Image"/>;
      case "FreshnessCartography":
          return <img className="iconsChartModel" src={ChartModelCustom} alt="Chart Model Image"/>;
      case "ProgressEvolution":
          return <img className="iconsChartModel" src={ChartModelProgressEvolution} alt="Chart Model Image"/>;
      case "ProgressTrend":
      case "PerformanceTrend":
          return <img className="iconsChartModel" src={ChartModelProgressTrend} alt="Chart Model Image"/>;
      case "QualityCartography":
          return <img className="iconsChartModel" src={ChartModelCustom} alt="Chart Model Image"/>;
      case "SCurve":
          return <img className="iconsChartModel" src={ChartModelCustom} alt="Chart Model Image"/>;
    }
  }

  displayComponent(chart) {
    if(chart.Mode === 'Chart') {
      return <div className="chartComponent">{this.displayChart(chart)}</div>;
    }
    else if(chart.Mode === 'Table') {
      return <div className="chartTable">{this.displayTable(chart)}</div>;
    }
    else if(chart.Mode === 'Both') {
      return <div className=""></div>;
    }
  }

  displayChart(chart) {
    if(chart.Series && chart.Series.length > 0) {
      if((chart.ChartModel || chart.ChartSetting.ChartModel) && (chart.ChartModel.Name === chart.ChartSetting.ChartModel.Name)) {
        // Group Type Accumulation
        if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Accumulation') {
          return this.templateChartAccumulation(chart);
        }
        // Group Type Bar
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Bar') {
          // Group Type Heatmap
          if(chart.ChartSetting && chart.ChartSetting.ChartType.Name === 'Heatmap') {
            return this.templateChartHeatmap(chart);
          }
          else {
            return this.templateChartBar(chart);
          }
        }
        // Group Type Bubble
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Bubble') {
          return this.templateChartBubble(chart);
        }
        // Group Type Polar
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Polar') {
          return this.templateChartPolar(chart);
        }
        // Group Type Widget
        else if(chart.ChartSetting && chart.ChartSetting.ChartType.GroupType === 'Widget') {
          return this.templateChartWidget(chart);
        }
      }
    }
  }

  displayTable(chart) {
    const { itemId, itemType, itemTitle, blockType } = this.state;

    if(chart.ChartTableView && chart.ChartTableView.ViewType === 0 && chart.ChartTableView.DefaultLevel === 0) {
      return <MiniTree ref={this.tree} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Display={'Chart'} Editable={false} Pagging={true} CurrentView={chart.ChartTableView} Columns={chart.ChartTable.ColumnHeaders} Rows={chart.ChartTable.Rows}></MiniTree>;
    }
    else {
      return <MiniTable ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Display={'Chart'} Editable={false} Pagging={true} CurrentView={chart.ChartTableView} Columns={chart.ChartTable.ColumnHeaders} Rows={chart.ChartTable.Rows}></MiniTable>;
    }
  }

  applySettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Get Chart Settings
    this.getChartSetting(login, authId, itemId, itemType, blockType, currentView);
  }

  displaySettings() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Get Chart Settings
    this.getChartSetting(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ settings: true });
  }

  closeSettings() {
    this.setState({ settings: false });
  }

  updateChartMode(chartMode) {
    const { login, authId, itemId, itemType, blockType, chart } = this.state;

    // Update Chart Mode
    chart.ChartSetting.Mode = chartMode;

    // Update Current View From 
    this.updateCurrentView(chart);
    // const currentView = this.updateCurrentView(chart);

    // Get Chart Settings
    // this.getChartSetting(login, authId, itemId, itemType, blockType, currentView);
  }

  updateChartModel(chartModel) {
    const { login, authId, itemId, itemType, blockType, chart } = this.state;

    // Update Chart Model
    chart.ChartSetting.ChartModel = chartModel;

    // Update Current View From Chart
    this.updateCurrentView(chart);
    // const currentView = this.updateCurrentView(chart);

    // Get Chart Settings
    // this.getChartSetting(login, authId, itemId, itemType, blockType, currentView);
  }

  updateChartType(chartType) {
    const chart = this.state.chart;

    // Update Chart Type
    chart.ChartSetting.ChartType = chartType;

    // chart.Series.forEach(serie => {
    //   serie.ChartType = chartType;
    // });

    // Update Current View From Chart
    this.updateCurrentView(chart);

    // this.setState({ chart });
  }

  addChartRow(chartRow) {
    const chart = this.state.chart;

    // Add Chart Indicator
    chart.ChartSetting.Rows.push(chartRow);

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  deleteChartRow(index) {
    const chart = this.state.chart;
    
    // Delete Chart Indicator
    chart.ChartSetting.Rows.splice(index, 1);
    
    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartRow(chartRow, index) {
    const chart = this.state.chart;

    // Update Chart Indicator
    chart.ChartSetting.Rows[index] = chartRow;

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartColumn(chartColumn) {
    const chart = this.state.chart;

    // Update Chart Column
    chart.ChartSetting.Column = chartColumn;

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  addChartIndicator(chartIndicator) {
    const chart = this.state.chart;

    // Add Chart Indicator
    chart.ChartSetting.Indicators.push(chartIndicator);

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  deleteChartIndicator(index) {
    const chart = this.state.chart;

    // Delete Chart Indicator
    chart.ChartSetting.Indicators.splice(index, 1);

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartIndicator(chartIndicator, index) {
    const chart = this.state.chart;

    // Update Chart Indicator
    chart.ChartSetting.Indicators[index] = chartIndicator;

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartIndicatorFunction(chartIndicatorFunction, index) {
    const chart = this.state.chart;

    // Update Chart Indicator
    chart.ChartSetting.Indicators[index].AggregationFunction = chartIndicatorFunction;

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  changeChartSettingValue(chartSetting, event) {
    const chart = this.state.chart;

    // Update Chart Parameters Setting
    chart.ChartSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === chartSetting.Name)) {
        parameter.Value.find(setting => setting.Name === chartSetting.Name).Value.Value = event.target.value;
      }
    });

    this.setState({ chart });
  }

  updateChartSetting(chartSetting, value) {
    const chart = this.state.chart;

    // Update Chart Parameters Setting
    chart.ChartSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === chartSetting.Name)) {
        parameter.Value.find(setting => setting.Name === chartSetting.Name).Value = value;
      }
    });

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartSettingBoolean(chartSetting, value) {
    const chart = this.state.chart;

    // Update Chart Parameters Setting
    chart.ChartSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === chartSetting.Name)) {
        parameter.Value.find(setting => setting.Name === chartSetting.Name).Value.Value = value;
      }
    });

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateChartSettingText(chartSetting, event) {
    const chart = this.state.chart;

    // Update Chart Parameters Setting
    chart.ChartSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === chartSetting.Name)) {
        parameter.Value.find(setting => setting.Name === chartSetting.Name).Value.Value = event.target.value;
      }
    });

    // Update Current View From Chart
    this.updateCurrentView(chart);

    this.setState({ chart });
  }

  updateCurrentView(chart) {
    const currentView = this.state.currentView;
    let sort;

    // Check if CurrentView contains Sort Parameter
    if(currentView.Parameters.find(parameter => parameter.Name === 'Sort')) {
      sort = currentView.Parameters.find(parameter => parameter.Name === 'Sort');
    }

    // Clear Current View Parameters
    currentView.Parameters = [];

    // Update Sort Parameter
    if(sort) {
      currentView.Parameters.push(sort);
    }

    // Update Chart Mode
    if(chart.ChartSetting.Mode) {
      currentView.Parameters.push({ Name: 'ChartMode', Value: chart.ChartSetting.Mode, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Chart Model
    if(chart.ChartSetting.ChartModel) {
      currentView.Parameters.push({ Name: 'ChartModel', Value: chart.ChartSetting.ChartModel.Name, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Chart Type
    if(chart.ChartSetting.ChartType) {
      currentView.Parameters.push({ Name: 'ChartType', Value: chart.ChartSetting.ChartType.Name, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Chart Rows
    if(chart.ChartSetting.Rows) {
      let values = '';
          
      chart.ChartSetting.Rows.forEach(row => {
        values = values.concat(row.Name, ',');
      });
      
      values = values.slice(0, -1);
      
      currentView.Parameters.push({ Name: 'Rows', Value: values, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Chart Column
    if(chart.ChartSetting.Column) {
      currentView.Parameters.push({ Name: 'Columns', Value: chart.ChartSetting.Column.Name, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Clear Current View Values
    currentView.Values = [];

    // Update Current View Values list
    chart.ChartSetting.Indicators.forEach(indicator => {
      currentView.Values.push({ AggregationType: indicator.AggregationFunction.Name, Field: indicator.Name, ViewId: currentView.ViewId });
    });

    // Update Chart Parameters
    chart.ChartSetting.DictParameters.forEach(parameter => {
      parameter.Value.forEach(setting => {
        currentView.Parameters.push({ Name: setting.Name, Value: setting.Value.Value.toString(), ViewParameterId: -1, ViewId: currentView.ViewId });
      });
    });

    // Call the event from the Parent component through the props with view value
    this.props.onSettingsChange(currentView);

    return currentView;
  }

  exportCSV() {
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportCSV();
    }
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Tree component
    if(this.tree.current) {
      this.tree.current.exportXLSX();
    }
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
  }

  exportJPEG() {
    const chart = this.state.chart;
    const fileName = "Export " + chart.Title;
    const type = "JPEG";

    if(this.chart) {
      this.chart.export(type, fileName);
    }
  }

  exportPNG() {
    const chart = this.state.chart;
    const fileName = "Export " + chart.Title;
    const type = "PNG";

    if(this.chart) {
      this.chart.export(type, fileName);
    }
  }

  exportPDF() {
    const chart = this.state.chart;
    const fileName = "Export " + chart.Title;
    const type = "PDF";

    if(this.chart) {
      this.chart.export(type, fileName);
    }
  }

  templateChartAccumulation(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <AccumulationChartComponent id={chart.Id} style={style} chartArea={chartArea} legendSettings={legendSettings} tooltip={tooltip} ref={chart=>this.chart=chart}>
        <AccumulationSeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <AccumulationSeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={(serie.ChartType.Name === 'Pyramid' ? 'Pyramid' : 'Pie')} fill={serie.Color} dataLabel={{ name: 'Label', font: { fontWeight: serie.Marker.Label.Font.fontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }} innerRadius={(serie.ChartType.Name === 'Doughnut' ? '40%' : '0%')} xName='X' yName='Y' pointColorMapping='Color' tooltipMappingName='X'></AccumulationSeriesDirective>
          })}
        </AccumulationSeriesCollectionDirective>
        <Inject services={[ AccumulationDataLabel, AccumulationLegend, AccumulationTooltip, PieSeries, PyramidSeries, Export ]}/>
      </AccumulationChartComponent>
    );
  }

  templateChartBar(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType === 'Double') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, interval: chart.XAxis.Interval, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else if(chart.XAxis.ValueType === 'DateTime') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, intervalType: chart.XAxis.IntervalType, desiredInterval: chart.XAxis.Interval, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, labelFormat: chart.XAxis.LabelFormat, intervalType: chart.XAxis.IntervalType, desiredInterval: chart.XAxis.Interval, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType,labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart=>this.chart=chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} fill={serie.Color} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.FontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }, height: serie.Marker.Height, width: serie.Marker.Width, shape: serie.Marker.Shape, isFilled: serie.Marker.IsFilled, visible: serie.Marker.Visible }} xName='X' yName='Y' tooltipMappingName='XLabel' columnSpacing={0.1} width={serie.Width}></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ Legend, ChartTooltip, Category, DataLabel, DateTime, Highlight, StripLine, AreaSeries, BarSeries, ColumnSeries, LineSeries, ParetoSeries, SplineAreaSeries, SplineSeries, WaterfallSeries, StackingAreaSeries, StackingBarSeries, StackingColumnSeries, StackingLineSeries, Export ]}/>
      </ChartComponent>
    );
  }

  templateChartBubble(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType === 'Double') {
        if(chart.XAxis.CustomRange) {
          primaryXAxis = { title: chart.XAxis.Title, labelFormat: chart.XAxis.LabelFormat, interval: chart.XAxis.Interval, minimum: chart.XAxis.Minimum, maximum: chart.XAxis.Maximum, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
        else {
          primaryXAxis = { title: chart.XAxis.Title, labelFormat: chart.XAxis.LabelFormat, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
        }
      }
      else {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, enableMarker: false };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart=>this.chart=chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.FontWeight }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible } }} xName='X' yName='Y' size='Size' pointColorMapping='Color' border={{ width: 2 }} minRadius={3} maxRadius={9} tooltipMappingName='Label'></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ ChartTooltip, DataLabel, DateTime, BubbleSeries, Export ]}/>
      </ChartComponent>
    );
  }

  templateChartHeatmap(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    
    const style = { height: '100%', width: '100%' };
    const dataSourceSettings = { xDataMapping: 'X', yDataMapping: 'Y', valueMapping: 'Size', adaptorType: 'Cell', isJsonData: true };
    const cellSettings = { format: '{value}', showLabel: true, border: { radius: 4, width: 1, color: 'white' } };
    const paletteSettings = { palette: [{ color: '#DCD57E' }, { color: '#A6DC7E' }, { color: '#7EDCA2' }, { color: '#6EB5D0' }] };
    const xAxis = { labels: chart.XAxis.Labels, title: chart.XAxis.Title };
    const yAxis = { labels: chart.YAxis.Labels, title: chart.YAxis.Title };

    return (
      <HeatMapComponent id={chart.Id} style={style} dataSource={chart.Series[0].Points} dataSourceSettings={dataSourceSettings} cellSettings={cellSettings} paletteSettings={paletteSettings} xAxis={xAxis} yAxis={yAxis}>
        <Inject services={[ HeatmapTooltip, HeatmapLegend, Adaptor ]} />
      </HeatMapComponent>
    );
  }

  templateChartPolar(chart) {
    const { language, itemId, itemType, blockType, currentView, isLoading } = this.state;
    let primaryXAxis, primaryYAxis;

    // Chart xAxis
    if(chart.XAxis) {
      if(chart.XAxis.ValueType != 'Double' && chart.XAxis.ValueType != 'DateTime') {
        primaryXAxis = { title: chart.XAxis.Title, valueType: chart.XAxis.ValueType, interval: 1, stripLines: chart.XAxis.ListStripLines, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
      }
      else {
        return;
      }
    }

    // Chart yAxis
    if(chart.YAxis) {
      if(chart.YAxis.CustomRange) {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat, interval: chart.YAxis.Interval, minimum: chart.YAxis.Minimum, maximum: chart.YAxis.Maximum };
      }
      else {
        primaryYAxis = { title: chart.YAxis.Title, labelFormat: chart.YAxis.LabelFormat };
      }
    }

    const style = { height: '100%', width: '100%' };
    const chartArea = { border: { width: 0 } };
    const legendSettings = { position: chart.Legend.PositionName, visible: chart.Legend.Visible, enableHighlight: true };
    const tooltip = { header: "<b>${point.tooltip}</b>", enable: true, shared: true };

    return (
      <ChartComponent id={chart.Id} style={style} chartArea={chartArea} primaryXAxis={primaryXAxis} primaryYAxis={primaryYAxis} legendSettings={legendSettings} tooltip={tooltip} ref={chart=>this.chart=chart}>
        <SeriesCollectionDirective>
          {chart.Series && chart.Series.map((serie, index) => {
            return <SeriesDirective key={index} dataSource={serie.Points} name={serie.Name} type={serie.ChartType.Name} drawType={serie.DrawType.Name} fill={serie.Color} marker={{ dataLabel: { name: 'Label', font: { fontWeight: serie.Marker.Label.Font.fontWeight, color: serie.Marker.Label.Font.Color }, position: serie.Marker.Label.Position, visible: serie.Marker.Label.Visible }, height: serie.Marker.Height, width: serie.Marker.Width, shape: serie.Marker.Shape, isFilled: serie.Marker.IsFilled, visible: serie.Marker.Visible }} xName='X' yName='Y' tooltipMappingName='XLabel' width={serie.Width}></SeriesDirective>
          })}
        </SeriesCollectionDirective>
        <Inject services={[ Legend, Category, DataLabel, DateTime, ChartTooltip, AreaSeries, LineSeries, PolarSeries, RadarSeries, Export ]}/>
      </ChartComponent>
    );
  }

  templateChartWidget(chart) {
    return (
      <div className="">
        {chart.Series && chart.Series.map((serie, index) => {
          return <div key={index} className="chartWidgetSerie">
            {/* Serie Name */}
            {serie.Marker.Label.Visible && <div className="fs13 bold">{serie.Name}</div>}
            {/* Points */}
            {serie.Points && serie.Points.map((point, i) => {
              return <div key={i} className="chartWidgetPoint">
                {/* Value */}
                <div className="fs40 bold" style={{ color: point.Color }}>{point.Label}</div>
                {/* Label */}
                {serie.Marker.Label.Visible && <div className="fs12">{point.X}</div>}
              </div>
            })}
          </div>
        })}
      </div>
    )
  }

  templateChartSettings() {
    const { language, itemId, itemType, chart, availableChartModels, availableChartTypes, availableColumns, availableIndicators, availableRows } = this.state;
    let chartMode, chartModel, chartType, chartTypeSelection, chartRows, chartRowLabel, allowedRows, chartColumn, chartColumnLabel, chartColumnSelection, chartIndicators, chartIndicatorsLabel, allowedIndicators, chartParameters;

    // Chart Mode
    if(chart.ChartSetting && chart.ChartSetting.Mode) {
      chartMode = chart.ChartSetting.Mode;
    }

    // Chart Model
    if(chart.ChartSetting && chart.ChartSetting.ChartModel) {
      chartModel = chart.ChartSetting.ChartModel;
    }

    // Chart Type
    if(chart.ChartSetting) {
      chartTypeSelection = chart.ChartSetting.WithChartTypeChoice;

      if(chart.ChartSetting.ChartType) {
        chartType = chart.ChartSetting.ChartType;
      }
    }

    // Chart Row
    if(chart.ChartSetting) {
      chartRowLabel = chart.ChartSetting.RowLabel;
      allowedRows = chart.ChartSetting.AllowedRows;
      
      if(chart.ChartSetting.Rows) {
        chartRows = chart.ChartSetting.Rows;
      }
    }

    // Chart Column
    if(chart.ChartSetting) {
      chartColumnLabel = chart.ChartSetting.ColumnLabel;
      chartColumnSelection = chart.ChartSetting.WithColumnChoice;

      if(chart.ChartSetting.Column) {
        chartColumn = chart.ChartSetting.Column;
      }
    }

    // Chart Indicators
    if(chart.ChartSetting) {
      chartIndicatorsLabel = chart.ChartSetting.IndicatorsLabel;
      allowedIndicators = chart.ChartSetting.AllowedIndicators;

      if(chart.ChartSetting.Indicators) {
        chartIndicators = chart.ChartSetting.Indicators;
      }
    }

    // Chart Parameters
    if(chart.ChartSetting && chart.ChartSetting.DictParameters) {
      chartParameters = chart.ChartSetting.DictParameters;
    }

    return (
      <div className="chartSettingsParameters">
        {/* Chart Model */}
        {chartModel && <div className="chartSettingsTitle">
          <div className="chartModelTitle">{Traduction.translate(language, 'chart')}</div>
          <div className="chartModelValue" onClick={() => this.setState({ chartModelPopup: true })}>
            <div className="">{chartModel.Label}</div>
            <span className="iconChevronDownYellow verysmallIcons ml10 cursor"></span>
          </div>
        </div>}
        {/* <Form.Label className="chartSettingsTitle">{chartModel.Label}</Form.Label> */}
        {/* {chartModel && <div className="chartSettingsTitle">
          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartModel(chartModel, availableChartModels)}>
            <div className="">{chartModel.Label}</div>
          </OverlayTrigger>
        </div>} */}
        <div className="chartSettingsContent scrollbar-y overflow-y">
          {/* Chart Mode */}
          <div className="chartSettingsMode">
            {/* Label */}
            <div className="chartSettingsLabel">{Traduction.translate(language, 'chart_mode')}</div>
            {/* Value */}
            <div className={(chartMode === 'Table' ? "chartModeBordered" : "chartMode")} onClick={() => this.updateChartMode('Table')}>
              <div className="iconViewTypeTable iconsChartMode"></div>
            </div>
            <div className={(chartMode === 'Chart' ? "chartModeBordered" : "chartMode")} onClick={() => this.updateChartMode('Chart')}>
              <div className="iconViewTypeChart iconsChartMode"></div>
            </div>
          </div>
          {/* Chart Type */}
          {chartTypeSelection && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{Traduction.translate(language, 'chart_type')}</div>
            {/* Icon */}
            {/* {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'chart_type')}</strong></Tooltip>}>
                <div className="chartSettingsIcon"><span className="iconChartType iconsChartParameters"/></div>
              </OverlayTrigger>
            ))} */}
            {/* Value */}
            <div className="chartSettingsAxes">
              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartType(chartType, availableChartTypes)}>
                <div className="chartSettingsAxisLabel">
                  <div className="width90p">{chartType.Label}</div>
                  <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                </div>
              </OverlayTrigger>
            </div>
          </div>}
          {/* Chart Row */}
          {allowedRows > 0 && chartRows && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{chartRowLabel}</div>
            {/* Icon */}
            {/* {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'axis')}</strong></Tooltip>}>
                <div className="chartSettingsIcon"><span className="iconChartRow iconsChartParameters"/></div>
              </OverlayTrigger>
            ))} */}
            <div className="chartSettingsAxes">
              {chartRows.map((row, index) => {
                if(index < allowedRows) {
                  return <div key={index} className="chartSettingsIndicator">
                    {/* Indicator */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartRow(row, availableRows, index)}>
                      <div className="chartSettingsAxisLabel">
                        <div className="width90p">{row.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Delete Indicator */}
                    {chartRows.length > 1 && <div className="chartSettingsIndicatorDelete">
                      <span className="iconClearRed verysmallIcons cursor" onClick={() => this.deleteChartRow(index)}></span>
                    </div>}
                  </div>;
                }
              })}
              {/* New Row */}
              {chartRows.length < allowedRows && <div className="chartSettingsIndicator">
                <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartAddRow(availableRows)}>
                  <div className="chartSettingsNewIndicator">{Traduction.translate(language, 'add_indicator')}</div>
                </OverlayTrigger>
              </div>}
            </div>
          </div>}
          {/* Chart Column */}
          {chartColumnSelection && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{chartColumnLabel}</div>
            {/* Icon */}
            {/* {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'category')}</strong></Tooltip>}>
                <div className="chartSettingsIcon"><span className="iconChartColumn iconsChartParameters"/></div>
              </OverlayTrigger>
            ))} */}
            {/* Value */}
            <div className="chartSettingsAxes">
              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartColumn(chartColumn, availableColumns)}>
                <div className="chartSettingsAxisLabel">
                  <div className="width90p">{chartColumn.Label}</div>
                  <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                </div>
              </OverlayTrigger>
            </div>
          </div>}
          {/* Chart Indicators */}
          {allowedIndicators > 0 && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{chartIndicatorsLabel}</div>
            {/* {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'indicators')}</strong></Tooltip>}>
                <div className="chartSettingsIcon"><span className="iconChartIndicators iconsChartParameters"/></div>
              </OverlayTrigger>
            ))} */}
            {/* Values */}
            <div className="chartSettingsIndicators">
              {chartIndicators.map((indicator, index) => {
                if(index < allowedIndicators) {
                  return <div key={index} className="chartSettingsIndicator">
                    {/* Indicator */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartIndicator(indicator, availableIndicators, index)}>
                      <div className="chartSettingsIndicatorLabel">
                        <div className="width90p">{indicator.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Function */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartIndicatorFunction(indicator.AggregationFunction, indicator.AvailableAggregationFunction, index)}>
                      <div className="chartSettingsIndicatorFunction">
                        <div className="width90p">{indicator.AggregationFunction.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Delete Indicator */}
                    <div className="chartSettingsIndicatorDelete">
                      <span className="iconClearRed verysmallIcons cursor" onClick={() => this.deleteChartIndicator(index)}></span>
                    </div>
                  </div>
                }
              })}
              {/* New Indicator */}
              {chartIndicators.length < allowedIndicators && <div className="chartSettingsAddIndicator">
                <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartAddIndicator(availableIndicators)}>
                  <div className="chartSettingsNewIndicator">{Traduction.translate(language, 'add_indicator')}</div>
                </OverlayTrigger>
              </div>}
            </div>
          </div>}
          {/* ----- Border ----- */}
          <div className="chartSettingsBorder"></div>
          {/* Chart Parameters */}
          {chartParameters && chartParameters.map((parameter, index) => {
            return <div key={index} className="chartParameters">
              {/* Label */}
              <div className="chartParametersLabel">{parameter.Key}</div>
              {/* Settings list */}
              {parameter.Value.map((setting, index) => {
                if(setting.Type === 'Boolean') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    {setting.Value.Value && <div className="iconCheck iconsChartParameters cursor" onClick={() => this.updateChartSettingBoolean(setting, false)}></div>}
                    {!setting.Value.Value && <div className="iconCircleGrey iconsChartParameters cursor" onClick={() => this.updateChartSettingBoolean(setting, true)}></div>}
                  </div>;
                }
                else if(setting.Type === 'Double') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    <Form.Group className="chartParametersSettingInput">
                      <Form.Control id="" className="chartParametersSettingText" as="textarea" rows="1" placeholder="" value={setting.Value.Value} onChange={(e) => this.changeChartSettingValue(setting, e)} onBlur={(e) => this.updateChartSettingText(setting, e)}/>
                    </Form.Group>
                  </div>;
                }
                else if(setting.Type === 'String') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    <Form.Group className="chartParametersSettingInput">
                      <Form.Control id="" className="chartParametersSettingText" as="textarea" rows="1" placeholder="" value={setting.Value.Value} onChange={(e) => this.changeChartSettingValue(setting, e)} onBlur={(e) => this.updateChartSettingText(setting, e)}/>
                    </Form.Group>
                  </div>;
                }
                else if(setting.Type === 'List') {
                  return <div key={index} className="chartParametersSetting">
                    {/* Label */}
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    {/* Value */}
                    <div className="chartSettingsAxes">
                      <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverChartSetting(setting, setting.AvailableValues)}>
                        <div className="chartSettingsAxisLabel">
                          <div className="width90p">{setting.Value.DisplayedValue}</div>
                          <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>;
                }
              })}
            </div>;
          })}
        </div>
      </div>
    );
  }

  templateChartModelPopup() {
    const { language, availableChartModels, chartModelSelected } = this.state;

    return (<div className="flex flex-column popupChartModelModification">
      {/* Chart Model Modification */}
      <div className="chartModelModificationContent scrollbar-miniblock">
        {/* Chart Model Custom */}
        <div className="width100p">
          <div className="col-md-2">
            <div className="mt20">
              <div className="chartModelTitle">{Traduction.translate(language, 'chart')}</div>
              <div className="chartModelType">{Traduction.translate(language, 'chart_custom')}</div>
            </div>
          </div>
          <div className="col-md-10">
            {availableChartModels.map((chartModel, index) => {
              if(chartModel.Name === 'Custom') {
                return <div key={index} className="col-md-6">
                  <div className={(chartModel.Model === chartModelSelected.Model ? "chartModelModificationBordered" : "chartModelModification")} onClick={() => this.setState({ chartModelSelected: chartModel })}>
                    <div className="chartModel">
                      {/* Chart Model Image */}
                      <div className="chartModelImageBorder">{this.getChartModelImage(chartModel.Name)}</div>
                      {/* Chart Model Label & Description */}
                      <div className="chartModelContent">
                        <div className="chartModelLabel">{chartModel.Label}</div>
                        <div className="chartModelDescription">{chartModel.Description}</div>
                      </div>
                    </div>
                  </div>
                </div>;
              }
            })}
          </div>
        </div> 
        {/* Other Chart Models */}
        <div className="width100p">
          <div className="col-md-2">
            <div className="mt20">
              <div className="chartModelTitle">{Traduction.translate(language, 'chart')}</div>
              <div className="chartModelType">{Traduction.translate(language, 'from_model')}</div>
            </div>
          </div>
          <div className="col-md-10">
            {availableChartModels.map((chartModel, index) => {
              if(chartModel.Name !== 'Custom') {
                return <div key={index} className="col-md-6">
                  <div className={(chartModel.Model === chartModelSelected.Model ? "chartModelModificationBordered" : "chartModelModification")} onClick={() => this.setState({ chartModelSelected: chartModel })}>
                    <div className="chartModel">
                      {/* Chart Model Image */}
                      <div className="chartModelImageBorder">{this.getChartModelImage(chartModel.Name)}</div>
                      {/* Chart Model Label & Description */}
                      <div className="chartModelContent">
                        <div className="chartModelLabel">{chartModel.Label}</div>
                        <div className="chartModelDescription">{chartModel.Description}</div>
                      </div>
                    </div>
                  </div>
                </div>;
              }
            })}
          </div>
        </div>
      </div>
      {/* Button Validate or Cancel */}
      <div className="flex flex-end">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.updateChartModel(chartModelSelected); this.closeChartModelPopup() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeChartModelPopup() }>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePopoverChartModel(chartModel, availableChartModels) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
        <PopoverEditChartSetting ItemId={itemId} ItemType={itemType} ChartSetting={chartModel} AvailableValues={availableChartModels} onChartSettingEdit={this.updateChartModel}></PopoverEditChartSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartType(chartType, availableChartTypes) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartType ItemId={itemId} ItemType={itemType} ChartType={chartType} AvailableChartTypes={availableChartTypes} onChartTypeEdit={this.updateChartType}></PopoverEditChartType>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartRow(chartRow, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartRow ItemId={itemId} ItemType={itemType} Index={index} ChartRow={chartRow} AvailableFields={availableFields} onChartRowEdit={this.updateChartRow}></PopoverEditChartRow>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartAddRow(availableFields) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartRow ItemId={itemId} ItemType={itemType} AvailableFields={availableFields} onChartRowAdd={this.addChartRow}></PopoverEditChartRow>
        </Popover.Content>
      </Popover>
    );
    
    return popover;
  }

  templatePopoverChartColumn(chartColumn, availableFields) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartColumn ItemId={itemId} ItemType={itemType} ChartColumn={chartColumn} AvailableFields={availableFields} onChartColumnEdit={this.updateChartColumn}></PopoverEditChartColumn>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartAddIndicator(availableFields) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartIndicator ItemId={itemId} ItemType={itemType} AvailableFields={availableFields} onChartIndicatorAdd={this.addChartIndicator}></PopoverEditChartIndicator>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartIndicator(chartIndicator, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartIndicator ItemId={itemId} ItemType={itemType} Index={index} ChartIndicator={chartIndicator} AvailableFields={availableFields} onChartIndicatorEdit={this.updateChartIndicator}></PopoverEditChartIndicator>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartIndicatorFunction(chartIndicatorFunction, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartIndicatorFunction ItemId={itemId} ItemType={itemType} Index={index} ChartIndicatorFunction={chartIndicatorFunction} AvailableFields={availableFields} onChartIndicatorFunctionEdit={this.updateChartIndicatorFunction}></PopoverEditChartIndicatorFunction>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverChartSetting(chartSetting, availableValues) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditChartSetting ItemId={itemId} ItemType={itemType} ChartSetting={chartSetting} AvailableValues={availableValues} onChartSettingEdit={this.updateChartSetting}></PopoverEditChartSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closeChartModelPopup() {
    this.setState({ chartModelPopup: false });
  }

  render() {
    const { language, itemId, itemType, blockType, currentView, chart, settings, chartModelPopup, isLoading } = this.state;

    return (
      <div className="chart">
        {/* Chart Model Popup */}
        {(chartModelPopup === true) && <div className="chartModelModificationPopup">
          <div className="chartModelInnerModificationPopup">{this.templateChartModelPopup()}</div>
        </div>}

        {/* Display Chart + Settings */}
        {settings && <div className="chartWithSettings">
          {isLoading && <div className="chartDisplay"></div>}
          {!isLoading && <div className="chartDisplay">{this.displayComponent(chart)}</div>}
          <div className="chartSettings">{this.templateChartSettings(chart)}</div>
        </div>}

        {/* Display Chart only */}
        {!settings && !isLoading && <div className="chartWithoutSettings">{this.displayComponent(chart)}</div>}
      </div>
    );
  }
}

export default Chart;