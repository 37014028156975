import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import IconAndTitle from './IconAndTitle';
import MiniCard from './MiniCard';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';

const API = '/WebAppService/GetCardHome';
const API_create = '/WebAppService/InsertItemAndSave';

class Environment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockContent: {},
      blocks: [],
      tables: [],
      minicards: [],
      displayAllMinicards: [],
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getData = this.getData.bind(this);
    this.getMiniCards = this.getMiniCards.bind(this);
    this.getTables = this.getTables.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    // this.getActivesBL = this.getActivesBL.bind(this);
    // this.getAddButtonLabel = this.getAddButtonLabel.bind(this);
    this.getColorClass = this.getColorClass.bind(this);
    this.getEnvironmentTypeLabel = this.getEnvironmentTypeLabel.bind(this);
    this.buildMinicards = this.buildMinicards.bind(this);

    // Actions
    // this.createItem = this.createItem.bind(this);
    this.showMoreButton = this.showMoreButton.bind(this);
    this.showOrHideCards = this.showOrHideCards.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    await this.getData(login, authId, itemId, itemType);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // if(blockType !== prevProps.match.params.blockType) {
    //   await this.getData(login, itemId, itemType, blockType);
    // }
  }

  // Get Block Content from the API
  async getData(login, authId, itemId, itemType) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId
      })
    };
    
    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
      
      const data = await response.json();
      const blockContent = data.GetCardHomeResult;

      if(blockContent) {
        const itemTitle = blockContent.Name;
        const blocks = blockContent.Blocks;
  
        let tables = this.getTables(blocks);
  
        // Build Minicards based on Blocks
        this.buildMinicards(blocks);
  
        this.setState({ blockContent, itemTitle, blocks, tables, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }
      
    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get list of Minicards by type to display
  getMiniCards(minicards) {
    let displayAllMinicards = this.state.displayAllMinicards;
    let result = [];

    for(let i = 0; minicards[i]; i++) {
      let minicardsType = [];

      // Show all cards
      if(displayAllMinicards[i]) {
        for(let index = 0; minicards[i].Items[index]; index++) {
          minicardsType.push(minicards[i].Items[index]);
        }
      }
      // Show only 3 cards
      else {
        for(let index = 0; index < 3 && minicards[i].Items[index]; index++) {
          minicardsType.push(minicards[i].Items[index]);
        }
      }

      result.push(minicardsType);
    }

    return result;
  }

  getTables(blocks) {
    let tables = [];

    blocks.forEach(block => {
      if(block.Value.Tables[0]) {
        tables.push(block.Value.Tables[0]);
      }
    });

    return tables;
  }

  getColumnsName(index) {
    let tables = this.state.tables;
    let columns = [];
    
    if(tables[index]) {
      tables[index].ColumnHeaders.forEach(element => {
        if(element) {
          columns.push(element.FieldName);
        }
      });
    }

    return columns;
  }
  
  // getActivesBL(index) {
  //   const minicards = this.state.minicards;
  //   let nbActive = 0;

  //   minicards[index].Items.forEach(item => {
  //     if(item.Active === "True") {
  //       nbActive++;
  //     }
  //   });

  //   return nbActive;
  // }

  // getAddButtonLabel(itemType) {
  //   switch(itemType) {
  //     case "Business_Line":
  //         return "Nouveau Portfeuille";
  //     case "Entity":
  //         return "Nouvelle Équipe";
  //     case "BudgetCode":
  //         return "Nouvelle Enveloppe Budgétaire";
  //     default:
  //         break;
  //   }
  // }

  getColorClass(itemType) {
    switch(itemType) {
      case "Business_Line":
          return "blue";
      case "Entity":
          return "turquoise";
      case "BudgetCode":
          return "black";
      case "AgileBoard":
          return "green";
      default:
          break;
    }
  }

  getEnvironmentTypeLabel(index, itemType) {
    const language = this.state.language;
    const minicards = this.state.minicards;
    let nbItems;

    // Get number of Items by Item Type
    nbItems = minicards[index].Items.length;

    switch(itemType) {
      case "Business_Line":
          if(nbItems > 1) {
            return nbItems + " " + Traduction.translate(language, 'business_lines').toUpperCase();
          }
          else {
            return nbItems + " " + Traduction.translate(language, 'business_line').toUpperCase();
          }
      case "Entity":
          if(nbItems > 1) {
            return nbItems + " " + Traduction.translate(language, 'entities').toUpperCase();
          }
          else {
            return nbItems + " " + Traduction.translate(language, 'entity').toUpperCase();
          }
      case "BudgetCode":
          if(nbItems > 1) {
            return nbItems + " " + Traduction.translate(language, 'budget_codes').toUpperCase();
          }
          else {
            return nbItems + " " + Traduction.translate(language, 'budget_code').toUpperCase();
          }
      case "AgileBoard":
          if(nbItems > 1) {
            return nbItems + " " + Traduction.translate(language, 'agile_boards').toUpperCase();
          }
          else {
            return nbItems + " " + Traduction.translate(language, 'agile_board').toUpperCase();
          }
      default:
          return Traduction.translate(language, 'itemtype_not_recognized').toUpperCase();
    }
  }

  buildMinicards(blocks) {
    let minicard, type;
    let minicards = [];
    let displayAllMinicards = [];

    // Build Favorite Minicards Data structure based on Items array
    blocks.forEach(block => {
      block.Value.Tables.forEach(table => {
        if(table.Rows.length > 0) {
          minicard = table.Rows.map(row => {
            return row.Cells.reduce((acc, item) => {
              // Add the Column Name/Values to the reduced Table
              acc[item.ColumnName] = item.Value;
              acc['Item_Type'] = row.ItemType;
    
              // Store the Row ItemType
              type = row.ItemType;
    
              return acc;
            }, {});
          });
        }
        else {
          minicard = [];
          type = table.Level;
        }
  
        // Push items grouped by Type inside the Favorites array
        minicards.push({ 'Items': minicard, 'Type': type });
      });
    });

    // Initiate boolean to false for cards to only show 3
    for(let index = 0; minicards[index]; index++) {
      displayAllMinicards.push(false);
    }

    this.setState({ minicards, displayAllMinicards });
  }

  // async createItem(name, index) {
  //   const { login, authId } = this.state;
  //   let itemType = this.state.minicards[index].Type;
  //   let parentType = "";
  //   let parentId = 0;
  //   let additionalContext = [];
  //   let columns = this.getColumnsName(index);
    
  //   if(index == 1) {
  //     columns = columns.filter(item => item !== "Item_ID");
  //   }
    
  //   if(itemType === 'BudgetCode' || itemType === 'Entity') {
  //     return;
  //   }

  //   // Request Options and Body
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Mode': 'Login',
  //       'Login': login,
  //       'Token': authId
  //     },
  //     body: JSON.stringify({
  //       'ItemType': itemType,
  //       'ParentType': parentType,
  //       'ParentId': parentId,
  //       'Name': name,
  //       'NbItems': '1',
  //       'Fields': columns,
  //       'AdditionalContext': additionalContext
  //     })
  //   };
    
  //   try{
  //     const response = await fetch(API_create, requestOptions);

  //     if(!response.ok) {
  //       throw new Error('Something went wrong ...');
  //     }

  //     const data = await response.json();
  //     const result = data.InsertItemAndSaveResult;
  //     let rows = this.state.tables[index].Rows;

  //     // Push the new element(s) inside the Rows table
  //     if(result.IsValid === true) {
  //       result.InsertedItems.Rows.forEach(row => {
  //         rows.push(row);
  //       });

  //       // Redirect to the Business_Line Details block
  //       if(itemType === 'Business_Line') {
  //         let itemId = result.InsertedItems.Rows[0].Cells.find(element => element.ColumnName === 'Item_ID').Value;

  //         this.props.history.push(`/Card/${itemType}/${itemId.substring(1)}/Details`);
  //       }
  //     }
  //     else {
  //       this.updateErrors(result.Errors);
  //     }

  //     this.setState({ isLoading: false });

  //   } catch(error) {
  //     this.setState({ error, isLoading: false });
  //   }
  // }

  showMoreButton(index, itemType) {
    const language = this.state.language;
    const minicards = this.state.minicards;
    const displayAllMinicards = this.state.displayAllMinicards;
    let colorClass = this.getColorClass(itemType);
    let text, result;

    if(displayAllMinicards[index]) {
      text = Traduction.translate(language, 'reduce');
    }
    else {
      text = Traduction.translate(language, 'display_all_elements');
    }

    if(minicards[index].Items.length > 3) {
      result = <Button variant="" className="card-border btn-show fs15" onClick={() => this.showOrHideCards(index)}>
        <span className="iconThreeDots mediumIcons mb20"></span>
        <div className={colorClass}>{text}</div>
      </Button>;
    }

    return result;
  }

  showOrHideCards(index) {
    let display = this.state.displayAllMinicards;

    // Change display value of all Cards (true will show all Cards, false will show only 3)
    display[index] = !display[index];

    this.setState({ displayAllMinicards: display });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  render() {
    let { language, itemId, itemType, itemTitle, blockType, blockContent, blocks, minicards, displayAllMinicards, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'environment_home')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockContent.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockContent.HasRightOnItem === true && <div className="block">
          {/* Environment Block Header */}
          <div className="blockHeader">
            {/* Title */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} Blocks={blockContent.BlockNames}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Environment Block Body */}
          <div className="blockBody">
            {/* Environment Block Content */}
            <div className="blockContent">
              {/* Errors */}
              {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

              {/* Loading Spinner */}
              {isLoading && <div className="center mt30 mb20">
                <span className=""><LoadingSpinner></LoadingSpinner></span>
                <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
              </div>}

              {/* Minicards */}
              {this.getMiniCards(minicards).map((items, index) =>
                <div key={index} className="">
                  {/* Title */}
                  <IconAndTitle Title={this.getEnvironmentTypeLabel(index, minicards[index].Type)} Type={minicards[index].Type} Display={itemType}></IconAndTitle>
                  {/* Minicards */}
                  <div className="row">
                    {/* Add new Business_Line/Entity/Budget Button */}
                    {/* {items.InsertDeleteAllowed &&
                      <Button variant="" className="card-border btn-add btn-addbig" onClick={() => this.createItem("", index)}>
                        <span className="iconPlus mediumIcons mb20"></span>
                        <div className={this.getColorClass(minicards[index].Type)}>{this.getAddButtonLabel(minicards[index].Type)}</div>
                      </Button>
                    } */}
                    {/* Minicards list */}
                    {items.map(item => <div key={item.Item_ID} className="mr15">
                      <Link to={`/Card/${item.Item_Type}/${item.Item_ID.substring(1)}/Home`}>
                        <MiniCard Content={item} Name={item.Name} Id={item.Item_ID.substring(1)} Type={item.Item_Type} Status={item.Status} StartDate={item.StartDate} EndDate={item.EndDate} Progress={item.Progress}></MiniCard>
                      </Link>
                    </div>)}
                    {/* Show more/less Minicards Button */}
                    {this.showMoreButton(index, minicards[index].Type)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default Environment;