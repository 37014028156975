export const Authentication = {
  createCookie,
  getCookie,
  deleteCookie,
  deleteCookies
};

function createCookie(cookieName, cookieValue, expiresDate) {
  // Date
  const date = new Date(Date.now() + (24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();

  // Secure
  const secure = "Secure";
  
  // HttpOnly
  const http = "HttpOnly";

  // Path
  const path = "path=/";

  document.cookie = cookieName + "=" + cookieValue  + ";" + expires + ";" + secure + ";" + path;
}

function getCookie(cookieName) {
  const name = cookieName + "=";
  const ca = document.cookie.split(';');

  // Find the corresponding Cookie
  for(var i=0; i < ca.length; i++) {
    var c = ca[i];

    while(c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if(c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  // If the Cookie doesn't exists
  if(cookieName === 'userId' || cookieName === 'resourceId') {
    return 0;
  }
  else if(cookieName === 'authId') {
    return "null";
  }
  else {
    return "";
  }
}

function deleteCookie(cookieName) {
  const cookies = document.cookie.split(";");

  for(var i=0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    if(cookieName === name) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}

function deleteCookies() {
  // const cookies = document.cookie.split(";");

  document.cookie.split(";").forEach((c) => {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  
  // for(var i=0; i < cookies.length; i++) {
  //   let cookie = cookies[i];
  //   let eqPos = cookie.indexOf("=");
  //   let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

  //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  // }
}

export default Authentication;