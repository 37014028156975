import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { MapsComponent, MapsTheme, LayersDirective, LayerDirective, Bubble, BubblesDirective, BubbleDirective, Marker, MarkersDirective, MarkerDirective, Legend, MapsTooltip, Inject } from '@syncfusion/ej2-react-maps';
import '../Css/App.css';
import Authentication from '../Authentication';

import FrenchMap from './Maps/FrenchMap.json';
import EnedisDRMap from './Maps/EnedisDRMap.json';
import WorldMap from './Maps/WorldMap.json';

class MiniMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      map: {},
      isLoading: false
    };

    // Actions
    this.displayComponent = this.displayComponent.bind(this);

    // Template
    this.templateMapBubble = this.templateMapBubble.bind(this);
    this.templateBubbleTooltip = this.templateBubbleTooltip.bind(this);
    this.templateMapColor = this.templateMapColor.bind(this);
    this.templateMapMarker = this.templateMapMarker.bind(this);
    this.templateMarker = this.templateMarker.bind(this);
    this.templateMarkerTooltip = this.templateMarkerTooltip.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const map = this.props.Map;
    const isLoading = this.props.IsLoading;

    this.setState({ login, authId, language, itemId, itemType, itemTitle, blockType, currentView, map, isLoading });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const map = this.props.Map;
    const isLoading = this.props.IsLoading;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.Map) !== JSON.stringify(prevProps.Map)) {
      this.setState({ map });
    }
    if(this.props.IsLoading !== prevProps.IsLoading) {
      this.setState({ isLoading });
    }
  }

  displayComponent(map) {
    if(map && map.Points) {
      // Map Layer Marker
      if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Marker') {
        return this.templateMapMarker(map);
      }
      // Map Layer Bubble
      else if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Bubble') {
        return this.templateMapBubble(map);
      }
      // Map Layer Color
      else if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Color') {
        return this.templateMapColor(map);
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateMapBubble(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '12px' } };
    let mapShapes, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${countryName}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${countryName}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings}>
        <LayersDirective>
          <LayerDirective shapeData={mapShapes} shapeDataPath='countryName' shapePropertyPath='admin' shapeSettings={{ fill: '#E5E5E5' }}>
            <BubblesDirective>
              <BubbleDirective dataSource={map.Points} visible={true} valuePath='pointValue' minRadius={3} maxRadius={20} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></BubbleDirective>
            </BubblesDirective>
          </LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Bubble, Legend ]} />
      </MapsComponent>
    );
  }

  templateBubbleTooltip(data) {
    return '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
  }

  templateMapColor(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '12px' } };
    let mapShapes, legendSettings, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Legend
    if(map.Legend.PositionName === 'Top' || map.Legend.PositionName === 'Bottom') {
      legendSettings = { position: map.Legend.PositionName, orientation: 'Horizontal', mode: 'Default', height: '5%', width: '100%', visible: map.Legend.Visible };
    }
    else if(map.Legend.PositionName === 'Left' || map.Legend.PositionName === 'Right') {
      legendSettings = { position: map.Legend.PositionName, orientation: 'Vertical', mode: 'Default', height: '100%', width: '8%', visible: map.Legend.Visible };
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings} legendSettings={legendSettings}>
        <LayersDirective>
          <LayerDirective dataSource={map.Points} shapeData={mapShapes} shapeDataPath='consoKey' shapePropertyPath='name' shapeSettings={{ colorValuePath: 'pointValue', fill: '#E5E5E5', colorMapping: map.ColorSettings }} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Legend ]} />
      </MapsComponent>
    );
  }

  templateMapMarker(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '12px' } };
    let mapShapes, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${pointValue}</center></span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings}>
        <LayersDirective>
          <LayerDirective dataSource={map.Points} shapeData={mapShapes} shapeSettings={{ fill: '#E5E5E5' }}>
            <MarkersDirective>
              <MarkerDirective dataSource={map.Points} visible={true} animationDuration={0} shape='Circle' fill='white' width={10} border={{ color: '#285255', width: 2 }} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></MarkerDirective>
              {/* {map.Points.map((point, index) => {
                return <MarkerDirective key={index} dataSource={point} visible={true} template={() => this.templateMarker()}></MarkerDirective>
              })} */}
            </MarkersDirective>
          </LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Marker, Legend ]} />
      </MapsComponent>
    );
  }

  templateMarker(data) {
    return '<div style="font-size:13px;color:#ffffff;font-weight: 500;">XXX</div>';
  }

  templateMarkerTooltip(data) {
    return '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${pointValue}</center></span></div></div>';
  }

  render() {
    const { language, itemId, itemType, blockType, currentView, map, isLoading } = this.state;

    return (
      <div className="miniMap">
        {!isLoading && <div className="miniMapComponent">{this.displayComponent(map)}</div>}
      </div>
    );
  }
}

export default MiniMap;