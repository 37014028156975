import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import PopoverEditColumn from './PopoverEditColumn';
import MiniTable from './MiniTable';
import MiniTree from './MiniTree';
import Axe from './Axe';
import DatePickerFilter from './DatePickerFilter';

const API_check = '/WebAppService/CheckMassModification';
const API_multiple_modification = '/WebAppService/MassModifications';
const API_job_status = '/WebAppService/GetJobStatus';

class PopupMultipleModification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      columnHeaders: [],
      rows: [],
      columns: [],
      columnName: null,
      columnLabel: null,
      columnType: null,
      search: '',
      searchAxe: '',
      modificationValue: '',
      modificationBoolean: false,
      modificationObject: {},
      modificationValues: [],
      importView: {},
      tables: [],
      rowsModified: 0,
      rowsIncorrect: 0,
      job: {},
      multipleModificationPopup: true,
      previewDataPopup: false,
      jobStatusPopup: false,
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    this.getColumnRestrictedValues = this.getColumnRestrictedValues.bind(this);
    this.getRowsObject = this.getRowsObject.bind(this);

    // Actions
    this.checkMultipleModification = this.checkMultipleModification.bind(this);
    this.applyMultipleModification = this.applyMultipleModification.bind(this);
    this.getJobStatus = this.getJobStatus.bind(this);
    this.sleep = this.sleep.bind(this);
    this.searchAxeValue = this.searchAxeValue.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.updateColumn = this.updateColumn.bind(this);
    this.addAxeValue = this.addAxeValue.bind(this);
    this.deleteAxeValue = this.deleteAxeValue.bind(this);
    this.addAxisTableValue = this.addAxisTableValue.bind(this);
    this.deleteAxisTableValue = this.deleteAxisTableValue.bind(this);
    this.updateBooleanValue = this.updateBooleanValue.bind(this);
    this.updateDateValue = this.updateDateValue.bind(this);
    this.updateNumberValue = this.updateNumberValue.bind(this);
    this.updateTextValue = this.updateTextValue.bind(this);
    this.updateRatingValue = this.updateRatingValue.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    // Template
    this.templateColumnModification = this.templateColumnModification.bind(this);
    this.templateColumnTypeAxe = this.templateColumnTypeAxe.bind(this);
    this.templateColumnTypeAxisTable = this.templateColumnTypeAxisTable.bind(this);
    this.templateColumnTypeBoolean = this.templateColumnTypeBoolean.bind(this);
    this.templateColumnTypeDate = this.templateColumnTypeDate.bind(this);
    this.templateColumnTypeNumber = this.templateColumnTypeNumber.bind(this);
    this.templateColumnTypeText = this.templateColumnTypeText.bind(this);
    this.templateMultipleModification = this.templateMultipleModification.bind(this);
    this.templatePreviewData = this.templatePreviewData.bind(this);
    this.templateJobStatus = this.templateJobStatus.bind(this);
    this.closeMultipleModificationPopup = this.closeMultipleModificationPopup.bind(this);
    this.closeJobStatusPopup = this.closeJobStatusPopup.bind(this);

    // Reference
    this.minitable = React.createRef();
    this.minitree = React.createRef();
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const columnHeaders = this.props.ColumnHeaders;
    const rows = this.props.Rows;
    const columnName = this.props.ColumnName;
    let columns = [];

    // Get Current View Columns
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
    }
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView, columnHeaders, rows, columns, columnName });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const columnHeaders = this.props.ColumnHeaders;
    const rows = this.props.Rows;
    const columnName = this.props.ColumnName;
    let columns = [];

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      // Get Current View Columns
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      }

      this.setState({ currentView, columns });
    }
    if(JSON.stringify(this.props.ColumnHeaders) !== JSON.stringify(prevProps.ColumnHeaders) || columnName !== prevProps.ColumnName) {
      this.setState({ columnHeaders, columnName });
    }
  }

  getColumnLabel(columnName) {
    const columnHeaders = this.state.columnHeaders;
    let label = '';

    if(columnHeaders.find(column => column.FieldName === columnName)) {
      label = columnHeaders.find(column => column.FieldName === columnName).Label;
    }
    
    return label;
  }

  getColumnType(columnName) {
    const columnHeaders = this.state.columnHeaders;

    if(columnHeaders.find(column => column.FieldName === columnName)) {
      return columnHeaders.find(column => column.FieldName === columnName).FieldType;
    }
    else {
      return null;
    }
  }

  getColumnRestrictedValues(columnName) {
    const columnHeaders = this.state.columnHeaders;

    if(columnHeaders.find(column => column.FieldName === columnName)) {
      return columnHeaders.find(column => column.FieldName === columnName).RestrictedValues;
    }
    else {
      return [];
    }
  }

  getRowsObject(rows) {
    let itemId, items = [];

    rows.forEach(row => {
      if(row.Cells.find(cell => cell.ColumnName === 'Item_ID')) {
        itemId = row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value;
      }

      items.push({ ItemId: itemId.substring(1), ItemType: row.ItemType });
    });

    return items;
  }

  async checkMultipleModification(columnName) {
    const { login, authId, language, itemId, itemType, currentView, rows, modificationValue, modificationBoolean, modificationObject, modificationValues } = this.state;
    let newValue, additionalContext = [];

    this.setState({ isLoading: true });

    if(this.getColumnType(columnName) === 'AxisTable' || this.getColumnType(columnName) === 'ResourceTable') {
      newValue = JSON.stringify(modificationValues);
    }
    else if(this.getColumnType(columnName) === 'Axe' || this.getColumnType(columnName) === 'Location' || this.getColumnType(columnName) === 'Resource' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline') {
      newValue = JSON.stringify(modificationObject);
    }
    else if(this.getColumnType(columnName) === 'Boolean') {
      newValue = JSON.stringify(modificationBoolean);
    }
    else {
      newValue = modificationValue;
    }
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'Items': this.getRowsObject(rows),
        'ColumnName': columnName,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_check, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.CheckMassModificationResult;
      let errors = [];
      
      // Check request response
      if(result) {
        if(result.IsValid === true) {
          const importView = result.ImportView;
          const tables = result.Tables[0];
          let rowsModified = 0, rowsIncorrect = 0;
          
          if(result.Tables && result.Tables[0].Rows) {
            rowsModified = result.Tables[0].Rows.length;

            result.Tables[0].Rows.forEach(row => {
              if(row.Cells.find(cell => cell.ColumnName === 'Import_Status') && JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Import_Status').Value).Id !== 0) {
                rowsIncorrect ++;
              }
            });
          }
  
          this.setState({ importView, tables, rowsModified, rowsIncorrect, multipleModificationPopup: false, previewDataPopup: true, isLoading: false, errors });
        }
        else {
          errors.push(result.Errors);
  
          this.setState({ isLoading: false, errors });
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async applyMultipleModification(columnName) {
    const { login, authId, language, itemId, itemType, blockType, currentView, rows, modificationValue, modificationBoolean, modificationObject, modificationValues } = this.state;
    let newValue, additionalContext = [];

    this.setState({ isLoading: true });

    if(this.getColumnType(columnName) === 'AxisTable' || this.getColumnType(columnName) === 'ResourceTable') {
      newValue = JSON.stringify(modificationValues);
    }
    else if(this.getColumnType(columnName) === 'Axe' || this.getColumnType(columnName) === 'Location' || this.getColumnType(columnName) === 'Resource' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline') {
      newValue = JSON.stringify(modificationObject);
    }
    else if(this.getColumnType(columnName) === 'Boolean') {
      newValue = JSON.stringify(modificationBoolean);
    }
    else {
      newValue = modificationValue;
    }
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'Items': this.getRowsObject(rows),
        'ColumnName': columnName,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_multiple_modification, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.MassModificationsResult;
      let errors = [];

      // Check request response
      if(result) {
        let job, count = 0;

        this.setState({ job: result, previewDataPopup: false, jobStatusPopup: true, isLoading: false });

        // Try to get Job Status while not finished
        while(count < 150) {
          job = await this.getJobStatus(result);

          if(job.Completion === 100 || job.Status === 'Finished') {
            break;
          }
          else {
            await this.sleep(2000);
          }

          count ++;

          this.setState({ job });
        }

        if(job.ResultImport) {
          if(job.ResultImport.IsValid === true) {
            // Close Popup
            this.closeJobStatusPopup();
            this.closeMultipleModificationPopup();

            // Get Card Data
            await this.props.onMultipleModification(login, authId, itemId, itemType, blockType, currentView);
          }
          else {
            errors.push(job.Errors);
    
            this.setState({ errors });
          }
        }
        else {
          errors.push(Traduction.translate(language, 'server_not_responding'));

          this.setState({ errors });
        }

      }
      else {
        errors.push(Traduction.translate(language, 'modification_impossible'));

        this.setState({ errors });
      }

    } catch(error) {
      console.log(error);
    }
  }

  async getJobStatus(job) {
    const { login, authId, language, itemId, itemType, blockType, currentView } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'JobId': job.JobId
      })
    };

    try{
      const response = await fetch(API_job_status, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetJobStatusResult;
      let errors = this.state.errors;

      if(result) {
        return result;
      }
      else {
        errors.push(Traduction.translate(language, 'job_error'));

        this.setState({ errors });
      }

    } catch(error) {
      console.log(error);
    }
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  searchAxeValue(event) {
    this.setState({ searchAxe: event.target.value });
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.minitable.current) {
      this.minitable.current.searchItem(search);
    }
    // Search Items in Tree component
    if(this.minitree.current) {
      this.minitree.current.searchItem(search);
    }

    this.setState({ search });
  }

  exportXLSX() {
    this.setState({ isLoading: true });

    if(this.minitable.current) {
      this.minitable.current.exportXLSX();
    }
    if(this.minitree.current) {
      this.minitree.current.exportXLSX();
    }

    // Call the event from the Parent component through the props
    // this.props.onXLSXExport();

    this.setState({ isLoading: false });
  }

  updateColumn(columnName) {
    this.setState({ columnName });
  }

  addAxeValue(value) {
    this.setState({ modificationObject: value });
  }

  deleteAxeValue() {
    this.setState({ modificationObject: {} });
  }

  addAxisTableValue(modificationValues, value) {
    modificationValues.push(value);

    this.setState({ modificationValues });
  }

  deleteAxisTableValue(modificationValues, value) {
    if(modificationValues.find(item => item.Id === value.Id)) {
      modificationValues.splice(modificationValues.findIndex(item => item.Id === value.Id), 1);
    }

    this.setState({ modificationValues });
  }

  updateBooleanValue(value) {
    this.setState({ modificationBoolean: value });
  }

  updateDateValue(columnName, date) {
    this.setState({ modificationValue: date });
  }

  updateNumberValue(event) {
    this.setState({ modificationValue: event.target.value });
  }

  updateTextValue(event) {
    this.setState({ modificationValue: event.target.value });
  }

  updateRatingValue(value) {
    this.setState({ modificationValue: value });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  templateColumnModification() {
    const { language, itemId, itemType, currentView, columnHeaders, columns, columnName, searchAxe } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditColumn CurrentView={currentView} ColumnHeaders={columnHeaders} Columns={columns} ColumnName={columnName} onColumnEdit={this.updateColumn}></PopoverEditColumn>
        </Popover.Content>
      </Popover>
    );

    return (<div className="flex flex-column width100p">
      <div className="filtersColumnTitle">
        {/* Filter Select */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="filtersColumnName">
            <div className="filtersColumnLabel">{this.getColumnLabel(columnName)}</div>
            <div className="flex flex-end"><span className="iconChevronDownGrey iconsPopover"></span></div>
          </div>
        </OverlayTrigger>
      </div>
      {/* Column Value */}
      {(this.getColumnType(columnName) === 'Axe' || this.getColumnType(columnName) === 'AxisTable' || this.getColumnType(columnName) === 'Resource' || this.getColumnType(columnName) === 'ResourceTable' || this.getColumnType(columnName) === 'Location') && 
        <div className="filtersSearchValues">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={searchAxe} placeholder={Traduction.translate(language, 'search')} onChange={(e) => this.searchAxeValue(e)} />
          </Form.Group>
        </div>
      }
      {/* Axes */}
      {this.getColumnType(columnName) === 'Axe' && this.templateColumnTypeAxe(columnName)}
      {this.getColumnType(columnName) === 'Resource' && this.templateColumnTypeAxe(columnName)}
      {this.getColumnType(columnName) === 'Location' && this.templateColumnTypeAxe(columnName)}
      {columnName === 'Timeline' && this.templateColumnTypeAxe(columnName)}
      {columnName === 'Meteo' && this.templateColumnTypeAxe(columnName)}
      {columnName === 'Trend' && this.templateColumnTypeAxe(columnName)}
      {/* Axis Table */}
      {this.getColumnType(columnName) === 'AxisTable' && this.templateColumnTypeAxisTable(columnName)}
      {this.getColumnType(columnName) === 'ResourceTable' && this.templateColumnTypeAxisTable(columnName)}
      {/* Boolean */}
      {this.getColumnType(columnName) === 'Boolean' && this.templateColumnTypeBoolean(columnName)}
      {/* Date */}
      {this.getColumnType(columnName) === 'Date' && this.templateColumnTypeDate(columnName)}
      {/* HTML/Text */}
      {this.getColumnType(columnName) === 'HTML' && this.templateColumnTypeText(columnName)}
      {this.getColumnType(columnName) === 'Link' && this.templateColumnTypeText(columnName)}
      {this.getColumnType(columnName) === 'String' && this.templateColumnTypeText(columnName)}
      {/* Number */}
      {this.getColumnType(columnName) === 'Double' && columnName !== 'Meteo' && columnName !== 'Trend' && this.templateColumnTypeNumber(columnName)}
      {this.getColumnType(columnName) === 'Percentage' && this.templateColumnTypeNumber(columnName)}
      {/* Rating */}
      {this.getColumnType(columnName) === 'Rating' && this.templateColumnRating(columnName)}
    </div>);
  }

  templateColumnTypeAxe(columnName) {
    const { currentView, modificationObject, searchAxe } = this.state;
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    return (
      <div className="multipleModificationAxeValues">
        {/* Current Values */}
        {Object.entries(modificationObject).length > 0 && 
          <div className="multipleModificationAxeValue" onClick={() => this.deleteAxeValue(modificationObject)}>
            <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
            <Axe CurrentView={currentView} Axe={columnName} Value={modificationObject} View={'Popover'}></Axe>
          </div>
        }
        {/* Other Values */}
        {restrictedValues && restrictedValues.map((value, index) => {
          if(modificationObject && modificationObject.Id !== value.Id) {
            // Check if Value contains Search
            if(value.Label.toLowerCase().includes(searchAxe.toLowerCase())) {
              return <div key={index} className="multipleModificationAxeValue" onClick={() => this.addAxeValue(value)}>
                <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                <Axe CurrentView={currentView} Axe={columnName} Value={value} View={'Popover'}></Axe>
              </div>;
            }
          }
        })}
      </div>
    );
  }

  templateColumnTypeAxisTable(columnName) {
    const { currentView, modificationValues, searchAxe } = this.state;
    const restrictedValues = this.getColumnRestrictedValues(columnName);

    return (
      <div className="multipleModificationAxeValues">
        {/* Current Values */}
        {modificationValues && modificationValues.map((value, index) => {
          return <div key={index} className="multipleModificationAxeValue" onClick={() => this.deleteAxisTableValue(modificationValues, value)}>
            <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
            <Axe CurrentView={currentView} Axe={columnName} Value={value} View={'Popover'}></Axe>
          </div>;
        })}
        {/* Other Values */}
        {restrictedValues && restrictedValues.map((value, index) => {
          if(!modificationValues.find(item => item.Id === value.Id)) {
            // Check if Value contains Search
            if(value.Label.toLowerCase().includes(searchAxe.toLowerCase())) {
              return <div key={index} className="multipleModificationAxeValue" onClick={() => this.addAxisTableValue(modificationValues, value)}>
                <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                <Axe CurrentView={currentView} Axe={columnName} Value={value} View={'Popover'}></Axe>
              </div>;
            }
          }
        })}
      </div>
    );
  }

  templateColumnTypeBoolean(columnName) {
    const { language, modificationBoolean } = this.state;

    return <div className="multipleModificationBooleanValues">
      <div className="multipleModificationBooleanValue" onClick={() => this.updateBooleanValue(true)}>
        <Form.Check id="" className="checkboxObjectTable" type="radio" label="" checked={modificationBoolean} readOnly/>
        <span className="iconCheck iconsPopover"></span>
        <span className="fs12 ml10">{Traduction.translate(language, 'true')}</span>
      </div>
      <div className="multipleModificationBooleanValue" onClick={() => this.updateBooleanValue(false)}>
        <Form.Check id="" className="checkboxObjectTable" type="radio" label="" checked={!modificationBoolean} readOnly/>
        <span className="iconCircleGrey iconsPopover"></span>
        <span className="fs12 ml10">{Traduction.translate(language, 'false')}</span>
      </div>
    </div>;
  }

  templateColumnTypeDate(columnName) {
    return (
      <DatePickerFilter Filter={columnName} Editable={true} onDateChange={this.updateDateValue}></DatePickerFilter>
    );
  }

  templateColumnTypeNumber(columnName) {
    const { language, modificationValue } = this.state;

    return (
      <Form.Group className="multipleModificationTypeString">
        <Form.Control id="" className="multipleModificationTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={modificationValue} onChange={(e) => this.updateNumberValue(e)} />
      </Form.Group>
    );
  }

  templateColumnRating(columnName) {
    const { currentView, modificationValue } = this.state;

    return (
      <div className="multipleModificationAxeValues">
        {/* Value 0 */}
        {modificationValue === '0' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'0'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '0' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('0')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'0'} View={'Popover'}></Axe>
        </div>}
        {/* Value 1 */}
        {modificationValue === '1' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'1'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '1' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('1')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'1'} View={'Popover'}></Axe>
        </div>}
        {/* Value 2 */}
        {modificationValue === '2' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'2'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '2' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('2')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'2'} View={'Popover'}></Axe>
        </div>}
        {/* Value 3 */}
        {modificationValue === '3' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'3'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '3' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('3')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'3'} View={'Popover'}></Axe>
        </div>}
        {/* Value 4 */}
        {modificationValue === '4' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'4'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '4' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('4')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'4'} View={'Popover'}></Axe>
        </div>}
        {/* Value 5 */}
        {modificationValue === '5' && <div className="multipleModificationAxeValue" onClick={() => this.updateRatingValue('')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'5'} View={'Popover'}></Axe>
        </div>}
        {modificationValue !== '5' && <div className="multipleModificationAxeValue opacity50" onClick={() => this.updateRatingValue('5')}>
          <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
          <Axe CurrentView={currentView} Axe={columnName} Type={this.getColumnType(columnName)} Value={'5'} View={'Popover'}></Axe>
        </div>}
      </div>
    );
  }

  templateColumnTypeText(columnName) {
    const { language, modificationValue } = this.state;

    return (
      <Form.Group className="multipleModificationTypeString">
        <Form.Control id="" className="multipleModificationTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={modificationValue} onChange={(e) => this.updateTextValue(e)} />
      </Form.Group>
    );
  }

  templateMultipleModification(columnName) {
    const { language, columnHeaders, errors } = this.state;

    return (<div className="popupMultipleModification">
      {/* Label */}
      <div className="inline-flex">
        <span className="multipleModificationLabel">{Traduction.translate(language, 'multiple_modification')}</span>
        <span className="multipleModificationLabelYellow">{Traduction.translate(language, 'multiple_modification_filtered_lines')}</span>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Column Mass Modification */}
      {columnName && <div className="filtersBlockContent scrollbar-miniblock">
        <div className="filtersColumn">{this.templateColumnModification(columnName)}</div>
      </div>}

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.checkMultipleModification(columnName)}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeMultipleModificationPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePreviewData() {
    const { language, itemId, itemType, blockType, currentView, columnName, importView, tables, rowsModified, rowsIncorrect, search, isLoading, errors } = this.state;

    return (<div className="popupPreviewModification flex flex-column">
      <div className="blockFilters row">
        <div className="views">
          {/* Label */}
          <div className="inline-flex">
            <span className="multipleModificationLabel">{Traduction.translate(language, 'multiple_modification_report')}</span>
            <span className="multipleModificationLabelYellow">{this.getColumnLabel(columnName)}</span>
            <span className="multipleModificationLabel">{Traduction.translate(language, 'with')}</span>
            <span className="multipleModificationLabelYellow">{rowsIncorrect}</span>
            <span className="multipleModificationLabel">{Traduction.translate(language, 'multiple_modification_incorrect_lines')}</span>
          </div>
        </div>
        <div className="filters">
          {/* Search */}
          <div className="filtersSearch">
            <Form.Group className="searchBlock">
              <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
            </Form.Group>
          </div>
          {/* Export */}
          <div id="exportXLSX" className="exportList" onClick={(e) => this.exportXLSX()}>
            <i className="iconExcel icons mr10" alt="Export XLSX"/>
            <div className="fs12">{Traduction.translate(language, 'export_xlsx')}</div>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Spinner Loading */}
      {isLoading && <div className="center mt30 mb20">
        <span className=""><LoadingSpinner></LoadingSpinner></span>
        <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
      </div>}

      {/* Mini Component Preview */}
      {!isLoading && <div className="multipleModificationTableContent scrollbar-miniblock">
        {importView.ViewType === 0 && importView.DefaultLevel === 0 && <MiniTree ref={this.minitree} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} Pagging={true} CurrentView={importView} Columns={tables.ColumnHeaders} Rows={tables.Rows}></MiniTree>}
        {importView.ViewType === 0 && importView.DefaultLevel !== 0 && <MiniTable ref={this.minitable} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} Pagging={true} CurrentView={importView} Columns={tables.ColumnHeaders} Rows={tables.Rows}></MiniTable>}
      </div>}

      {/* Button Validate or Cancel */}
      {!isLoading && <div className="flex flex-end align-items-center">
        {/* Buttons */}
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.applyMultipleModification(columnName)}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeMultipleModificationPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>}
    </div>);
  }

  templateJobStatus() {
    const { language, fileImport, job, errors } = this.state;

    // setInterval(() => { this.validateJob() }, 10000);

    return (<div className="popupJobStatus flex flex-column">
      <div className="blockFilters row">
        <div className="views">
          {/* Job Status */}
          <div className="jobStatusLabel">{job.Status}</div>
          {/* Spinner Loading */}
          <div className="mt10 ml30"><LoadingSpinner></LoadingSpinner></div>
        </div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Job Informations */}
      {job.Completion === 100 && <div className="jobProgress">
        <span className="jobProgressLabel">{`${job.Completion} %`}</span>
        <span className="jobProgressBar"><ProgressBar className="progressJob" variant="success" now={job.Completion}></ProgressBar></span>
      </div>}
      {job.Completion < 100 && <div className="jobProgress">
        <span className="jobProgressLabel">{`${job.Completion} %`}</span>
        <span className="jobProgressBar"><ProgressBar className="progressJob" variant="primary" now={job.Completion}></ProgressBar></span>
      </div>}
    </div>);
  }

  closeMultipleModificationPopup() {
    // Call the event from the Parent component through the props
    this.props.onPopupClose();
  }

  closeJobStatusPopup() {
    this.setState({ jobStatusPopup: false, errors: [] });
  }
  
  render() {
    const { language, currentView, columnHeaders, columns, columnName, job, multipleModificationPopup, previewDataPopup, jobStatusPopup } = this.state;

    return (
      <div className="">
        {/* Popup Multiple Modification */}
        {multipleModificationPopup === true && <div className="multipleModificationPopup">
          <div className="multipleModificationInnerPopup">{this.templateMultipleModification(columnName)}</div>
        </div>}

        {/* Popup Preview Data */}
        {previewDataPopup === true && <div className="multipleModificationPopup">
          <div className="multipleModificationInnerPopup">{this.templatePreviewData(columnName)}</div>
        </div>}

        {/* Popup Job Status */}
        {jobStatusPopup === true && <div className="jobStatusPopup">
          <div className="jobStatusInnerPopup">{this.templateJobStatus()}</div>
        </div>}
      </div>
    )
  }
}

export default PopupMultipleModification;