import React, {Component} from 'react';
import { Button, Form, FormControl, InputGroup, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopoverEditSetting from './PopoverEditSetting';

class FiltersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      availableAxes: [],
      htmlContent: 'Dynamic',
      qualitativeDisplay: 'Weather',
      tableSettingsPopup: false
    };

    // Data Structure
    this.initializeAttributes = this.initializeAttributes.bind(this);
    
    // Actions
    this.changeHTMLContent = this.changeHTMLContent.bind(this);
    this.changeQualitativeDisplay = this.changeQualitativeDisplay.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.openTableSettingsPopup = this.openTableSettingsPopup.bind(this);

    // Template
    this.templatePlanningSettings = this.templatePlanningSettings.bind(this);
    this.templatePopoverEditHTML = this.templatePopoverEditHTML.bind(this);
    this.templatePopoverEditQualitative = this.templatePopoverEditQualitative.bind(this);
    this.closeTableSettingsPopup = this.closeTableSettingsPopup.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const availableAxes = this.props.AvailableAxes;

    this.initializeAttributes(currentView);

    this.setState({ authId, language, itemId, itemType, blockType, currentView, availableAxes });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const availableAxes = this.props.AvailableAxes;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.AvailableAxes) !== JSON.stringify(prevProps.AvailableAxes)) {
      this.setState({ availableAxes });
    }
  }

  initializeAttributes(currentView) {
    let htmlContent, qualitativeDisplay;

    // HTML Content
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'HTMLContent')) {
      htmlContent = currentView.Parameters.find(param => param.Name === 'HTMLContent').Value;
    }
    else {
      htmlContent = "Dynamic";
    }

    // Qualitative Display
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'QualitativeDisplay')) {
      qualitativeDisplay = currentView.Parameters.find(param => param.Name === 'QualitativeDisplay').Value;
    }
    else {
      qualitativeDisplay = "Weather";
    }

    this.setState({ currentView, htmlContent, qualitativeDisplay });
  }

  changeHTMLContent(htmlContent) {
    // const htmlContent = event.target.value;

    this.setState({ htmlContent });
  }

  changeQualitativeDisplay(qualitativeDisplay) {
    // const qualitativeDisplay = event.target.value;

    this.setState({ qualitativeDisplay });
  }

  applySettings() {
    const { currentView, htmlContent, qualitativeDisplay } = this.state;

    // HTML Content
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'HTMLContent')) {
      currentView.Parameters.find(param => param.Name === 'HTMLContent').Value = htmlContent;
    }
    else if(currentView.Parameters) {
      currentView.Parameters.push({ Name: 'HTMLContent', Value: htmlContent, ViewId: currentView.ViewId });
    }

    // Qualitative Display
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'QualitativeDisplay')) {
      currentView.Parameters.find(param => param.Name === 'QualitativeDisplay').Value = qualitativeDisplay;
    }
    else if(currentView.Parameters) {
      currentView.Parameters.push({ Name: 'QualitativeDisplay', Value: qualitativeDisplay, ViewId: currentView.ViewId });
    }

    // Call the event from the Parent component through the props with view value
    this.props.onSettingsChange(currentView);

    // Call the event from the Parent component through the props with view value
    // this.props.onViewChange(currentView);

    this.setState({ tableSettingsPopup: false });
  }

  openTableSettingsPopup() {
    this.setState({ tableSettingsPopup: true });
  }

  templatePlanningSettings() {
    const { language, currentView, availableAxes, htmlContent, qualitativeDisplay } = this.state;
    let htmlContentValues = [], qualitativeDisplayDValues = [];
    
    // HTML Content Values
    htmlContentValues = [
      { Axe: 'Dynamic', Label: Traduction.translate(language, 'dynamic_line_height')},
      { Axe: 'Limited', Label: Traduction.translate(language, 'limited_line_height')}
    ];

    // Qualitative Display Values
    qualitativeDisplayDValues = [
      { Axe: 'Weather', Label: Traduction.translate(language, 'weather_icons')},
      { Axe: 'Traffic', Label: Traduction.translate(language, 'traffic_lights')}
    ];

    return (<div className="flex flex-column popupColumnChooser">
      {/* Label */}
      <div className="tableSettingsLabel mb10">{Traduction.translate(language, 'advanced_settings')}</div>
    
      {/* Display */}
      <div className="flex flex-column">
        {/* Title */}
        <div className="tableSettingsTitle">{Traduction.translate(language, 'display')}</div>

        {/* HTML Content */}
        <div className="mv5">
          <div className="flex align-items-center">
            <div className="tableSettingSubtitle mr20">{Traduction.translate(language, 'rich_text')}</div>
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditHTML(htmlContent, htmlContentValues)}>
              <div className="cursor">
                {htmlContentValues.find(value => value.Axe === htmlContent) && <div className="selectAxe"><div className="">{htmlContentValues.find(value => value.Axe === htmlContent).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
                {!htmlContentValues.find(value => value.Axe === htmlContent) && <div className="selectAxe"><div className="">{htmlContentValues[0].Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
              </div>
            </OverlayTrigger>
          </div>
        </div>

        {/* Qualitative Display */}
        <div className="mv5">
          <div className="flex align-items-center mb10">
            <div className="tableSettingSubtitle mr20">{Traduction.translate(language, 'qualitative_display')}</div>
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverEditQualitative(qualitativeDisplay, qualitativeDisplayDValues)}>
              <div className="cursor">
                {qualitativeDisplayDValues.find(value => value.Axe === qualitativeDisplay) && <div className="selectAxe"><div className="">{qualitativeDisplayDValues.find(value => value.Axe === qualitativeDisplay).Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
                {!qualitativeDisplayDValues.find(value => value.Axe === qualitativeDisplay) && <div className="selectAxe"><div className="">{qualitativeDisplayDValues[0].Label}</div><span className="iconChevronDownGrey extrasmallIcons ml10"></span></div>}
              </div>
            </OverlayTrigger>
          </div>
        </div>
      </div>

      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={this.applySettings}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={this.closeTableSettingsPopup}>{Traduction.translate(language, 'cancel')}</Button>
      </div>
    </div>);
  }

  templatePopoverEditHTML(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeHTMLContent}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverEditQualitative(currentValue, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditSetting ItemId={itemId} ItemType={itemType} CurrentValue={currentValue} Values={values} onValueEdit={this.changeQualitativeDisplay}></PopoverEditSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closeTableSettingsPopup() {
    this.setState({ tableSettingsPopup: false });
  }

  render() {
    let { language, currentView, tableSettingsPopup } = this.state;

    return (
      <div className="">
        {/* Popup Table Settings */}
        {tableSettingsPopup === true && <div className="tableSettingsPopup">
          <div className="tableSettingsInnerPopup">{this.templatePlanningSettings()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'advanced_settings')}</strong></Tooltip>}>
            <div className="filtersTableSettings" onClick={() => this.openTableSettingsPopup()}>
              <span className="iconParametersBlue iconsFilter cursor"/>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersTable;