import React, { Component } from 'react';
import { Badge, ProgressBar } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Timeline from './Timeline';
import IconEmptyWeek from '../Images/EmptyWeek.png';

class IndicatorsUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      userWeek: {},
      userTimetracking: {},
      emptyWeekIcon: null,
      tasks: [],
      nbDelayed: 0,
      nbInProgress: 0,
      display: false,
      isLoading: false,
      error: null
    };
    
    // Data Structure
    this.getTasksIndicators = this.getTasksIndicators.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);

    // Actions
    this.changeDisplay = this.changeDisplay.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const emptyWeekIcon = Authentication.getCookie('emptyWeekIcon');
    const userWeek = this.props.UserWeek;
    const userTimetracking = this.props.UserTimetracking;
    let tasks = [];

    if(userWeek.Tasks) {
      tasks = JSON.parse(JSON.stringify(userWeek.Tasks.Rows));
      tasks.splice(5);
    }

    // Get Tasks Indicators
    this.getTasksIndicators(userWeek);
    
    this.setState({ authId, language, formatDate, emptyWeekIcon, userWeek, userTimetracking, tasks });
  }

  componentDidUpdate(prevProps) {
    const userWeek = this.props.UserWeek;
    const userTimetracking = this.props.UserTimetracking;
    let tasks = [];

    if(this.props.UserWeek !== prevProps.UserWeek || this.props.UserTimetracking !== prevProps.UserTimetracking) {
      if(userWeek.Tasks) {
        tasks = JSON.parse(JSON.stringify(userWeek.Tasks.Rows));
        tasks.splice(5);
      }

      // Get Tasks Indicators
      this.getTasksIndicators(userWeek);

      this.setState({ userWeek, userTimetracking, tasks });
    }
  }

  getTasksIndicators(userWeek) {
    let nbDelayed = 0;
    let nbInProgress = 0;

    if(userWeek.Tasks) {
      userWeek.Tasks.Rows.forEach(row => {
        const today = new Date();
        let endDate;

        if(row.Cells.find(cell => cell.ColumnName === 'EndDate')) {
          endDate = new Date(row.Cells.find(cell => cell.ColumnName === 'EndDate').Value);
        }

        // If EndDate is before or after the Period
        if((endDate.getTime() < today.getTime())) {
          nbDelayed ++;
        }
        else {
          nbInProgress ++;
        }
      });
    }

    this.setState({ nbDelayed, nbInProgress });
  }

  changeDisplay(display) {
    const userWeek = this.state.userWeek;
    let tasks = [];

    if(userWeek.Tasks) {
      if(display) {
        tasks = JSON.parse(JSON.stringify(userWeek.Tasks.Rows));
        tasks.splice(5);
      }
      else {
        tasks = userWeek.Tasks.Rows;
      }
    }

    this.setState({ tasks, display: !display });
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }
  
  render() {
    let { language, formatDate, emptyWeekIcon, userWeek, userTimetracking, tasks, nbDelayed, nbInProgress, display, isLoading, error } = this.state;
    let total = 0, progress = 0;

    if(userTimetracking) {
      total = userTimetracking.Burned + userTimetracking.Holidays + userTimetracking.Missing;
      progress = (userTimetracking.Burned + userTimetracking.Holidays) * 100 / total;
      progress = parseInt(progress.toFixed(0));

      if(userTimetracking.Burned) {
        userTimetracking.Burned = userTimetracking.Burned.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 0 });
      }
      if(userTimetracking.Holidays) {
        userTimetracking.Holidays = userTimetracking.Holidays.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 0 });
      }
      if(userTimetracking.Missing) {
        userTimetracking.Missing = userTimetracking.Missing.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 0 });
      }
    }

    return (
      <div className="">
        {/* Title */}
        {userWeek && <div className="homeLabel"><span className="iconCalendarYellow icons icon nav mr10 mt-5"></span>{Traduction.translate(language, 'your_week')}</div>}

        {/* Empty Week */}
        {userWeek.EmptyWeek && <div className="homeUserWeek">
          {emptyWeekIcon && <div className="mb10"><img className="imageHomePage" src={emptyWeekIcon}/></div>}
          {!emptyWeekIcon && <div className="mb10"><img className="imageHomePage" src={IconEmptyWeek}/></div>}
          <span className="homeUserWeekMessage">{userWeek.GlobalMessage}</span>
        </div>}

        {/* Holidays */}
        {userWeek.Holidays && <div className="homeUserWeek"></div>}

        {/* User Week Tasks */}
        {userWeek.Tasks && <div className="homeUserWeek">
          {/* Label */}
          <div className="homeUserWeekLabel">
            <span className="iconTask iconsNav mr5"></span>
            <span className="">{Traduction.translate(language, 'your_tasks')}</span>
            {nbDelayed !== 0 && <span className=""><Badge className="badgeHome bg-red" pill>{nbDelayed}</Badge></span>}
            <span className=""><Badge className="badgeHome bg-grey" pill>{nbInProgress}</Badge></span>
          </div>
          {/* Tasks */}
          <div className="homeUserWeekInfo">
            {tasks.map((task, index) => 
              <div key={index} className="homeUserWeekTask">
                {/* Tasks Name */}
                <div className="homeUserWeekTaskLabel">
                  <a className="flex" target="_self" href={`/Card/Task/${task.Cells.find(cell => cell.ColumnName === 'Item_ID').Value.substring(1)}/Details`}><span className="iconTaskGrey verysmallIcons"></span></a>
                  <span className="flex fs12 ml5">{task.Cells.find(cell => cell.ColumnName === 'Name').Value}</span>
                </div>
                {/* Tasks Timeline */}
                <div className="homeUserWeekTaskTimeline">
                  <Timeline View={'Minicard'} StartDate={task.Cells.find(cell => cell.ColumnName === 'StartDate').Value} EndDate={task.Cells.find(cell => cell.ColumnName === 'EndDate').Value} Status={JSON.parse(task.Cells.find(cell => cell.ColumnName === 'Status').Value)}></Timeline>
                </div>
              </div>
            )}
            {/* Display All Tasks */}
            <div className="flex-end">
              {!display && userWeek.Tasks.Rows.length > 5 && <div className="homeDisplayAll bordered" onClick={() => this.changeDisplay(display)}>{Traduction.translate(language, 'display_all')}</div>}
              {display && userWeek.Tasks.Rows.length > 5 && <div className="homeDisplayAll bordered" onClick={() => this.changeDisplay(display)}>{Traduction.translate(language, 'display_less')}</div>}
            </div>
          </div>
        </div>}

        {/* User Week Decisions */}
        {userWeek.Decisions && <div className="homeUserWeek">
          {/* Label */}
          <div className="homeUserWeekLabel"><span className="iconDecisions iconsNav mr5"></span>{Traduction.translate(language, 'your_decisions')}</div>
          {/* Decisions */}
          <div className="homeUserWeekInfo">
            {userWeek.Decisions.Rows.map((decision, index) => 
              <div key={index} className="homeUserWeekEvents">
                <span className="iconDecisionsGrey verysmallIcons"></span>
                <span className="fs12 ml5">{decision.Cells.find(cell => cell.ColumnName === 'Name').Value}</span>
                {formatDate === 'MM/DD/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateEn(decision.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'DD/MM/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateFr(decision.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'YYYY-MM-DD' && <span className="fs12 ml5 blue bold">- {this.formatDateKr(decision.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
              </div>
            )}
          </div>
        </div>}

        {/* User Week Issues */}
        {userWeek.Issues && <div className="homeUserWeek">
          {/* Label */}
          <div className="homeUserWeekLabel"><span className="iconIssues iconsNav mr5"></span>{Traduction.translate(language, 'your_issues')}</div>
          {/* Issues */}
          <div className="homeUserWeekInfo">
            {userWeek.Issues.Rows.map((issue, index) => 
              <div key={index} className="homeUserWeekEvents">
                <span className="iconIssuesGrey verysmallIcons"></span>
                <span className="fs12 ml5">{issue.Cells.find(cell => cell.ColumnName === 'Name').Value}</span>
                {formatDate === 'MM/DD/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateEn(issue.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'DD/MM/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateFr(issue.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'YYYY-MM-DD' && <span className="fs12 ml5 blue bold">- {this.formatDateKr(issue.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
              </div>
            )}
          </div>
        </div>}

        {/* User Week Meetings */}
        {userWeek.Meetings && <div className="homeUserWeek">
          {/* Label */}
          <div className="homeUserWeekLabel"><span className="iconMeetings iconsNav mr5"></span>{Traduction.translate(language, 'your_meetings')}</div>
          {/* Meetings */}
          <div className="homeUserWeekInfo">
            {userWeek.Meetings.Rows.map((meeting, index) => 
              <div key={index} className="homeUserWeekEvents">
                <span className="iconMeetingsGrey verysmallIcons"></span>
                <span className="fs12 ml5">{JSON.parse(meeting.Cells.find(cell => cell.ColumnName === 'Meeting_Type').Value).Label}</span>
                {formatDate === 'MM/DD/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateEn(meeting.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'DD/MM/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateFr(meeting.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'YYYY-MM-DD' && <span className="fs12 ml5 blue bold">- {this.formatDateKr(meeting.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
              </div>
            )}
          </div>
        </div>}

        {/* User Week Risks */}
        {userWeek.Risks && <div className="homeUserWeek">
          {/* Label */}
          <div className="homeUserWeekLabel"><span className="iconRisks iconsNav mr5"></span>{Traduction.translate(language, 'your_risks')}</div>
          {/* Risks */}
          <div className="homeUserWeekInfo">
            {userWeek.Risks.Rows.map((risk, index) => 
              <div key={index} className="homeUserWeekEvents">
                <span className="iconRisksGrey verysmallIcons"></span>
                <span className="fs12 ml5">{risk.Cells.find(cell => cell.ColumnName === 'Name').Value}</span>
                {formatDate === 'MM/DD/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateEn(risk.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'DD/MM/YYYY' && <span className="fs12 ml5 blue bold">- {this.formatDateFr(risk.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
                {formatDate === 'YYYY-MM-DD' && <span className="fs12 ml5 blue bold">- {this.formatDateKr(risk.Cells.find(cell => cell.ColumnName === 'EndDate').Value)}</span>}
              </div>
            )}
          </div>
        </div>}

        {/* User Timetracking Title */}
        {userTimetracking && <div className="homeUserWeekLabel">
          <span className="iconTimeTrackingYellow icons icon nav mr10 mt-5"></span>
          <span className="homeLabel">{Traduction.translate(language, 'your_timetracking')}</span>
          {userTimetracking.Missing > 0 && <span className=""><Badge className="badgeHome bg-red" pill>{userTimetracking.Missing} {Traduction.translate(language, 'missing_days')}</Badge></span>}
        </div>}

        {/* User Timetracking Info */}
        {userTimetracking && <div className="homeUserWeek">
          {/* Timetracking Progress */}
          <div className="">
            <ProgressBar className="progressTimeTrackingHome" now={progress}>
              <ProgressBar className="" variant="info" now={(userTimetracking.Holidays*100)/total} key={1}/>
              <ProgressBar className="" variant="primary" now={(userTimetracking.Burned*100)/total} key={2}/>
              <ProgressBar className="" variant="light" now={(userTimetracking.Missing*100)/total} key={3}/>
            </ProgressBar>
          </div>
          {/* Timetracking Indicators */}
          <div className="homeTimetrackingIndicators">
            {/* Burned && Holidays */}
            <div className="row">
              <div className="col-md-6">
                <span className="fs20 blue bold">{userTimetracking.Burned}</span>
                <span className="fs12 ml5">{Traduction.translate(language, 'burned_days')}</span>
              </div>
              <div className="col-md-6">
                <span className="fs20 turquoise bold">{userTimetracking.Holidays}</span>
                <span className="fs12 ml5">{Traduction.translate(language, 'holidays_days')}</span>
              </div>
            </div>
            {/* Missing */}
            {/* <div className="row">
              <div className="col-md-6">
                <span className="fs20 red bold">{userTimetracking.Missing}</span>
                <span className="fs12 ml5">{labelMissing}</span>
              </div>
            </div> */}
          </div>
        </div>}
      </div>
    )
  }
}

export default IndicatorsUser;