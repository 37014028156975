import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersChart from './FiltersChart';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Table from './Table';
import Pivot from './Pivot';
import Chart from './Chart';

const API_info = '/WebAppService/GetCardBlockInformation';

class BlockWarnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      headers: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      search: '',
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.reorderColumns = this.reorderColumns.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Reference
    this.views = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.table = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });
      
      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);
    }
    if(viewId !== oldViewId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

      const columns = this.state.columns;
      const rows = this.state.rows;

      // Refresh Components with CurrentView
      if(currentView.ViewType === 0 && this.table.current) {
        this.table.current.refreshComponent(blockType, currentView, columns, rows);
      }
      else if(currentView.ViewType === 3 && this.pivot.current) {
        this.pivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.chart.current) {
        this.chart.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const headers = blockContent.Headers;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        let columns = [], rows = [];

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        this.setState({ blockContent, viewId: currentView.ViewId, currentView, headers, tables, columns, rows, chart, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getColumnsName() {
    let columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      // Exclude Column Index for creation (does not exist in Database)
      if(column.FieldName !== 'Index') {
        columnNames.push(column.FieldName);
      }
    });

    return columnNames;
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeView(view) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeFilters(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeSort(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeFormattings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeColumns(columnNames) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  reorderColumns(columnNames) {
    const { blockType, currentView, columns, rows } = this.state;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Refresh Component
    // if(this.table.current) {
    //   this.table.current.refreshComponent(blockType, currentView, columns, rows);
    // }

    this.setState({ currentView });
  }

  async applyChartSettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Chart and Update CurrentView
    if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }
  }

  displayChartSettings() {
    if(this.chart.current) {
      this.chart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.chart.current) {
      this.chart.current.closeSettings();
    }
  }

  filterColumn(columnName) {
    if(this.filters.current) {
      this.filters.current.openFiltersPopup(columnName);
    }
  }

  sortColumn(columnName) {
    if(this.sort.current) {
      this.sort.current.openSortPopup(columnName);
    }
  }

  exportCSV() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportCSV();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportXLSX();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportXLSX();
    }
  }

  exportJPEG() {
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportJPEG();
    }
  }

  exportPNG() {
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportPNG();
    }
  }

  exportPDF() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportPDF();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportPDF();
    }
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }
  
  updateErrors(err) {
    let errors = [];
    
    // Push the new Errors in the Errors Table
    errors.push(err);
    
    this.setState({ errors });
  }
  
  cleanErrors() {
    this.setState({ errors: [] });
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, editable, guestLicence, favorite, parents, warnings, displayViews, views, defaultViewId, currentView, headers, columns, rows, chart, search, isLoading, errors } = this.state;
    
    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'warnings')}</title></MetaTags>
        
        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Block Filters */}
                <FiltersBlock ref={this.filters} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                {/* Sort Filters */}
                <FiltersSort ref={this.sort} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                {/* Conditional Formatting Filters */}
                {currentView.ViewType !== 3 && 
                  <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>
                }

                {/* Chart Settings */}
                {currentView.ViewType === 7 && !guestLicence && 
                  <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                }

                {/* Columns Chooser */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1) && 
                  <FiltersColumnChooser ItemId={itemId} ItemType={itemType} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                }

                {/* Search input */}
                {(currentView.ViewType !== 3 && currentView.ViewType !== 7) && 
                  <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>
                }

                {/* Export */}
                {(currentView.ViewType === 0 || currentView.ViewType === 3 || currentView.ViewType === 7) && 
                  <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onJPEGExport={this.exportJPEG} onPNGExport={this.exportPNG} onPDFExport={this.exportPDF} onErrorsUpdate={this.updateErrors}></FiltersExport>
                }
              </div>
              
              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* View Components */}
                {currentView.ViewType === 0 && rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onColumnReorder={this.reorderColumns}></Table>}
                {currentView.ViewType === 3 && rows.length > 0 && <Pivot ref={this.pivot} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView}></Pivot>}
                {currentView.ViewType === 7 && <Chart ref={this.chart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockWarnings;