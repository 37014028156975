import React, {Component} from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import NotificationIcon from './NotificationIcon';
import NotificationTitle from './NotificationTitle';

const API = '/WebAppService/DeleteNotifications';

class NotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      parentId: null,
      parentType: null,
      parentName: null,
      user: null,
      message: null,
      link: null,
      date: null
    };

    this.clearNotification = this.clearNotification.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const parentId = this.props.ParentId;
    const parentType = this.props.ParentType;
    const parentName = this.props.ParentName;
    const user = this.props.User;
    const message = this.props.Message;
    const link = this.props.Link;
    const date = this.props.Date;

    this.setState({ login, authId, language, itemId, itemType, parentId, parentType, parentName, user, message, link, date });
  }

  async clearNotification(id) {
    const login = this.state.login;
    const authId = this.state.authId;
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ListNotificationIds': id
      })
    };
    
    try {
      const response = await fetch(API, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const valid = data.DeleteNotificationsResult;

      if(valid) {
        // Call the event from the Parent component through the props with id value
        this.props.onNotificationClean(id);
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  render() {
    const { itemId, itemType, parentId, parentType, parentName, user, message, link, date } = this.state;

    return (
      <Card className="notificationCard">
        {/* Header */}
        <Card.Header className="notificationCardHeader">
          <div className="notificationCardTitle">
            <NotificationIcon Type={parentType}></NotificationIcon>
            <NotificationTitle Title={parentName} Type={parentType}></NotificationTitle>
          </div>
          <div className="notificationCardDate">
            {date}
          </div>
          <div className="notificationCardClose">
            <span className="closeNotificationIcon iconClear icons cursor" onClick={(e) => this.clearNotification(itemId)}/>
          </div>
        </Card.Header>
        {/* Content */}
        <Card.Body className="notificationCardContent">
          {/* User */}
          <span className="bold turquoise">{user} : </span>
          {/* Message */}
          <span className="" dangerouslySetInnerHTML={{ __html: message }}></span>
          {/* Link */}
          {link && <Link target="_self" to={link} className="">{link}</Link>}
        </Card.Body>
      </Card>
    )
  }
}

export default NotificationCard;