import React, { Component } from 'react';

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: null
    }

    this.buildProgressBar = this.buildProgressBar.bind(this);
  }

  componentDidMount() {
    const progress = this.props.Progress;

    this.setState({progress});
  }

  componentDidUpdate(prevProps) {
    const progress = this.props.Progress;

    if(this.props.Progress !== prevProps.Progress) {
      this.setState({ progress });
    }
  }

  buildProgressBar(progress) {
    // If Progress is null, change it to 0
    if(!progress) {
      progress = "0";
    }
  
    // progress = (progress.replace(',', '.') * 100).toFixed(0);
  
    if(progress == 100) {
      // cardProgress = <ProgressBar className="progressBlock" variant="success" now={progress}></ProgressBar>;
      return (<div className="progressBarElement">
        <div className={"progress-bar bg-success"} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        <div className={"timelineLabel white"}>{progress + "%"}</div>
      </div>);
    }
    else if(progress < 100 && progress >= 0) {
      // cardProgress = <ProgressBar className="progressBlock" variant="primary" now={progress}></ProgressBar>;
      return (<div className="progressBarElement">
        <div className={"progress-bar bg-blue opacity60"} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        <div className={"timelineLabel black"}>{progress + "%"}</div>
      </div>);
    }
    else if(progress < 0) {
      return (<div className="progressBarElement">
        <div className={"progress-bar bg-red opacity60"} role="progressbar" style={{width: Math.abs(progress) + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        <div className={"timelineLabel black"}>{progress + "%"}</div>
      </div>);
    }
    else {
      return (<div className="progressBarElement">
        <div className={"progress-bar bg-red opacity60"} role="progressbar" style={{width: progress + "%"}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        <div className={"timelineLabel white"}>{progress + "%"}</div>
      </div>);
    }
  }
  
  render() {
    const progress = this.state.progress;
    
    return this.buildProgressBar(progress);
  }
}

export default Progress;