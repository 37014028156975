import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverEditColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      columnName: null,
      oldValue: null,
      colorValue: ''
    };

    this.beforeCircleTileRender = this.beforeCircleTileRender.bind(this);
    this.change = this.change.bind(this);
    this.changeColor = this.changeColor.bind(this);
    // this.isColorValid = this.isColorValid.bind(this);
    this.isHexColor = this.isHexColor.bind(this);
    this.updateColor = this.updateColor.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
    const oldValue = this.props.ColorValue;
    const colorValue = this.props.ColorValue;

    this.setState({ language, itemId, itemType, columnName, oldValue, colorValue });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const columnName = this.props.ColumnName;
    const oldValue = this.props.ColorValue;
    const colorValue = this.props.ColorValue;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.ColorValue !== prevProps.ColorValue) {
    //   this.setState({ columnName, colorValue });
    // }
  }

  beforeCircleTileRender(args) {
    args.element.classList.add("e-circle-palette");
  }

  change(args) {
    const newValue = args.currentValue.hex;

    this.setState({ colorValue: newValue });
  }

  changeColor(event) {
    const newValue = event.target.value;

    if(newValue.length <= 7) {
      this.setState({ colorValue: newValue });
    }
  }

  // isColorValid(color){
  //   var s = new Option().style;
  //   s.color = color;

  //   return s.color == color;
  // }

  isHexColor(color) {
    return typeof color === 'string' && color.length === 6 && !isNaN(Number('0x' + color));
  }

  updateColor(itemId, itemType, columnName, oldValue, newValue) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }
    
    if(this.isHexColor(newValue.substring(1))) {
      // Call the event from the Parent component through the props
      this.props.onColorEdit(itemId, itemType, columnName, oldValue, newValue);
    }
  }

  render() {
    const { language, itemId, itemType, columnName, oldValue, colorValue } = this.state;

    // Palette Colors
    this.paletteColors = { "custom": ['#E21313', '#E91E63', '#9C27B0', '#9E5FFF', '#2196F3', '#0088C7', '#00BCD4','#26A599', '#00C77A', '#FABD05', '#FFC107', '#F57C12', '#FFFFFF', '#B1B2BE', '#8E8E90', '#050A2F'] };
    // this.paletteColors = { "custom": ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107'] };

    return (
      <div className="axeEditPopover">
        {/* Color Picker */}
        <div className="colorPreview">
          {/* Color Preview */}
          <div className="axisColor mr10" style={{ background: colorValue, border: '1px solid', borderColor: '#8E8E90' }}></div>
          {/* Color Input */}
          <Form.Group className="filterTypeColor">
            <Form.Control id="" className="filterTypeColorValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={colorValue} onChange={(e) => this.changeColor(e)} />
          </Form.Group>
        </div>
        {/* Color Palette */}
        <div className="e-circle-wrap">
          <ColorPickerComponent id="circle-palette" mode="Palette" columns={4} presetColors={this.paletteColors} inline={true} modeSwitcher={false} showButtons={false} beforeTileRender={this.beforeCircleTileRender} change={this.change}></ColorPickerComponent>
        </div>
        {/* Button Validate */}
        <div className="flex flex-end align-items-center">
          <Button className="fs12 bold brd-radius mr14" variant="primary" onClick={() => this.updateColor(itemId, itemType, columnName, oldValue, colorValue)}>{Traduction.translate(language, 'validate')}</Button>
        </div>
      </div>
    );
  }
}

export default PopoverEditColor;