import React, {Component} from 'react';
import '../Css/App.css';
import MiniTableHolidays from './MiniTableHolidays';

class MiniBlockHolidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      columns: [],
      rows: []
    };

    this.buildBlockData = this.buildBlockData.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let columns = [], rows = [];

    if(blockContent) {
      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      this.setState({ columns, rows });
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, blockType, blockLabel, blockContent, columns, rows } = this.state;

    return (
      <div className="miniblock">
        <div className="miniblockHolidays">
          {columns && rows.length > 0 && <div className="miniblockTable">
            <MiniTableHolidays ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Columns={columns} Rows={rows}></MiniTableHolidays>
          </div>}
        </div>
      </div>
    )
  }
}

export default MiniBlockHolidays;