import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class BudgetDoughnut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      columns: [],
      rows: [],
      budget: 0,
      burned: 0,
      workload: 0,
      allocated: 0,
      notAllocated:0,
      burnedPercentage: 0,
      allocatedPercentage:0,
      budgetUnit: { Value: "€", Label: "€" }
    };

    // Data Structure
    this.buildBudgetDetails = this.buildBudgetDetails.bind(this);
    this.buildBudgetData = this.buildBudgetData.bind(this);

    // Actions
    this.refreshDoughnut = this.refreshDoughnut.bind(this);

    // Template
    this.templateBudgetIndicators = this.templateBudgetIndicators.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Budget Doughnut
    this.buildBudgetData(currentView, rows);

    this.setState({ language, currentView, columns, rows });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(JSON.stringify(this.props.CurrentView) != JSON.stringify(prevProps.CurrentView) || JSON.stringify(this.props.Columns) != JSON.stringify(prevProps.Columns) || JSON.stringify(this.props.Rows) != JSON.stringify(prevProps.Rows)) {
      // Build Budget Doughnut
      this.buildBudgetData(currentView, rows);

      this.setState({ currentView, columns, rows });
    }
  }

  // Build Doughnut Data based on Budget Table
  buildBudgetData(currentView, rows) {
    let budget = 0, burned = 0, workload = 0;
    let allocated = 0, notAllocated = 0;
    let burnedPercentage = 0, allocatedPercentage = 0;
    let budgetUnit = { Value: "€", Label: "€" };

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'DoughtnutBudgetUnit')) {
      budgetUnit = currentView.Parameters.find(param => param.Name === 'DoughtnutBudgetUnit');
    }

    // Budget Unit Euro
    if(budgetUnit.Value === "€") {
      rows.forEach(row => {
        if(row.Cells) {
          // Exclude Childs (already consolidated in Parent)
          if(!row.Cells.find(cell => cell.ColumnName === "Parent_ID")) {
            // Budget
            if(row.Cells.find(cell => cell.ColumnName === "Actual_Amount")) {
              if(row.Cells.find(cell => cell.ColumnName === "Actual_Amount").Value) {
                // Sum Values of all Rows
                budget = budget + parseInt(row.Cells.find(cell => cell.ColumnName === "Actual_Amount").Value);
              }
            }
            // Burned
            if(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost")) {
              if(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost").Value) {
                burned = burned + parseInt(row.Cells.find(cell => cell.ColumnName === "Global_Burned_Workload_Cost").Value);
              }
            }
            // Workload
            if(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost")) {
              if(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost").Value) {
                workload = workload + parseInt(row.Cells.find(cell => cell.ColumnName === "Global_Workload_Cost").Value);
              }
            }
            // Allocated
            if(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_Cost")) {
              if(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_Cost").Value) {
                allocated = allocated + parseInt(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_Cost").Value);
              }
            }
            // Not Allocated
            if(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_Cost")) {
              if(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_Cost").Value) {
                notAllocated = notAllocated + parseInt(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_Cost").Value);
              }
            }
          }
        }
      });
    }
    // Budget Unit Man Days
    else if(budgetUnit.Value === "Mandays") {
      rows.forEach(row => {
        if(row.Cells) {
          // Exclude Childs (already consolidated in parent)
          if(!row.Cells.find(cell => cell.ColumnName === "Parent_ID")) {
            // Budget
            if(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays")) {
              if(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays").Value) {
                // Sum Values of all Rows
                budget = budget + parseInt(row.Cells.find(cell => cell.ColumnName === "Actual_ManDays").Value);
              }
            }
            // Burned
            if(row.Cells.find(cell => cell.ColumnName === "Burned_Workload")) {
              if(row.Cells.find(cell => cell.ColumnName === "Burned_Workload").Value) {
                burned = burned + parseInt(row.Cells.find(cell => cell.ColumnName === "Burned_Workload").Value);
              }
            }
            // Workload
            if(row.Cells.find(cell => cell.ColumnName === "Workload")) {
              if(row.Cells.find(cell => cell.ColumnName === "Workload").Value) {
                workload = workload + parseInt(row.Cells.find(cell => cell.ColumnName === "Workload").Value);
              }
            }
            // Allocated
            if(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_ManDays")) {
              if(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_ManDays").Value) {
                allocated = allocated + parseInt(row.Cells.find(cell => cell.ColumnName === "Allocated_Budget_ManDays").Value);
              }
            }
            // Not Allocated
            if(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_ManDays")) {
              if(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_ManDays").Value) {
                notAllocated = notAllocated + parseInt(row.Cells.find(cell => cell.ColumnName === "Not_Allocated_Budget_ManDays").Value);
              }
            }
          }
        }
      });
    }

    // Burned percentage calculation
    if(burned != 0 && budget != 0) {
      burnedPercentage = Math.round(burned * 100 / budget);
    }
    // Allocated percentage calculation
    if(budget != 0) {
      allocatedPercentage = Math.round(allocated * 100 / budget);
    }
    
    this.setState({ budgetUnit, burned, budget, workload, allocated, notAllocated, burnedPercentage, allocatedPercentage });
  }

  buildBudgetDetails() {
    const { language, columns, budget, burned, workload, allocated, notAllocated, budgetUnit } = this.state;
    let budgetValue, workloadValue, burnedValue, unit, burnedUnit, workloadUnit, overrunBurned, overrunWorkload, allocatedUnit, allocatedValue,  notAllocatedValue;
    let labelBudget, labelBurned, labelWorkload, labelOverrun, labelAllocated, labelNotAllocated;
    
    // Budget Unit Euro
    if(budgetUnit.Value === "€") {
      // Budget
      if(columns.find(element => element.FieldName === 'Actual_Amount')) {
        unit = columns.find(element => element.FieldName === 'Actual_Amount').Unit;
        labelBudget = columns.find(element => element.FieldName === 'Actual_Amount').Label;

        if(budget < 1000) {
          budgetValue = parseFloat(budget.toFixed(2)) + ' ' + unit;
        }
        else if(budget >= 1000 && budget < 1000000) {
          budgetValue = parseFloat((budget / 1000).toFixed(2)) + ' k' + unit;
        }
        else {
          budgetValue = parseFloat((budget / 1000000).toFixed(2)) + ' M' + unit;
        }
      }
      // Burned
      if(columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost')) {
        burnedUnit = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Unit;
        labelBurned = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Label;

        if(burned < 1000) {
          burnedValue = parseFloat(burned.toFixed(2)) + ' ' + burnedUnit;
        }
        else if(burned >= 1000 && burned < 1000000) {
          burnedValue = parseFloat((burned / 1000).toFixed(2)) + ' k' + burnedUnit;
        }
        else {
          burnedValue = parseFloat((burned / 1000000).toFixed(2)) + ' M' + burnedUnit;
        }
      }
      // Workload
      if(columns.find(element => element.FieldName === 'Global_Workload_Cost')) {
        workloadUnit = columns.find(element => element.FieldName === 'Global_Workload_Cost').Unit;
        labelWorkload = columns.find(element => element.FieldName === 'Global_Workload_Cost').Label;

        if(workload < 1000) {
          workloadValue = parseFloat(workload.toFixed(2)) + ' ' + workloadUnit;
        }
        else if(workload >= 1000 && workload < 1000000) {
          workloadValue = parseFloat((workload / 1000).toFixed(2)) + ' k' + workloadUnit;
        }
        else {
          workloadValue = parseFloat((workload / 1000000).toFixed(2)) + ' M' + workloadUnit;
        }
      }
      // Allocated
      if(columns.find(element => element.FieldName === 'Allocated_Budget_Cost')) {
        allocatedUnit = columns.find(element => element.FieldName === 'Allocated_Budget_Cost').Unit;
        labelAllocated = columns.find(element => element.FieldName === 'Allocated_Budget_Cost').Label;

        if(allocated < 1000) {
          allocatedValue = parseFloat(allocated.toFixed(2)) + ' ' + allocatedUnit;
        }
        else if(allocated >= 1000 && allocated < 1000000) {
          allocatedValue = parseFloat((allocated / 1000).toFixed(2)) + ' k' + allocatedUnit;
        }
        else {
          allocatedValue = parseFloat((allocated / 1000000).toFixed(2)) + ' M' + allocatedUnit;
        }
      }
      // Not Allocated
      if(columns.find(element => element.FieldName === 'Not_Allocated_Budget_Cost')) {
        labelNotAllocated = columns.find(element => element.FieldName === 'Not_Allocated_Budget_Cost').Label;

        if(notAllocated < 1000) {
          notAllocatedValue = parseFloat(notAllocated.toFixed(2)) + ' ' + allocatedUnit;
        }
        else if(notAllocated >= 1000 && notAllocated < 1000000) {
          notAllocatedValue = parseFloat((notAllocated / 1000).toFixed(2)) + ' k' + allocatedUnit;
        }
        else {
          notAllocatedValue = parseFloat((notAllocated / 1000000).toFixed(2)) + ' M' + allocatedUnit;
        }
      }
    }
    // Budget Unit Man Days
    else if(budgetUnit.Value === "Mandays") {
      // Budget
      if(columns.find(element => element.FieldName === 'Actual_ManDays')) {
        unit = columns.find(element => element.FieldName === 'Actual_ManDays').Unit;
        labelBudget = columns.find(element => element.FieldName === 'Actual_ManDays').Label;

        if(budget < 1000) {
          budgetValue = parseFloat(budget.toFixed(2)) + ' ' + unit;
        }
        else if(budget >= 1000 && budget < 1000000) {
          budgetValue = parseFloat((budget / 1000).toFixed(2)) + ' k' + unit;
        }
        else {
          budgetValue = parseFloat((budget / 1000000).toFixed(2)) + ' M' + unit;
        }
      }
      // Burned
      if(columns.find(element => element.FieldName === 'Burned_Workload')) {
        burnedUnit = columns.find(element => element.FieldName === 'Burned_Workload').Unit;
        labelBurned = columns.find(element => element.FieldName === 'Burned_Workload').Label;

        if(burned < 1000) {
          burnedValue = parseFloat(burned.toFixed(2)) + ' ' + burnedUnit;
        }
        else if(burned >= 1000 && burned < 1000000) {
          burnedValue = parseFloat((burned / 1000).toFixed(2)) + ' k' + burnedUnit;
        }
        else {
          burnedValue = parseFloat((burned / 1000000).toFixed(2)) + ' M' + burnedUnit;
        }
      }
      // Workload
      if(columns.find(element => element.FieldName === 'Workload')) {
        workloadUnit = columns.find(element => element.FieldName === 'Workload').Unit;
        labelWorkload = columns.find(element => element.FieldName === 'Workload').Label;

        if(workload < 1000) {
          workloadValue = parseFloat(workload.toFixed(2)) + ' ' + workloadUnit;
        }
        else if(workload >= 1000 && workload < 1000000) {
          workloadValue = parseFloat((workload / 1000).toFixed(2)) + ' k' + workloadUnit;
        }
        else {
          workloadValue = parseFloat((workload / 1000000).toFixed(2)) + ' M' + workloadUnit;
        }
      }
      // Allocated
      if(columns.find(element => element.FieldName === 'Allocated_Budget_ManDays')) {
        allocatedUnit = columns.find(element => element.FieldName === 'Allocated_Budget_ManDays').Unit;
        labelAllocated = columns.find(element => element.FieldName === 'Allocated_Budget_ManDays').Label;

        if(allocated < 1000) {
          allocatedValue = parseFloat(allocated.toFixed(2)) + ' ' + allocatedUnit;
        }
        else if(allocated >= 1000 && allocated < 1000000) {
          allocatedValue = parseFloat((allocated / 1000).toFixed(2)) + ' k' + allocatedUnit;
        }
        else {
          allocatedValue = parseFloat((allocated / 1000000).toFixed(2)) + ' M' + allocatedUnit;
        }
      }
      // Not Allocated
      if(columns.find(element => element.FieldName === 'Not_Allocated_Budget_ManDays')) {
        labelNotAllocated = columns.find(element => element.FieldName === 'Not_Allocated_Budget_ManDays').Label;

        if(notAllocated < 1000) {
          notAllocatedValue = parseFloat(notAllocated.toFixed(2)) + ' ' + allocatedUnit;
        }
        else if(notAllocated >= 1000 && notAllocated < 1000000) {
          notAllocatedValue = parseFloat((notAllocated / 1000).toFixed(2)) + ' k' + allocatedUnit;
        }
        else {
          notAllocatedValue = parseFloat((notAllocated / 1000000).toFixed(2)) + ' M' + allocatedUnit;
        }
      }
    }

    // Overrun
    labelOverrun = Traduction.translate(language, 'overrun');

    if(burned > budget) {
      overrunBurned = true;

      if(workload > budget) {
        overrunWorkload = true;
      } 
      else {
        overrunWorkload = false;
      }   
    }
    else {
      overrunBurned = false;

      if(workload > budget) {
        overrunWorkload = true;
      }
      else {
        overrunWorkload = false;
      }
    }
    
    return this.templateBudgetIndicators(budgetValue, labelBudget, burnedValue, labelBurned, workloadValue, labelWorkload, overrunBurned, overrunWorkload, labelOverrun, allocatedValue, labelAllocated, notAllocatedValue, labelNotAllocated);
  }

  refreshDoughnut(currentView, rows) {
    // Build Budget Doughnut
    this.buildBudgetData(currentView, rows);
  }

  templateBudgetIndicators(budget, labelBudget, burned, labelBurned, workload, labelWorkload, overrunBurned, overrunWorkload, labelOverrun, allocated,labelAllocated,notAllocated,labelNotAllocated) {
    return (<div className="">
      <div className="blockBudgetIndicators">
        {/* Budget */}
        <div className="budgetDetailsElement budgetDetailsBudgetBorder mv10">
          <div className="blockBudgetIndicatorValue">{budget}</div>
          <div className="blockBudgetIndicatorLabel">{labelBudget}</div>
        </div>
        {/* Burned */}
        <div className={(overrunBurned ? "budgetDetailsOverBurnedBorder" : "budgetDetailsBurnedBorder") + " budgetDetailsElement mv10"}>
          <div className="blockBudgetIndicatorValue">{burned}</div>
          <div className="blockBudgetIndicatorLabel">{labelBurned}</div>
          {overrunBurned && <div className="axe brd-radius bg-red white mt3">{labelOverrun}</div>}
        </div>
        {/* Workload */}
        <div className={(overrunWorkload ? "budgetDetailsOverWorkloadBorder" : "budgetDetailsWorkloadBorder") + " budgetDetailsElement mv10"}>
          <div className="blockBudgetIndicatorValue">{workload}</div>
          <div className="blockBudgetIndicatorLabel">{labelWorkload}</div>
          {overrunWorkload && <div className="axe brd-radius bg-red white mt3">{labelOverrun}</div>}
        </div>
      </div>
      <div className="blockBudgetIndicators">
        {/* Allocated */}
        <div className="budgetDetailsElement budgetDetailsBudgetBorder mt10 mb5">
          <div className="blockBudgetIndicatorValue">{allocated}</div>
          <div className="blockBudgetIndicatorLabel">{labelAllocated}</div>
        </div>
        {/* Not Allocated */}
        <div className="budgetDetailsElement budgetDetailsNotAllocatedBorder mt10 mb5">
          <div className="blockBudgetIndicatorValue">{notAllocated}</div>
          <div className="blockBudgetIndicatorLabel">{labelNotAllocated}</div>
        </div>
      </div>
    </div>);
    // return <div className="">{" burned " + burned + " Buddget " + budget + "workload :" + workload }</div>;
  }

  render() {
    const { language, currentView, columns, rows, budget, burned, workload, burnedPercentage, budgetUnit } = this.state;
    let labelBurned, padding;

    // Label Burned
    if(budgetUnit.Value === "€") {
      if(columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost')) {
        labelBurned = columns.find(element => element.FieldName === 'Global_Burned_Workload_Cost').Label;
      }
    }
    else if(budgetUnit.Value === "Mandays") {
      if(columns.find(element => element.FieldName === 'Burned_Workload')) {
        labelBurned = columns.find(element => element.FieldName === 'Burned_Workload').Label;
      }
    }

    // Doughnut Padding
    if(window.innerWidth > 1700) {
      padding = 50;
    }
    else if(window.innerWidth > 1400) {
      padding = 30;
    }
    else if(window.innerWidth > 1000) {
      padding = 20;
    }
    else if(window.innerWidth > 800) {
      padding = 10;
    }
    else {
      padding = 0;
    }

    // Doughnut Component
    Chart.register(ArcElement);
    
    // Doughnut ChartColor
    if(burned > budget) {
      this.colors = ["#E21313"];
    }
    else {
      if(workload > budget) {
        this.colors = ["#336699", "#E21313"];
      }
      else {
        this.colors = ["#336699", "#41B2E7", '#D5D5D7'];
      }
    }

    // Doughnut Data
    if(burned > budget) {
      this.data = { datasets: [{ data: [100], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: true };
    }
    else {
      if(workload > budget) {
        this.data = { datasets: [{ data: [burned, workload-burned], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: true };
      }
      else {
        this.data = { datasets: [{ data: [burned, workload-burned, budget-workload], backgroundColor: this.colors }], maintainAspectRatio: false, responsive: true };
      }
    }

    // Doughnut Options
    this.doughnutOptions = { legend: { display: false, position: "right" }, layout: { padding: padding }, elements: { arc:{ borderWidth: 0 }} };

    return (
      <div className="flex flex-column">
        <div className="budgetDoughnut">
          {/* Doughnut */}
          <Doughnut data={this.data} options={this.doughnutOptions}></Doughnut>
          {/* Labels */}
          <div className="blockBudgetDoughnutValue">
            <div className="blockBudgetDoughnutPercentage">{burnedPercentage + " %"}</div>
            <div className="blockBudgetDoughnutUnit">{labelBurned}</div>
          </div>
        </div>
        {/* Budget Details */}
        <div className="blockBudgetDetails">{this.buildBudgetDetails()}</div>
      </div>
    )
  }
}

export default BudgetDoughnut;