import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

const API_save = '/WebAppService/SaveView';

class FiltersCurrentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {}
    };

    this.displayViews = this.displayViews.bind(this);
    this.saveView = this.saveView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  displayViews() {
    // Call the event from the Parent component through the props
    this.props.onDisplayViews();
  }

  async saveView(view, saveAs) {
    const { login, authId, language } = this.state;

    // Update HTML element class
    if(document.getElementById("saveView")) {
      document.getElementById("saveView").classList.remove("cursor");
      document.getElementById("saveView").classList.add("wait");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CurrentView': view,
        'SaveAs': saveAs
      })
    };

    try{
      const response = await fetch(API_save, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveViewResult;

      if(result) {
        if(result.IsValid === true) {
          // Reset CurrentView Modify attribute to false
          view.Modify = false;

          this.setState({ currentView: view });
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

      // Update HTML element class
      if(document.getElementById("saveView")) {
        document.getElementById("saveView").classList.remove("wait");
        document.getElementById("saveView").classList.add("cursor");
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  updateErrors(error) {
    // Call the event from the Parent component through the props
    this.props.onErrorsUpdate(error);
  }
  
  render() {
    const { language, itemId, itemType, blockType, currentView } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'display_views')}</strong></Tooltip>}>
            <div className="currentViewName cursor">
              {/* Display Views */}
              <span className="iconDisplayViews iconsPopover mr10 cursor" onClick={() => this.displayViews()}></span>
              {/* ViewType Icon */}
              {currentView.ViewType === 0 && <span className="iconViewTypeTable iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 1 && <span className="iconViewTypePlanning iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 2 && <span className="iconViewTypeKanban iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 3 && <span className="iconViewTypePivot iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 4 && <span className="iconViewTypeList iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 6 && <span className="iconViewTypeScheduler iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 7 && <span className="iconViewTypeChart iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {currentView.ViewType === 8 && <span className="iconViewTypeMap iconsFilter mr10" onClick={() => this.displayViews()}></span>}
              {/* View Name */}
              <span className="fs12 bold" onClick={() => this.displayViews()}>{currentView.Name}</span>
              {/* Save View */}
              {currentView.Modify && <span id="saveView" className="iconSave iconsViews cursor ml10" onClick={() => this.saveView(currentView, false)}></span>}
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersCurrentView;