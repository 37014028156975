import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersTable from './FiltersTable';
import PlanningTimeline from './PlanningTimeline';
import FiltersPlanning from './FiltersPlanning';
import FiltersKanban from './FiltersKanban';
import FiltersChart from './FiltersChart';
import FiltersColumnChooser from './FiltersColumnChooser';
import FiltersDisplayComponent from './FiltersDisplayComponent';
import FiltersImport from './FiltersImport';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import PopupMultipleModification from './PopupMultipleModification';
import Table from './Table';
import Kanban from './Kanban';
import Planning from './Planning';
import Pivot from './Pivot';
import Chart from './Chart';
import MeetingCalendar from './MeetingCalendar';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';
const API_create = '/WebAppService/InsertItemAndSave';
const API_delete = '/WebAppService/DeleteItemAndSave';

class BlockMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      blockContent: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      headers: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      multipleModificationPopup: false,
      columnName: null,
      search: '',
      visibleComponent: true,
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);

    // Actions
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changeAxe = this.changeAxe.bind(this);
    this.changeKeyField = this.changeKeyField.bind(this);
    this.changeColumns = this.changeColumns.bind(this);
    this.reorderColumns = this.reorderColumns.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    // this.applyTableFilters = this.applyTableFilters.bind(this);
    this.applyTableSettings = this.applyTableSettings.bind(this);
    this.applyPlanningZoomAuto = this.applyPlanningZoomAuto.bind(this);
    this.applyPlanningTimeline = this.applyPlanningTimeline.bind(this);
    this.applyPlanningSettings = this.applyPlanningSettings.bind(this);
    this.refreshPlanning = this.refreshPlanning.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.openMultipleModificationPopup = this.openMultipleModificationPopup.bind(this);
    this.multipleModificationColumn = this.multipleModificationColumn.bind(this);
    this.checkModification = this.checkModification.bind(this);
    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.applyPropagations = this.applyPropagations.bind(this);
    this.addRowsInTable = this.addRowsInTable.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.importData = this.importData.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
    this.closeMultipleModificationPopup = this.closeMultipleModificationPopup.bind(this);

    // Reference
    this.views = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.table = React.createRef();
    this.kanban = React.createRef();
    this.planning = React.createRef();
    this.pivot = React.createRef();
    this.chart = React.createRef();
    this.calendar = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }

    this.setState({ login, authId, language, itemId, itemType, blockType, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // Get Params from Query string url
    let params, old_params, viewId, oldViewId;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      viewId = parseInt(params.viewId);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      oldViewId = parseInt(old_params.viewId);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });
      
      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId);
    }
    if(viewId !== oldViewId) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

      const blockContent = this.state.blockContent;
      const columns = this.state.columns;
      const rows = this.state.rows;

      // Refresh Components with CurrentView
      if(currentView.ViewType === 0 && this.table.current) {
        this.table.current.refreshComponent(blockType, currentView, columns, rows);
      }
      else if(currentView.ViewType === 1 && this.planning.current) {
        this.planning.current.refreshComponent(blockType, currentView, columns, rows);
      }
      else if(currentView.ViewType === 2 && this.kanban.current) {
        this.kanban.current.refreshKanban(currentView, blockContent.Kanban);
      }
      else if(currentView.ViewType === 3 && this.pivot.current) {
        this.pivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.chart.current) {
        this.chart.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};

        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const headers = blockContent.Headers;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        let columns = [], rows = [];
        let visibleComponent;

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        // Initialise Visible Component
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'VisibleComponent')) {
          visibleComponent = this.convertStringtoBoolean(currentView.Parameters.find(param => param.Name === 'VisibleComponent').Value);
        }
        else {
          visibleComponent = true;
        }

        this.setState({ blockContent, viewId: currentView.ViewId, currentView, headers, tables, columns, rows, chart, visibleComponent, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getColumnsName() {
    const columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      // Exclude Column Index for creation (does not exist in Database)
      if(column.FieldName !== 'Index') {
        columnNames.push(column.FieldName);
      }
    });

    return columnNames;
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeView(view) {
    const { itemId, itemType, blockType } = this.state;

    // Redirect with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeFilters(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
    else if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeSort(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
    else if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeFormattings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
    else if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async changeAxe(axe) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Update Current View Axe
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1')) {
      currentView.Parameters.find(param => param.Name === 'Axe1').Value = axe;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateAxe(axe);
    // }
  }

  async changeKeyField(keyField) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Update Current View KeyField
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2')) {
      currentView.Parameters.find(param => param.Name === 'Axe2').Value = keyField;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Update Axe in Kanban component
    // if(this.kanban.current) {
    //   this.kanban.current.updateKeyField(keyField);
    // }
  }

  async changeColumns(columnNames) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
    else if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  reorderColumns(columnNames) {
    const { blockType, currentView, columns, rows } = this.state;

    // Update Current View Columns list
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Refresh Component
    // if(this.table.current) {
    //   this.table.current.refreshComponent(blockType, currentView, columns, rows);
    // }

    this.setState({ currentView });
  }

  async changeDisplay() {
    const { login, authId, itemId, itemType, blockType, currentView, search } = this.state;
    let visibleComponent;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
      // Hide Calendar
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === "true") {
        currentView.Parameters.find(param => param.Name === "VisibleComponent").Value = "false";
        visibleComponent = false;
      }
      // Display Calendar
      else if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === "false") {
        currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === "VisibleComponent"), 1);
        visibleComponent = true;
      }
    } 
    // Hide Calendar
    else {
      currentView.Parameters.push({ Name: "VisibleComponent", Value: "false" });
      visibleComponent = false;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ visibleComponent });

    // Apply Table Filters
    // this.applyTableFilters(currentView, search);
  }

  // applyTableFilters(view, search) {
  //   const currentView = this.state.currentView;
  //   const rows = this.state.rows;
  //   let visibleColumns = [], filtered = [];

  //   // Get Visible Columns
  //   if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
  //     visibleColumns = currentView.Parameters.find(param => param.Name === 'Columns').Value;
  //   }

  //   // Apply Search on Visible Columns
  //   if(search !== '') {
  //     rows.forEach(row => {
  //       if(row.Cells.find(cell => cell.Value.toLowerCase().includes(search.toLowerCase()) && visibleColumns.includes(cell.ColumnName))) {
  //         filtered.push(row);
  //       }
  //     });
  //   }
  //   else {
  //     filtered = rows;
  //   }

  //   // Apply Filters on Rows
  //   view.Filters.forEach(filter => {
  //     // Create list of applied Filters
  //     let appliedFilters;

  //     // Get Filter Column Type 
  //     const columnTypeFilter = this.getColumnType(filter.Field);

  //     // Apply Filters
  //     if(filter.Operator === "equal") {
  //       appliedFilters = filter.Items.split(',');

  //       if(columnTypeFilter === 'Axe' || columnTypeFilter === 'Resource' || columnTypeFilter === 'Meteo' || columnTypeFilter === 'Trend' || columnTypeFilter === 'Business_Line' || columnTypeFilter === 'Project' || columnTypeFilter === 'Workpackage' || columnTypeFilter === 'Action' || columnTypeFilter === 'Task' || columnTypeFilter === 'Entity') {
  //         filtered = filtered.filter(row => appliedFilters.includes(JSON.parse(row.Cells.find(cell => cell.ColumnName === filter.Field).Value).Label));
  //       }
  //       else {
  //         filtered = filtered.filter(row => appliedFilters.includes(row.Cells.find(cell => cell.ColumnName === filter.Field).Value));
  //       }
  //     }
  //     else if(filter.Operator === "notequal") {
  //       appliedFilters = filter.Items.split(',');

  //       if(columnTypeFilter === 'Axe' || columnTypeFilter === 'Resource' || columnTypeFilter === 'Meteo' || columnTypeFilter === 'Trend' || columnTypeFilter === 'Business_Line' || columnTypeFilter === 'Project' || columnTypeFilter === 'Workpackage' || columnTypeFilter === 'Action' || columnTypeFilter === 'Task' || columnTypeFilter === 'Entity') {
  //         filtered = filtered.filter(row => !appliedFilters.includes(JSON.parse(row.Cells.find(cell => cell.ColumnName === filter.Field).Value).Label));
  //       }
  //       else {
  //         filtered = filtered.filter(row => !appliedFilters.includes(row.Cells.find(cell => cell.ColumnName === filter.Field).Value));
  //       }
  //     }
  //     else if(filter.Operator === "lessthan") {
  //       appliedFilters = filter.Items;

  //       filtered = filtered.filter(row => row.Cells.find(cell => cell.ColumnName === filter.Field).Value < appliedFilters);
  //     }
  //     else if(filter.Operator === "lessthanorequal") {
  //       appliedFilters = filter.Items;

  //       filtered = filtered.filter(row => row.Cells.find(cell => cell.ColumnName === filter.Field).Value <= appliedFilters);
  //     }
  //     else if(filter.Operator === "greaterthan") {
  //       appliedFilters = filter.Items;

  //       filtered = filtered.filter(row => row.Cells.find(cell => cell.ColumnName === filter.Field).Value > appliedFilters);
  //     }
  //     else if(filter.Operator === "greaterthanorequal") {
  //       appliedFilters = filter.Items;

  //       filtered = filtered.filter(row => row.Cells.find(cell => cell.ColumnName === filter.Field).Value >= appliedFilters);
  //     }
  //   });

  //   // Refresh Calendar Meetings with Filters applied
  //   if(this.calendar.current) {
  //     this.calendar.current.refreshCalendar(filtered);
  //   }
  // }

  async applyTableSettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Apply Setting on Table
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  applyPlanningZoomAuto(currentView) {
    const { blockType, columns, rows } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Apply Setting on Planning and Update CurrentView
    if(this.planning.current) {
      this.planning.current.zoomAuto(blockType, currentView, columns, rows);
    }

    this.setState({ currentView });
  }

  applyPlanningTimeline(currentView) {
    const { blockType, columns, rows } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Apply Setting on Planning and Update CurrentView
    if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }

    this.setState({ currentView });
  }

  async applyPlanningSettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Apply Setting on Planning
    if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async refreshPlanning(login, authId, itemId, itemType, blockType, currentView) {
    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Apply Setting on Planning
    if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async applyChartSettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    // Apply Setting on Chart
    if(this.chart.current) {
      this.chart.current.applySettings(currentView);
    }
  }

  displayChartSettings() {
    if(this.chart.current) {
      this.chart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.chart.current) {
      this.chart.current.closeSettings();
    }
  }

  filterColumn(columnName) {
    if(this.filters.current) {
      this.filters.current.openFiltersPopup(columnName);
    }
  }

  sortColumn(columnName) {
    if(this.sort.current) {
      this.sort.current.openSortPopup(columnName);
    }
  }

  openMultipleModificationPopup(columnName) {
    this.setState({ multipleModificationPopup: true, columnName });
  }

  async multipleModificationColumn() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language } = this.state;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            // Update Rows in Components DataSource
            if(this.table.current) {
              this.table.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.planning.current) {
              this.planning.current.updateDatasource(modification, propagations, warnings);
            }
            else if(this.kanban.current) {
              this.kanban.current.updateDatasource(modification, propagations, warnings);
            }

            // Update Rows in Data Table
            this.updateRowsInTable(modification, propagations, warnings);
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }
            else if(this.planning.current) {
              this.planning.current.cancelModification(modification);
            }
            else if(this.kanban.current) {
              // this.kanban.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async createItem(name, itemType, templateId, sameParent) {
    const { login, authId, language } = this.state;
    let parentType = this.state.itemType;
    let parentId = this.state.itemId;
    let columns = this.getColumnsName();
    let additionalContext = [];

    // Additional Context for Creation with Template
    if(templateId) {
      additionalContext = [{ "Key": "FromId", "Value": templateId }];
    }

    // Specific case for Duplicate
    if(templateId && sameParent) {
      parentType = '';
      parentId = 0;
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ParentType': parentType,
        'ParentId': parentId,
        'Name': name,
        'NbItems': '1',
        'Fields': columns,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.InsertItemAndSaveResult;

      // Check request response
      if(result) {
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Get new Rows
          const newRows = result.InsertedItems.Rows;

          // Add Rows in Components DataSource
          if(this.table.current) {
            this.table.current.addRowsInDatasource(newRows);
          }
          else if(this.planning.current) {
            this.planning.current.addRowsInDatasource(newRows);
          }
          else if(this.kanban.current) {
            this.kanban.current.addRowsInDatasource(newRows);
          }

          // Add Rows in Data Table
          this.addRowsInTable(newRows);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'creation_impossible'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(itemId, itemType, fromCalendar) {
    const { login, authId, language } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete
        const modification = result.Delete;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            if(fromCalendar) {
              this.table.current.deleteRowFromComponent(modification);
            }
            else {
              this.table.current.deleteRowInDatasource(modification);
            }
          }
          else if(this.planning.current) {
            this.planning.current.deleteRowInDatasource(modification);
          }
          else if(this.kanban.current) {
            // this.kanban.current.deleteRowInDatasource(modification);
          }

          // Delete Row in Data Table
          this.deleteRowInTable(modification);
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateRowsInTable(modification, propagations, warnings) {
    const currentView = this.state.currentView;
    const search = this.state.search;
    let rows = this.state.rows;
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === modification.ColumnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }

      // Check if we find Warning Message Column
      const currentWarning = currentRow.Cells.find(cell => cell.ColumnName === 'WarningMessage');

      // Update Warnings
      if(currentWarning) {
        if(warnings.length > 0) {
          currentWarning.Value = warnings.join(' | ');
        }
        else {
          currentWarning.Value = '';
        }
      }
    }

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    this.setState({ rows });

    // Refresh Calendar Meetings with Filters applied
    if(this.calendar.current) {
      this.calendar.current.refreshCalendar(rows);
    }

    // Apply Table Filters
    // this.applyTableFilters(currentView, search);
  }

  applyPropagations(rows, propagations) {
    // Update Row with Propagations NewValue
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1).concat(propagation.ToItemId);

      // Check if we find Row to apply Propagation
      const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === propagationId);

      // Find Row to apply Propagation and Update with new Value
      if(currentRow) {
        // Check if we find Cell to apply Modification
        const currentCell = currentRow.Cells.find(cell => cell.ColumnName === propagation.ColumnName);

        // Update Row Cell with Propagation
        if(currentCell) {
          currentCell.Value = propagation.NewValue;
        }
      }
    });
  }

  addRowsInTable(newRows) {
    const currentView = this.state.currentView;
    const search = this.state.search;
    let rows = this.state.rows;

    // Push new Rows
    newRows.forEach(row => {
      rows.push(row);
    });
    
    this.setState({ rows });

    // Refresh Calendar Meetings with Filters applied
    if(this.calendar.current) {
      this.calendar.current.refreshCalendar(rows);
    }

    // Apply Table Filters
    // this.applyTableFilters(currentView, search);
  }

  deleteRowInTable(modification) {
    const currentView = this.state.currentView;
    const search = this.state.search;
    let rows = this.state.rows;
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let indexToDelete = -1;

    if(rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId)) {
      indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId);
    }

    // Delete Row in Rows
    if(indexToDelete >= 0) {
      rows.splice(indexToDelete, 1);
    }

    this.setState({ rows });

    // Refresh Calendar Meetings with Filters applied
    if(this.calendar.current) {
      this.calendar.current.refreshCalendar(rows);
    }
    
    // Apply Table Filters
    // this.applyTableFilters(currentView, search);
  }

  async importData(login, authId, itemId, itemType, blockType, currentView) {
    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView);

    const columns = this.state.columns;
    const rows = this.state.rows;

    // Refresh Component
    if(this.table.current) {
      this.table.current.refreshComponent(blockType, currentView, columns, rows);
    }
    else if(this.planning.current) {
      this.planning.current.refreshComponent(blockType, currentView, columns, rows);
    }
  }

  exportCSV() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
    // Export Data from Planning component
    if(this.planning.current) {
      this.planning.current.exportCSV();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportCSV();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
    // Export Data from Planning component
    if(this.planning.current) {
      this.planning.current.exportXLSX();
    }
    // Export Data from Pivot component
    if(this.pivot.current) {
      this.pivot.current.exportXLSX();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportXLSX();
    }
  }

  exportJPEG() {
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportJPEG();
    }
  }

  exportPNG() {
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportPNG();
    }
  }

  exportPDF() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportPDF();
    }
    // Export Data from Planning component
    if(this.planning.current) {
      this.planning.current.exportPDF();
    }
    // Export Data from Chart component
    if(this.chart.current) {
      this.chart.current.exportPDF();
    }
  }

  searchItem(event) {
    const currentView = this.state.currentView;
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }
    // Search Items in Gantt component
    if(this.planning.current) {
      this.planning.current.searchItem(search);
    }
    // Search Items in Kanban component
    if(this.kanban.current) {
      this.kanban.current.searchItem(search);
    }

    // Apply Table Filters
    // this.applyTableFilters(currentView, search);

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  closeMultipleModificationPopup() {
    this.setState({ multipleModificationPopup: false });
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, blockContent, editable, guestLicence, favorite, parents, warnings, displayViews, views, defaultViewId, currentView, headers, columns, rows, chart, multipleModificationPopup, columnName, search, visibleComponent, isLoading, errors } = this.state;
    
    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'meetings')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onPlanningUpdate={this.refreshPlanning}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Add Button */}
                {itemType === 'Project' && (currentView.ViewType !== 3 && currentView.ViewType !== 7) && editable && !guestLicence && 
                  <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'Meeting')}/>
                }

                {/* Visible Component */}
                {currentView.ViewType === 0 && !guestLicence && 
                  <FiltersDisplayComponent BlockType={blockType} CurrentView={currentView} Display={visibleComponent} onDisplayChange={this.changeDisplay}></FiltersDisplayComponent>
                }

                {/* Block Filters */}
                <FiltersBlock ref={this.filters} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>

                {/* Sort Filters */}
                <FiltersSort ref={this.sort} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>

                {/* Conditional Formatting Filters */}
                {currentView.ViewType !== 3 && 
                  <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>
                }

                {/* Table Settings */}
                {currentView.ViewType === 0 && !guestLicence && 
                  <FiltersTable ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onViewChange={this.updateView} onSettingsChange={this.applyTableSettings}></FiltersTable>
                }

                {/* Planning Timeline */}
                {currentView.ViewType === 1 && !guestLicence && 
                  <PlanningTimeline ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onTimelineChange={this.applyPlanningTimeline} onZoomChange={this.applyPlanningTimeline} onZoomAuto={this.applyPlanningZoomAuto}></PlanningTimeline>
                }
                
                {/* Planning Settings */}
                {currentView.ViewType === 1 && !guestLicence && 
                  <FiltersPlanning ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} AvailableAxes={blockContent.AvailableAxes} onViewChange={this.updateView} onSettingsChange={this.applyPlanningSettings}></FiltersPlanning>
                }

                {/* Kanban Filters */}
                {currentView.ViewType === 2 && !guestLicence && 
                  <FiltersKanban BlockType={blockType} CurrentView={currentView} Axes={blockContent.AvailableAxes} onAxeChange={this.changeAxe} onKeyFieldChange={this.changeKeyField}></FiltersKanban>
                }

                {/* Chart Settings */}
                {currentView.ViewType === 7 && !guestLicence && 
                  <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                }

                {/* Columns Chooser */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1 || currentView.ViewType === 2) && 
                  <FiltersColumnChooser ItemId={itemId} ItemType={itemType} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                }

                {/* Search input */}
                {(currentView.ViewType !== 1 && currentView.ViewType !== 3 && currentView.ViewType !== 7) && 
                  <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>
                }

                {/* Import */}
                {currentView.ViewType === 0 && !guestLicence && 
                  <FiltersImport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDataImport={this.importData} onXLSXExport={this.exportXLSX}></FiltersImport>
                }

                {/* Export */}
                {(currentView.ViewType === 0 || currentView.ViewType === 1 || currentView.ViewType === 3 || currentView.ViewType === 7) && 
                  <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onJPEGExport={this.exportJPEG} onPNGExport={this.exportPNG} onPDFExport={this.exportPDF} onErrorsUpdate={this.updateErrors}></FiltersExport>
                }
              </div>

              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Multiple Modification Popup */}
                {multipleModificationPopup && <PopupMultipleModification ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} ColumnHeaders={columns} Rows={rows} ColumnName={columnName} onMultipleModification={this.multipleModificationColumn} onPopupClose={this.closeMultipleModificationPopup}></PopupMultipleModification>}

                {/* Meetings Calendar + Table */}
                {visibleComponent && currentView.ViewType === 0 && <div className="blockMeetings">
                  <div className="blockMeetingsCalendar">
                    <MeetingCalendar ref={this.calendar} CurrentView={currentView} Columns={columns} Rows={rows} onMeetingDelete={this.deleteItem}></MeetingCalendar>
                  </div>
                  <div className="blockMeetingsTable">
                    {rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onColumnReorder={this.reorderColumns} onColumnMultipleModification={this.openMultipleModificationPopup} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>} 
                  </div>
                </div>}

                {/* Table */}
                {!visibleComponent && currentView.ViewType === 0 && <div className="blockMeetingsComponent">
                  {rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onColumnReorder={this.reorderColumns} onColumnMultipleModification={this.openMultipleModificationPopup} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>} 
                </div>}
                
                {/* View Components */}
                {currentView.ViewType === 1 && rows.length > 0 && <Planning ref={this.planning} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onColumnsFilter={this.filterColumn} onColumnsSort={this.sortColumn} onColumnSort={this.changeSort} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Planning>}
                {currentView.ViewType === 2 && rows.length > 0 && <Kanban ref={this.kanban} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes} onTableUpdate={this.checkModification}></Kanban>}
                {currentView.ViewType === 3 && rows.length > 0 && <Pivot ref={this.pivot} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView}></Pivot>}
                {currentView.ViewType === 7 && <Chart ref={this.chart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockMeetings;