import React, {Component} from 'react';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnDirective, ColumnsDirective } from '@syncfusion/ej2-react-treegrid';
import { Inject, Sort } from '@syncfusion/ej2-react-treegrid';
import '../Css/App.css';
import Authentication from '../Authentication';

class MiniTableHolidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      columns: [],
      rows: [],
      dataSource: [],
      error: {}
    };

    // Table Structure
    this.created = this.created.bind(this);
    this.dataStructure = this.dataStructure.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.getColumnHeaders = this.getColumnHeaders.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);

    // Template Functions
    this.templateHeaderDay = this.templateHeaderDay.bind(this);
    this.templateName = this.templateName.bind(this);
    this.templateHoliday = this.templateHoliday.bind(this);
    this.templateBankHoliday = this.templateBankHoliday.bind(this);
    this.templateWeekend = this.templateWeekend.bind(this);

    this.errors = React.createRef();
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Table Datasource
    let dataSource = this.dataStructure(rows);

    this.setState({ authId, itemId, itemType, itemTitle, blockType, columns, rows, dataSource });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.Title !== prevProps.Title || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(this.props.Columns !== prevProps.Columns || this.props.Rows !== prevProps.Rows) {
      // Build Table Datasource
      let dataSource = this.dataStructure(rows);

      this.setState({ columns, rows, dataSource });
    }
  }

  created() {
    // Get Columns Headers
    const columns = this.getColumnHeaders();

    // Auto Generated Columns
    if(this.grid) {
      // Clean Grid columns
      this.grid.columns = [];

      // Clean Grid aggregate
      // this.grid.aggregates = [];

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < columns.length; i++) {
        let type, header, headerTextAlign, template, primaryKey, visible, width;

        // Type
        if(columns[i].FieldType === 'String') {
          type = 'string';
        }
        else if(columns[i].FieldType === 'Double' || columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          type = 'number';
        }
        else if(columns[i].FieldType === 'Date') {
          type = 'date';
        }
        else {
          type = null;
        }

        // Header
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          header = this.templateHeaderDay;
        }

        // Header Text Align
        if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday' || columns[i].FieldType === 'Weekend') {
          headerTextAlign = 'center';
        }
        else {
          headerTextAlign = 'left';
        }

        // Template
        if(columns[i].FieldName === 'Name') {
          template = this.templateName;
        }
        else if(columns[i].FieldType === 'OpenDay') {
          template = this.templateHoliday;
        }
        // else if(columns[i].FieldType === 'BankHoliday') {
        //   template = this.templateBankHoliday;
        // }
        else if(columns[i].FieldType === 'Weekend') {
          template = this.templateWeekend;
        }

        // Primary Key
        if(columns[i].FieldName === 'Item_ID') {
          primaryKey = true;
        }
        else {
          primaryKey = false;
        }

        // Visible
        if(columns[i].FieldName === 'Item_ID' || columns[i].FieldName === 'Item_Type' || columns[i].FieldName === 'Resource_ID') {
          visible = false;
        }
        else {
          visible = true;
        }

        // Width
        if(columns[i].FieldName === 'Name') {
          width = 200;
        }
        else if(columns[i].FieldType === 'OpenDay' || columns[i].FieldType === 'BankHoliday') {
          width = 48;
        }
        else if(columns[i].FieldType === 'Weekend') {
          width = 48;
        }
        
        // Define Column object for Grid
        var colObj = {
          allowEditing: false,
          field: columns[i].FieldName,
          headerText: this.getColumnLabel(columns[i].FieldName),
          headerTextAlign: headerTextAlign,
          headerTemplate: header,
          isPrimaryKey: primaryKey,
          lockColumn: false,
          showInColumnChooser: false,
          type: type,
          template: template,
          visible: visible,
          width: width
        };

        // Add the Column in the Grid
        this.grid.columns.push(colObj);
      }

      this.grid.refreshColumns();
    }
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if((item.FieldType === 'Double' || item.FieldType === 'Day') && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          else if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  dataBound(args) {
    if(this.grid) {
      // Autofit Columns width
      // this.grid.autoFitColumns();
      
      // TO UPDATE: Fix: Force Column Generation after Data update
      // if(this.grid.columns.length === 1) {
      //   this.created();
      // }
    }
  }

  // Row Formatting design
  rowDataBound(args) {
    // Define styles for Objects rows
    if(getObject('Item_Type', args.data) === 'Task') {
      args.rowHeight = 30;
    }
    else {
      args.rowHeight = 30;
    }
  }

  getColumnHeaders() {
    return this.state.columns;
  }

  getColumnLabel(columnName) {
    let columns = this.state.columns;
    let label = "";

    if(columns.find(column => column.FieldName === columnName)) {
      label = columns.find(column => column.FieldName === columnName).Label;
    }

    if(columnName === 'WarningMessage') {
      label = 'W';
    }

    return label;
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // console.log(args);
  }

  // ----- ----- Format Functions ----- -----
  templateHeaderDay(props) {
    const label = props.headerText;
    const field = props.field;
    const date = new Date(field);
    const day = date.getDay();

    const today = new Date();

    // Weekends
    if(day === 0 || day === 6) {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="smoke-grey">{label}</div>;
      }
    }
    else {
      // Today
      if(date.toDateString() === today.toDateString()) {
        return <div className="headerToday">
          <span className="white">{label}</span>
        </div>;
      }
      else {
        return <div className="">{label}</div>
      }
    }
  }

  templateName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    let name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateHoliday(props) {
    const date = props.column.field;
    const value = props[date];
    const field = date;

    // Bank Holiday
    if(value == -1) {
      return <div className="holidaysMiniTable">
      <div className="iconsMiniblock iconFullCircle bg-dark-grey"></div>
    </div>;
    }
    // Open Day
    else if(value == 1) {
      return <div className="holidaysMiniTable">
        <div id={field} className="iconsMiniblock iconFullCircle bg-turquoise"></div>
      </div>;
    }
    else if(value == 0.5) {
      return <div className="holidaysMiniTable">
        <div id={field} className="iconsMiniblock iconHalfCircle bg-turquoise"></div>
      </div>;
    }
    else {
      return <div className="holidaysMiniTable">
        <div id={field} className="iconsMiniblock iconCircle bg-grey"></div>
      </div>;
    }
  }

  templateBankHoliday(props) {
    return <div className="holidaysMiniTable">
      <div className="iconsMiniblock iconFullCircle bg-dark-grey"></div>
    </div>;
  }

  templateWeekend(props) {
    return <div className="holidaysMiniTable">
      <div className="iconsMiniblock iconFullCircle bg-smoke-grey"></div>
    </div>;
  }
  
  render() {
    let { itemId, itemType, columns, rows, dataSource, level } = this.state;

    // Grid Lines
    this.gridLines = 'Default';

    // Toolbar Options
    this.toolbarOptions = [];

    // Context Menu
    this.contextMenuItems = [];

    // Column Menu
    this.columnMenuItems = [];

    // Edit Options
    this.editOptions = { allowAdding: false, allowDeleting: false, allowEditing: false, mode: 'Cell' };

    // Filter Settings
    this.filterSettings = { type: 'Excel', hierarchyMode: 'Parent' };

    return (
      <div className="control-pane height100p">
        <div className="control-section height100p">
          {dataSource.length > 0 && 
            <TreeGridComponent dataSource={dataSource} idMapping='Item_ID' parentIdMapping='Parent_ID' height={'100%'} gridLines={this.gridLines} editSettings={this.editOptions} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} filterSettings={this.filterSettings} created={this.created} dataBound={this.dataBound} rowDataBound={this.rowDataBound} actionBegin={this.actionBegin} actionComplete={this.actionComplete} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={false} allowExcelExport={false} allowReordering={false} allowResizing={false} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.grid=treegrid}>
              {/* Columns */}
              <ColumnsDirective>
                <ColumnDirective isPrimaryKey={true} field='Item_ID' headerText={'Item_ID'} visible={false} autoFit={true} allowEditing={false} lockColumn={false} showInColumnChooser={false}></ColumnDirective>
              </ColumnsDirective>
              {/* Services */}
              <Inject services={[ Sort ]}/>
            </TreeGridComponent>
          }
        </div>
      </div>
    )
  }
}

export default MiniTableHolidays;