import React, {Component} from 'react';
import '../Css/App.css';

class IconAndTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemType: null,
      itemTitle: null,
      display: null
    };

    this.getObjectIcon = this.getObjectIcon.bind(this);
    this.getObjectTitle = this.getObjectTitle.bind(this);
  }
  
  componentDidMount() {
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const display = this.props.Display;

    this.setState({ itemType, itemTitle, display });
  }

  componentDidUpdate(prevProps) {
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const display = this.props.Display;
    
    if(this.props.Type !== prevProps.Type || this.props.Title !== prevProps.Title) {
      this.setState({ itemType, itemTitle, display });
    }
  }

  getObjectIcon(itemType, display) {
    // Determine the icon to display depending on the Object type
    switch(itemType) {
      case "AgileBoard":
          if(display === 'Card') {
            return <span className="iconAgileBoardWhite iconsCard" alt="Agile Board"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconAgileBoard iconsMinicard" alt="Agile Board"/>;
          }
          else if(display === 'Environment') {
            return <span className="iconAgileBoard iconsCard" alt="Agile Board"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconAgileBoardWhite iconsMinicard" alt="Agile Board"/>;
          }
      case "Sprint":  
          if(display === 'Card') {
            return <span className="iconSprintWhite iconsCard" alt="Sprint"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconSprint iconsMinicard" alt="Sprint"/>;
          }
          else if(display === 'Environment') {
            return <span className="iconSprint iconsCard" alt="Sprint"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconSprintWhite iconsMinicard" alt="Sprint"/>;
          }
      case "Business_Line":
          if(display === 'Card') {
            return <span className="iconBusinessLineWhite iconsCard" alt="Business Line"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconBusinessLine iconsMinicard" alt="Business Line"/>;
          } 
          else if(display === 'Environment') {
            return <span className="iconBusinessLine iconsCard" alt="Business Line"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconBusinessLineWhite iconsMinicard" alt="Business Line"/>;
          }
          else if(display === 'Communication') {
            return <span className="iconBusinessLineDark iconsSlideParameters" alt="Business Line"/>;
          }
      case "Project":
          if(display === 'Card') {
            return <span className="iconProjectWhite iconsCard" alt="Project"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconProject iconsMinicard" alt="Project"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconProjectWhite iconsMinicard" alt="Project"/>;
          }
          else if(display === 'Communication') {
            return <span className="iconProjectDark iconsSlideParameters" alt="Project"/>;
          }
      case "Workpackage":
          if(display === 'Card') {
            return <span className="iconWorkpackageWhite iconsCard" alt="Workpackage"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconWorkpackage iconsMinicard" alt="Workpackage"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconWorkpackageWhite iconsMinicard" alt="Workpackage"/>;
          }
      case "Action":
          if(display === 'Card') {
            return <span className="iconActionWhite iconsCard" alt="Action"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconAction iconsMinicard" alt="Action"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconActionWhite iconsMinicard" alt="Action"/>;
          }
      case "Task":
          if(display === 'Card') {
            return <span className="iconTaskWhite iconsCard" alt="Task"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconTask iconsMinicard" alt="Task"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconTaskWhite iconsMinicard" alt="Task"/>;
          }
      case "Decision":
          if(display === 'Minicard') {
            return <span className="iconDecisions iconsMinicard flex" alt="Decision"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconDecisions iconsMinicard" alt="Decision"/>;
          }
      case "Issue":
          if(display === 'Minicard') {
            return <span className="iconIssues iconsMinicard flex" alt="Issue"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconIssues iconsMinicard" alt="Issue"/>;
          }
      case "Meeting":
          if(display === 'Minicard') {
            return <span className="iconMeetings iconsMinicard flex" alt="Meeting"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconMeetings iconsMinicard" alt="Meeting"/>;
          }
      case "Risk":
          if(display === 'Minicard') {
            return <span className="iconRisks iconsMinicard flex" alt="Risk"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconRisks iconsMinicard" alt="Risk"/>;
          }
      case "BudgetCode":
          if(display === 'Card') {
            return <span className="iconBudgetCodeLight iconsCard" alt="Budget Code"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconBudgetCode iconsMinicard" alt="Budget Code"/>;
          }
          else if(display === 'Environment') {
            return <span className="iconBudgetCodeDark iconsCard" alt="Budget Code"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconBudgetCodeLight iconsMinicard" alt="Budget Code"/>;
          }
      case "Entity":
          if(display === 'Card') {
            return <span className="iconEntityWhite iconsCard" alt="Entity"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconEntity iconsMinicard" alt="Entity"/>;
          }
          else if(display === 'Environment') {
            return <span className="iconEntity iconsCard" alt="Entity"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconEntityWhite iconsMinicard" alt="Entity"/>;
          }
      case "Resource":
          if(display === 'Card') {
            return <span className="iconResourceWhite iconsCard" alt="Resource"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconResource iconsMinicard" alt="Resource"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconUserLight iconsMinicard" alt="Resource"/>;
          }
          if(display === 'TimeTracking') {
            return <span className="iconUserLight iconsCard" alt="Resource"/>;
          }
      case "User":
          if(display === 'Card') {
            return <span className="iconUserLight iconsCard" alt="User"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconUser iconsMinicard" alt="User"/>;
          }
          else if(display === 'Notification') {
            return <span className="iconUserLight iconsMinicard" alt="User"/>;
          }
      case "Environment":
          if(display === 'Environment') {
            return <span className="iconEnvironment iconsCard" alt="Environment"/>;
          }
      case "Notifications":
          if(display === 'Notifications') {
            return <span className="iconBellLight iconsCard" alt="Notifications"/>;
          }
      case "Help":
          if(display === 'Help') {
            return <span className="iconHelpWhite iconsCard" alt="Help"/>;
          }
      case "Global":
          if(display === 'Communication') {
            return <span className="iconGlobal iconsSlideParameters" alt="Global"/>;
          }
      default:
          if(display === 'Card') {
            return <span className="iconAxeLight iconsCard" alt="Axe"/>;
          }
          else if(display === 'Minicard') {
            return <span className="iconAxe iconsMinicard" alt="Axe"/>;
          }
          else if(display === 'Notifications') {
            return <span className="iconAxe iconsMinicard" alt="Axe"/>;
          }
    }
  }

  getObjectTitle(itemTitle) {
    // Substring Minicard title if too long
    if(itemTitle && itemTitle.length > 60) {
      return <span className="" title={itemTitle}>{itemTitle.substring(0,60)} ...</span>;
    }
    else {
      return itemTitle;
    }
  }
  
  render() {
    let { itemType, itemTitle, display } = this.state;
  
    return (
      <div className="">
        {/* ----- Card ----- */}
        {/* Business_Line/Project/Workpackage/Action/Task */}
        {display === 'Card' && (itemType === 'Business_Line' || itemType === 'Project' || itemType === 'Workpackage' || itemType === 'Action' || itemType === 'Task') && 
          <div className="cardTitle">
            <span className="cardIconBlue">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Resource/Entity/User */}
        {display === 'Card' && (itemType === 'Resource' || itemType === 'Entity' || itemType === 'User') && 
          <div className="cardTitle">
            <span className="cardIconTurquoise">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* BudgetCode */}
        {display === 'Card' && (itemType === 'BudgetCode') && 
          <div className="cardTitle">
            <span className="cardIconGrey">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Axes */}
        {display === 'Card' && (itemType !== 'Business_Line' && itemType !== 'Project' && itemType !== 'Workpackage' && itemType !== 'Action' && itemType !== 'Task' && itemType !== 'Resource' && itemType !== 'Entity' && itemType !== 'User' && itemType !== 'BudgetCode' && itemType !== 'Global') && 
          <div className="cardTitle">
            <span className="cardIconGrey">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- Minicard ----- */}
        {display === 'Minicard' && 
          <div className="minicardTitle">
            <span className="">{this.getObjectIcon(itemType, display)}</span>
            <span className={"line-height20" + (itemType !== 'Action' ? " ml5" : "")}>{this.getObjectTitle(itemTitle)}</span>
          </div>
          // <div className="minicardTitle"><span className="minicardIcon">{icon}</span>{title}</div>
        }
        {/* ----- Environment ----- */}
        {/* Environment */}
        {display === 'Environment' && itemType === 'Environment' && 
          <div className="cardTitle">
            <span className="environmentIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Business_Line */}
        {display === 'Environment' && itemType === 'Business_Line' && 
          <div className="environmentTitle blue">
            <span className="environmentCategoryIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Entity */}
        {display === 'Environment' && itemType === 'Entity' && 
          <div className="environmentTitle turquoise">
            <span className="environmentCategoryIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Budget */}
        {display === 'Environment' && itemType === 'BudgetCode' &&
          <div className="environmentTitle black">
            <span className="environmentCategoryIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* Agile Board */}
        {display === 'Environment' && itemType === 'AgileBoard' &&
          <div className="environmentTitle orange">
            <span className="environmentCategoryIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- Notifications ----- */}
        {display === 'Notifications' &&
          <div className="cardTitle">
            <span className="notificationsIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- Notification Minicard ----- */}
        {display === 'Notification' && (itemType === 'Business_Line' || itemType === 'Project' || itemType === 'Workpackage' || itemType === 'Action' || itemType === 'Task') &&
          <div className="notificationMinicardTitle">
            <span className="minicardIconBlue">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {display === 'Notification' && (itemType === 'Resource' || itemType === 'Entity' || itemType === 'User') &&
          <div className="notificationMinicardTitle">
            <span className="minicardIconTurquoise">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {display === 'Notification' && (itemType === 'BudgetCode') &&
          <div className="notificationMinicardTitle">
            <span className="minicardIconGrey">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {display === 'Notification' && (itemType !== 'Business_Line' && itemType !== 'Project' && itemType !== 'Workpackage' && itemType !== 'Action' && itemType !== 'Task' && itemType !== 'Resource' && itemType !== 'Entity' && itemType !== 'User' && itemType !== 'BudgetCode' && itemType !== 'Global') &&
          <div className="notificationMinicardTitle">
            <span className="minicardIconGrey">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- TimeTracking ----- */}
        {display === 'TimeTracking' &&
          <div className="cardTitle">
            <span className="timetrackingIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- Help ----- */}
        {display === 'Help' && 
          <div className="cardTitle">
            <span className="helpIcon">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
        {/* ----- Communication ----- */}
        {display === 'Communication' && 
          <div className="slideFieldsBigLabel">
            <span className="mr10">{this.getObjectIcon(itemType, display)}</span>
            <span className="">{this.getObjectTitle(itemTitle)}</span>
          </div>
        }
      </div>
    )
  }
}

export default IconAndTitle;