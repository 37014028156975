import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class ButtonsMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      month: null,
      year: null,
      current_month: null,
      current_year: null,
    };

    this.getMonthName = this.getMonthName.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const month = this.props.Month;
    const year = this.props.Year;

    const date = new Date();
    const current_month = (date.getMonth())+1;
    const current_year = date.getFullYear();

    this.setState({ language, month, year, current_month, current_year });
  }

  componentDidUpdate(prevProps) {
    const month = this.props.Month;
    const year = this.props.Year;

    if(this.props.Month !== prevProps.Month || this.props.Year !== prevProps.Year) {
      this.setState({ month, year });
    }
  }

  getMonthName(month) {
    const language = this.state.language;

    switch(month) {
      case 1:
          return Traduction.translate(language, 'january');
      case 2:
          return Traduction.translate(language, 'february');
      case 3:
          return Traduction.translate(language, 'march');
      case 4:
          return Traduction.translate(language, 'april');
      case 5:
          return Traduction.translate(language, 'may');
      case 6:
          return Traduction.translate(language, 'june');
      case 7:
          return Traduction.translate(language, 'july');
      case 8:
          return Traduction.translate(language, 'august');
      case 9:
          return Traduction.translate(language, 'september');
      case 10:
          return Traduction.translate(language, 'october');
      case 11:
          return Traduction.translate(language, 'november');
      case 12:
          return Traduction.translate(language, 'december');
      default:
          break;
    }
  }

  previousMonth(month, year) {
    let previous;

    if(month === 1) {
      previous = 12;
      year = year - 1;
    }
    else {
      previous = month - 1;
    }

    // Call the event from the Parent component through the props with month and year value
    this.props.onMonthChange(previous, year);

    // this.setState({ month: previous, year: year });
  }

  nextMonth(month, year) {
    let next;

    if(month === 12) {
      next = 1;
      year = year + 1;
    }
    else {
      next = month + 1;
    }

    // Call the event from the Parent component through the props with month and year value
    this.props.onMonthChange(next, year);

    // this.setState({ month: next, year: year });
  }
  
  render() {
    let { language, month, year, current_month, current_year } = this.state;

    return (
      <div className="flex align-items-center fs14 bold yellow">
        <span className="iconPreviousYellow verysmallIcons cursor" onClick={(e) => this.previousMonth(month, year)}></span>
        <span className="centervertical ml10 mr10">{this.getMonthName(month)}</span>
        <span className="centervertical mr10">{year}</span>
        <span className="iconNextYellow verysmallIcons cursor" onClick={(e) => this.nextMonth(month, year)}></span>
      </div>
    )
  }
}

export default ButtonsMonth;