import React, {Component} from 'react';
import { Card, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class CardIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      usersConfig: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {}
    };

    // Data Structure
    this.buildCardIcon = this.buildCardIcon.bind(this);

    // Actions
    this.changeFavorite = this.changeFavorite.bind(this);
    this.displayAudit = this.displayAudit.bind(this);
    this.manageRights = this.manageRights.bind(this);
    this.executeAlertsBatch = this.executeAlertsBatch.bind(this);
    this.recalculatePlanning = this.recalculatePlanning.bind(this);
    this.updateCriticalPaths = this.updateCriticalPaths.bind(this);

    // Template
    this.templatePopover = this.templatePopover.bind(this);
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const usersConfig = Authentication.getCookie('usersConfig');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const favorite = this.props.Favorite;

    this.setState({ authId, language, usersConfig, itemId, itemType, blockType, currentView, favorite });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const favorite = this.props.Favorite;
    
    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.Favorite !== prevProps.Favorite) {
      this.setState({ favorite });
    }
  }

  // Determine the icon to display depending on Card Item type
  buildCardIcon(itemType, blockType) {
    switch(itemType) {
      case "AgileBoard":
          return <div className="cardIconOrange"><span className="iconAgileBoardWhite iconsCard" alt="Agile Board"/></div>;
      case "Sprint":
          return <div className="cardIconOrange"><span className="iconSprintWhite iconsCard" alt="Sprint"/></div>;
      case "Business_Line":
          return <div className="cardIconBlue"><span className="iconBusinessLineWhite iconsCard" alt="Business Line"/></div>;
      case "Project":
          return <div className="cardIconBlue"><span className="iconProjectWhite iconsCard" alt="Project"/></div>;
      case "Workpackage":
          return <div className="cardIconBlue"><span className="iconWorkpackageWhite iconsCard" alt="Workpackage"/></div>;
      case "Action":
          return <div className="cardIconBlue"><span className="iconActionWhite iconsCard" alt="Action"/></div>;
      case "Task":
          return <div className="cardIconBlue"><span className="iconTaskWhite iconsCard" alt="Task"/></div>;
      case "Meeting":
          return <div className="cardIconBlue"><span className="iconMeetingLight iconsCard" alt="Meeting"/></div>;
      case "Entity":
          return <div className="cardIconTurquoise"><span className="iconEntityWhite iconsCard" alt="Entity"/></div>;
      case "Resource":
          return <div className="cardIconTurquoise"><span className="iconResourceWhite iconsCard" alt="Resource"/></div>;
      case "User":
          return <div className="cardIconTurquoise"><span className="iconUserLight iconsCard" alt="User"/></div>;
      case "BudgetCode":
          return <div className="cardIconGrey"><span className="iconBudgetCodeLight iconsCard" alt="Budget Code"/></div>;
      case "Administration":
          return <div className="adminIcon"><span className="iconAdministrationWhite iconsCard" alt="Admin"/></div>;
      case "Environment":
          if(blockType !== 'NotificationsCenter') {
            return <div className="environmentIcon"><span className="iconEnvironment iconsCard" alt="Environment"/></div>;
          }
          else {
            return <div className="notificationsIcon"><span className="iconBellLight iconsCard" alt="Notifications"/></div>;
          }
      case "Notifications":
          return <div className="notificationsIcon"><span className="iconBellLight iconsCard" alt="Notifications"/></div>;
      case "DeleteBin":
          return <div className="deleteBinIcon"><span className="iconArchivedLight iconsCard" alt="Delete Bin"/></div>;
      default:
          return <div className="cardIconGrey"><span className="iconAxeLight iconsCard" alt="Axe"/></div>;
    }
  }

  changeFavorite() {
    // Call the event from the Parent component through the props
    this.props.onFavoriteChange();
  }

  displayAudit() {
    // Call the event from the Parent component through the props
    this.props.onAuditDisplay();
  }

  manageRights() {
    // Call the event from the Parent component through the props
    this.props.onRightsDisplay();
  }

  executeAlertsBatch() {
    // Call the event from the Parent component through the props
    this.props.onAlertsBatchExecute();
  }

  recalculatePlanning(itemId, itemType, currentView) {
    // Call the event from the Parent component through the props
    this.props.onPlanningRecalculate(itemId, itemType, currentView);
  }

  updateCriticalPaths(itemId, itemType, currentView) {
    // Call the event from the Parent component through the props
    this.props.onCriticalPathsUpdate(itemId, itemType, currentView);
  }

  templatePopover(itemId, itemType, blockType, currentView, favorite) {
    const language = this.state.language;
    const usersConfig = this.state.usersConfig;
    let labelFavorite;

    if(favorite) {
      labelFavorite = Traduction.translate(language, 'remove_favorite');
    }
    else {
      labelFavorite = Traduction.translate(language, 'add_favorite');
    }

    const popover = (
      <Popover id="popover-basic" className="popoverCardMenu">
        <Popover.Content>
          <div className="flex flex-column">
            {/* Add to Favorite */}
            {blockType !== 'NotificationsCenter' && 
              <div className="cardMenuAction cursor" onClick={() => this.changeFavorite()}>
                <span className="iconStarDarkBlue verysmallIcons icon"></span>
                <div className="accountLabel">{labelFavorite}</div>
              </div>
            }
            {/* View History */}
            {blockType !== 'NotificationsCenter' && 
              <div className="cardMenuAction cursor" onClick={() => this.displayAudit()}>
                <span className="iconAudit verysmallIcons icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'display_audit')}</div>
              </div>
            }
            {/* Manage Rights */}
            {blockType !== 'NotificationsCenter' && 
              <div className="cardMenuAction cursor" onClick={() => this.manageRights()}>
                <span className="iconUserNavAccount verysmallIcons icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'manage_rights')}</div>
              </div>
            }
            {/* Border */}
            {blockType === 'Roadmap' && (currentView.ViewType === 0 || currentView.ViewType === 1) && 
              <div className="popoverBorder"></div>
            }
            {/* Execute Alerts Batch */}
            {usersConfig && blockType === 'NotificationsCenter' && 
              <div className="cardMenuAction cursor" onClick={() => this.executeAlertsBatch()}>
                <span className="iconNotificationsDarkBlue verysmallIcons icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'launch_alerts_batch')}</div>
              </div>
            }
            {/* Recalculate Planning */}
            {blockType === 'Roadmap' && (currentView.ViewType === 0 || currentView.ViewType === 1) &&
              <div className="cardMenuAction cursor" onClick={() => this.recalculatePlanning(itemId, itemType, currentView)}>
                <span className="iconCalendarDarkBlue verysmallIcons icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'recalculate_planning')}</div>
              </div>
            }
            {/* Refresh Dependencies & Critical Path */}
            {blockType === 'Roadmap' && (currentView.ViewType === 0 || currentView.ViewType === 1) &&
              <div className="cardMenuAction cursor" onClick={() => this.updateCriticalPaths(itemId, itemType, currentView)}>
                <span className="iconDepencyPathDarkBlue verysmallIcons icon"></span>
                <div className="accountLabel">{Traduction.translate(language, 'refresh_dependencies_critical_path')}</div>
              </div>
            }
          </div>
        </Popover.Content>
      </Popover>
    );
    
    return popover;
  }
  
  render() {
    const { itemId, itemType, blockType, currentView, favorite } = this.state;

    return (
      <div className="cardIcon">
        {itemType !== 'Administration' && itemType !== 'DeleteBin' && (itemType !== 'Environment' || blockType === 'NotificationsCenter') && itemType !== 'Notifications' && blockType !== 'TimeTracking' && 
          <div className="cursor">
            <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopover(itemId, itemType, blockType, currentView, favorite)}>
              {this.buildCardIcon(itemType, blockType)}
            </OverlayTrigger>
          </div>
        }
        {(itemType === 'Administration' || itemType === 'DeleteBin' || (itemType === 'Environment' && blockType !== 'NotificationsCenter') || itemType === 'Notifications' || blockType === 'TimeTracking') && 
          <div className="">{this.buildCardIcon(itemType, blockType)}</div>
        }
      </div>
    )
  }
}

export default CardIcon;