import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorModification from './ErrorModification';

// API
const API_formula = '/WebAppService/GetFormulaInformation';
const API_valid = '/WebAppService/CheckFormula';

class TextFormulaEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      rowItemId: null,
      rowItemType: null,
      fieldName: null,
      label: null,
      oldValue: null,
      formula: '',
      fields: [],
      functions: [],
      search: '',
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getFormulaInformation = this.getFormulaInformation.bind(this);

    // Actions
    this.updateFormula = this.updateFormula.bind(this);
    this.addField = this.addField.bind(this);
    this.addFunction = this.addFunction.bind(this);
    this.searchField = this.searchField.bind(this);
    this.saveFormula = this.saveFormula.bind(this);
    this.checkFormula = this.checkFormula.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    this.formulaEditor = React.createRef();
  }
    
  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const rowItemId = this.props.RowItemId;
    const rowItemType = this.props.RowItemType;
    const fieldName = this.props.FieldName;
    const label = this.props.Label;

    this.setState({ login, authId, language, itemId, itemType, blockType, rowItemId, rowItemType, fieldName, label });

    // Get Formula Information
    this.getFormulaInformation(login, authId, rowItemId);
  }
  
  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const rowItemId = this.props.RowItemId;
    const rowItemType = this.props.RowItemType;
    const fieldName = this.props.FieldName;
    const label = this.props.Label;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
    //   this.setState({ itemId, itemType, blockType });
    // }
    // if(this.props.RowItemId !== prevProps.RowItemId || this.props.RowItemType !== prevProps.RowItemType || this.props.FieldName !== prevProps.FieldName) {
    //   this.setState({ rowItemId, rowItemType, fieldName, label });
    // }
  }

  async getFormulaInformation(login, authId, rowItemId) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify(
        rowItemId.substring(1)
      )
    };

    try {
      const response = await fetch(API_formula, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetFormulaInformationResult;

      if(result) {
        const formula = result.Formula;
        const fields = result.ListFields;
        const functions = result.ListFunctions;

        this.setState({ oldValue: formula, formula, fields, functions });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Update New Comment with input value
  updateFormula(event) {
    const formula = event.target.value;

    this.setState({ formula });
  }

  addField(element) {
    const value = this.formulaEditor.current.value;
    const selectionStart = this.formulaEditor.current.selectionStart;
    const selectionEnd = this.formulaEditor.current.selectionEnd;

    const formula = value.substring(0, selectionStart) + "[" + element.Label + "]" + value.substring(selectionEnd);

    this.setState({ formula });

    // Search Auto focus
    setTimeout(() => { this.formulaEditor.current.focus() }, 1);
  }

  addFunction(element) {
    const value = this.formulaEditor.current.value;
    const selectionStart = this.formulaEditor.current.selectionStart;
    const selectionEnd = this.formulaEditor.current.selectionEnd;

    const formula = value.substring(0, selectionStart) + element.Label + "()" + value.substring(selectionEnd);

    this.setState({ formula });

    // Search Auto focus
    setTimeout(() => { this.formulaEditor.current.focus() }, 1);
  }

  searchField(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  saveFormula() {
    const { rowItemId, rowItemType, fieldName, oldValue, formula } = this.state;

    if(formula !== oldValue) {
      this.checkFormula(rowItemId.substring(1), rowItemType, fieldName, oldValue, formula);
    }
    else {
      // Close Popup
      this.props.onPopupClose();
    }
  }

  async checkFormula(rowItemId, rowItemType, fieldName, oldValue, formula) {
    const { login, authId, language } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify(
        formula
      )
    };

    try {
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.CheckFormulaResult;

      // Check request response
      if(result) {
        // If the Modification is valid
        if(result.IsValid === true) {
          const formulaHtml = result.FormulaHtml;

          // Build Modification object for checkModification function
          let modification = { ItemId: rowItemId, ItemType: rowItemType, ColumnName: fieldName, NewValue: formulaHtml, OldValue: oldValue };

          // Call the event from the Parent component through the props
          this.props.onTextChange(modification, [], []);

          // Call the event from the Parent component through the props with Modification value
          this.props.onTextUpdate(rowItemId, rowItemType, fieldName, oldValue, formula);

          // Close Popup
          this.props.onPopupClose();
        }
        // If the Modification is not valid
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);
        
        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    const { language, itemType, blockType, fieldName, label, oldValue, formula, fields, functions, search, errors } = this.state;

    return (
      <div className="flex flex-column">
        {/* Errors */}
        {errors.length > 0 && 
          <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
        }

        <div className="textEditorFormula">
          {/* Title */}
          <div className="textEditorFormulaTitle">{Traduction.translate(language, 'formula')}</div>
          {/* Input */}
          <Form.Group className="formulaEditorInput">
            <Form.Control id="formulaEditor" className="formulaEditorTextArea" as="textarea" rows="4" placeholder={Traduction.translate(language, 'add_formula')} autoFocus value={formula} onChange={(e) => this.updateFormula(e)} ref={this.formulaEditor} />
          </Form.Group>
        </div>

        <div className="textEditorFormulaConstructor">
          <div className="textEditorFormulaFields">
            <div className="textEditorFieldTitle">
              <div className="flex">{Traduction.translate(language, 'available_fields')}</div>
              {/* Search */}
              <div className="textEditorFieldSearch">
                <Form.Group className="searchBlock">
                  <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchField} ref={this.search}/>
                </Form.Group>
              </div>
            </div>
            <div className="textEditorFields scrollbar-y">
              {fields.map((element, index) => {
                if(search === "" || element.Label.toLowerCase().includes(search.toLowerCase())) {
                  return <div key={index} className="textEditorField" onClick={() => this.addField(element)}>{element.Label}</div>;
                }
              })}
            </div>
          </div>
          <div className="textEditorFormulaFunctions">
            <div className="textEditorFormulaTitle">{Traduction.translate(language, 'available_functions')}</div>
            <div className="textEditorFunctions scrollbar-y">
              {functions.map((element, index) => {
                return <div key={index} className="textEditorFunction" onClick={() => this.addFunction(element)}>{element.Label}</div>;
              })}
            </div>
          </div>
        </div>

        {/* Button Validate or Cancel */}
        <div className="flex flex-end mt10">
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.saveFormula()}>{Traduction.translate(language, 'validate')}</Button>
          <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.props.onPopupClose() }>{Traduction.translate(language, 'cancel')}</Button>
        </div>
      </div>
    )
  }
}

export default TextFormulaEditor;