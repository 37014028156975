import React, {Component} from 'react';
import '../Css/App.css';

class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };

  }

  componentDidMount() {
    let errors = this.props.Errors;

    this.setState({ errors });
  }

  componentDidUpdate(prevProps) {
    if(this.props.Errors !== prevProps.Errors) {
      this.setState({ errors: this.props.Errors });
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="">
        {errors.map((error, index) =>
          <div key={index} className="errorMessage row">{error}</div>
        )}
      </div>
    );
  }
}

export default ErrorMessage;