import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersDisplayMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      blockType: null,
      currentView: {},
      display: true
    };

    this.changeDisplay = this.changeDisplay.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const display = this.props.Display;
    
    this.setState({ language, blockType, currentView, display });
  }

  componentDidUpdate(prevProps) {
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const display = this.props.Display;

    if(this.props.CurrentView !== prevProps.CurrentView) {
      this.setState({ currentView });
    }
    if(this.props.Display !== prevProps.Display) {
      this.setState({ display });
    }
  }

  changeDisplay() {
    const display = this.state.display;

    // Call the event from the Parent component through the props
    this.props.onDisplayChange();

    this.setState({ display: !display });
  }

  render() {
    const { language, blockType, currentView, display } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'display_discussion_thread')}</strong></Tooltip>}>
            <div className="filtersDisplayComponent" onClick={() => this.changeDisplay()}>
              <span className={(display ? "iconComments" : "iconComments") + " iconComments iconsFilter cursor"}/>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersDisplayMessages;