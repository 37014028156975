import React, {Component} from 'react';
import '../Css/App.css';

class NotificationTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      type: null
    };
  }
    
  componentDidMount() {
    const title = this.props.Title;
    const type = this.props.Type;

    this.setState({ title, type });
  }
  
  componentDidUpdate(prevProps) {
    const title = this.props.Title;
    const type = this.props.Type;
    
    if(this.props.Title !== prevProps.Title || this.props.Type !== prevProps.Type) {
      this.setState({ title, type });
    }
  }

  render() {
    let { title, type } = this.state;

    return (
      <div className="notificationMinicardTitle">{title}</div>
    )
  }
}

export default NotificationTitle;