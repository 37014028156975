import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Table from './Table';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_delete = '/WebAppService/DeleteItemAndSave';
const API_restore = '/WebAppService/RestoreFromDeleteBin';

class DeleteBin extends Component {
  constructor(props){
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      blockContent: {},
      editable: false,
      views: [],
      currentView: {},
      tables: [],
      columns: [],
      rows: [],
      search: '',
      isLoading: false,
      errors: []
    }

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    
    // Actions
    this.deleteItem = this.deleteItem.bind(this);
    this.deleteAllItems = this.deleteAllItems.bind(this);
    this.restoreItem = this.restoreItem.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.deleteAllRowsInTable = this.deleteAllRowsInTable.bind(this);
    this.restoreRowInTable = this.restoreRowInTable.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    this.table = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = 0;
    const itemType = "DeleteBin";
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType);

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType);
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;

        this.setState({ blockInfo, itemTitle });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const views = blockContent.Views;
        const tables = blockContent.Tables;
        let currentView = {}, columns = [], rows = [];

        // Get Current View
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }
        
        // Apply View
        // this.applyView(rows, currentView);

        this.setState({ blockContent, views, currentView, tables, columns, rows, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(deleteId) {
    const { login, authId, language, itemType } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': deleteId,
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete & Propagations
        const modification = result.Delete;
        const propagations = result.Propagations;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            this.table.current.deleteRowInDatasource(modification);
          }

          // Delete Row in Data Table
          this.deleteRowInTable(modification, propagations);
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async deleteAllItems() {
    const { login, authId, language, itemType } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': 0,
        'ItemType': itemType
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Rows in Components DataSource
          if(this.table.current) {
            this.table.current.deleteAllRowsInDatasource();
          }

          // Delete Rows in Data Table
          this.deleteAllRowsInTable();
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async restoreItem(deleteId) {
    const { login, authId, language } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'DeleteId': deleteId
      })
    };

    try{
      const response = await fetch(API_restore, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.RestoreFromDeleteBinResult;

      // Check request response
      if(result) {
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            this.table.current.restoreRowInDatasource(deleteId);
          }

          // Delete Row in Data Table
          this.restoreRowInTable(deleteId);

          this.setState({ isLoading : false });
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);

          this.setState({ isLoading : false });
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  deleteRowInTable(modification) {
    let rows = this.state.rows;
    const deleteId = modification.ItemId;

    // Check if we find Row to Delete
    const indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'DeleteId').Value === deleteId);

    // Delete Row
    if(indexToDelete >= 0) {
      rows.splice(indexToDelete, 1);
    }

    this.setState({ rows });
  }

  deleteAllRowsInTable() {
    this.setState({ rows: [] });
  }

  restoreRowInTable(deleteId) {
    let rows = this.state.rows;

    // Check if we find Row to Delete
    const indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'DeleteId').Value === deleteId);

    // Delete Row
    if(indexToDelete >= 0) {
      rows.splice(indexToDelete, 1);
    }

    this.setState({ rows });
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, blockContent, editable, currentView, views, columns, rows, search, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'delete_bin')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Card Block Content */}
            <div className="blockContent">
              {/* Filters */}
              <div className="blockFilters">
                <div className="views">
                  {/* Filters View */}
                  {/* <FiltersView BlockType={blockType} CurrentView={currentView} Views={views} onViewChange={this.changeView}></FiltersView> */}
                </div>
                <div className="filters">
                  {/* Delete All */}
                  {rows.length > 0 && <div className="deleteAllItemsIcon cursor" onClick={() => this.deleteAllItems()}>
                    <span className="iconArchivedLight iconsPopover"/>
                    <span className="fs12 white ml10">{Traduction.translate(language, 'clear_delete_bin')}</span>
                  </div>}

                  {/* Search input */}
                  {rows.length > 0 && <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>}
                </div>
              </div>

              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* View Components */}
                {rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onItemDelete={this.deleteItem} onItemRestore={this.restoreItem}></Table>}
              
                {rows && rows.length === 0 && <div className=""></div>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}
export default DeleteBin;