import React, {Component} from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class ButtonsOccupationMetric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      occupationMetric: null
    };

    this.getOccupationMetric = this.getOccupationMetric.bind(this);
    this.changeOccupationMetric = this.changeOccupationMetric.bind(this);
    this.templatePopover = this.templatePopover.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const occupationMetric = this.props.OccupationMetric;

    this.setState({ language, currentView, occupationMetric });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const occupationMetric = this.props.OccupationMetric;

    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.OccupationMetric !== prevProps.OccupationMetric) {
      this.setState({ occupationMetric });
    }
  }

  getOccupationMetric(occupationMetric) {
    const language = this.state.language;

    switch(occupationMetric) {
      case "Workload":
          return Traduction.translate(language, 'workload');
      case "Availability":
          return Traduction.translate(language, 'availability');
      case "Occupancy":
          return Traduction.translate(language, 'occupancy');
      default:
          break;
    }
  }

  changeOccupationMetric(value) {
    this.setState({ occupationMetric: value });

    // Call the event from the Parent component through the props
    this.props.onOccupationMetricChange(value);
  }

  templatePopover() {
    const language = this.state.language;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex flex-column">
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationMetric('Workload')}>{Traduction.translate(language, 'workload')}</div>
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationMetric('Availability')}>{Traduction.translate(language, 'availability')}</div>
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationMetric('Occupancy')}>{Traduction.translate(language, 'occupancy')}</div>
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, currentView, occupationMetric } = this.state;

    return (
      <div className="occupationLevelList cursor">
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover()}>
          <div className="flex align-items-center">
            <span className="fs14 bold yellow">{this.getOccupationMetric(occupationMetric)}</span>
            <span className="iconChevronDownYellow verysmallIcons ml10"></span>
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

export default ButtonsOccupationMetric;