import React, {Component} from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopupAddView from './PopupAddView';
import PopupEditRights from './PopupEditRights';

const API_save = '/WebAppService/SaveView';
const API_save_pinned_views = '/WebAppService/SavePinnedViews';
const API_delete = '/WebAppService/DeleteView';
const API_default = '/WebAppService/SetViewAsDefault';

class FiltersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      userId: null,
      authId: null,
      language: null,
      resourceId: null,
      itemId: null,
      itemType: null,
      blockType: null,
      guestLicence: null,
      defaultViewId: null,
      currentView: {},
      views: [],
      viewItemId: null,
      viewItemType: null,
      viewItemTitle: null,
      viewsPopup: false,
      rightsPopup: false,
      errors: []
    };

    this.getViewsId = this.getViewsId.bind(this);

    // Actions
    this.addView = this.addView.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.renameView = this.renameView.bind(this);
    this.changeViewName = this.changeViewName.bind(this);
    this.saveViewName = this.saveViewName.bind(this);
    this.pinView = this.pinView.bind(this);
    this.unpinView = this.unpinView.bind(this);
    this.shareView = this.shareView.bind(this);
    this.savePinnedViews = this.savePinnedViews.bind(this);
    this.saveView = this.saveView.bind(this);
    this.duplicateView = this.duplicateView.bind(this);
    this.deleteView = this.deleteView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);

    // Template
    this.templatePopover = this.templatePopover.bind(this);
    this.closeRightsPopup = this.closeRightsPopup.bind(this);
    this.closeViewsPopup = this.closeViewsPopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const userId = Authentication.getCookie('userId');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const resourceId = Authentication.getCookie('resourceId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const defaultViewId = this.props.DefaultViewId;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    
    this.setState({ login, userId, authId, language, resourceId, itemId, itemType, blockType, guestLicence, defaultViewId, currentView, views });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const guestLicence = this.props.GuestLicence;
    const defaultViewId = this.props.DefaultViewId;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
    if(this.props.DefaultViewId !== prevProps.DefaultViewId || JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView) || JSON.stringify(this.props.Views) !== JSON.stringify(prevProps.Views)) {
      this.setState({ defaultViewId, currentView, views });
    }
  }

  getViewsId(views) {
    let ids = [];

    views.forEach(view => {
      ids.push(view.ViewId);
    });

    return ids;
  }

  addView() {
    // Open Add View Popup
    this.setState({ viewsPopup: true });
  }

  hideViews() {
    // Call the event from the Parent component through the props
    this.props.onViewsHide();
  }

  renameView(view) {
    const views = this.state.views;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    if(views.find(item => item.ViewId === view.ViewId)) {
      views.find(item => item.ViewId === view.ViewId).Edit = true;
    }

    this.setState({ views });
  }

  changeViewName(event, view) {
    const views = this.state.views;
    const value = event.target.value;

    if(views.find(item => item.ViewId === view.ViewId)) {
      views.find(item => item.ViewId === view.ViewId).Name = value;
    }

    this.setState({ views });

    // const currentView = this.state.currentView;
    // let viewName = this.state.viewName;

    // Update Current View Name
    // currentView.Name = event.target.value;
    // viewName = event.target.value;

    // this.setState({ viewName });

    // Call the event from the Parent component through the props with new View name
    // this.props.onViewChange(currentView);
  }

  saveViewName(event, view) {
    const value = event.target.value;

    // Update View Name
    view.Name = value;
    view.Edit = false;

    // Save View
    this.saveView(view, false);

    // Call the event from the Parent component through the props with new View name
    // this.props.onViewChange(currentView);

    // this.setState({ currentView });
  }

  pinView(view) {
    const { itemType, itemId, blockType, views } = this.state;

    // Close Popup
    this.setState({ viewsPopup: false });

    // If View is already in Pinned Views list
    if(views.find(element => element.ViewId === view.ViewId)) {
      // Call the event from the Parent component through the props with existing View
      this.props.onViewChange(views.find(element => element.ViewId === view.ViewId));
    }
    else {
      // Add View to pinned Views
      views.push(view);

      this.savePinnedViews(view, views, true);
    }
  }

  unpinView(view) {
    const views = this.state.views;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Remove View from pinned Views
    if(views.find(item => item.ViewId === view.ViewId)) {
      views.splice(views.findIndex(item => item.ViewId === view.ViewId), 1);
    }

    this.savePinnedViews(view, views, false);
  }

  shareView(view) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    this.setState({ rightsPopup: true, viewItemId: view.ViewId, viewItemType: 'View', viewItemTitle: view.Name });
  }

  async savePinnedViews(view, views, pin) {
    const { login, authId, language, itemType, currentView } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'Block': view.Block,
        'ViewIdsToPin': this.getViewsId(views)
      })
    };

    try{
      const response = await fetch(API_save_pinned_views, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SavePinnedViewsResult;

      if(result) {
        if(result.IsValid === true) {
          // Pin View
          if(pin === true) {
            // Call the event from the Parent component through the props with new View selected
            this.props.onViewChange(view);
          }
          // Unpin View which is current View
          else if(pin === false && view.ViewId === currentView.ViewId) {
            // Call the event from the Parent component through the props with new View selected
            this.props.onViewChange(views[0]);
          }

          this.setState({ views });
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async saveView(view, saveAs) {
    const { login, authId, language, defaultViewId } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Update HTML element class
    if(document.getElementById("saveView")) {
      document.getElementById("saveView").classList.remove("cursor");
      document.getElementById("saveView").classList.add("wait");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CurrentView': view,
        'SaveAs': saveAs
      })
    };

    try{
      const response = await fetch(API_save, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveViewResult;

      if(result) {
        if(result.IsValid === true) {
          const currentView = this.state.currentView;
          const views = this.state.views;
          let redirect = false;

          // Redirect to new ViewId created from Generic View
          if(currentView.ViewId === view.ViewId && view.UserId < 0) {
            redirect = true;
          }

          // Update CurrentView
          if(redirect === false && currentView.ViewId === view.ViewId) {
            // Reset CurrentView Modify attribute to false
            view.Modify = false;

            this.setState({ currentView: view });
          }
          else {
            // Update corresponding View in Views list
            if(views.find(item => item.ViewId === view.ViewId)) {
              views[views.findIndex(item => item.ViewId === view.ViewId)] = result.View;
            }

            // Set new View as default View
            if(view.ViewId === defaultViewId && view.UserId < 0) {
              this.setDefaultView(result.View);
            }

            // Redirect to new ViewId created from Generic View
            if(redirect === true) {
              // Call the event from the Parent component through the props with new View selected
              this.props.onViewChange(result.View);
            }
          }

          this.setState({ views });

          // Reset CurrentView Modify attribute to false
          // currentView.Modify = false;

          // let views = this.state.views;
          // let currentView = this.state.currentView;
  
          // // Reset CurrentView Modify attribute to false
          // currentView.Modify = false;
  
          // // Update Views with CurrentView
          // views[result.View.ViewType].Value[0] = result.View;
  
          // this.setState({ currentView, views });
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

      // Update HTML element class
      if(document.getElementById("saveView")) {
        document.getElementById("saveView").classList.remove("wait");
        document.getElementById("saveView").classList.add("cursor");
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async duplicateView(view, saveAs) {
    const { login, userId, authId, language, views } = this.state;
    let currentView;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Duplicate Current View
    let duplicataView = JSON.parse(JSON.stringify(view));

    // Update View
    duplicataView.Name = 'Copie de ' + view.Name;
    duplicataView.UserId = userId;
    duplicataView.Edit = true;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'CurrentView': duplicataView,
        'SaveAs': saveAs
      })
    };

    try{
      const response = await fetch(API_save, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveViewResult;

      if(result) {
        if(result.IsValid === true) {
          // Update View
          duplicataView = result.View;

          // Push Duplicata View
          views.unshift(duplicataView);

          // Update Current View
          currentView = duplicataView;

          this.setState({ views, currentView });

          // Call the event from the Parent component through the props with new View selected
          this.props.onViewChange(duplicataView);

          // // Update View
          // duplicataView = result.View;
          
          // // Push Duplicata View
          // views[viewIndex].Value.unshift(duplicataView);
  
          // // Update Current View
          // currentView = views[viewIndex].Value[0];
          
          // this.setState({ views, currentView });
  
          // // Call the event from the Parent component through the props with new View selected
          // this.props.onViewChange(currentView);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async deleteView(view) {
    const { login, authId, language, views, currentView } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ViewId': view.ViewId
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteViewResult;

      if(result) {
        if(result.IsValid === true) {
          // Remove View from pinned Views
          if(views.find(item => item.ViewId === view.ViewId)) {
            views.splice(views.findIndex(item => item.ViewId === view.ViewId), 1);
          }

          // Delete View which is current View
          if(view.ViewId === currentView.ViewId) {
            // Call the event from the Parent component through the props with new View selected
            this.props.onViewChange(views[0]);
          }

          this.setState({ views, currentView });
    
          // let indexToDelete;
  
          // if(views[viewIndex].Value.findIndex(v => v.ViewId === view.ViewId) >= 0) {
          //   // Get the index of the View index to delete
          //   indexToDelete = views[viewIndex].Value.findIndex(v => v.ViewId === view.ViewId);
  
          //   // Delete corresponding View in list
          //   views[viewIndex].Value.splice(indexToDelete, 1);
  
          //   // Update Current View
          //   currentView = views[viewIndex].Value[0];
  
          //   this.setState({ views, currentView });
  
          //   // Call the event from the Parent component through the props with new View selected
          //   this.props.onViewChange(currentView);
          // }
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  async setDefaultView(view) {
    const { login, authId, language } = this.state;

    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ViewId': view.ViewId
      })
    };

    try{
      const response = await fetch(API_default, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SetViewAsDefaultResult;

      if(result) {
        if(result.IsValid === true) {
          // Call the event from the Parent component through the props
          this.props.onSetDefaultView(view.ViewId);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  updateErrors(error) {
    // Call the event from the Parent component through the props
    this.props.onErrorsUpdate(error);
  }

  templatePopover(view, index) {
    const { userId, language, guestLicence, defaultViewId, currentView, views } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex flex-column">
            {/* Rename View */}
            {(view.RightType >= 3 || view.UserId < 0) && 
              <div id="renameView" className="viewAction cursor" onClick={(e) => this.renameView(view)}>
                <span className="iconRename iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'rename_view')}</span>
              </div>
            }
            {/* Save View Button */}
            {(view.RightType >= 3 || view.UserId < 0) && 
              <div id="saveView" className="viewAction cursor" onClick={(e) => this.saveView(view, false)}>
                <span className="iconSave iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'save_view')}</span>
              </div>
            }
            {/* Duplicate View Button */}
            <div id="duplicateView" className="viewAction cursor" onClick={(e) => this.duplicateView(view, true)}>
              <span className="iconDuplicate iconsPopover mr10"></span>
              <span className="viewActionLabel">{Traduction.translate(language, 'duplicate_view')}</span>
            </div>
            {/* Delete View Button */}
            {views.length > 1 && view.RightType >= 3 && 
              <div id="deleteView" className="viewAction cursor" onClick={(e) => this.deleteView(view)}>
                <span className="iconClear iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'delete_view')}</span>
              </div>
            }
            {/* Pin View Button */}
            {/* <div id="pinView" className="viewAction cursor" onClick={(e) => this.pinView(view)}>
              <span className="iconPin iconsPopover mr10"></span>
              <span className="viewActionLabel">{Traduction.translate(language, 'pin_view')}</span>
            </div> */}
            {/* Unpin View Button */}
            {views.length > 1 && view.ViewId !== defaultViewId && 
              <div id="unpinView" className="viewAction cursor" onClick={(e) => this.unpinView(view)}>
                <span className="iconUnpin iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'unpin_view')}</span>
              </div>
            }
            {/* Share View Button */}
            {view.RightType >= 4 && 
              <div id="shareView" className="viewAction cursor" onClick={(e) => this.shareView(view)}>
                <span className="iconShare iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'share_view')}</span>
              </div>
            }
            {/* Define as default View Button */}
            {view.ViewId !== defaultViewId && 
              <div id="setDefaultView" className="viewAction cursor" onClick={(e) => this.setDefaultView(view)}>
                <span className="iconStarGrey iconsPopover mr10"></span>
                <span className="viewActionLabel">{Traduction.translate(language, 'set_default_view')}</span>
              </div>
            }
            {/* Border */}
            <div className="popoverBorder"></div>
            {/* Right */}
            <div className="viewRight">
              {view.UserId === -1 && <span className="center">{Traduction.translate(language, 'generic_view')}</span>}
              {view.UserId > 0 && view.RightType < 3 && <span className="center">{Traduction.translate(language, 'reading_view')}</span>}
              {view.UserId > 0 && view.RightType === 3 && <span className="center">{Traduction.translate(language, 'modification_view')}</span>}
              {view.UserId > 0 && view.RightType === 4 && <span className="center">{Traduction.translate(language, 'admin_view')}</span>}
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closeViewsPopup() {
    this.setState({ viewsPopup: false });
  }

  closeRightsPopup() {
    this.setState({ rightsPopup: false });
  }
  
  render() {
    const { userId, language, resourceId, itemId, itemType, blockType, defaultViewId, currentView, views, viewItemId, viewItemType, viewItemTitle, viewsPopup, rightsPopup } = this.state;
    let link, month, year;
    
    // Get Current Year
    month = new Date().getMonth() + 1;
    year = new Date().getFullYear();

    return (
      <div className="blockViews">
        {/* Views Popup */}
        {viewsPopup && <PopupAddView ItemId={itemId} ItemType={itemType} BlockType={blockType} onViewAdd={this.pinView} onPopupClose={this.closeViewsPopup}></PopupAddView>}

        {/* Rights Popup */}
        {rightsPopup && <PopupEditRights ItemId={viewItemId} ItemType={viewItemType} ItemTitle={viewItemTitle} onPopupClose={this.closeRightsPopup}></PopupEditRights>}

        {/* Views Title */}
        <div className="viewsTitle">
          <div className="viewsLabel">{Traduction.translate(language, 'my_pinned_views')}</div>
          <div className="viewsIcons">
            <span className="iconPlus iconsPopover mh10 cursor" onClick={() => this.addView()}></span>
            {/* <span className="iconParametersBlue iconsPopover mh10 cursor"/> */}
            <span className="iconHideViews iconsPopover mh10 cursor" onClick={() => this.hideViews()}></span>
          </div>
        </div>
        {/* Views List */}
        <div className="viewsList scrollbar-y">
          {views.map((view, index) => {
            // Build View Link
            if(itemType === 'Administration' || itemType === 'Environment') {
              if(blockType === 'Roadmap') {
                link = "/" + itemType + "/" + blockType + "?viewId=" + view.ViewId + "&active=true";
              }
              else {
                link = "/" + itemType + "/" + blockType + "?viewId=" + view.ViewId;
              }
            }
            else if(blockType === 'TimeTrackingDay' || blockType === 'TimeTrackingMonth') {
              link = "/TimeTracking?viewId=" + view.ViewId + "&year=" + year + "&month=" + month + "&resourceId=" + itemId;
            }
            else if(blockType === 'Burned' || blockType === 'Workload') {
              link = "/Card/" + itemType + "/" + itemId + "/" + blockType + "?viewId=" + view.ViewId + "&year=" + year;
            }
            else {
              link = "/Card/" + itemType + "/" + itemId + "/" + blockType + "?viewId=" + view.ViewId;
            }

            return <div key={index} className={view.ViewId === currentView.ViewId ? "viewItemSelected": "viewItem"}>
              <Link className={view.Modify ? "viewLinkWithSave" : "viewLink"} to={link}>
                {/* ViewType Icon */}
                {view.ViewType === 0 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeTable" : "iconViewTypeTableGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 1 && <span className={(view.ViewId === defaultViewId ? "iconViewTypePlanning" : "iconViewTypePlanningGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 2 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeKanban" : "iconViewTypeKanbanGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 3 && <span className={(view.ViewId === defaultViewId ? "iconViewTypePivot" : "iconViewTypePivotGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 4 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeList" : "iconViewTypeListGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 6 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeScheduler" : "iconViewTypeSchedulerGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 7 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeChart" : "iconViewTypeChartGrey") + " iconsViews mr10"}></span>}
                {view.ViewType === 8 && <span className={(view.ViewId === defaultViewId ? "iconViewTypeMap" : "iconViewTypeMapGrey") + " iconsViews mr10"}></span>}
                
                {/* View Name */}
                {view.Edit !== true && <span className="viewName">{view.Name}</span>}
                {view.Edit === true && <Form.Control type="text" className="viewNameSelected" value={view.Name} autoFocus onChange={(e) => this.changeViewName(e, view)} onBlur={(e) => this.saveViewName(e, view)}/>}
              </Link>
              {/* Save View */}
              {view.Modify && <div className="flex align-items-center ml10">
                <span id="saveView" className="iconSave iconsViews cursor" onClick={() => this.saveView(view, false)}></span>
              </div>}
              <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.templatePopover(view, index)}>
                <div className="flex align-items-center ml10"><span className="iconViewsActions iconsViewsActions cursor"></span></div>
              </OverlayTrigger>
            </div>;
          })}
        </div>
      </div>
    )
  }
}

export default FiltersView;