import React, {Component} from 'react';
import { Card } from 'react-bootstrap';
import '../Css/App.css';
import 'react-sweet-progress/lib/style.css';
import Authentication from '../Authentication';
import Axe from './Axe';
import Timeline from './Timeline';

class MiniCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      content: {},
      name: null,
      status: null,
      startDate: null,
      endDate: null,
      progress: null
    };

    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.displayMinicardIcon = this.displayMinicardIcon.bind(this);
    this.displayMinicardTitle = this.displayMinicardTitle.bind(this);
    this.displayMinicardContent = this.displayMinicardContent.bind(this);
    this.displayMinicardFooter = this.displayMinicardFooter.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const content = this.props.Content;
    const name = this.props.Name;
    const status = this.props.Status;
    const startDate = this.props.StartDate;
    const endDate = this.props.EndDate;
    const progress = this.props.Progress;

    this.setState({ language, formatDate, itemId, itemType, content, name, status, startDate, endDate, progress });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const content = this.props.Content;
    const name = this.props.Name;
    const status = this.props.Status;
    const startDate = this.props.StartDate;
    const endDate = this.props.EndDate;
    const progress = this.props.Progress;
    
    if(this.props.Id !== prevProps.Id || this.props.Type !== prevProps.Type) {
      this.setState({ itemId, itemType, content, name, status, startDate, endDate, progress });
    }
  }

  formatDate(date) {
    const formatDate = this.state.formatDate;

    if(formatDate === 'MM/DD/YYYY') {
      return this.formatDateEn(date);
    }
    else if(formatDate === 'DD/MM/YYYY') {
      return this.formatDateFr(date);
    }
    else if(formatDate === 'YYYY-MM-DD') {
      return this.formatDateKr(date);
    }
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  // Display Minicard Icon depending on the ItemType
  displayMinicardIcon(type) {
    switch(type) {
      case "AgileBoard":
          return <div className="minicardIcon"><span className="iconAgileBoard iconsMinicard" alt="Agile Board"/></div>;
      case "Sprint":
          return <div className="minicardIcon"><span className="iconSprint iconsMinicard" alt="Sprint"/></div>;
      case "Business_Line":
          return <div className="minicardIcon"><span className="iconBusinessLine iconsMinicard" alt="Business Line"/></div>;
      case "Project":
          return <div className="minicardIcon"><span className="iconProject iconsMinicard" alt="Project"/></div>;
      case "Workpackage":
          return <div className="minicardIcon"><span className="iconWorkpackage iconsMinicard" alt="Workpackage"/></div>;
      case "Action":
          return <div className="minicardIcon"><span className="iconAction iconsMinicard" alt="Action"/></div>;
      case "Task":
          return <div className="minicardIcon"><span className="iconTask iconsMinicard" alt="Task"/></div>;
      case "Decision":
          return <div className="minicardIcon"><span className="iconDecisions iconsMinicard" alt="Decision"/></div>;
      case "Issue":
          return <div className="minicardIcon"><span className="iconIssues iconsMinicard" alt="Issue"/></div>;
      case "Meeting":
          return <div className="minicardIcon"><span className="iconMeetings iconsMinicard" alt="Meeting"/></div>;
      case "Risk":
          return <div className="minicardIcon"><span className="iconRisks iconsMinicard flex" alt="Risk"/></div>;
      case "BudgetCode":
          return <div className="minicardIcon"><span className="iconBudgetCode iconsMinicard" alt="Budget Code"/></div>;
      case "Entity":
          return <div className="minicardIcon"><span className="iconEntity iconsMinicard" alt="Entity"/></div>;
      case "Resource":
          return <div className="minicardIcon"><span className="iconResource iconsMinicard" alt="Resource" /></div>;
      case "User":
          return <div className="minicardIcon"><span className="iconUser iconsMinicard" alt="User"/></div>;
      default:
          return <div className="minicardIcon"><span className="iconAxe iconsMinicard" alt="Axe"/></div>;
    }
  }

  // Display Minicard Title
  displayMinicardTitle(name, title) {
    return <div className="minicardTitle" title={title}>{name}</div>;
  }

  // Define the attributes to display on the Minicards depending on the Object type
  displayMinicardContent(itemType, content) {
    switch(itemType) {
      case "Business_Line":
      case "Project":
      case "Workpackage":
      case "Action":
      case "Task":
          return <div className="mb20"><Timeline View={'Minicard'} StartDate={content.StartDate} EndDate={content.EndDate} Status={JSON.parse(content.Status)}></Timeline></div>;
      case "Decision":
      case "Issue":
      case "Risk":
        return <div className="minicardContent">
          <div className="col-md-6"><Axe Axe={'Status'} Value={JSON.parse(content.Status)} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><span className="fs12">{this.formatDate(content.EndDate)}</span></div>
        </div>;
      case "Meeting":
        return <div className="minicardContent">
          <div className="col-md-12"><Axe Axe={'Project'} Value={JSON.parse(content.Project)} View={'Minicard'}></Axe></div>
        </div>;
      case "Entity":
        return <div className="minicardContent">
          <div className="col-md-6"><Axe Axe={'Manager'} Value={content.Manager} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Location'} Value={JSON.parse(content.Location)} View={'Minicard'}></Axe></div>
        </div>;
      case "Resource":
        return <div className="minicardContent">
          <div className="col-md-6"><Axe Axe={'External'} Value={content.External} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Location'} Value={JSON.parse(content.Location)} View={'Minicard'}></Axe></div>
        </div>;
      case "AgileBoard":
        return <div className="minicardContent">
          <div className="col-md-12"><Axe Axe={content.Item_Type} Value={content.Item_Type} View={'Minicard'}></Axe></div>
        </div>;
      // TO DO
      case "BudgetCode":
        return <div className="minicardContent">
          <div className="col-md-12">{content.Description}</div>
        </div>;
      default:
        return <div className="minicardContent">
          <div className="col-md-12"><Axe Axe={content.Item_Type} Value={content.Field_Label} View={'Minicard'}></Axe></div>
        </div>;
    }
  }

  // Define the attributes to display on the Minicards depending on the Object type
  displayMinicardFooter(itemType, content) {
    let meteo;

    // Avoid Error if Meteo Empty
    if(content.Meteo && content.Meteo !== "") {
      meteo = JSON.parse(content.Meteo);
    }

    switch(itemType) {
      case "Business_Line":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Warning'} Value={content.Warning} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Progress'} Value={content.Progress} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Meteo'} Value={meteo} View={'Minicard'}></Axe></div>
        </div>;
      case "Project":
      case "Workpackage":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Status'} Value={JSON.parse(content.Status)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Progress'} Value={content.Progress} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Meteo'} Value={meteo} View={'Minicard'}></Axe></div>
        </div>;
      case "Action":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Status'} Value={JSON.parse(content.Status)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Progress'} Value={content.Progress} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Priority'} Value={JSON.parse(content.Priority)} View={'Minicard'}></Axe></div>
        </div>;
      case "Task":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Status'} Value={JSON.parse(content.Status)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Progress'} Value={content.Progress} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Resource'} Value={JSON.parse(content.Resource)} View={'Minicard'}></Axe></div>
        </div>;
      case "Decision":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Decision_Type'} Value={JSON.parse(content.Decision_Type)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Meeting'} Value={JSON.parse(content.Meeting)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Owner'} Value={JSON.parse(content.Owner)} View={'Minicard'}></Axe></div>
        </div>;
      case "Issue":
        return <div className="minicardFooter">
          <div className="col-md-6"><Axe Axe={'Priority'} Value={JSON.parse(content.Priority)} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Owner'} Value={JSON.parse(content.Owner)} View={'Minicard'}></Axe></div>
        </div>;
      case "Meeting":
        return <div className="minicardFooter">
          <div className="col-md-6"><Axe Axe={'Status'} Value={JSON.parse(content.Status)} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Owner'} Value={JSON.parse(content.Owner)} View={'Minicard'}></Axe></div>
        </div>;
      case "Risk":
        return <div className="minicardFooter">
          <div className="col-md-4"><Axe Axe={'Probability'} Value={JSON.parse(content.Probability)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Impact'} Value={JSON.parse(content.Impact)} View={'Minicard'}></Axe></div>
          <div className="col-md-4"><Axe Axe={'Owner'} Value={JSON.parse(content.Owner)} View={'Minicard'}></Axe></div>
        </div>;
      case "Entity":
        return <div className="minicardFooter">
          <div className="col-md-6"><Axe Axe={'Entity_Type'} Value={JSON.parse(content.Entity_Type)} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Department'} Value={JSON.parse(content.Department)} View={'Minicard'}></Axe></div>
        </div>;
      case "Resource":
        return <div className="minicardFooter">
          <div className="col-md-6"><Axe Axe={'Resource_Type'} Value={JSON.parse(content.Resource_Type)} View={'Minicard'}></Axe></div>
          <div className="col-md-6"><Axe Axe={'Contract_Type'} Value={JSON.parse(content.Contract_Type)} View={'Minicard'}></Axe></div>
        </div>;
      // TO DO
      case "BudgetCode":
        return <div className="minicardFooter">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>;
      default:
        return <div className="minicardFooter">
          <div className="col-md-6"></div>
          <div className="col-md-6"></div>
        </div>;
    }
  }

  render() {
    let { itemId, itemType, content, name, status, startDate, endDate, progress } = this.state;
    let meetingName, meetingTitle;

    // Meetings Name & Title
    if(itemType === 'Meeting') {
      if(content.EndDate) {
        if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length <= 35) {
          meetingName = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
          meetingTitle = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
        }
        else if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length > 35) {
          meetingName = JSON.parse(content.Meeting_Type).Label.substring(0,35) + " - " + this.formatDate(content.EndDate);
          meetingTitle = JSON.parse(content.Meeting_Type).Label + " - " + this.formatDate(content.EndDate);
        }
      }
      else {
        if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length <= 48) {
          meetingName = JSON.parse(content.Meeting_Type).Label;
          meetingTitle = JSON.parse(content.Meeting_Type).Label;
        }
        else if(content.Meeting_Type && JSON.parse(content.Meeting_Type).Label.length > 48) {
          meetingName = JSON.parse(content.Meeting_Type).Label.substring(0,48);
          meetingTitle = JSON.parse(content.Meeting_Type).Label;
        }
      }
    }

    return (
      <div className="">
        {Object.entries(content).length > 0 && itemType && 
          <Card className="minicard">
            {/* MiniCard Header except Meetings */}
            {itemType !== 'Meeting' && <Card.Header className="minicardHeader">
              {this.displayMinicardIcon(itemType)}
              {this.displayMinicardTitle(content.Name, content.Name)}
            </Card.Header>}
            {/* MiniCard Header Meetings */}
            {itemType === 'Meeting' && <Card.Header className="minicardHeader">
              {this.displayMinicardIcon(itemType)}
              {this.displayMinicardTitle(meetingName, meetingTitle)}
            </Card.Header>}
            {/* MiniCard Content & Footer */}
            <Card.Body className="minicardBody">
              {this.displayMinicardContent(itemType, content)}
              {this.displayMinicardFooter(itemType, content)}
            </Card.Body>
          </Card>
        }
      </div>
    )
  }
}

export default MiniCard;