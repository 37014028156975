import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersDisplayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      blockType: null,
      currentView: {},
      display: true
    };

    this.changeDisplay = this.changeDisplay.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const display = this.props.Display;
    const displayGraph = this.props.DisplayGraph;
    const displayHeatmap = this.props.DisplayHeatmap;
    
    this.setState({ language, blockType, currentView, display, displayGraph, displayHeatmap });
  }

  componentDidUpdate(prevProps) {
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const display = this.props.Display;
    const displayGraph = this.props.DisplayGraph;
    const displayHeatmap = this.props.DisplayHeatmap;

    if(this.props.CurrentView !== prevProps.CurrentView) {
      this.setState({ currentView });
    }
    if(this.props.Display !== prevProps.Display) {
      this.setState({ display });
    }
    if(this.props.DisplayGraph !== prevProps.DisplayGraph || this.props.DisplayHeatmap !== prevProps.DisplayHeatmap) {
      this.setState({ displayGraph, displayHeatmap });
    }
  }

  changeDisplay() {
    const display = this.state.display;

    // Call the event from the Parent component through the props
    this.props.onDisplayChange();

    this.setState({ display: !display });
  }

  render() {
    const { language, blockType, currentView, display, displayGraph, displayHeatmap } = this.state;

    return (
      <div className="">
        {(blockType === 'Details' || blockType === 'BudgetDetails' || blockType === 'Meetings' || blockType === 'Risks') && <div className="">
          {['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'change_display')}</strong></Tooltip>}>
              <div className="filtersDisplayComponent" onClick={() => this.changeDisplay()}>
                <span className={"iconsFilter cursor " + (display ? "iconHideComponent" : "iconShowComponent")}/>
              </div>
            </OverlayTrigger>
          ))}
        </div>}
        {(blockType === 'Burned' || blockType === 'Workload') && <div className="">
          {['top'].map(placement => (
            <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'change_display')}</strong></Tooltip>}>
              <div className="filtersDisplayComponent" onClick={() => this.props.onDisplayChange()}>
                {displayGraph && displayHeatmap && <span className={"iconsFilter cursor iconShowHeatmap"}/>}
                {!displayGraph && displayHeatmap && <span className={"iconsFilter cursor iconShowGraph"}/>}
                {displayGraph && !displayHeatmap && <span className={"iconsFilter cursor iconShowBothComponent"}/>}
              </div>
            </OverlayTrigger>
          ))}
        </div>}
      </div>
    )
  }
}

export default FiltersDisplayComponent;