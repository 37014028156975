import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
// import FiltersView from './FiltersView';
// import FiltersBlock from './FiltersBlock';
// import FiltersSort from './FiltersSort';
// import FiltersColumnChooser from './FiltersColumnChooser';
// import FiltersImport from './FiltersImport';
// import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import Table from './Table';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';
const API_create = '/WebAppService/InsertItemAndSave';
const API_delete = '/WebAppService/DeleteItemAndSave';

class EnvironmentCustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockContent: {},
      blockInfo: {},
      editable: false,
      views: [],
      currentView: {},
      tables: [],
      columns: [],
      rows: [],
      search: '',
      isLoading: false,
      errors: []
    };

    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);

    // Actions
    this.checkModification = this.checkModification.bind(this);
    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.addRowsInTable = this.addRowsInTable.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    this.views = React.createRef();
    this.table = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    this.setState({ login, authId, language, itemId, itemType, blockType, isLoading: true });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType);

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = 0;
    const itemType = 'Environment';
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get Card Data
    // await this.getCardData(login, authId, itemId, itemType, blockType, axis);
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);
  
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
  
      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;
  
      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;

        this.setState({ blockInfo, itemTitle, editable });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType) {
    const language = this.state.language;
    let additionalContext = [];

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);
  
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }
  
      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;
  
      if(blockContent) {
        const views = blockContent.Views;
        const tables = blockContent.Tables;
        let currentView = {}, columns = [], rows = [];

        // Get Current View
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Tree")) {
          columns = tables.find(element => element.Level === "Tree").ColumnHeaders;
          rows = tables.find(element => element.Level === "Tree").Rows;
        }

        this.setState({ blockContent, views, currentView, tables, columns, rows, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getColumnsName() {
    let columns = this.state.columns;
    let columnNames = [];

    columns.forEach(column => {
      columnNames.push(column.FieldName);
    });

    return columnNames;
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language } = this.state;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            if(modification.ColumnName !== 'Formula') {
              // Update Rows in Components DataSource
              if(this.table.current) {
                this.table.current.updateDatasource(modification, propagations, warnings);
              }

              // Update Rows in Data Table
              this.updateRowsInTable(modification);
            }
          }
          // If the Modification is not valid
          else {
            // Update Datasource with OldValue
            if(this.table.current) {
              this.table.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async createItem(name, itemType) {
    const { login, authId, language } = this.state;
    let columns = this.getColumnsName();
    let additionalContext = [];

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'Name': name,
        'NbItems': '1',
        'Fields': columns,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.InsertItemAndSaveResult;

      // Check request response
      if(result) {
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Get new Rows
          const newRows = result.InsertedItems.Rows;

          // Add Rows in Components DataSource
          if(this.table.current) {
            this.table.current.addRowsInDatasource(newRows);
          }

          // Add Rows in Data Table
          this.addRowsInTable(newRows);
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'creation_impossible'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(itemId, itemType) {
    const { login, authId, language } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete & Propagations
        const modification = result.Delete;
        const propagations = result.Propagations;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Delete Row in Components DataSource
          if(this.table.current) {
            this.table.current.deleteRowInDatasource(modification);
          }

          // Delete Row in Data Table
          this.deleteRowInTable(modification, propagations);
        }
        else {
          // Update Errors
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  updateRowsInTable(modification) {
    let rows = this.state.rows;
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === modification.ColumnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }
    }

    this.setState({ rows });
  }

  addRowsInTable(newRows) {
    let rows = this.state.rows;

    // Push new Rows
    newRows.forEach(row => {
      rows.push(row);
    });

    this.setState({ rows });
  }

  deleteRowInTable(modification) {
    let rows = this.state.rows;
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to Delete
    const indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId);

    // Delete Row
    if(indexToDelete >= 0) {
      rows.splice(indexToDelete, 1);
    }

    this.setState({ rows });
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.table.current) {
      this.table.current.searchItem(search);
    }

    this.setState({ search });
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    let { language, itemId, itemType, itemTitle, blockType, blockInfo, blockContent, editable, currentView, tables, columns, rows, search, isLoading, errors } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'environment_custom_fields')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Environment Block Header */}
          <div className="blockHeader">
            {/* Title */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Environment Block Body */}
          <div className="blockBody">
            {/* Card Block Content */}
            <div className="blockContent">
              {/* Filters */}
              <div className="blockFilters">
                <div className="views">
                  {/* Filters View */}
                  {/* <FiltersView ref={this.views} BlockType={blockType} CurrentView={currentView} Views={views} onViewChange={this.changeView}></FiltersView> */}
                </div>
                <div className="filters">
                  {/* Add Button */}
                  {(editable && currentView.ViewType !== 3) && 
                    <span className="iconButtonPlus mediumIcons mh10 cursor" onClick={(e) => this.createItem('', 'CustomField')}></span>
                  }

                  {/* Block Filters */}
                  {/* <FiltersBlock ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock> */}

                  {/* Sort Filters */}
                  {/* <FiltersSort ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort> */}

                  {/* Columns Chooser */}
                  {/* {(currentView.ViewType === 0 || currentView.ViewType === 1) && 
                    <FiltersColumnChooser ItemId={itemId} ItemType={itemType} Columns={columns} CurrentView={currentView} onColumnsChange={this.changeColumns}></FiltersColumnChooser>
                  } */}

                  {/* Search input */}
                  {currentView.ViewType !== 3 && <div className="filtersSearch">
                    <Form.Group className="searchBlock">
                      <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
                    </Form.Group>
                  </div>}

                  {/* Import */}
                  {/* {currentView.ViewType === 0 && 
                    <FiltersImport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDataImport={this.getCardData}></FiltersImport>
                  } */}

                  {/* Export */}
                  {/* <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport> */}
                </div>
              </div>
              
              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>}

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* View Components */}
                {rows.length > 0 && <Table ref={this.table} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} CurrentView={currentView} Columns={columns} Rows={rows} onViewChange={this.updateView} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default EnvironmentCustomFields;