import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      views: [],
      levels: [],
      rows: [],
      nbTotal: 0,
      nbBusinessLine: 0,
      nbProjects: 0,
      nbWorkpackage: 0,
      nbAction: 0,
      nbTask: 0,
      nbSprint: 0,
      nbEntity: 0,
      nbResource: 0
    };
    
    // Data Structure
    this.getObjectLevel = this.getObjectLevel.bind(this);
    this.getLevelIcon = this.getLevelIcon.bind(this);
    this.getObjectsTotal = this.getObjectsTotal.bind(this);
    this.getObjectsNumberByType = this.getObjectsNumberByType.bind(this);

    // Actions
    this.changeLevel = this.changeLevel.bind(this);
    this.refreshIndicators = this.refreshIndicators.bind(this);

    // Template
    this.templatePopover = this.templatePopover.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const levels = this.props.Levels;
    const rows = this.props.Rows;
    
    // Refresh Indicators
    this.refreshIndicators(rows);

    this.setState({ language, itemId, itemType, blockType, currentView, views, levels, rows });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const views = this.props.Views;
    const levels = this.props.Levels;
    const rows = this.props.Rows;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(this.props.CurrentView !== prevProps.CurrentView || this.props.Views !== prevProps.Views) {
      this.setState({ currentView, views });
    }
    if(this.props.Levels !== prevProps.Levels) {
      this.setState({ levels });
    }
    if(JSON.stringify(this.props.Rows) != JSON.stringify(prevProps.Rows)) {
      // Refresh Indicators
      this.refreshIndicators(rows);

      this.setState({ rows });
    }
  }

  getObjectLevel(currentView) {
    const { language, nbTotal, nbBusinessLine, nbProjects, nbWorkpackage, nbAction, nbTask, nbSprint, nbEntity, nbResource } = this.state;

    // Current View Chart
    if(currentView.ViewType === 7) {
      switch (currentView.DefaultLevel) {
        case 0:
            return <span className="fs12 bold">{Traduction.translate(language, 'tree')}</span>;
        case 1:
            return <span className="fs12 bold">{Traduction.translate(language, 'business_line')}</span>;
        case 2:
            return <span className="fs12 bold">{Traduction.translate(language, 'project')}</span>;
        case 3:
            return <span className="fs12 bold">{Traduction.translate(language, 'workpackage')}</span>;
        case 4:
            return <span className="fs12 bold">{Traduction.translate(language, 'action')}</span>;
        case 5:
            return <span className="fs12 bold">{Traduction.translate(language, 'task')}</span>;
        case 12:
            return <span className="fs12 bold">{Traduction.translate(language, 'resource')}</span>;
        case 13:
            return <span className="fs12 bold">{Traduction.translate(language, 'entity')}</span>;
        case 24:
            return <span className="fs12 bold">{Traduction.translate(language, 'sprint')}</span>;
        default:
            break;
      }
    }
    else {
      switch (currentView.DefaultLevel) {
        case 0:
            return <span className="fs12 bold">{Traduction.translate(language, 'tree')} ({nbTotal})</span>;
        case 1:
            return <span className="fs12 bold">{Traduction.translate(language, 'business_line')} ({nbBusinessLine})</span>;
        case 2:
            return <span className="fs12 bold">{Traduction.translate(language, 'project')} ({nbProjects})</span>;
        case 3:
            return <span className="fs12 bold">{Traduction.translate(language, 'workpackage')} ({nbWorkpackage})</span>;
        case 4:
            return <span className="fs12 bold">{Traduction.translate(language, 'action')} ({nbAction})</span>;
        case 5:
            return <span className="fs12 bold">{Traduction.translate(language, 'task')} ({nbTask})</span>;
        case 12:
            return <span className="fs12 bold">{Traduction.translate(language, 'resource')} ({nbResource})</span>;
        case 13:
            return <span className="fs12 bold">{Traduction.translate(language, 'entity')} ({nbEntity})</span>;
        case 24:
            return <span className="fs12 bold">{Traduction.translate(language, 'sprint')} ({nbSprint})</span>;
        default:
            break;
      }
    }
  }

  getLevelIcon(currentView) {
    switch(currentView.DefaultLevel) {
      case 0:
          return <span className="iconTree iconsFilter mr10" alt="Tree"/>;
      case 1:
          return <span className="iconBusinessLine iconsFilter mr10" alt="Business Line"/>;
      case 2:
          return <span className="iconProject iconsFilter mr10" alt="Project"/>;
      case 3:
          return <span className="iconWorkpackage iconsFilter mr10" alt="Workpackage"/>;
      case 4:
          return <span className="iconAction iconsFilter mr10" alt="Action"/>;
      case 5:
          return <span className="iconTask iconsFilter mr10" alt="Task"/>;
      case 12:
          return <span className="iconResource iconsFilter mr10" alt="Resource"/>;
      case 13:
          return <span className="iconEntityBlue iconsFilter mr10" alt="Entity"/>;
      case 24:
          return <span className="iconSprintBlue iconsFilter mr10" alt="Sprint" />;
      default:
          break;
    }
  }

  // Get the total number of Objects
  getObjectsTotal(rows) {
    let result;

    if(rows.length) {
      result = rows.length;
    }
    else {
      result = 0;
    }
    
    return result;
  }

  // Get the number of Objects by Type
  getObjectsNumberByType(rows, type) {
    let result = 0;

    if(rows) {
      rows.forEach(row => {
        if(row.ItemType === type) {
          result ++;
        }
      });
    }

    return result;
  }

  changeLevel(level) {
    // Call the event from the Parent component through the props with new Level selected
    this.props.onLevelChange(level);
  }

  refreshIndicators(rows) {
    const nbTotal = this.getObjectsTotal(rows);
    const nbBusinessLine = this.getObjectsNumberByType(rows, 'Business_Line');
    const nbProjects = this.getObjectsNumberByType(rows, 'Project');
    const nbWorkpackage = this.getObjectsNumberByType(rows, 'Workpackage');
    const nbAction = this.getObjectsNumberByType(rows, 'Action');
    const nbTask = this.getObjectsNumberByType(rows, 'Task');
    const nbSprint = this.getObjectsNumberByType(rows, 'Sprint');
    const nbEntity = this.getObjectsNumberByType(rows, 'Entity');
    const nbResource = this.getObjectsNumberByType(rows, 'Resource');

    this.setState({ nbTotal, nbBusinessLine, nbProjects, nbWorkpackage, nbAction, nbTask, nbSprint, nbEntity, nbResource, nbEntity, nbResource });
  }

  templatePopover() {
    const { language, currentView, levels, nbTotal, nbBusinessLine, nbProjects, nbWorkpackage, nbAction, nbTask, nbSprint, nbEntity, nbResource } = this.state;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {levels && levels.map((lvl, index) => <div key={index} className="filtersLevelPopover">
            {/* Level = Tree */}
              {lvl === 'Tree' && (currentView.ViewType !== 2 && currentView.ViewType !== 3 && currentView.ViewType !== 7) &&
              <div className={"levelList " + (currentView.DefaultLevel === 0 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconTree icons mr10" alt="Tree"></span>
                <span className="fs12">{Traduction.translate(language, 'tree')}</span>
              </div>
            }
            {/* Level = Business Line */}
            {lvl === 'Business_Line' && 
              <div className={"levelList " + (currentView.DefaultLevel === 1 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconBusinessLine icons mr10" alt="Business Line"></span>
                <span className="fs12">{Traduction.translate(language, 'business_line')}</span>
              </div>
            }
            {/* Level = Project */}
            {lvl === 'Project' && 
              <div className={"levelList " + (currentView.DefaultLevel === 2 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <i className="iconProject icons mr10" alt="Project"/>
                <span className="fs12">{Traduction.translate(language, 'project')}</span>
              </div>
            }
            {/* Level = Workpackage */}
            {lvl === 'Workpackage' && 
              <div className={"levelList " + (currentView.DefaultLevel === 3 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconWorkpackage icons mr10" alt="Workpackage"></span>
                <span className="fs12">{Traduction.translate(language, 'workpackage')}</span>
              </div>
            }
            {/* Level = Action */}
            {lvl === 'Action' && 
              <div className={"levelList " + (currentView.DefaultLevel === 4 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconAction icons mr10" alt="Action"></span>
                <div className="fs12">{Traduction.translate(language, 'action')}</div>
              </div>
            }
            {/* Level = Task */}
            {lvl === 'Task' && 
              <div className={"levelList " + (currentView.DefaultLevel === 5 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconTask icons mr10" alt="Task"></span>
                <span className="fs12">{Traduction.translate(language, 'task')}</span>
              </div>
            }
            {/* Level = Sprint */}
            {lvl === 'Sprint' &&
              <div className={"levelList " + (currentView.DefaultLevel === 24 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconSprintBlue icons mr10" alt="Sprint"></span>
                <span className="fs12">{Traduction.translate(language, 'sprint')}</span>
              </div>
            }
            {/* Level = Resource */}
            {lvl === 'Resource' && 
              <div className={"levelList " + (currentView.DefaultLevel === 12 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconResource icons mr10" alt="Resource"></span>
                <span className="fs12">{Traduction.translate(language, 'resource')}</span>
              </div>
            }
            {/* Level = Entity */}
            {lvl === 'Entity' && 
              <div className={"levelList " + (currentView.DefaultLevel === 13 ? "bold" : "opacity50")} onClick={(e) => this.changeLevel(lvl)}>
                <span className="iconEntityBlue icons mr10" alt="Entity"></span>
                <span className="fs12">{Traduction.translate(language, 'entity')}</span>
              </div>
            }
          </div>)}
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, itemType, currentView, views, levels, rows, nbTotal, nbBusinessLine, nbProjects, nbWorkpackage, nbAction, nbTask, nbSprint, nbEntity, nbResource } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'display_level')}</strong></Tooltip>}>
            <div className="filtersLevel">
              <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover()}>
                <div className="inline-flex align-items-center cursor">{this.getLevelIcon(currentView)} {this.getObjectLevel(currentView)}</div>
              </OverlayTrigger>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersLevel;