import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import '../Css/App.css';

class Parents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parents: []
    };
  }

  componentDidMount() {
    this.setState({ parents: this.props.Parents });
  }

  componentDidUpdate(prevProps) {
    if(this.props.Parents !== prevProps.Parents) {
      this.setState({ parents: this.props.Parents });
    }
  }
  
  render() {
    const { parents } = this.state;

    return (
      <div className="cardParents">
        {/* Parents */}
        <div className="breadcrumb">
          {parents && parents.map((item, index) => (
            <div key={index} className="flex">
              {/* Link */}
              <Link className="flex align-items-center" to={`/Card/${parents[index].ItemType}/${parents[index].ItemId}/Home`}>
                {/* Icons */}
                {item.ItemType === 'Business_Line' && <div className="pathIcon"><span className="iconBusinessLine iconsPath" alt="Business Line"></span></div>}
                {item.ItemType === 'Project' && <div className="pathIcon"><span className="iconProject iconsPath" alt="Project"></span></div>}
                {item.ItemType === 'Workpackage' && <div className="pathIcon"><span className="iconWorkpackage iconsPath" alt="Workpackage"></span></div>}
                {item.ItemType === 'Action' && <div className="pathIcon"><span className="iconAction iconsPath" alt="Action"></span></div>}
                {item.ItemType === 'Task' && <div className="pathIcon"><span className="iconTask iconsPath" alt="Task"></span></div>}
                {item.ItemType === 'Entity' && <div className="pathIcon"><span className="iconEntity iconsPath" alt="Entity"></span></div>}
                {item.ItemType === 'AgileBoard' && <div className="pathIcon"><span className="iconAgileBoard iconsPath" alt="Agile Board"></span></div>}
                {/* Name */}
                {item.Name.length <= 25 && <div className="linkParents">{item.Name}</div>}
                {item.Name.length > 25 && <div className="linkParents" title={item.Name}>{item.Name.substring(0,24)}...</div>}
              </Link>
              {/* Arrow */}
              {parents[index+1] && <div className="flex align-items-center"><span className="iconArrowRight iconsPath mh5" alt=""/></div>}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Parents;