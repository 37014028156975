import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import MiniCard from './MiniCard';

class FavoritesCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      items: [],
      favorites: [],
      isLoading: false,
      error: null
    };

    // Data Structure
    this.getFavorites = this.getFavorites.bind(this);
    this.getLabel = this.getLabel.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const items = this.props.Items;
    
    this.setState({ authId, language, items });

    // Build Favorites Minicards based on Items
    this.getFavorites(items);
  }

  componentDidUpdate(prevProps) {
    const items = this.props.Items;
    
    if(this.props.Items !== prevProps.Items) {
      this.setState({ items });

      // Build Favorites Minicards based on Items
      this.getFavorites(items);
    }
  }

  getFavorites(items) {
    let favorites = [];
    let favorite, label, type;

    // Build Favorite Minicards Data structure based on Items array
    items.forEach(item => {
      favorite = item.Rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Add the Column Name/Values to the reduced Table
          acc[item.ColumnName] = item.Value;

          // Store Row Item Type
          acc['Item_Type'] = row.ItemType;
          type = row.ItemType;

          // Store Row Field Label
          if(item.ColumnName === 'Field_Label') {
            label = item.Value;
          }

          return acc;
        }, {});
      });

      // Push items grouped by Type inside the Favorites array
      favorites.push({ "Items": favorite, "Type": type, "Label": this.getLabel(label, type) });
    });

    this.setState({ favorites });
  }

  getLabel(label, type) {
    const language = this.state.language;

    switch(type) {
      case "Business_Line":
          return Traduction.translate(language, 'business_line');
      case "Project":
          return Traduction.translate(language, 'project');
      case "Workpackage":
          return Traduction.translate(language, 'workpackage');
      case "Action":
          return Traduction.translate(language, 'action');
      case "Task":
          return Traduction.translate(language, 'task');
      case "AgileBoard":
          return Traduction.translate(language, 'agile_board');
      case "Entity":
          return Traduction.translate(language, 'entity');
      case "Resource":
          return Traduction.translate(language, 'resource');
      case "Meeting":
          return Traduction.translate(language, 'meeting');
      case "Global_Business_Line":
          return Traduction.translate(language, 'global_business_line');
      case "Global_Project":
          return Traduction.translate(language, 'global_project');
      case "Global_Workpackage":
          return Traduction.translate(language, 'global_workpackage');
      case "Global_Action":
          return Traduction.translate(language, 'global_action');
      case "Global_Task":
          return Traduction.translate(language, 'global_task');
      case "Project_Type":
          return Traduction.translate(language, 'project_type');
      case "Task_Type":
          return Traduction.translate(language, 'task_type');
      case "Contract_Type":
          return Traduction.translate(language, 'contract_type');
      case "Decision_Type":
          return Traduction.translate(language, 'decision_type');
      case "Entity_Type":
          return Traduction.translate(language, 'entity_type');
      case "Issue_Type":
          return Traduction.translate(language, 'issue_type');
      case "Meeting_Type":
          return Traduction.translate(language, 'meeting_type');
      case "Risk_Type":
          return Traduction.translate(language, 'risk_type');
      case "Work_Type":
          return Traduction.translate(language, 'work_type');
      case "Application":
          return Traduction.translate(language, 'application');
      case "Asset":
          return Traduction.translate(language, 'asset');
      case "Category":
          return Traduction.translate(language, 'category');
      case "Department":
          return Traduction.translate(language, 'department');
      case "Feature":
          return Traduction.translate(language, 'feature');
      case "Highlighted":
          return Traduction.translate(language, 'highlighted');
      case "Location":
          return Traduction.translate(language, 'location');
      case "Mission":
          return Traduction.translate(language, 'mission');
      case "Offer":
          return Traduction.translate(language, 'offer');
      case "Package":
          return Traduction.translate(language, 'package');
      case "Phase":
          return Traduction.translate(language, 'phase');
      case "Project_Nature":
          return Traduction.translate(language, 'project_nature');
      case "Sponsor":
          return Traduction.translate(language, 'sponsor');
      case "Sub_Status":
          return Traduction.translate(language, 'sub_status');
      default:
          return label;
    }
  }
  
  render() {
    let { language, items, favorites, isLoading, error } = this.state;

    return (
      <div className="">
        {/* Title */}
        <div className="homeLabel"><span className="iconStarYellow icons icon nav mr10 mt-5"></span>{Traduction.translate(language, 'favorite')}</div>

        {/* Favorites Minicards */}
        {favorites.map((favorite, index) =>
          <div key={index} className="">
            <div className="favoriteLabel">{favorite.Label}</div>
            <div className="row">
              {/* Display Favorites inside Minicards sorted by Type */}
              {favorite.Items.map(item => 
                <div key={item.Item_ID} className="mr15">
                  {/* Minicards except Tasks */}
                  {item.Item_Type !== 'Task' && <Link to={`/Card/${item.Item_Type}/${item.Item_ID.substring(1)}/Home`}>
                    <MiniCard Content={item} Name={item.Name} Id={item.Item_ID.substring(1)} Type={item.Item_Type} StartDate={item.StartDate} EndDate={item.EndDate} Progress={item.Progress}></MiniCard>
                  </Link>}
                  {/* Minicard Tasks */}
                  {item.Item_Type === 'Task' && <Link to={`/Card/${item.Item_Type}/${item.Item_ID.substring(1)}/Details`}>
                    <MiniCard Content={item} Name={item.Name} Id={item.Item_ID.substring(1)} Type={item.Item_Type} StartDate={item.StartDate} EndDate={item.EndDate} Progress={item.Progress}></MiniCard>
                  </Link>}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default FavoritesCards;