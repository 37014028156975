import React, {Component} from 'react';
import '../Css/App.css';
import MiniTable from './MiniTable';
import MiniPlanning from './MiniPlanning';
import Kanban from './Kanban';
import MiniPivot from './MiniPivot';
import MiniChart from './MiniChart.js';
import EmptyBlockIssues from '../Images/EmptyBlockIssues.png';

class MiniBlockIssues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      guestLicence: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      filters: null,
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      emptyBlock: null
    };

    this.buildBlockData = this.buildBlockData.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const guestLicence = this.props.GuestLicence;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;
    const filters = this.props.Filters;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, filters });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [], chart = {};
    let emptyBlock;

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      // Chart
      if(blockContent.Chart) {
        chart = blockContent.Chart;
      }

      // Header
      // if(blockContent.Headers) {
      //   if(blockContent.Headers.find(element => element.FieldName === 'EmptyBlock')) {
      //     emptyBlock = blockContent.Headers.find(element => element.FieldName === 'EmptyBlock').FieldValue;
      //   }
      // }

      this.setState({ currentView, views, columns, rows, chart, emptyBlock });
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, filters, currentView, views, columns, rows, chart, emptyBlock } = this.state;

    return (
      <div className="miniblock">
        <div className="miniblockContent">
          {/* Mini Components */}
          {columns && rows.length > 0 && <div className="miniblockTable">
          {/* {columns && rows.length > 0 && <div className="miniblockTable scrollbar-miniblock"> */}
            {currentView.ViewType === 0 && <MiniTable ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTable>}
            {currentView.ViewType === 1 && <MiniPlanning ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPlanning>}
            {currentView.ViewType === 2 && <Kanban ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes}></Kanban>}
            {currentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}

          {/* Mini Chart */}
          {((chart.Mode === 'Chart' && chart.Series && chart.Series.length > 0) || (chart.Mode === 'Table' && chart.ChartTable && chart.ChartTable.Rows.length > 0)) && <div className="miniblockTable">
            {currentView.ViewType === 7 && <MiniChart ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} CurrentView={currentView} Chart={chart}></MiniChart>}
          </div>}

          {/* Empty Block */}
          {/* {Array.isArray(rows) && rows.length === 0 && filters == 0 && 
            <div className="miniblockEmpty scrollbar-miniblock">
              <div className=""><img className="iconsEmptyBlock" src={EmptyBlockIssues} alt="EmptyBlock Issues"/></div>
              <div className=""><div className="miniblockEmptyText" dangerouslySetInnerHTML={{ __html: emptyBlock }}></div></div>
            </div>
          } */}
        </div>
      </div>
    )
  }
}

export default MiniBlockIssues;