import React, {Component} from 'react';
import '../Css/App.css';

class NotificationIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null
    };

    this.getNotificationIcon = this.getNotificationIcon.bind(this);
  }
    
  componentDidMount() {
    const type = this.props.Type;

    this.setState({ type });
  }
  
  componentDidUpdate(prevProps) {
    const type = this.props.Type;
    
    if(this.props.Type !== prevProps.Type) {
      this.setState({ type });
    }
  }

  // Determine the icon to display depending on the Object type
  getNotificationIcon(type) {
    switch(type) {
      case "Business_Line":
          return <span className="iconBusinessLineWhite iconsMinicard" alt="Business Line"/>;
      case "Project":
          return <span className="iconProjectWhite iconsMinicard" alt="Project"/>;
      case "Workpackage":
          return <span className="iconWorkpackageWhite iconsMinicard" alt="Workpackage"/>;
      case "Action":
          return <span className="iconActionWhite iconsMinicard" alt="Action"/>;
      case "Task":
          return <span className="iconTaskWhite iconsMinicard" alt="Task"/>;
      case "Decision":
          return <span className="iconDecisions iconsMinicard" alt="Decision"/>;
      case "Issue":
          return <span className="iconIssues iconsMinicard" alt="Issue"/>;
      case "Meeting":
          return <span className="iconMeetings iconsMinicard" alt="Meeting"/>;
      case "Risk":
          return <span className="iconRisks iconsMinicard" alt="Risk"/>;
      case "BudgetCode":
          return <span className="iconBudgetCodeLight iconsMinicard" alt="Budget Code"/>;
      case "Entity":
          return <span className="iconEntityWhite iconsMinicard" alt="Entity"/>;
      case "Resource":
          return <span className="iconUserLight iconsMinicard" alt="Resource"/>;
      case "User":
          return <span className="iconUserLight iconsMinicard" alt="User"/>;
      case "PowerpointPresentation":
          return <span className="iconPPTLight iconsMinicard" alt="PowerPoint Presentation"/>;
      default:
          return <span className="iconAxe iconsMinicard" alt="Axe"/>;
    }
  }

  render() {
    const { type } = this.state;

    return (
      <div className="notificationMinicardIcon">
        {(type === 'Business_Line' || type === 'Project' || type === 'Workpackage' || type === 'Action' || type === 'Task') &&
          <span className="minicardIconBlue">{this.getNotificationIcon(type)}</span>
        }
        {(type === 'Resource' || type === 'Entity' || type === 'User') &&
          <span className="minicardIconTurquoise">{this.getNotificationIcon(type)}</span>
        }
        {(type === 'BudgetCode') &&
          <span className="minicardIconGrey">{this.getNotificationIcon(type)}</span>
        }
        {(type === 'PowerpointPresentation') &&
          <span className="minicardIconRed">{this.getNotificationIcon(type)}</span>
        }
        {(type !== 'Business_Line' && type !== 'Project' && type !== 'Workpackage' && type !== 'Action' && type !== 'Task' && type !== 'BudgetCode' && type !== 'Entity' && type !== 'PowerpointPresentation' && type !== 'Resource' && type !== 'User' ) && 
          <span className="minicardIconGrey">{this.getNotificationIcon(type)}</span>
        }
      </div>
    )
  }
}

export default NotificationIcon;