import React, {Component} from 'react';
import { Form, FormControl, InputGroup, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopupEditRights from './PopupEditRights';

class IndicatorsRights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      rights: [],
      rightsPopup: false
    };

    // Template Functions
    this.triggerPopup = this.triggerPopup.bind(this);
    this.templateResourceTooltip = this.templateResourceTooltip.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const rights = this.props.Rights;

    this.setState({ authId, language, itemId, itemType, itemTitle, rights });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const rights = this.props.Rights;
    
    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.ItemTitle !== prevProps.ItemTitle) {
      this.setState({ itemId, itemType, itemTitle });
    }
    if(JSON.stringify(this.props.Rights) !== JSON.stringify(prevProps.Rights)) {
      this.setState({ rights });
    } 
  }

  triggerPopup() {
    this.setState({ rightsPopup: true });
  }

  templateResourceTooltip(resource) {
    if(resource === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="iconHollowCircle icons fs13">
        {['left'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{resource}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
            <div className="blue">{firstname + lastname}</div>
          </OverlayTrigger>
        ))}
      </div>;
    }
    else if(resource === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="iconHollowCircle icons fs13">
        {['left'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{resource}</strong></Tooltip>} style={{padding: '2px 10px', color: 'black', borderRadius: 3}}>
            <div className="blue">{firstname + lastname}</div>
          </OverlayTrigger>
        ))}
      </div>;
    }
    else if(resource) {
      let firstname, lastname;
      let split = resource.split(' ');

      if(split.length === 1) {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = resource.split(' ')[1].substring(0,1);
      }
      else {
        firstname = resource.split(' ')[0].substring(0,1);
        lastname = resource.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="iconFullCircleBlue icons fs13">
        {['left'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{resource}</strong></Tooltip>} style={{padding: '2px 10px', color: 'white', borderRadius: 3}}>
            <div className="white">{firstname + lastname}</div>
          </OverlayTrigger>
        ))}
      </div>;
    }
    else {
      return null;
    }
  }

  closePopup(authorizedUsersList) {
    this.setState({ rights: authorizedUsersList, rightsPopup: false });
  }

  render() {
    const { language, itemId, itemType, itemTitle, rights, rightsPopup } = this.state;
    let count = 0;

    // Count
    if(rights && rights[0]) {
      count = rights.length;
    }
    
    return (
      <div className="indicatorsRights">
        {/* Rights Popup */}
        {rightsPopup && <PopupEditRights ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} onPopupClose={this.closePopup}></PopupEditRights>}

        <div className="cursor" onClick={() => this.triggerPopup()}>
          {rights && rights.length > 0 && <div className="flex inline-flex">
            {/* Display 3 first Resources */}
            {rights.map((item, index) => (
              <div key={index} className="">
                {(index === 0 || index === 1 || index === 2) && <div className="mr2">{this.templateResourceTooltip(item.Name)}</div>}
              </div>
            ))}
            <div className="flex align-items-end">
              {count > 3 && <div className="flex align-items-end">
                <div className="fs12 bold light-grey">
                  <span className="">+</span>
                  <span className="ml5">{count-3}</span>
                  <span className="ml5">{Traduction.translate(language, 'users')}</span>
                </div>
              </div>}
              {count < 3 && <div className="flex align-items-end">
                <div className="fs12 bold light-grey">
                  <span className="">{Traduction.translate(language, 'add')}</span>
                </div>
              </div>}
            </div>
          </div>}
          {rights && rights.length === 0 && <div className="flex align-items-end">
            <div className="fs12 bold light-grey">
              <span className="">{Traduction.translate(language, 'add_rights')}</span>
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

export default IndicatorsRights;