import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class PopoverColumnActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      columnName: null,
      columnType: null,
      editable: false
    };

    this.filterColumn = this.filterColumn.bind(this);
    this.sortColumn= this.sortColumn.bind(this);
    this.autofitColumn = this.autofitColumn.bind(this);
    this.autofitAllColumns = this.autofitAllColumns.bind(this);
    this.freezeColumns = this.freezeColumns.bind(this);
    this.multipleModification = this.multipleModification.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const editable = this.props.Editable;

    this.setState({ language, itemId, itemType, blockType, columnName, columnType, editable });
  }

  filterColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsFilter(columnName);
  }

  sortColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsSort(columnName);
  }

  autofitColumn(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnAutofit(columnName);
  }

  autofitAllColumns() {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsAutofit();
  }

  freezeColumns(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnsFreeze(columnName);
  }

  multipleModification(columnName) {
    // Close Popover
    if(document.getElementById("popover-basic")) {
      document.getElementById("popover-basic").classList.remove("show");
    }

    // Call the event from the Parent component through the props
    this.props.onColumnMultipleModification(columnName);
  }

  render() {
    const { language, itemId, itemType, blockType, columnName, columnType, editable } = this.state;

    return (
      <div className="columnActionsPopover">
        <div className="columnActionValue" onClick={() => this.filterColumn(columnName)}>
          <span className="iconFiltersGrey iconsFilter mr10" alt="Filters"/>
          <span className="fs12">{Traduction.translate(language, 'filter_data')}</span>
        </div>
        <div className="columnActionValue" onClick={() => this.sortColumn(columnName)}>
          <span className="iconSortGrey iconsFilter mr10" alt="Sort"/>
          <span className="fs12">{Traduction.translate(language, 'sort_data')}</span>
        </div>
        {/* <div className="columnActionValue" onClick={() => this.autofitColumn(columnName)}>
          <span className="iconAutofitGrey iconsFilter mr10" alt="Autofit"/>
          <span className="fs12">{Traduction.translate(language, 'autofit_column')}</span>
        </div> */}
        <div className="columnActionValue" onClick={() => this.autofitAllColumns()}>
          <span className="iconAutofitGrey iconsFilter mr10" alt="Autofit"/>
          <span className="fs12">{Traduction.translate(language, 'autofit_all_columns')}</span>
        </div>
        {/* <div className="columnActionValue" onClick={() => this.freezeColumns(columnName)}>
          <span className="iconAutofitGrey iconsFilter mr10" alt="Autofit"/>
          <span className="fs12">{Traduction.translate(language, 'freeze_columns')}</span>
        </div> */}
        {itemType !== 'Administration' && itemType !== 'Environment' && blockType !== 'DeleteBin' && blockType !== 'NotificationsCenter' && blockType !== 'TimeTracking' && columnType !== 'Files' && columnName !== 'Previous_Dependencies' && columnName !== 'Next_Dependencies' && columnName !== 'Previous_Constraint' && columnName !== 'Next_Constraint' && columnName !== 'Previous_Lag' && columnName !== 'Next_Lag' && editable && 
          <div className="columnActionValue" onClick={() => this.multipleModification(columnName)}>
            <span className="iconMultipleModification iconsFilter mr10" alt="Multiple modification"/>
            <span className="fs12">{Traduction.translate(language, 'multiple_modification')}</span>
          </div>
        }
      </div>
    );
  }
}

export default PopoverColumnActions;