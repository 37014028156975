import React, {Component} from 'react';
import { Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorModification from './ErrorModification';
import PopupConfirmation from './PopupConfirmation';
import Table from './Table';

// API
const API = '/WebAppService/GetItemInformation';
const API_valid = '/WebAppService/GetPropagationsAndSaveModification';
const API_create = '/WebAppService/InsertItemAndSave';
const API_delete = '/WebAppService/DeleteItemAndSave';

class PopupEditDependencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      editable: null,
      guestLicence: null,
      modified: false,
      rowInformation: {},
      predecessorView: {},
      predecessorColumns: [],
      predecessorRows: [],
      predecessorDataSource: [],
      successorView: {},
      successorColumns: [],
      successorRows: [],
      successorDataSource: [],
      confirm: false,
      deleteId: null,
      deleteType: null,
      deleteMessage: null,
      search: '',
      errors: []
    };

    // Table Structure
    this.getItemInformation = this.getItemInformation.bind(this);
    this.getColumnsName = this.getColumnsName.bind(this);
    this.dataStructure = this.dataStructure.bind(this);

    // Actions
    this.checkModification = this.checkModification.bind(this);
    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.applyPropagations = this.applyPropagations.bind(this);
    this.addPredecessorRowsInTable = this.addPredecessorRowsInTable.bind(this);
    this.addSuccessorRowsInTable = this.addSuccessorRowsInTable.bind(this);
    this.deleteRowInTable = this.deleteRowInTable.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
    this.closePopup = this.closePopup.bind(this);

    // Reference
    this.predecessorTable = React.createRef();
    this.successorTable = React.createRef();
  }
  
  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;

    await this.getItemInformation(login, authId, itemId, itemType);

    this.setState({ login, authId, language, itemId, itemType, itemTitle, editable, guestLicence });
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.ItemTitle;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      await this.getItemInformation(login, authId, itemId, itemType);
      
      this.setState({ itemId, itemType, itemTitle });
    }
    if(this.props.Editable !== prevProps.Editable) {
      this.setState({ editable });
    }
    if(this.props.GuestLicence !== prevProps.GuestLicence) {
      this.setState({ guestLicence });
    }
  }

  async getItemInformation(login, authId, itemId, itemType) {
    const language = this.state.language;
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetItemInformationResult;

      // Check request response
      if(result) {
        const predecessorView = result.DependenciesInformation.PreviousView;
        const predecessorColumns = result.DependenciesInformation.PreviousDependencies.ColumnHeaders;
        const predecessorRows = result.DependenciesInformation.PreviousDependencies.Rows;
        
        const successorView = result.DependenciesInformation.NextView;
        const successorColumns = result.DependenciesInformation.NextDependencies.ColumnHeaders;
        const successorRows = result.DependenciesInformation.NextDependencies.Rows;

        // Build Predecessor Dependencies Datasource
        const predecessorDataSource = this.dataStructure(predecessorRows);

        // Build Successor Dependencies Datasource
        const successorDataSource = this.dataStructure(successorRows);

        this.setState({ predecessorView, predecessorColumns, predecessorRows, predecessorDataSource, successorView, successorColumns, successorRows, successorDataSource });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'display_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  getColumnsName(dependencyWay) {
    let columns, columnNames = [];

    if(dependencyWay === 'Previous') {
      columns = this.state.predecessorColumns;
    }
    else if(dependencyWay === 'Next') {
      columns = this.state.successorColumns;
    }

    columns.forEach(column => {
      columnNames.push(column.FieldName);
    });

    return columnNames;
  }

  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          // Exclude Parent_ID for Table
          if(item.ColumnName !== 'Parent_ID') {
            acc[item.ColumnName] = item.Value;
          }
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  async checkModification(itemId, itemType, columnName, oldValue, newValue) {
    const { login, authId, language } = this.state;
    let additionalContext = [];
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'ColumnName': columnName,
        'OldValue': oldValue,
        'NewValue': newValue,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_valid, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const results = data.GetPropagationsAndSaveModificationResult;
      
      // Check request response
      if(results) {
        // For Each Modification
        results.forEach(result => {
          // Get Modifications, Propagations & Warnings
          let modification = result.Modification;
          let propagations = result.Propagations;
          let warnings = result.Warnings;

          // If the Modification is valid
          if(result.IsValid === true) {
            const predecessorRows = this.state.predecessorRows;
            const successorRows = this.state.successorRows;

            // Update Predecessor Rows in Components DataSource
            if(this.predecessorTable.current) {
              this.predecessorTable.current.updateDatasource(modification, propagations, warnings);
            }
            // Update Successor Rows in Components DataSource
            if(this.successorTable.current) {
              this.successorTable.current.updateDatasource(modification, propagations, warnings);
            }

            // Update Predecessor Rows in Data Table
            this.updateRowsInTable(predecessorRows, modification, propagations);

            // Update Successor Rows in Data Table
            this.updateRowsInTable(successorRows, modification, propagations);

            this.setState({ modified: true });
          }
          // If the Modification is not valid
          else {
            // Update Predecessor Datasource with OldValue
            if(this.predecessorTable.current) {
              this.predecessorTable.current.cancelModification(modification);
            }
            // Update Successor Datasource with OldValue
            if(this.successorTable.current) {
              this.successorTable.current.cancelModification(modification);
            }

            this.updateErrors(result.Errors);
          }
        });
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  async createItem(name, itemType, dependencyWay) {
    const { login, authId, language } = this.state;
    const parentType = this.state.itemType;
    const parentId = this.state.itemId;
    const columns = this.getColumnsName(dependencyWay);

    let additionalContext = [{ "Key": "Way", "Value": dependencyWay }];

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ParentType': parentType,
        'ParentId': parentId,
        'Name': name,
        'NbItems': '1',
        'Fields': columns,
        'AdditionalContext': additionalContext
      })
    };
    
    try{
      const response = await fetch(API_create, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.InsertItemAndSaveResult;

      // Check request response
      if(result) {
        // If the Modification is Valid
        if(result.IsValid === true) {
          // Get new Rows & Propagations
          const newRows = result.InsertedItems.Rows;
          const propagations = result.Propagations;

          if(dependencyWay === 'Previous') {
            // Add Rows in Predecessor DataSource
            if(this.predecessorTable.current) {
              this.predecessorTable.current.addRowsInDatasource(newRows);
            }

            // Add Rows in Predecessor Data Table
            this.addPredecessorRowsInTable(newRows, propagations);
          }
          else if(dependencyWay === 'Next') {
            // Add Rows in Successor DataSource
            if(this.successorTable.current) {
              this.successorTable.current.addRowsInDatasource(newRows);
            }

            // Add Rows in Successor Data Table
            this.addSuccessorRowsInTable(newRows, propagations);
          }

          this.setState({ modified: true });
        }
        else {
          this.updateErrors(result.Errors);
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'creation_impossible'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  async deleteItem(itemId, itemType, isForced) {
    const { login, authId, language } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemId': itemId,
        'ItemType': itemType,
        'IsForced': isForced
      })
    };

    try{
      const response = await fetch(API_delete, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.DeleteItemAndSaveResult;

      // Check request response
      if(result) {
        // Get Delete & Propagations
        const modification = result.Delete;
        const propagations = result.Propagations;
  
        // If the Modification is Valid
        if(result.IsValid === true) {
          const predecessorRows = this.state.predecessorRows;
          const successorRows = this.state.successorRows;

          // Delete Row in Predecessor DataSource
          if(this.predecessorTable.current) {
            this.predecessorTable.current.deleteRowFromComponent(modification);
          }
          // Delete Row in Successor DataSource
          if(this.successorTable.current) {
            this.successorTable.current.deleteRowFromComponent(modification);
          }

          // Delete Row in Predecessor Data Table
          this.deleteRowInTable(predecessorRows, modification, propagations);

          // Delete Row in Successor Data Table
          this.deleteRowInTable(successorRows, modification, propagations);

          this.setState({ modified: true });
        }
        else {
          if(result.ErrorCode === 101 || result.ErrorCode === 102) {
            this.setState({ confirm: true, deleteId: itemId, deleteType: itemType, deleteMessage: result.Errors[0] });
          }
          else {
            // Update Errors
            this.updateErrors(result.Errors);
          }
        }
      }
      else {
        this.updateErrors(Traduction.translate(language, 'deletion_impossible'));
      }

    } catch(error) {
      console.log(error);
    }
  }

  confirmDelete() {
    const deleteId = this.state.deleteId;
    const deleteType = this.state.deleteType;

    this.deleteItem(deleteId, deleteType, true);

    this.setState({ confirm: false });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  updateRowsInTable(rows, modification, propagations) {
    const modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === modificationId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === modification.ColumnName);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = modification.NewValue;
      }
    }

    // Apply Propagations in Rows
    this.applyPropagations(rows, propagations);

    this.setState({ rows });
  }

  applyPropagations(rows, propagations) {
    // Update Row with Propagations NewValue
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0,1).concat(propagation.ToItemId);

      // Check if we find Row to apply Propagation
      const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === propagationId);

      // Find Row to apply Propagation and Update with new Value
      if(currentRow) {
        // Check if we find Cell to apply Modification
        const currentCell = currentRow.Cells.find(cell => cell.ColumnName === propagation.ColumnName);

        // Update Row Cell with Propagation
        if(currentCell) {
          currentCell.Value = propagation.NewValue;
        }
      }
    });
  }

  addPredecessorRowsInTable(newRows, propagations) {
    const predecessorRows = this.state.predecessorRows;

    // Push new Rows
    newRows.forEach(row => {
      predecessorRows.push(row);
    });

    // Apply Propagations in Rows
    // this.applyPropagations(rows, propagations);

    this.setState({ predecessorRows });
  }

  addSuccessorRowsInTable(newRows, propagations) {
    const successorRows = this.state.successorRows;

    // Push new Rows
    newRows.forEach(row => {
      successorRows.push(row);
    });

    // Apply Propagations in Rows
    // this.applyPropagations(rows, propagations);

    this.setState({ successorRows });
  }

  deleteRowInTable(rows, modification, propagations) {
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let indexToDelete = -1;

    if(rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId)) {
      indexToDelete = rows.findIndex(row => row.Cells.find(cell => cell.ColumnName === 'Item_ID').Value === deleteId);
    }

    // Delete Row
    if(indexToDelete >= 0) {
      rows.splice(indexToDelete, 1);
    }

    // Apply Propagations in Rows
    // this.applyPropagations(rows, propagations);

    this.setState({ rows });
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  closePopup() {
    const modified = this.state.modified;

    // Call the event from the Parent component through the props
    this.props.onPopupClose(modified);
  }

  render() {
    const { language, itemId, itemType, itemTitle, editable, guestLicence, predecessorView, predecessorColumns, predecessorRows, predecessorDataSource, successorView, successorColumns, successorRows, successorDataSource, confirm, deleteMessage, search, errors } = this.state;

    return (
      <div className="dependenciesPopup">
        <div className="dependenciesInnerPopup">
          {/* Delete Confirmation Popup */}
          {confirm && <PopupConfirmation Message={deleteMessage} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}
          
          <div className="popupEditDependencies">
            {/* Dependencies Title */}
            <div className="flex mb10">
              {/* Label */}
              <Form.Label className="dependenciesLabel">
                <div className="dependenciesLabelBlue">{Traduction.translate(language, 'dependencies')} {Traduction.translate(language, 'of')}</div>
                <div className="dependenciesLabelYellow ml5">{itemTitle}</div>
              </Form.Label>

              {/* Close Popup */}
              <div className="flex-end align-items-center"><span className="iconClear icons cursor" onClick={() => this.closePopup()}></span></div>
            </div>

            {/* Errors */}
            {errors.length > 0 && 
              <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
            }
            
            <div className="dependenciesTables scrollbar-y">
              <div className="previousDependencies">
                {/* Previous Dependencies Title */}
                <div className="previousDependenciesTitle">
                  <div className="previousDependenciesLabel">{Traduction.translate(language, 'previous_dependencies')} ({predecessorRows.length})</div>
                  <span className="iconButtonPlus icons mh10 cursor" onClick={(e) => this.createItem('', 'Dependency', 'Previous')}></span>
                </div>
                {/* Previous Dependencies Table */}
                {predecessorRows.length > 0 && <Table ref={this.predecessorTable} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={'Dependencies'} Editable={editable} GuestLicence={guestLicence} CurrentView={predecessorView} Columns={predecessorColumns} Rows={predecessorRows} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>}
              </div>
              <div className="nextDependencies">
                {/* Next Dependencies Title */}
                <div className="nextDependenciesTitle">
                  <div className="nextDependenciesLabel">{Traduction.translate(language, 'next_dependencies')} ({successorRows.length})</div>
                  <span className="iconButtonPlus icons mh10 cursor" onClick={(e) => this.createItem('', 'Dependency', 'Next')}></span>
                </div>
                {/* Next Dependencies Table */}
                {successorRows.length > 0 && <Table ref={this.successorTable} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={'Dependencies'} Editable={editable} GuestLicence={guestLicence} CurrentView={successorView} Columns={successorColumns} Rows={successorRows} onItemCreate={this.createItem} onItemDelete={this.deleteItem} onTableUpdate={this.checkModification}></Table>}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopupEditDependencies;