import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { MapsComponent, MapsTheme, LayersDirective, LayerDirective, Bubble, BubblesDirective, BubbleDirective, Marker, MarkersDirective, MarkerDirective } from '@syncfusion/ej2-react-maps';
import { Inject, Legend, MapsTooltip, ImageExport, PdfExport } from '@syncfusion/ej2-react-maps';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopoverEditMapShapes from './PopoverEditMapShapes';
import PopoverEditMapLayer from './PopoverEditMapLayer';
import PopoverEditMapRow from './PopoverEditMapRow';
import PopoverEditMapIndicator from './PopoverEditMapIndicator';
import PopoverEditMapIndicatorFunction from './PopoverEditMapIndicatorFunction';
import PopoverEditMapSetting from './PopoverEditMapSetting';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';

import FrenchMap from './Maps/FrenchMap.json';
import EnedisDRMap from './Maps/EnedisDRMap.json';
import WorldMap from './Maps/WorldMap.json';

// API
const API_settings = '/WebAppService/GetMapSetting';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      currentView: {},
      map: {},
      settings: false,
      isLoading: false
    };

    // Data Structure
    this.getMapSetting = this.getMapSetting.bind(this);

    // Actions
    this.displayComponent = this.displayComponent.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.displaySettings = this.displaySettings.bind(this);
    this.closeSettings = this.closeSettings.bind(this);
    this.updateMapShapes = this.updateMapShapes.bind(this);
    this.updateMapLayer = this.updateMapLayer.bind(this);
    this.addMapRow = this.addMapRow.bind(this);
    this.deleteMapRow = this.deleteMapRow.bind(this);
    this.updateMapRow = this.updateMapRow.bind(this);
    this.addMapIndicator = this.addMapIndicator.bind(this);
    this.deleteMapIndicator = this.deleteMapIndicator.bind(this);
    this.updateMapIndicator = this.updateMapIndicator.bind(this);
    this.updateMapIndicatorFunction = this.updateMapIndicatorFunction.bind(this);
    this.changeMapSettingValue = this.changeMapSettingValue.bind(this);
    this.updateMapSetting = this.updateMapSetting.bind(this);
    this.updateMapSettingBoolean = this.updateMapSettingBoolean.bind(this);
    this.updateMapSettingText = this.updateMapSettingText.bind(this);
    this.updateCurrentView = this.updateCurrentView.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);

    // Template
    this.templateMapBubble = this.templateMapBubble.bind(this);
    this.templateBubbleTooltip = this.templateBubbleTooltip.bind(this);
    this.templateMapColor = this.templateMapColor.bind(this);
    this.templateMapMarker = this.templateMapMarker.bind(this);
    this.templateMarker = this.templateMarker.bind(this);
    this.templateMarkerTooltip = this.templateMarkerTooltip.bind(this);
    this.templateMapSettings = this.templateMapSettings.bind(this);
    this.templatePopoverMapShapes = this.templatePopoverMapShapes.bind(this);
    this.templatePopoverMapLayer = this.templatePopoverMapLayer.bind(this);
    this.templatePopoverMapRow = this.templatePopoverMapRow.bind(this);
    this.templatePopoverMapAddRow = this.templatePopoverMapAddRow.bind(this);
    this.templatePopoverMapIndicator = this.templatePopoverMapIndicator.bind(this);
    this.templatePopoverMapIndicatorFunction = this.templatePopoverMapIndicatorFunction.bind(this);
    this.templatePopoverMapAddIndicator = this.templatePopoverMapAddIndicator.bind(this);
    this.templatePopoverMapSetting = this.templatePopoverMapSetting.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const map = this.props.Map;
    const isLoading = this.props.IsLoading;

    this.setState({ login, authId, language, itemId, itemType, itemTitle, blockType, currentView, map, isLoading });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    const map = this.props.Map;
    const isLoading = this.props.IsLoading;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, itemTitle, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(JSON.stringify(this.props.Map) !== JSON.stringify(prevProps.Map)) {
      this.setState({ map });
    }
    if(this.props.IsLoading !== prevProps.IsLoading) {
      this.setState({ isLoading });
    }
  }

  // Get Map available Settings from the API
  async getMapSetting(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_settings, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetMapSettingResult;
      const map = this.state.map;

      if(result) {
        // Update Map Settings
        map.MapSetting = result;

        const availableMapShapes = result.AvailableMapShapes;
        const availableLayers = result.AvailableLayers;
        const availableRows = result.AvailableRows;
        const availableIndicators = result.AvailableIndicators;

        this.setState({ map, availableMapShapes, availableLayers, availableRows, availableIndicators, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  displayComponent(map) {
    if(map && map.Points) {
      // Map Layer Marker
      if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Marker') {
        return this.templateMapMarker(map);
      }
      // Map Layer Bubble
      else if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Bubble') {
        return this.templateMapBubble(map);
      }
      // Map Layer Color
      else if(map.MapSetting && map.MapSetting.Layer.LayerName === 'Color') {
        return this.templateMapColor(map);
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  applySettings(currentView) {
    const { login, authId, itemId, itemType, blockType } = this.state;

    // Get Map Settings
    this.getMapSetting(login, authId, itemId, itemType, blockType, currentView);
  }

  displaySettings() {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    // Get Map Settings
    this.getMapSetting(login, authId, itemId, itemType, blockType, currentView);

    this.setState({ settings: true });
  }

  closeSettings() {
    this.setState({ settings: false });
  }

  updateMapShapes(mapShapes) {
    const map = this.state.map;

    // Update Map Shapes
    map.MapSetting.MapShapes = mapShapes;

    // Update Current View From Map
    this.updateCurrentView(map);

    // this.setState({ map });
  }

  updateMapLayer(mapLayer) {
    const map = this.state.map;

    // Update Map Layer
    map.MapSetting.Layer = mapLayer;

    // Update Current View From Map
    this.updateCurrentView(map);

    // this.setState({ map });
  }

  addMapRow(mapRow) {
    const map = this.state.map;

    // Add Map Row
    map.MapSetting.Rows.push(mapRow);

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  deleteMapRow(index) {
    const map = this.state.map;
    
    // Delete Map Row
    map.MapSetting.Rows.splice(index, 1);
    
    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateMapRow(mapRow, index) {
    const map = this.state.map;

    // Update Map Row
    map.MapSetting.Rows[index] = mapRow;

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  addMapIndicator(mapIndicator) {
    const map = this.state.map;

    // Add Map Indicator
    map.MapSetting.Indicators.push(mapIndicator);

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  deleteMapIndicator(index) {
    const map = this.state.map;

    // Delete Map Indicator
    map.MapSetting.Indicators.splice(index, 1);

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateMapIndicator(mapIndicator, index) {
    const map = this.state.map;

    // Update Map Indicator
    map.MapSetting.Indicators[index] = mapIndicator;

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateMapIndicatorFunction(mapIndicatorFunction, index) {
    const map = this.state.map;

    // Update Map Indicator
    map.MapSetting.Indicators[index].AggregationFunction = mapIndicatorFunction;

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  changeMapSettingValue(mapSetting, event) {
    const map = this.state.map;

    // Update Map Parameters Setting
    map.MapSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === mapSetting.Name)) {
        parameter.Value.find(setting => setting.Name === mapSetting.Name).Value.Value = event.target.value;
      }
    });

    this.setState({ map });
  }

  updateMapSetting(mapSetting, value) {
    const map = this.state.map;

    // Update Map Parameters Setting
    map.MapSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === mapSetting.Name)) {
        parameter.Value.find(setting => setting.Name === mapSetting.Name).Value = value;
      }
    });

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateMapSettingBoolean(mapSetting, value) {
    const map = this.state.map;

    // Update Map Parameters Setting
    map.MapSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === mapSetting.Name)) {
        parameter.Value.find(setting => setting.Name === mapSetting.Name).Value.Value = value;
      }
    });

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateMapSettingText(mapSetting, event) {
    const map = this.state.map;

    // Update Map Parameters Setting
    map.MapSetting.DictParameters.forEach(parameter => {
      if(parameter.Value.find(setting => setting.Name === mapSetting.Name)) {
        parameter.Value.find(setting => setting.Name === mapSetting.Name).Value.Value = event.target.value;
      }
    });

    // Update Current View From Map
    this.updateCurrentView(map);

    this.setState({ map });
  }

  updateCurrentView(map) {
    const currentView = this.state.currentView;
    let sort;

    // Check if CurrentView contains Sort Parameter
    if(currentView.Parameters.find(parameter => parameter.Name === 'Sort')) {
      sort = currentView.Parameters.find(parameter => parameter.Name === 'Sort');
    }

    // Clear Current View Parameters
    currentView.Parameters = [];

    // Update Sort Parameter
    if(sort) {
      currentView.Parameters.push(sort);
    }

    // Update Map Shapes
    if(map.MapSetting.MapShapes) {
      currentView.Parameters.push({ Name: 'MapShapes', Value: map.MapSetting.MapShapes.Name, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Map Layer
    if(map.MapSetting.Layer) {
      currentView.Parameters.push({ Name: 'MapLayer', Value: map.MapSetting.Layer.LayerName, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Update Map Rows
    if(map.MapSetting.Rows) {
      let values = '';

      map.MapSetting.Rows.forEach(row => {
        values = values.concat(row.Name, ',');
      });
      
      values = values.slice(0, -1);
      
      currentView.Parameters.push({ Name: 'Rows', Value: values, ViewParameterId: -1, ViewId: currentView.ViewId });
    }

    // Clear Current View Values
    currentView.Values = [];

    // Update Current View Values list
    map.MapSetting.Indicators.forEach(indicator => {
      currentView.Values.push({ AggregationType: indicator.AggregationFunction.Name, Field: indicator.Name, ViewId: currentView.ViewId });
    });

    // Update Map Parameters
    map.MapSetting.DictParameters.forEach(parameter => {
      parameter.Value.forEach(setting => {
        currentView.Parameters.push({ Name: setting.Name, Value: setting.Value.Value.toString(), ViewParameterId: -1, ViewId: currentView.ViewId });
      });
    });

    // Call the event from the Parent component through the props with view value
    this.props.onSettingsChange(currentView);

    return currentView;
  }

  exportJPEG() {
    const map = this.state.map;
    const fileName = "Export " + map.Title;
    const type = "JPEG";

    if(this.map) {
      this.map.export(type, fileName);
    }
  }

  exportPNG() {
    const map = this.state.map;
    const fileName = "Export " + map.Title;
    const type = "PNG";

    if(this.map) {
      this.map.export(type, fileName);
    }
  }

  exportPDF() {
    const map = this.state.map;
    const fileName = "Export " + map.Title;
    const type = "PDF";

    if(this.map) {
      this.map.export(type, fileName);
    }
  }

  templateMapBubble(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '14px' } };
    let mapShapes, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if (map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${countryName}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${countryName}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings} allowImageExport={true} allowPdfExport={true} ref={map=>this.map=map}>
        <LayersDirective>
          <LayerDirective shapeData={mapShapes} shapeDataPath='countryName' shapePropertyPath='admin' shapeSettings={{ fill: '#E5E5E5' }}>
            <BubblesDirective>
              <BubbleDirective dataSource={map.Points} visible={true} valuePath='pointValue' minRadius={3} maxRadius={20} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></BubbleDirective>
            </BubblesDirective>
          </LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Bubble, Legend, ImageExport, PdfExport ]} />
      </MapsComponent>
    );
  }

  templateBubbleTooltip(data) {
    return '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
  }

  templateMapColor(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '14px' } };
    let mapShapes, legendSettings, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Legend
    if(map.Legend.PositionName === 'Top' || map.Legend.PositionName === 'Bottom') {
      legendSettings = { position: map.Legend.PositionName, orientation: 'Horizontal', mode: 'Default', height: '5%', width: '100%', visible: map.Legend.Visible };
    }
    else if(map.Legend.PositionName === 'Left' || map.Legend.PositionName === 'Right') {
      legendSettings = { position: map.Legend.PositionName, orientation: 'Vertical', mode: 'Default', height: '100%', width: '8%', visible: map.Legend.Visible };
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${pointValue}</span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings} legendSettings={legendSettings} allowImageExport={true} allowPdfExport={true} ref={map=>this.map=map}>
        <LayersDirective>
          <LayerDirective dataSource={map.Points} shapeData={mapShapes} shapeDataPath='consoKey' shapePropertyPath='name' shapeSettings={{ colorValuePath: 'pointValue', fill: '#E5E5E5', colorMapping: map.ColorSettings }} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Legend, ImageExport, PdfExport ]} />
      </MapsComponent>
    );
  }

  templateMapMarker(map) {
    const titleSettings = { text: map.Title, textStyle: { size: '14px' } };
    let mapShapes, tooltip;

    // Map Shapes
    if(map.MapSetting && map.MapSetting.MapShapes.Name === 'FrenchMap') {
      mapShapes = FrenchMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'WorldMap') {
      mapShapes = WorldMap;
    }
    else if(map.MapSetting && map.MapSetting.MapShapes.Name === 'EnedisDRMap') {
      mapShapes = EnedisDRMap;
    }

    // Tooltip
    if(map.Points.length !== 0) {
      if(map.Points[0].pointValueType === 'Percentage') {
        tooltip = '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">${pointValueField} : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${(pointValue*100).toFixed(0)}%</span></div></div>';
      }
      else {
        tooltip = '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${pointValue}</center></span></div></div>';
      }
    }
    else {
      tooltip = null;
    }

    return (
      <MapsComponent id={'map' + map.Id} height={'100%'} width={'100%'} titleSettings={titleSettings} allowImageExport={true} allowPdfExport={true} ref={map=>this.map=map}>
        <LayersDirective>
          <LayerDirective dataSource={map.Points} shapeData={mapShapes} shapeSettings={{ fill: '#E5E5E5' }}>
            <MarkersDirective>
              <MarkerDirective dataSource={map.Points} visible={true} animationDuration={0} shape='Circle' fill='white' width={10} border={{ color: '#285255', width: 2 }} tooltipSettings={{ valuePath: 'pointValue', template: tooltip, visible: true }}></MarkerDirective>
              {/* {map.Points.map((point, index) => {
                return <MarkerDirective key={index} dataSource={point} visible={true} template={() => this.templateMarker()}></MarkerDirective>
              })} */}
            </MarkersDirective>
          </LayerDirective>
        </LayersDirective>
        <Inject services={[ MapsTooltip, Marker, Legend, ImageExport, PdfExport ]} />
      </MapsComponent>
    );
  }

  templateMarker(data) {
    return '<div style="font-size:13px;color:#ffffff;font-weight: 500;">XXX</div>';
  }

  templateMarkerTooltip(data) {
    return '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${label}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${pointValue}</center></span></div></div>';
    // return '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' + '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${name}</center></div>' + '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' + '<div><span style="font-size:13px;color:#cccccc">Country : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${Country}</span></div>' + '<div><span style="font-size:13px;color:#cccccc">Continent : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${Continent}</span></div>' + '<div><span style="font-size:13px;color:#cccccc">Population : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${population}</span></div></div>';
  }

  templateMapSettings() {
    const { language, itemId, itemType, map, availableMapShapes, availableLayers, availableRows, availableIndicators } = this.state;
    let mapShapes, mapLayer, allowedRows, mapRowLabel, mapRows, allowedIndicators, mapIndicators, mapIndicatorsLabel, mapParameters;

    // Map
    if(map.MapSetting) {
      if(map.MapSetting.MapShapes) {
        mapShapes = map.MapSetting.MapShapes;
      }
    }

    // Map Layers
    if(map.MapSetting) {
      if(map.MapSetting.Layer) {
        mapLayer = map.MapSetting.Layer;
      }
    }

    // Map Row
    if(map.MapSetting) {
      mapRowLabel = map.MapSetting.RowLabel;
      allowedRows = map.MapSetting.AllowedRows;
      
      if(map.MapSetting.Rows) {
        mapRows = map.MapSetting.Rows;
      }
    }

    // Map Indicators
    if(map.MapSetting) {
      mapIndicatorsLabel = map.MapSetting.IndicatorsLabel;
      allowedIndicators = map.MapSetting.AllowedIndicators;

      if(map.MapSetting.Indicators) {
        mapIndicators = map.MapSetting.Indicators;
      }
    }

    // Map Parameters
    if(map.MapSetting && map.MapSetting.DictParameters) {
      mapParameters = map.MapSetting.DictParameters;
    }

    return (
      <div className="chartSettingsParameters">
        {/* Map Shapes */}
        {mapShapes && <div className="chartSettingsTitle">
          {/* Title */}
          <div className="chartModelTitle">{Traduction.translate(language, 'map')}</div>
          {/* Value */}
          <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapShapes(mapShapes, availableMapShapes)}>
            <div className="chartModelValue">
              <span className="">{mapShapes.Label}</span>
              <span className="iconChevronDownYellow verysmallIcons ml10 cursor"></span>
            </div>
          </OverlayTrigger>
        </div>}
        <div className="chartSettingsContent scrollbar-y overflow-y">
          {/* Map Layers */}
          {mapLayer && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{Traduction.translate(language, 'map_type')}</div>
            {/* Value */}
            <div className="chartSettingsAxes">
              <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapLayer(mapLayer, availableLayers)}>
                <div className="chartSettingsAxisLabel">
                  <div className="width90p">{mapLayer.Label}</div>
                  <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                </div>
              </OverlayTrigger>
            </div>
          </div>}
          {/* Map Rows */}
          {allowedRows > 0 && mapRows && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{mapRowLabel}</div>
            <div className="chartSettingsAxes">
              {mapRows.map((row, index) => {
                if(index < allowedRows) {
                  return <div key={index} className="chartSettingsIndicator">
                    {/* Row */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapRow(row, availableRows, index)}>
                      <div className="chartSettingsAxisLabel">
                        <div className="width90p">{row.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Delete Indicator */}
                    {mapRows.length > 1 && <div className="chartSettingsIndicatorDelete">
                      <span className="iconClearRed verysmallIcons cursor" onClick={() => this.deleteMapRow(index)}></span>
                    </div>}
                  </div>;
                }
              })}
              {/* New Row */}
              {mapRows.length < allowedRows && <div className="chartSettingsIndicator">
                <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapAddRow(availableRows)}>
                  <div className="chartSettingsNewIndicator">{Traduction.translate(language, 'add_row')}</div>
                </OverlayTrigger>
              </div>}
            </div>
          </div>}
          {/* Map Indicators */}
          {allowedIndicators > 0 && <div className="chartSettingsField">
            {/* Label */}
            <div className="chartSettingsLabel">{mapIndicatorsLabel}</div>
            {/* Values */}
            <div className="chartSettingsIndicators">
              {mapIndicators.map((indicator, index) => {
                if(index < allowedIndicators) {
                  return <div key={index} className="chartSettingsIndicator">
                    {/* Indicator */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapIndicator(indicator, availableIndicators, index)}>
                      <div className="chartSettingsIndicatorLabel">
                        <div className="width90p">{indicator.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Function */}
                    <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapIndicatorFunction(indicator.AggregationFunction, indicator.AvailableAggregationFunction, index)}>
                      <div className="chartSettingsIndicatorFunction">
                        <div className="width90p">{indicator.AggregationFunction.Label}</div>
                        <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                      </div>
                    </OverlayTrigger>
                    {/* Delete Indicator */}
                    <div className="chartSettingsIndicatorDelete">
                      <span className="iconClearRed verysmallIcons cursor" onClick={() => this.deleteMapIndicator(index)}></span>
                    </div>
                  </div>
                }
              })}
              {/* New Indicator */}
              {mapIndicators.length < allowedIndicators && <div className="chartSettingsAddIndicator">
                <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapAddIndicator(availableIndicators)}>
                  <div className="chartSettingsNewIndicator">{Traduction.translate(language, 'add_indicator')}</div>
                </OverlayTrigger>
              </div>}
            </div>
          </div>}
          {/* ----- Border ----- */}
          <div className="chartSettingsBorder"></div>
          {/* Map Parameters */}
          {mapParameters && mapParameters.map((parameter, index) => {
            return <div key={index} className="chartParameters">
              {/* Label */}
              <div className="chartParametersLabel">{parameter.Key}</div>
              {/* Settings list */}
              {parameter.Value.map((setting, index) => {
                if(setting.Type === 'Boolean') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    {setting.Value.Value && <div className="iconCheck iconsChartParameters cursor" onClick={() => this.updateMapSettingBoolean(setting, false)}></div>}
                    {!setting.Value.Value && <div className="iconCircleGrey iconsChartParameters cursor" onClick={() => this.updateMapSettingBoolean(setting, true)}></div>}
                  </div>;
                }
                else if(setting.Type === 'Double') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    <Form.Group className="chartParametersSettingInput">
                      <Form.Control id="" className="chartParametersSettingText" as="textarea" rows="1" placeholder="" value={setting.Value.Value} onChange={(e) => this.changeMapSettingValue(setting, e)} onBlur={(e) => this.updateMapSettingText(setting, e)}/>
                    </Form.Group>
                  </div>;
                }
                else if(setting.Type === 'String') {
                  return <div key={index} className="chartParametersSetting">
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    <Form.Group className="chartParametersSettingInput">
                      <Form.Control id="" className="chartParametersSettingText" as="textarea" rows="1" placeholder="" value={setting.Value.Value} onChange={(e) => this.changeMapSettingValue(setting, e)} onBlur={(e) => this.updateMapSettingText(setting, e)}/>
                    </Form.Group>
                  </div>;
                }
                else if(setting.Type === 'List') {
                  return <div key={index} className="chartParametersSetting">
                    {/* Label */}
                    <div className="chartSettingsLabel">{setting.Label}</div>
                    {/* Value */}
                    <div className="chartSettingsAxes">
                      <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={this.templatePopoverMapSetting(setting, setting.AvailableValues)}>
                        <div className="chartSettingsAxisLabel">
                          <div className="width90p">{setting.Value.DisplayedValue}</div>
                          <span className="iconChevronDownGrey verysmallIcons ml10 cursor"></span>
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>;
                }
              })}
            </div>;
          })}
        </div>
      </div>
    );
  }

  templatePopoverMapShapes(mapShapes, availableMapShapes) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapShapes ItemId={itemId} ItemType={itemType} MapShapes={mapShapes} AvailableMapShapes={availableMapShapes} onMapShapesEdit={this.updateMapShapes}></PopoverEditMapShapes>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapLayer(mapLayer, availableMapLayers) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapLayer ItemId={itemId} ItemType={itemType} MapLayer={mapLayer} AvailableMapLayers={availableMapLayers} onMapLayerEdit={this.updateMapLayer}></PopoverEditMapLayer>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapRow(mapRow, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapRow ItemId={itemId} ItemType={itemType} Index={index} MapRow={mapRow} AvailableFields={availableFields} onMapRowEdit={this.updateMapRow}></PopoverEditMapRow>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapAddRow(availableFields) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapRow ItemId={itemId} ItemType={itemType} AvailableFields={availableFields} onMapRowAdd={this.addMapRow}></PopoverEditMapRow>
        </Popover.Content>
      </Popover>
    );
    
    return popover;
  }

  templatePopoverMapAddIndicator(availableFields) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapIndicator ItemId={itemId} ItemType={itemType} AvailableFields={availableFields} onMapIndicatorAdd={this.addMapIndicator}></PopoverEditMapIndicator>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapIndicator(mapIndicator, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapIndicator ItemId={itemId} ItemType={itemType} Index={index} MapIndicator={mapIndicator} AvailableFields={availableFields} onMapIndicatorEdit={this.updateMapIndicator}></PopoverEditMapIndicator>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapIndicatorFunction(mapIndicatorFunction, availableFields, index) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapIndicatorFunction ItemId={itemId} ItemType={itemType} Index={index} MapIndicatorFunction={mapIndicatorFunction} AvailableFields={availableFields} onMapIndicatorFunctionEdit={this.updateMapIndicatorFunction}></PopoverEditMapIndicatorFunction>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  templatePopoverMapSetting(mapSetting, availableValues) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapSetting ItemId={itemId} ItemType={itemType} MapSetting={mapSetting} AvailableValues={availableValues} onMapSettingEdit={this.updateMapSetting}></PopoverEditMapSetting>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  render() {
    const { language, itemId, itemType, blockType, currentView, map, settings, isLoading } = this.state;

    return (
      <div className="map">
        {/* Display Map + Settings */}
        {settings && <div className="mapWithSettings">
          {isLoading && <div className="mapDisplay"></div>}
          {!isLoading && <div className="mapDisplay">{this.displayComponent(map)}</div>}
          <div className="mapSettings">{this.templateMapSettings(map)}</div>
        </div>}

        {/* Display Map only */}
        {!settings && !isLoading && <div className="mapWithoutSettings">{this.displayComponent(map)}</div>}
      </div>
    );
  }
}

export default Map;