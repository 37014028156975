import React, {Component} from 'react';
import { Badge, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { L10n } from '@syncfusion/ej2-base';
import { GanttComponent, ColumnsDirective, ColumnDirective, ColumnMenu, EventMarkerDirective, EventMarkersDirective } from '@syncfusion/ej2-react-gantt';
import { Inject, Edit, Toolbar, ContextMenu, DayMarkers, Filter, Sort, Reorder, Resize, Selection, VirtualScroll } from '@syncfusion/ej2-react-gantt';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import QRCode from "react-qr-code";
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import Timeline from './Timeline';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class MiniPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      blockType: null,
      editable: null,
      currentView: {},
      dictParameters: {},
      columns: [],
      dictColumns: {},
      dataSource: [],
      borneInf: null,
      borneSup: null,
      refreshCount: 0,
      refreshInProgress: false
    };

    // Gantt Structure
    this.dataStructure = this.dataStructure.bind(this);
    this.getColumnParameters = this.getColumnParameters.bind(this);
    this.getCurrentViewParameters = this.getCurrentViewParameters.bind(this);

    // Fonctions
    this.convertItemType = this.convertItemType.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.convertColorHexToRGB = this.convertColorHexToRGB.bind(this);
    this.convertColorRBGToHex = this.convertColorRBGToHex.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.getColorHex = this.getColorHex.bind(this);
    this.getColumnName = this.getColumnName.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    // this.getColumnType = this.getColumnType.bind(this);
    // this.getColumnUnit = this.getColumnUnit.bind(this);
    // this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.getPlanningLabelFormat = this.getPlanningLabelFormat.bind(this);
    this.getTreeColumnIndex = this.getTreeColumnIndex.bind(this);
    this.getTimelineSettings = this.getTimelineSettings.bind(this);
    this.getTimelineDayLabel = this.getTimelineDayLabel.bind(this);
    this.getTimelineWeekLabel = this.getTimelineWeekLabel.bind(this);
    this.getTimelineMonthLabel = this.getTimelineMonthLabel.bind(this);
    this.getTimelineQuarterLabel = this.getTimelineQuarterLabel.bind(this);
    this.getTimelineYearLabel = this.getTimelineYearLabel.bind(this);
    this.getUnitSize = this.getUnitSize.bind(this);

    // Actions
    this.refreshComponent = this.refreshComponent.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.refreshDatasource = this.refreshDatasource.bind(this);
    this.applySettings = this.applySettings.bind(this);
    this.createColumn = this.createColumn.bind(this);
    this.updatePlanningBornes = this.updatePlanningBornes.bind(this);

    // Syncfusion Events
    this.created = this.created.bind(this);
    this.dataBound = this.dataBound.bind(this);
    this.queryTaskbarInfo = this.queryTaskbarInfo.bind(this);

    // Template Columns
    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnActive = this.templateColumnActive.bind(this);
    this.templateColumnAdmin = this.templateColumnAdmin.bind(this);
    this.templateColumnBudgetCode = this.templateColumnBudgetCode.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnDataFreshness = this.templateColumnDataFreshness.bind(this);
    this.templateColumnDecisions = this.templateColumnDecisions.bind(this);
    this.templateColumnEmail = this.templateColumnEmail.bind(this);
    this.templateColumnEntity = this.templateColumnEntity.bind(this);
    this.templateColumnExternal = this.templateColumnExternal.bind(this);
    this.templateColumnHighlighted = this.templateColumnHighlighted.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnIndex = this.templateColumnIndex.bind(this);
    this.templateColumnItemType = this.templateColumnItemType.bind(this);
    this.templateColumnLag = this.templateColumnLag.bind(this);
    this.templateColumnLicenceType = this.templateColumnLicenceType.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMeteoFreshness = this.templateColumnMeteoFreshness.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnResourceName = this.templateColumnResourceName.bind(this);
    this.templateColumnRowType = this.templateColumnRowType.bind(this);
    this.templateColumnSeverity = this.templateColumnSeverity.bind(this);
    this.templateColumnSprint = this.templateColumnSprint.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnValidated = this.templateColumnValidated.bind(this);
    this.templateColumnWarning = this.templateColumnWarning.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    // Template Types
    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDependency = this.templateTypeDependency.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeFiles = this.templateTypeFiles.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeQRCode = this.templateTypeQRCode.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
    // Template Taskbars
    // this.templateTaskbar = this.templateTaskbar.bind(this);
    // this.templateParentTaskbar = this.templateParentTaskbar.bind(this);
    // this.templateMilestone = this.templateMilestone.bind(this);
  }

  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Planning Datasource
    const dataSource = this.dataStructure(blockType, rows);

    this.setState({ authId, language, formatDate, itemId, itemType, blockType, editable, currentView, dictParameters, dictColumns, columns, dataSource, refreshCount: 1 });
  }

  componentDidUpdate(prevProps) {
    
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   if(this.state.refreshCount !== prevState.refreshCount) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  dataStructure(blockType, rows) {
    let dataSource = [];
    let taskLabel;

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        // Get Taskbar Label
        if(row.Cells.find(cell => cell.ColumnName === 'Name')) {
          taskLabel = row.Cells.find(cell => cell.ColumnName === 'Name').Value;
        }
        else if(row.Cells.find(cell => cell.ColumnName === 'Meeting_Type')) {
          taskLabel = JSON.parse(row.Cells.find(cell => cell.ColumnName === 'Meeting_Type').Value).Label;
        }
        else {
          taskLabel = '';
        }

        return row.Cells.reduce((acc, item) => {
          // Dependencies
          if(blockType === 'Roadmap' && item.ColumnName === 'Previous_Dependencies') {
            if(item.Value) {
              // Split Dependencies list
              let dependencies = item.Value.split(',');

              // Loop through the dependencies
              for(let i=0; i < dependencies.length; i++) {
                // Replace Dependency first letter by corresponding number
                dependencies[i] = this.convertItemType(dependencies[i]).concat(dependencies[i].substring(1));
              }

              // Format DependencyID
              acc['DependencyID'] = dependencies.join(',');

              // Add the Column Name/Values to the reduced Planning
              acc[item.ColumnName] = item.Value;
            }
          }
          // Item ID
          else if(blockType === 'Roadmap' && item.ColumnName === 'Item_ID') {
            // Format TaskID (replace Dependency first letter by corresponding number)
            acc['TaskID'] = this.convertItemType(item.Value).concat(item.Value.substring(1));

            // Add the Column Name/Values to the reduced Planning
            acc[item.ColumnName] = item.Value;
          }
          // Parent ID
          else if(blockType === 'Roadmap' && item.ColumnName === 'Parent_ID') {
            // Replace Dependency first letter by corresponding number
            acc['ParentID'] = this.convertItemType(item.Value).concat(item.Value.substring(1));

            // Add the Column Name/Values to the reduced Planning
            acc[item.ColumnName] = item.Value;
          }
          // Format Dates + Duration
          else if(item.FieldType === 'Date') {
            if(item.ColumnName === 'EndDate') {
              // Update Duration to for Blocks Decisions/Issues/Meetings, or if StartDate = EndDate
              if(blockType === 'Decisions' || blockType === 'Issues' || blockType === 'Meetings') {
                acc['Duration'] = 0;
              }
              else if(row.Cells.find(cell => cell.ColumnName === 'StartDate') && row.Cells.find(cell => cell.ColumnName === 'StartDate').Value === item.Value) {
                if(!item.Value) {
                  acc['Duration'] = 5;
                }
                else {
                  acc['Duration'] = 0;
                }
              }
  
              // Add EndDate Format
              if(item.Value) {
                acc['EndDateFormat'] = this.formatDate(new Date(item.Value));
              }
              else {
                acc['EndDateFormat'] = item.Value;
              }
            }

            if(item.Value) {
              acc[item.ColumnName] = new Date(item.Value);
            }
            else {
              acc[item.ColumnName] = null;
            }
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Object Tables (Decisions/HashTags)
          else if(item.FieldType === 'ObjectTable' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Add the Column Name/Values to the reduced Table
          else {
            acc[item.ColumnName] = item.Value;
          }

          // Add TaskLabel to the reduced Table
          acc['Task_Label'] = taskLabel;
          
          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  // Build Current View Parameters
  getCurrentViewParameters(currentView) {
    const date = new Date();
    let dictParameters = {};

    // Default values
    dictParameters['AxisColor'] = null;
    dictParameters['Columns'] = [];
    dictParameters['ColumnsWidth'] = [];
    dictParameters['DisplayBaseline'] = false;
    dictParameters['DisplayCriticalPath'] = false;
    dictParameters['DisplayDependencies'] = false;
    dictParameters['DisplayLabel'] = "Name";
    dictParameters['DisplayToday'] = false;
    dictParameters['EditionMode'] = null;
    dictParameters['GanttPeriod'] = "week";
    dictParameters['GanttZoom'] = 3;
    dictParameters['ParentEdition'] = "Auto";
    dictParameters['Splitter'] = { view: 'Default', position: "33%" };
    dictParameters['StartDate'] = new Date();
    dictParameters['EndDate'] = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    for(let i=0; i < currentView.Parameters.length; i++) {
      // Axis Color
      if(currentView.Parameters[i].Name === 'AxisColor') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
        }
      }
      // Columns
      else if(currentView.Parameters[i].Name === 'Columns') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value.split(',');
        }
      }
      // Columns Width
      else if(currentView.Parameters[i].Name === 'ColumnsWidth') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = JSON.parse(currentView.Parameters[i].Value);
        }
      }
      // Display Baseline
      else if(currentView.Parameters[i].Name === 'DisplayBaseline') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.convertStringtoBoolean(currentView.Parameters[i].Value);
        }
      }
      // Display Critical Path
      else if(currentView.Parameters[i].Name === 'DisplayCriticalPath') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.convertStringtoBoolean(currentView.Parameters[i].Value);
        }
      }
      // Display Dependencies
      else if(currentView.Parameters[i].Name === 'DisplayDependencies') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.convertStringtoBoolean(currentView.Parameters[i].Value);
        }
      }
      // Display Label
      else if(currentView.Parameters[i].Name === 'DisplayLabel') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.getPlanningLabelFormat(currentView.Parameters[i].Value);
        } 
      }
      // Display Today
      else if(currentView.Parameters[i].Name === 'DisplayToday') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.convertStringtoBoolean(currentView.Parameters[i].Value);
        }
      }
      // Edition Mode
      else if(currentView.Parameters[i].Name === 'EditionMode') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = this.convertStringtoBoolean(currentView.Parameters[i].Value);
        }
      }
      // Gantt Period
      else if(currentView.Parameters[i].Name === 'GanttPeriod') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
        }
      }
      // Gantt Zoom
      else if(currentView.Parameters[i].Name === 'GanttZoom') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = parseFloat(currentView.Parameters[i].Value);
        }
      }
      // Parent Edition
      else if(currentView.Parameters[i].Name === 'ParentEdition') {
        if(currentView.Parameters[i].Value && this.convertStringtoBoolean(currentView.Parameters[i].Value)) {
          dictParameters[currentView.Parameters[i].Name] = 'Manual';
        }
      }
      // Splitter
      else if(currentView.Parameters[i].Name === 'Splitter') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = { view: 'Default', position: currentView.Parameters[i].Value + "%" };
        }
      }
      // StartDate
      else if(currentView.Parameters[i].Name === 'StartDate') {
        if(currentView.Parameters[i].Value) {
          const date = currentView.Parameters[i].Value;
          const startDate = new Date(date.split("/")[2], date.split("/")[0]-1, date.split("/")[1]);

          dictParameters['StartDate'] = startDate;
        }
      }
      // EndDate
      else if(currentView.Parameters[i].Name === 'EndDate') {
        if(currentView.Parameters[i].Value) {
          const date = currentView.Parameters[i].Value;
          const endDate = new Date(date.split("/")[2], date.split("/")[0]-1, date.split("/")[1]);

          dictParameters['EndDate'] = endDate;
        }
      }
      else {
        dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
      }
    }

    return dictParameters;
  }

  // Build Current View Columns
  getColumnParameters(columnHeaders) {
    let dictColumns = {};

    for(let i=0; i < columnHeaders.length; i++) {
      if(columnHeaders[i]) {
        dictColumns[columnHeaders[i].FieldName] = columnHeaders[i];
      }
    }

    return dictColumns;
  }

  // Replace Dependency first letter by corresponding number
  convertItemType(itemType) {
    switch(itemType.substring(0, 1)) {
      case 'B':
          return "1";
      case 'P':
          return "2";
      case 'W':
          return "3";
      case 'A':
          return "4";
      case 'T':
          return "5";
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  convertColorHexToRGB(hex) {
    const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    const rgbChar = ['r', 'g', 'b'];
    
    if(normal) {
      return normal.slice(1).reduce((a, e, i) => { 
        a[rgbChar[i]] = parseInt(e, 16); 
        return a;
      }, {});
    }

    if(shorthand) { 
      return shorthand.slice(1).reduce((a, e, i) => { 
        a[rgbChar[i]] = 0x11 * parseInt(e, 16); 
        return a;
      }, {});
    }

    return null;
  }

  convertColorRBGToHex(r, g, b) {
    return "#" + this.getColorHex(r) + this.getColorHex(g) + this.getColorHex(b);
  }

  formatDate(date) {
    const formatDate = Authentication.getCookie('formatDate');
    
    if(formatDate === 'MM/DD/YYYY') {
      return this.formatDateEn(date);
    }
    else if(formatDate === 'DD/MM/YYYY') {
      return this.formatDateFr(date);
    }
    else if(formatDate === 'YYYY-MM-DD') {
      return this.formatDateKr(date);
    }
  }

  // Formatting Date to English format for Gantt usage
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = month + "/" + day + "/" + year;
    }
    
    return formattedDate;
  }

  // Formatting Date to French format for Gantt usage
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = day + "/" + month + "/" + year;
    }
    
    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  getColorHex(c) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  getColumnName(props) {
    const dictParameters = this.state.dictParameters;
    let columnName = '';

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
    }
    else {
      columnName = props.column.field;
    }

    return columnName.replace(".Label", "");
  }

  getColumnLabel(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];
    let label = '';

    if(columnName === 'WarningMessage') {
      label = 'W';
    }
    else if(column) {
      label = column.Label;
    }

    // if(columnName === 'WarningMessage') {
    //   label = 'W';
    // }
    // else if(columns.find(column => column.FieldName === columnName)) {
    //   label = columns.find(column => column.FieldName === columnName).Label;
    // }
    
    return label;
  }

  getColumnType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.FieldType;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).FieldType;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnUnit(columnName) {
    // let columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.Unit;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).Unit;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnConditionalFormattings(columnName) {
    // let columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.ConditionalFormattings;
    }
    else {
      return [];
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    // }
    // else {
    //   return [];
    // }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  getPlanningLabelFormat(label) {
    let labelFormat = '${Task_Label}';

    switch(label) {
      case "Name + %":
          labelFormat += " - ${Progress}%";
          break;
      case "Name + End Date":
          labelFormat += " - ${EndDateFormat}";
          break;
      default:
          break;
    }

    return labelFormat;
  }

  getTreeColumnIndex(currentView, columns) {
    for(let i = 0; i < columns.length; i++) {
      if(currentView.DefaultLevel === 0) {
        if(columns[i] === 'Name') {
          return i;
        }
      }
    }

    return 0;
  }

  getTimelineSettings(period, zoom) {
    let timelineSettings;

    if(period && zoom && period === 'day') {
      timelineSettings = {
        timelineViewMode: 'Day',
        timelineUnitSize: this.getUnitSize(zoom),
        topTier: { unit: 'Week', format: 'dd MMM yyyy', formatter: (date) => this.getTimelineWeekLabel(date, true) },
        bottomTier: { unit: 'Day', format: 'EEE dd', count: 1, formatter: (date) => this.getTimelineDayLabel(date) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }
    else if(period && zoom && period === 'week') {
      timelineSettings = {
        timelineViewMode: 'Week',
        timelineUnitSize: this.getUnitSize(zoom),
        topTier: { unit: 'Month', format: 'MMMM yyyy', formatter: (date) => this.getTimelineMonthLabel(date, false) },
        bottomTier: { unit: 'Week', format: 'EEE dd', count: 1, formatter: (date) => this.getTimelineWeekLabel(date, false) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }
    else if(period && zoom && period === 'month') {
      timelineSettings = {
        timelineViewMode: 'Month',
        timelineUnitSize: this.getUnitSize(zoom),
        topTier: { unit: 'Month', format: 'MMMM yyyy', count: 3, formatter: (date) => this.getTimelineQuarterLabel(date, true) },
        bottomTier: { unit: 'Month', format: 'MMMM', count: 1, formatter: (date) => this.getTimelineMonthLabel(date, false) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }
    else if(period && zoom && period === 'quarter') {
      timelineSettings = {
        timelineViewMode: 'Month',
        timelineUnitSize: this.getUnitSize(zoom),
        topTier: { unit: 'Year', format: 'yyyy', count: 1, formatter: (date) => this.getTimelineYearLabel(date) },
        bottomTier: { unit: 'Month', format: 'MMMM', count: 3, formatter: (date) => this.getTimelineQuarterLabel(date, false) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }
    else if(period && zoom && period === 'year') {
      timelineSettings = {
        timelineViewMode: 'Year',
        timelineUnitSize: this.getUnitSize(zoom) / 2,
        topTier: { unit: 'Year', format: 'yyyy', count: 1, formatter: (date) => this.getTimelineYearLabel(date) },
        bottomTier: { unit: 'Month', format: 'MMMM', count: 3, formatter: (date) => this.getTimelineQuarterLabel(date, false) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }
    else {
      timelineSettings = {
        timelineViewMode: 'Year',
        timelineUnitSize: this.getUnitSize(zoom) / 2,
        topTier: { unit: 'Year', format: 'yyyy', count: 1, formatter: (date) => this.getTimelineYearLabel(date) },
        bottomTier: { unit: 'Month', format: 'MMMM', count: 3, formatter: (date) => this.getTimelineQuarterLabel(date, false) },
        showTooltip: true,
        updateTimescaleView: false,
        weekStartDay: 1
      };
    }

    return timelineSettings;
  }

  getTimelineDayLabel(date) {
    const language = this.state.language;
    let calendar;

    if(language === 'English') {
      calendar = "en-US";
      return new Date(date).getDate() + ' ' + new Date(date).toLocaleDateString(calendar, { weekday: 'short' });
    }
    else if(language === 'Français') {
      calendar = "fr-FR";
      return new Date(date).toLocaleDateString(calendar, { weekday: 'short' }) + ' ' + new Date(date).getDate();
    }
    else {
      calendar = "es-ES";
      return new Date(date).toLocaleDateString(calendar, { weekday: 'short' }) + ' ' + new Date(date).getDate();
    }
  }

  getTimelineWeekLabel(date, longFormat) {
    const language = this.state.language;
    const current = new Date(date);
    let weekLabel, calendar;

    // Get first and last day of the week
    const firstDay = new Date(current.setDate(current.getDate() - current.getDay() + 1));
    const lastDay = new Date(current.setDate(current.getDate() - current.getDay() + 7));

    if(language === 'English') {
      calendar = "en-US";

      if(longFormat) {
        weekLabel = "Week ";
      }
      else {
        weekLabel = "W";
      }
    }
    else {
      if(longFormat) {
        if(language === 'Français') {
          calendar = "fr-FR";
          weekLabel = "Semaine ";
        }
        else {
          calendar = "es-ES";
          weekLabel = "Semana ";
        }
      }
      else {
        weekLabel = "S";
      }
    }

    // Get first day of the year
    const startDate = new Date(firstDay.getFullYear(), 0, 1);

    // Get difference between date and first day of the year
    const days = Math.floor((firstDay - startDate) / (24 * 60 * 60 * 1000));
    
    // Get Week number
    const weekNumber = Math.ceil(days / 7);

    if(longFormat) {
      return weekLabel + weekNumber + ' : ' + firstDay.getDate() + ' ' + firstDay.toLocaleDateString(calendar, { month: 'short' })  + ' - ' + lastDay.getDate() + ' ' + lastDay.toLocaleDateString(calendar, { month: 'short' });
    }
    else {
      return weekLabel + weekNumber + ' ' + firstDay.getDate() + ' - ' + lastDay.getDate();
    }
  }

  getTimelineMonthLabel(date, longFormat) {
    const language = this.state.language;
    let calendar;

    if(language === 'English') {
      calendar = "en-US";
    }
    else if(language === 'Français') {
      calendar = "fr-FR";
    }
    else {
      calendar = "es-ES";
    }

    if(longFormat) {
      return new Date(date).toLocaleDateString(calendar, { year: 'numeric', month: 'long' });
    }
    else {
      return new Date(date).toLocaleDateString(calendar, { year: 'numeric', month: 'short' });
    }
  }

  getTimelineQuarterLabel(date, year) {
    const month = date.getMonth();
    
    if(month >= 0 && month <=2) {
      if(year) {
        return 'Q1 ' + date.getFullYear();
      }
      else {
        return 'Q1';
      }
    }
    else if(month >= 3 && month <=5) {
      if(year) {
        return 'Q2 ' + date.getFullYear();
      }
      else {
        return 'Q2';
      }
    }
    else if(month >= 6 && month <=8) {
      if(year) {
        return 'Q3 ' + date.getFullYear();
      }
      else {
        return 'Q3';
      }
    }
    else {
      if(year) {
        return 'Q4 ' + date.getFullYear();
      }
      else {
        return 'Q4';
      }
    }
  }

  getTimelineYearLabel(date) {
    return new Date(date).getFullYear();
  }

  getUnitSize(zoom) {
    if(zoom === 1) {
      return 60;
    }
    else if(zoom === 2) {
      return 100;
    }
    else if(zoom === 3) {
      return 160;
    }
    else if(zoom === 4) {
      return 240;
    }
    else if(zoom === 5) {
      return 320;
    }
  }

  // Refresh Gannt Component
  refreshComponent(blockType, currentView, columns, rows) {
    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Planning Datasource
    const dataSource = this.dataStructure(blockType, rows);

    this.setState({ currentView, dictParameters, dictColumns, dataSource, refreshInProgress: true });

    this.setState({ refreshInProgress: false });

    // Refresh Gantt Datasource
    this.refreshDatasource(dataSource);

    // Refresh Gantt Columns
    this.refreshColumns(currentView, dictParameters, dictColumns);

    // Update Planning Bornes
    this.updatePlanningBornes(dataSource, dictParameters);
    
    // Apply Settings
    this.applySettings(currentView, dictParameters);

    this.gantt.refresh();
  }

  refreshColumns(currentView, dictParameters, dictColumns) {
    // Auto Generated Columns
    if(this.gantt) {
      // Clean Gantt columns 
      this.gantt.columns = [];

      // Loop through the columns to build and add them to the Grid
      for(let i=0; i < dictParameters['Columns'].length; i++) {
        let columnName = dictParameters['Columns'][i];

        if(dictColumns[columnName]) {
          // Create Column object
          const colObj = this.createColumn(dictColumns[columnName], dictParameters['ColumnsWidth'], true);

          // Add the Column in the Grid
          this.gantt.columns.push(colObj);
        }
      }

      // Mandatory columns
      const mandatoryColumns = ['Item_ID', 'Item_Type', 'Parent_ID', 'Previous_Dependencies', 'Name', 'StartDate', 'EndDate', 'Progress', 'Initial_StartDate', 'Initial_EndDate'];

      // Add Axis Color column if different than None
      if(dictParameters['AxisColor'] !== 'None') {
        mandatoryColumns.push(dictParameters['AxisColor']);
      }

      // Add critical path if required
      if(dictParameters['DisplayCriticalPath']) {
        mandatoryColumns.push('Critical_Path');
      }

      // Add Mandatory columns in Gantt
      Object.keys(dictColumns).forEach(key => {
        let column = dictColumns[key];

        if(!dictParameters['Columns'].includes(column.FieldName) && mandatoryColumns.includes(column.FieldName)) {
          // Define Column object for Gantt
          let colObj = this.createColumn(column, dictParameters['ColumnsWidth'], false);
          
          this.gantt.columns.push(colObj);
        }
      });

      // Update gantt Column Index
      this.gantt.treeColumnIndex = this.getTreeColumnIndex(currentView, dictParameters['Columns']);
    }
  }

  refreshDatasource(dataSource) {
    if(this.gantt) {
      this.gantt.dataSource = dataSource;
      // this.gantt.updateDataSource(dataSource);
    }
  }

  applySettings(currentView, dictParameters) {
    const { borneInf, borneSup } = this.state;

    if(this.gantt) {
      // BorneInf & BorneSup
      this.gantt.projectStartDate = borneInf;
      this.gantt.projectEndDate = borneSup;
      // Display Baseline
      this.gantt.renderBaseline = dictParameters['DisplayBaseline'];
      // Display Label
      this.gantt.labelSettings.properties.rightLabel = dictParameters['DisplayLabel'];
      // Edition Mode
      this.gantt.readOnly = true;
      // Gantt Timeline
      if(dictParameters['GanttPeriod'] && dictParameters['GanttZoom']) {
        this.gantt.timelineSettings = this.getTimelineSettings(dictParameters['GanttPeriod'], dictParameters['GanttZoom']);
      }
      // Parent Edition
      this.gantt.taskMode = dictParameters['ParentEdition'];
      // Splitter
      this.gantt.splitterSettings = dictParameters['Splitter'];
      // Tree Column Index
      this.gantt.treeColumnIndex = this.getTreeColumnIndex(currentView, dictParameters['Columns']);
    }
  }

  createColumn(columnHeader, columnsWidth, visible) {
    const { formatDate, itemType, blockType } = this.state;
    let label, type, field, format, primaryKey, template, width;

    // Label
    label = this.getColumnLabel(columnHeader.FieldName);

    // Type
    // if(columnHeader.FieldType === 'String' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
    //   type = 'string';
    // }
    // else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
    //   type = 'number';
    // }
    // else if(columnHeader.FieldType === 'Date') {
    //   type = 'date';
    // }
    // else {
    //   type = null;
    // }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'ResourceTable' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName;
      // field = columnHeader.FieldName + ".Label";
    }
    else {
      field = columnHeader.FieldName;
    }

    // Format
    if(columnHeader.FieldType === 'Date') {
      if(formatDate === 'MM/DD/YYYY') {
        format = { type: 'date', format: 'MM/dd/yyyy' };
      }
      else if(formatDate === 'DD/MM/YYYY') {
        format = { type: 'date', format: 'dd/MM/yyyy' };
      }
      else if(formatDate === 'YYYY-MM-DD') {
        format = { type: 'date', format: 'yyyy-MM-dd' };
      }
    }
    else {
      format = null;
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Template functions
    // By FieldName
    if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Active') {
      template = this.templateColumnActive;
    }
    else if(columnHeader.FieldName === 'Admin') {
      template = this.templateColumnAdmin;
    }
    else if(columnHeader.FieldName === 'BudgetCode') {
      template = this.templateColumnBudgetCode;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Data_Freshness') {
      template = this.templateColumnDataFreshness;
    }
    else if(columnHeader.FieldName === 'Decisions') {
      template = this.templateColumnDecisions;
    }
    else if(columnHeader.FieldName === 'Email') {
      template = this.templateColumnEmail;
    }
    else if(columnHeader.FieldName === 'Entity') {
      template = this.templateColumnEntity;
    }
    else if(columnHeader.FieldName === 'External') {
      template = this.templateColumnExternal;
    }
    else if(columnHeader.FieldName === 'Highlighted') {
      template = this.templateColumnHighlighted;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Index') {
      template = this.templateColumnIndex;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if(columnHeader.FieldName === 'Next_Lag' || columnHeader.FieldName === 'Previous_Lag') {
      template = this.templateColumnLag;
    }
    else if(columnHeader.FieldName === 'Licence_Type') {
      template = this.templateColumnLicenceType;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Meteo_Freshness') {
      template = this.templateColumnMeteoFreshness;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      if(itemType === 'Entity' && blockType === 'Resources') {
        template = this.templateColumnResourceName;
      }
      else {
        template = this.templateColumnName;
      }
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'RowType') {
      template = this.templateColumnRowType;
    }
    else if(columnHeader.FieldName === 'Severity') {
      template = this.templateColumnSeverity;
    }
    else if(columnHeader.FieldName === 'Sprint') {
      template = this.templateColumnSprint;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Validated') {
      template = this.templateColumnValidated;
    }
    else if(columnHeader.FieldName === 'WarningMessage') {
      template = this.templateColumnWarning;
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldName === 'Previous_Dependencies' || columnHeader.FieldName === 'Next_Dependencies') {
      template = this.templateTypeDependency;
    }
    else if(columnHeader.FieldType === 'Double' && columnHeader.FieldName !== 'Parent_ID') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'Files') {
      template = this.templateTypeFiles;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'QRCode') {
      template = this.templateTypeQRCode;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Width
    if(columnsWidth.find(column => column.Name.replace(".Label", "") === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name.replace(".Label", "") === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Name') {
        width = 400;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 115;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 175;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Business_Line') {
        width = 350
      }
      else if(columnHeader.FieldName === 'Highlighted' || columnHeader.FieldName === 'External' || columnHeader.FieldName === 'Validated') {
        width = 125;
      }
      else if(columnHeader.FieldName === 'Project') {
        width = 220;
      }
      else if(columnHeader.FieldName === 'WarningMessage') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Data_Freshness') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Meteo_Freshness') {
        width = 110;
      }
      else {
        width = 150;
      }
    }

    // Define Column object for Grid
    var colObj = {
      field: field,
      format: format,
      headerText: label,
      headerTextAlign: 'left',
      isPrimaryKey: primaryKey,
      template: template,
      // type: type,
      visible: visible,
      width: width
    };

    return colObj;
  }

  updatePlanningBornes(dataSource, dictParameters) {
    let borneInf = this.state.borneInf;
    let borneSup = this.state.borneSup;

    const date = new Date();

    // Custom Period
    if(dictParameters['CustomPeriod'] == true) {
      if(dictParameters['StartDate'] && dictParameters['StartDate'] < borneInf) {
        borneInf = dictParameters['StartDate'];
      }
      if(dictParameters['EndDate'] && dictParameters['EndDate'] > borneSup) {
        borneSup = dictParameters['EndDate'];
      }
    }

    // Get Datasource min Start date & max End date
    dataSource.forEach(row => {
      if(!borneInf || row.StartDate < borneInf) {
        borneInf = row.StartDate;
      }
      if(!borneSup || row.EndDate > borneSup) {
        borneSup = row.EndDate;
      }
      // Specific case for Objects without Start date
      if(!borneInf || row.EndDate < borneInf) {
        borneInf = row.EndDate;
      }
    });

    // Default values (first and last day of current month)
    if(!borneInf) {
      borneInf = new Date(date.getFullYear(), date.getMonth(), 1);
    }
    if(!borneSup) {
      borneSup = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    // Force bornes at start of quarter for Period month (0 1 2 - 3 4 5 - 6 7 8 - 9 10 11)
    if(dictParameters['GanttPeriod'] === 'month') {
      if(borneInf.getMonth() === 0 || borneInf.getMonth() === 1 || borneInf.getMonth() === 2) {
        borneInf = new Date(borneInf.getFullYear(), 0, 1);
      }
      else if(borneInf.getMonth() === 3 || borneInf.getMonth() === 4 || borneInf.getMonth() === 5) {
        borneInf = new Date(borneInf.getFullYear(), 3, 1);
      }
      else if(borneInf.getMonth() === 6 || borneInf.getMonth() === 7 || borneInf.getMonth() === 8) {
        borneInf = new Date(borneInf.getFullYear(), 6, 1);
      }
      else {
        borneInf = new Date(borneInf.getFullYear(), 9, 1);
      }
    }
    else {
      borneInf = new Date(new Date(borneInf).getFullYear(), new Date(borneInf).getMonth(), 1);
    }

    borneSup = new Date(new Date(borneSup).getFullYear(), 11, 31);

    this.setState({ borneInf, borneSup });
  }

  created(args) {
    if(this.gantt) {
      // Zoom to Fit
      this.gantt.fitToProject();
      this.gantt.timelineSettings.properties.updateTimescaleView = false;
    }
  }

  dataBound(args) {
    if(this.gantt) {
      // Zoom to Fit
      this.gantt.fitToProject();
      this.gantt.timelineSettings.properties.updateTimescaleView = false;
    }
  }

  // Customize Taskbars colors
  queryTaskbarInfo(args) {
    const dictParameters = this.state.dictParameters;
    const axisColor = dictParameters['AxisColor'];
    const criticalPath = dictParameters['DisplayCriticalPath'];
    let type = args.data.taskData.Item_Type;

    // Criticl Path
    if(criticalPath && args.data.taskData['Critical_Path'] && args.data.taskData['Critical_Path'] == "True") {
      args.milestoneColor = "rgb(245, 65, 65)";
      args.taskbarBgColor = "rgb(245, 65, 65)";
      args.taskbarBorderColor = "rgb(230, 50, 50)";
      args.progressBarBgColor = "rgb(230, 50, 50)";
    }
    // Axis Color
    else if(axisColor !== 'None' && args.data.taskData[axisColor] && args.data.taskData[axisColor].BackColor) {
      let backColor = args.data.taskData[axisColor].BackColor;

      if(backColor === '#FFFFFF' || backColor === 'None') {
        backColor = args.data.taskData[axisColor].ForeColor;
      }

      if(backColor === 'None') {
        backColor = '#0088C7';
      }

      const rgbColor = this.convertColorHexToRGB(backColor);
      const rColor = rgbColor.r;
      const gColor = rgbColor.g;
      const bColor = rgbColor.b;

      // const stringColor = "rbg(" + (rColor) + ", " + (gColor) + ", " + (bColor) + ");";

      args.milestoneColor = backColor;
      args.taskbarBgColor = backColor;
      args.taskbarBorderColor = backColor;
      args.progressBarBgColor = this.convertColorRBGToHex(parseInt(rColor * 4 / 5), parseInt(gColor * 4 / 5), parseInt(bColor * 4 / 5));
    }
    // Item Type
    else {
      switch(type) {
        case 'AgileBoard':
        case 'Sprint':
            args.milestoneColor = "rgb(253, 183, 121)";
            args.taskbarBgColor = "rgb(253, 183, 121)";
            args.taskbarBorderColor = "rgb(246, 142, 50)";
            args.progressBarBgColor = "rgb(246, 142, 50)";
            break;
        case 'Business_Line':
            args.milestoneColor = "rgb(23, 106, 144)";
            args.taskbarBgColor = "rgb(23, 106, 144)";
            args.taskbarBorderColor = "rgb(3, 86, 124)";
            args.progressBarBgColor = "rgb(3, 86, 124)";
            break;
        case "Project":
            args.milestoneColor = "rgb(20, 156, 219)";
            args.taskbarBgColor = "rgb(20, 156, 219)";
            args.taskbarBorderColor = "rgb(0, 136, 199)";
            args.progressBarBgColor = "rgb(0, 136, 199)";
            break;
        case "Workpackage":
            args.milestoneColor = "rgb(20, 184, 254)";
            args.taskbarBgColor = "rgb(20, 184, 254)";
            args.taskbarBorderColor = "rgb(0, 164, 234)";
            args.progressBarBgColor = "rgb(0, 164, 234)";
            break;
        case "Action":
            args.milestoneColor = "rgb(120, 213, 260)";
            args.taskbarBgColor = "rgb(120, 213, 260)";
            args.taskbarBorderColor = "rgb(100, 203, 250)";
            args.progressBarBgColor = "rgb(100, 203, 250)";
            break;
        case "Task":
            args.milestoneColor = "rgb(132, 132, 132)";
            args.taskbarBgColor = "rgb(132, 132, 132)";
            args.taskbarBorderColor = "rgb(112, 112, 112)";
            args.progressBarBgColor = "rgb(112, 112, 112)";
            break;
        default:
            args.milestoneColor = "rgb(10, 146, 209)";
            args.taskbarBgColor = "rgb(10, 146, 209)"; //"rgb(244, 247, 248)";
            args.taskbarBorderColor = "rgb(3, 86, 124)";
            args.progressBarBgColor = "rgb(3, 86, 124)";
            break;
      }
    }
  }

  // ----- ----- Template Functions ----- -----
  // ----- ----- Columns
  templateColumnAction(props) {
    const action = props.taskData.Action;

    if(action) {
      if(action.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{action.Label}</div>;
      }
      else if(action.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/Action/${action.Id}/Home`}><span className="iconAction verysmallIcons mr5"></span></a>
          <span className="">{action.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnActive(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;
    
    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconActiveTrue iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconActiveFalse iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnAdmin(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconShieldGreen iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconShieldGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnBudgetCode(props) {
    const budgetCode = props.taskData.BudgetCode;

    if(budgetCode) {
      if(budgetCode.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{budgetCode.Label}</div>;
      }
      else if(budgetCode.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/BudgetCode/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a>
          <span className="">{budgetCode.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    const businessLine = props.taskData.Business_Line;

    if(businessLine) {
      if(businessLine.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{businessLine.Label}</div>;
      }
      else if(businessLine.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/Business_Line/${businessLine.Id}/Home`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
          <span className="">{businessLine.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDataFreshness(props) {
    const dataFreshness = props.taskData.Data_Freshness;

    if(dataFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(dataFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(dataFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnDecisions(props) {
    const decisions = props.taskData.Decisions;

    if(decisions) {
      return <div className="inline-flex">
        {decisions.map((decision, index) => {
          return <div key={index} className={"miniplanningAxe bordered blue" + (index < decisions.length - 1 ? " mr10" : "")}>
            <span className="iconDecisions verysmallIcons mr5"></span>
            <span className="">{decision.Label}</span>
          </div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnEmail(props) {
    const email = props.taskData.Email;

    if(email) {
      return <a href={"mailto:" + email} className="emailTable">{email}</a>;
      // return <div onClick={(e) => {window.location = "mailto:" + email; e.preventDefault();}} className="emailTable">{email}</div>
    }
    else {
      return null;
    }
  }

  templateColumnEntity(props) {
    const entity = props.taskData.Entity;

    if(entity) {
      if(entity.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{entity.Label}</div>;
      }
      else if(entity.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/Entity/${entity.Id}/Home`}><span className="iconEntity verysmallIcons mr5"></span></a>
          <span className="">{entity.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnExternal(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconExternal iconsRectangleMiniTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconInternal iconsRectangleMiniTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnHighlighted(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconHighlighted iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconNotHighlighted iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const impact = props.taskData.Impact;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{impact.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{impact.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnIndex(props) {
    const impact = props.taskData.Impact;
    const probability = props.taskData.Probability;
    const index = props.taskData.Index;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact && probability) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{index}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{index}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{index}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{index}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{index}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{index}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{index}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{index}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{index}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.taskData.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnLag(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    let backColor, foreColor = '#FFFFFF';

    if(value > 0) {
      backColor = "#00C77A";
      return <div className="miniplanningAxe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" + " + value+ " " }</div>;
    }
    else if(value < 0) {
      backColor = "#E21313";
      return <div className="miniplanningAxe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" - " + (-value) + " "}</div>;
    }
    else if(value === 0) {
      backColor = "#FFFFFF";
      foreColor = "#9D9D9F";
      return <div className="miniplanningAxe bordered" style={{ background: backColor, color: foreColor, borderColor: foreColor }}>{" " + value + " "}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnLicenceType(props) {
    const licenceType = props.taskData.Licence_Type;
    
    switch(licenceType) {
      case 'Full':
          return <div className="miniplanningAxe brd-radius bg-green white">{licenceType}</div>;
      case 'Standard':
          return <div className="miniplanningAxe bordered green">{licenceType}</div>;
      case 'No Licence':
      case 'Duplicated license':
      case 'Invalid License':
          return <div className="miniplanningAxe bordered grey">{licenceType}</div>;
      default:
          return null;
    }
  }

  templateColumnMeteo(props) {
    const meteo = props.taskData.Meteo;

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    if(meteo) {
      switch(meteo.Id) {
        case 1:
            return <div className=""><img className="iconsTable" src={IconSun} alt="Sun"/></div>;
        case 2:
            return <div className=""><img className="iconsTable" src={IconCloud} alt="Cloud"/></div>;
        case 3:
            return <div className=""><img className="iconsTable" src={IconRain} alt="Rain"/></div>;
        case 4:
            return <div className=""><img className="iconsTable" src={IconThunder} alt="Thunder"/></div>;
        case 5:
            return <div className=""><img className="iconsTable" src={IconMoon} alt="Moon"/></div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteoFreshness(props) {
    const meteoFreshness = props.taskData.Meteo_Freshness;

    if(meteoFreshness === "1") {
      return <div className="iconFullCircleGreen iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "2") {
      return <div className="iconFullCircleOrange iconsMiniblock"></div>;
    } 
    else if(meteoFreshness === "3") {
      return <div className="iconFullCircleRed iconsMiniblock"></div>;
    }
    else if(meteoFreshness === "4") {
      return <div className="iconCircleGrey iconsMiniblock"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.taskData.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const itemId = props.taskData.Item_ID;
    const itemType = props.taskData.Item_Type;
    const name = props.taskData.Name;

    switch(itemType) {
      case 'Business_Line':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a>{name}</div>);
      case 'Project':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a>{name}</div>);
      case 'Workpackage':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a>{name}</div>);
      case 'Action':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a>{name}</div>);
      case 'Task':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a>{name}</div>);
      default:
          return <span className="">{name}</span>;
    }
  }

  templateColumnPriority(props) {
    const priority = props.taskData.Priority;

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(priority) {
      switch(priority.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{priority.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{priority.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProbability(props) {
    const probability = props.taskData.Probability;

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(probability) {
      switch(probability.Id) {
        case 0:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 1:
            return <li className="fs18 green"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 2:
            return <li className="fs18 orange-light"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 3:
            return <li className="fs18 orange"><span className="minitablePriority black">{probability.Label}</span></li>;
        case 4:
            return <li className="fs18 black"><span className="minitablePriority red">{probability.Label}</span></li>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProgress(props) {
    let progress = props.taskData.Progress;
    // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);

    if(progress) {
      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateColumnProject(props) {
    const project = props.taskData.Project;

    if(project) {
      if(project.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{project.Label}</div>;
      }
      else if(project.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/Project/${project.Id}/Home`}><span className="iconProject verysmallIcons mr5"></span></a>
          <span className="">{project.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnResourceName(props) {
    const itemId = props.taskData.Item_ID;
    const itemType = props.taskData.Item_Type;
    const name = props.taskData.Name;
    
    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="inline-flex align-items-center">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="inline-flex align-items-center">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="inline-flex align-items-center">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnRowType(props) {
    const rowType = props.taskData.RowType;

    if(rowType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'Forecast') {
      return <div className="blue"><span className="iconHourglass tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'TimeTracking') {
      return <div className="blue bold"><span className="iconTimeTrackingBlue tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else {
      return <div className="">{rowType}</div>;
    }
  }

  templateColumnSeverity(props) {
    const impact = props.taskData.Impact;
    const probability = props.taskData.Probability;
    const severity = props.taskData.Severity;

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-verylow-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-verylow-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-verylow-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-verylow-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-verylow-critical white">{severity}</div>;
            default:
                return null;
          }
        case 1:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-low-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-low-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-low-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-low-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-low-critical white">{severity}</div>;
            default:
                return null;
          }
        case 2:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-medium-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-medium-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-medium-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-medium-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-medium-critical white">{severity}</div>;
            default:
                return null;
          }
        case 3:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-high-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-high-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-high-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-high-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-high-critical white">{severity}</div>;
            default:
                return null;
          }
        case 4:
          switch(probability.Id) {
            case 0:
                return <div className="brd-radius mat-index bg-critical-verylow white">{severity}</div>;
            case 1:
                return <div className="brd-radius mat-index bg-critical-low white">{severity}</div>;
            case 2:
                return <div className="brd-radius mat-index bg-critical-medium white">{severity}</div>;
            case 3:
                return <div className="brd-radius mat-index bg-critical-high white">{severity}</div>;
            case 4:
                return <div className="brd-radius mat-index bg-critical-critical white">{severity}</div>;
            default:
                return null;
          }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnSprint(props) {
    const sprint = props.taskData.Sprint;

    let backColor, foreColor, borderColor;

    // Back, Fore & Border Color
    if(sprint) {
      if(!sprint.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = sprint.BackColor;
      }
      if(!sprint.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((sprint.ForeColor === '#FFFFFF' || sprint.ForeColor === '#ffffff')) {
          borderColor = sprint.BackColor;
        }
        else {
          borderColor = sprint.ForeColor;
        }

        foreColor = sprint.ForeColor;
      }
    }

    if(sprint) {
      if(sprint.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{sprint.Label}</div>;
      }
      else if(sprint.Label) {
        return <div className="axePlanning bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
          <a target="_self" href={`/Card/Sprint/${sprint.Id}/Home`}><span className="iconSprint verysmallIcons mr5"></span></a>
          <span className="">{sprint.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnStatus(props) {
    const status = props.taskData.Status;

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    if(status) {
      switch(status.Id) {
        case 1:
        case 9:
            return <div className="miniplanningAxe brd-radius bg-grey white">{status.Label}</div>;
        case 2:
        case 10:
            return <div className="miniplanningAxe brd-radius bg-blue white">{status.Label}</div>;
        case 3:
            return <div className="miniplanningAxe bordered orange">{status.Label}</div>;
        case 4:
            return <div className="miniplanningAxe bordered grey">{status.Label}</div>;
        case 5:
        case 7:
        case 11:
            return <div className="miniplanningAxe brd-radius bg-green white">{status.Label}</div>;
        case 6:
        case 12:
            return <div className="miniplanningAxe brd-radius bg-orange white">{status.Label}</div>;
        case 8:
            return <div className="miniplanningAxe brd-radius bg-red white">{status.Label}</div>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.taskData.Status;
    let startDate = props.taskData.StartDate;
    let endDate = props.taskData.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline Display={'Dashboard'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateColumnTrend(props) {
    const trend = props.taskData.Trend;

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    if(trend) {
      switch(trend.Id) {
        case 1:
            return <span className="iconTrendDown iconsMiniblock"></span>;
        case 2:
            return <span className="iconTrendRight iconsMiniblock"></span>;
        case 3:
            return <span className="iconTrendUp iconsMiniblock"></span>;
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnValidated(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.taskData.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    const workpackage = props.taskData.Workpackage;

    if(workpackage) {
      if(workpackage.Id === 1) {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{workpackage.Label}</div>;
      }
      else if(workpackage.Label) {
        return <div className="miniplanningAxe bordered blue">
          <a target="_self" href={`/Card/Workpackage/${workpackage.Id}/Home`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
          <span className="">{workpackage.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props.taskData[columnName];

    let backColor, foreColor, borderColor;

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value) {
      if(value.Label === '- NA -') {
        return <div className="miniplanningAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="miniplanningAxe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props.taskData[columnName];

    if(values) {
      return <div className="displayblock">
        {values.map((value, index) => {
          let backColor, foreColor, borderColor;

          // Back, Fore & Border Color
          if(value) {
            if(!value.BackColor) {
              backColor = '#FFFFFF';
            }
            else {
              backColor = value.BackColor;
            }
            if(!value.ForeColor) {
              foreColor = '#0088C7';
              borderColor = '#0088C7';
            }
            else {
              if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                borderColor = value.BackColor;
              }
              else {
                borderColor = value.ForeColor;
              }

              foreColor = value.ForeColor;
            }
          }

          return <div key={index} className={"miniplanningAxe bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // const allowEditing = props.column.allowEditing;
    // const id = itemId + " " + itemType + " " + field + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanMiniTable">
        <div className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    const formatDate = this.state.formatDate;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    let date;

    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
      
      return <div className="">{date}</div>;
    }
    else {
      return <div className=""></div>;
    }
  }

  templateTypeDependency(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    if(value) {
      const itemType = props.Item_Type;

      switch(itemType) {
        case 'Business_Line':
            return (<div className="bold"><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons mr5" alt="Business Line"></span>{value}</div>);
        case 'Project':
            return (<div className="bold"><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons mr5" alt="Project"></span>{value}</div>);
        case 'Workpackage':
            return (<div className="bold"><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons mr5" alt="Workpackage"></span>{value}</div>);
        case 'Action':
            return (<div className="bold"><span title={itemType} className="treeIcon iconActionWhite verysmallIcons mr5" alt="Action"></span>{value}</div>);
        case 'Task':
            return (<div className="bold"><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons mr5" alt="Task"></span>{value}</div>);
        default:
            return <span className="">{value}</span>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeDouble(props) {
    const dictColumns = this.state.dictColumns;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let conditionalFormattings, conditionalFormatting, unit;

    if(column) {
      conditionalFormattings = column.ConditionalFormattings;
      conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, value);
      unit = column.Unit;
    }

    if(conditionalFormatting) {
      if(value || value === 0) {
        if(unit) {
          return <div className="conditionalFormattingPlanning" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="conditionalFormattingPlanning" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      if(value || value === 0) {
        if(unit) {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
  }

  templateTypeFiles(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props.taskData[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            if(value && value.Label) {
              let split = value.Label.split('.');
              let extension = split[split.length - 1];

              if(extension === 'docx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconWord iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pdf') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPDF iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pptx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPPT iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'xlsx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconExcel iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconFile iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
            }
            else {
              return null;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateTypeHTML(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    if(value) {
      return <div className="flex flex-wrap width100p" dangerouslySetInnerHTML={{ __html: value }}></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLink(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props.taskData[columnName];

    if(value) {
      if(value.Id === 1) {
        return <div className="minitableAxe brd-dashed very-light-grey">{value.Label}</div>;
      }
      else if(value.Label) {
        return <div className="minitableLocation">
          <span className="iconMap verysmallIcons"></span>
          <span className="">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    const percentage = value;
    // const percentage = (value * 100).toFixed(0);

    if(value) {
      if(percentage == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 100 && percentage >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(percentage)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeQRCode(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    const url = window.location.origin + '/Card/' + itemType + '/' + itemId.substring(1) + '/Details';

    if(itemId && itemType && url) {
      return <QRCode value={url} size={64} style={{ height: 'auto', width: 'auto' }} viewBox={`0 0 64 64`}/>;
    }

    // Get ColumnName and Value
    // const columnName = this.getColumnName(props);
    // const value = props[columnName];

    // if(value) {
    //   return <img style={{ height: '50px', width: '50px' }} src={'data:image/jpeg;base64,' + value}/>;
    // }
  }

  templateTypeRating(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    if(value == 5) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    if(value == 4) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 3) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 2) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    if(value == 1) {
      return <div className="ratingTable">
        <div className="rating iconRating iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
        <div className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props.taskData[columnName];

    if(value) {
      if(value.Id === 1) {
        let firstname = 'N';
        let lastname = 'A';
  
        return <div className="inline-flex align-items-center">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Label === '- To be assigned -') {
        let firstname = 'T';
        let lastname = 'B';
  
        return <div className="inline-flex align-items-center">
          <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        let split = value.Label.split(' ');
  
        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }
  
        return <div className="inline-flex align-items-center">
          <a target="_self" href={`/Card/Resource/${value.Id}/Home`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
          <span className="ml10 grey">{value.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props.taskData[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeText(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props.taskData[columnName];

    return <div className="">{value}</div>;
  }

  render() {
    let { language, itemId, itemType, blockType, editable, currentView, dictParameters, dictColumns, columns, dataSource } = this.state;

    if(Object.entries(dictParameters).length === 0 || columns.length === 0 || dataSource.length === 0) {
      return <div className=""></div>;
    }

    // Data Fields
    if(blockType === 'Roadmap') {
      if(currentView.DefaultLevel === 0) {
        this.dataFields = { id: 'TaskID', parentID: 'ParentID', name: 'Task_Label', startDate: 'StartDate', endDate: 'EndDate', duration: 'Duration', progress: 'Progress', dependency: 'DependencyID', baselineStartDate: 'Initial_StartDate', baselineEndDate: 'Initial_EndDate', notes: 'EndDateFormat' };
      }
      else {
        this.dataFields = { id: 'TaskID', parentID: 'ParentID', name: 'Task_Label', startDate: 'StartDate', endDate: 'EndDate', duration: 'Duration', progress: 'Progress', dependency: 'DependencyID', baselineStartDate: 'Initial_StartDate', baselineEndDate: 'Initial_EndDate', notes: 'EndDateFormat' };
      }
    }
    else {
      if(blockType === 'Risks') {
        this.dataFields = { id: 'Item_ID', name: 'Task_Label', startDate: 'StartDate', endDate: 'EndDate', duration: 'Duration', notes: 'EndDateFormat' };
      }
      else {
        this.dataFields = { id: 'Item_ID', name: 'Task_Label', endDate: 'EndDate', duration: 'Duration', notes: 'EndDateFormat' };
      }
    }

    // Context Menu
    this.contextMenuItems = [];

    // Edit Options
    this.editOptions = { allowTaskbarEditing: false, allowEditing: false, allowDeleting: false };

    // Grid Lines
    this.gridLines = 'Vertical';

    // Loading Indicator
    this.loadingIndicator = { indicatorType: 'Shimmer' };

    // Work Week
    this.workWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
      <div className="control-pane height100p">
        <div className="control-section height100p">
          {dataSource.length > 0 && 
            <GanttComponent id='GanttTasbar' dataSource={dataSource} locale={Traduction.translate(language, 'locale')} height={'100%'} rowHeight={40} taskbarHeight={25} gridLines={this.gridLines} readOnly={true} taskFields={this.dataFields} contextMenuItems={this.contextMenuItems} editSettings={this.editOptions} labelSettings={{ rightLabel: dictParameters['DisplayLabel'] }} loadingIndicator={this.loadingIndicator} renderBaseline={false} splitterSettings={dictParameters['Splitter']} taskMode={dictParameters['ParentEdition']} timelineSettings={this.getTimelineSettings(dictParameters['GanttPeriod'], dictParameters['GanttZoom'])} treeColumnIndex={this.getTreeColumnIndex(currentView, dictParameters['Columns'])} workWeek={this.workWeek} created={this.created} dataBound={this.dataBound} queryTaskbarInfo={this.queryTaskbarInfo} highlightWeekends={false} showColumnMenu={false} enableContextMenu={false} enablePredecessorValidation={false} enableVirtualization={false} allowFiltering={false} allowReordering={false} allowResizing={true} allowSelection={true} allowSorting={false} allowUnscheduledTasks={true} ref={gantt=>this.gantt=gantt}>
              {/* Display Today */}
              {dictParameters['DisplayToday'] && <EventMarkersDirective><EventMarkerDirective day={new Date()} label={Traduction.translate(language, 'today')}></EventMarkerDirective></EventMarkersDirective>}
              {/* Columns */}
              <ColumnsDirective>
                {/* Create Current view Columns */}
                {dictParameters && dictParameters.Columns.map((column, index) => {
                  let col;

                  // Check if ColumnHeader is sent by backend
                  if(dictColumns[column]) {
                    col = this.createColumn(dictColumns[column], dictParameters['ColumnsWidth'], true);
                  
                    return <ColumnDirective key={index} field={col.field} type={col.type} headerText={col.headerText} format={col.format} template={col.template} isPrimaryKey={col.isPrimaryKey} allowEditing={false} visible={true}></ColumnDirective>
                  }
                })}
                {/* Mandatory Columns */}
                <ColumnDirective field='Item_ID' headerText={'Item_ID'} isPrimaryKey={true} visible={false}></ColumnDirective>
                <ColumnDirective field='Item_Type' headerText={'Item_Type'} isPrimaryKey={false} visible={false}></ColumnDirective>
                <ColumnDirective field='Parent_ID' headerText={'Parent_ID'} isPrimaryKey={false} visible={false}></ColumnDirective>
                {/* Create Mandatory Columns if not in Current view */}
                {!dictParameters.Columns.find(column => column === 'Name') && <ColumnDirective field='Name' headerText={'Name'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'StartDate') && <ColumnDirective field='StartDate' headerText={'StartDate'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'EndDate') && <ColumnDirective field='EndDate' headerText={'EndDate'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'Progress') && <ColumnDirective field='Progress' headerText={'Progress'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'Initial_StartDate') && <ColumnDirective field='Initial_StartDate' headerText={'Initial_StartDate'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'Initial_EndDate') && <ColumnDirective field='Initial_EndDate' headerText={'Initial_EndDate'} isPrimaryKey={false} visible={false}></ColumnDirective>}
                {!dictParameters.Columns.find(column => column === 'Previous_Dependencies') && <ColumnDirective field='Previous_Dependencies' headerText={'Previous_Dependencies'} isPrimaryKey={false} visible={false}></ColumnDirective>}
              </ColumnsDirective>
              <Inject services={[ Toolbar, ContextMenu, Edit, Reorder, Resize, Selection, DayMarkers, VirtualScroll ]} />
            </GanttComponent>
          }
        </div>
      </div>
    )
  }
}

export default MiniPlanning;