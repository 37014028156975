import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      settings: false
    };
    
    this.openMapSettings = this.openMapSettings.bind(this);
    this.closeMapSettings = this.closeMapSettings.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    
    this.setState({ language });
  }

  componentDidUpdate(prevProps) {

  }

  openMapSettings() {
    // Call the event from the Parent component
    this.props.onMapSettingsDisplay();

    this.setState({ settings: true });
  }

  closeMapSettings() {
    // Call the event from the Parent component
    this.props.onMapSettingsClose();

    this.setState({ settings: false });
  }
  
  render() {
    const { language, settings } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'map_parameters')}</strong></Tooltip>}>
            <div className="">
              {!settings && <div className="filtersMapSettings cursor" onClick={() => this.openMapSettings()}>
                <span className="iconParametersBlue iconsFilter"/>
              </div>}
              {settings && <div className="filtersMapSettingsActive cursor" onClick={() => this.closeMapSettings()}>
                <span className="iconParametersBlue iconsFilter"/>
              </div>}
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersMap;