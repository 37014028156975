import React, {Component} from 'react';
import { Badge, ProgressBar, Popover, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { L10n, getValue } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { getObject } from '@syncfusion/ej2-grids';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, ColumnChooser, ColumnMenu, ContextMenu, Edit, Toolbar } from '@syncfusion/ej2-react-treegrid';
import { Inject, Filter, Sort, Reorder, Resize, InfiniteScroll, VirtualScroll, Page, ExcelExport, PdfExport } from '@syncfusion/ej2-react-treegrid';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import QRCode from "react-qr-code";
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import IconCloud from '../Images/IconCloud.png';
import IconMoon from '../Images/IconMoon.png';
import IconRain from '../Images/IconRain.png';
import IconSun from '../Images/IconSun.png';
import IconThunder from '../Images/IconThunder.png';
import PopoverColumnActions from './PopoverColumnActions';
import PopoverEditAxe from './PopoverEditAxe';
import PopoverEditObjectTable from './PopoverEditObjectTable';
import PopupConfirmation from './PopupConfirmation';
import PopupCutPaste from './PopupCutPaste';
import PopupEditFiles from './PopupEditFiles';
import Timeline from './Timeline';
import TableAudit from './TableAudit';
import TextEditor from './TextEditor';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      formatDate: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      editable: null,
      guestLicence: null,
      currentView: {},
      dictParameters: {},
      columns: [],
      dictColumns: {},
      dataSource: [],
      auditPopup: false,
      filesPopup: false,
      textEditorPopup: false,
      confirmDelete: false,
      confirmCutPaste: false,
      cutItemId: null,
      cutItemType: null,
      rowItemId: null,
      rowItemType: null,
      rowItemTitle: null,
      rowItemColumn: null,
      rowItemLabel: null,
      rowItemValue: null,
      refreshCount: 0,
      refreshInProgress: false,
      error: {}
    };

    this.treeClicked = false;

    // Tree Structure
    this.dataStructure = this.dataStructure.bind(this);
    this.getCurrentViewParameters = this.getCurrentViewParameters.bind(this);
    this.getColumnParameters = this.getColumnParameters.bind(this);

    // Fonctions
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);
    this.formatDateKr = this.formatDateKr.bind(this);
    this.getColumnEditable = this.getColumnEditable.bind(this);
    this.getColumnEditionItemType = this.getColumnEditionItemType.bind(this);
    this.getColumnFilter = this.getColumnFilter.bind(this);
    this.getColumnLabel = this.getColumnLabel.bind(this);
    this.getColumnName = this.getColumnName.bind(this);
    this.getColumnAllowEditing = this.getColumnAllowEditing.bind(this);
    // this.getColumnRestrictedValues = this.getColumnRestrictedValues.bind(this);
    this.getColumnSort = this.getColumnSort.bind(this);
    this.getColumnType = this.getColumnType.bind(this);
    // this.getColumnUnit = this.getColumnUnit.bind(this);
    // this.getColumnConditionalFormattings = this.getColumnConditionalFormattings.bind(this);
    this.getCellConditionalFormatting = this.getCellConditionalFormatting.bind(this);
    this.isConditionalFormattingRespected = this.isConditionalFormattingRespected.bind(this);
    this.getCurrentViewLevel = this.getCurrentViewLevel.bind(this);
    this.getDeleteLabel = this.getDeleteLabel.bind(this);
    this.getModificationValue = this.getModificationValue.bind(this);
    this.getSelectedRow = this.getSelectedRow.bind(this);
    this.getRowIndex = this.getRowIndex.bind(this);
    this.getRowItemId = this.getRowItemId.bind(this);
    this.getTreeColumnIndex = this.getTreeColumnIndex.bind(this);
    this.getTreeSearchSettings = this.getTreeSearchSettings.bind(this);

    // Syncfusion Events
    this.dataBound = this.dataBound.bind(this);
    this.rowDataBound = this.rowDataBound.bind(this);
    this.contextMenuOpen = this.contextMenuOpen.bind(this);
    this.contextMenuClick = this.contextMenuClick.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.cellEdit = this.cellEdit.bind(this);

    // Actions
    this.refreshComponent = this.refreshComponent.bind(this);
    this.refreshColumns = this.refreshColumns.bind(this);
    this.refreshDatasource = this.refreshDatasource.bind(this);
    this.createColumn = this.createColumn.bind(this);

    this.filterColumns = this.filterColumns.bind(this);
    this.sortColumn = this.sortColumn.bind(this);
    this.sortColumns = this.sortColumns.bind(this);
    this.autofitColumn = this.autofitColumn.bind(this);
    this.autofitAllColumns = this.autofitAllColumns.bind(this);
    this.freezeColumns = this.freezeColumns.bind(this);
    this.multipleModification = this.multipleModification.bind(this);
    this.resizeStop = this.resizeStop.bind(this);
    this.collapsing = this.collapsing.bind(this);
    this.expanding = this.expanding.bind(this);
    this.editAxe = this.editAxe.bind(this);
    this.editObjectTable = this.editObjectTable.bind(this);
    this.onBooleanColumnClick = this.onBooleanColumnClick.bind(this);
    this.updateDatasource = this.updateDatasource.bind(this);
    this.updateDictModifications = this.updateDictModifications.bind(this);
    this.updateGridCell = this.updateGridCell.bind(this);
    this.cancelModification = this.cancelModification.bind(this);
    this.addRowsInDatasource = this.addRowsInDatasource.bind(this);
    this.buildDatasourceRow = this.buildDatasourceRow.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.confirmCutPaste = this.confirmCutPaste.bind(this);
    this.cancelCutPaste = this.cancelCutPaste.bind(this);
    this.deleteRowInDatasource = this.deleteRowInDatasource.bind(this);
    this.getChildToDelete = this.getChildToDelete.bind(this);
    this.excelQueryCellInfo = this.excelQueryCellInfo.bind(this);
    this.pdfQueryCellInfo = this.pdfQueryCellInfo.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.searchItem = this.searchItem.bind(this);

    // Popup
    this.popupTextEditor = this.popupTextEditor.bind(this);
    this.closeAuditPopup = this.closeAuditPopup.bind(this);
    this.closeFilesPopup = this.closeFilesPopup.bind(this);
    this.closeTextEditorPopup = this.closeTextEditorPopup.bind(this);

    // Template Header
    this.templateHeader = this.templateHeader.bind(this);
    // Template Columns
    this.templateColumnAction = this.templateColumnAction.bind(this);
    this.templateColumnActive = this.templateColumnActive.bind(this);
    this.templateColumnAdmin = this.templateColumnAdmin.bind(this);
    this.templateColumnBudgetCode = this.templateColumnBudgetCode.bind(this);
    this.templateColumnBusinessLine = this.templateColumnBusinessLine.bind(this);
    this.templateColumnDataFreshness = this.templateColumnDataFreshness.bind(this);
    this.templateColumnDependency = this.templateColumnDependency.bind(this);
    this.templateColumnEmail = this.templateColumnEmail.bind(this);
    this.templateColumnEntity = this.templateColumnEntity.bind(this);
    this.templateColumnExternal = this.templateColumnExternal.bind(this);
    this.templateColumnHighlighted = this.templateColumnHighlighted.bind(this);
    this.templateColumnImpact = this.templateColumnImpact.bind(this);
    this.templateColumnItemType = this.templateColumnItemType.bind(this);
    this.templateColumnLag = this.templateColumnLag.bind(this);
    this.templateColumnLicenceType = this.templateColumnLicenceType.bind(this);
    this.templateColumnMeteo = this.templateColumnMeteo.bind(this);
    this.templateColumnMeteoFreshness = this.templateColumnMeteoFreshness.bind(this);
    this.templateColumnMonthBurned = this.templateColumnMonthBurned.bind(this);
    this.templateColumnName = this.templateColumnName.bind(this);
    this.templateColumnPriority = this.templateColumnPriority.bind(this);
    this.templateColumnProbability = this.templateColumnProbability.bind(this);
    this.templateColumnProgress = this.templateColumnProgress.bind(this);
    this.templateColumnProject = this.templateColumnProject.bind(this);
    this.templateColumnResourceName = this.templateColumnResourceName.bind(this);
    this.templateColumnRowType = this.templateColumnRowType.bind(this);
    this.templateColumnSprint = this.templateColumnSprint.bind(this);
    this.templateColumnStatus = this.templateColumnStatus.bind(this);
    this.templateColumnTask = this.templateColumnTask.bind(this);
    this.templateColumnTimeline = this.templateColumnTimeline.bind(this);
    this.templateColumnTrend = this.templateColumnTrend.bind(this);
    this.templateColumnValidated = this.templateColumnValidated.bind(this);
    this.templateColumnWarning = this.templateColumnWarning.bind(this);
    this.templateColumnWorkpackage = this.templateColumnWorkpackage.bind(this);
    this.templateColumnYear = this.templateColumnYear.bind(this);
    // Template Types
    this.templateTypeAxe = this.templateTypeAxe.bind(this);
    this.templateTypeAxisTable = this.templateTypeAxisTable.bind(this);
    this.templateTypeBoolean = this.templateTypeBoolean.bind(this);
    this.templateTypeDate = this.templateTypeDate.bind(this);
    this.templateTypeDouble = this.templateTypeDouble.bind(this);
    this.templateTypeFiles = this.templateTypeFiles.bind(this);
    this.templateTypeHTML = this.templateTypeHTML.bind(this);
    this.templateTypeLink = this.templateTypeLink.bind(this);
    this.templateTypeLocation = this.templateTypeLocation.bind(this);
    this.templateTypePercentage = this.templateTypePercentage.bind(this);
    this.templateTypeProgress = this.templateTypeProgress.bind(this);
    this.templateTypeQRCode = this.templateTypeQRCode.bind(this);
    this.templateTypeRating = this.templateTypeRating.bind(this);
    this.templateTypeResource = this.templateTypeResource.bind(this);
    this.templateTypeResourceTable = this.templateTypeResourceTable.bind(this);
    this.templateTypeText = this.templateTypeText.bind(this);
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const formatDate = Authentication.getCookie('formatDate');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const itemTitle = this.props.Title;
    const blockType = this.props.BlockType;
    const editable = this.props.Editable;
    const guestLicence = this.props.GuestLicence;
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Planning Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ authId, language, formatDate, itemId, itemType, itemTitle, blockType, editable, guestLicence, currentView, dictParameters, dictColumns, columns, dataSource, refreshCount: 1 });
  }

  componentDidUpdate(prevProps) {
    // const currentView = this.props.CurrentView;
    // const columns = this.props.Columns;
    // const rows = this.props.Rows;

    // if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
    //   this.setState({ currentView });
    // }
    // if(this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
    //   // Build Table Datasource
    //   let dataSource = this.dataStructure(rows);

    //   this.setState({ columns, rows, dataSource });
    // }
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   if(this.state.refreshCount !== prevState.refreshCount) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // Build Datasource
  dataStructure(rows) {
    let dataSource = [];

    if(rows.length > 0) {
      dataSource = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Add the Column Name/Values to the reduced Table
          // Format Dates
          if(item.FieldType === 'Date' && item.Value) {
            acc[item.ColumnName] = new Date(item.Value);
          }
          // Format Numbers
          else if(item.FieldType === 'Double' && item.Value) {
            acc[item.ColumnName] = parseFloat(item.Value);
          }
          // Format Meteo/Trend
          else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects
          else if(item.FieldType === 'Object' && item.Value) {
            acc[item.ColumnName] = JSON.parse(item.Value);
          }
          // Format Objects Table
          else if(item.FieldType === 'ObjectTable' || item.FieldType === 'Files' || item.FieldType === 'ResourceTable') {
            if(item.Value) {
              acc[item.ColumnName] = JSON.parse(item.Value);
            }
            else {
              acc[item.ColumnName] = [];
            }
          }
          // Format Collapse Boolean
          else if(item.ColumnName === 'isExpanded') {
            if(item.Value === 'False') {
              acc[item.ColumnName] = false;
            }
            else if(item.Value === 'True') {
              acc[item.ColumnName] = true;
            }
          }
          else {
            acc[item.ColumnName] = item.Value;
          }

          return acc;
        }, {});
      });
    }

    return dataSource;
  }

  // Build Current View Parameters
  getCurrentViewParameters(currentView) {
    let dictParameters = {};

    // Default values
    dictParameters['Columns'] = [];
    dictParameters['ColumnsWidth'] = [];

    for(let i=0; i < currentView.Parameters.length; i++) {
      // Columns
      if(currentView.Parameters[i].Name === 'Columns') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value.split(',');
        }
      }
      // Columns Width
      else if(currentView.Parameters[i].Name === 'ColumnsWidth') {
        if(currentView.Parameters[i].Value) {
          dictParameters[currentView.Parameters[i].Name] = JSON.parse(currentView.Parameters[i].Value);
        }
      }
      else {
        dictParameters[currentView.Parameters[i].Name] = currentView.Parameters[i].Value;
      }
    }

    return dictParameters;
  }

  // Build Current View Columns
  getColumnParameters(columnHeaders) {
    let dictColumns = {};

    for(let i=0; i < columnHeaders.length; i++) {
      if(columnHeaders[i]) {
        dictColumns[columnHeaders[i].FieldName] = columnHeaders[i];
      }
    }

    return dictColumns;
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to Korean format
  formatDateKr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = year + "-" + month + "-" + day;
    }

    return formattedDate;
  }

  getColumnEditable(editionItemTypes) {
    const currentView = this.state.currentView;

    // Get View corresponding level
    const level = this.getCurrentViewLevel(currentView);
     
    if(editionItemTypes.includes(level)) {
      return true;
    }
    else {
      return false;
    }
  }

  getColumnEditionItemType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.EditionItemTypes;
    }
    else {
      return "";
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).EditionItemTypes;
    // }
    // else {
    //   return false;
    // }
  }

  getColumnFilter(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Filters && currentView.Filters.length > 0) {
      if(currentView.Filters.find(filter => filter.Field === columnName)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  getColumnLabel(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];
    let label = '';

    if(columnName === 'WarningMessage') {
      label = 'W';
    }
    else if(column) {
      label = column.Label;
    }

    // if(columnName === 'WarningMessage') {
    //   label = 'W';
    // }
    // else if(columns.find(column => column.FieldName === columnName)) {
    //   label = columns.find(column => column.FieldName === columnName).Label;
    // }
    
    return label;
  }

  getColumnName(props) {
    const dictParameters = this.state.dictParameters;
    let columnName = '';

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
    }
    else {
      columnName = props.column.field;
    }

    return columnName.replace(".Label", "");
  }

  getColumnAllowEditing(props) {
    const { dictParameters, dictColumns } = this.state;
    let column, columnName, allowEditing;

    // Get Column Name
    if(!props || !props.column) {
      columnName = dictParameters['Columns'][0];
      column = dictColumns[columnName];

      if(column && column.EditionItemTypes.includes(props.Item_Type)) {
        allowEditing = true;
      }
      else {
        allowEditing = false;
      }
    }
    else {
      allowEditing = props.column.allowEditing;
    }

    return allowEditing;
  }

  getColumnRestrictedValues(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.RestrictedValues;
    }
    else {
      return [];
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).RestrictedValues;
    // }
    // else {
    //   return [];
    // }
  }

  getColumnSort(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        return "descending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        return "ascending";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        return "ascending";
      }
      else {
        return false;
      }
      
      // if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName + " DESC")) {
      //   return "descending";
      // }
      // else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.includes(columnName)) {
      //   return "ascending";
      // }
    }
    else {
      return false;
    }
  }

  getColumnType(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.FieldType;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).FieldType;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnUnit(columnName) {
    // let columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.Unit;
    }
    else {
      return null;
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).Unit;
    // }
    // else {
    //   return null;
    // }
  }

  getColumnConditionalFormattings(columnName) {
    // const columns = this.state.columns;
    const dictColumns = this.state.dictColumns;
    const column = dictColumns[columnName];

    if(column) {
      return column.ConditionalFormattings;
    }
    else {
      return [];
    }

    // if(columns.find(column => column.FieldName === columnName)) {
    //   return columns.find(column => column.FieldName === columnName).ConditionalFormattings;
    // }
    // else {
    //   return [];
    // }
  }

  getCellConditionalFormatting(conditionalFormattings, value) {
    if(conditionalFormattings) {
      for(let i = 0; i < conditionalFormattings.length; i++) {
        if(this.isConditionalFormattingRespected(conditionalFormattings[i], value)) {
          return conditionalFormattings[i];
        }
      }
    }
    else {
      return null;
    }
  }

  isConditionalFormattingRespected(conditionalFormatting, value) {
    if(conditionalFormatting) {
      switch(conditionalFormatting.Conditions) {
        case 'equal':
            if(conditionalFormatting.Value1 == value) {
              return true;
            }
            else {
              return false;
            }
        case 'notequal':
            if(conditionalFormatting.Value1 != value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthan':
            if(conditionalFormatting.Value1 < value) {
              return true;
            }
            else {
              return false;
            }
        case 'greaterthanorequal':
            if(conditionalFormatting.Value1 <= value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthan':
            if(conditionalFormatting.Value1 > value) {
              return true;
            }
            else {
              return false;
            }
        case 'lessthanorequal':
            if(conditionalFormatting.Value1 >= value) {
              return true;
            }
            else {
              return false;
            }
        case 'between':
            if(conditionalFormatting.Value1 <= value && conditionalFormatting.Value2 >= value) {
              return true;
            }
            else {
              return false;
            }
        default:
            return false;
      }
    }
    else {
      return false;
    }
  }

  getCurrentViewLevel() {
    const currentView = this.state.currentView;
    let level;

    // Get View corresponding level
    if(currentView) {
      switch(currentView.DefaultLevel) {
        case 0:
            level = 'Tree';
            break;
        case 1:
            level = 'Business_Line';
            break;
        case 2:
            level = 'Project';
            break;
        case 3:
            level = 'Workpackage';
            break;
        case 4:
            level = 'Action';
            break;
        case 5:
            level = 'Task';
            break;
        case 6:
            level = 'Risk';
            break;
        case 7:
            level = 'Issue';
            break;
        case 8:
            level = 'Decision';
            break;
        case 9:
            level = 'Meeting';
            break;
        case 10:
            level = 'Definition';
            // level = 'Budget';
            break;
        case 11:
            level = 'Link';
            break;
        case 12:
            level = 'Resource';
            break;
        case 13:
            level = 'Entity';
            break;
        case 14:
            level = 'User';
            break;
        case 15:
            level = 'AxisValue';
            break;
        case 16:
            level = 'DeletedItem';
            break;
        case 18:
            level = 'Workload';
            break;
        case 19:
            level = 'CustomField';
            break;
        case 20:
            level = 'HashTag';
            break;
        case 21:
            level = 'BudgetCode';
            break;
        case 22:
            level = 'UserGroup';
            break;
        case 23:
            level = 'Warning';
            break;
        case 24:
            level = 'Sprint';
            break;
        case 25:
            level = 'AgileBoard';
            break;
        case 26:
            level = 'Dependency';
            break;
        case 30:
            level = 'NotificationRule';
            break;
        default:
            break;
      }
    }

    return level;
  }

  getDeleteLabel(itemType) {
    const language = this.state.language;

    switch(itemType) {
      case 'Business_Line':
          return Traduction.translate(language, 'delete_business_line');
      case 'Project':
          return Traduction.translate(language, 'delete_project');
      case 'Workpackage':
          return Traduction.translate(language, 'delete_workpackage');
      case 'Action':
          return Traduction.translate(language, 'delete_action');
      case 'Task':
          return Traduction.translate(language, 'delete_task');
      case 'Entity':
          return Traduction.translate(language, 'delete_entity');
      case 'Resource':
          return Traduction.translate(language, 'delete_resource');
      case 'AgileBoard':
          return Traduction.translate(language, 'delete_agile_board');
      case 'Sprint':
          return Traduction.translate(language, 'delete_sprint');
      case 'Definition':
          return Traduction.translate(language, 'delete_budget');
    }
  }

  getModificationValue(columnName, newValue) {
    let value;

    // Get Column Type
    const columnType = this.getColumnType(columnName);
      
    // Parse Object for Axes
    if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Resource' || columnType === 'ResourceTable' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity') {
      value = JSON.parse(newValue);
    }
    // Parse Double
    else if(columnType === 'Double') {
      value = parseFloat(newValue);
    }
    // Parse Date
    else if(columnType === 'Date') {
      if(newValue) {
        value = new Date(newValue);
      }
      else {
        value = "";
      }
    }
    else {
      value = newValue;
    }

    return value;
  }

  getSelectedRow() {
    if(this.treegrid) {
      return this.treegrid.getSelectedRecords();
    }
  }

  getRowIndex(modificationId) {
    let index = -1;

    if(this.treegrid && this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
      // Get Row index
      index = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
      // index = this.treegrid.dataSource.findIndex(row => row['Item_ID'] === modificationId);
    }

    return index;
  }

  getRowItemId(itemType, itemId) {
    let rowItemId;

    if(itemType === 'AgileBoard') {
      rowItemId = 'O'.concat(itemId);
    }
    else if(itemType === 'Budget') {
      rowItemId = 'D'.concat(itemId);
    }
    else {
      rowItemId = itemType.substring(0,1).concat(itemId);
    }

    return rowItemId;
  }

  getTreeColumnIndex(columns) {
    for(let i = 0; i < columns.length; i++) {
      if(columns[i] === 'Name') {
        return i;
      }
    }

    return 0;
  }

  getTreeSearchSettings(dictColumns, columns) {
    let fields = [];

    for(let i = 0; i < columns.length; i++) {
      if(dictColumns[columns[i]] && (dictColumns[columns[i]].FieldType === 'Axe' || dictColumns[columns[i]].FieldType === 'AxisTable' || dictColumns[columns[i]].FieldType === 'Resource' || dictColumns[columns[i]].FieldType === 'ResourceTable' || dictColumns[columns[i]].FieldType === 'Location' || dictColumns[columns[i]].FieldName === 'Meteo' || dictColumns[columns[i]].FieldName === 'Trend' || dictColumns[columns[i]].FieldName === 'Business_Line' || dictColumns[columns[i]].FieldName === 'Project' || dictColumns[columns[i]].FieldName === 'Workpackage' || dictColumns[columns[i]].FieldName === 'Action' || dictColumns[columns[i]].FieldName === 'Task' || dictColumns[columns[i]].FieldName === 'Entity')) {
        fields.push(dictColumns[columns[i]].FieldName + ".Label");
      }
      else if(dictColumns[columns[i]]) {
        fields.push(dictColumns[columns[i]].FieldName);
      }
    }

    return { fields: fields };
  }

  dataBound() {
    if(this.treegrid) {
      // Tree Event Listener
      if(!this.treeClicked) {
        this.treegrid.element.addEventListener('click', this.onBooleanColumnClick);
        // this.treegrid.element.addEventListener('dblclick', this.onHolidayDoubleClick);
        this.treeClicked = true;
      }
    }
  }

  rowDataBound(args) {
    const itemType = this.state.itemType;
    const rowItemType = getObject('Item_Type', args.data);

    // Define styles for Objects rows
    if(rowItemType === 'Business_Line') {
      args.rowHeight = 30;
      args.row.style.fontSize = '14px';
      // args.row.style.fontWeight = 'bold';

      if(itemType === 'Environment') {
        args.row.style.borderBottom = '1px solid #D4D4DA';
      }
    }
    else if(rowItemType === 'Project') {      
      args.rowHeight = 30;
      args.row.style.fontSize = '13px';
      // args.row.style.fontWeight = 'bold';
    }
    else if(rowItemType === 'Workpackage') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';

      // Skip border top for the first element
      if(args.data.index !== 0) {
        args.row.style.borderTop = '1px solid #777777';
      }
    }
    else if(rowItemType === 'Action') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';

      // Skip border top for the first element
      if(args.data.index !== 0) {
        args.row.style.borderTop = '1px solid #D4D4DA';
      }
    }
    else if(rowItemType === 'Task') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '100';
    }
    else if(rowItemType === 'Definition') {
      if(args.data.Parent_ID) {
        args.rowHeight = 30;
        // args.row.style.fontWeight = '100';
        args.row.style.borderTop = '1px solid #D4D4DA';
      }
      else {
        args.rowHeight = 30;
        // args.row.style.fontWeight = '500';
      }
    }
    else if(rowItemType === 'Entity') {
      args.rowHeight = 30;
      args.row.style.fontSize = '14px';
      // args.row.style.fontWeight = 'bold';
      args.row.style.borderBottom = '1px solid #D4D4DA';
    }
    else if(rowItemType === 'Resource') {
      args.rowHeight = 30;
      args.row.style.fontWeight = '500';
    }
    else if(rowItemType === 'AgileBoard') {
      args.rowHeight = 30;
      args.row.style.fontSize = '13px';
      // args.row.style.fontWeight = 'bold';
    }
    else if(rowItemType === 'Sprint') {
      args.rowHeight = 30;
      // args.row.style.fontWeight = 'bold';
      args.row.style.borderBottom = '1px solid #777777';
    }
  }

  // Before Right Click Events
  contextMenuOpen(args) {
    const { itemType, editable, guestLicence, cutItemId, cutItemType } = this.state;
    let rowItemType;

    // Get Right Click items
    let items = document.querySelectorAll('.e-menu-item');

    // (0: Add BL, 1: Add Project, 2: Add Workpackage, 3: Add Action, 4: Add Task, 5: Add Resource, 6: Add Definition, 7: Add Sprint, 8: Cut, 9: Paste, 10: Duplicate, 11: Delete, 12: Dependencies, 13: Audit)
    const business_line = 0;
    const project = 1;
    const workpackage = 2;
    const action = 3;
    const task = 4;
    const resource = 5;
    const budget = 6;
    const sprint = 7;
    const cut = 8;
    const paste = 9;
    const duplicate = 10;
    const remove = 11;
    const dependencies = 12;
    const audit = 13;

    // for(let i = 0; i < items.length; i++) {
    //   items.item(i).setAttribute('style','display: none;');
    //   items.item(i).setAttribute('style','display: block;');
    // }
    
    if(this.treegrid) {
      if(items && args.rowInfo.rowData) {
        // Get Row Item Type
        rowItemType = args.rowInfo.rowData.Item_Type;

        if(editable && !guestLicence) {
          // Display Action depending on Row ItemType
          switch(rowItemType) {
            case 'Business_Line':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: block;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: none;');

                if(cutItemId && cutItemType) {
                  items.item(paste).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(paste).setAttribute('style', 'display: none;');
                }

                items.item(duplicate).setAttribute('style', 'display: block;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: block;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Project':
                if(itemType === 'Environment') {
                  items.item(business_line).setAttribute('style', 'display: none;');
                  items.item(project).setAttribute('style', 'display: none;');
                  items.item(workpackage).setAttribute('style', 'display: none;');
                  items.item(action).setAttribute('style', 'display: none;');
                  items.item(task).setAttribute('style', 'display: none;');
                  items.item(resource).setAttribute('style', 'display: none;');
                  items.item(budget).setAttribute('style', 'display: none;');
                  items.item(sprint).setAttribute('style', 'display: none;');
                  items.item(cut).setAttribute('style', 'display: block;');
                  
                  if(cutItemId && cutItemType) {
                    items.item(paste).setAttribute('style', 'display: block;');
                  }
                  else {
                    items.item(paste).setAttribute('style', 'display: none;');
                  }

                  items.item(duplicate).setAttribute('style', 'display: block;');
                  items.item(remove).setAttribute('style', 'display: block;');
                  items.item(dependencies).setAttribute('style', 'display: none;');
                  items.item(audit).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(business_line).setAttribute('style', 'display: none;');
                  items.item(project).setAttribute('style', 'display: none;');
                  items.item(workpackage).setAttribute('style', 'display: block;');
                  items.item(action).setAttribute('style', 'display: block;');
                  items.item(task).setAttribute('style', 'display: none;');
                  items.item(resource).setAttribute('style', 'display: none;');
                  items.item(budget).setAttribute('style', 'display: none;');
                  items.item(sprint).setAttribute('style', 'display: none;');
                  items.item(cut).setAttribute('style', 'display: block;');

                  if(cutItemId && cutItemType) {
                    items.item(paste).setAttribute('style', 'display: block;');
                  }
                  else {
                    items.item(paste).setAttribute('style', 'display: none;');
                  }

                  items.item(duplicate).setAttribute('style', 'display: block;');
                  items.item(remove).setAttribute('style', 'display: block;');
                  items.item(dependencies).setAttribute('style', 'display: block;');
                  items.item(audit).setAttribute('style', 'display: block;');
                }
                break;
            case 'Workpackage':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: block;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: block;');

                if(cutItemId && cutItemType) {
                  items.item(paste).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(paste).setAttribute('style', 'display: none;');
                }

                items.item(duplicate).setAttribute('style', 'display: block;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: block;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Action':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: block;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: block;');

                if(cutItemId && cutItemType) {
                  items.item(paste).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(paste).setAttribute('style', 'display: none;');
                }

                items.item(duplicate).setAttribute('style', 'display: block;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: block;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Task':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: block;');

                if(cutItemId && cutItemType) {
                  items.item(paste).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(paste).setAttribute('style', 'display: none;');
                }

                items.item(duplicate).setAttribute('style', 'display: block;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: block;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Entity':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: block;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: none;');
                
                if(cutItemId && cutItemType) {
                  items.item(paste).setAttribute('style', 'display: block;');
                }
                else {
                  items.item(paste).setAttribute('style', 'display: none;');
                }

                items.item(duplicate).setAttribute('style', 'display: none;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: none;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Resource':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: block;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: block;');
                items.item(paste).setAttribute('style', 'display: none;');
                items.item(duplicate).setAttribute('style', 'display: none;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: none;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Definition': 
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: block;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: none;');
                items.item(paste).setAttribute('style', 'display: none;');
                items.item(duplicate).setAttribute('style', 'display: none;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: none;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'AgileBoard':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: block;');
                items.item(cut).setAttribute('style', 'display: none;');
                items.item(paste).setAttribute('style', 'display: none;');
                items.item(duplicate).setAttribute('style', 'display: none;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: none;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            case 'Sprint':
                items.item(business_line).setAttribute('style', 'display: none;');
                items.item(project).setAttribute('style', 'display: none;');
                items.item(workpackage).setAttribute('style', 'display: none;');
                items.item(action).setAttribute('style', 'display: none;');
                items.item(task).setAttribute('style', 'display: none;');
                items.item(resource).setAttribute('style', 'display: none;');
                items.item(budget).setAttribute('style', 'display: none;');
                items.item(sprint).setAttribute('style', 'display: none;');
                items.item(cut).setAttribute('style', 'display: none;');
                items.item(paste).setAttribute('style', 'display: none;');
                items.item(duplicate).setAttribute('style', 'display: none;');
                items.item(remove).setAttribute('style', 'display: block;');
                items.item(dependencies).setAttribute('style', 'display: none;');
                items.item(audit).setAttribute('style', 'display: block;');
                break;
            default:
                break;
          }
        }
        else {
          items.item(0).setAttribute('style', 'display: block;');
        }
      }
      else {
        if(editable && !guestLicence) {
          items.item(business_line).setAttribute('style', 'display: none;');
          items.item(project).setAttribute('style', 'display: none;');
          items.item(workpackage).setAttribute('style', 'display: none;');
          items.item(action).setAttribute('style', 'display: none;');
          items.item(task).setAttribute('style', 'display: none;');
          items.item(resource).setAttribute('style', 'display: none;');
          items.item(budget).setAttribute('style', 'display: none;');
          items.item(sprint).setAttribute('style', 'display: none;');
          items.item(cut).setAttribute('style', 'display: none;');
          items.item(paste).setAttribute('style', 'display: none;');
          items.item(duplicate).setAttribute('style', 'display: none;');
          items.item(remove).setAttribute('style', 'display: none;');
          items.item(dependencies).setAttribute('style', 'display: none;');
          items.item(audit).setAttribute('style', 'display: block;');
        }
        else {
          items.item(0).setAttribute('style','display: block;');
        }
      }
    }
  }

  // Right Click Events
  contextMenuClick(args) {
    if(args.item.id === 'contextMenu_AddBL') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Business_Line');
    }
    else if(args.item.id === 'contextMenu_AddProject') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Project');
    }
    else if(args.item.id === 'contextMenu_AddWorkpackage') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Workpackage');
    }
    else if(args.item.id === 'contextMenu_AddAction') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Action');
    }
    else if(args.item.id === 'contextMenu_AddTask') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Task');
    }
    else if(args.item.id === 'contextMenu_AddResource') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Resource');
    }
    else if(args.item.id === 'contextMenu_AddDefinition') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Definition', true);
    }
    else if(args.item.id === 'contextMenu_AddSprint') {
      // Call the event from the Parent component through the props
      this.props.onItemCreate('', 'Sprint');
    }
    else if(args.item.id === 'contextMenu_Cut') {
      let selectedRows = this.treegrid.getSelectedRecords();

      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;
        let name = selectedRows[0].Name;

        this.setState({ cutItemType: itemType, cutItemId: itemId, rowItemTitle: name });
      }
    }
    else if(args.item.id === 'contextMenu_Paste') {
      const cutItemId = this.state.cutItemId;
      const cutItemType = this.state.cutItemType;
      let selectedRows = this.treegrid.getSelectedRecords();

      if(cutItemId && cutItemType) {
        if(selectedRows.length > 0) {
          let itemId = selectedRows[0].Item_ID.substring(1);
          let itemType = selectedRows[0].Item_Type;

          if(itemType === 'Entity') {
            this.setState({ confirmCutPaste: true, rowItemType: itemType, rowItemId: itemId });
          }
          else {
            // Call the event from the Parent component through the props
            this.props.onItemCutPaste(cutItemType, cutItemId, itemType, itemId);
          }
        }
      }
    }
    else if(args.item.id === 'contextMenu_Duplicate') {
      let selectedRows = this.treegrid.getSelectedRecords();
      
      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;

        // Call the event from the Parent component through the props
        this.props.onItemCreate('', itemType, itemId, true);
      }
    }
    else if(args.item.id === 'contextMenu_Delete') {
      let selectedRows = this.treegrid.getSelectedRecords();
      
      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;

        this.setState({ confirmDelete: true, rowItemType: itemType, rowItemId: itemId });
      }
    }
    else if(args.item.id === 'contextMenu_Dependencies') {
      let selectedRows = this.treegrid.getSelectedRecords();
      
      if(selectedRows[0]) {
        // Call the event from the Parent component through the props
        this.props.onDependenciesDisplay(selectedRows[0]);
      }
    }
    else if(args.item.id === 'contextMenu_Audit') {
      let selectedRows = this.treegrid.getSelectedRecords();

      if(selectedRows.length > 0) {
        let itemId = selectedRows[0].Item_ID.substring(1);
        let itemType = selectedRows[0].Item_Type;
        let name = selectedRows[0].Name;

        this.setState({ auditPopup: true, rowItemType: itemType, rowItemId: itemId, rowItemTitle: name });
      }
    }
  }

  actionBegin(args) {
    const { refreshInProgress } = this.state;

    // Refresh
    if(args.requestType === 'refresh') {
      if(refreshInProgress) {
        args.cancel = true;
      }
      else {
        args.cancel = false;
      }
    }
    // Search
    else if(args.requestType === 'searching') {
      if(args.searchString || args.searchString === '') {
        args.cancel = false;
      }
      else {
        args.cancel = true;
      }
    }
  }

  actionComplete(args) {
    const { currentView, dictParameters } = this.state;

    // Columns
    if(args.requestType === 'columnstate' || args.requestType === 'reorder') {
      let columnNames = '', columns = [];

      this.treegrid.columns.forEach((column, index) => {
        if(column.visible) {
          // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
          column.field = column.field.replace(".Label", "");

          columns.push(column.field);
        }
      });

      columnNames = columns.join(',');

      // Update Current View Columns list
      // if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
      //   currentView.Parameters.find(param => param.Name === 'Columns').Value = columnNames;
      // }

      // Add Modify attribute in CurrentView
      // currentView["Modify"] = true;

      // Call the event from the Parent component through the props with view value
      // this.props.onViewChange(currentView);

      // Call the event from the Parent component through the props with view value
      this.props.onColumnReorder(columnNames);
    }
    // Delete
    // else if(args.requestType === 'delete') {
    //   if(args.data) {
    //     const itemId = args.data[0].Item_ID.substring(1);
    //     const itemType = args.data[0].Item_Type;
    //     const deleteId = itemType.substring(0,1).concat(itemId);

    //     this.setState({ confirm: true, rowItemType: itemType, rowItemId: itemId });
    //   }
    // }
    // Save
    else if(args.type == 'save') {
      if(args.data) {
        const itemId = args.data.Item_ID.substring(1);
        const itemType = args.data.Item_Type;
        const columnName = args.column.field;
        const type = args.column.type;
        let oldValue = args.previousData;
        let newValue = args.data[columnName];
        // const warning = args.data['WarningMessage'];
    
        // Force Date format after Datepicker modification (format MM/dd/YYYY for Database)
        if(type === 'date' || columnName === 'StartDate' || columnName === 'EndDate' || columnName === 'Initial_StartDate' || columnName === 'Initial_EndDate') {
          if(newValue) {
            newValue = this.formatDateEn(newValue);
          }
          else {
            newValue = "";
          }
  
          if(oldValue) {
            oldValue = this.formatDateEn(oldValue);
          }
          else {
            oldValue = "";
          }
        }
  
        if(newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
        }
      }
    }
  }

  cellEdit(args) {
    const columnName = args.columnName.replace(".Label", "");

    // Get Column Type
    const columnType = this.getColumnType(columnName);

    // Edit Dependencies with Popup
    if(columnName === 'Previous_Constraint' || columnName === 'Next_Constraint' || columnName === 'Previous_Lag' || columnName === 'Next_Lag' || columnName === 'Previous_Dependencies' || columnName === 'Next_Dependencies') {
      // Cancel Editing in Table
      args.cancel = true;

      // Call the event from the Parent component through the props
      this.props.onDependenciesDisplay(args.rowData);
    }
    // Edit Files with Popup
    else if(columnType === 'Files') {
      // Cancel Editing in Table
      args.cancel = true;

      this.setState({ filesPopup: true, rowItemId: args.rowData.Item_ID.substring(1), rowItemType: args.rowData.Item_Type, rowItemTitle: args.rowData.Name, rowItemColumn: columnName, rowItemLabel: this.getColumnLabel(columnName) });
    }
    // Edit value with Popup and Text Editor
    else if(columnType === 'HTML') {
      // Cancel Editing in Tree
      args.cancel = true;

      // If Column is editable, open Popup to modify Text Editor value
      if(this.getColumnEditionItemType(columnName)) {
        this.setState({ textEditorPopup: true, rowItemId: args.rowData.Item_ID, rowItemType: args.rowData.Item_Type, rowItemColumn: columnName, rowItemLabel: this.getColumnLabel(columnName), rowItemValue: args.value, rowItemTitle: args.rowData.Name });
      }
    }
    // Edit value with Popover
    else if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity' || columnName === 'Levels') {
      // Cancel Editing in Tree
      args.cancel = true;
    }
    // Edit value with Boolean icon
    else if(columnType === 'Boolean') {
      // Cancel Editing in Table
      args.cancel = true;
    }
    // Edit value with Rating icon
    else if(columnType === 'Rating') {
      // Cancel Editing in Table
      args.cancel = true;
    }
  }

  // Refresh Table Component
  refreshComponent(blockType, currentView, columns, rows) {
    // Get Current View Parameters
    const dictParameters = this.getCurrentViewParameters(currentView);

    // Get Columns Parameters
    const dictColumns = this.getColumnParameters(columns);

    // Build Table Datasource
    const dataSource = this.dataStructure(rows);

    this.setState({ currentView, dictParameters, dictColumns, dataSource, refreshInProgress: true });

    this.setState({ refreshInProgress: false });

    // Refresh Table Datasource
    this.refreshDatasource(dataSource);

    // Refresh Table Columns
    this.refreshColumns(blockType, currentView, dictParameters, dictColumns);

    this.treegrid.refreshColumns();
  }

  refreshColumns(blockType, currentView, dictParameters, dictColumns) {
    let treegridColumns = [];

    // Auto Generated Columns
    if(this.treegrid) {
      // Clean Treegrid columns
      // this.treegrid.columns = [];

      // Clean Search Settings
      // this.treegrid.searchSettings = { search: [] , operators: {} };

      // Loop through the columns to build and add them to the Treegrid
      for(let i=0; i < dictParameters['Columns'].length; i++) {
        let columnName = dictParameters['Columns'][i];

        if(dictColumns[columnName]) {
          // Create Column object
          const colObj = this.createColumn(dictColumns[columnName], dictParameters['ColumnsWidth'], true);

          // Add the Column in the Treegrid
          treegridColumns.push(colObj);
          // this.treegrid.columns.push(colObj);
        }
      }

      // Add Column Item_ID
      if(!dictParameters['Columns'].includes('Item_ID')) {
        let columnHeader = dictColumns['Item_ID'];

        // Define Column object for Treegrid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: true
        };

        treegridColumns.push(colObj);
        // this.treegrid.columns.push(colObj);
      }
      // Add Column Item_Type
      if(!dictParameters['Columns'].includes('Item_Type')) {
        let columnHeader = dictColumns['Item_Type'];

        // Define Column object for Treegrid
        var colObj = {
          lockColumn: false,
          field: columnHeader.FieldName,
          headerTemplate: this.templateHeader,
          headerText: columnHeader.Label,
          visible: false,
          allowEditing: false,
          isPrimaryKey: false
        };

        treegridColumns.push(colObj);
        // this.treegrid.columns.push(colObj);
      }

      // Update Tree Columns
      this.treegrid.columns = treegridColumns;

      // Update Tree Column Index
      this.treegrid.treeColumnIndex = this.getTreeColumnIndex(dictParameters['Columns']);

      // Update Tree Search Settings
      this.treegrid.searchSettings = this.getTreeSearchSettings(dictColumns, dictParameters['Columns']);
    }
  }

  refreshDatasource(dataSource) {
    if(this.treegrid) {
      this.treegrid.dataSource = dataSource;
    }
  }

  createColumn(columnHeader, columnsWidth, visible) {
    const { formatDate, itemType, blockType } = this.state;
    let label, headerTemplate, type, field, format, primaryKey, textAlign, edit, editType, validationRules, template, width;

    // Label
    if(blockType !== 'Warnings' && columnHeader.FieldName === 'WarningMessage') {
      label = 'W';
    }
    else {
      label = columnHeader.Label;
    }

    // Header Template
    headerTemplate = this.templateHeader;

    // Type
    if(columnHeader.FieldType === 'String'  || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
      type = 'string';
    }
    else if(columnHeader.FieldType === 'Double' || columnHeader.FieldType === 'Percentage') {
      type = 'number';
    }
    else if(columnHeader.FieldType === 'Date') {
      type = 'date';
    }
    else {
      type = null;
    }

    // Field (Add Suffix .Label to Object Fields {"Id": id, "Label": label} for Grid Component)
    if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'AxisTable' || columnHeader.FieldType === 'Resource' || columnHeader.FieldType === 'ResourceTable' || columnHeader.FieldType === 'Location' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend' || columnHeader.FieldName === 'Business_Line' || columnHeader.FieldName === 'Project' || columnHeader.FieldName === 'Workpackage' || columnHeader.FieldName === 'Action' || columnHeader.FieldName === 'Task' || columnHeader.FieldName === 'Entity') {
      field = columnHeader.FieldName;
      // field = columnHeader.FieldName + ".Label";
    }
    else {
      field = columnHeader.FieldName;
    }

    // Format
    if(columnHeader.FieldType === 'Date') {
      if(formatDate === 'MM/DD/YYYY') {
        format = { type: 'date', format: 'MM/dd/yyyy' };
      }
      else if(formatDate === 'DD/MM/YYYY') {
        format = { type: 'date', format: 'dd/MM/yyyy' };
      }
      else if(formatDate === 'YYYY-MM-DD') {
        format = { type: 'date', format: 'yyyy-MM-dd' };
      }
    }
    else {
      format = null;
    }

    // Primary Key
    if(columnHeader.FieldName === 'Item_ID') {
      primaryKey = true;
    }
    else {
      primaryKey = false;
    }

    // Text Align
    if(columnHeader.FieldType === 'Date' || columnHeader.FieldName === 'Active' || columnHeader.FieldName === 'Cost_Following' || columnHeader.FieldName === 'Static_Data' || columnHeader.FieldName === 'Users_Config' || columnHeader.FieldName === 'Administrator' || columnHeader.FieldName === 'Reporting' || columnHeader.FieldName === 'Previous_Lag' || columnHeader.FieldName === 'Next_Lag') {
      textAlign = 'center';
    }
    else if(columnHeader.FieldType === 'Double' && (columnHeader.FieldName !== 'Meteo' && columnHeader.FieldName !== 'Trend')) {
      textAlign = 'right';
    }

    // Edit
    if(columnHeader.FieldType === 'Date') {
      edit = { params: { firstDayOfWeek: 1, weekNumber: true } };
    }
    else {
      edit = null;
    }

    // Edit Type
    if(columnHeader.FieldType === 'Date') {
      editType = 'datepickeredit';
    }
    // else if(columnHeader.FieldType === 'Axe' || columnHeader.FieldType === 'Entity' || columnHeader.FieldType === 'Resource' || columnHeader.FieldName === 'Meteo' || columnHeader.FieldName === 'Trend') {
    //   editType = 'dropdownedit';
    // }
    else {
      editType = null;
    }

    // Validation Rules
    if(columnHeader.FieldName === 'Name' || columnHeader.FieldName === 'Login') {
      validationRules = this.nameRules;
    }
    else if(columnHeader.FieldType === 'String') {
      validationRules = this.textRules;
    }
    else if(columnHeader.FieldName === 'Burned_Workload' || columnHeader.FieldName === 'Workload' || columnHeader.FieldName === 'Month_Burned') {
      validationRules = this.numberRules;
    }
    else if(columnHeader.FieldName === 'Progress') {
      validationRules = this.progressRules;
    }
    else if(columnHeader.FieldName === 'Year') {
      validationRules = this.yearRules;
    }
    else {
      validationRules = null;
    }

    // Template functions
    // By FieldName
    if(columnHeader.FieldName === 'Action') {
      template = this.templateColumnAction;
    }
    else if(columnHeader.FieldName === 'Active') {
      template = this.templateColumnActive;
    }
    else if(columnHeader.FieldName === 'Admin') {
      template = this.templateColumnAdmin;
    }
    else if(columnHeader.FieldName === 'BudgetCode') {
      template = this.templateColumnBudgetCode;
    }
    else if(columnHeader.FieldName === 'Business_Line') {
      template = this.templateColumnBusinessLine;
    }
    else if(columnHeader.FieldName === 'Data_Freshness') {
      template = this.templateColumnDataFreshness;
    }
    else if(columnHeader.FieldName === 'Previous_Dependencies' || columnHeader.FieldName === 'Next_Dependencies') {
      template = this.templateColumnDependency;
    }
    else if(columnHeader.FieldName === 'Email') {
      template = this.templateColumnEmail;
    }
    else if(columnHeader.FieldName === 'Entity') {
      template = this.templateColumnEntity;
    }
    else if(columnHeader.FieldName === 'External') {
      template = this.templateColumnExternal;
    }
    else if(columnHeader.FieldName === 'Highlighted') {
      template = this.templateColumnHighlighted;
    }
    else if(columnHeader.FieldName === 'Impact') {
      template = this.templateColumnImpact;
    }
    else if(columnHeader.FieldName === 'Item_Type') {
      template = this.templateColumnItemType;
    }
    else if(columnHeader.FieldName === 'Next_Lag' || columnHeader.FieldName === 'Previous_Lag') {
      template = this.templateColumnLag;
    }
    else if(columnHeader.FieldName === 'Licence_Type') {
      template = this.templateColumnLicenceType;
    }
    else if(columnHeader.FieldName === 'Meteo') {
      template = this.templateColumnMeteo;
    }
    else if(columnHeader.FieldName === 'Meteo_Freshness') {
      template = this.templateColumnMeteoFreshness;
    }
    else if(columnHeader.FieldName === 'Month_Burned') {
      template = this.templateColumnMonthBurned;
    }
    else if(columnHeader.FieldName === 'Name') {
      // Entity Table Resources
      if(itemType === 'Entity' && blockType === 'Resources') {
        template = this.templateColumnResourceName;
      }
      else {
        template = this.templateColumnName;
      }
    }
    else if(columnHeader.FieldName === 'Priority') {
      template = this.templateColumnPriority;
    }
    else if(columnHeader.FieldName === 'Probability') {
      template = this.templateColumnProbability;
    }
    else if(columnHeader.FieldName === 'Progress') {
      template = this.templateColumnProgress;
    }
    else if(columnHeader.FieldName === 'Project') {
      template = this.templateColumnProject;
    }
    else if(columnHeader.FieldName === 'RowType') {
      template = this.templateColumnRowType;
    }
    else if(columnHeader.FieldName === 'Sprint') {
      template = this.templateColumnSprint;
    }
    else if(columnHeader.FieldName === 'Status') {
      template = this.templateColumnStatus;
    }
    else if(columnHeader.FieldName === 'Task') {
      template = this.templateColumnTask;
    }
    else if(columnHeader.FieldName === 'Timeline') {
      template = this.templateColumnTimeline;
    }
    else if(columnHeader.FieldName === 'Trend') {
      template = this.templateColumnTrend;
    }
    else if(columnHeader.FieldName === 'Validated') {
      template = this.templateColumnValidated;
    }
    else if(columnHeader.FieldName === 'WarningMessage') {
      template = this.templateColumnWarning;
    }
    else if(columnHeader.FieldName === 'Workpackage') {
      template = this.templateColumnWorkpackage;
    }
    else if(columnHeader.FieldName === 'Year') {
      template = this.templateColumnYear;
    }
    // By Type
    else if(columnHeader.FieldType === 'Axe') {
      template = this.templateTypeAxe;
    }
    else if(columnHeader.FieldType === 'AxisTable') {
      template = this.templateTypeAxisTable;
    }
    else if(columnHeader.FieldType === 'Boolean') {
      template = this.templateTypeBoolean;
    }
    else if(columnHeader.FieldType === 'Date') {
      template = this.templateTypeDate;
    }
    else if(columnHeader.FieldType === 'Double') {
      template = this.templateTypeDouble;
    }
    else if(columnHeader.FieldType === 'Files') {
      template = this.templateTypeFiles;
    }
    else if(columnHeader.FieldType === 'HTML') {
      template = this.templateTypeHTML;
    }
    else if(columnHeader.FieldType === 'Link') {
      template = this.templateTypeLink;
    }
    else if(columnHeader.FieldType === 'Location') {
      template = this.templateTypeLocation;
    }
    else if(columnHeader.FieldType === 'Percentage') {
      template = this.templateTypePercentage;
    }
    else if(columnHeader.FieldType === 'Progress') {
      template = this.templateTypeProgress;
    }
    else if(columnHeader.FieldType === 'QRCode') {
      template = this.templateTypeQRCode;
    }
    else if(columnHeader.FieldType === 'Rating') {
      template = this.templateTypeRating;
    }
    else if(columnHeader.FieldType === 'Resource') {
      template = this.templateTypeResource;
    }
    else if(columnHeader.FieldType === 'ResourceTable') {
      template = this.templateTypeResourceTable;
    }
    else {
      template = this.templateTypeText;
    }

    // Width
    if(columnsWidth.find(column => column.Name === columnHeader.FieldName)) {
      width = columnsWidth.find(column => column.Name === columnHeader.FieldName).Width;
    }
    else {
      if(columnHeader.FieldName === 'Name') {
        width = 400;
      }
      else if(columnHeader.FieldName === 'Status') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Priority') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Probability') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Impact') {
        width = 110;
      }
      else if(columnHeader.FieldName === 'Meteo') {
        width = 100;
      }
      else if(columnHeader.FieldName === 'Trend') {
        width = 115;
      }
      else if(columnHeader.FieldName === 'Progress') {
        width = 175;
      }
      else if(columnHeader.FieldName === 'Workload') {
        width = 135;
      }
      else if(columnHeader.FieldName === 'Burned_Workload') {
        width = 145;
      }
      else if(columnHeader.FieldName === 'StartDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'EndDate') {
        width = 155;
      }
      else if(columnHeader.FieldName === 'Timeline') {
        width = 200;
      }
      else if(columnHeader.FieldName === 'Resource') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Task_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'WarningMessage') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Active') {
        width = 90;
      }
      else if(columnHeader.FieldName === 'Description') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Comment') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Corrective_Action') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Decision_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Meeting_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Resource_Type') {
        width = 170;
      }
      else if(columnHeader.FieldName === 'Owner') {
        width = 160;
      }
      else if(columnHeader.FieldName === 'Project') {
        width = 240;
      }
      else if(columnHeader.FieldName === 'Message') {
        width = 350;
      }
      else if(columnHeader.FieldName === 'Time') {
        width = 150;
      }
      else if(columnHeader.FieldName === 'Highlighted') {
        width = 125;
      }
      else if(columnHeader.FieldName === 'Data_Freshness') {
        width = 120;
      }
      else if(columnHeader.FieldName === 'Meteo_Freshness') {
        width = 110;
      }
    }

    // Define Column object for Grid
    var colObj = {
      allowEditing: this.getColumnEditable(columnHeader.EditionItemTypes),
      editType: editType,
      edit: edit,
      field: field,
      format: format,
      headerTemplate: headerTemplate,
      headerText: label,
      headerTextAlign: 'left',
      isPrimaryKey: primaryKey,
      lockColumn: false,
      template: template,
      textAlign: textAlign,
      type: type,
      validationRules: validationRules,
      visible: true,
      width: width
    };

    return colObj;
  }

  filterColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsFilter(columnName);
  }

  sortColumn(columnName) {
    const currentView = this.state.currentView;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Sort')) {
      if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " DESC")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Descending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName)) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else if(currentView.Parameters.find(param => param.Name === 'Sort').Value.split(',').find(sort => sort === columnName + " Ascending")) {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName + " DESC";
      }
      else {
        currentView.Parameters.find(param => param.Name === 'Sort').Value = columnName;
      }
    }
    else {
      currentView.Parameters.push({ Name: 'Sort', Value: columnName, ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Call the event from the Parent component through the props
    this.props.onColumnSort(currentView);
  }

  sortColumns(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnsSort(columnName);
  }

  autofitColumn(columnName) {
    const currentView = this.state.currentView;
    let columns = [], columnsWidth = [];

    columns.push(columnName);

    this.treegrid.autoFitColumns(columns);

    // Loop through the Treegrid columns to build CurrentView Columns Width
    for(let i=0; i < this.treegrid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.treegrid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.treegrid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  autofitAllColumns() {
    const currentView = this.state.currentView;
    let columnsWidth = [];

    this.treegrid.autoFitColumns();

    // Loop through the Treegrid columns to build CurrentView Columns Width
    for(let i=0; i < this.treegrid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.treegrid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.treegrid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  freezeColumns(columnName) {
    this.treegrid.frozenColumns = 1;
  }

  multipleModification(columnName) {
    // Call the event from the Parent component through the props
    this.props.onColumnMultipleModification(columnName);
  }

  resizeStop(args) {
    const currentView = this.state.currentView;
    let columnsWidth = [];

    // Loop through the Treegrid columns to build CurrentView Columns Width
    for(let i=0; i < this.treegrid.columns.length; i++) {
      // Remove Suffix .Label to Object Fields {"Id": id, "Label": label} for Database
      const columnName = this.treegrid.columns[i].field.replace(".Label", "");

      columnsWidth.push({ Name: columnName, Width: this.treegrid.columns[i].width });
    }

    // Update Current View Columns Width
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'ColumnsWidth')) {
      currentView.Parameters.find(param => param.Name === 'ColumnsWidth').Value = JSON.stringify(columnsWidth);
    }
    // Create Current View Columns Width
    else {
      currentView.Parameters.push({ Name: 'ColumnsWidth', Value: JSON.stringify(columnsWidth), ViewFilterId: -1, ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  collapsing(args) {
    const { itemId, itemType, currentView } = this.state;
    const rowItemId = args.data.Item_ID;
    const parameterName = 'CollapsedItems_' + itemType.substring(0,1) + itemId;
    let collapsedItems = [];

    // Cancel Event
    args.cancel = true;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // this.treegrid.refreshColumns();

    dataSource.forEach(row => {
      // Update Row isExpanded to false
      if(row['Item_ID'] === rowItemId || (Array.isArray(args.data) && args.data.length > 1)) {
        row['isExpanded'] = false;
      }

      // Push Row Item_ID inside collapsedItems
      if(row['isExpanded'] === false) {
        collapsedItems.push(row['Item_ID']);
      }
    });

    // Update Datasource
    this.treegrid.dataSource = dataSource;

    // Update Current View CollapsedItems Objects
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === parameterName)) {
      currentView.Parameters.find(param => param.Name === parameterName).Value = collapsedItems.join(',');
    }
    else {
      currentView.Parameters.push({ Name: parameterName, Value: collapsedItems.join(','), ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  expanding(args) {
    const { itemId, itemType, currentView } = this.state;
    const rowItemId = args.data.Item_ID;
    const parameterName = 'CollapsedItems_' + itemType.substring(0,1) + itemId;
    let collapsedItems = [];

    // Cancel Event
    args.cancel = true;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // this.treegrid.refreshColumns();

    dataSource.forEach(row => {
      // Update Row isExpanded to true
      if(row['Item_ID'] === rowItemId || (Array.isArray(args.data) && args.data.length > 1)) {
        row['isExpanded'] = true;
      }

      // Push Row Item_ID inside collapsedItems
      if(row['isExpanded'] === false) {
        collapsedItems.push(row['Item_ID']);
      }
    });

    // Update Datasource
    this.treegrid.dataSource = dataSource;

    // Update Current View CollapsedItems Objects
    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === parameterName)) {
      currentView.Parameters.find(param => param.Name === parameterName).Value = collapsedItems.join(',');
    }
    else {
      currentView.Parameters.push({ Name: parameterName, Value: collapsedItems.join(','), ViewId: currentView.ViewId });
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Call the event from the Parent component through the props with view value
    this.props.onViewChange(currentView);
  }

  editAxe(itemId, itemType, columnName, oldValue, newValue) {
    // Call the event from the Parent component through the props
    this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);

    // if(newValue != oldValue) {
    //   // Call the event from the Parent component through the props
    //   this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
    // }
  }

  editObjectTable(itemId, itemType, columnName, oldValue, newValue) {
    // Call the event from the Parent component through the props
    this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
  }

  onBooleanColumnClick(args) {
    // Click on Table element e-rowcell
    if(args.target.closest('.e-rowcell')) {
      const classList = args.target.classList;
      const element = args.target.id;

      // Get element informations to build request
      const split = element.split(' ');
      const itemId = split[0].substring(1);
      const itemType = split[1];
      const columnName = split[2];
      const allowEditing = split[3];

      let oldValue, newValue;

      // Check if Editing is allowed
      if(allowEditing === "true") {
        // Check if Click on Boolean element
        if(classList.contains('boolean')) {
          // Get Boolean new value
          if(classList.contains('iconBooleanTrue')) {
            oldValue = "True";
            newValue = "False";
          }
          else if(classList.contains('iconBooleanFalse')) {
            oldValue = "False";
            newValue = "True";
          }
        }
        else if(classList.contains('rating')) {
          // Get Boolean old and new value
          oldValue = split[4];
          newValue = split[5];
        }

        if(newValue != oldValue) {
          // Call the event from the Parent component through the props
          this.props.onTableUpdate(itemId, itemType, columnName, oldValue, newValue);
        }
      }

      this.treeClicked = false;
    }
  }

  updateDatasource(modification, propagations, warnings) {
    let dictModifications = {};

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // Get Column Type
    const columnType = this.getColumnType(modification.ColumnName);

    // Apply Modification on Row
    if((columnType !== 'Date' || modification.ColumnName === 'StartDate' || modification.ColumnName === 'EndDate') && (columnType !== 'Double' || modification.ColumnName === 'DayCost' || modification.ColumnName === 'Meteo' || modification.ColumnName === 'Trend') && (columnType !== 'String' || modification.ColumnName === 'BackColor' || modification.ColumnName === 'ForeColor')) {
      this.updateDictModifications(dataSource, dictModifications, modification.ItemType, modification.ItemId, modification.ColumnName, modification.NewValue);
    }

    // Apply Propagations in Tree DataSource
    propagations.forEach(propagation => {
      this.updateDictModifications(dataSource, dictModifications, propagation.ToItemType, propagation.ToItemId, propagation.ColumnName, propagation.NewValue);
    });

    // Update Table Row
    // Object.keys(dictModifications).forEach(key => {
    //   this.treegrid.updateRow(key, dictModifications[key]);
    // });

    // Update Datasource
    if(Object.keys(dictModifications).length > 0) {
      this.treegrid.dataSource = dataSource;
    }

    // // Apply Modification on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, modification.ColumnName, modification.NewValue);

    // // Apply Propagations in Tree DataSource
    // propagations.forEach(propagation => {
    //   this.updateGridCell(propagation.ToItemType, propagation.ToItemId, propagation.ColumnName, propagation.NewValue);
    // });

    // if(warnings.length > 0) {
    //   warningMessage = warnings.join(' | ');
    // }

    // // Apply Warnings on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, 'WarningMessage', warningMessage);
  }

  updateDictModifications(dataSource, dictModifications, itemType, itemId, columnName, newValue) {
    const dictColumns = this.state.dictColumns;
    const modificationId = this.getRowItemId(itemType, itemId);
    const index = this.getRowIndex(modificationId);
    let currentRow;

    if(index >= 0 && Object.keys(dictColumns).find(column => column === columnName)) {
      // Format Modification Value
      const value = this.getModificationValue(columnName, newValue);

      if(!Object.keys(dictModifications).find(modification => modification === index)) {
        // Get Current Row
        currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
        // currentRow = this.treegrid.dataSource.find(row => row['Item_ID'] === modificationId);

        dictModifications[index] = currentRow;
      }

      // Update Modifications Dictionary 
      dictModifications[index][columnName] = value;
    }
  }

  updateGridCell(itemType, itemId, columnName, newValue) {
    const modificationId = this.getRowItemId(itemType, itemId);
    let currentRow, index, value;

    if(this.treegrid && this.treegrid.columns.find(column => column.field.replace(".Label", "") === columnName)) {
      // Get Column Type
      const columnType = this.getColumnType(columnName);
      
      // Parse Object for Axes
      if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Resource' || columnType === 'ResourceTable' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend') {
        value = JSON.parse(newValue);
      }
      // Parse Double
      else if(columnType === 'Double') {
        value = parseFloat(newValue);
      }
      // Parse Date
      else if(columnType === 'Date') {
        if(newValue) {
          value = new Date(newValue);
        }
        else {
          value = "";
        }
      }
      else {
        value = newValue;
      }

      // Specific case for Text Editor
      // if(columnType === 'HTML') {
      //   this.setState({ rowItemValue: newValue });
      // }

      // Find current Row to apply Modification
      if(this.treegrid.dataSource.find(row => row['Item_ID'] === modificationId)) {
        // Get Row index
        index = this.treegrid.dataSource.findIndex(row => row['Item_ID'] === modificationId);

        // Get Current Row
        currentRow = this.treegrid.dataSource.find(row => row['Item_ID'] === modificationId);

        // Update Cell in Current Row
        currentRow[columnName] = value;

        // Update Tree Row
        this.treegrid.updateRow(index, currentRow);
      }

      // this.treegrid.setCellValue(modificationId, columnName, value);
    }
  }

  // updateDatasource(modification, propagations, warnings) {
  //   const columnName = modification.ColumnName;
  //   const newValue = modification.NewValue;
  //   let modificationId, currentRow, index;
  //   let applyModification = false;

  //   // Get Column Type
  //   const columnType = this.getColumnType(columnName);

  //   if(modification.ItemType === 'AgileBoard') {
  //     modificationId = 'O'.concat(modification.ItemId);
  //   }
  //   else {
  //     modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
  //   }

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

  //   // Find current Row to apply Modification
  //   if(dataSource.find(row => row['Item_ID'] === modificationId)) {
  //     currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
  //   }

  //   // Find Index of current Row in visible Records
  //   // if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
  //   //   index = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
  //   // }

  //   // Build Updated Rows
  //   // let updatedRows = [];

  //   // Need to Update Datasource for these Columns (Status & StartDate & EndDdate => Timeline, Impact & Probability => Risk Index)
  //   if(currentRow && (columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Boolean' || columnType === 'Date' || columnType === 'HTML' || columnType === 'Location' || columnType === 'Rating' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'StartDate' || columnName === 'EndDate' || columnName === 'Parent_ID')) {
  //     // Parse Object for Axes
  //     if(columnType === 'Axe' || columnType === 'AxisTable' || columnType === 'Location' || columnType === 'Resource' || columnType === 'ResourceTable' || columnName === 'Meteo' || columnName === 'Trend') {
  //       // Update Current Row Column
  //       currentRow[columnName] = JSON.parse(newValue);

  //       // if(this.treegrid) {
  //       //   this.treegrid.updateRow(index, currentRow);
  //       // }
  //     }
  //     // Parse Date
  //     else if(columnType === 'Date') {
  //       // Update Current Row Column
  //       if(newValue) {
  //         currentRow[columnName] = new Date(newValue);
  //       }
  //       else {
  //         currentRow[columnName] = "";
  //       }
  //     }
  //     else {
  //       // Update Current Row Column
  //       currentRow[columnName] = newValue;

  //       // if(this.treegrid) {
  //       //   this.treegrid.updateRow(index, currentRow);
  //       // }
  //     }

  //     // Add updated Row
  //     // updatedRows.push({ "Index": index, "Row": currentRow });

  //     // Specific case for Text Editor
  //     if(columnType === 'HTML') {
  //       this.setState({ rowItemValue: newValue });
  //     }

  //     applyModification = true;
  //   }

  //   // Apply Propagations in Tree DataSource
  //   propagations.forEach(propagation => {
  //     const propagationId = propagation.ToItemType.substring(0, 1) + propagation.ToItemId;
  //     const propagationName = propagation.ColumnName;
  //     const propagationValue = propagation.NewValue;
  //     let propagationRow, propagationIndex;

  //     // Get Propagation Type
  //     const propagationType = this.getColumnType(propagationName);

  //     // Check if we find Column to apply Propagation
  //     if(this.treegrid.columns.find(column => column.field.replace(".Label", "") === propagationName)) {
  //       // Find propagation Row to apply Modification
  //       if(dataSource.find(row => row['Item_ID'] === propagationId)) {
  //         propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
  //       }

  //       // Find Index of propagation Row in visible Records
  //       // if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === propagationId)) {
  //       //   propagationIndex = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === propagationId);
  //       // }

  //       if(propagationRow) {
  //         // Parse Object for Axes
  //         if(propagationType === 'Axe' || propagationType === 'AxisTable'|| propagationType === 'Resource' || propagationType === 'ResourceTable' || propagationName === 'Meteo' || propagationName === 'Trend') {
  //           // Update Propagation Row Column
  //           propagationRow[propagationName] = JSON.parse(propagationValue);
  //         }
  //         // Parse Date
  //         else if(propagationType === 'Date') {
  //           // Update Propagation Row Column
  //           if(propagationValue) {
  //             propagationRow[propagationName] = new Date(propagationValue);
  //           }
  //           else {
  //             propagationRow[propagationName] = "";
  //           }
  //         }
  //         else {
  //           // Update Propagation Row Column
  //           propagationRow[propagationName] = propagationValue;
  //         }

  //         // Add updated Row
  //         // if(!updatedRows.find(row => row.Index === propagationIndex)) {
  //         //   updatedRows.push({ "Index": propagationIndex, "Row": propagationRow });
  //         // }

  //         applyModification = true;
  //       }
  //     }
  //   });

  //   // Apply Warnings in Tree DataSource
  //   if(currentRow) {
  //     let warningMessage = '';

  //     if(warnings.length > 0) {
  //       warningMessage = warnings.join(' | ');
  //     }

  //     if(currentRow['WarningMessage'] && currentRow['WarningMessage'] !== warningMessage) {
  //       // Update Current Row Warning
  //       currentRow['WarningMessage'] = warningMessage;

  //       // Add updated Row
  //       // if(!updatedRows.find(row => row.Index === index)) {
  //       //   updatedRows.push({ "Index": index, "Row": currentRow });
  //       // }

  //       applyModification = true;
  //     }
  //   }

  //   // Apply Update Row on Updated Rows
  //   // updatedRows.forEach(updatedRows => {
  //   //   if(this.treegrid) {
  //   //     this.treegrid.updateRow(updatedRows.Index, updatedRows.Row);
  //   //   }
  //   // });

  //   // Update Datasource
  //   if(applyModification) {
  //     this.treegrid.dataSource = dataSource;
  //   }
  // }

  cancelModification(modification) {
    let dictModifications = {};

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // Apply Modification on Row
    this.updateDictModifications(dataSource, dictModifications, modification.ItemType, modification.ItemId, modification.ColumnName, modification.OldValue);

    // Update Table Row
    // Object.keys(dictModifications).forEach(key => {
    //   this.treegrid.updateRow(key, dictModifications[key]);
    // });

    // Update Datasource
    if(Object.keys(dictModifications).length > 0) {
      this.treegrid.dataSource = dataSource;
    }

    // Apply Modification on Row
    // this.updateGridCell(modification.ItemType, modification.ItemId, modification.ColumnName, modification.OldValue);
  }

  // cancelModification(modification) {
  //   const columnName = modification.ColumnName;
  //   const oldValue = modification.OldValue;
  //   let modificationId, currentRow, index;
  //   let applyModification = false;

  //   // Get Column Type
  //   const columnType = this.getColumnType(columnName);

  //   if(modification.ItemType === 'AgileBoard') {
  //     modificationId = 'O'.concat(modification.ItemId);
  //   }
  //   else {
  //     modificationId = modification.ItemType.substring(0,1).concat(modification.ItemId);
  //   }

  //   // Clone Datasource
  //   let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

  //   // Find current Row to apply Modification
  //   if(dataSource.find(row => row['Item_ID'] === modificationId)) {
  //     currentRow = dataSource.find(row => row['Item_ID'] === modificationId);
  //   }

  //   // Find Index of current Row in visible Records
  //   // if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === modificationId)) {
  //   //   index = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === modificationId);
  //   // }    

  //   if(currentRow && columnType !== 'Axe' && columnType !== 'AxisTable' && columnType !== 'HTML' && columnType !== 'Resource' && columnType !== 'ResourceTable' && columnName !== 'Meteo' && columnName !== 'Trend') {
  //     // Parse Date
  //     if(columnType === 'Date') {
  //       // Update Current Row Column with old Value
  //       if(oldValue) {
  //         currentRow[columnName] = new Date(oldValue);
  //       }
  //       else {
  //         currentRow[columnName] = "";
  //       }
  //     }
  //     else {
  //       // Update Current Row Column with old Value
  //       currentRow[columnName] = oldValue;
  //     }

  //     // if(this.treegrid) {
  //     //   this.treegrid.updateRow(index, currentRow);
  //     // }

  //     applyModification = true;
  //   }

  //   // Update Datasource
  //   if(applyModification) {
  //     this.treegrid.dataSource = dataSource;
  //   }
  // }

  addRowsInDatasource(rows, propagations) {
    let parentId, parentIndex;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // Update DataSource
    let updateDataSource = false;

    // Build Propagations Rows
    let propagationRows = [];

    if(this.treegrid) {
      if(rows.length === 1) {
        // Get Parent Id
        if(rows[0].Cells.find(cell => cell.ColumnName === 'Parent_ID')) {
          parentId = rows[0].Cells.find(cell => cell.ColumnName === 'Parent_ID').Value;
        }

        // Get Parent Index
        if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === parentId)) {
          parentIndex = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === parentId);
        }

        // Push new Row in DataSource
        dataSource.push(this.buildDatasourceRow(rows[0]));

        updateDataSource = true;

        // Add Row in Treegrid
        // if(parentIndex || parentIndex >= 0) {
        //   this.treegrid.addRecord(this.buildDatasourceRow(rows[0]), parentIndex, 'Child');
        // }
        // else {
        //   updateDataSource = true;

        //   // Push new Row in DataSource
        //   dataSource.push(this.buildDatasourceRow(rows[0]));

        //   // this.treegrid.addRecord(this.buildDatasourceRow(rows[0]));
        // }

        // Apply Propagations
        propagations.forEach(propagation => {
          const propagationId = propagation.ToItemType.substring(0, 1) + propagation.ToItemId;
          const propagationName = propagation.ColumnName;
          const propagationValue = propagation.NewValue;
          let propagationRow, propagationIndex;

          // Get Propagation Type
          const propagationType = this.getColumnType(propagationName);

          // Check if we find Column to apply Propagation
          if(this.treegrid.columns.find(column => column.field === propagationName)) {
            // Find propagation Row to apply Modification
            if(dataSource.find(row => row['Item_ID'] === propagationId)) {
              propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
            }

            // Find Index of propagation Row in visible Records
            if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === propagationId)) {
              propagationIndex = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === propagationId);
            }

            if(propagationRow) {
              // Parse Object for Axes
              if(propagationType === 'Axe' || propagationName === 'Meteo' || propagationName === 'Trend') {
                // Update Propagation Row Column
                propagationRow[propagationName] = JSON.parse(propagationValue);
              }
              else {
                // Update Propagation Row Column
                propagationRow[propagationName] = propagationValue;
              }

              // Add updated Row
              if(!propagationRows.find(row => row.Index === propagationIndex)) {
                propagationRows.push({ "Index": propagationIndex, "Row": propagationRow });
              }

              updateDataSource = true;
            }
          }
        });

        // Update Treegrid DataSource
        if(updateDataSource || propagationRows.length > 0) {
          this.treegrid.dataSource = dataSource;
        }

        // Apply Propagation Rows on Treegrid
        // propagationRows.forEach(propagationRow => {
        //   if(this.treegrid) {
        //     this.treegrid.updateRow(propagationRow.Index, propagationRow.Row);
        //   }
        // });
      }
      else {
        // Add Rows in the Datasource
        rows.forEach(row => {
          dataSource.push(this.buildDatasourceRow(row));
        });

        // Apply Propagations in Tree DataSource
        propagations.forEach(propagation => {
          const propagationId = propagation.ToItemType.substring(0, 1) + propagation.ToItemId;
          const propagationName = propagation.ColumnName;
          const propagationValue = propagation.NewValue;
          let propagationRow;

          if(this.treegrid.columns.find(column => column.field === propagationName)) {
            if(dataSource.find(row => row['Item_ID'] === propagationId)) {
              propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
            }

            if(propagationRow) {
              // Update Propagation Row Column
              propagationRow[propagationName] = propagationValue;
            }
          }
        });

        // Update Datasource
        this.treegrid.dataSource = dataSource;
      }
    }
  }

  buildDatasourceRow(row) {
    let rowDatasource = {};

    rowDatasource = row.Cells.reduce((acc, item) => {
      // Format Dates
      if(item.FieldType === 'Date' && item.Value) {
        acc[item.ColumnName] = new Date(item.Value);
      }
      // Format Numbers
      else if(item.FieldType === 'Double' && item.Value) {
        acc[item.ColumnName] = parseFloat(item.Value);
      }
      // Format Meteo/Trend
      else if((item.ColumnName === 'Meteo' || item.ColumnName === 'Trend') && item.Value) {
        acc[item.ColumnName] = JSON.parse(item.Value);
      }
      // Format Objects
      else if(item.FieldType === 'Object' && item.Value) {
        acc[item.ColumnName] = JSON.parse(item.Value);
      }
      // Format Object Tables (Decisions/Files/HashTags/Rights/Resources)
      else if(item.FieldType === 'ObjectTable' || item.FieldType === 'Files' || item.FieldType === 'ResourceTable') {
        if(item.Value) {
          acc[item.ColumnName] = JSON.parse(item.Value);
        }
        else {
          acc[item.ColumnName] = [];
        }
      }
      // Format Collapse Boolean
      else if(item.ColumnName === 'isCollapsed') {
        if(item.Value === 'False') {
          acc['isExpanded'] = true;
        }
        else if(item.Value === 'True') {
          acc['isExpanded'] = false;
        }
      }
      // Add the Column Name/Values to the reduced Table
      else {
        acc[item.ColumnName] = item.Value;
      }

      return acc;
    }, {});

    return rowDatasource;
  }

  confirmDelete() {
    const { rowItemId, rowItemType } = this.state;

    // Call the event from the Parent component through the props
    this.props.onItemDelete(rowItemId, rowItemType);

    this.setState({ confirmDelete: false });
  }

  cancelDelete() {
    this.setState({ confirmDelete: false });
  }

  confirmCutPaste() {
    const { cutItemId, cutItemType, rowItemId, rowItemType } = this.state;

    // Call the event from the Parent component through the props
    this.props.onItemCutPaste(cutItemType, cutItemId, rowItemType, rowItemId, true);

    this.setState({ confirmCutPaste: false, cutItemType: null, cutItemId: null });
  }

  cancelCutPaste() {
    this.setState({ confirmCutPaste: false, cutItemType: null, cutItemId: null });
  }

  deleteRowInDatasource(modification, propagations) {
    const deleteId = modification.ItemType.substring(0,1).concat(modification.ItemId);
    let idToDelete = [];

    // Update DataSource
    let updateDataSource = false;

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    // Get list of Index to delete
    idToDelete = this.getChildToDelete(dataSource, deleteId);

    idToDelete.forEach(id => {
      // Get corresponding Row Index
      let rowIndex = dataSource.findIndex(row => row.Item_ID === id);

      // Delete Row in the Datasource
      if(rowIndex >= 0) {
        dataSource.splice(rowIndex, 1);

        updateDataSource = true;
      }
    });

    // Apply Propagations in Table DataSource
    propagations.forEach(propagation => {
      const propagationId = propagation.ToItemType.substring(0, 1) + propagation.ToItemId;
      const propagationName = propagation.ColumnName;
      const propagationValue = propagation.NewValue;
      let propagationRow, propagationIndex;

      // Get Propagation Type
      const propagationType = this.getColumnType(propagationName);

      if(this.treegrid.columns.find(column => column.field === propagationName)) {
        // Find propagation Row to apply Modification
        if(dataSource.find(row => row['Item_ID'] === propagationId)) {
          propagationRow = dataSource.find(row => row['Item_ID'] === propagationId);
        }

        // Find Index of propagation Row in visible Records
        if(this.treegrid.getVisibleRecords().find(row => row['Item_ID'] === propagationId)) {
          propagationIndex = this.treegrid.getVisibleRecords().findIndex(row => row['Item_ID'] === propagationId);
        }

        if(propagationRow) {
          // Parse Object for Axes
          if(propagationType === 'Axe' || propagationName === 'Meteo' || propagationName === 'Trend') {
            // Update Propagation Row Column
            propagationRow[propagationName] = JSON.parse(propagationValue);
          }
          else {
            // Update Propagation Row Column
            propagationRow[propagationName] = propagationValue;
          }

          updateDataSource = true;
        }
      }
    });

    // Update Datasource
    if(updateDataSource) {
      this.treegrid.dataSource = dataSource;
    }
  }

  getChildToDelete(dataSource, deleteId) {
    let idToDelete = [];
    let itemId;

    // Push Index of element to delete
    idToDelete.push(deleteId);

    // Find child of the element
    dataSource.filter(row => row.Parent_ID === deleteId).forEach(row => {
      if(row.Item_ID) {
        // Get Datasource Row ItemId
        itemId = row.Item_ID;

        // Call recursive function to get child ItemId
        idToDelete.push(this.getChildToDelete(dataSource, itemId));
      }
    });

    return idToDelete.flat();
  }

  // Format Cells value for Export
  excelQueryCellInfo(args) {
    const columnName = args.column.field.replace(".Label", "");
    const columnType = this.getColumnType(columnName);
    const value = args.data[columnName];

    // Dates
    if(value && columnType === 'Date') {
      args.value = new Date(value).toLocaleDateString();
    }
    // Percentage
    else if(columnType === 'Percentage') {
      if(args.value) {
        args.value = value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // QRCode
    else if(value && columnType === 'QRCode') {
      args.value = "";
      args.image = { base64: args.data[columnName], height: 50, width: 50 };
    }
    // Objects
    else if(value && (columnType === 'Axe' || columnType === 'Resource' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity')) {
      args.value = value.Label;
    }
    // Table Objects
    else if(value && (columnType === 'AxisTable' || columnType === 'ResourceTable' || columnType === 'Files' || columnName === 'Rights')) {
      let string = "";

      value.forEach((item, index) => {
        if(index < value.length - 1) {
          string = string.concat(item.Label, ", ");
        }
        else {
          string = string.concat(item.Label);
        }
      });

      args.value = string;
    }
  }

  pdfQueryCellInfo(args) {
    const columnName = args.column.field.replace(".Label", "");
    const columnType = this.getColumnType(columnName);
    const value = args.data[columnName];

    // Dates
    if(value && columnType === 'Date') {
      args.value = new Date(value).toLocaleDateString();
    }
    // Percentage
    else if(columnType === 'Percentage') {
      if(args.value) {
        args.value = value.toFixed(0) + "%";
      }
      else {
        args.value = "0%";
      }
    }
    // QRCode
    else if(value && columnType === 'QRCode') {
      args.value = "";
      args.image = { base64: args.data[columnName], height: 50, width: 50 };
    }
    // Objects
    else if(value && (columnType === 'Axe' || columnType === 'Resource' || columnType === 'Location' || columnName === 'Meteo' || columnName === 'Trend' || columnName === 'Timeline' || columnName === 'Business_Line' || columnName === 'Project' || columnName === 'Workpackage' || columnName === 'Action' || columnName === 'Task' || columnName === 'Entity')) {
      args.value = value.Label;
    }
    // Table Objects
    else if(value && (columnType === 'AxisTable' || columnType === 'ResourceTable' || columnType === 'Files' || columnName === 'Rights')) {
      let string = "";

      value.forEach((item, index) => {
        if(index < value.length - 1) {
          string = string.concat(item.Label, ", ");
        }
        else {
          string = string.concat(item.Label);
        }
      });

      args.value = string;
    }
  }

  exportCSV() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".csv";

    if(this.treegrid) {
      // Define Export File Name
      let csvExportProperties = { fileName: fileName };

      this.treegrid.csvExport(csvExportProperties);
    }
  }

  exportXLSX() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".xlsx";

    if(this.treegrid) {
      // Define Export File Name
      let excelExportProperties = { fileName: fileName };

      this.treegrid.excelExport(excelExportProperties);
    }
  }

  exportPDF() {
    const { itemTitle, blockType } = this.state;
    const fileName = "Export " + blockType + " " + itemTitle + ".pdf";

    if(this.treegrid) {
      // Define Export File Name
      let pdfExportProperties = { fileName: fileName };

      this.treegrid.pdfExport(pdfExportProperties);
    }
  }

  searchItem(search) {
    if(this.treegrid) {
      this.treegrid.search(search);
    }
  }

  popupTextEditor() {
    let { language, itemId, itemType, blockType, rowItemId, rowItemType, rowItemTitle, rowItemColumn, rowItemLabel, rowItemValue } = this.state;
    
    return (<div className="textEditorPopup">
      <div className="textEditorInnerPopup">
        <div className="flex flex-column">
          <div className="flex inline-flex">
            {/* Title */}
            {language === 'English' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabel ">{rowItemLabel}</div>
              <div className="auditLabelYellow ml5">{rowItemTitle}</div>
            </div>}
            {language === 'Français' && <div className="flex-start inline-flex mb10 width100p">
              <div className="auditLabelYellow">{rowItemTitle}</div>
              <div className="auditLabel ml5">{rowItemLabel}</div>
            </div>}

            {/* Close Popup */}
            <div className="flex-end">
              <div className="iconClear icons cursor" onClick={(e) => this.closeTextEditorPopup()}></div>
            </div>
          </div>

          {/* Text Editor Component */}
          <TextEditor ItemId={itemId} ItemType={itemType} BlockType={blockType} RowItemId={rowItemId} RowItemType={rowItemType} FieldName={rowItemColumn} Label={rowItemLabel} Value={rowItemValue} Edit={true} Editable={true} onTextChange={this.updateDatasource} onTextUpdate={this.props.onTableUpdate} onPopupClose={this.closeTextEditorPopup}></TextEditor>
        </div>
      </div>
    </div>);
  }

  closeAuditPopup() {
    this.setState({ auditPopup: false });
  }

  closeFilesPopup(files, rowItemId, rowItemType, rowItemColumn) {
    let currentRow;
    let cellData = [];

    // Clone Datasource
    let dataSource = JSON.parse(JSON.stringify(this.treegrid.dataSource));

    if(dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId))) {
      currentRow = dataSource.find(row => row['Item_ID'] === rowItemType.substring(0,1).concat(rowItemId));
    }

    files.forEach(file => {
      cellData.push({ Id: file.CustomValueId, Label: file.FileName });
    });

    // Update Current Row Column
    if(currentRow) {
      currentRow[rowItemColumn] = cellData;
    }

    // Update Datasource
    this.treegrid.dataSource = dataSource;

    this.setState({ filesPopup: false });
  }

  closeTextEditorPopup() {
    this.setState({ textEditorPopup: false });
  }

  // ----- ----- Validation Functions ----- -----
  nameValidation(args) {
    return getValue('value', args).length <= 100;
  }

  textValidation(args) {
    return getValue('value', args).length <= 100;
  }

  numberValidation(args) {
    return getValue('value', args) >= 0;
  }

  progressValidation(args) {
    return (getValue('value', args) >= 0 && getValue('value', args) <= 100);
  }

  yearValidation(args) {
    return getValue('value', args) >= 2000;
  }

  // ----- ----- Format Functions ----- -----
  templateHeader(props) {
    const { itemId, itemType, blockType, guestLicence } = this.state;

    // Get Column Name and Value (Remove Suffix .Label to Object Fields)
    const columnName = props.field.replace(".Label", "");
    const label = props.headerText;
    let columnType, editable = false, filter = false, sort = false;

    // Column Indicators
    if(columnName !== 'Index') {
      columnType = this.getColumnType(columnName);
      editable = this.getColumnEditable(this.getColumnEditionItemType(columnName));
      filter = this.getColumnFilter(columnName);
      sort = this.getColumnSort(columnName);
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverColumnActions ItemId={itemId} ItemType={itemType} BlockType={blockType} ColumnName={columnName} ColumnType={columnType} Editable={editable} onColumnsFilter={this.filterColumns} onColumnsSort={this.sortColumns} onColumnAutofit={this.autofitColumn} onColumnsAutofit={this.autofitAllColumns} onColumnMultipleModification={this.multipleModification}></PopoverColumnActions>
        </Popover.Content>
      </Popover>
    );

    return <div className={"flex space-between align-items-center" + (editable ? " columnHeaderEditable" : " columnHeaderNotEditable")}>
      <span className="flex-start" onClick={!guestLicence ? () => this.sortColumn(columnName) : null}>{label}</span>
      <div className="flex-end">
        {/* Column Indicators */}
        {filter && <span className={(editable ? "iconColumnFilter" : "iconColumnFilterGrey") + " iconsTemplateHeader mh5"}></span>}
        {sort === 'ascending' && <span className={(editable ? "iconArrowDown" : "iconArrowDownGrey") + " iconsTemplateHeader mh5"}></span>}
        {sort === 'descending' && <span className={(editable ? "iconArrowUp" : "iconArrowUpGrey") + " iconsTemplateHeader mh5"}></span>}
        {/* Column Actions Popover */}
        {!guestLicence && <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <span className={(editable ? "iconColumnActions" : "iconColumnActionsGrey") + " iconsTemplateHeader mh5"}></span>
        </OverlayTrigger>}
      </div>
    </div>;
  }
  
  // ----- ----- Columns
  templateColumnAction(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const action = props.Action;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={action} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(action) {
      if(action.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{action.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{action.Label}</div>;
        }
      }
      else if(action.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <a target="_self" href={`/Card/${columnName}/${action.Id}/${blockType}`}><span className="iconAction verysmallIcons mr5"></span></a>
                <span className="">{action.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <a target="_self" href={`/Card/${columnName}/${action.Id}/${blockType}`}><span className="iconAction verysmallIcons mr5"></span></a>
            <span className="">{action.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnActive(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get Column Name and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;
    
    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconActiveTrue iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconActiveFalse iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnAdmin(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconShieldGreen iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconShieldGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnBudgetCode(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const budgetCode = props.BudgetCode;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={budgetCode} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(budgetCode) {
      if(budgetCode.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{budgetCode.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{budgetCode.Label}</div>;
        }
      }
      else if(budgetCode.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <span className="iconBudgetCode verysmallIcons mr5"></span>
                {/* <a target="_self" href={`/Card/${columnName}/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a> */}
                <span className="">{budgetCode.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <span className="iconBudgetCode verysmallIcons mr5"></span>
            {/* <a target="_self" href={`/Card/${columnName}/${budgetCode.Id}/Home`}><span className="iconBudgetCode verysmallIcons mr5"></span></a> */}
            <span className="">{budgetCode.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnBusinessLine(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const businessLine = props.Business_Line;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={businessLine} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(businessLine) {
      if(businessLine.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{businessLine.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{businessLine.Label}</div>;
        }
      }
      else if(businessLine.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <a target="_self" href={`/Card/${columnName}/${businessLine.Id}/${blockType}`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
                <span className="">{businessLine.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <a target="_self" href={`/Card/${columnName}/${businessLine.Id}/${blockType}`}><span className="iconBusinessLine verysmallIcons mr5"></span></a>
            <span className="">{businessLine.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnDataFreshness(props) {
    const dataFreshness = props.Data_Freshness;

    if(dataFreshness === "1") {
      return <div className="iconFullCircleGreen iconsTable"></div>;
    } 
    else if(dataFreshness === "2") {
      return <div className="iconFullCircleOrange iconsTable"></div>;
    } 
    else if(dataFreshness === "3") {
      return <div className="iconFullCircleRed iconsTable"></div>;
    }
    else if(dataFreshness === "4") {
      return <div className="iconCircleGrey iconsTable"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnDependency(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];
    let dependencies;

    if(value) {
      dependencies = value.split(',');

      return <div className="">
        {dependencies.map((dependency, index) => {
          switch(value.substring(0,1)) {
            case 'B':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{dependency}</div>;
            case 'P':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{dependency}</div>;
            case 'W':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{dependency}</div>;
            case 'A':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{dependency}</div>;
            case 'T':
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}><span className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span>{dependency}</div>;
            default:
                return <div key={index} className={"inline-flex" + (index < dependencies.length - 1 ? " mr10" : "")}>{dependency}</div>;
          }
        })}
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnEmail(props) {
    const email = props.Email;

    if(email) {
      return <a href={"mailto:" + email} className="emailTable">{email}</a>;
      // return <div onClick={(e) => {window.location = "mailto:" + email; e.preventDefault();}} className="emailTable">{email}</div>
    }
    else {
      return null;
    }
  }

  templateColumnEntity(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const entity = props.Entity;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={entity} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(entity) {
      if(entity.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{entity.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{entity.Label}</div>;
        }
      }
      else if(entity.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <a target="_self" href={`/Card/${columnName}/${entity.Id}/${blockType}`}><span className="iconEntity verysmallIcons mr5"></span></a>
                <span className="">{entity.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <a target="_self" href={`/Card/${columnName}/${entity.Id}/${blockType}`}><span className="iconEntity verysmallIcons mr5"></span></a>
            <span className="">{entity.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnExternal(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconExternal iconsRectangle"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconInternal iconsRectangle"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnHighlighted(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconHighlighted iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconNotHighlighted iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnImpact(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const impact = props.Impact;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={impact} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Impact (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(impact) {
      switch(impact.Id) {
        case 0:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
            }
        case 1:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{impact.Label}</span></li>;
            }
        case 2:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange-light"><span className="minicardPriority black">{impact.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange-light"><span className="minicardPriority black">{impact.Label}</span></li>;
            }
        case 3:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange"><span className="minicardPriority black">{impact.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange"><span className="minicardPriority black">{impact.Label}</span></li>;
            }
        case 4:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 black"><span className="minicardPriority red">{impact.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 black"><span className="minicardPriority red">{impact.Label}</span></li>;
            }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnItemType(props) {
    const itemType = props.Item_Type;

    switch(itemType) {
      case 'Business_Line':
          return <div className=""><span className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span>{itemType}</div>;
      case 'Project':
          return <div className=""><span className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span>{itemType}</div>;
      case 'Workpackage':
          return <div className=""><span className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span>{itemType}</div>;
      case 'Action':
          return <div className=""><span className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span>{itemType}</div>;
      case 'Task':
          return <div className=""><span className="treeIcon iconTaskWhite verysmallIcons" alt="Task"></span>{itemType}</div>;
      default:
          return <div className="">{itemType}</div>;
    }
  }

  templateColumnLag(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let backColor, foreColor = '#FFFFFF';

    if(value > 0) {
      backColor = "#00C77A";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" + " + value+ " " }</div>;
    }
    else if(value < 0) {
      backColor = "#E21313";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: backColor }}>{" - " + (-value) + " "}</div>;
    }
    else if(value === 0) {
      backColor = "#FFFFFF";
      foreColor = "#9D9D9F";
      return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: foreColor }}>{" " + value + " "}</div>;
    }
    else {
      return null;
    }
  }

  templateColumnLicenceType(props) {
    const licenceType = props.Licence_Type;
    
    switch(licenceType) {
      case 'Full':
          return <div className="axe brd-radius bg-green white">{licenceType}</div>;
      case 'Standard':
          return <div className="axe bordered green">{licenceType}</div>;
      case 'No Licence':
      case 'Duplicated license':
      case 'Invalid License':
          return <div className="axe bordered grey">{licenceType}</div>;
      default:
          return null;
    }
  }

  templateColumnMeteo(props) {
    const { currentView, dictColumns, dictParameters } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const meteo = props.Meteo;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={meteo} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
    if(meteo) {
      if(dictParameters['QualitativeDisplay'] && dictParameters['QualitativeDisplay'] === 'Traffic') {
        switch(meteo.Id) {
          case 0:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><span className="iconsTable"></span></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return null;
              }
          case 1:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="iconFullCircleGreen iconsTable"></div></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <div className="iconFullCircleGreen iconsTable"></div>;
              }
          case 2:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="iconFullCircleOrange iconsTable"></div></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <div className="iconFullCircleOrange iconsTable"></div>;
              }
          case 3:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="iconFullCircleRed iconsTable"></div></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <div className="iconFullCircleRed iconsTable"></div>;
              }
          case 4:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="iconFullCircleDarkRed iconsTable"></div></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <div className="iconFullCircleDarkRed iconsTable"></div>;
              }
          // case 5:
          //     // Editable with Popover
          //     if(editionItemTypes.includes(itemType)) {
          //       return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          //         <div className="width100p"><img className="iconsTable" src={IconMoon} alt="Moon"/></div>
          //       </OverlayTrigger>;
          //     }
          //     // Non Editable
          //     else {
          //       return <img className="iconsTable" src={IconMoon} alt="Moon"/>;
          //     }
          default:
              return null;
        }
      }
      else {
        // Meteo (0: None, 1: Sun ☀, 2: Cloud ⛅, 3: Rain 🌧, 4: Thunder 🌩, 5: Moon ☾)
        switch(meteo.Id) {
          case 0:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><span className="iconsTable"></span></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return null;
              }
          case 1:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><img className="iconsTable" src={IconSun} alt="Sun"/></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <img className="iconsTable" src={IconSun} alt="Sun"/>;
              }
          case 2:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><img className="iconsTable" src={IconCloud} alt="Cloud"/></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <img className="iconsTable" src={IconCloud} alt="Cloud"/>;
              }
          case 3:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><img className="iconsTable" src={IconRain} alt="Rain"/></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <img className="iconsTable" src={IconRain} alt="Rain"/>;
              }
          case 4:
              // Editable with Popover
              if(editionItemTypes.includes(itemType)) {
                return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><img className="iconsTable" src={IconThunder} alt="Thunder"/></div>
                </OverlayTrigger>;
              }
              // Non Editable
              else {
                return <img className="iconsTable" src={IconThunder} alt="Thunder"/>;
              }
          // case 5:
          //     // Editable with Popover
          //     if(editionItemTypes.includes(itemType)) {
          //       return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          //         <div className="width100p"><img className="iconsTable" src={IconMoon} alt="Moon"/></div>
          //       </OverlayTrigger>;
          //     }
          //     // Non Editable
          //     else {
          //       return <img className="iconsTable" src={IconMoon} alt="Moon"/>;
          //     }
          default:
              return null;
        }
      }
    }
    else {
      return null;
    }
  }

  templateColumnMeteoFreshness(props) {
    const meteoFreshness = props.Meteo_Freshness;

    if(meteoFreshness === "1") {
      return <div className="iconFullCircleGreen iconsTable"></div>;
    } 
    else if(meteoFreshness === "2") {
      return <div className="iconFullCircleOrange iconsTable"></div>;
    } 
    else if(meteoFreshness === "3") {
      return <div className="iconFullCircleRed iconsTable"></div>;
    }
    else if(meteoFreshness === "4") {
      return <div className="iconCircleGrey iconsTable"></div>;
    }
    else {
      return null;
    }
  }

  templateColumnMonthBurned(props) {
    const monthBurned = props.Month_Burned;

    if(monthBurned == 0) {
      return <div className="monthBurned brd-dashed-timetracking blue bold">{monthBurned}</div>;
    }
    else {
      return <div className="monthBurned brd-timetracking blue bold">{monthBurned}</div>;
    }
  }

  templateColumnName(props) {
    const blockType = this.state.blockType;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    let split, firstname = '', lastname = '';

    if(itemType === 'Resource') {
      if(name === '- Not assigned -') {
        firstname = 'N';
        lastname = 'A';
      }
      else if(name === '- To be assigned -') {
        firstname = 'T';
        lastname = 'B';
      }
      else if(name) {
        split = name.split(' ');
  
        if(split.length === 1) {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = name.split(' ')[1].substring(0,1);
        }
        else {
          firstname = name.split(' ')[0].substring(0,1);
          lastname = name.split(' ')[split.length-1].substring(0,1);
        }
      }
    }
    
    switch(itemType) {
      case 'AgileBoard':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconGrey iconAgileBoard verysmallIcons" alt="AgileBoard"></span></a>{name}</div>);
      case 'Sprint':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIconGrey iconSprint verysmallIcons" alt="Sprint"></span></a>{name}</div>);
      case 'Business_Line':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconBusinessLineWhite verysmallIcons" alt="Business Line"></span></a>{name}</div>);
      case 'Project':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconProjectWhite verysmallIcons" alt="Project"></span></a>{name}</div>);
      case 'Workpackage':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconWorkpackageWhite verysmallIcons" alt="Workpackage"></span></a>{name}</div>);
      case 'Action':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/${blockType}`}><span title={itemType} className="treeIcon iconActionWhite verysmallIcons" alt="Action"></span></a>{name}</div>);
      case 'Task':
          return (<div className=""><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Details`}><span title={itemType} className="treeIconGrey iconTaskGrey verysmallIcons" alt="Task"></span></a>{name}</div>);
      case 'Entity':
          return (<div className="bold"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><span title={itemType} className="treeIconTurquoise iconEntityWhite verysmallIcons" alt="Entity"></span></a>{name}</div>);
      case 'Resource':
          return (<div className="align-baseline"><a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div title={itemType} className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a><span className="ml10 grey">{name}</span></div>);
      case 'Definition':
          if(props.Parent_ID) {
            return (<div className=""><span title={itemType} className="treeIconGrey iconBudgetCodeGrey verysmallIcons" alt="Budget"></span>{name}</div>);
          }
          else {
            return (<div className="bold"><span title={itemType} className="treeIconGrey iconBudgetCode verysmallIcons" alt="Budget"></span>{name}</div>);
          }
      default:
          return <span className="">{name}</span>;
    }
  }

  templateColumnPriority(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const priority = props.Priority;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={priority} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Priority (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(priority) {
      switch(priority.Id) {
        case 0:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
            }
        case 1:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{priority.Label}</span></li>;
            }
        case 2:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange-light"><span className="minicardPriority black">{priority.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange-light"><span className="minicardPriority black">{priority.Label}</span></li>;
            }
        case 3:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange"><span className="minicardPriority black">{priority.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange"><span className="minicardPriority black">{priority.Label}</span></li>;
            }
        case 4:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 black"><span className="minicardPriority red">{priority.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 black"><span className="minicardPriority red">{priority.Label}</span></li>;
            }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProbability(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const probability = props.Probability;
    
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={probability} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Probability (0: Very Low, 1: Low, 2: Medium, 3: High, 4: Critical)
    if(probability) {
      switch(probability.Id) {
        case 0:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
            }
        case 1:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 green"><span className="minicardPriority black">{probability.Label}</span></li>;
            }
        case 2:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange-light"><span className="minicardPriority black">{probability.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange-light"><span className="minicardPriority black">{probability.Label}</span></li>;
            }
        case 3:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 orange"><span className="minicardPriority black">{probability.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 orange"><span className="minicardPriority black">{probability.Label}</span></li>;
            }
        case 4:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <li className="fs18 black"><span className="minicardPriority red">{probability.Label}</span></li>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <li className="fs18 black"><span className="minicardPriority red">{probability.Label}</span></li>;
            }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnProgress(props) {
    let progress = props.Progress;
    // progress = (props.Progress.replace(',', '.') * 100).toFixed(0);
    
    if(props.Progress) {
      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateColumnProject(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const project = props.Project;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={project} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(project) {
      if(project.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{project.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{project.Label}</div>;
        }
      }
      else if(project.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <a target="_self" href={`/Card/${columnName}/${project.Id}/${blockType}`}><span className="iconProject verysmallIcons mr5"></span></a>
                <span className="">{project.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <a target="_self" href={`/Card/${columnName}/${project.Id}/${blockType}`}><span className="iconProject verysmallIcons mr5"></span></a>
            <span className="">{project.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnResourceName(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const name = props.Name;

    if(name === '- Not assigned -') {
      let firstname = 'N';
      let lastname = 'A';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name === '- To be assigned -') {
      let firstname = 'T';
      let lastname = 'B';

      return <div className="align-baseline">
        <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else if(name) {
      let firstname, lastname;
      let split = name.split(' ');

      if(split.length === 1) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = '';
      }
      else if(split.length === 2) {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[1].substring(0,1);
      }
      else {
        firstname = name.split(' ')[0].substring(0,1);
        lastname = name.split(' ')[split.length-1].substring(0,1);
      }

      return <div className="align-baseline">
        <a target="_self" href={`/Card/${itemType}/${itemId.substring(1)}/Home`}><div className="iconResourceTable icons aligncenter"><span className="white">{firstname + lastname}</span></div></a>
        <span className="ml10 grey">{name}</span>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnRowType(props) {
    const rowType = props.RowType;

    if(rowType === 'Holiday') {
      return <div className="turquoise bold"><span className="iconHolidays tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'Forecast') {
      return <div className="blue"><span className="iconHourglass tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else if(rowType === 'TimeTracking') {
      return <div className="blue bold"><span className="iconTimeTrackingBlue tableIcon verysmallIcons"></span>{rowType}</div>;
    }
    else {
      return <div className="">{rowType}</div>;
    }
  }

  templateColumnSprint(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const sprint = props.Sprint;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    let backColor, foreColor, borderColor;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={sprint} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Back, Fore & Border Color
    if(sprint) {
      if(!sprint.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = sprint.BackColor;
      }
      if(!sprint.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((sprint.ForeColor === '#FFFFFF' || sprint.ForeColor === '#ffffff')) {
          borderColor = sprint.BackColor;
        }
        else {
          borderColor = sprint.ForeColor;
        }

        foreColor = sprint.ForeColor;
      }
    }

    if(sprint) {
      if(sprint.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{sprint.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{sprint.Label}</div>;
        }
      }
      else if(sprint.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
                {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
                {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
                <span className="">{sprint.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>
            {(backColor === '#FFFFFF' || backColor === '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprint verysmallIcons mr5"></span></a>}
            {(backColor !== '#FFFFFF' && backColor !== '#ffffff') && <a target="_self" href={`/Card/${columnName}/${sprint.Id}/${blockType}`}><span className="iconSprintWhite verysmallIcons mr5"></span></a>}
            <span className="">{sprint.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnStatus(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const status = props.Status;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }
    
    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={status} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Status (1: To be Done, 2: In Progress, 3: On Hold, 4: Cancelled, 5: Completed, 6: Open, 7: Closed)
    // Status (8: Realised, 9: To be Scheduled, 10: Scheduled, 11: Taken, 12: To be Taken)
    if(status) {
      switch(status.Id) {
        case 1:
        case 9:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe brd-radius bg-grey white">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe brd-radius bg-grey white">{status.Label}</div>;
            }
        case 2:
        case 10:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe brd-radius bg-blue white">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe brd-radius bg-blue white">{status.Label}</div>;
            }
        case 3:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe bordered orange">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe bordered orange">{status.Label}</div>;
            }
        case 4:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe bordered grey">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe bordered grey">{status.Label}</div>;
            }
        case 5:
        case 7:
        case 11:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe brd-radius bg-green white">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe brd-radius bg-green white">{status.Label}</div>;
            }
        case 6:
        case 12:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe brd-radius bg-orange white">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe brd-radius bg-orange white">{status.Label}</div>;
            }
        case 8:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                  <div className="width100p"><div className="axe brd-radius bg-red white">{status.Label}</div></div>
                </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="axe brd-radius bg-red white">{status.Label}</div>;
            }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTask(props) {
    const task = props.Task;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    if(task) {
      if(task.Id === 1) {
        return <div className="axe brd-dashed very-light-grey">{task.Label}</div>;
      }
      else if(task.Label) {
        return <div className="axe bordered blue">
          <a target="_self" href={`/Card/${columnName}/${task.Id}/Details`}><span className="iconTask iconsTable mr5"></span></a>
          <span className="">{task.Label}</span>
        </div>;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnTimeline(props) {
    let status = props.Status;
    let startDate = props.StartDate;
    let endDate = props.EndDate;

    // Create Timeline with different colors based on status, StartDate and EndDate
    if(status && startDate && endDate) {
      // Format Date to English format (it is Date object after modification in Table Datepicker)
      if(startDate instanceof Date) {
        startDate = startDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      if(endDate instanceof Date) {
        endDate = endDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }

      return <Timeline View={'Block'} Status={status} StartDate={startDate} EndDate={endDate}></Timeline>;
    }
    else {
      return null;
    }
  }

  templateColumnTrend(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const trend = props.Trend;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={trend} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Trend (0: None, 1: Down ⬊, 2: Right ⮕, 3: Up ⬈)
    if(trend) {
      switch(trend.Id) {
        case 0:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><span className="iconsTable"></span></div>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return null;
            }
        case 1:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><span className="iconTrendDown iconsTable"></span></div>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="iconTrendDown iconsTable"></div>;
            }
        case 2:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><span className="iconTrendRight iconsTable"></span></div>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="iconTrendRight iconsTable"></div>;
            }
        case 3:
            // Editable with Popover
            if(editionItemTypes.includes(itemType)) {
              return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
                <div className="width100p"><span className="iconTrendUp iconsTable"></span></div>
              </OverlayTrigger>;
            }
            // Non Editable
            else {
              return <div className="iconTrendUp iconsTable"></div>;
            }
        default:
            return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnValidated(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateColumnWarning(props) {
    const warningMessage = props.WarningMessage;

    if(warningMessage) {
      return <OverlayTrigger key="right" placement="right" overlay={<Tooltip id="tooltip-right" className="tooltip-warning">{warningMessage}</Tooltip>}>
        <Badge className="badgeTable bg-white" pill><i className="iconWarningRed verysmallIcons"/></Badge>
      </OverlayTrigger>;
    }
    else {
      return null;
    }
  }

  templateColumnWorkpackage(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;
    const workpackage = props.Workpackage;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={workpackage} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(workpackage) {
      if(workpackage.Id === 1) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{workpackage.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{workpackage.Label}</div>;
        }
      }
      else if(workpackage.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <div className="axe bordered blue">
                <a target="_self" href={`/Card/${columnName}/${workpackage.Id}/${blockType}`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
                <span className="">{workpackage.Label}</span>
              </div>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered blue">
            <a target="_self" href={`/Card/${columnName}/${workpackage.Id}/${blockType}`}><span className="iconWorkpackage verysmallIcons mr5"></span></a>
            <span className="">{workpackage.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateColumnYear(props) {
    const year = props.Year;

    if(year) {
      return <div className="">{year}</div>;
    }
    else {
      return null;
    }
  }

  // ----- ----- Type
  templateTypeAxe(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    let backColor, foreColor, borderColor;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    // Back, Fore & Border Color
    if(value) {
      if(!value.BackColor) {
        backColor = '#FFFFFF';
      }
      else {
        backColor = value.BackColor;
      }
      if(!value.ForeColor) {
        foreColor = '#0088C7';
        borderColor = '#0088C7';
      }
      else {
        if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
          borderColor = value.BackColor;
        }
        else {
          borderColor = value.ForeColor;
        }

        foreColor = value.ForeColor;
      }
    }

    if(value) {
      if(value.Label === '- NA -') {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{value.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
        }
      }
      else if(value.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe bordered" style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeAxisTable(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let editionItemTypes = '', restrictedValues = [];

    if(column) {
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ObjectTableValues={values} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(values) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {values.map((value, index) => {
                let backColor, foreColor, borderColor;

                // Back, Fore & Border Color
                if(value) {
                  if(!value.BackColor) {
                    backColor = '#FFFFFF';
                  }
                  else {
                    backColor = value.BackColor;
                  }
                  if(!value.ForeColor) {
                    foreColor = '#0088C7';
                    borderColor = '#0088C7';
                  }
                  else {
                    if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                      borderColor = value.BackColor;
                    }
                    else {
                      borderColor = value.ForeColor;
                    }
      
                    foreColor = value.ForeColor;
                  }
                }

                return <div key={index} className={"axisTable bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {values.map((value, index) => {
            let backColor, foreColor, borderColor;

            // Back, Fore & Border Color
            if(value) {
              if(!value.BackColor) {
                backColor = '#FFFFFF';
              }
              else {
                backColor = value.BackColor;
              }
              if(!value.ForeColor) {
                foreColor = '#0088C7';
                borderColor = '#0088C7';
              }
              else {
                if((value.ForeColor === '#FFFFFF' || value.ForeColor === '#ffffff')) {
                  borderColor = value.BackColor;
                }
                else {
                  borderColor = value.ForeColor;
                }
  
                foreColor = value.ForeColor;
              }
            }

            return <div key={index} className={"axisTable bordered" + (index < values.length - 1 ? " mr10" : "")} style={{ background: backColor, color: foreColor, borderColor: borderColor }}>{value.Label}</div>;
          })}
        </div>;
      }
    }
    else {
      return null;
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }

  templateTypeBoolean(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing;

    if(value == 'True') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanTrue iconCheck iconsTable"></div>
      </div>;
    }
    else if(value == 'False') {
      return <div className="booleanTable">
        <div id={id} className="boolean iconBooleanFalse iconCircleGrey iconsTable"></div>
      </div>;
    }
    else {
      return null;
    }
  }

  templateTypeDate(props) {
    const formatDate = this.state.formatDate;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let date;
    
    if(value) {
      if(formatDate === 'MM/DD/YYYY') {
        date = new Date(value).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      }
      else if(formatDate === 'DD/MM/YYYY') {
        date = new Date(value).toLocaleDateString("fr-FR");
      }
      else if(formatDate === 'YYYY-MM-DD') {
        date = this.formatDateKr(new Date(value));
      }
    }

    return <div className="">{date}</div>;
  }

  templateTypeDouble(props) {
    const dictColumns = this.state.dictColumns;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let conditionalFormattings, conditionalFormatting, unit;

    if(column) {
      conditionalFormattings = column.ConditionalFormattings;
      conditionalFormatting = this.getCellConditionalFormatting(conditionalFormattings, value);
      unit = column.Unit;
    }

    if(conditionalFormatting) {
      if(value || value === 0) {
        if(unit) {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="conditionalFormatting" style={{ background: conditionalFormatting.BackGroundColor, color: conditionalFormatting.Color, borderColor: conditionalFormatting.Color }}>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      if(value || value === 0) {
        if(unit) {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " " + unit}</div>;
        }
        else {
          return <div className="">{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>;
        }
      }
      else {
        return null;
      }
    }
  }

  templateTypeFiles(props) {
    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];
    
    if(values) {
      return <div className="width100p">
        <div className="cellTable inline-flex">
          {values.map((value, index) => {
            if(value && value.Label) {
              let split = value.Label.split('.');
              let extension = split[split.length - 1];

              if(extension === 'docx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconWord iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pdf') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPDF iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'pptx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconPPT iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else if(extension === 'xlsx') {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconExcel iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
              else {
                return <div key={index} className={"inline-flex align-items-center" + (index < values.length - 1 ? " mr10" : "")}>
                  <span className="iconFile iconsTable mr10"></span>
                  <span className="">{value.Label}</span>
                </div>;
              }
            }
            else {
              return null;
            }
          })}
        </div>
      </div>;
    }
    else {
      // return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
      return null;
    }
  }

  templateTypeHTML(props) {
    const dictParameters = this.state.dictParameters;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    if(dictParameters['HTMLContent'] && dictParameters['HTMLContent'] === 'Limited') {
      return <div className="limitedHeightTable" dangerouslySetInnerHTML={{ __html: value }}/>;
    }
    else if(value) {
      return <div dangerouslySetInnerHTML={{ __html: value }}/>;
    }
    else {
      return null;
    }
  }

  templateTypeLink(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    const split = value.split('/');
    const shortName = split[split.length-1];

    if(value) {
      return <div title={value} className=""><a target="_self" className="" href={value}><span className="treeIcon iconLink iconsPath" alt="Link"></span>{shortName}</a></div>;
    }
    else {
      return null;
    }
  }

  templateTypeLocation(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let columnType, editionItemTypes = '', restrictedValues = [];

    if(column) {
      columnType = column.FieldType;
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ColumnType={columnType} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value) {
      if(value.Label === '- NA -') {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p"><div className="axe brd-dashed very-light-grey">{value.Label}</div></div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="axe brd-dashed very-light-grey">{value.Label}</div>;
        }
      }
      else if(value.Label) {
        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="width100p">
              <span className="iconMap verysmallIcons"></span>
              <span className="">{value.Label}</span>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="">
          <span className="iconMap verysmallIcons"></span>
          <span className="">{value.Label}</span>
        </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypePercentage(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let percentage = value;
    // percentage = (value * 100).toFixed(0);

    if(value) {
      if(percentage == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 100 && percentage >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={percentage}></ProgressBar></span>
        </div>;
      }
      else if(percentage < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(percentage)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${percentage} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return null;
    }
  }

  templateTypeProgress(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    let progress;
    
    if(value) {
      progress = (value.replace(',', '.') * 100).toFixed(0);

      if(progress == 100) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="success" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 100 && progress >= 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="primary" now={progress}></ProgressBar></span>
        </div>;
      }
      else if(progress < 0) {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={Math.abs(progress)}></ProgressBar></span>
        </div>;
      }
      else {
        return <div className="progressBlock">
          <span className="progressLabel">{`${progress} %`}</span>
          <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'100'}></ProgressBar></span>
        </div>;
      }
    }
    else {
      return <div className="progressBlock">
        <span className="progressLabel">{`0 %`}</span>
        <span className="progressBar"><ProgressBar className="progressTable" variant="danger" now={'0'}></ProgressBar></span>
      </div>;
    }
  }

  templateTypeQRCode(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    const url = window.location.origin + '/Card/' + itemType + '/' + itemId.substring(1) + '/Details';

    if(itemId && itemType && url) {
      return <QRCode value={url} size={64} style={{ height: 'auto', width: 'auto' }} viewBox={`0 0 64 64`}/>;
    }

    // Get ColumnName and Value
    // const columnName = this.getColumnName(props);
    // const value = props[columnName];

    // if(value) {
    //   return <img style={{ height: '50px', width: '50px' }} src={'data:image/jpeg;base64,' + value}/>;
    // }
  }

  templateTypeRating(props) {
    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    // const allowEditing = props.column.allowEditing;
    const allowEditing = this.getColumnAllowEditing(props);
    const id = itemId + " " + itemType + " " + columnName + " " + allowEditing + " " + value;

    if(value == 5) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRating iconsRating mr10"></div>
      </div>;
    }
    else if(value == 4) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 3) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 2) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value == 1) {
      return <div className="ratingTable">
        <div id={id + " 0"} className="rating iconRating iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else if(value === 0) {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
    else {
      return <div className="ratingTable">
        <div id={id + " 1"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 2"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 3"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 4"} className="rating iconRatingEmpty iconsRating mr10"></div>
        <div id={id + " 5"} className="rating iconRatingEmpty iconsRating mr10"></div>
      </div>;
    }
  }

  templateTypeResource(props) {
    const { blockType, currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Value
    const value = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let columnType, editionItemTypes = '', restrictedValues = [];

    if(column) {
      columnType = column.FieldType;
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditAxe ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ColumnType={columnType} AxeValue={value} RestrictedValues={restrictedValues} onAxeEdit={this.editAxe}></PopoverEditAxe>
        </Popover.Content>
      </Popover>
    );

    if(value) {
      if(value.Id === 1) {
        const firstname = 'N';
        const lastname = 'A';

        // Editable with Popover
        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
              <span className="ml10 grey">{value.Label}</span>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="align-baseline">
            <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
            <span className="ml10 grey">{value.Label}</span>
          </div>;
        }
      }
      else if(value.Label === '- To be assigned -') {
        const firstname = 'T';
        const lastname = 'B';

        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="align-baseline">
              <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
              <span className="ml10 grey">{value.Label}</span>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="align-baseline">
            <span className="iconEmptyResourceTable icons aligncenter blue">{firstname + lastname}</span>
            <span className="ml10 grey">{value.Label}</span>
          </div>;
        }
      }
      else if(value.Id && value.Label) {
        let firstname, lastname;
        const split = value.Label.split(' ');

        if(split.length === 1) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = '';
        }
        else if(split.length === 2) {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[1].substring(0,1);
        }
        else {
          firstname = value.Label.split(' ')[0].substring(0,1);
          lastname = value.Label.split(' ')[split.length-1].substring(0,1);
        }

        if(editionItemTypes.includes(itemType)) {
          return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
            <div className="align-baseline">
              <a target="_self" href={`/Card/Resource/${value.Id}/${blockType}`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
              <span className="ml10 grey">{value.Label}</span>
            </div>
          </OverlayTrigger>;
        }
        // Non Editable
        else {
          return <div className="align-baseline">
            <a target="_self" href={`/Card/Resource/${value.Id}/${blockType}`}><span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span></a>
            <span className="ml10 grey">{value.Label}</span>
          </div>;
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  templateTypeResourceTable(props) {
    const { currentView, dictColumns } = this.state;

    const itemId = props.Item_ID;
    const itemType = props.Item_Type;

    // Get ColumnName (Remove .Label to Object fields)
    const columnName = this.getColumnName(props);

    // Get Values
    const values = props[columnName];

    // Get Dict Columns attributes
    const column = dictColumns[columnName];
    let columnType, editionItemTypes = '', restrictedValues = [];

    if(column) {
      columnType = column.FieldType;
      editionItemTypes = column.EditionItemTypes;
      restrictedValues = column.RestrictedValues;
    }

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditObjectTable ItemId={itemId.substring(1)} ItemType={itemType} CurrentView={currentView} ColumnName={columnName} ColumnType={columnType} ObjectTableValues={values} RestrictedValues={restrictedValues} onObjectTableEdit={this.editObjectTable}></PopoverEditObjectTable>
        </Popover.Content>
      </Popover>
    );
    
    if(values) {
      // Editable with Popover
      if(editionItemTypes.includes(itemType)) {
        return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="width100p">
            <div className="cellTable inline-flex">
              {values.map((value, index) => {
                let firstname, lastname;
                const split = value.Label.split(' ');
          
                if(split.length === 1) {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = '';
                }
                else if(split.length === 2) {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = value.Label.split(' ')[1].substring(0,1);
                }
                else {
                  firstname = value.Label.split(' ')[0].substring(0,1);
                  lastname = value.Label.split(' ')[split.length-1].substring(0,1);
                }

                if(value.Id === 0) {
                  return <div key={index} className="multiResource mr10">{value.Label}</div>;
                }
                else {
                  return <div key={index} className="multiResource mr10">
                    <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                    <span className="ml5 grey">{value.Label}</span>
                  </div>;
                }
              })}
            </div>
          </div>
        </OverlayTrigger>;
      }
      // Non Editable
      else {
        return <div className="inline-flex">
          {values.map((value, index) => {
            let firstname, lastname;
            const split = value.Label.split(' ');
      
            if(split.length === 1) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = '';
            }
            else if(split.length === 2) {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[1].substring(0,1);
            }
            else {
              firstname = value.Label.split(' ')[0].substring(0,1);
              lastname = value.Label.split(' ')[split.length-1].substring(0,1);
            }

            if(value.Id === 0) {
              return <div key={index} className="multiResource mr10">{value.Label}</div>;
            }
            else {
              return <div key={index} className="multiResource mr10">
                <span className="iconResourceTable icons aligncenter white">{firstname + lastname}</span>
                <span className="ml5 grey">{value.Label}</span>
              </div>;
            }
          })}
        </div>;
      }
    }
    else {
      return <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}><div className="emptyCellTable"></div></OverlayTrigger>;
    }
  }

  templateTypeText(props) {
    // Get ColumnName and Value
    const columnName = this.getColumnName(props);
    const value = props[columnName];

    return <div className="">{value}</div>;
  }
  
  render() {
    let { language, itemId, itemType, blockType, editable, guestLicence, currentView, dictParameters, dictColumns, columns, dataSource, auditPopup, filesPopup, textEditorPopup, confirmDelete, confirmCutPaste, cutItemId, cutItemType, rowItemId, rowItemType, rowItemTitle, rowItemColumn, rowItemLabel } = this.state;
    let height, minimum, pagging = true;

    if(Object.entries(dictParameters).length === 0 || columns.length === 0 || dataSource.length === 0) {
      return <div className=""></div>;
    }

    // Tree Height
    if(!pagging) {
      if(window.innerHeight < 100) {
        height = '100px';
      }
      else {
        height = (window.innerHeight - 175);
      }
    }
    else {
      if(itemType === 'Environment' && blockType === 'Roadmap') {
        height = (window.innerHeight - 235);
        minimum = 100;
      }
      else {
        height = (window.innerHeight - 205);
        minimum = 100;
      }

      if(height > minimum) {
        height += 'px';
      }
      else {
        height = minimum + 'px';
      }
    }

    // Column Menu
    this.columnMenuItems = [];
    // this.columnMenuItems = ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending', 'Filter'];

    // Context Menu
    if(editable && !guestLicence) {
      this.contextMenuItems = [
        { id: 'contextMenu_AddBL', text: Traduction.translate(language, 'add_business_line'), target: '.e-content' },
        { id: 'contextMenu_AddProject', text: Traduction.translate(language, 'add_project'), target: '.e-content' },
        { id: 'contextMenu_AddWorkpackage', text: Traduction.translate(language, 'add_workpackage'), target: '.e-content' },
        { id: 'contextMenu_AddAction', text: Traduction.translate(language, 'add_action'), target: '.e-content' },
        { id: 'contextMenu_AddTask', text: Traduction.translate(language, 'add_task'), target: '.e-content' },
        { id: 'contextMenu_AddResource', text: Traduction.translate(language, 'add_resource'), target: '.e-content' },
        { id: 'contextMenu_AddDefinition', text: Traduction.translate(language, 'add_budget_definition'), target: '.e-content' },
        { id: 'contextMenu_AddSprint', text: Traduction.translate(language, 'add_sprint'), target: '.e-content' },
        { id: 'contextMenu_Cut', text: Traduction.translate(language, 'cut'), target: '.e-content' },
        { id: 'contextMenu_Paste', text: Traduction.translate(language, 'paste'), target: '.e-content' },
        { id: 'contextMenu_Duplicate', text: Traduction.translate(language, 'duplicate'), target: '.e-content' },
        { id: 'contextMenu_Delete', text: Traduction.translate(language, 'delete'), target: '.e-content' },
        { id: 'contextMenu_Dependencies', text: Traduction.translate(language, 'dependencies'), target: '.e-content' },
        { id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }
      ];
    }
    else {
      this.contextMenuItems = [{ id: 'contextMenu_Audit', text: Traduction.translate(language, 'audit'), target: '.e-content' }];
    }

    // Edit Options
    this.editOptions = { allowAdding: true, allowDeleting: true, allowEditing: !guestLicence, mode: 'Cell' };

    // Grid Lines
    this.gridLines = 'None';

    // Validation Rules
    this.dateRules = { date: true };
    this.nameRules = { maxLength: [this.nameValidation, Traduction.translate(language, 'rules_name')], required: true };
    this.textRules = { maxLength: [this.textValidation, Traduction.translate(language, 'rules_text')], required: false };
    this.numberRules = { min: [this.numberValidation, Traduction.translate(language, 'rules_number')], number: true };
    this.progressRules = { min: [this.progressValidation, Traduction.translate(language, 'rules_progress')], number: true };
    this.yearRules = { min: [this.yearValidation, Traduction.translate(language, 'rules_year')], number: true, required: true };

    return (
      <div className="">
        {/* Audit Popup */}
        {auditPopup && <TableAudit ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} BlockType={blockType} onPopupClose={this.closeAuditPopup}></TableAudit>}

        {/* Delete Confirmation Popup */}
        {confirmDelete && <PopupConfirmation ItemType={rowItemType} Message={this.getDeleteLabel(rowItemType)} onMessageConfirm={this.confirmDelete} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

        {/* Cut Paste Confirmation Popup */}
        {confirmCutPaste && <PopupCutPaste ItemTitle={rowItemTitle} onCutPasteConfirm={this.confirmCutPaste} onCutPasteCancel={this.cancelCutPaste}></PopupCutPaste>}

        {/* Files Popup */}
        {filesPopup && <PopupEditFiles ItemId={rowItemId} ItemType={rowItemType} ItemTitle={rowItemTitle} ColumnName={rowItemColumn} ColumnLabel={rowItemLabel} onPopupClose={this.closeFilesPopup}></PopupEditFiles>}

        {/* Text Editor Popup */}
        {textEditorPopup && this.popupTextEditor()}

        {/* TreeGrid Component */}
        <div className="control-pane">
          <div className="control-section">
            {dataSource.length > 0 && 
              <TreeGridComponent id={'tree' + currentView.ViewId} dataSource={dataSource} idMapping='Item_ID' parentIdMapping='Parent_ID' expandStateMapping='isExpanded' locale={Traduction.translate(language, 'locale')} height={height} editSettings={this.editOptions} gridLines={this.gridLines} searchSettings={this.getTreeSearchSettings(dictColumns, dictParameters['Columns'])} treeColumnIndex={this.getTreeColumnIndex(dictParameters['Columns'])} columnMenuItems={this.columnMenuItems} contextMenuItems={this.contextMenuItems} contextMenuOpen={this.contextMenuOpen} contextMenuClick={this.contextMenuClick} dataBound={this.dataBound} rowDataBound={this.rowDataBound} actionBegin={this.actionBegin} actionComplete={this.actionComplete} cellEdit={this.cellEdit} collapsing={this.collapsing} expanding={this.expanding} resizeStop={this.resizeStop} excelQueryCellInfo={this.excelQueryCellInfo} pdfQueryCellInfo={this.pdfQueryCellInfo} showColumnMenu={false} showColumnChooser={false} allowFiltering={false} allowPaging={pagging} enableInfiniteScrolling={false} enableVirtualization={false} pageSettings={{ pageSize: 50 }} allowExcelExport={true} allowPdfExport={true} allowReordering={true} allowResizing={true} allowSorting={false} allowTextWrap={true} ref={treegrid=>this.treegrid=treegrid}>
                <ColumnsDirective>
                  {/* Create Current view Columns */}
                  {dictParameters && dictParameters.Columns.map((column, index) => {
                    let col;

                    // Check if ColumnHeader is sent by backend
                    if(dictColumns[column]) {
                      col = this.createColumn(dictColumns[column], dictParameters['ColumnsWidth'], true);

                      return <ColumnDirective key={index} field={col.field} type={col.type} headerText={col.headerText} headerTemplate={col.headerTemplate} edit={col.edit} editType={col.editType} format={col.format} template={col.template} textAlign={col.textAlign} isPrimaryKey={col.isPrimaryKey} allowEditing={col.allowEditing} visible={true} validationRules={col.validationRules} width={col.width}></ColumnDirective>
                    }
                  })}
                  {/* Mandatory Columns if not in Current view */}
                  {!dictParameters.Columns.find(column => column === 'Item_ID') && <ColumnDirective field='Item_ID' headerText={'Item_ID'} isPrimaryKey={true} allowEditing={false} visible={false}></ColumnDirective>}
                  {!dictParameters.Columns.find(column => column === 'Item_Type') && <ColumnDirective field='Item_Type' headerText={'Item_Type'} isPrimaryKey={false} allowEditing={false} visible={false}></ColumnDirective>}
                </ColumnsDirective>
                <Inject services={[ ContextMenu, ColumnMenu, Edit, Filter, Sort, Reorder, Resize, InfiniteScroll, VirtualScroll, Page, ExcelExport, PdfExport ]}/>
              </TreeGridComponent>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Tree;