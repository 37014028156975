import React, {Component} from 'react';
import { Button, Form } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';

class PopoverEditObjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      staticData: null,
      itemId: null,
      itemType: null,
      currentView: {},
      columnName: null,
      columnType: null,
      oldValue: [],
      objectTableValues: [],
      restrictedValues: [],
      search: ''
    };

    this.searchItem = this.searchItem.bind(this);
    this.pressKey = this.pressKey.bind(this);
    this.addValue = this.addValue.bind(this);
    this.deleteValue = this.deleteValue.bind(this);
    this.updateObjectTable = this.updateObjectTable.bind(this);

    this.search = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const staticData = Authentication.getCookie('staticData');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const objectTableValues = this.props.ObjectTableValues;
    const restrictedValues = this.props.RestrictedValues;

    // Search Auto focus
    setTimeout(() => { this.search.current.focus() }, 1);

    this.setState({ language, staticData, itemId, itemType, currentView, columnName, columnType, oldValue: objectTableValues, objectTableValues, restrictedValues });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const currentView = this.props.CurrentView;
    const columnName = this.props.ColumnName;
    const columnType = this.props.ColumnType;
    const objectTableValues = this.props.ObjectTableValues;
    const restrictedValues = this.props.RestrictedValues;

    // if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
    //   this.setState({ itemId, itemType });
    // }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    // if(this.props.ColumnName !== prevProps.ColumnName || this.props.AxeValue !== prevProps.AxeValue || this.props.RestrictedValues !== prevProps.RestrictedValues) {
    //   this.setState({ columnName, axeValue, restrictedValues });
    // }
  }

  componentWillUnmount() {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const columnName = this.state.columnName;
    const columnType = this.props.ColumnType;
    const oldValue = this.state.oldValue;
    const objectTableValues = this.state.objectTableValues;

    if(JSON.stringify(objectTableValues) != JSON.stringify(oldValue)) {
      // Call the event from the Parent component through the props
      this.props.onObjectTableEdit(itemId, itemType, columnName, JSON.stringify(oldValue), JSON.stringify(objectTableValues));
    }
  }

  searchItem(event) {
    const search = event.target.value;

    this.setState({ search });
  }

  pressKey(event) {
    const objectTableValues = this.state.objectTableValues;
    const restrictedValues = this.state.restrictedValues;
    const search = event.target.value;
    let currentValue;

    if(event.key === 'Enter' && search !== '') {
      if(restrictedValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()))) {
        currentValue = restrictedValues.find(value => value.Label.toLowerCase().includes(search.toLowerCase()));

        // Close Popover
        // if(document.getElementById("popover-basic")) {
        //   document.getElementById("popover-basic").classList.remove("show");
        // }

        // Check if Current Value is not already in the list
        if(!objectTableValues.find(value => value.Label === currentValue.Label)) {
          // Add Value
          this.addValue(JSON.stringify(currentValue));

          // Clear Search
          this.setState({ search: '' });
        }
      }
    }
  }

  addValue(newValue) {
    const objectTableValues = this.state.objectTableValues;

    // Clone Values
    let values = JSON.parse(JSON.stringify(objectTableValues));

    // Add Value in the List
    values.push(JSON.parse(newValue));

    // this.updateObjectTable(objectTableValues, values);

    this.setState({ objectTableValues: values });
  }

  deleteValue(newValue) {
    const objectTableValues = this.state.objectTableValues;
    let indexToDelete = -1;

    // Clone Values
    let values = JSON.parse(JSON.stringify(objectTableValues));

    // Find index of the Value to delete
    if(values.find(value => value.Id === JSON.parse(newValue).Id)) {
      indexToDelete = values.findIndex(value => value.Id === JSON.parse(newValue).Id);
    }

    // Remove Value in the List
    if(indexToDelete >= 0) {
      values.splice(indexToDelete, 1);
    }

    // this.updateObjectTable(objectTableValues, values);

    this.setState({ objectTableValues: values });
  }

  updateObjectTable(oldValue, newValue) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const columnName = this.state.columnName;

    // Close Popover
    // if(document.getElementById("popover-basic")) {
    //   document.getElementById("popover-basic").classList.remove("show");
    // }
    
    // if(newValue != oldValue) {
    //   // Call the event from the Parent component through the props
    //   this.props.onObjectTableEdit(itemId, itemType, columnName, JSON.stringify(oldValue), JSON.stringify(newValue));
    // }
  }

  render() {
    const { language, staticData, itemId, itemType, currentView, columnName, columnType, objectTableValues, restrictedValues, search } = this.state;

    return (
      <div className="axeEditPopover">
        {/* Search */}
        <div className="axeEditSearch">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} autoFocus onChange={this.searchItem} onKeyDown={this.pressKey} ref={this.search}/>
          </Form.Group>
        </div>
        {/* Values */}
        <div className="axeEditValues scrollbar-y">
          {/* Current Values */}
          {objectTableValues && objectTableValues.map((value, index) => {
            return <div key={index} className="axeEditValue" onClick={() => this.deleteValue(JSON.stringify(value))}>
              <div className="inline-flex">
                <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
                <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
              </div>
            </div>;
          })}
          {/* {objectTableValues && <div className="axeCurrentValue">
            <Axe Axe={columnName} Value={objectTableValues} View={'Popover'}></Axe>
          </div>} */}
          {/* Border */}
          {/* <div className="popoverBorder"></div> */}
          {/* Other Values */}
          {restrictedValues.map((value, index) => {
            if(!objectTableValues) {
              if(search === "" || value.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.addValue(JSON.stringify(value))}>
                  <div className="inline-flex">
                    <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                    <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
                  </div>
                </div>;
              }
            }
            else if(!objectTableValues.find(objectValue => objectValue.Id === value.Id)) {
              if(search === "" || value.Label.toLowerCase().includes(search.toLowerCase())) {
                return <div key={index} className="axeEditValue" onClick={() => this.addValue(JSON.stringify(value))}>
                  <div className="inline-flex">
                    <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                    <Axe CurrentView={currentView} Axe={columnName} Type={columnType} Value={value} View={'Popover'}></Axe>
                  </div>
                </div>;
              }
            }
          })}
        </div>
        {/* Edit Axis Values */}
        {staticData === 'true' && itemType !== 'UserGroup' && columnType !== 'Resource' && columnType !== 'ResourceTable' && columnName !== 'Levels' && 
          <div className="axeEditValuesButton">
            <a target="_self" href={`/Environment/AxisValues?axis=${columnName}`}><Button className="axeEditButton" variant="primary">{Traduction.translate(language, 'edit_values')}</Button></a>
          </div>
        }
      </div>
    );
  }
}

export default PopoverEditObjectTable;