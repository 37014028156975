import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Axe from './Axe';
import DatePickerFilter from './DatePickerFilter';
import LoadingSpinner from './LoadingSpinner';
import PopoverEditFilter from './PopoverEditFilter';

const API = '/WebAppService/GetCardFilters';

class FiltersBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      availableFilters: [],
      currentFilters: [],
      columns: [],
      columnName: null,
      search: [],
      filtersPopup: false,
      isLoading: false
    };
    
    // Data Structure
    this.getAvailableFilters = this.getAvailableFilters.bind(this);
    this.getCurrentViewColumns = this.getCurrentViewColumns.bind(this);
    this.getOperatorIcon = this.getOperatorIcon.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);

    // Actions
    this.addFilter = this.addFilter.bind(this);
    this.addColumnFilter = this.addColumnFilter.bind(this);
    this.deleteFilter = this.deleteFilter.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updateOperator = this.updateOperator.bind(this);
    this.addValue = this.addValue.bind(this);
    this.deleteValue = this.deleteValue.bind(this);
    this.updateBoolean = this.updateBoolean.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updateNumber = this.updateNumber.bind(this);
    this.updateText = this.updateText.bind(this);
    this.saveFilters = this.saveFilters.bind(this);
    this.openFiltersPopup = this.openFiltersPopup.bind(this);

    // Template
    this.templateFiltersPopup = this.templateFiltersPopup.bind(this);
    this.templateColumnFilter = this.templateColumnFilter.bind(this);
    this.templateFilterTypeAxe = this.templateFilterTypeAxe.bind(this);
    this.templateFilterTypeBoolean = this.templateFilterTypeBoolean.bind(this);
    this.templateFilterTypeDate = this.templateFilterTypeDate.bind(this);
    this.templateFilterTypeNumber = this.templateFilterTypeNumber.bind(this);
    this.templateFilterTypeText = this.templateFilterTypeText.bind(this);
    this.closeFiltersPopup = this.closeFiltersPopup.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(itemId !== prevProps.ItemId || itemType !== prevProps.ItemType || blockType !== prevProps.BlockType) {
      this.setState({ itemId, itemType, blockType });
    }
    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  // Get Available Filters from the API
  async getAvailableFilters(login, authId, itemId, itemType, blockType, view) {
    const language = this.state.language;
    
    this.setState({ isLoading: true });
    
    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': view,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetCardFiltersResult;

      if(result) {
        const availableFilters = result.AvailableFilters;
        const currentFilters = result.CurrentFilters;
        let search = [];

        currentFilters.forEach(filter => {
          search.push({ Name: filter.FieldObject.Name, Value: "" });
        });

        this.setState({ availableFilters, currentFilters, search, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  getCurrentViewColumns(currentView) {
    let columns = [];
    
    // Current View Table/Tree/Planning
    if(currentView.ViewType === 0 || currentView.ViewType === 1) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        columns = (currentView.Parameters.find(param => param.Name === 'Columns').Value).split(',');
      }
    }
    // Current View Kanban
    else if(currentView.ViewType === 2) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe1') && currentView.Parameters.find(param => param.Name === 'Axe1').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe1').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Axe2') && currentView.Parameters.find(param => param.Name === 'Axe2').Value !== 'None') {
        columns.push(currentView.Parameters.find(param => param.Name === 'Axe2').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
      }
    }
    // Current View Pivot/Chart/Map
    else if(currentView.ViewType === 3 || currentView.ViewType === 7 || currentView.ViewType === 8) {
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Columns')) {
        currentView.Parameters.find(param => param.Name === 'Columns').Value.split(',').forEach(column => {
          columns.push(column);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Columns').Value);
      }
      if(currentView.Parameters && currentView.Parameters.find(param => param.Name === 'Rows')) {
        currentView.Parameters.find(param => param.Name === 'Rows').Value.split(',').forEach(row => {
          columns.push(row);
        });
        // columns.push(currentView.Parameters.find(param => param.Name === 'Rows').Value);
      }
      if(currentView.Values && currentView.Values.length > 0) {
        currentView.Values.forEach(value => {
          columns.push(value.Field);
        });
      }
    }

    return columns;
  }

  getOperatorIcon(filter, operator) {
    switch(operator.Operator) {
      case 0:
          return <span className={"iconFilterOperatorEqual iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 1:
          return <span className={"iconFilterOperatorNotEqual iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 2:
          return <span className={"iconFilterOperatorStartWith iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 3:
          return <span className={"iconFilterOperatorEndWith iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 4:
          return <span className={"iconFilterOperatorContains iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 5:
          return <span className={"iconFilterOperatorNotContains iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 6:
          return <span className={"iconFilterOperatorGreaterThan iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 7:
          return <span className={"iconFilterOperatorGreaterOrEqualThan iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 8:
          return <span className={"iconFilterOperatorLessThan iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 9:
          return <span className={"iconFilterOperatorLessOrEqualThan iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 10:
          return <span className={"iconFilterOperatorCustom iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 11:
          return <span className={"iconFilterOperatorNull iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      case 12:
          return <span className={"iconFilterOperatorNotNull iconsFilter" + (filter.OperatorObject.Operator === operator.Operator ? "" : " opacity50")}></span>;
      default:
          break;
    }
  }

  convertStringtoBoolean(string) {
    if(string === 'True' || string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  addFilter() {
    const { currentView, availableFilters, currentFilters, search } = this.state;
    const columns = this.getCurrentViewColumns(currentView);
    const length = currentFilters.length;
    let newFilter, viewFilterId = -1;

    // Get Minimum View Filter Id
    currentFilters.forEach(filter => {
      if(filter.ViewFilterId <= viewFilterId) {
        viewFilterId = filter.ViewFilterId - 1;
      }
    });

    // Get first available Filter corresponding to CurrentView Columns
    if(availableFilters.find(filter => columns.includes(filter.FieldObject.Name))) {
      newFilter = availableFilters.find(filter => columns.includes(filter.FieldObject.Name));
    }

    // Add new Filter in Current Filters
    currentFilters.push(JSON.parse(JSON.stringify(newFilter)));
    // currentFilters.push(JSON.parse(JSON.stringify(availableFilters[0])));

    // Update ViewFilterId
    currentFilters[length].ViewFilterId = viewFilterId;

    // Update Search
    search.push({ Name: newFilter.FieldObject.Name, Value: "" });
    // search.push({ Name: availableFilters[0].FieldObject.Name, Value: "" });

    this.setState({ currentFilters, search });
  }

  addColumnFilter(columnName) {
    const availableFilters = this.state.availableFilters;
    const currentFilters = this.state.currentFilters;
    const search = this.state.search;
    const length = currentFilters.length;
    let newFilter, viewFilterId = -1;

    // Get Minimum View Filter Id
    currentFilters.forEach(filter => {
      if(filter.ViewFilterId <= viewFilterId) {
        viewFilterId = filter.ViewFilterId - 1;
      }
    });

    // Find Filter corresponding to ColumnName in Available Filters
    if(availableFilters.find(filter => filter.FieldObject.Name === columnName)) {
      newFilter = availableFilters.find(filter => filter.FieldObject.Name === columnName);
    }

    // Add new Filter in Current Filters
    currentFilters.push(JSON.parse(JSON.stringify(newFilter)));

    // Update ViewFilterId
    currentFilters[length].ViewFilterId = viewFilterId;

    // Update Search
    search.push({ Name: newFilter.FieldObject.Name, Value: "" });

    this.setState({ currentFilters, search });
  }

  deleteFilter(filter) {
    const currentFilters = this.state.currentFilters;
    const search = this.state.search;
    let indexToDelete = -1;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      indexToDelete = currentFilters.findIndex(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId);
    }

    // Delete Current Filter & Search
    if(indexToDelete >= 0) {
      currentFilters.splice(indexToDelete, 1);
      search.splice(indexToDelete, 1);
    }

    this.setState({ currentFilters, search });
  }

  updateFilter(oldFilter, newFilter) {
    const availableFilters = this.state.availableFilters;
    const currentFilters = this.state.currentFilters;
    const search = this.state.search;
    let currentFilter;
    let indexToUpdate = -1;
    let viewFilterId = -1;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === oldFilter.ViewFilterId)) {
      indexToUpdate = currentFilters.findIndex(currentFilter => currentFilter.ViewFilterId === oldFilter.ViewFilterId);
    }

    // Get Minimum View Filter Id
    currentFilters.forEach(filter => {
      if(filter.ViewFilterId <= viewFilterId) {
        viewFilterId = filter.ViewFilterId - 1;
      }
    });

    // Update Current Filter
    if(indexToUpdate >= 0 && availableFilters.find(availableFilter => availableFilter.FieldObject.Name === newFilter.FieldObject.Name)) {
      currentFilter = JSON.parse(JSON.stringify(availableFilters.find(availableFilter => availableFilter.FieldObject.Name === newFilter.FieldObject.Name)));

      // Update Current Filter
      currentFilters[indexToUpdate] = currentFilter;
      currentFilters[indexToUpdate].ViewFilterId = viewFilterId;
      
      // Reset Search
      search[indexToUpdate] = { Name: newFilter.FieldObject.Name, Value: "" };
    }

    this.setState({ currentFilters, search });
  }

  updateOperator(filter, newOperator) {
    const currentFilters = this.state.currentFilters;
    let indexToUpdate = -1;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      indexToUpdate = currentFilters.findIndex(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId);
    }

    // Update Current Filter
    if(indexToUpdate >= 0 && currentFilters[indexToUpdate].AvailableOperators.find(operator => operator.Operator === newOperator.Operator)) {
      currentFilters[indexToUpdate].OperatorObject = currentFilters[indexToUpdate].AvailableOperators.find(operator => operator.Operator === newOperator.Operator);
    }

    this.setState({ currentFilters });
  }

  addValue(filter, value) {
    const currentFilters = this.state.currentFilters;

    // Push new Value inside CurrentValues
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      if(filter.Type === 'Date') {
        currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).CurrentValues = [value];
      }
      else {
        currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).CurrentValues.push(value);
      }
    }

    this.setState({ currentFilters });
  }

  deleteValue(filter, newValue) {
    const currentFilters = this.state.currentFilters;
    let currentFilter, indexToDelete = -1;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      currentFilter = currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId);
    }

    // Find index of the Value to delete
    if(currentFilter.CurrentValues.find(value => value.Id === newValue.Id)) {
      indexToDelete = currentFilter.CurrentValues.findIndex(value => value.Id === newValue.Id);
    }

    // Remove Value in the List
    if(indexToDelete >= 0) {
      currentFilter.CurrentValues.splice(indexToDelete, 1);
    }

    this.setState({ currentFilters });
  }

  updateBoolean(filter, value) {
    const currentFilters = this.state.currentFilters;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).Items = value;
    }

    this.setState({ currentFilters });
  }

  updateDate(filter, date) {
    const currentFilters = this.state.currentFilters;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).Items = date;
    }

    this.setState({ currentFilters });
  }

  updateNumber(filter, event) {
    const currentFilters = this.state.currentFilters;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).Items = event.target.value;
    }

    this.setState({ currentFilters });
  }

  updateText(filter, event) {
    const currentFilters = this.state.currentFilters;

    // Find Current Filter
    if(currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId)) {
      currentFilters.find(currentFilter => currentFilter.ViewFilterId === filter.ViewFilterId).Items = event.target.value;
    }

    this.setState({ currentFilters });
  }

  searchValue(event, filter) {
    const search = this.state.search;
    const value = event.target.value;

    if(search.find(item => item.Name === filter.FieldObject.Name)) {
      search.find(item => item.Name === filter.FieldObject.Name).Value = value;
    }

    this.setState({ search });
  }

  saveFilters() {
    const currentView = this.state.currentView;
    const currentFilters = this.state.currentFilters;
    let items;

    // Clear Current View Filters
    currentView.Filters = [];

    // Update Current View with Current Filters
    currentFilters.forEach(filter => {
      if(filter.CurrentValues.length > 0) {
        items = filter.CurrentValues.map(value => value.Id).join(',');
      }
      else {
        items = filter.Items;
      }

      currentView.Filters.push({ Field: filter.FieldObject.Name, Operator: filter.OperatorObject.OperatorName, Items: items, ViewFilterId: filter.ViewFilterId, ViewId: currentView.ViewId });
    });

    // Call the event from the Parent component through the props with updated Current View
    this.props.onFiltersChange(currentView);

    // Close Popup
    this.setState({ filtersPopup: false });
  }

  async openFiltersPopup(columnName) {
    const { login, authId, itemId, itemType, blockType, currentView } = this.state;

    this.setState({ filtersPopup: true });
    
    // Get View Available Filters
    await this.getAvailableFilters(login, authId, itemId, itemType, blockType, currentView);

    // Add Filter on specific column
    if(columnName) {
      this.addColumnFilter(columnName);
    }
  }

  templateFiltersPopup() {
    const { language, availableFilters, currentFilters, isLoading } = this.state;

    return (<div className="popupFiltersBlock">
      {/* Loading Spinner */}
      {isLoading && <div className="center mt30 mb20">
        <span className=""><LoadingSpinner></LoadingSpinner></span>
        <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
      </div>}
      {/* Title */}
      {!isLoading && <div className="flex align-items-center">
        {/* Label */}
        <div className="filtersBlockLabel">{Traduction.translate(language, 'filters')}</div>
        {/* Add Filter */}
        <div className="cursor" onClick={() => this.addFilter()}>
          <span className="iconButtonPlusGreen iconsFilter"></span>
          <span className="addFilterLabel">{Traduction.translate(language, 'add_filter')}</span>
        </div>
      </div>}
      {/* Filters */}
      {!isLoading && <div className="filtersBlockContent scrollbar-miniblock">
        {currentFilters.map((filter, index) => {
          return (<div key={index} className="filtersColumn">{this.templateColumnFilter(filter)}</div>)
        })}
      </div>}
      {/* Button Validate or Cancel */}
      {!isLoading && <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.saveFilters()}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => this.closeFiltersPopup()}>{Traduction.translate(language, 'cancel')}</Button>
      </div>}
    </div>);
  }

  templateColumnFilter(filter) {
    const { language, itemId, itemType, currentView, availableFilters, currentFilters, search } = this.state;
    let value = "";

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditFilter ItemId={itemId} ItemType={itemType} Display={'Filter'} CurrentView={currentView} AvailableFilters={availableFilters} CurrentFilters={currentFilters} Filter={filter} onFilterEdit={this.updateFilter}></PopoverEditFilter>
        </Popover.Content>
      </Popover>
    );

    // Search
    if(search.find(item => item.Name === filter.FieldObject.Name)) {
      value = search.find(item => item.Name === filter.FieldObject.Name).Value;
    }

    return (<div className="flex flex-column width100p">
      <div className="filtersColumnTitle">
        {/* Delete Filter */}
        <span className="iconRemove iconsPopover mr10 cursor" onClick={() => this.deleteFilter(filter)}/>
        {/* Filter Select */}
        <OverlayTrigger trigger="click" rootClose placement="bottom-start" overlay={popover}>
          <div className="filtersColumnName">
            <div className="filtersColumnLabel">{filter.FieldObject.Label}</div>
            <div className="flex flex-end"><span className="iconChevronDownGrey iconsPopover"></span></div>
          </div>
        </OverlayTrigger>
      </div>
      {/* Operators */}
      <div className="filtersOperators">
        {filter.AvailableOperators.map((operator, index) => {
          return <div key={index} className="filtersOperator" onClick={() => this.updateOperator(filter, operator)}>
            {['top'].map(placement => (
              <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{operator.OperatorLabel}</strong></Tooltip>}>
                {this.getOperatorIcon(filter, operator)}
              </OverlayTrigger>
            ))}
          </div>
        })}
      </div>
      {/* Search for Axes */}
      {(filter.Type === 'Axe' || filter.Type === 'AxisTable' || filter.Type === 'Resource' || filter.Type === 'ResourcesTable' || filter.Type === 'Location') && 
        <div className="filtersSearchValues">
          <Form.Group className="searchBlock">
            <Form.Control type="text" id="searchFilters" name="search" value={value} placeholder={Traduction.translate(language, 'search')} onChange={(e) => this.searchValue(e, filter)} />
          </Form.Group>
        </div>
      }
      {/* Filter Values */}
      {(filter.Type === 'Axe' || filter.Type === 'AxisTable' || filter.Type === 'Resource' || filter.Type === 'ResourcesTable' || filter.Type === 'Location' || filter.Field === 'Timeline' || filter.Field === 'Meteo' || filter.Field === 'Trend') && this.templateFilterTypeAxe(filter)}
      {filter.Type === 'Boolean' && this.templateFilterTypeBoolean(filter)}
      {filter.Type === 'Date' && this.templateFilterTypeDate(filter)}
      {(filter.Type === 'HTML' || filter.Type === 'Text') && this.templateFilterTypeText(filter)}
      {filter.Type === 'Number' && filter.Field !== 'Meteo' && filter.Field !== 'Trend' && this.templateFilterTypeNumber(filter)}
      {filter.Type === 'Rating' && this.templateFilterRating(filter)}
    </div>);
  }

  templateFilterTypeAxe(filter) {
    const currentView = this.state.currentView;
    const search = this.state.search;
    let searchValue;

    if(search.find(item => item.Name === filter.FieldObject.Name)) {
      searchValue = search.find(item => item.Name === filter.FieldObject.Name).Value;
    }

    if(filter.OperatorObject.Operator !== 11 && filter.OperatorObject.Operator !== 12) {
      return (
        <div className="filtersAxeValues">
          {/* Current Values */}
          {filter.CurrentValues && filter.CurrentValues.map((value, index) => {
            return <div key={index} className="filtersAxeValue" onClick={() => this.deleteValue(filter, value)}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Value={value} View={'Popover'}></Axe>
            </div>;
          })}
          {/* Border */}
          {/* <div className="popoverBorder"></div> */}
          {/* Other Values */}
          {filter.AvailableValues && filter.AvailableValues.map((value, index) => {
            if(!filter.CurrentValues || !filter.CurrentValues.find(objectValue => objectValue.Id === value.Id)) {
              // Check if Value contains Search
              if(value.Label.toLowerCase().includes(searchValue.toLowerCase())) {
                return <div key={index} className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, value)}>
                  <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                  <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Value={value} View={'Popover'}></Axe>
                </div>;
              }
            }
          })}
        </div>
      );
    }
    else {
      return null;
    }
  }

  templateFilterTypeBoolean(filter) {
    const language = this.state.language;

    return <div className="filtersBooleanValues">
      <div className="filtersBooleanValue" onClick={() => this.updateBoolean(filter, "true")}>
        <Form.Check id="" className="checkboxObjectTable" type="radio" label="" checked={this.convertStringtoBoolean(filter.Items)} readOnly/>
        <span className="iconCheck iconsPopover"></span>
        <span className="fs12 ml10">{Traduction.translate(language, 'true')}</span>
      </div>
      <div className="filtersBooleanValue" onClick={() => this.updateBoolean(filter, "false")}>
        <Form.Check id="" className="checkboxObjectTable" type="radio" label="" checked={!this.convertStringtoBoolean(filter.Items)} readOnly/>
        <span className="iconCircleGrey iconsPopover"></span>
        <span className="fs12 ml10">{Traduction.translate(language, 'false')}</span>
      </div>
    </div>;
  }

  templateFilterTypeDate(filter) {
    if(filter.OperatorObject.OperatorName === 'custom') {
      return (
        <div className="flex flex-column">
          {/* Current Values */}
          {filter.CurrentValues && filter.CurrentValues.map((value, index) => {
            return <div key={index} className="cursor" onClick={() => this.deleteValue(filter, value)}>
              <div className="filterTypeDate">{value.Label}</div>
              {/* <Axe Axe={filter.FieldObject.Name} Value={value.Label} View={'Popover'}></Axe> */}
            </div>
          })}
          {/* Other Values */}
          {filter.AvailableValues && filter.AvailableValues.map((value, index) => {
            if(!filter.CurrentValues || !filter.CurrentValues.find(objectValue => objectValue.Id === value.Id)) {
              return <div key={index} className="cursor opacity50" onClick={() => this.addValue(filter, value)}>
                <div className="filterTypeDate">{value.Label}</div>
                {/* <Axe Axe={filter.FieldObject.Name} Value={value.Label} View={'Popover'}></Axe> */}
              </div>
            }
          })}
        </div>
      );
    }
    else if(filter.OperatorObject.Operator !== 11 && filter.OperatorObject.Operator !== 12) {
      return (
        <DatePickerFilter Filter={filter} Editable={true} onDateChange={this.updateDate}></DatePickerFilter>
      );
    }
    else {
      return null;
    }
  }

  templateFilterTypeNumber(filter) {
    const language = this.state.language;

    return (
      <Form.Group className="filterTypeString">
        <Form.Control id="" className="filterTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={filter.Items} onChange={(e) => this.updateNumber(filter, e)} />
      </Form.Group>
    );
  }

  templateFilterRating(filter) {
    const currentView = this.state.currentView;

    if(filter.OperatorObject.Operator !== 11 && filter.OperatorObject.Operator !== 12) {
      return (
        <div className="filtersAxeValues">
          {/* Value 0 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 0) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 0))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 0).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 0) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 0))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 0).Label} View={'Popover'}></Axe>
            </div>
          }
          {/* Value 1 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 1) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 1))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 1).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 1) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 1))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 1).Label} View={'Popover'}></Axe>
            </div>
          }
          {/* Value 2 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 2) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 2))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 2).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 2) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 2))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 2).Label} View={'Popover'}></Axe>
            </div>
          }
          {/* Value 3 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 3) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 3))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 3).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 3) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 3))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 3).Label} View={'Popover'}></Axe>
            </div>
          }
          {/* Value 4 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 4) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 4))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 4).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 4) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 4))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 4).Label} View={'Popover'}></Axe>
            </div>
          }
          {/* Value 5 */}
          {filter.CurrentValues && filter.CurrentValues.find(objectValue => objectValue.Id === 5) && 
            <div className="filtersAxeValue" onClick={() => this.deleteValue(filter, filter.CurrentValues.find(objectValue => objectValue.Id === 5))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.Field} Type={filter.Type} Value={filter.CurrentValues.find(objectValue => objectValue.Id === 5).Label} View={'Popover'}></Axe>
            </div>
          }
          {filter.AvailableValues && !filter.CurrentValues.find(objectValue => objectValue.Id === 5) && 
            <div className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, filter.AvailableValues.find(objectValue => objectValue.Id === 5))}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
              <Axe CurrentView={currentView} Axe={filter.FieldObject.Name} Type={filter.Type} Value={filter.AvailableValues.find(objectValue => objectValue.Id === 5).Label} View={'Popover'}></Axe>
            </div>
          }
  
          {/* Current Values */}
          {/* {filter.CurrentValues && filter.CurrentValues.map((value, index) => {
            return <div key={index} className="filtersAxeValue" onClick={() => this.deleteValue(filter, value)}>
              <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={true} readOnly/>
              <Axe Axe={filter.FieldObject.Name} Type={filter.Type} Value={value.Label} View={'Popover'}></Axe>
            </div>;
          })} */}
          {/* Other Values */}
          {/* {filter.AvailableValues && filter.AvailableValues.map((value, index) => {
            if(!filter.CurrentValues || !filter.CurrentValues.find(objectValue => objectValue.Id === value.Id)) {
              return <div key={index} className="filtersAxeValue opacity50" onClick={() => this.addValue(filter, value)}>
                <Form.Check id="" className="checkboxObjectTable" type="checkbox" label="" checked={false} readOnly/>
                <Axe Axe={filter.FieldObject.Name} Type={filter.Type} Value={value.Label} View={'Popover'}></Axe>
              </div>;
            }
          })} */}
        </div>
      );
    }
    else {
      return null;
    }
  }

  templateFilterTypeText(filter) {
    const language = this.state.language;

    if(filter.OperatorObject.Operator !== 11 && filter.OperatorObject.Operator !== 12) {
      return (
        <Form.Group className="filterTypeString">
          <Form.Control id="" className="filterTypeStringValue" as="textarea" rows="1" placeholder={Traduction.translate(language, 'enter_value')} value={filter.Items} onChange={(e) => this.updateText(filter, e)} />
        </Form.Group>
      );
    }
    else {
      return null;
    }
  }

  closeFiltersPopup() {
    this.setState({ filtersPopup: false });
  }
  
  render() {
    const { language, currentView, currentFilters, filtersPopup } = this.state;
    let label;

    // Label
    if(currentView.Filters && currentView.Filters.length === 0) {
      label = Traduction.translate(language, 'filter');
    }
    else if(currentView.Filters && currentView.Filters.length === 1) {
      label = currentView.Filters.length + " " + Traduction.translate(language, 'filter');
    }
    else if(currentView.Filters && currentView.Filters.length > 1) {
      label = currentView.Filters.length + " " + Traduction.translate(language, 'filters');
    }

    return (
      <div className="">
        {/* Popup Filters */}
        {filtersPopup && <div className="filtersBlockPopup">
          <div className="filtersBlockInnerPopup">{this.templateFiltersPopup()}</div>
        </div>}

        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{label}</strong></Tooltip>}>
            <div className={(currentView.Filters && currentView.Filters.length === 0 ? "filtersBlock" : "filtersBlockActive") + " cursor"} onClick={() => this.openFiltersPopup()}>
              <span className="iconFiltersBlue iconsFilter"/>
              <span className="fs12 bold ml10">{label}</span>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersBlock;