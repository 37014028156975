import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class RiskMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      applicationParameters: [],
      impact: [],
      probability: [],
      rows: [],
      matrixData: []
    };

    // Data Structure
    this.buildMatrixData = this.buildMatrixData.bind(this);
    this.getCellData = this.getCellData.bind(this);

    // Actions
    this.displayMatrix = this.displayMatrix.bind(this);
    this.refreshMatrix = this.refreshMatrix.bind(this);

    // Template
    this.templateMatrix43 = this.templateMatrix43.bind(this);
    this.templateMatrix55 = this.templateMatrix55.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const applicationParameters = this.props.ApplicationParameters;
    const impact = this.props.Impact;
    const probability = this.props.Probability;
    const rows = this.props.Rows;

    // Build Risks Matrix
    const matrixData = this.buildMatrixData(rows);

    // const probability = ['Low', 'Medium', 'High'];
    // const impact = ['Low', 'Medium', 'High', 'Critical'];

    this.setState({ language, applicationParameters, impact, probability, rows, matrixData });
  }

  componentDidUpdate(prevProps) {
    const applicationParameters = this.props.ApplicationParameters;
    const impact = this.props.Impact;
    const probability = this.props.Probability;
    const rows = this.props.Rows;
    
    if(JSON.stringify(this.props.ApplicationParameters) !== JSON.stringify(prevProps.ApplicationParameters)) {
      this.setState({ applicationParameters });
    }
    if(this.props.Impact != prevProps.Impact || this.props.Probability != prevProps.Probability) {
      this.setState({ impact, probability });
    }
    if(JSON.stringify(this.props.Rows) != JSON.stringify(prevProps.Rows)) {
      // Build Risks Matrix
      const matrixData = this.buildMatrixData(rows);
      
      this.setState({ rows, matrixData });
    }
  }

  // Build Matrix Datasource based on Risks Table
  buildMatrixData(rows) {
    let rowData, index, matImpact, matProba;
    let matrixData = [];

    rows.forEach(element => {
      if(element.Cells) {
        if(element.Cells.find(cell => cell.ColumnName === 'Impact')) {
          matImpact = JSON.parse(element.Cells.find(cell => cell.ColumnName === 'Impact').Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === 'Probability')) {
          matProba = JSON.parse(element.Cells.find(cell => cell.ColumnName === 'Probability').Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === 'Index')) {
          index = element.Cells.find(cell => cell.ColumnName === 'Index').Value;
        }

        rowData = { "Impact": matImpact, "Probability": matProba, "Index": index };

        matrixData.push(rowData);
      }
    });

    return matrixData;
  }

  // Build each Cell of Matrix adding Risks with corresponding Impact & Probability
  getCellData(matrixData, impact, probability) {
    const applicationParameters = this.state.applicationParameters;
    let result = [];

    matrixData.map(item => {
      // Matrix 5 * 5
      if(applicationParameters && applicationParameters.find(param => param.Name === "RiskMatrix") && applicationParameters.find(param => param.Name === "RiskMatrix").Value === "5*5") {
        // if(result.length < 4) {
          if(item.Impact.Id === impact && item.Probability.Id === probability) {
            result.push(item.Index);
          }
        // }
      }
      // Matrix 4 * 3
      else {
        // if(result.length < 6) {
          if(item.Impact.Id === impact && item.Probability.Id === probability) {
            result.push(item.Index);
          }
        // }
      }
    });

    return result;
  }

  getImpactLabel(id) {
    const impact = this.state.impact;
    let label;

    if(impact.find(item => item.Id === id)) {
      label = impact.find(item => item.Id === id).Label;
    }

    return label;
  }

  displayMatrix(probability, impact, matrixData, applicationParameters) {
    // Template Matrix 5 * 5
    if(applicationParameters && applicationParameters.find(param => param.Name === "RiskMatrix") && applicationParameters.find(param => param.Name === "RiskMatrix").Value === "5*5") {
      return this.templateMatrix55(probability, impact, matrixData);
    }
    // Template Matrix 4 * 3
    else {
      return this.templateMatrix43(probability, impact, matrixData);
    }
  }

  refreshMatrix(rows) {
    // Build Risks Matrix
    const matrixData = this.buildMatrixData(rows);

    this.setState({ matrixData });
  }

  templateMatrix43(probability, impact, matrixData) {
    const language = this.state.language;

    return <div className="riskMatrix43">
      {/*---------- Ordinate ----------*/}
      <div className="col-md-1">
        <div className="matrixOrdinateTitle43">
          <hr className="matrixHr"/><span className="matrixAxe">{Traduction.translate(language, 'impact')}</span><hr className="matrixHr"/>
          <div className="arrow-right"></div>
        </div>
      </div>
      {/* Impact Critical */}
      <div className="col-md-11">
        <Row className="mr-50 ml-10">
          <div className="col-md-1 maxtrixRow43">
            <div className="matrixOrdinate">{this.getImpactLabel(4)}</div>
          </div>
          <div className="col-md-11">
            {/* Probability Low */}
            <div id="critical-low" className="col-md-3 matrixCell43 border-critical-low">
              {matrixData && this.getCellData(matrixData, 4, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 1).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-critical-low white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 critical-low">+ {this.getCellData(matrixData, 4, 1).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-critical-low white">{item}</span>
                }
              })}
            </div>
            {/* Probability Medium */}
            <div id="critical-medium" className="col-md-3 matrixCell43 border-critical-medium">
              {matrixData && this.getCellData(matrixData, 4, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 2).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-critical-medium white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 critical-medium">+ {this.getCellData(matrixData, 4, 2).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-critical-medium white">{item}</span>
                }
              })}
            </div>
            {/* Probability High */}
            <div id="critical-high" className="col-md-3 matrixCell43 border-critical-high">
              {matrixData && this.getCellData(matrixData, 4, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 3).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-critical-high white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 critical-high">+ {this.getCellData(matrixData, 4, 3).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-critical-high white">{item}</span>
                }
              })}
            </div>
          </div>
        </Row>
        {/* Impact High */}
        <Row className="mr-50 ml-10">
          <div className="col-md-1 maxtrixRow43">
            <div className="matrixOrdinate">{this.getImpactLabel(3)}</div>
          </div>
          <div className="col-md-11">
            {/* Probability Low */}
            <div id="high-low" className="col-md-3 matrixCell43 border-high-low">
              {matrixData && this.getCellData(matrixData, 3, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 1).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-high-low white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 high-low">+ {this.getCellData(matrixData, 3, 1).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-high-low white">{item}</span>
                }
              })}
            </div>
            {/* Probability Medium */}
            <div id="high-medium" className="col-md-3 matrixCell43 border-high-medium">
              {matrixData && this.getCellData(matrixData, 3, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 2).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-high-medium white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 high-medium">+ {this.getCellData(matrixData, 3, 2).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-high-medium white">{item}</span>
                }
              })}
            </div>
            {/* Probability High */}
            <div id="high-high" className="col-md-3 matrixCell43 border-high-high">
              {matrixData && this.getCellData(matrixData, 3, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 3).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-high-high white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 high-high">+ {this.getCellData(matrixData, 3, 3).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-high-high white">{item}</span>
                }
              })}
            </div>
          </div>
        </Row>
        {/* Impact Medium */}
        <Row className="mr-50 ml-10">
          <div className="col-md-1 maxtrixRow43">
            <div className="matrixOrdinate">{this.getImpactLabel(2)}</div>
          </div>
          <div className="col-md-11">
            {/* Probability Low */}
            <div id="medium-low" className="col-md-3 matrixCell43 border-medium-low">
              {matrixData && this.getCellData(matrixData, 2, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 1).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-medium-low white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 medium-low">+ {this.getCellData(matrixData, 2, 1).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-medium-low white">{item}</span>
                }
              })}
            </div>
            {/* Probability Medium */}
            <div id="medium-medium" className="col-md-3 matrixCell43 border-medium-medium">
              {matrixData && this.getCellData(matrixData, 2, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 2).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-medium-medium white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 medium-medium">+ {this.getCellData(matrixData, 2, 2).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-medium-medium white">{item}</span>
                }
              })}
            </div>
            {/* Probability High */}
            <div id="medium-high" className="col-md-3 matrixCell43 border-medium-high">
              {matrixData && this.getCellData(matrixData, 2, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 3).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-medium-high white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 medium-high">+ {this.getCellData(matrixData, 2, 3).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-medium-high white">{item}</span>
                }
              })}
            </div>
          </div>
        </Row>
        {/* Impact Low */}
        <Row className="mr-50 ml-10">
          <div className="col-md-1 maxtrixRow43">
            <div className="matrixOrdinate">{this.getImpactLabel(1)}</div>
          </div>
          <div className="col-md-11">
            {/* Probability Low */}
            <div id="low-low" className="col-md-3 matrixCell43 border-low-low">
              {matrixData && this.getCellData(matrixData, 1, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 1).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-low-low white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 low-low">+ {this.getCellData(matrixData, 1, 1).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-low-low white">{item}</span>
                }
                
              })}
            </div>
            {/* Probability Medium */}
            <div id="low-medium" className="col-md-3 matrixCell43 border-low-medium">
              {matrixData && this.getCellData(matrixData, 1, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 2).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-low-medium white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 low-medium">+ {this.getCellData(matrixData, 1, 2).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-low-medium white">{item}</span>
                }
              })}
            </div>
            {/* Probability High */}
            <div id="low-high" className="col-md-3 matrixCell43 border-low-high">
              {matrixData && this.getCellData(matrixData, 1, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 3).length > 6) {
                  if(index < 5) {
                    return <span key={index} className="matrixResult43 bg-low-high white">{item}</span>
                  }
                  else if(index === 5) {
                    return <span key={index} className="matrixAdditional43 low-high">+ {this.getCellData(matrixData, 1, 3).length - 5}</span>
                  }
                }
                else {
                  return <span key={index} className="matrixResult43 bg-low-high white">{item}</span>
                }
              })}
            </div>
          </div>
        </Row>
        {/*---------- Abscissa ----------*/}
        <Row className="mr-50 ml-10">
          <div className="col-md-1"></div>
          <div className="col-md-11">
            {probability.map((item, index) => {
              return <div key={index} className="col-md-3 matrixAbscissa">{item.Label}</div>
            })}
          </div>
        </Row>
        <Row>
          <div className="col-md-1"></div>
          <div className="col md-11 matrixAbscissaTitle43">
            <hr className="matrixHr"/><span className="matrixAxe">{Traduction.translate(language, 'probability')}</span><hr className="matrixHr"/>
            <div className="arrow-right"></div>
          </div>
        </Row>
      </div>
    </div>;
  }

  templateMatrix55(probability, impact, matrixData) {
    const language = this.state.language;

    return <div className="riskMatrix55">
      {/*---------- Ordinate ----------*/}
      <div className="matrixOrdinate55">
        <div className="matrixOrdinateTitle55">
          <span className="matrixAxe">{Traduction.translate(language, 'impact')}</span>
        </div>
      </div>
      <div className="matrixContent55">
        {/* Impact Critical */}
        <div className="matrixHeight55">
          {/* Label */}
          <div className="matrixOrdinateValues55">
            <div className="matrixOrdinateLabel55">{this.getImpactLabel(4)}</div>
          </div>
          {/* Probability Very Low */}
          <div className="matrixWidth55">
            <div id="critical-verylow" className="matrixCell55 border-critical-verylow">
              {matrixData && this.getCellData(matrixData, 4, 0).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 0).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-critical-verylow white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 critical-verylow">+ {this.getCellData(matrixData, 4, 0).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-critical-verylow white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Low */}
          <div className="matrixWidth55">
            <div id="critical-low" className="matrixCell55 border-critical-low">
              {matrixData && this.getCellData(matrixData, 4, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 1).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-critical-low white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 critical-low">+ {this.getCellData(matrixData, 4, 1).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-critical-low white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Medium */}
          <div className="matrixWidth55">
            <div id="critical-medium" className="matrixCell55 border-critical-medium">
              {matrixData && this.getCellData(matrixData, 4, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 2).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-critical-medium white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 critical-medium">+ {this.getCellData(matrixData, 4, 2).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-critical-medium white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability High */}
          <div className="matrixWidth55">
            <div id="critical-high" className="matrixCell55 border-critical-high">
              {matrixData && this.getCellData(matrixData, 4, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 3).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-critical-high white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 critical-high">+ {this.getCellData(matrixData, 4, 3).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-critical-high white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Critical */}
          <div className="matrixWidth55">
            <div id="critical-critical" className="matrixCell55 border-critical-critical">
              {matrixData && this.getCellData(matrixData, 4, 4).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 4, 4).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-critical-critical white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 critical-critical">+ {this.getCellData(matrixData, 4, 4).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-critical-critical white">{item}</div>
                }
              })}
            </div>
          </div>
        </div>
        {/* Impact High */}
        <div className="matrixHeight55">
          {/* Label */}
          <div className="matrixOrdinateValues55">
            <div className="matrixOrdinateLabel55">{this.getImpactLabel(3)}</div>
          </div>
          {/* Probability Very Low */}
          <div className="matrixWidth55">
            <div id="high-verylow" className="matrixCell55 border-high-verylow">
              {matrixData && this.getCellData(matrixData, 3, 0).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 0).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-high-verylow white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 high-verylow">+ {this.getCellData(matrixData, 3, 0).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-high-verylow white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Low */}
          <div className="matrixWidth55">
            <div id="high-low" className="matrixCell55 border-high-low">
              {matrixData && this.getCellData(matrixData, 3, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 1).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-high-low white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 high-low">+ {this.getCellData(matrixData, 3, 1).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-high-low white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Medium */}
          <div className="matrixWidth55">
            <div id="high-medium" className="matrixCell55 border-high-medium">
              {matrixData && this.getCellData(matrixData, 3, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 2).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-high-medium white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 high-medium">+ {this.getCellData(matrixData, 3, 2).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-high-medium white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability High */}
          <div className="matrixWidth55">
            <div id="high-high" className="matrixCell55 border-high-high">
              {matrixData && this.getCellData(matrixData, 3, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 3).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-high-high white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 high-high">+ {this.getCellData(matrixData, 3, 3).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-high-high white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Critical */}
          <div className="matrixWidth55">
            <div id="high-critical" className="matrixCell55 border-high-critical">
              {matrixData && this.getCellData(matrixData, 3, 4).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 3, 4).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-high-critical white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 high-critical">+ {this.getCellData(matrixData, 3, 4).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-high-critical white">{item}</div>
                }
              })}
            </div>
          </div>
        </div>
        {/* Impact Medium */}
        <div className="matrixHeight55">
          {/* Label */}
          <div className="matrixOrdinateValues55">
            <div className="matrixOrdinateLabel55">{this.getImpactLabel(2)}</div>
          </div>
          {/* Probability Very Low */}
          <div className="matrixWidth55">
            <div id="medium-verylow" className="matrixCell55 border-medium-verylow">
              {matrixData && this.getCellData(matrixData, 2, 0).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 0).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-medium-verylow white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 medium-verylow">+ {this.getCellData(matrixData, 2, 0).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-medium-verylow white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Low */}
          <div className="matrixWidth55">
            <div id="medium-low" className="matrixCell55 border-medium-low">
              {matrixData && this.getCellData(matrixData, 2, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 1).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-medium-low white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 medium-low">+ {this.getCellData(matrixData, 2, 1).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-medium-low white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Medium */}
          <div className="matrixWidth55">
            <div id="medium-medium" className="matrixCell55 border-medium-medium">
              {matrixData && this.getCellData(matrixData, 2, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 2).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-medium-medium white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 medium-medium">+ {this.getCellData(matrixData, 2, 2).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-medium-medium white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability High */}
          <div className="matrixWidth55">
            <div id="medium-high" className="matrixCell55 border-medium-high">
              {matrixData && this.getCellData(matrixData, 2, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 3).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-medium-high white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 medium-high">+ {this.getCellData(matrixData, 2, 3).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-medium-high white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Critical */}
          <div className="matrixWidth55">
            <div id="medium-critical" className="matrixCell55 border-medium-critical">
              {matrixData && this.getCellData(matrixData, 2, 4).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 2, 4).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-medium-critical white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 medium-critical">+ {this.getCellData(matrixData, 2, 4).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-medium-critical white">{item}</div>
                }
              })}
            </div>
          </div>
        </div>
        {/* Impact Low */}
        <div className="matrixHeight55">
          {/* Label */}
          <div className="matrixOrdinateValues55">
            <div className="matrixOrdinateLabel55">{this.getImpactLabel(1)}</div>
          </div>
          {/* Probability Very Low */}
          <div className="matrixWidth55">
            <div id="low-verylow" className="matrixCell55 border-low-verylow">
              {matrixData && this.getCellData(matrixData, 1, 0).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 0).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-low-verylow white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 low-verylow">+ {this.getCellData(matrixData, 1, 0).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-low-verylow white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Low */}
          <div className="matrixWidth55">
            <div id="low-low" className="matrixCell55 border-low-low">
              {matrixData && this.getCellData(matrixData, 1, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 1).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-low-low white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 low-low">+ {this.getCellData(matrixData, 1, 1).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-low-low white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Medium */}
          <div className="matrixWidth55">
            <div id="low-medium" className="matrixCell55 border-low-medium">
              {matrixData && this.getCellData(matrixData, 1, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 2).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-low-medium white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 low-medium">+ {this.getCellData(matrixData, 1, 2).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-low-medium white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability High */}
          <div className="matrixWidth55">
            <div id="low-high" className="matrixCell55 border-low-high">
              {matrixData && this.getCellData(matrixData, 1, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 3).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-low-high white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 low-high">+ {this.getCellData(matrixData, 1, 3).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-low-high white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Critical */}
          <div className="matrixWidth55">
            <div id="low-critical" className="matrixCell55 border-low-critical">
              {matrixData && this.getCellData(matrixData, 1, 4).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 1, 4).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-low-critical white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 low-critical">+ {this.getCellData(matrixData, 1, 4).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-low-critical white">{item}</div>
                }
              })}
            </div>
          </div>
        </div>
        {/* Impact Very Low */}
        <div className="matrixHeight55">
          {/* Label */}
          <div className="matrixOrdinateValues55">
            <div className="matrixOrdinateLabel55">{this.getImpactLabel(0)}</div>
          </div>
          {/* Probability Very Low */}
          <div className="matrixWidth55">
            <div id="verylow-verylow" className="matrixCell55 border-verylow-verylow">
              {matrixData && this.getCellData(matrixData, 0, 0).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 0, 0).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-verylow-verylow white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 verylow-verylow">+ {this.getCellData(matrixData, 0, 0).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-verylow-verylow white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Low */}
          <div className="matrixWidth55">
            <div id="verylow-low" className="matrixCell55 border-verylow-low">
              {matrixData && this.getCellData(matrixData, 0, 1).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 0, 1).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-verylow-low white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 verylow-low">+ {this.getCellData(matrixData, 0, 1).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-verylow-low white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Medium */}
          <div className="matrixWidth55">
            <div id="verylow-medium" className="matrixCell55 border-verylow-medium">
              {matrixData && this.getCellData(matrixData, 0, 2).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 0, 2).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-verylow-medium white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 verylow-medium">+ {this.getCellData(matrixData, 0, 2).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-verylow-medium white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability High */}
          <div className="matrixWidth55">
            <div id="verylow-high" className="matrixCell55 border-verylow-high">
              {matrixData && this.getCellData(matrixData, 0, 3).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 0, 3).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-verylow-high white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 verylow-high">+ {this.getCellData(matrixData, 0, 3).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-verylow-high white">{item}</div>
                }
              })}
            </div>
          </div>
          {/* Probability Critical */}
          <div className="matrixWidth55">
            <div id="verylow-critical" className="matrixCell55 border-verylow-critical">
              {matrixData && this.getCellData(matrixData, 0, 4).map((item, index) => {
                // Display 6 first elements
                if(this.getCellData(matrixData, 0, 4).length > 4) {
                  if(index < 3) {
                    return <div key={index} className="matrixResult55 bg-verylow-critical white">{item}</div>
                  }
                  else if(index === 3) {
                    return <div key={index} className="matrixAdditional55 verylow-critical">+ {this.getCellData(matrixData, 0, 4).length - 3}</div>
                  }
                }
                else {
                  return <div key={index} className="matrixResult55 bg-verylow-critical white">{item}</div>
                }
              })}
            </div>
          </div>
        </div>
        {/*---------- Abscissa ----------*/}
        <div className="row">
          <div className="matrixAbscissaValues55"></div>
          <div className="matrixAbscissaCells55">
            {probability.map((item, index) => {
              return <div key={index} className="matrixAbscissa55">{item.Label}</div>
            })}
          </div>
        </div>
        <div className="row">
          <div className="matrixAbscissaValues55"></div>
          <div className="matrixAbscissaCells55">
            <div className="matrixAbscissaTitle55">
              <span className="matrixAxe">{Traduction.translate(language, 'probability')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    let { language, applicationParameters, impact, probability, rows, matrixData } = this.state;

    return(
      <div className="riskMatrix">{this.displayMatrix(probability, impact, matrixData, applicationParameters)}</div>
    )
  }
}

export default RiskMatrix;