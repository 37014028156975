import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import ButtonsMonth from './ButtonsMonth';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import TableHolidays from './TableHolidays';

const API_info = '/WebAppService/GetCardBlockInformation';
const API_saveHolidays = '/WebAppService/SaveHolidayModifications';

class BlockHolidays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      resourceId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      warnings: 0,
      parents: [],
      month: null,
      year: null,
      tables: [],
      columns: [],
      rows: [],
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);

    // Actions
    this.changeMonth = this.changeMonth.bind(this);
    this.saveHolidays = this.saveHolidays.bind(this);
    this.updateRowsInTable = this.updateRowsInTable.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    this.table = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const resourceId = Authentication.getCookie('resourceId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];

    // Get current Date
    // const date = new Date();
    // const month = (date.getMonth())+1;
    // const year = date.getFullYear();

    // Get Params from Query string url
    let params, month, year;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      month = parseInt(params.month);
      year = parseInt(params.year);
    }

    this.setState({ login, authId, resourceId, language, itemId, itemType, blockType, month, year });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, month, year);

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, month, year);
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // Get current Date
    // const date = new Date();
    // const month = (date.getMonth())+1;
    // const year = date.getFullYear();

    // Get Params from Query string url
    let params, old_params, month, year, old_month, old_year;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);
      month = parseInt(params.month);
      year = parseInt(params.year);
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);
      old_month = parseInt(old_params.month);
      old_year = parseInt(old_params.year);
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });

      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, month, year);
    }
    if(month !== old_month || year !== old_year) {
      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, month, year);

      // Refresh Table
      if(this.table.current) {
        this.table.current.refreshColumns();
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, month, year) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const warnings = blockInfo.Warnings;
        const parents = blockInfo.Parents;

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, warnings, parents, month, year });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, month, year) {
    const language = this.state.language;
    let additionalContext = [];

    if(month && year) {
      additionalContext = [{ "Key": "Month", "Value": month.toString() }, { "Key": "Year", "Value": year.toString() }];
    }

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const tables = blockContent.Tables;
        let columns = [], rows = [];

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Resource")) {
          columns = tables.find(element => element.Level === "Resource").ColumnHeaders;
          rows = tables.find(element => element.Level === "Resource").Rows;
        }

        this.setState({ month, year, tables, columns, rows, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  changeMonth(month, year) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;
    const blockType = this.state.blockType;

    // Redirect to Block Holidays Page with new Query params
    this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?year=${year}&month=${month}`);
  }

  async saveHolidays(resourceId, date, weight) {
    const { login, authId, language } = this.state;

    // Get Day, Month and Year of the Holiday
    const holiday = new Date(date);
    const day = holiday.getDate();
    const month = holiday.getMonth() + 1;
    const year = holiday.getFullYear();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify([{
        'Year': year,
        'Month': month,
        'Day': day,
        'ResourceId': parseInt(resourceId),
        'Weight': parseFloat(weight)
      }])
    };
    
    try{
      const response = await fetch(API_saveHolidays, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.SaveHolidayModificationsResult;

      // Check request response
      if(result) {
        // Update Rows in Components DataSource
        if(this.table.current) {
          this.table.current.updateDatasource(resourceId, date, weight);
        }

        // Update Table with Holiday Modification
        this.updateRowsInTable(resourceId, date, weight);
      }
      else {
        this.updateErrors(Traduction.translate(language, 'modification_impossible'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  updateRowsInTable(resourceId, date, value) {
    let rows = this.state.rows;

    // Check if we find Row to apply Modification
    const currentRow = rows.find(row => row.Cells.find(cell => cell.ColumnName === 'Resource_ID').Value === resourceId);

    if(currentRow) {
      // Check if we find Cell to apply Modification
      const currentCell = currentRow.Cells.find(cell => cell.ColumnName === date);

      // Update Row Cell with NewValue
      if(currentCell) {
        currentCell.Value = value;
      }
    }

    this.setState({ rows });
  }

  exportCSV() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Table component
    if(this.table.current) {
      this.table.current.exportXLSX();
    }
  }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }

  render() {
    const { resourceId, language, itemId, itemType, itemTitle, blockType, blockInfo, editable, guestLicence, favorite, warnings, parents, month, year, columns, rows, isLoading, errors } = this.state;
    
    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'holidays')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBodyColumn">
            {/* Buttons & Filters */}
            <div className="blockFilters row">
              {/* Buttons Month */}
              <div className="filtersHolidays">
                <ButtonsMonth Month={month} Year={year} onMonthChange={this.changeMonth}></ButtonsMonth>
              </div>
              <div className="filters">
                {/* Legend */}
                <div className="holidaysLegend">
                  {/* Open Day */}
                  <span className="iconsCard iconCircle bg-grey"></span>
                  <span className="ml10">{Traduction.translate(language, 'open_day')}</span>
                  {/* Week end */}
                  <span className="iconsCard iconFullCircle bg-smoke-grey ml20"></span>
                  <span className="ml10">{Traduction.translate(language, 'week_end')}</span>
                  {/* Bank Holiday */}
                  <span className="iconsCard iconFullCircle bg-dark-grey ml20"></span>
                  <span className="ml10">{Traduction.translate(language, 'bank_holiday')}</span>
                </div>
                
                {/* Export */}
                <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onErrorsUpdate={this.updateErrors}></FiltersExport>
              </div>
            </div>

            {/* Card Block Content */}
            <div className="blockContent">
              {/* Errors */}
              {errors.length > 0 && 
                <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
              }

              {/* Loading Spinner */}
              {isLoading && <div className="center mt30 mb20">
                <span className=""><LoadingSpinner></LoadingSpinner></span>
                <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
              </div>}

              {/* Holidays Table */}
              {columns && rows && <TableHolidays ref={this.table} ResourceId={resourceId} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} Columns={columns} Rows={rows} Month={month} Year={year} onTableUpdate={this.saveHolidays}></TableHolidays>}
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockHolidays;