import React, {Component} from 'react';
import { Button, Form, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import PopoverEditMapping from './PopoverEditMapping';
import MiniTable from './MiniTable';
import MiniTree from './MiniTree';

const API_check_file = '/WebAppService/CheckFile';
const API_assess_file = '/WebAppService/AssessFile';
const API_import_file = '/WebAppService/ImportAll';
const API_job_status = '/WebAppService/GetJobStatus';

class FiltersImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      blockType: null,
      currentView: {},
      base64: "",
      fileImport: {},
      blobFileImport: [],
      mappings: [],
      fields: [],
      job: {},
      importView: {},
      tables: [],
      importFilePopup: false,
      mappingDataPopup: false,
      selectFilePopup: false,
      jobStatusPopup: false,
      search: '',
      isLoading: false,
      errors: []
    };

    // Actions
    this.checkFile = this.checkFile.bind(this);
    this.assessFile = this.assessFile.bind(this);
    this.importFile = this.importFile.bind(this);
    this.getJobStatus = this.getJobStatus.bind(this);

    this.triggerUploadFile = this.triggerUploadFile.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.dataURItoBlob = this.dataURItoBlob.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.changeFieldMapping = this.changeFieldMapping.bind(this);
    // this.excludeColumn = this.excludeColumn.bind(this);
    this.validateMapping = this.validateMapping.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.validateImport = this.validateImport.bind(this);
    this.sleep = this.sleep.bind(this);
    this.cleanFile = this.cleanFile.bind(this);

    // Template
    this.templateSelectFile = this.templateSelectFile.bind(this);
    this.templateMappingData = this.templateMappingData.bind(this);
    this.templateImportFile = this.templateImportFile.bind(this);
    this.templateJobStatus = this.templateJobStatus.bind(this);
    this.templatePopoverMapping = this.templatePopoverMapping.bind(this);
    this.closeSelectFilePopup = this.closeSelectFilePopup.bind(this);
    this.closeMappingDataPopup = this.closeMappingDataPopup.bind(this);
    this.closeImportFilePopup = this.closeImportFilePopup.bind(this);
    this.closeJobStatusPopup = this.closeJobStatusPopup.bind(this);

    // Reference
    this.minitable = React.createRef();
    this.minitree = React.createRef();
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;
    
    this.setState({ login, authId, language, itemId, itemType, blockType, currentView });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.ItemId;
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const currentView = this.props.CurrentView;

    if(this.props.ItemId !== prevProps.ItemId || this.props.ItemType !== prevProps.ItemType) {
      this.setState({ itemId, itemType });
    }
    if(JSON.stringify(this.props.CurrentView) != JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
  }

  async checkFile(file) {
    const { login, authId, language, itemId, itemType, currentView } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'File': file
      })
    };

    try{
      const response = await fetch(API_check_file, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.CheckFileResult;
      let errors = [];

      if(result.IsValid === true) {
        const mappings = result.Mappings;
        const fields = result.AvailableFields;

        this.setState({ mappings, fields, mappingDataPopup: true });
      }
      else {
        errors.push(result.Errors);
      }

      this.setState({ errors });

    } catch(error) {
      console.log(error);
    }
  }
  
  async assessFile(mappings, file) {
    const { login, authId, language, itemId, itemType, currentView } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'Mappings': mappings,
        'File': file
      })
    };

    try{
      const response = await fetch(API_assess_file, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.AssessFileResult;
      let errors = [];

      if(result) {
        if(result.IsValid === true) {
          const tables = result.Tables[0];
          const importView = result.ImportView;
  
          this.setState({ tables, importView, selectFilePopup: false, mappingDataPopup: false, importFilePopup: true, isLoading: false, errors });
        }
        else {
          errors.push(result.Errors);
  
          this.setState({ isLoading: false, errors });
        }
      }
      else {
        errors.push(Traduction.translate(language, 'import_impossible'));

        this.setState({ errors });
      }

    } catch(error) {
      console.log(error);
    }
  }

  async importFile(mappings, file) {
    const { login, authId, language, itemId, itemType, blockType, currentView } = this.state;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'View': currentView,
        'Mappings': mappings,
        'File': file
      })
    };

    try{
      const response = await fetch(API_import_file, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.ImportAllResult;
      let errors = [];

      if(result) {
        let job, count = 0;

        this.setState({ job: result, importFilePopup: false, jobStatusPopup: true, isLoading: false });

        // Try to get Job Status while not finished
        while(count < 150) {
          job = await this.getJobStatus(result);

          if(job.Completion === 100 || job.Status === 'Finished') {
            break;
          }
          else {
            await this.sleep(2000);
          }

          count ++;

          this.setState({ job });
        }

        if(job.ResultImport) {
          if(job.ResultImport.IsValid === true) {
            // Close Popup
            this.closeJobStatusPopup();

            // Get Card Data
            await this.props.onDataImport(login, authId, itemId, itemType, blockType, currentView);
          }
          else {
            errors.push(job.Errors);
    
            this.setState({ errors });
          }
        }
        else {
          errors.push(Traduction.translate(language, 'server_not_responding'));

          this.setState({ errors });
        }

      }
      else {
        errors.push(Traduction.translate(language, 'import_impossible'));

        this.setState({ errors });
      }

    } catch(error) {
      console.log(error);
    }
  }
  
  async getJobStatus(job) {
    const { login, authId, language, itemId, itemType, blockType, currentView } = this.state;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'JobId': job.JobId
      })
    };

    try{
      const response = await fetch(API_job_status, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetJobStatusResult;
      let errors = this.state.errors;

      if(result) {
        return result;
      }
      else {
        errors.push(Traduction.translate(language, 'job_error'));

        this.setState({ errors });
      }

    } catch(error) {
      console.log(error);
    }
  }

  triggerUploadFile() {
    if(document.getElementById("uploadFile")) {
      document.getElementById("uploadFile").click();
    }
  }

  async handleChangeFile(file) {
    this.setState({ isLoading: true });

    await this.getBase64(file).then(result => {
      this.setState({ blobFileImport: this.dataURItoBlob(result), fileImport: file });
      this.validateFile(this.dataURItoBlob(result))
    });
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      if(file) {
        reader.readAsDataURL(file);
      }
      
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  dataURItoBlob(dataURI) {
    let byteString;
    let array = [];

    // Convert base64/URLEncoded data component to raw binary data held in a string
    if(dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = window.atob(dataURI.split(',')[1]);
    }

    // Separate out the mime component
    // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);

    for(let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    
    for(var i = 0; i < byteString.length; i++) {
      array[i] = ia[i];
    }
    
    return array;
  }

  async validateFile(blobFileImport) {
    // const blobFileImport = this.state.blobFileImport;

    if(blobFileImport.length !== 0) {
      // Check Imported File
      await this.checkFile(blobFileImport);

      this.setState({ errorImport: false, isLoading: false });
    }
    else {
      this.setState({ selectFilePopup: true, errorImport: true, isLoading: false });
    }
  }

  changeFieldMapping(mapping, value) {
    const mappings = this.state.mappings;
    // const fields = this.state.fields;

    if(mappings.find(item => item.Header === mapping.Header)) {
      mappings.find(item => item.Header === mapping.Header).Field = value;
    }

    // if(fields.find(field => field.Name === value)) {
    //   mappings[index].Field = fields.find(field => field.Name === value);
    // }
    
    this.setState({ mappings });
  }

  // excludeColumn(index) {
  //   let mappings = this.state.mappings;

  //   if(mappings[index].exclude) {
  //     mappings[index].exclude = false;
  //   } else {
  //     mappings[index].exclude = true;
  //   }

  //   this.setState({mappings});
  // }

  validateMapping() {
    const mappings = this.state.mappings;
    const blobFileImport = this.state.blobFileImport;
    let mappingFinal = [];

    mappings.forEach(item => {
      if(!item.exclude) {
        mappingFinal.push(item);
      }
    })

    // Analyse & Preview File
    this.assessFile(mappingFinal, blobFileImport);
  }

  exportXLSX() {
    this.setState({ isLoading: true });

    if(this.minitable.current) {
      this.minitable.current.exportXLSX();
    }
    if(this.minitree.current) {
      this.minitree.current.exportXLSX();
    }

    // Call the event from the Parent component through the props
    // this.props.onXLSXExport();

    this.setState({ isLoading: false });
  }

  searchItem(event) {
    const search = event.target.value;

    // Search Items in Table component
    if(this.minitable.current) {
      this.minitable.current.searchItem(search);
    }
    // Search Items in Tree component
    if(this.minitree.current) {
      this.minitree.current.searchItem(search);
    }

    this.setState({ search });
  }

  validateImport() {
    const mappings = this.state.mappings;
    const blobFileImport = this.state.blobFileImport;

    // Import File
    this.importFile(mappings, blobFileImport);
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  cleanFile() {
    this.setState({ fileImport: {}, blobFileImport: [] });
  }

  templateSelectFile() {
    const { language, isLoading, errors } = this.state;

    return (<div className="popupImportData flex flex-column">
      {/* Label */}
      <div className="inline-flex align-items-center mb10">  
        <div className="flex-start width100p"><span className="importDataLabel">{Traduction.translate(language, 'import_data')}</span></div>
        <div className="flex-end"><span className="iconClear icons cursor" onClick={() => { this.closeSelectFilePopup(); this.setState({ fileImport: {}, blobFileImport: [] }) }}></span></div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* File Reader */}
      {!isLoading && <div className="importFileReader">
        <input id="uploadFile" className="inputFileReader hidden" type="file" accept=".xlsx" onChange={(e) => this.handleChangeFile(e.target.files[0])} />
        <Button id="uploadTrigger" className="fs12 bold brd-radius" variant="primary" onClick={() => this.triggerUploadFile()}>{Traduction.translate(language, 'choose_file')}</Button>
      </div>}
      
      {/* <ReactFileReader ref={this.fileInput} fileTypes={[".xlsx"]} base64={true} multipleFiles={false} handleFiles={this.dataURItoBlob}>
        <button className='btn'>Upload</button>
      </ReactFileReader> */}

      {/* Spinner Loading */}
      {isLoading && <div className="spinnerSelectFile"><LoadingSpinner></LoadingSpinner></div>}

      {/* Help */}
      <div className="flex flex-end align-items-center">
        <a target="_self" href={"https://help.tabsters.fr/support/" + Traduction.translate(language, 'locale') + "/import"} className="importHelp">
          <span className="fs12">{Traduction.translate(language, 'need_help')}</span>
        </a>
      </div>

      {/* Button Validate or Cancel */}
      {/* <div className="flex flex-end align-items-center">
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.closeSelectFilePopup(); this.validateFile() }}>{Traduction.translate(language, 'validate')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.closeSelectFilePopup(); this.setState({ fileImport: {}, blobFileImport: [] }) }}>{Traduction.translate(language, 'cancel')}</Button>
      </div> */}
    </div>);
  }

  templateMappingData() {
    const { language, fileImport, mappings, fields, isLoading, errors } = this.state;

    return (<div className="popupImportData flex flex-column">
      {/* Label */}
      <div className="importDataLabel">
        <div className="importLabelBlue">{Traduction.translate(language, 'file_mapping')}</div>
        <div className="importLabelYellow ml5">{fileImport.name}</div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Mapping */}
      <div className="importPopupContent flex flex-column scrollbar-miniblock">
        {/* Header */}
        <div className="flex inline-flex align-items-center mb5">
          <div className="importMappingHeader col-md-6">{Traduction.translate(language, 'excel_column')}</div>
          <div className="importMappingHeader col-md-6">{Traduction.translate(language, 'tabsters_field')}</div>
        </div>
        {/* Values */}
        {mappings.map((item, index) => {
          return (<div key={index} className="importMappingValues">
            {/* Excel Column */}
            <div className="col-md-6">
              {item.Field.Name !== '- NA -' && <span className="col-md-6 fs12">{item.Header}</span>}
              {item.Field.Name === '- NA -' && <span className="col-md-6 fs12 red">{item.Header}</span>}
            </div>
            {/* Tabsters Field */}
            <div className="col-md-6">
              <div className="importFieldsSelect cursor">
                <OverlayTrigger trigger="click" rootClose placement="right" overlay={this.templatePopoverMapping(item, fields)}>
                  <div className="width100p">
                    {item.Field.Name !== '- NA -' && <div className="importMappingLabel">
                      <span className="darkgreen">{item.Field.Label}</span>
                      <span className="iconChevronDownGreen extrasmallIcons ml10"></span>
                    </div>}
                    {item.Field.Name === '- NA -' && <div className="importMappingLabel">
                      <span className="red">{item.Field.Label}</span>
                      <span className="iconChevronDownRed extrasmallIcons ml10"></span>
                    </div>}
                  </div>
                </OverlayTrigger>
              </div>
              {/* {item.Field.Name !== '- NA -' && 
                <Form.Control className="importFieldsSelect darkgreen" as="select" value={item.Field.Name} onChange={(e) => this.changeFieldMapping(e, index)}>
                  {fields.map((field, i) => {
                    return <option key={i} value={field.Name}>{field.Label}</option>;
                  })}
                </Form.Control>
              } */}
              {/* {item.Field.Name === '- NA -' && 
                <Form.Control className="importFieldsSelect red" as="select" value={item.Field.Name} onChange={(e) => this.changeFieldMapping(e, index)}>
                  {fields.map((field, i) => {
                    return <option key={i} value={field.Name}>{field.Label}</option>;
                  })}
                </Form.Control>
              } */}
            </div>
            {/* <div className="col-md-4 flex-end">
              <Form.Check type="switch" id={"custom-switch" + index} onClick={(e) => {this.excludeColumn(index)}}/>
              <div className="ml20 mr10">{Traduction.translate(language, 'exclude_column')}</div>
            </div> */}
          </div>);
        })}
      </div>
      
      {/* Button Validate or Cancel */}
      <div className="flex flex-end align-items-center">
        {/* Buttons */}
        {!isLoading && <div className="flex">
          <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => this.validateMapping()}>{Traduction.translate(language, 'validate')}</Button>
          <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.closeMappingDataPopup(); this.cleanFile() }}>{Traduction.translate(language, 'cancel')}</Button>
        </div>}
        {/* Spinner Loading */}
        {isLoading && <div className="mr20"><LoadingSpinner></LoadingSpinner></div>}
      </div>
    </div>);
  }

  templateImportFile() {
    const { language, itemId, itemType, blockType, currentView, fileImport, importView, tables, search, isLoading, errors } = this.state;

    // Clone Current View
    // let importView = JSON.parse(JSON.stringify(currentView));

    // Update Import View (add 3 new Columns Import_Event, Import_Status, Import_Information)
    // if(importView.Parameters && importView.Parameters.find(param => param.Name === 'Columns')) {
    //   importView.Parameters.find(param => param.Name === 'Columns').Value = "Import_Event,Import_Status,Import_Information,Item_Type," + importView.Parameters.find(param => param.Name === 'Columns').Value;
    // }

    return (<div className="popupImportData flex flex-column">
      <div className="blockFilters row">
        <div className="views">
          {/* Label */}
          <div className="importDataLabel">
            <div className="importLabelBlue">{Traduction.translate(language, 'import')}</div>
            <div className="importLabelYellow ml5">{fileImport.name}</div>
          </div>
        </div>
        <div className="filters">
          {/* Search */}
          <div className="filtersSearch">
            <Form.Group className="searchBlock">
              <Form.Control type="text" id="searchFilters" name="search" value={search} placeholder={Traduction.translate(language, 'search')} onChange={this.searchItem} />
            </Form.Group>
          </div>
          {/* Export */}
          <div id="exportXLSX" className="exportList" onClick={(e) => this.exportXLSX()}>
            <i className="iconExcel icons mr10" alt="Export XLSX"/>
            <div className="fs12">{Traduction.translate(language, 'export_xlsx')}</div>
          </div>
        </div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Spinner Loading */}
      {isLoading && <div className="center mt30 mb20">
        <span className=""><LoadingSpinner></LoadingSpinner></span>
        <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
      </div>}

      {/* Mini Component Preview */}
      {!isLoading && <div className="importTableContent flex flex-column scrollbar-miniblock">
        {currentView.ViewType === 0 && currentView.DefaultLevel === 0 && <MiniTree ref={this.minitree} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} Pagging={true} CurrentView={importView} Columns={tables.ColumnHeaders} Rows={tables.Rows}></MiniTree>}
        {currentView.ViewType === 0 && currentView.DefaultLevel !== 0 && <MiniTable ref={this.minitable} ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} Pagging={true} CurrentView={importView} Columns={tables.ColumnHeaders} Rows={tables.Rows}></MiniTable>}
      </div>}
      
      {/* Button Validate or Cancel */}
      {!isLoading && <div className="flex flex-end align-items-center">
        {/* Buttons */}
        <Button className="fs12 bold brd-radius mr15" variant="primary" onClick={() => { this.validateImport() }}>{Traduction.translate(language, 'import')}</Button>
        <Button className="fs12 bold brd-radius" variant="warning" onClick={() => { this.closeImportFilePopup(); this.cleanFile() }}>{Traduction.translate(language, 'cancel')}</Button>
      </div>}
    </div>);
  }

  templateJobStatus() {
    const { language, fileImport, job, errors } = this.state;

    // setInterval(() => { this.validateJob() }, 10000);

    return (<div className="popupJobStatus flex flex-column">
      <div className="blockFilters row">
        <div className="views">
          {/* Job Status */}
          <div className="jobStatusLabel">{job.Status}</div>
          {/* Spinner Loading */}
          <div className="mt10 ml30"><LoadingSpinner></LoadingSpinner></div>
        </div>
      </div>

      {/* Error Message */}
      {errors.length > 0 && <ErrorMessage Errors={errors}></ErrorMessage>}

      {/* Job Informations */}
      {job.Completion === 100 && <div className="jobProgress">
        <span className="jobProgressLabel">{`${job.Completion} %`}</span>
        <span className="jobProgressBar"><ProgressBar className="progressJob" variant="success" now={job.Completion}></ProgressBar></span>
      </div>}
      {job.Completion < 100 && <div className="jobProgress">
        <span className="jobProgressLabel">{`${job.Completion} %`}</span>
        <span className="jobProgressBar"><ProgressBar className="progressJob" variant="primary" now={job.Completion}></ProgressBar></span>
      </div>}
    </div>);
  }
  
  templatePopoverMapping(item, values) {
    const itemId = this.state.itemId;
    const itemType = this.state.itemType;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <PopoverEditMapping ItemId={itemId} ItemType={itemType} Mapping={item} CurrentValue={item.Field} Values={values} onValueEdit={this.changeFieldMapping}></PopoverEditMapping>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }

  closeSelectFilePopup() {
    this.setState({ selectFilePopup: false, errors: [] });
  }

  closeMappingDataPopup() {
    this.setState({ selectFilePopup: false, mappingDataPopup: false, errors: [] });
  }

  closeImportFilePopup() {
    this.setState({ selectFilePopup: false, mappingDataPopup: false, importFilePopup: false, errors: [] });
  }

  closeJobStatusPopup() {
    this.setState({ selectFilePopup: false, mappingDataPopup: false, importFilePopup: false, jobStatusPopup: false, errors: [] });
  }

  render() {
    const { language, itemId, itemType, blockType, currentView, selectFilePopup, mappingDataPopup, importFilePopup, jobStatusPopup } = this.state;

    return (
      <div className="">
        {/* Popup Select File */}
        {(selectFilePopup === true) && <div className="selectFilePopup">
          <div className="selectFileInnerPopup">{this.templateSelectFile()}</div>
        </div>}

        {/* Popup Mapping Data */}
        {(mappingDataPopup === true) && <div className="mappingDataPopup">
          <div className="mappingDataInnerPopup">{this.templateMappingData()}</div>
        </div>}

        {/* Popup Import File */}
        {(importFilePopup === true) && <div className="importFilePopup">
          <div className="importFileInnerPopup">{this.templateImportFile()}</div>
        </div>}

        {/* Popup Job Status */}
        {(jobStatusPopup === true) && <div className="jobStatusPopup">
          <div className="jobStatusInnerPopup">{this.templateJobStatus()}</div>
        </div>}

        {/* Import Button */}
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'import_data')}</strong></Tooltip>}>
            <div className="filtersExport" onClick={(e) => this.setState({ selectFilePopup: true })}>
              <span className="iconImport iconsFilter cursor"/>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersImport;