import React, { Component } from 'react';
import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { ScheduleComponent, ViewsDirective, ViewDirective, Week, Month, Year, TimelineYear, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import PopupConfirmation from './PopupConfirmation';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });
loadCldr(require('cldr-data/supplemental/numberingSystems.json'), require('cldr-data/main/fr/ca-gregorian.json'), require('cldr-data/main/fr/numbers.json'), require('cldr-data/main/fr/timeZoneNames.json'), require('cldr-data/main/es/ca-gregorian.json'), require('cldr-data/main/es/numbers.json'), require('cldr-data/main/es/timeZoneNames.json'));

class MeetingCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      columns: [],
      rows: [],
      calendarData: [],
      month: null,
      year: null,
      confirm: false,
      deleteId: null,
      deleteType: null
    };

    // Calendar Structure
    this.buildCalendar = this.buildCalendar.bind(this);
    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateFr = this.formatDateFr.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.eventRendered = this.eventRendered.bind(this);
    this.refreshCalendar = this.refreshCalendar.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteMeeting = this.deleteMeeting.bind(this);

    // Template Functions
    this.headerTemplate = this.headerTemplate.bind(this);
    this.contentTemplate = this.contentTemplate.bind(this);
    this.footerTemplate = this.footerTemplate.bind(this);

    this.scheduler = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Calendar Data
    const calendarData = this.buildCalendar(rows);

    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    
    this.setState({ language, currentView, columns, rows, calendarData, month, year });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Build Calendar Data
      const calendarData = this.buildCalendar(rows);

      this.setState({ columns, rows, calendarData });
    }
  }

  // Build Calendar Datasource based on Meetings Table
  buildCalendar(rows) {
    let calendarData = [];

    rows.forEach(element => {
      let id, type, name, description, date, color;

      if(element.Cells) {
        if(element.Cells.find(cell => cell.ColumnName === "Item_ID")) {
          id = element.Cells.find(cell => cell.ColumnName === "Item_ID").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Item_Type")) {
          type = element.Cells.find(cell => cell.ColumnName === "Item_Type").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Meeting_Type")) {
          name = JSON.parse(element.Cells.find(cell => cell.ColumnName === "Meeting_Type").Value).Label;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Description")) {
          description = element.Cells.find(cell => cell.ColumnName === "Description").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "EndDate")) {
          date = new Date(element.Cells.find(cell => cell.ColumnName === "EndDate").Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === "Status")) {
          color = JSON.parse(element.Cells.find(cell => cell.ColumnName === "Status").Value).BackColor;
        }
      }

      let rowData = { Id: id, Type: type, Subject: name, Description: description, StartTime: date, EndTime: date, IsAllDay: true, CategoryColor: color };

      calendarData.push(rowData);
    });

    // Update Calendar Datasource
    if(this.scheduler.current) {
      this.scheduler.current.eventSettings.dataSource = calendarData;
    }

    return calendarData;
  }

  // Formatting Date to English format for Database
  formatDateEn(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = month + "/" + day + "/" + year;
    }

    return formattedDate;
  }

  // Formatting Date to French format
  formatDateFr(date) {
    let formattedDate;

    if(date) {
      // let dateFr = new Date(date).toLocaleString().split(' ')[0];
      let dateFr = new Date(date).toLocaleString("en-GB").split(/,| /)[0];

      const split = dateFr.split('/');
      const day = split[0];
      const month = split[1];
      const year = split[2];

      formattedDate = day + "/" + month + "/" + year;
    }

    return formattedDate;
  }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // console.log(args);
  }

  // Get Meeting background color depending on their Status
  eventRendered(args) {
    let categoryColor = args.data.CategoryColor;

    if(!args.element || !categoryColor) {
      return;
    }

    args.element.style.backgroundColor = categoryColor;
    args.element.style.border = 'rgb(255, 255, 255)';
  }

  refreshCalendar(rows) {
    const calendarData = this.buildCalendar(rows);

    // Update Calendar Datasource
    // this.scheduler.current.eventSettings.dataSource = calendarData;

    this.setState({ calendarData });
  }

  confirmDelete(itemId, itemType) {
    this.setState({ confirm: true, deleteId: itemId, deleteType: itemType });
  }

  cancelDelete() {
    this.setState({ confirm: false });
  }

  deleteMeeting() {
    const deleteId = this.state.deleteId;
    const deleteType = this.state.deleteType;

    // Call the event from the Parent component through the props
    this.props.onMeetingDelete(deleteId, deleteType, true);

    this.setState({ confirm: false });
  }

  headerTemplate(props) {
    const language = this.state.language;

    return (<div className="meetingInfoHeader">
      <div className="meetingInfoHeaderContent" style={{ alignItems: 'center', background: props.CategoryColor, color: '#FFFFFF' }}>
        <div className="meetingInfoHeaderTitle">{props.Subject}</div>
        {language === 'English' && <div className="meetingInfoHeaderDates">{this.formatDateEn(props.StartTime)}</div>}
        {language === 'Français' && <div className="meetingInfoHeaderDates">{this.formatDateFr(props.StartTime)}</div>}
      </div>
    </div>);
  }

  contentTemplate(props) {
    const language = this.state.language;
    const currentView = this.state.currentView;

    return (<div className="meetingInfoContent">
      <div className="bold">{Traduction.translate(language, 'description')} :</div>
      <div className="ml5" dangerouslySetInnerHTML={{ __html: props.Description }}></div>
    </div>);
  }

  footerTemplate(props) {
    const language = this.state.language;

    return (<div className="meetingInfoFooter">
      <div className="flex flex-end align-items-center">
        {/* Meeting Redirection */}
        <Link target="_self" to={`/Card/Meeting/${props.Id.substring(1)}/Home`}>
          <Button className="fs12 brd-radius mr10" variant="primary">{Traduction.translate(language, 'details')}</Button>
        </Link>
        {/* Delete Button */}
        <Button className="fs12 brd-radius" variant="warning" onClick={() => this.confirmDelete(props.Id.substring(1), props.Type)}>{Traduction.translate(language, 'delete')}</Button>
      </div>
    </div>);
  }

  render() {
    let { language, currentView, columns, rows, calendarData, month, year, confirm } = this.state;

    // Calendar Height
    let height = (window.innerHeight - 170);
    let minimum = 100;

    if(height > minimum) {
      height += 'px';
    }
    else {
      height = minimum + 'px';
    }

    // Event Settings
    this.eventSettings = { dataSource: calendarData, allowAdding: false, allowDeleting: true, allowEditing: true };

    // Quick Info Template
    this.quickInfoTemplates = { header: this.headerTemplate, content: this.contentTemplate, footer: this.footerTemplate };

    return (
      <div className="">
        {/* Delete Confirmation Popup */}
        {confirm && <PopupConfirmation Message={Traduction.translate(language, 'would_you_like_to_delete_meeting')} onMessageConfirm={this.deleteMeeting} onMessageCancel={this.cancelDelete}></PopupConfirmation>}

        {/* Scheduler */}
        <div className="schedule-control-section">
          <div className="control-section">
            <div className="control-wrapper">
              {language === 'English' && 
                <ScheduleComponent ref={this.scheduler} height={height} eventSettings={this.eventSettings} quickInfoTemplates={this.quickInfoTemplates} actionBegin={this.actionBegin} actionComplete={this.actionComplete} eventRendered={this.eventRendered} firstDayOfWeek={1} showHeaderBar={true}>
                  <ViewsDirective>
                    <ViewDirective option='Month'/>
                    <ViewDirective option='Year'/>
                  </ViewsDirective>
                  <Inject services={[ Month, Year ]}/>
                </ScheduleComponent>
              }
              {language !== 'English' && 
                <ScheduleComponent ref={this.scheduler} locale={Traduction.translate(language, 'locale')} height={height} eventSettings={this.eventSettings} quickInfoTemplates={this.quickInfoTemplates} actionBegin={this.actionBegin} actionComplete={this.actionComplete} eventRendered={this.eventRendered} firstDayOfWeek={1} showHeaderBar={true}>
                  <ViewsDirective>
                    <ViewDirective option='Month'/>
                    <ViewDirective option='Year'/>
                  </ViewsDirective>
                  <Inject services={[ Month, Year ]}/>
                </ScheduleComponent>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MeetingCalendar;