import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class CardName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authId: null,
      language: null,
      itemType: null,
      blockType: null,
      name: null
    };
  }
  
  componentDidMount() {
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const name = this.props.Name;

    this.setState({ authId, language, itemType, blockType, name });
  }

  componentDidUpdate(prevProps) {
    const itemType = this.props.ItemType;
    const blockType = this.props.BlockType;
    const name = this.props.Name;
    
    if(this.props.ItemType !== prevProps.ItemType || this.props.BlockType !== prevProps.BlockType || this.props.Name !== prevProps.Name) {
      this.setState({ itemType, blockType, name });
    }
  }
  
  render() {
    let { authId, language, itemType, blockType, name } = this.state;
    
    // Substring Minicard name if too long
    // if(name && name.length > 55) {
    //   name = <span className="" title={name}>{name.substring(0,55)} ...</span>;
    // }
  
    return (
      <div className="cardName">{name}</div>
    )
  }
}

export default CardName;