import React, {Component} from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class FiltersChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      settings: false
    };
    
    this.openChartSettings = this.openChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    
    this.setState({ language });
  }

  componentDidUpdate(prevProps) {

  }

  openChartSettings() {
    // Call the event from the Parent component
    this.props.onChartSettingsDisplay();

    this.setState({ settings: true });
  }

  closeChartSettings() {
    // Call the event from the Parent component
    this.props.onChartSettingsClose();

    this.setState({ settings: false });
  }
  
  render() {
    const { language, settings } = this.state;

    return (
      <div className="">
        {['top'].map(placement => (
          <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip id={`tooltip-${placement}`}><strong>{Traduction.translate(language, 'chart_parameters')}</strong></Tooltip>}>
            <div className="">
              {!settings && <div className="filtersChartSettings cursor" onClick={() => this.openChartSettings()}>
                <span className="iconParametersBlue iconsFilter"/>
              </div>}
              {settings && <div className="filtersChartSettingsActive cursor" onClick={() => this.closeChartSettings()}>
                <span className="iconParametersBlue iconsFilter"/>
              </div>}
            </div>
          </OverlayTrigger>
        ))}
      </div>
    )
  }
}

export default FiltersChart;