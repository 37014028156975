import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Form } from 'react-bootstrap';
import './Css/App.css';
import Authentication from './Authentication';
import Traduction from './Traduction';
import Navbar from './Blocs/Navbar';
import LoadingSpinner from './Blocs/LoadingSpinner';
import FavoritesCards from './Blocs/FavoritesCards';
import IndicatorsUser from './Blocs/IndicatorsUser';

const API = '/WebAppService/GetHomePage';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      firstName: null,
      guestLicence: null,
      welcomeMessage: null,
      search: '',
      favorites: [],
      userWeek: {},
      userTimetracking: {},
      isLoading: false,
      error: null
    };

    // Data Structure
    this.getHomePage = this.getHomePage.bind(this);

    // Actions
    this.changeSearch = this.changeSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const firstName = Authentication.getCookie('firstName');
    
    this.setState({ login, authId, language, firstName });

    await this.getHomePage(login, authId);
  }

  async getHomePage(login, authId) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };
    
    try {
      const response = await fetch(API, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const result = data.GetHomePageResult;

      if(result) {
        const guestLicence = result.GuestLicence;
        const welcomeMessage = result.WelcomeMessage;
        const favorites = result.FavoriteItems;
        const userWeek = result.MyWeek;
        const userTimetracking = result.MyTimeTracking;
        // const reports = result.Reports;
        
        this.setState({ guestLicence, welcomeMessage, favorites, userWeek, userTimetracking, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  changeSearch(event) {
    this.setState({ search: event.target.value });
  }

  async handleSubmit(event) {
    const search = this.state.search;

    event.preventDefault();

    if(search !== '') {
      // Redirect to SearchResults Page
      this.props.history.push(`/SearchResults?search=${search}`);
    }
  }

  render() {
    let { language, firstName, welcomeMessage, favorites, userWeek, userTimetracking, isLoading, error } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'tabsters')} • {Traduction.translate(language, 'home')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* Block */}
        <div className="block">
          {/* Search Content */}
          <div className="searchContent">
            <div className="row">
              {/* Welcome */}
              <div className="col-md-12">
                <div className="homeTitle">
                  <span className="">{Traduction.translate(language, 'hello')} {firstName}</span>
                  <span className="ml5 blue">{welcomeMessage}</span>
                </div>
              </div>

              {/* Search input */}
              <div className="col-md-6 mt10">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group className="searchbar">
                    <Form.Control type="text" id="searchHome" name="search" value={this.state.search} placeholder={Traduction.translate(language, 'search_placeholder')} onChange={this.changeSearch} />
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-2"></div>

              <div className="col-md-4 center"><div className="homeLabelBeta">BETA</div></div>
            </div>

            {/* Loading Spinner */}
            {isLoading && <div className="center mt30 mb20">
              <span className=""><LoadingSpinner></LoadingSpinner></span>
              <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
            </div>}

            <div className="row">
              {/* Favorites Cards */}
              <div className="col-md-8">
                <FavoritesCards Items={favorites}></FavoritesCards>

                {favorites.length === 0 && <div className="col-md-6 mt20 ml5 line-height25">
                  <span className="row bold">{Traduction.translate(language, 'no_favorite')}</span>
                  <span className="row">{Traduction.translate(language, 'favorite_utility')}</span>
                  <span className="row mt20">{Traduction.translate(language, 'favorite_add')}</span>
                </div>}
              </div>

              {/* User Week & Timetracking */}
              <div className="col-md-4">
                <IndicatorsUser UserWeek={userWeek} UserTimetracking={userTimetracking}></IndicatorsUser>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;