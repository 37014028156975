import React, {Component} from 'react';
import '../Css/App.css';

class ErrorModification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      errors: []
    };

    this.closeErrorModification = this.closeErrorModification.bind(this);
  }

  componentDidMount() {
    const open = this.props.Open;
    let errors = this.props.Errors;

    this.setState({ open, errors });
  }

  componentDidUpdate(prevProps) {
    if(this.props.Open !== prevProps.Open || this.props.Errors !== prevProps.Errors) {
      this.setState({ open: this.props.Open, errors: this.props.Errors });
    }
  }

  closeErrorModification() {
    let open = false;
    let errors = [];

    // Call the event from the Parent component through the props
    this.props.onErrorsClean(errors);

    this.setState({ open, errors });
  }

  render() {
    const { open, errors } = this.state;

    return (
      <div className="">
        {open && <div className="errorModification flex space-between align-items-center">
          {/* Errors */}
          <div className="">
            {errors.map((error, index) =>
              <div key={index} className="row">{error}</div>
            )}
          </div>
          {/* Close Button */}
          <span className="iconClose icons cursor" onClick={(e) => this.closeErrorModification()}/>
        </div>}
      </div>
    );
  }
}

export default ErrorModification;