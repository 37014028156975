import React, {Component} from 'react';
import '../Css/App.css';
import MiniTable from './MiniTable';
import Kanban from './Kanban';
import MiniPivot from './MiniPivot';

class MiniBlockResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      itemTitle: null,
      guestLicence: null,
      blockType: null,
      blockLabel: null,
      blockContent: {},
      currentView: {},
      views: [],
      tables: [],
      columns: [],
      rows: []
    };
    
    this.buildBlockData = this.buildBlockData.bind(this);
  }

  componentDidMount() {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const itemTitle = this.props.Title;
    const guestLicence = this.props.GuestLicence;
    const blockType = this.props.BlockType;
    const blockLabel = this.props.BlockLabel;
    const blockContent = this.props.BlockContent;

    // Get Block Content Data
    this.buildBlockData(blockContent);

    this.setState({ itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent });
  }

  componentDidUpdate(prevProps) {
    const itemId = this.props.Id;
    const itemType = this.props.Type;
    const blockContent = this.props.BlockContent;

    // if(this.props.BlockContent !== prevProps.BlockContent) {
    //   // Get Block Content Data
    //   this.buildBlockData(blockContent);

    //   this.setState({ blockContent });
    // }
  }

  buildBlockData(blockContent) {
    let views = [], currentView = {}, columns = [], rows = [];

    if(blockContent) {
      // Views
      if(blockContent.Views) {
        views = blockContent.Views;

        // Find CurrentView in View list
        if(views.find(view => view.ViewId === blockContent.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockContent.DefaultViewId);
        }
      }

      // Tables
      if(blockContent.Tables && blockContent.Tables.length > 0) {
        columns = blockContent.Tables[0].ColumnHeaders;
        rows = blockContent.Tables[0].Rows;
      }

      this.setState({ currentView, views, columns, rows });
    }
  }
  
  render() {
    const { itemId, itemType, itemTitle, guestLicence, blockType, blockLabel, blockContent, currentView, views, columns, rows } = this.state;

    return (
      <div className="miniblock">
        <div className="miniblockResources">
          {/* Mini Components */}
          {columns && rows.length > 0 && <div className="miniblockTable">
          {/* {columns && rows.length > 0 && <div className="miniblockTable scrollbar-miniblock"> */}
            {currentView.ViewType === 0 && <MiniTable ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} Pagging={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniTable>}
            {currentView.ViewType === 2 && <Kanban ItemId={itemId} ItemType={itemType} BlockType={blockType} Editable={false} GuestLicence={guestLicence} CurrentView={currentView} Kanban={blockContent.Kanban} Columns={columns} Rows={rows} Axes={blockContent.AvailableAxes}></Kanban>}
            {currentView.ViewType === 3 && <MiniPivot ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={false} CurrentView={currentView} Columns={columns} Rows={rows}></MiniPivot>}
          </div>}
        </div>
      </div>
    )
  }
}

export default MiniBlockResources;