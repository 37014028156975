import React, {Component} from 'react';
import { L10n } from '@syncfusion/ej2-base';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });

class DatePickerFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      filter: {},
      date: null,
      editable: null
    };

    this.formatDateEn = this.formatDateEn.bind(this);
    this.formatDateDatePicker = this.formatDateDatePicker.bind(this);
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const filter = this.props.Filter;
    const editable = this.props.Editable;

    this.setState({ language, filter, date: filter.Items, editable });
  }

  componentDidUpdate(prevProps) {
    const filter = this.props.Filter;
    const editable = this.props.Editable;

    if(this.props.Filter !== prevProps.Filter || this.props.Editable !== prevProps.Editable) {
      this.setState({ filter, date: filter.Items, editable });
    }
  }

  // Format Date to English format for Backend
  formatDateEn(date) {
    let formattedDate = "";

    if(date) {
      let day = date.getDate();
      let month = date.getMonth()+1; // Between 0 and 11
      let year = date.getFullYear();

      if(day < 10) {
        day = "0" + day;
      }
      if(month < 10) {
        month = "0" + month;
      }

      formattedDate = month + "/" + day + "/" + year;
    }
    
    return formattedDate;
  }

  // Formatting Date for DatePicker
  formatDateDatePicker(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const day = split[1];
      const month = split[0]-1; // Between 0 and 11
      const year = split[2];

      formattedDate = new Date(year, month, day);
    }

    return formattedDate;
  }

  change(args) {
    const filter = this.state.filter;

    let date = this.formatDateEn(args.value);

    // Call the event from the Parent component through the props
    this.props.onDateChange(filter, date);

    // this.setState({ date });
  }

  render() {
    const { language, filter, date, editable } = this.state;

    return (
      <div className="">
        <DatePickerComponent locale={Traduction.translate(language, 'locale')} format="dd/MM/yyyy" width={120} firstDayOfWeek={1} weekNumber={true} value={this.formatDateDatePicker(date)} change={this.change} enabled={editable} allowEdit={editable} showTodayButton={true}></DatePickerComponent>
      </div>
    );
  }
}

export default DatePickerFilter;