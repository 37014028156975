import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import NotificationGroup from './NotificationGroup';

const API_get = '/WebAppService/GetNotificationsTables';
const API_delete = '/WebAppService/DeleteNotifications';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      notifications: []
    };

    // Data Structure
    this.getNotifications = this.getNotifications.bind(this);
    this.buildGroups = this.buildGroups.bind(this);

    // Actions
    this.deleteGroup = this.deleteGroup.bind(this);
    // this.deleteNotification = this.deleteNotification.bind(this);
    this.updateNotificationsNumber = this.updateNotificationsNumber.bind(this);

    this.navbar = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = 0;
    const path = this.props.match.path.split('/');
    const itemType = path[path.length-1];
    const blockType = path[path.length-1];

    await this.getNotifications(login, authId);
    
    this.setState({ login, authId, language, itemId, itemType, itemTitle: Traduction.translate(language, 'my_notifications'), blockType });
  }

  async getNotifications(login, authId) {
    const language = this.state.language;

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      }
    };

    try {
      const response = await fetch(API_get, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const notifications = data.GetNotificationsTablesResult;

      if(notifications) {
        // this.buildGroups(notifications);

        this.setState({ notifications, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  buildGroups(notifications) {
    let notificationGroups = [];

    notifications.forEach(group => {
      notificationGroups.push(group);
    })

    return notificationGroups;
  }

  async deleteGroup(array, index) {
    const { login, authId, language, notifications } = this.state;

    // Build Id's list to delete
    let ids = array.join(',');

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ListNotificationIds': ids
      })
    };
    
    try {
      const response = await fetch(API_delete, requestOptions);
      
      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const valid = data.DeleteNotificationsResult;

      if(valid) {
        // Delete Group from Notifications
        notifications.splice(index, 1);

        // Update Notifications number in Navbar
        this.updateNotificationsNumber();

        this.setState({ notifications });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error });
    }
  }

  // deleteNotification(id) {
  //   let rows = this.state.rows;
  //   let indexToDelete = -1;

  //   // Loop through the rows
  //   rows.forEach((row, index) => {
  //     // Get Index if Row Notification_ID correspond to ID to delete
  //     if(row.Cells.find(cell => cell.ColumnName === 'Notification_ID').Value === id) {
  //       indexToDelete = index;
  //     }
  //   })

  //   // Delete Row
  //   if(indexToDelete >= 0) {
  //     rows.splice(indexToDelete, 1);
  //   }

  //   this.setState({ rows });
  // }

  updateNotificationsNumber() {
    // Update Notifications number in Navbar
    if(this.navbar.current) {
      this.navbar.current.updateNotifications();
    }
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, notifications } = this.state;

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{Traduction.translate(language, 'notifications')}</title></MetaTags>

        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* Block */}
        <div className="block">
          {/* Notifications Block Header */}
          <div className="blockHeaderTitle">
            {/* Title */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Notifications Block Body */}
          <div className="blockBody">
            {/* Notifications Block Content */}
            <div className="blockContent">
              {/* Notifications Groups */}
              {notifications.map((group, index) => 
                <div key={index} className="">
                  <NotificationGroup Group={group} Index={index} onGroupClean={this.deleteGroup} onNotificationClean={this.updateNotificationsNumber}></NotificationGroup>
                </div>
              )}

              {/* Notification Card */}
              {/* {notifications.map(notification =>
                <div key={notification.Notification_ID} className="">
                  <NotificationCard Id={notification.Notification_ID} Type={'Notification'} ParentId={notification.Parent_ID} ParentType={notification.Parent_Item_Type} ParentName={notification.Parent_Name} User={notification.From_User} Message={notification.Message} Link={notification.Link} Date={notification.Time} onNotificationClean={this.deleteNotification}></NotificationCard>
                </div>
              )} */}

              {/* Table */}
              {/* {filtered && <Table ItemType={itemType} BlockType={blockType} Columns={columns} Rows={rows} Filtered={filtered} Level={'Notification'} Editable={false}/>} */}

              {/* {rows.length === 0 && 
                <div className="line-height25">
                  <span className="row bold">Pas encore de notifications ?</span>
                  <span className="row">Retrouvez ici vos notifications reçues et archivées.</span>
                  <span className="row">Pour créer une nouvelle règle de notifications, merci de contacter le <Link to='/Help' className="ml5">support Tabsters</Link>.</span>
                </div>
              } */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications;