import React, {Component} from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

class ButtonsOccupationLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      occupationLevel: null
    };

    this.getOccupationLevel = this.getOccupationLevel.bind(this);
    this.changeOccupationLevel = this.changeOccupationLevel.bind(this);
    this.templatePopover = this.templatePopover.bind(this);
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const occupationLevel = this.props.OccupationLevel;

    this.setState({ language, currentView, occupationLevel });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const occupationLevel = this.props.OccupationLevel;

    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.OccupationLevel !== prevProps.OccupationLevel) {
      this.setState({ occupationLevel });
    }
  }

  getOccupationLevel(occupationLevel) {
    const language = this.state.language;

    switch(occupationLevel) {
      case "Month":
          return Traduction.translate(language, 'month');
      case "Week":
          return Traduction.translate(language, 'week');
      case "Day":
          return Traduction.translate(language, 'day');
      default:
          break;
    }
  }

  changeOccupationLevel(value) {
    this.setState({ occupationLevel: value });

    // Call the event from the Parent component through the props
    this.props.onOccupationLevelChange(value);
  }

  templatePopover() {
    const language = this.state.language;

    // Define Popover template
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <div className="flex flex-column">
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationLevel('Month')}>{Traduction.translate(language, 'month')}</div>
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationLevel('Week')}>{Traduction.translate(language, 'week')}</div>
            <div className="occupationLevelWorkload cursor" onClick={(e) => this.changeOccupationLevel('Day')}>{Traduction.translate(language, 'day')}</div>
          </div>
        </Popover.Content>
      </Popover>
    );

    return popover;
  }
  
  render() {
    const { language, currentView, occupationLevel } = this.state;

    return (
      <div className="occupationLevelList cursor mr10">
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={this.templatePopover()}>
          <div className="flex align-items-center">
            <span className="fs14 bold yellow">{this.getOccupationLevel(occupationLevel)}</span>
            <span className="iconChevronDownYellow verysmallIcons ml10"></span>
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

export default ButtonsOccupationLevel;