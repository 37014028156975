import React, { Component } from 'react';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { ScheduleComponent, ViewsDirective, ViewDirective, Week, Month, Year, TimelineYear, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import * as ej2FRlocale from './EJ2_LOCALE/ej2FRlocale.json';
import * as ej2ESlocale from './EJ2_LOCALE/ej2ESlocale.json';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';

// Traductions
L10n.load({ fr: ej2FRlocale.fr, es: ej2ESlocale.es });
loadCldr(require('cldr-data/supplemental/numberingSystems.json'), require('cldr-data/main/fr/ca-gregorian.json'), require('cldr-data/main/fr/numbers.json'), require('cldr-data/main/fr/timeZoneNames.json'), require('cldr-data/main/es/ca-gregorian.json'), require('cldr-data/main/es/numbers.json'), require('cldr-data/main/es/timeZoneNames.json'));

class MiniMeetingCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: null,
      currentView: {},
      columns: [],
      rows: [],
      calendarData: [],
      month: null,
      year: null
    };
    
    // Calendar Structure
    this.buildCalendar = this.buildCalendar.bind(this);
    // this.getMonthNameFr = this.getMonthNameFr.bind(this);
    // this.getMonthNameEn = this.getMonthNameEn.bind(this);

    // Actions
    this.actionBegin = this.actionBegin.bind(this);
    this.actionComplete = this.actionComplete.bind(this);
    this.eventRendered = this.eventRendered.bind(this);
    this.refreshCalendar = this.refreshCalendar.bind(this);

    this.scheduler = React.createRef();
  }

  componentDidMount() {
    const language = Authentication.getCookie('language');
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    // Build Calendar Data
    const calendarData = this.buildCalendar(rows);

    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    
    this.setState({ language, currentView, columns, rows, calendarData, month, year });
  }

  componentDidUpdate(prevProps) {
    const currentView = this.props.CurrentView;
    const columns = this.props.Columns;
    const rows = this.props.Rows;

    if(JSON.stringify(this.props.CurrentView) !== JSON.stringify(prevProps.CurrentView)) {
      this.setState({ currentView });
    }
    if(this.props.Columns !== prevProps.Columns || JSON.stringify(this.props.Rows) !== JSON.stringify(prevProps.Rows)) {
      // Build Calendar Data
      const calendarData = this.buildCalendar(rows);

      this.setState({ columns, rows, calendarData });
    }
  }

  // Build Calendar Datasource based on Meetings Table
  buildCalendar(rows) {
    let id, type, name, description, date, color;
    let calendarData = [];

    rows.forEach(element => {
      if(element.Cells) {
        if(element.Cells.find(cell => cell.ColumnName === "Item_ID")) {
          id = element.Cells.find(cell => cell.ColumnName === "Item_ID").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Item_Type")) {
          type = element.Cells.find(cell => cell.ColumnName === "Item_Type").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Meeting_Type")) {
          name = JSON.parse(element.Cells.find(cell => cell.ColumnName === "Meeting_Type").Value).Label;
        }
        if(element.Cells.find(cell => cell.ColumnName === "Description")) {
          description = element.Cells.find(cell => cell.ColumnName === "Description").Value;
        }
        if(element.Cells.find(cell => cell.ColumnName === "EndDate")) {
          date = new Date(element.Cells.find(cell => cell.ColumnName === "EndDate").Value);
        }
        if(element.Cells.find(cell => cell.ColumnName === "Status")) {
          color = JSON.parse(element.Cells.find(cell => cell.ColumnName === "Status").Value).BackColor;
        }
      }

      let rowData = { Id: id, Type: type, Subject: name, Description: description, StartTime: date, EndTime: date, IsAllDay: true, CategoryColor: color };

      calendarData.push(rowData);
    });

    // Update Calendar Datasource
    if(this.scheduler.current) {
      this.scheduler.current.eventSettings.dataSource = calendarData;
    }

    return calendarData;
  }

  // getMonthNameEn(date) {
  //   let month;

  //   switch(date) {
  //     case 0:
  //         month = 'January';
  //         break;
  //     case 1:
  //         month = 'February';
  //         break;
  //     case 2:
  //         month = 'March';
  //         break;
  //     case 3:
  //         month = 'April';
  //         break;
  //     case 4:
  //         month = 'May';
  //         break;
  //     case 5:
  //         month = 'June';
  //         break;
  //     case 6:
  //         month = 'July';
  //         break;
  //     case 7:
  //         month = 'August';
  //         break;
  //     case 8:
  //         month = 'September';
  //         break;
  //     case 9:
  //         month = 'October';
  //         break;
  //     case 10:
  //         month = 'November';
  //         break;
  //     case 11:
  //         month = 'December';
  //         break;
  //     default:
  //         break;
  //   }
  //   return month;
  // }

  // getMonthNameFr(date) {
  //   let month;

  //   switch(date) {
  //     case 0:
  //         month = 'Janvier';
  //         break;
  //     case 1:
  //         month = 'Février';
  //         break;
  //     case 2:
  //         month = 'Mars';
  //         break;
  //     case 3:
  //         month = 'Avril';
  //         break;
  //     case 4:
  //         month = 'Mai';
  //         break;
  //     case 5:
  //         month = 'Juin';
  //         break;
  //     case 6:
  //         month = 'Juillet';
  //         break;
  //     case 7:
  //         month = 'Août';
  //         break;
  //     case 8:
  //         month = 'Septembre';
  //         break;
  //     case 9:
  //         month = 'Octobre';
  //         break;
  //     case 10:
  //         month = 'Novembre';
  //         break;
  //     case 11:
  //         month = 'Décembre';
  //         break;
  //     default:
  //         break;
  //   }
  //   return month;
  // }

  actionBegin(args) {
    // console.log(args);
  }

  actionComplete(args) {
    // console.log(args);
  }

  // Get Meeting background color depending on their Status
  eventRendered(args) {
    let categoryColor = args.data.CategoryColor;

    if(!args.element || !categoryColor) {
      return;
    }

    args.element.style.backgroundColor = categoryColor;
    args.element.style.border = 'rgb(255, 255, 255)';
  }

  refreshCalendar(rows) {
    const calendarData = this.buildCalendar(rows);

    // Update Calendar Datasource
    // this.scheduler.current.eventSettings.dataSource = calendarData;

    this.setState({ calendarData });
  }

  render() {
    let { language, rows, calendarData, month, year } = this.state;

    // Event Settings
    this.eventSettings = { dataSource: calendarData, allowAdding: false, allowDeleting: false, allowEditing: false };

    return (
      <div className="">
        {/* {language === 'English' && <div className="miniSchedulerDate">{this.getMonthNameEn(month)} {year}</div>} */}
        {/* {language === 'Français' && <div className="miniSchedulerDate">{this.getMonthNameFr(month)} {year}</div>} */}
        <div className="schedule-control-section">
          <div className="control-section">
            <div className="control-wrapper">
              {language === 'English' && 
                <ScheduleComponent ref={this.scheduler} eventSettings={this.eventSettings} actionBegin={this.actionBegin} actionComplete={this.actionComplete} eventRendered={this.eventRendered} firstDayOfWeek={1} showHeaderBar={false}>
                  <ViewsDirective>
                    <ViewDirective option='Month'/>
                    <ViewDirective option='Year'/>
                  </ViewsDirective>
                  <Inject services={[ Month, Year ]}/>
                </ScheduleComponent>
              }
              {language === 'Français' && 
                <ScheduleComponent ref={this.scheduler} locale={Traduction.translate(language, 'locale')} eventSettings={this.eventSettings} actionBegin={this.actionBegin} actionComplete={this.actionComplete} eventRendered={this.eventRendered} firstDayOfWeek={1} showHeaderBar={false}>
                  <ViewsDirective>
                    <ViewDirective option='Month'/>
                    <ViewDirective option='Year'/>
                  </ViewsDirective>
                  <Inject services={[ Month, Year ]}/>
                </ScheduleComponent>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MiniMeetingCalendar;