import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import Navbar from './Navbar';
import BlockTitle from './BlockTitle';
import FiltersView from './FiltersView';
import FiltersCurrentView from './FiltersCurrentView';
import FiltersBlock from './FiltersBlock';
import FiltersSort from './FiltersSort';
import FiltersConditionalFormatting from './FiltersConditionalFormatting';
import FiltersDisplayComponent from './FiltersDisplayComponent';
import FiltersChart from './FiltersChart';
import FiltersExport from './FiltersExport';
import LoadingSpinner from './LoadingSpinner';
import ErrorModification from './ErrorModification';
import BurnedHeatmap from './BurnedHeatmap';
import Pivot from './Pivot';
import Chart from './Chart';

const API_info = '/WebAppService/GetCardBlockInformation';

class BlockBurned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      itemId: null,
      itemType: null,
      itemTitle: null,
      blockType: null,
      blockInfo: {},
      editable: null,
      guestLicence: null,
      favorite: null,
      parents: [],
      warnings: 0,
      year: null,
      displayViews: null,
      views: [],
      defaultViewId: null,
      viewId: null,
      currentView: {},
      heatmaps: [],
      heatmap: {},
      tables: [],
      columns: [],
      rows: [],
      chart: {},
      filtered: [],
      selectedDates: [],
      selectedResources: [],
      visibleGraph: true,
      visibleHeatmap: true,
      isLoading: false,
      errors: []
    };
    
    // Data Structure
    this.getCardInformation = this.getCardInformation.bind(this);
    this.getCardData = this.getCardData.bind(this);
    this.getSelectedDates = this.getSelectedDates.bind(this);
    this.getSelectedMonth = this.getSelectedMonth.bind(this);
    this.getSelectedYear = this.getSelectedYear.bind(this);
    this.getSelectedResourceId = this.getSelectedResourceId.bind(this);
    this.convertStringtoBoolean = this.convertStringtoBoolean.bind(this);
    this.formatDate = this.formatDate.bind(this);

    // Actions
    this.previousYear = this.previousYear.bind(this);
    this.nextYear = this.nextYear.bind(this);
    this.displayViews = this.displayViews.bind(this);
    this.hideViews = this.hideViews.bind(this);
    this.changeView = this.changeView.bind(this);
    this.setDefaultView = this.setDefaultView.bind(this);
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.changeFormattings = this.changeFormattings.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.applyChartSettings = this.applyChartSettings.bind(this);
    this.displayChartSettings = this.displayChartSettings.bind(this);
    this.closeChartSettings = this.closeChartSettings.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.exportXLSX = this.exportXLSX.bind(this);
    this.exportJPEG = this.exportJPEG.bind(this);
    this.exportPNG = this.exportPNG.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updateView = this.updateView.bind(this);
    this.updateHeatmap = this.updateHeatmap.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.selectionFilter = this.selectionFilter.bind(this);
    // this.updateTable = this.updateTable.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);

    // Reference
    this.views = React.createRef();
    this.filters = React.createRef();
    this.sort = React.createRef();
    this.formattings = React.createRef();
    this.burnedHeatmap = React.createRef();
    this.burnedPivot = React.createRef();
    this.burnedChart = React.createRef();
  }

  async componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    let displayViews;

    if(Authentication.getCookie('displayViews')) {
      displayViews = JSON.parse(Authentication.getCookie('displayViews'));
    }
    else {
      displayViews = true;
    }

    // Get Params from Query string url
    let params, viewId, year;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);

      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }
      
      if(params.year) {
        year = parseInt(params.year);
      }
    }

    // Get Params from Query string url
    // const params = queryString.parse(this.props.location.search);
    // const year = parseInt(params.year);

    this.setState({ login, authId, language, itemId, itemType, blockType, year, displayViews });

    // Get Card Information
    await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId, year);

    const blockInfo = this.state.blockInfo;
    const views = this.state.views;
    let currentView = {};

    if(Object.entries(blockInfo).length > 0) {
      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      if(!viewId && (currentView.ViewId && viewId !== currentView.ViewId)) {
        // Redirect with new Query params
        this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${currentView.ViewId}&year=${year}`);
      }
      else {
        // Get Card Data
        await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);
      }
    }
    else {
      // Redirect to Login Page
      this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
    }
  }

  async componentDidUpdate(prevProps) {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const itemId = this.props.match.params.itemId;
    const itemType = this.props.match.params.itemType;
    const path = this.props.match.path.split('/');
    const blockType = path[path.length-1];
    
    // Get Params from Query string url
    let params, old_params, viewId, oldViewId, year, oldYear;

    if(this.props.location.search) {
      params = queryString.parse(this.props.location.search);

      if(params.viewId) {
        viewId = parseInt(params.viewId);
      }
      
      if(params.year) {
        year = parseInt(params.year);
      }
    }
    if(prevProps.location.search) {
      old_params = queryString.parse(prevProps.location.search);

      if(old_params.viewId) {
        oldViewId = parseInt(old_params.viewId);
      }
      
      if(old_params.year) {
        oldYear = parseInt(old_params.year);
      }
    }

    if(itemId !== prevProps.match.params.itemId || itemType !== prevProps.match.params.itemType) {
      this.setState({ itemId, itemType });

      // Get Card Information
      await this.getCardInformation(login, authId, itemId, itemType, blockType, viewId, year);
    }
    if(viewId !== oldViewId || year !== oldYear) {
      const blockInfo = this.state.blockInfo;
      const views = this.state.views;
      let currentView = {};

      // Get Current View
      if(viewId && views.find(view => view.ViewId === viewId)) {
        currentView = views.find(view => view.ViewId === viewId);
      }
      else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
        currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
      }

      // Get Card Data
      await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);

      // Refresh Filters View
      // if(this.views.current) {
      //   this.views.current.selectView(currentView);
      // }

      // Refresh Components with CurrentView
      if(currentView.ViewType === 3 && this.burnedPivot.current) {
        this.burnedPivot.current.refreshPivot(currentView);
      }
      else if(currentView.ViewType === 7 && this.burnedChart.current) {
        this.burnedChart.current.applySettings(currentView);
      }
    }
  }

  // Get Block Information from the API
  async getCardInformation(login, authId, itemId, itemType, blockType, viewId, year) {
    const language = this.state.language;

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'ViewId': viewId,
        'WithData': false,
        'WithInformation': true,
        'InactiveData': false
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockInfo = data.GetCardBlockInformationResult;

      if(blockInfo) {
        const itemTitle = blockInfo.ObjectName;
        const editable = blockInfo.InsertDeleteAllowed;
        const guestLicence = blockInfo.GuestLicence;
        const favorite = blockInfo.IsFavorite;
        const parents = blockInfo.Parents;
        const warnings = blockInfo.Warnings;
        const views = blockInfo.Views;
        const defaultViewId = blockInfo.DefaultViewId;
        let currentView = {};
        
        // Get Current View
        if(viewId && views.find(view => view.ViewId === viewId)) {
          currentView = views.find(view => view.ViewId === viewId);
        }
        else if(views.find(view => view.ViewId === blockInfo.DefaultViewId)) {
          currentView = views.find(view => view.ViewId === blockInfo.DefaultViewId);
        }

        this.setState({ blockInfo, itemTitle, editable, guestLicence, favorite, parents, warnings, year, views, defaultViewId, viewId, currentView });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  // Get Block Data from the API
  async getCardData(login, authId, itemId, itemType, blockType, currentView, year) {
    const language = this.state.language;
    let additionalContext = [];

    if(year) {
      additionalContext = [{"Key": "Year", "Value": year.toString()}];
    }

    this.setState({ isLoading: true });

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': itemType,
        'ItemId': itemId,
        'BlockType': blockType,
        'View': currentView,
        'WithData': true,
        'WithInformation': false,
        'InactiveData': false,
        'AdditionalContext': additionalContext
      })
    };

    try{
      const response = await fetch(API_info, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const blockContent = data.GetCardBlockInformationResult;

      if(blockContent) {
        const heatmaps = blockContent.HeatMaps;
        const tables = blockContent.Tables;
        const chart = blockContent.Chart;
        let heatmap = {}, columns = [], rows = [], filtered = [];
        let visibleGraph, visibleHeatmap;

        // Get Heatmap
        if(heatmaps && heatmaps.length > 0) {
          heatmap = heatmaps[0];
        }
        // if(heatmaps.find(element => element.Name === "Month")) {
        //   heatmap = heatmaps.find(element => element.Name === "Month");
        // }

        // Get Tables, Rows & Columns
        if(tables.find(element => element.Level === "Workload")) {
          columns = tables.find(element => element.Level === "Workload").ColumnHeaders;
          rows = tables.find(element => element.Level === "Workload").Rows;
          filtered = tables.find(element => element.Level === "Workload").Rows;
        }
        
        // Apply View
        // this.applyView(rows, currentView);

        // Initialise Visible Component
        if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
          // Display Graph
          if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Graph') {
            visibleGraph = true;
            visibleHeatmap = false;
          }
          // Display Heatmap
          else if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Heatmap') {
            visibleGraph = false;
            visibleHeatmap = true;
          }
        }
        // Display Both
        else {
          visibleGraph = true;
          visibleHeatmap = true;
        }

        this.setState({ year, viewId: currentView.ViewId, currentView, heatmaps, heatmap, tables, columns, rows, chart, filtered, visibleGraph, visibleHeatmap, isLoading: false });
      }
      else {
        // Create Cookie with the current URL
        Authentication.createCookie('lastUrl', window.location.pathname + window.location.search);

        // Redirect to Login Page
        this.props.history.push("/Login?language=" + Traduction.translate(language, 'locale'));
      }

    } catch(error) {
      this.setState({ error, isLoading: false });
    }
  }

  getSelectedDates(datesLabel) {
    const columns = this.state.heatmap.Columns;
    let dates = [];

    datesLabel.forEach(date => {
      if(columns.find(column => column.Label === date)) {
        dates.push(columns.find(column => column.Label === date).Name);
      }
    })

    return dates;
  }

  getSelectedMonth(value) {
    const heatmap = this.state.heatmap;
    let date, month;
    
    if(heatmap.Columns.find(element => element.Label === value)) {
      date = heatmap.Columns.find(element => element.Label === value).Name;
    }

    month = new Date(date).getMonth() + 1;

    return month;
  }

  getSelectedYear(value) {
    const heatmap = this.state.heatmap;
    let date, year;
    
    if(heatmap.Columns.find(element => element.Label === value)) {
      date = heatmap.Columns.find(element => element.Label === value).Name;
    }

    year = new Date(date).getFullYear();

    return year;
  }

  getSelectedResourceId(value) {
    const heatmap = this.state.heatmap;
    let resourceId;

    if(heatmap.Rows.find(element => element.Label === value)) {
      resourceId = heatmap.Rows.find(element => element.Label === value).Id;
    }

    return resourceId;
  }

  convertStringtoBoolean(string) {
    if(string === 'true') {
      return true;
    }
    else {
      return false;
    }
  }

  formatDate(date) {
    let formattedDate;

    if(date) {
      const split = date.split('/');
      const month = split[0];
      const year = split[2];

      // Get first day of the month
      formattedDate = month + '/01/' + year;
    }

    return formattedDate;
  }

  previousYear(year) {
    const { itemId, itemType, blockType, viewId } = this.state;

    // Decrement Year
    year = year - 1;

    // Redirect to Block Holidays Page with new Query params
    if(viewId) {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${viewId}&year=${year}`);
    }
    else {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?year=${year}`);
    }
  }

  nextYear(year) {
    const { itemId, itemType, blockType, viewId } = this.state;

    // Increment Year
    year = year + 1;

    // Redirect to Block Holidays Page with new Query params
    if(viewId) {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${viewId}&year=${year}`);
    }
    else {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?year=${year}`);
    }
  }

  displayViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', true);

    this.setState({ displayViews: true });
  }

  hideViews() {
    Authentication.deleteCookie('displayViews');
    Authentication.createCookie('displayViews', false);

    this.setState({ displayViews: false });
  }

  changeView(view) {
    const { itemId, itemType, blockType, year } = this.state;

    // Redirect with new Query params
    if(view.ViewType === 3) {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}&year=${year}`);
    }
    else {
      this.props.history.push(`/Card/${itemType}/${itemId}/${blockType}?viewId=${view.ViewId}`);
    }
  }

  setDefaultView(viewId) {
    this.setState({ defaultViewId: viewId });
  }

  async changeFilters(currentView) {
    const { login, authId, itemId, itemType, blockType, year } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);
  }

  async changeSort(currentView) {
    const { login, authId, itemId, itemType, blockType, year } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);
  }

  async changeFormattings(currentView) {
    const { login, authId, itemId, itemType, blockType, year } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);
  }

  async changeDisplay() {
    const { login, authId, itemId, itemType, blockType, currentView, year } = this.state;
    let visibleHeatmap, visibleGraph;

    if(currentView.Parameters && currentView.Parameters.find(param => param.Name === "VisibleComponent")) {
      // Display Both
      if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Graph') {
        currentView.Parameters.splice(currentView.Parameters.findIndex(param => param.Name === "VisibleComponent"), 1);
        visibleGraph = true;
        visibleHeatmap = true;
      }
      // Display Graph
      else if(currentView.Parameters.find(param => param.Name === "VisibleComponent").Value === 'Heatmap') {
        currentView.Parameters.find(param => param.Name === "VisibleComponent").Value = "Graph";
        visibleGraph = true;
        visibleHeatmap = false;
      }
    }
    // Display Heatmap
    else {
      currentView.Parameters.push({ Name: "VisibleComponent", Value: "Heatmap" });
      visibleHeatmap = true;
      visibleGraph = false;
    }

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);

    this.setState({ visibleGraph, visibleHeatmap });
  }

  async applyChartSettings(currentView) {
    const { login, authId, itemId, itemType, blockType, year } = this.state;

    // Add Modify attribute in CurrentView
    currentView["Modify"] = true;

    // Get Card Data
    await this.getCardData(login, authId, itemId, itemType, blockType, currentView, year);

    // Apply Setting on Chart and Update CurrentView
    if(this.burnedChart.current) {
      this.burnedChart.current.applySettings(currentView);
    }

    // this.setState({ currentView });
  }

  displayChartSettings() {
    if(this.burnedChart.current) {
      this.burnedChart.current.displaySettings();
    }
  }

  closeChartSettings() {
    if(this.burnedChart.current) {
      this.burnedChart.current.closeSettings();
    }
  }

  exportCSV() {
    // Export Data from Pivot component
    if(this.burnedPivot.current) {
      this.burnedPivot.current.exportCSV();
    }
    // Export Data from Chart component
    if(this.burnedChart.current) {
      this.burnedChart.current.exportCSV();
    }
  }

  exportXLSX() {
    // Export Data from Pivot component
    if(this.burnedPivot.current) {
      this.burnedPivot.current.exportXLSX();
    }
    // Export Data from Chart component
    if(this.burnedChart.current) {
      this.burnedChart.current.exportXLSX();
    }
  }

  exportJPEG() {
    // Export Data from Chart component
    if(this.burnedChart.current) {
      this.burnedChart.current.exportJPEG();
    }
  }

  exportPNG() {
    // Export Data from Chart component
    if(this.burnedChart.current) {
      this.burnedChart.current.exportPNG();
    }
  }

  exportPDF() {
    // Export Data from Chart component
    if(this.burnedChart.current) {
      this.burnedChart.current.exportPDF();
    }
  }

  updateView(view) {
    this.setState({ currentView: view });
  }

  updateHeatmap(period) {
    const heatmaps = this.state.heatmaps;

    // Get corresponding Heatmap
    const heatmap = heatmaps.find(element => element.Name === period);

    this.setState({ heatmap });
  }

  updateSelection(selectedDatesLabel, selectedResources) {
    const rows = this.state.rows;
    let selectedDates = [];
    let filtered = [];

    // Update selection
    if(selectedDatesLabel.length > 0 || selectedResources.length > 0) {
      // Get selected Dates
      selectedDates = this.getSelectedDates(selectedDatesLabel);

      rows.forEach(row => {
        const asOfDate = row.Cells.find(cell => cell.ColumnName === "AsOfDate").Value;
        const resource = row.Cells.find(cell => cell.ColumnName === "Resource").Value;

        // If Row is selected
        if(this.selectionFilter(asOfDate, resource, selectedDates, selectedResources)) {
          filtered.push(row);
        }
      });
  
      // Refresh Component Data
      if(this.burnedPivot.current) {
        this.burnedPivot.current.updatePivot(filtered);
      }
      else if(this.burnedChart.current) {
        this.burnedChart.current.updateChart(filtered);
      }

      this.setState({ filtered, selectedDates: selectedDatesLabel, selectedResources });
    }
    // Clear selection
    else {
      // Refresh Component Data
      if(this.burnedPivot.current) {
        this.burnedPivot.current.updatePivot(rows);
      }
      else if(this.burnedChart.current) {
        this.burnedChart.current.updateChart(rows);
      }
    }
  }

  clearSelection() {
    const rows = this.state.rows;

    if(this.burnedHeatmap.current) {
      this.burnedHeatmap.current.clearSelection();
    }

    this.setState({ filtered: rows, selectedDates: [], selectedResources: [] });
  }

  selectionFilter(asOfDate, resource, selectedDates, selectedResources) {
    if(selectedResources.includes(resource)) {
      if(selectedDates.includes(this.formatDate(asOfDate))) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  // updateTable(rows) {

  // }

  updateErrors(err) {
    let errors = [];

    // Push the new Errors in the Errors Table
    errors.push(err);

    this.setState({ errors });
  }

  cleanErrors() {
    this.setState({ errors: [] });
  }
  
  render() {
    const { language, itemId, itemType, itemTitle, blockType, blockInfo, editable, guestLicence, favorite, parents, warnings, year, displayViews, views, defaultViewId, currentView, heatmap, tables, columns, rows, chart, filtered, selectedDates, selectedResources, visibleHeatmap, visibleGraph, isLoading, errors } = this.state;

    // Get current Date
    const date = new Date();
    const currentYear = date.getFullYear();

    return (
      <div className="blockContainer">
        {/* Title */}
        <MetaTags><title>{itemTitle} • {Traduction.translate(language, 'burned')}</title></MetaTags>
        
        {/* Navbar */}
        <Navbar Selected={this.props.match.url}></Navbar>

        {/* HasRightOnItem = false */}
        {blockInfo.HasRightOnItem === false && <div className="block">
          <div className="blockHeader">
            <div className="blockTitle">
              <div className="cardIcon"><div className="cardIconGrey"><span className="iconNoRights iconsCard"></span></div></div>
              <div className="flex"><span className="cardTitle">{Traduction.translate(language, 'no_sufficient_rights')}</span></div>
            </div>
          </div>
        </div>}

        {/* Block */}
        {blockInfo.HasRightOnItem === true && <div className="block">
          {/* Card Block Header */}
          <div className="blockHeader">
            {/* Title & Parents */}
            <BlockTitle ItemId={itemId} ItemType={itemType} ItemTitle={itemTitle} BlockType={blockType} CurrentView={currentView} Blocks={blockInfo.BlockNames} Favorite={favorite} Parents={parents} Warnings={warnings} onDataUpdate={this.getCardData}></BlockTitle>
            {/* Border */}
            <div className="blockBorder"></div>
          </div>

          {/* Card Block Body */}
          <div className="blockBody">
            {/* Filters Views */}
            {displayViews && <FiltersView ref={this.views} ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} DefaultViewId={defaultViewId} CurrentView={currentView} Views={views} onViewsHide={this.hideViews} onViewChange={this.changeView} onSetDefaultView={this.setDefaultView} onErrorsUpdate={this.updateErrors}></FiltersView>}

            {/* Card Block Content */}
            <div className={!displayViews ? "blockContent" : "blockContentViews"}>
              {/* Filters */}
              <div className="blockFilters">
                {/* Current View */}
                {!displayViews && <FiltersCurrentView ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onDisplayViews={this.displayViews} onErrorsUpdate={this.updateErrors}></FiltersCurrentView>}

                {/* Buttons Year */}
                {currentView.ViewType === 3 && <div className="filtersBurned">
                  <div className="flex align-items-center fs14 bold yellow">
                    <span className="iconPreviousYellow verysmallIcons mr10 cursor" onClick={(e) => this.previousYear(year)}></span>
                    <span className="flex align-items-center">{year}</span>
                    {year < currentYear && <span className="iconNextYellow verysmallIcons ml10 cursor" onClick={(e) => this.nextYear(year)}></span>}
                  </div>
                </div>}

                {/* Visible Component */}
                {currentView.ViewType === 3 && !guestLicence && 
                  <FiltersDisplayComponent BlockType={blockType} CurrentView={currentView} DisplayHeatmap={visibleHeatmap} DisplayGraph={visibleGraph} onDisplayChange={this.changeDisplay}></FiltersDisplayComponent>
                }

                {/* Block Filters */}
                {(!visibleHeatmap || currentView.ViewType === 7) && 
                  <FiltersBlock ref={this.filters} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFiltersChange={this.changeFilters}></FiltersBlock>
                }

                {/* Sort Filters */}
                {(!visibleHeatmap || currentView.ViewType === 7) && 
                  <FiltersSort ref={this.sort} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onSortChange={this.changeSort}></FiltersSort>
                }

                {/* Conditional Formatting Filters */}
                {(!visibleHeatmap || currentView.ViewType === 7) && 
                  <FiltersConditionalFormatting ref={this.formattings} ItemId={itemId} ItemType={itemType} BlockType={blockType} CurrentView={currentView} onFormattingsChange={this.changeFormattings}></FiltersConditionalFormatting>
                }

                {/* Chart Settings */}
                {currentView.ViewType === 7 && !guestLicence && 
                  <FiltersChart onChartSettingsDisplay={this.displayChartSettings} onChartSettingsClose={this.closeChartSettings}></FiltersChart>
                }

                {/* Export */}
                <FiltersExport ItemId={itemId} ItemType={itemType} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} onCSVExport={this.exportCSV} onXLSXExport={this.exportXLSX} onJPEGExport={this.exportJPEG} onPNGExport={this.exportPNG} onPDFExport={this.exportPDF} onErrorsUpdate={this.updateErrors}></FiltersExport>

                {currentView.ViewType === 3 && selectedDates.length === 1 && selectedResources.length === 1 && 
                  <div className="selectionHeatmap">
                    {/* Clear Selection */}
                    <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                    {/* Selection Details */}
                    <div className="selectionDetails fs12">
                      <span className="bold">{Traduction.translate(language, 'burned_breakdown')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                      <span className="turquoise bold">{selectedResources.join(', ')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                      <span className="bold">{selectedDates[0]}</span>
                    </div>
                    {/* Timetracking Button */}
                    <div className="ml20">
                      <Link target="_self" to={`/TimeTracking?year=${this.getSelectedYear(selectedDates[0])}&month=${this.getSelectedMonth(selectedDates[0])}&resourceId=${this.getSelectedResourceId(selectedResources[0])}`}>
                        <Button className="fs12" variant="primary">{Traduction.translate(language, 'open_timesheet')}</Button>
                      </Link>
                    </div>
                  </div>
                }
                {currentView.ViewType === 3 && selectedDates.length === 1 && selectedResources.length > 1 && 
                  <div className="selectionHeatmap">
                    {/* Clear Selection */}
                    <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                    {/* Selection Details */}
                    <div className="selectionDetails fs12">
                      <span className="bold">{Traduction.translate(language, 'burned_breakdown')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                      <span className="turquoise bold">{selectedResources.join(', ')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                      <span className="bold">{selectedDates[0]}</span>
                    </div>
                  </div>
                }
                {currentView.ViewType === 3 && selectedDates.length > 1 && selectedResources.length >= 1 && 
                  <div className="selectionHeatmap">
                    {/* Clear Selection */}
                    <span className="clearHeatmapIcon iconClear icons cursor" onClick={(e) => this.clearSelection()}/>
                    {/* Selection Details */}
                    <div className="selectionDetails fs12">
                      <span className="bold">{Traduction.translate(language, 'burned_breakdown')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'of')}</span>
                      <span className="turquoise bold">{selectedResources.join(', ')}</span>
                      <span className="ml5 mr5">{Traduction.translate(language, 'on')}</span>
                      <span className="bold">{selectedDates.join(', ')}</span>
                    </div>
                  </div>
                }
              </div>
              
              {/* Card Block Component */}
              <div className="blockComponent">
                {/* Errors */}
                {errors.length > 0 && 
                  <ErrorModification Errors={errors} Open={true} onErrorsClean={this.cleanErrors}></ErrorModification>
                }

                {/* Loading Spinner */}
                {isLoading && <div className="center mt30 mb20">
                  <span className=""><LoadingSpinner></LoadingSpinner></span>
                  <span className="bold ml30">{Traduction.translate(language, 'data_loading')}</span>
                </div>}

                {/* Heatmap */}
                {visibleHeatmap && currentView.ViewType === 3 && 
                  <BurnedHeatmap ref={this.burnedHeatmap} ItemType={itemType} Title={heatmap.Title} Columns={heatmap.Columns} Rows={heatmap.Rows} ChartData={heatmap.ChartData} onHeatmapSelection={this.updateSelection} VisibleHeatmap={visibleHeatmap} VisibleGraph={visibleGraph}></BurnedHeatmap>
                }

                {/* Pivot */}
                {visibleGraph && currentView.ViewType === 3 && filtered.length > 0 && <div className="burnedPivot">
                  <Pivot ref={this.burnedPivot} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} Editable={editable} GuestLicence={guestLicence} CurrentView={currentView} Columns={columns} Rows={rows} Filtered={filtered} onViewChange={this.updateView} VisibleHeatmap={visibleHeatmap} VisibleGraph={visibleGraph}></Pivot>
                </div>}

                {/* Chart */}
                {currentView.ViewType === 7 && <div className="burnedChart">
                  <Chart ref={this.burnedChart} ItemId={itemId} ItemType={itemType} Title={itemTitle} BlockType={blockType} GuestLicence={guestLicence} CurrentView={currentView} Chart={chart} IsLoading={isLoading} onViewChange={this.updateView} onSettingsChange={this.applyChartSettings}></Chart>
                </div>}
              </div>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default BlockBurned;