import React, {Component} from 'react';
import '../Css/App.css';
import Authentication from '../Authentication';
import Traduction from '../Traduction';
import NotificationCard from './NotificationCard';

const API_subscribe = '/WebAppService/SubscribeNotificationRuleForUser';

class NotificationGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: null,
      authId: null,
      language: null,
      group: [],
      index: null,
      notifications: [],
      open: false
    };

    this.buildNotifications = this.buildNotifications.bind(this);
    this.clearGroup = this.clearGroup.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.unsubscribeNotifications = this.unsubscribeNotifications.bind(this);
  }

  componentDidMount() {
    const login = Authentication.getCookie('login');
    const authId = Authentication.getCookie('authId');
    const language = Authentication.getCookie('language');
    const group = this.props.Group;
    const index = this.props.Index;

    // Build Group Notifications
    const notifications = this.buildNotifications(group.Rows);

    this.setState({ login, authId, language, group, index, notifications });
  }

  componentDidUpdate(prevProps) {
    const group = this.props.Group;
    const index = this.props.Index;

    if(this.props.Group !== prevProps.Group) {
      // Build Group Notifications
      const notifications = this.buildNotifications(group.Rows);

      this.setState({ group, index, notifications, open: false });
    }
  }

  buildNotifications(rows) {
    let notifications = [];

    // Define the Notifications Card structure
    if(rows) {
      notifications = rows.map(row => {
        return row.Cells.reduce((acc, item) => {
          // Add the Column Name/Values to the reduced Table
          acc[item.ColumnName] = item.Value;
          
          return acc;
        }, {});
      });
    }

    return notifications;
  }

  clearGroup() {
    const group = this.state.group;
    const index = this.state.index;
    let ids = [];

    // Get Id's list of the Group
    group.Rows.forEach(row => {
      if(row.Cells.find(cell => cell.ColumnName === 'Notification_ID')) {
        ids.push(row.Cells.find(cell => cell.ColumnName === 'Notification_ID').Value);
      }
    });

    // Call the event from the Parent component through the props with id value
    this.props.onGroupClean(ids, index);
  }

  deleteNotification(id) {
    let group = this.state.group;
    let indexToDelete = -1;

    // Loop through the rows
    group.Rows.forEach((row, index) => {
      // Get Index if Row Notification_ID correspond to ID to delete
      if(row.Cells.find(cell => cell.ColumnName === 'Notification_ID').Value === id) {
        indexToDelete = index;
      }
    });

    // Delete Row
    if(indexToDelete >= 0) {
      group.Rows.splice(indexToDelete, 1);
    }

    // Build Group Notifications
    const notifications = this.buildNotifications(group.Rows);

    // Call the event from the Parent component through the props
    this.props.onNotificationClean();

    this.setState({ group, notifications });
  }

  displayNotifications() {
    const open = this.state.open;

    this.setState({ open: !open });
  }

  async unsubscribeNotifications(parentType, parentId) {
    const login = this.state.login;
    const authId = this.state.authId;

    // Clear Group
    this.clearGroup();

    // Request Options and Body
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Mode': 'Login',
        'Login': login,
        'Token': authId
      },
      body: JSON.stringify({
        'ItemType': parentType,
        'ItemId': parentId,
        'Subscribe': false
      })
    };

    try{
      const response = await fetch(API_subscribe, requestOptions);

      if(!response.ok) {
        throw new Error('Something went wrong ...');
      }

      const data = await response.json();
      const subscribe = data.SubscribeNotificationRuleForUserResult;

    } catch(error) {
      this.setState({ error });
    }
  }

  render() {
    const { language, group, index, notifications, open } = this.state;

    return (
      <div className="">
        {/* Group Open */}
        {notifications.length > 0 && open && <div className="notificationGroup">
          <div className="bold cursor mt2 mr5" onClick={(e) => this.displayNotifications()}>{Traduction.translate(language, 'display_less')}</div>
          <div className="blue bold cursor mt2 ml10 mr10" onClick={(e) => this.clearGroup()}>{Traduction.translate(language, 'clear_all')}</div>
          <span className="iconMute icons cursor ml5" onClick={(e) => this.unsubscribeNotifications(notifications[0].Parent_Item_Type, notifications[0].Parent_ID)}></span>
        </div>}
        {/* Group Notifications */}
        {notifications.length > 0 && open && notifications.map(notification => 
          <div key={notification.Notification_ID} className="">
            {/* Notification Card */}
            <NotificationCard Id={notification.Notification_ID} Type={'Notification'} ParentId={notification.Parent_ID} ParentType={notification.Parent_Item_Type} ParentName={notification.Parent_Name} User={notification.From_User} Message={notification.Message} Link={notification.Link} Date={notification.Time} onNotificationClean={this.deleteNotification}></NotificationCard>
          </div>
        )}

        {notifications.length > 0 && open && <div className="mt50"></div>}

        {/* Group Closed */}
        {notifications.length > 0 && !open && 
          <div key={notifications[0].Notification_ID} className="mb50">
            {/* Notification Card */}
            <NotificationCard Id={notifications[0].Notification_ID} Type={'Notification'} ParentId={notifications[0].Parent_ID} ParentType={notifications[0].Parent_Item_Type} ParentName={notifications[0].Parent_Name} User={notifications[0].From_User} Message={notifications[0].Message} Link={notifications[0].Link} Date={notifications[0].Time} onNotificationClean={this.deleteNotification}></NotificationCard>
            {/* Show more Group Notifications */}
            {notifications.length === 2 && 
              <div className="bold cursor mb30" onClick={(e) => this.displayNotifications()}>
                <span className="">+</span>
                <span className="ml5">{notifications.length - 1}</span>
                <span className="ml5">{Traduction.translate(language, 'notification')}</span>
              </div>
            }
            {notifications.length > 2 && 
              <div className="bold cursor mb30" onClick={(e) => this.displayNotifications()}>
                <span className="">+</span>
                <span className="ml5">{notifications.length - 1}</span>
                <span className="ml5">{Traduction.translate(language, 'notifications')}</span>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default NotificationGroup;